import { useMachine } from '@xstate/react'
import { equals } from 'ramda'
import { useCallback } from 'react'

import { MutationTypeState, QueryKey } from '../types'
import { getQueryKey } from '../utils'
import { mutationMachine } from './machines'
import useAppApi from './useAppApi'
import useServicesMeta from './useServicesMeta'

export function useAppUpload<T>(
  url: string,
  {
    method = 'post',
    refetchQueries = [],
  }: Partial<{ method?: 'patch' | 'post' | 'put'; refetchQueries: QueryKey[] }> = {}
) {
  const api = useAppApi(false)
  const services = useServicesMeta()
  const [current, send] = useMachine(mutationMachine, {
    services: {
      mutateData: (_, { body }) => api(url, { body, method: method.toUpperCase() }),
      refetchQueries: () => {
        for (const queryKey of refetchQueries) {
          const arrayQueryKey = Array.isArray(queryKey) ? queryKey : [queryKey]

          services.get(getQueryKey(arrayQueryKey))?.service?.send('REFETCH')

          Array.from(services.values())
            .filter((meta) => equals(meta.queryKey, queryKey))
            .forEach((meta) => meta.service?.send('REFETCH'))
        }

        return Promise.resolve()
      },
    },
  })
  const mutate = useCallback(
    <T>(data?: T) => {
      if (method === 'put') {
        send('FETCH', { body: JSON.stringify(data) })
      } else {
        const body = new FormData()

        for (const name in data) {
          if (data[name]) {
            body.append(name, data[name] as any)
          }
        }

        send('FETCH', { body })
      }
    },
    [send]
  )
  const reset = useCallback(() => {
    send('RESET')
  }, [send])

  return [mutate, current as MutationTypeState<T>, reset] as const
}
