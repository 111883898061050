import { contains } from 'ramda'

const containsNumOrString = (
  needle: string | number | WeakObject,
  haystack: Array<string | number | WeakObject>
) => {
  if (typeof needle === 'object') {
    return contains(needle, haystack)
  }

  return (
    contains(needle, haystack) ||
    contains(String(needle), haystack) ||
    contains(Number(needle), haystack)
  )
}

export default containsNumOrString
