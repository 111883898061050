import * as React from 'react'

import { Close } from '../../modules/icons'
import { twTextStyles } from '../../styles'
import Button from '../Button'
import { ModalProps } from './Modal'

type ModalHeaderProps = Pick<ModalProps, 'closeModal' | 'title' | 'classNameHeaderContainer'>

const styles = {
  headerContainer:
    'items-center bg-[rgba(48,_50,_59,_0.1)] rounded-tl rounded-tr rounded-br-none rounded-bl-none flex shrink-0 h-9 justify-between p-[10px]',
}

const ModalHeader = ({ closeModal, title, classNameHeaderContainer }: ModalHeaderProps) => (
  <div className={`${styles.headerContainer} ${classNameHeaderContainer}`}>
    <h1 className={`${twTextStyles.darkBold12} !uppercase`}>{title}</h1>
    {closeModal ? (
      <Button
        handleClick={closeModal}
        tw="items-center flex fill-dark h-[30px] w-[30px] justify-center"
      >
        <Close height={12} width={12} />
      </Button>
    ) : (
      <div />
    )}
  </div>
)

export default ModalHeader
