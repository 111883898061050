import React from 'react'
import { animated, useTransition } from 'react-spring'

type FadeInProps = {
  children: React.ReactNode
  when: boolean
}

const FadeIn = ({ children, when }: FadeInProps) => {
  const transition = useTransition(when, {
    enter: { opacity: 1 },
    from: { opacity: 0 },
    initial: null,
    leave: { opacity: 0 },
  })

  return (
    <>
      {transition(
        (props, item, { key }) =>
          item && (
            <animated.div key={key} style={props}>
              {children}
            </animated.div>
          )
      )}
    </>
  )
}

export default FadeIn
