import { Filter, Key } from '@juristat/common/types'
import React from 'react'

import parsePubno from '../../../utils/parsePubno'
import stringifyList from '../../../utils/stringifyList'
import { useNotification } from '../../notification/hooks'
import TagFilterContainer from './TagFilterContainer'
import getTagInputErrorValues from './utils/getTagInputErrorValues'

const getErrorValues = getTagInputErrorValues(/, /)

const PublicationNumberTagFilterContainer: React.FC = () => {
  const { addErrorNotification } = useNotification()

  return (
    <TagFilterContainer
      addKeys={[Key.Enter, Key.Tab]}
      filter={Filter.PublicationNumber}
      handleParseError={(data: string) => {
        const errorValues = getErrorValues(data)
        const message =
          errorValues.length > 0
            ? `Failed to parse ${stringifyList(errorValues)} as publication number(s)`
            : 'Did not receive any publication numbers'

        addErrorNotification(
          `${message}. Values should include the optional "US" prefix, 11 numbers, and an optional kind code.`
        )
      }}
      parseInput={parsePubno}
      pasteSplit={(data: string) => data.split(/[\t\n]+/).map((d: string) => d.trim())}
      title="Publication Number"
      placeholder="Enter a Publication #..."
    />
  )
}

export { getErrorValues }
export default PublicationNumberTagFilterContainer
