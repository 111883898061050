import { DataSource, Filter } from '@juristat/common/types'

import { Action } from '../../redux'
import { MergeableHttpContent } from '../http/types'
import { IntelligenceEntityType } from '../intelligence/types'
import { LocationChangeAction } from '../router/types'

enum DateRangeSelection {
  End = 'End',
  Start = 'Start',
}

enum FilterReportType {
  Dashboard = 'dashboard',
  IntelligenceApplications = 'intelligence-applications',
  IntelligenceBreakdown = 'intelligence-breakdown',
  IntelligenceCompare = 'intelligence-compare',
  Search = 'search',
}

type FilterMeta = {
  report: FilterReportType
  entity?: IntelligenceEntityType
  id?: number | string
}

type GraphQLResult = Partial<AvailableReducer>

type GraphQLVariables = {
  search: string
  uid: string
  withArtUnit: boolean
  withCpcClass: boolean
  withCurrentAssignee: boolean
  withCurrentFirm: boolean
  withExaminer: boolean
  withUspcClass: boolean
}

export enum ApplicationStatusEnum {
  Abandoned = 'ABANDONED',
  Allowed = 'ALLOWED',
  Disposed = 'DISPOSED',
  Issued = 'ISSUED',
  Pending = 'PENDING',
}

enum ApplicationTypeEnum {
  Continuation = 'CONTINUATION',
  ContinuationInPart = 'CONTINUATION_IN_PART',
  Utility = 'UTILITY',
  Design = 'DESIGN',
  Plant = 'PLANT',
  Provisional = 'PROVISIONAL',
  Reissue = 'REISSUE',
  Reexamination = 'REEXAMINATION',
  Divisional = 'DIVISIONAL',
  FirstFiling = 'FIRST_FILING',
  ForeignPriority = 'FOREIGN_PRIORITY',
  NationalStageEntry = 'NATIONAL_STAGE_ENTRY',
  TrackOne = 'TRACK_ONE',
}

export enum RejectionBasisEnum {
  Alice = 'ALICE',
  Bilski = 'BILSKI',
  DoublePatenting = 'DOUBLE_PATENTING',
  MayoMyriad = 'MAYO_MYRIAD',
  Type101 = 'TYPE_101',
  Type102 = 'TYPE_102',
  Type103 = 'TYPE_103',
  Type112a = 'TYPE_112_A',
  Type112b = 'TYPE_112_B',
  Type112f = 'TYPE_112_F',
  Type171 = 'TYPE_171',
}

enum StatCountSelection {
  AtLeast = 'atLeast',
  AtMost = 'atMost',
  Exactly = 'exactly',
}

enum EntityType {
  Company = 'COMPANY',
  Firm = 'FIRM',
  Government = 'GOVERNMENT',
  Person = 'PERSON',
  University = 'UNIVERSITY',
}

enum EntityStatus {
  Micro = 'MICRO',
  Small = 'SMALL',
  Undiscounted = 'UNDISCOUNTED',
}

type Metadata = {
  metadata: string | EntityType[] | null
}

type MaybeStatCountSelection = StatCountSelection | null

type SelectionAndValue = Nullable<{
  selection: MaybeStatCountSelection
  value: string
}>

type FilterResult<T> = { result: T }

type AppsAndName = {
  apps: number
  name: string
}

type FilterCount = AppsAndName & {
  id: number
}

type CheckListFilterCount = Omit<AppsAndName, 'name'> & {
  label: string
  value: string
}

type DateRange = Partial<{
  start: string
  end: string
}>

type StatCount = Partial<{
  atLeast: number
  atMost: number
}>

type TriState<T extends string = string> = Partial<{
  include: T[]
  exclude: T[]
}>

type ApplicationTypeState = TriState<ApplicationTypeEnum>

export type ApplicationStatusState = TriState<ApplicationStatusEnum>

type RejectionBasisState = RejectionBasisEnum[]

type Uspc = { class: string } & Nullable<{ subclass: string }>

type UspcFilterCount = Uspc & AppsAndName

type Cpc = { section: string } & Nullable<{
  class: string
  mainGroup: string
  subClass: string
  subGroup: string
}>

type CpcFilterCount = Cpc & AppsAndName

type IdFilter =
  | Filter.ArtUnit
  | Filter.AssigneeAtDisposition
  | Filter.AssigneeAtDispositionName
  | Filter.AttorneyAtDisposition
  | Filter.CurrentAssignee
  | Filter.CurrentAssigneeName
  | Filter.CurrentAttorney
  | Filter.CurrentFirm
  | Filter.CurrentFirmName
  | Filter.DocCode
  | Filter.Examiner
  | Filter.FirmAtDisposition
  | Filter.FirmAtDispositionName

type BooleanFilter = Filter.FiledUnpublished | Filter.MissedContinuation | Filter.MissedInterview

type CheckListFilter = Filter.EntityStatus | Filter.RejectionBasis | Filter.TechCenter

type StatCountFilter =
  | Filter.AmendmentCount
  | Filter.AppealCount
  | Filter.ExaminerAllowanceRate
  | Filter.ExaminerInterviewWinRate
  | Filter.FinalRejectionsCount
  | Filter.InterviewCount
  | Filter.NonFinalRejectionsCount
  | Filter.OfficeActionsCount
  | Filter.RceCount
  | Filter.UnnecessaryAppeals
  | Filter.UnnecessaryOfficeActions

type DateRangeFilter =
  | Filter.DispositionDate
  | Filter.FilingDate
  | Filter.IssuanceDate
  | Filter.PublicationDate

type TagFilter =
  | Filter.ApplicationNumber
  | Filter.AttorneyDocketNumber
  | Filter.CustomerNumber
  | Filter.PatentNumber
  | Filter.PublicationNumber
  | Filter.RegistrationNumber

type TypeaheadFilter = IdFilter | Filter.CpcClass | Filter.UspcClass

type TristateFilter = Filter.ApplicationStatus | Filter.ApplicationType

type AvailableFilter = TypeaheadFilter | CheckListFilter

type AlmostActiveFilter = AvailableFilter | TagFilter | DateRangeFilter | TristateFilter

type ActiveNonLocalFilter =
  | AlmostActiveFilter
  | StatCountFilter
  | BooleanFilter
  | Filter.RejectionBasis
  | Filter.CsvImport

type PossibleFilterItem =
  | (FilterCount & Metadata)
  | (CpcFilterCount & Metadata)
  | (UspcFilterCount & Metadata)

type Reducer = Omit<{ [K in IdFilter]: FilterCount & Metadata }, Filter.Examiner> & {
  [K in CheckListFilter]: CheckListFilterCount
} & {
  officeActions: CheckListFilterCount
  cpcClass: CpcFilterCount & Metadata
  examiner: FilterCount // Override examiner since there is no metadata
  uspcClass: UspcFilterCount & Metadata
}

type Description = { description: string | null } | null

export type TotalApps = { total: number }

type GraphQLFilterResponse = ArrayMap<{
  applicationStatus: AppsAndName
  artUnit: AppsAndName & FilterResult<{ group: Description; id: string }>
  assigneeAtDisposition: FilterCount & FilterResult<{ types: EntityType[] }>
  assigneeAtDispositionName: FilterCount & FilterResult<{ types: EntityType[] }>
  attorneyAtDisposition: FilterCount
  cpcClass: AppsAndName & FilterResult<Cpc & { descriptionText: string | null }>
  currentAssignee: FilterCount & FilterResult<{ types: EntityType[] }>
  currentAssigneeName: FilterCount & FilterResult<{ types: EntityType[] }>
  currentAttorney: FilterCount
  currentFirm: FilterCount & FilterResult<{ types: EntityType[] }>
  docCodeFilter: AppsAndName & FilterResult<{ description: string | null; docCode: string }>
  entityStatus: AppsAndName
  examiner: FilterCount
  firmAtDisposition: FilterCount & FilterResult<{ types: EntityType[] }>
  hasRejectionType: AppsAndName
  techCenter: AppsAndName & FilterResult<Description>
  uspcClass: AppsAndName & FilterResult<Uspc & { descriptionText: string | null }>
}>

type GraphQLAvailableFilterResponse = {
  filters: GraphQLFilterResponse
} & { [key in RejectionBasisEnum]: TotalApps }

type AvailableReducer = Partial<ArrayMap<Reducer>>

type ActiveReducer = Partial<
  ArrayMap<
    { [K in IdFilter | CheckListFilter | TagFilter | Filter.CsvImport]: string } & {
      [Filter.CpcClass]: Cpc
      [Filter.UspcClass]: Uspc
    }
  > & { [K in DateRangeFilter]: DateRange } & {
    [K in StatCountFilter]: StatCount
  } & {
    [K in BooleanFilter]: boolean
  } & {
    [Filter.RejectionBasis]: RejectionBasisState
    [Filter.ApplicationType]: ApplicationTypeState
    [Filter.ApplicationStatus]: ApplicationStatusState
  }
>

type Transformed = Omit<ActiveReducer, 'appno'> & { appno?: number[] }

type AlmostActiveReducer = ActiveReducer

type TypeaheadSuggestion = Reducer & {
  [key: string]: never
}

type FilterPayload<K extends keyof Reducer, T = Reducer[K]> = {
  filter: K
  value: T
}

type GetAvailablePayload = {
  dataSource: DataSource
  filters?: Partial<ActiveReducer | { applicationSet: { include: string[] } }> | Transformed
  uid?: string
}

type SetAvailableFilterPayload = ArrayMap<Reducer>

type ActiveFilterPayload = {
  filter: AlmostActiveFilter
  value: number | string | Cpc | Uspc
}

type TristateFilterPayload = {
  filter: TristateFilter
  value: string
}

type ActiveFilterListPayload = {
  filter: AlmostActiveFilter
  value: number[] | string[] | Cpc[] | Uspc[]
}

type ActiveFilterObjectPayload = {
  filter: Filter.ApplicationType
  value: ApplicationTypeState
}

type SetFilterPayload = {
  filter: ActiveNonLocalFilter
  value: number[] | string[] | Cpc[] | Uspc[] | DateRange | StatCount | Partial<RejectionBasisState>
}

type SetBooleanFilterPayload = {
  filter: BooleanFilter
  value?: boolean
}

export type ToggleSomePayload = {
  filters: Filter[]
  open: boolean
}

export type ApplyAction = Action<'filter/APPLY', Filter, FilterMeta>
export type ApplyAllAction = Action<'filter/APPLY_ALL', undefined, FilterMeta>
export type ApplyListAction = Action<'filter/APPLY_LIST', ActiveFilterListPayload, FilterMeta>
export type ApplySomeAction = Action<'filter/APPLY_SOME', Filter[], FilterMeta>
export type ClearAction = Action<'filter/CLEAR', Filter, FilterMeta>
export type ClearAllAction = Action<'filter/CLEAR_ALL', undefined, FilterMeta>
export type ClearSomeAction = Action<'filter/CLEAR_SOME', Filter[], FilterMeta>
export type ErrorAction = Action<'filter/ERROR', undefined, FilterMeta>
export type FetchAction = Action<'filter/FETCH', undefined, FilterMeta>
export type GetAvailableAction = Action<'filter/GET_AVAILABLE', GetAvailablePayload, FilterMeta>
export type RemoveAction = Action<'filter/REMOVE', ActiveFilterPayload, FilterMeta>
export type RemoveListAction = Action<'filter/REMOVE_LIST', ActiveFilterListPayload, FilterMeta>
export type RemoveObjectAction = Action<
  'filter/REMOVE_OBJECT',
  ActiveFilterObjectPayload,
  FilterMeta
>
export type SelectAction = Action<'filter/SELECT', ActiveFilterPayload, FilterMeta>
export type SelectListAction = Action<'filter/SELECT_LIST', ActiveFilterListPayload, FilterMeta>
export type SelectObjectAction = Action<
  'filter/SELECT_OBJECT',
  ActiveFilterObjectPayload,
  FilterMeta
>
export type SelectSomeAction = Action<
  'filter/SELECT_SOME',
  SetFilterPayload | SetBooleanFilterPayload,
  FilterMeta
>
export type SelectTypeaheadAction = Action<
  'filter/SELECT_TYPEAHEAD',
  FilterPayload<TypeaheadFilter>,
  FilterMeta
>
export type SetAction = Action<'filter/SET', SetFilterPayload, FilterMeta>
export type SetAvailableAction = Action<
  'filter/SET_AVAILABLE',
  SetAvailableFilterPayload,
  FilterMeta
>
export type SetSomeAction = Action<'filter/SET_SOME', ActiveReducer, FilterMeta>
export type ToggleAction = Action<'filter/TOGGLE', Filter, FilterMeta>
export type ToggleSomeAction = Action<'filter/TOGGLE_SOME', ToggleSomePayload, FilterMeta>
export type TriStateClearAction = Action<'filter/TRISTATE_CLEAR', TristateFilterPayload>
export type TriStateExcludeAction = Action<'filter/TRISTATE_EXCLUDE', TristateFilterPayload>
export type TriStateIncludeAction = Action<'filter/TRISTATE_INCLUDE', TristateFilterPayload>

export type HydrateAction = Action<
  'filter/HYDRATE',
  Partial<ActiveReducer> | Transformed,
  FilterMeta
>
export type SwitchAction = Action<'filter/SWITCH', CurrentOrDispositionState | null, FilterMeta>

export type Actions =
  | ApplyListAction
  | ApplyAction
  | ApplyAllAction
  | ApplySomeAction
  | ClearAction
  | ClearAllAction
  | ClearSomeAction
  | ErrorAction
  | FetchAction
  | HydrateAction
  | RemoveAction
  | RemoveListAction
  | RemoveObjectAction
  | SelectAction
  | SelectListAction
  | SelectObjectAction
  | SelectSomeAction
  | SelectTypeaheadAction
  | SetAction
  | SetAvailableAction
  | SetSomeAction
  | SwitchAction
  | ToggleAction
  | ToggleSomeAction
  | TriStateClearAction
  | TriStateExcludeAction
  | TriStateIncludeAction

type PossibleActions = Actions | LocationChangeAction

type CurrentOrDispositionAssigneeState =
  | Filter.AssigneeAtDisposition
  | Filter.AssigneeAtDispositionName
  | Filter.CurrentAssignee
  | Filter.CurrentAssigneeName
type CurrentOrDispositionAttorneyState = Filter.AttorneyAtDisposition | Filter.CurrentAttorney
type CurrentOrDispositionFirmState = Filter.CurrentFirm | Filter.FirmAtDisposition

type CurrentOrDispositionState =
  | CurrentOrDispositionAssigneeState
  | CurrentOrDispositionAttorneyState
  | CurrentOrDispositionFirmState

type SearchSetFilterState = {
  active: ActiveReducer
  assignee: CurrentOrDispositionAssigneeState
  attorney: CurrentOrDispositionAttorneyState
  available: MergeableHttpContent<AvailableReducer>
  firm: CurrentOrDispositionFirmState
  open: Filter[]
  selected: AlmostActiveReducer
}

enum IncludeOrExclude {
  Exclude = 'exclude',
  Include = 'include',
}

export {
  ActiveFilterObjectPayload,
  ActiveFilterPayload,
  ActiveNonLocalFilter,
  ActiveReducer,
  AlmostActiveFilter,
  AlmostActiveReducer,
  ApplicationTypeEnum,
  ApplicationTypeState,
  AppsAndName,
  AvailableFilter,
  AvailableReducer,
  BooleanFilter,
  CheckListFilter,
  CheckListFilterCount,
  Cpc,
  CpcFilterCount,
  CurrentOrDispositionState,
  DateRange,
  DateRangeFilter,
  DateRangeSelection,
  EntityStatus,
  EntityType,
  Filter,
  FilterCount,
  FilterMeta,
  FilterReportType,
  FilterResult,
  GraphQLAvailableFilterResponse,
  GraphQLFilterResponse,
  GraphQLResult,
  GraphQLVariables,
  IncludeOrExclude,
  MaybeStatCountSelection,
  PossibleActions,
  PossibleFilterItem,
  RejectionBasisState,
  SearchSetFilterState,
  SelectionAndValue,
  SetFilterPayload,
  SetAvailableFilterPayload,
  StatCount,
  StatCountFilter,
  StatCountSelection,
  TagFilter,
  Transformed,
  TriState,
  TristateFilter,
  TristateFilterPayload,
  TypeaheadFilter,
  TypeaheadSuggestion,
  Uspc,
  UspcFilterCount,
}
