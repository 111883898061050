import makeAction from '../../redux/utils/makeAction'
import { NotificationPopAction, NotificationPushAction, NotificationUpdateAction } from './types'

export const NOTIFICATION_POP = 'notification/POP'
const pop = makeAction<NotificationPopAction>(NOTIFICATION_POP)

export const NOTIFICATION_PUSH = 'notification/PUSH'
const push = makeAction<NotificationPushAction>(NOTIFICATION_PUSH)

export const NOTIFICATION_UPDATE = 'notification/UPDATE'
const update = makeAction<NotificationUpdateAction>(NOTIFICATION_UPDATE)

export default {
  pop,
  push,
  update,
}
