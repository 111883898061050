import { DataSource, SearchScope, SearchStatus, SortOrder } from '@juristat/common/types'
import { isNilOrEmpty } from '@juristat/common/utils'
import cuid from 'cuid'
import { parse } from 'qs'
import { difference, mergeDeepWith, path, pathOr, pick, union } from 'ramda'
import { Reducer, combineReducers } from 'redux'
import { Md5 } from 'ts-md5'

import { makeFilterReducer } from '../../filter/reducer'
import {
  Actions as FilterActions,
  PossibleActions as FilterPossibleActions,
  FilterReportType,
} from '../../filter/types'
import { HttpStatus } from '../../http/types'
import pagination from '../../pagination/reducer'
import { PossibleActions as PaginationPossibleActions } from '../../pagination/types'
import { LOCATION_CHANGE, LocationChangeAction } from '../../router'
import { SearchResultHttpContent } from '../../search/types'
import { SearchHistoryDeleteFavoriteAction } from '../../userSearches/types'
import {
  Actions,
  Definition,
  Result,
  SearchSet,
  SearchSetReport,
  SearchSetReportStatus,
  SearchSetState,
  SearchSetViewState,
  SearchSets,
  SearchState,
  SearchType,
  SetReportAction,
} from '../types'
import getActiveSearchIdType from '../utils/getActiveSearchIdType'
import getPhraseFromDefinition from '../utils/getPhraseFromDefinition'
import getScopeAndTypeFromDefinition from '../utils/getScopesAndTypeFromDefinition'
import getSortOrderFromDefinition from '../utils/getSortOrderFromDefinition'

type PossibleActions = Actions | FilterPossibleActions | PaginationPossibleActions

const initialSearchId = cuid()

const dataSource = (
  state: SearchSet['dataSource'] = DataSource.PublicPair,
  action: PossibleActions | SetReportAction
): SearchSet['dataSource'] => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return (
        (parse(action.payload!.search, { ignoreQueryPrefix: true }).src as
          | DataSource
          | undefined) ?? state
      )
    case 'filter/CLEAR_ALL':
      if (action.meta && action.meta.report === FilterReportType.Search) {
        return DataSource.PublicPair
      }
    case 'search/dataSource/SET':
      return action.payload!
    case 'search/report/SET':
    case 'search/HYDRATE':
      return typeof action.payload !== 'string' ? action.payload?.dataSource ?? state : state
    default:
      return state
  }
}

const definition = (state: Definition = {}, action: Actions) => {
  switch (action.type) {
    case 'search/HYDRATE':
      return pick(['filters', 'orderings', 'searches', 'similarTo'], action.payload!)
    default:
      return state
  }
}

const error = (state = false, action: PossibleActions): boolean => {
  switch (action.type) {
    case 'search/ERROR':
      return true
    case 'search/SET':
      return false
    default:
      return state
  }
}

const results: Reducer<SearchResultHttpContent<Result[]>> = (
  state = { type: HttpStatus.NotAsked },
  action: PossibleActions
) => {
  switch (action.type) {
    case 'search/SET': {
      if (state.type === HttpStatus.Success) {
        return {
          ...state,
          data: [...state.data, ...action.payload!.results],
        }
      }
      return {
        data: action.payload!.results,
        type: HttpStatus.Success,
      }
    }
    case 'filter/APPLY':
    case 'filter/APPLY_ALL':
    case 'filter/APPLY_LIST':
    case 'filter/APPLY_SOME':
    case 'filter/CLEAR':
    case 'filter/CLEAR_ALL':
    case 'filter/CLEAR_SOME':
    case 'filter/SET':
    case 'filter/SET_SOME':
      if (action.meta && action.meta.report === FilterReportType.Search) {
        return { type: HttpStatus.Fetching }
      }
    case 'pagination/GO':
    case 'search/INPUT':
    case 'search/sort/ORDER':
    case 'search/FETCH':
      return { type: HttpStatus.Fetching }
    case 'search/ERROR':
      return {
        message: 'Your search resulted in an error. Please try again.',
        type: HttpStatus.Error,
      }
    case 'search/MISSING_PERMISSION':
      return {
        missingPermission: action.payload!,
        type: SearchStatus.MissingPermission,
      }
    default:
      return state
  }
}

const phrase = (state: string | null = null, action: PossibleActions): string | null => {
  switch (action.type) {
    case 'filter/CLEAR_ALL':
      if (action.meta && action.meta.report === FilterReportType.Search) {
        return null
      }
    case 'search/INPUT':
      return action.payload!
    case 'search/HYDRATE':
      return getPhraseFromDefinition(action.payload!) || pathOr('', ['phrase'], action.payload!)
    default:
      return state
  }
}

const report = (
  state: SearchSetReport | null = null,
  action: PossibleActions | SearchHistoryDeleteFavoriteAction,
  searchState: SearchSet
): SearchSetReport | null => {
  const incomingChecksum = !isNilOrEmpty(searchState)
    ? Md5.hashStr(JSON.stringify(searchState))
    : null

  switch (action.type) {
    case 'filter/APPLY':
    case 'filter/APPLY_ALL':
    case 'filter/APPLY_LIST':
    case 'filter/APPLY_SOME':
    case 'filter/CLEAR':
    case 'filter/CLEAR_SOME':
    case 'filter/SET':
    case 'filter/SET_SOME':
      if (action.meta && action.meta.report === FilterReportType.Search) {
        return {
          ...(state as SearchSetReport),
          status: SearchSetReportStatus.Dirty,
        }
      }
    case 'search/INPUT':
    case 'search/scopes/SET':
    case 'search/sort/ORDER':
    case 'search/type/SET': {
      if (incomingChecksum !== state?.checksum) {
        return {
          ...(state as SearchSetReport),
          status: SearchSetReportStatus.Dirty,
        }
      }

      return state
    }
    case 'search/report/SET': {
      const { payload } = action
      const userDataKey = typeof payload === 'string' ? payload : payload?.userDataKey

      if (!userDataKey) {
        return null
      }

      return {
        checksum: incomingChecksum as string,
        status: SearchSetReportStatus.Clean,
        userDataKey,
      }
    }
    case 'filter/CLEAR_ALL':
      return null // invalidate active on any change
    case 'search/history/DELETE_FAVORITE':
      if (state && action.payload! === state.userDataKey) {
        return null
      }

      return state
    default:
      return state
  }
}

const direction = (
  state: SortOrder['direction'] = null,
  action: PossibleActions
): SortOrder['direction'] => {
  switch (action.type) {
    case 'search/sort/ORDER':
      return action.payload ? action.payload.direction : null
    case 'search/HYDRATE':
      return (
        path(['direction'], getSortOrderFromDefinition(action.payload!)) ??
        pathOr(null, ['sort', 'direction'], action.payload!)
      )
    default:
      return state
  }
}

const field = (state: SortOrder['field'] = null, action: PossibleActions): SortOrder['field'] => {
  switch (action.type) {
    case 'search/sort/ORDER':
      return action.payload ? action.payload.field : null
    case 'search/HYDRATE':
      return (
        path(['field'], getSortOrderFromDefinition(action.payload!)) ??
        pathOr(null, ['sort', 'field'], action.payload!)
      )
    default:
      return state
  }
}

const sort = combineReducers<SortOrder>({ direction, field })

const scopes: Reducer<SearchScope[]> = (
  state = [SearchScope.FullText],
  action: PossibleActions
) => {
  switch (action.type) {
    case 'filter/CLEAR_ALL':
      if (action.meta && action.meta.report === FilterReportType.Search) {
        return [SearchScope.FullText]
      }
    case 'search/scopes/SET':
      return action.payload!
    case 'search/HYDRATE': {
      const { scopes: maybeScopes } = getScopeAndTypeFromDefinition(action.payload!)

      return maybeScopes.length === 0 ? state : maybeScopes
    }
    default:
      return state
  }
}

const time = (state = '', action: PossibleActions): string => {
  switch (action.type) {
    case 'search/FETCH':
      return ''
    case 'search/SET':
      return (Number(state) + Number(action.payload!.time)).toFixed(2)
    default:
      return state
  }
}

const type = (state: SearchType = SearchType.Keyword, action: PossibleActions): SearchType => {
  switch (action.type) {
    case 'filter/CLEAR_ALL':
      if (action.meta && action.meta.report === FilterReportType.Search) {
        return SearchType.Keyword
      }
    case 'search/INPUT':
      return action.payload! === '' ? SearchType.Keyword : state
    case 'search/scopes/SET':
      if (
        difference(
          [SearchScope.Abstract, SearchScope.Claims, SearchScope.Description],
          action.payload!
        ).length !== 3
      ) {
        return SearchType.Keyword
      }

      return state
    case 'search/HYDRATE':
      return pathOr(state, ['type'], getScopeAndTypeFromDefinition(action.payload!))
    case 'search/type/SET':
      return action.payload!
    default:
      return state
  }
}

const uid = (
  state = '',
  action: PossibleActions | FilterActions | LocationChangeAction
): string => {
  switch (action.type) {
    case 'search/uid/SET':
      return action.payload!
    case 'filter/APPLY':
    case 'filter/APPLY_ALL':
    case 'filter/APPLY_LIST':
    case 'filter/APPLY_SOME':
    case 'filter/CLEAR':
    case 'filter/CLEAR_ALL':
    case 'filter/CLEAR_SOME':
    case 'filter/SET':
    case 'filter/SET_SOME':
      if (action.meta && action.meta.report === FilterReportType.Search) {
        return ''
      }
    case 'search/INPUT':
    case 'search/sort/ORDER':
      // If new filters or phrase are applied or cleared, the uid is no longer valid for the search
      return ''
    default:
      return state
  }
}

const filters = makeFilterReducer(FilterReportType.Search)

const searchSet = combineReducers({
  dataSource,
  definition,
  filters,
  pagination,
  phrase,
  results,
  scopes,
  sort,
  time,
  type,
  uid,
})

const searchSetView = combineReducers({
  error,
})

const initialSearchSetState: SearchSetState = {
  report: null,
  search: searchSet({}, { type: '' }) as SearchSet,
  view: searchSetView({}, { type: '' }) as SearchSetViewState,
}

const searchSets = (state: SearchSetState = initialSearchSetState, action: PossibleActions) => ({
  report: report(state.report, action, state.search),
  search: searchSet(state.search, action),
  view: searchSetView(state.view, action),
})

const sets = (state: SearchSets = {}, action: PossibleActions, searchId: string) => {
  switch (action.type) {
    case 'search/set/FORK': {
      const { newId } = action.payload ?? {}

      if (!newId) {
        return state
      }

      return {
        ...state,
        [newId]: {
          ...mergeDeepWith(union, state[searchId], {
            search: pathOr({}, ['payload', 'partial'], action),
          }),
        },
      }
    }
    default:
      return {
        ...state,
        [searchId]: searchSets(state[searchId], action),
      }
  }
}

const searchViewActiveFilters = (state: string = initialSearchId, action: PossibleActions) => {
  switch (action.type) {
    case 'search/set/FORK':
      return action.payload!.newId || state
    case 'search/set/SET_ACTIVE':
      return path(['meta', 'filters'], action) ? action.payload! : state
    default:
      return state
  }
}

const searchViewActiveResults = (state: string = initialSearchId, action: PossibleActions) => {
  switch (action.type) {
    case 'search/set/FORK':
      return action.payload!.newId || state
    case 'search/set/SET_ACTIVE':
      return path(['meta', 'results'], action) ? action.payload! : state
    default:
      return state
  }
}

const active = combineReducers({
  filters: searchViewActiveFilters,
  results: searchViewActiveResults,
})

const advancedSearchVisible = (state = false, action: PossibleActions) => {
  switch (action.type) {
    case 'search/ADVANCED_SEARCH_VISIBLE':
      return action.payload!
    case 'search/INPUT':
      return false
    default:
      return state
  }
}

const view = combineReducers({
  active,
  advancedSearchVisible,
})

const initialState = {
  sets: {},
  view: {
    active: {
      filters: initialSearchId,
      results: initialSearchId,
    },
    advancedSearchVisible: false,
  },
}

const reducer = (state: SearchState = initialState, action: PossibleActions) => {
  if (
    action.type.startsWith('filter/') &&
    path(['meta', 'report'], action) !== FilterReportType.Search
  ) {
    return state
  }

  const actionType = getActiveSearchIdType(action)
  return {
    sets: sets(
      state.sets,
      action,
      pathOr(state.view.active[actionType], ['meta', 'searchId'], action) || initialSearchId
    ),
    view: view(state.view, action),
  }
}

export default reducer
