import { css } from 'emotion'
import React, { Fragment } from 'react'

import { colors } from '../../../styles'
import { IconProps } from '../../icons'
import { OmnisearchResult } from '../types'

type SectionProps = {
  children: React.ReactNode
  data: OmnisearchResult[]
  icon: (props: IconProps) => JSX.Element
  label: string
}

const styles = {
  header: css({
    '& > svg': {
      fill: 'currentColor',
      marginRight: 10,
    },
    '&:not(:first-of-type)': {
      marginTop: 20,
    },
    alignItems: 'center',
    display: 'flex',
    height: 24,
    marginBottom: 8,
    transition: 'color 300ms ease-out',
  }),
  headerEmpty: css({
    '& > svg': {
      fill: 'currentColor',
    },
    color: colors.silver2,
  }),
}

const Section: React.FC<SectionProps> = ({ children, data, icon: Icon, label }) => (
  <Fragment>
    <div className={css(styles.header, data.length === 0 && styles.headerEmpty)}>
      <Icon height={16} width={16} /> {label} ({data.length})
    </div>
    {children}
  </Fragment>
)

export default Section
