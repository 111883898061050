import { Machine, assign } from 'xstate'

export const resetPasswordMachine = Machine<{ error: string | null }>({
  context: { error: null },
  id: 'resetPassword',
  initial: 'idle',
  on: {
    RESET: {
      actions: assign((_context, _event) => ({ error: null })),
      target: 'idle',
    },
  },
  states: {
    idle: {
      on: {
        FETCH: 'loading',
      },
    },
    loading: {
      entry: assign((_context, _event) => ({ error: null })),
      invoke: {
        src: 'reset',
        onDone: 'success',
        onError: {
          target: 'failure',
          actions: assign((_context, event) => ({ error: event.data })),
        },
      },
    },
    success: {
      on: {
        FETCH: 'loading',
      },
    },
    failure: {
      on: {
        FETCH: 'loading',
      },
    },
  },
})
