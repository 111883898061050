import { useDraggable } from 'muuri-react'
import React from 'react'

import { GridItemProps } from '../../types'
import BaseGridItem from './BaseGridItem'

const StaticGridItem: React.FC<GridItemProps & { children: React.ReactNode }> = (props) => {
  const setDraggable = useDraggable()

  setDraggable(false)

  return <BaseGridItem {...props} />
}

export default StaticGridItem
