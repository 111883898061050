import { useLocation, useParams } from 'react-router-dom'

import { useDashboardPpair } from '../../dashboards/hooks'
import { mergeFilters } from '../../dashboards/utils'
import { useEntityTypeAndId } from '../../intelligence/hooks'
import { getIntrinsicFilters } from '../../intelligence/utils'
import { useSearchVariables } from '../../search/hooks'

export * from './useExaminerAppealsGraph'
export * from './useExaminerTableSortState'

export function useIsDashboard() {
  const { dashboard } = useParams<{ dashboard?: string }>()

  return Boolean(dashboard)
}

export function useIsExaminerReport() {
  const { pathname } = useLocation()

  return pathname.includes('/examiner/')
}

export function useFilters(examinerId?: number) {
  const { filters } = useSearchVariables()
  const isDashboard = useIsDashboard()
  const ppair = useDashboardPpair()
  const entityTypeAndId = useEntityTypeAndId()

  return mergeFilters(filters, {
    dispositionDate: { start: '2000-01-01' },
    ...(examinerId ? { examiner: [examinerId] } : {}),
    ...(isDashboard
      ? ppair
        ? getIntrinsicFilters(entityTypeAndId)
        : {}
      : getIntrinsicFilters(entityTypeAndId)),
  })
}
