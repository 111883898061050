import makeAction from '../../redux/utils/makeAction'
import { CallHistoryMethodAction, CallHistoryMethodPayload, LocationChangeAction } from './types'

type Args = CallHistoryMethodPayload['args']
type Method = CallHistoryMethodPayload['method']

const makeRouterAction =
  (method: Method) =>
  (...args: Args): CallHistoryMethodAction => ({
    payload: { args, method },
    type: '@@router/CALL_HISTORY_METHOD',
  })

const push = makeRouterAction('push')
const replace = makeRouterAction('replace')
const sync = makeAction<LocationChangeAction>('@@router/LOCATION_CHANGE')

export default { push, replace, sync }
