import React from 'react'

import { AllowanceRatesOverTime, Charts, TransformLine } from '../types'
import { ChartProps } from '../types'
import LineChart from './LineChart'
import LineChartSkeletonLoader from './LineChartSkeletonLoader'
import PlatformChartContainer from './PlatformChartContainer'

const transform: TransformLine<AllowanceRatesOverTime> = (datum) =>
  datum.map(({ data, ...item }) => ({
    ...item,
    data: data
      .sort((current, next) => current.year - next.year)
      .map(({ allowanceRate, year }) => ({
        x: year,
        y: allowanceRate,
      })),
  }))

const AllowanceRate: React.FC<ChartProps> = (props) => (
  <PlatformChartContainer
    chart={Charts.AllowanceRatesOverTime}
    exportableConfig={{
      filename: 'allowance_rate',
      getData: (data) => {
        const years = data[0].data.map(({ year }) => year)

        return data.map((item) => {
          const datapoints = years.map((year) =>
            item.data.find(({ year: datumYear }) => year === datumYear)
          )

          return [
            item.id,
            ...datapoints.map((datapoint) => (datapoint ? datapoint.allowanceRate : '')),
          ]
        })
      },
      getHeader: (data) => data[0].data.map(({ year }) => year),
    }}
    skeleton={LineChartSkeletonLoader}
    title="Allowance Rate"
    {...props}
  >
    {({ data: raw, ...dimensions }) => {
      const data = transform(raw)

      return (
        <LineChart
          {...dimensions}
          axisBottom={{
            legend: 'Disposition Year',
          }}
          axisLeft={{
            format: '.0%',
            legend: 'Allowance Rate',
          }}
          data={data}
          tooltipFormat=".0%"
        />
      )
    }}
  </PlatformChartContainer>
)

export default AllowanceRate
