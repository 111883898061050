import { isEmpty, map, pickBy, reduce, values } from 'ramda'
import { createSelector } from 'reselect'

import { AppState } from '../../../redux'
import { ActiveReducer } from '../../filter/types'
import { getSearchPhrase } from '../../search/selectors'
import getActiveFilters from '../../search/selectors/getActiveFilters'
import {
  SearchHistoryData,
  SearchHistoryDataWithFavorite,
  SearchHistoryHistoryState,
  SearchHistoryState,
  SearchHistoryViewState,
  SearchHistoryWithFavoritesState,
  UserSearchesFetchingState,
  UserSearchesFetchingStateItems,
} from '../types'

export const getSearchHistoryState = (state: AppState): SearchHistoryState => state.searchHistory

export const getSearchHistory = createSelector(
  getSearchHistoryState,
  (state: SearchHistoryState) => state.history
)

export const getSearchHistoryList = createSelector(
  getSearchHistory,
  (state: SearchHistoryHistoryState): SearchHistoryData[] => values(state)
)

export const getSearchHistoryUnsaved = createSelector(
  getSearchHistory,
  (state: SearchHistoryHistoryState) =>
    pickBy<SearchHistoryHistoryState, SearchHistoryHistoryState>(
      (_, k) => !/saved/gi.test(k),
      state
    )
)

export const getLastSavedSearchHistoryItemKey = createSelector(
  getActiveFilters,
  getSearchPhrase,
  getSearchHistoryUnsaved,
  (
    filters: ActiveReducer,
    phrase: string,
    state: SearchHistoryHistoryState
  ): string | undefined => {
    const filtersAreEmpty = isEmpty(filters) && !phrase
    if (filtersAreEmpty) {
      return undefined
    }
    return reduce(
      (data: SearchHistoryData, acc: SearchHistoryData) => {
        return data.timestamp > acc.timestamp ? data : acc
      },
      { timestamp: -Infinity } as SearchHistoryData,
      values(state)
    ).userDataKey
  }
)

export const getSearchHistoryFavorites = createSelector(
  getSearchHistoryState,
  (state: SearchHistoryState): SearchHistoryHistoryState => state.favorites
)

export const getSearchHistoryFavoritesList = createSelector(
  getSearchHistoryFavorites,
  (state: SearchHistoryHistoryState): SearchHistoryData[] => values(state)
)

export const makeGetSearchHistoryFavoritesItem = (userDataKey: string) =>
  createSelector(
    getSearchHistoryFavorites,
    (state: SearchHistoryHistoryState) => state[userDataKey] || null
  )

export const getSearchHistoryWithFavorites = createSelector(
  getSearchHistoryUnsaved,
  getSearchHistoryFavorites,
  (
    history: SearchHistoryHistoryState,
    favorites: SearchHistoryHistoryState
  ): SearchHistoryWithFavoritesState => {
    const isFavorite = (historyItem: SearchHistoryData): boolean =>
      Object.keys(
        pickBy(
          ({ userDataKey }: SearchHistoryData) =>
            userDataKey ===
            historyItem?.userDataKey.replace(/search_history/gi, 'search_history/saved'),
          favorites
        )
      ).length > 0
    const addIsFavorite = (historyItem: SearchHistoryData): SearchHistoryDataWithFavorite => ({
      ...historyItem,
      isFavorite: isFavorite(historyItem),
    })
    return map(addIsFavorite, history)
  }
)

export const makeGetSearchHistoryItemWithFavorite = (userDataKey: string) =>
  createSelector(getSearchHistoryWithFavorites, (state: SearchHistoryWithFavoritesState) => {
    return state[userDataKey]
  })

export const getLastSavedSearchHistoryItem = createSelector(
  getSearchHistoryWithFavorites,
  getLastSavedSearchHistoryItemKey,
  (historyItems: SearchHistoryHistoryState, key?: string) =>
    key
      ? {
          [key]: historyItems[key],
        }
      : {}
)

export const getSearchHistoryView = createSelector(
  getSearchHistoryState,
  (state: SearchHistoryState): SearchHistoryViewState => state.view
)

const getFetchingState = createSelector(
  getSearchHistoryView,
  (state: SearchHistoryViewState) => state.fetching
)

export const getFetchingItems = createSelector(
  getFetchingState,
  (state: UserSearchesFetchingState) => state.items
)

export const makeGetItemIsFetching = (userDataKey: string) =>
  createSelector(
    getFetchingItems,
    (state: UserSearchesFetchingStateItems) => state && state.indexOf(userDataKey) > -1
  )

export const makeGetIsFetching = () =>
  createSelector(getFetchingState, (state: UserSearchesFetchingState) => state.isFetching)
