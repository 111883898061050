import {
  always,
  head,
  keys,
  last,
  map,
  pathOr,
  pipe,
  pluck,
  prop,
  range,
  reduce,
  sort,
  sortBy,
  subtract,
  uniqBy,
  values,
  when,
} from 'ramda'
import React from 'react'

import { colors } from '../../../styles'
import {
  Charts,
  Datum,
  FilingsByTechCenter,
  IssuedPatentsByTechCenter,
  TransformLine,
  XY,
} from '../types'
import { ChartProps } from '../types'
import { getTitleForLookups } from '../utils'
import LineChart from './LineChart'
import LineChartSkeletonLoader from './LineChartSkeletonLoader'
import PlatformChartContainer from './PlatformChartContainer'

type ChartByTechCenterProps = ChartProps & {
  asPercentage?: boolean
  chart: Charts.FilingsByTechCenter | Charts.IssuedPatentsByTechCenterOverTime
  chartProps?: typeof LineChart extends React.FC<infer U> ? Partial<U> : never
  title: string
}

type Data = FilingsByTechCenter | IssuedPatentsByTechCenter

type Accumulator = {
  xys: {
    [techCenter: string]: Datum<XY>
  }
  years: {
    [year: string]: number
  }
}

type PluckYear = (list: Data[]) => number[]

const getYearRange = (ys: number[]) => (ys.length > 0 ? range(head(ys)!, last(ys)! + 1) : [])
const getSortedUniqByX = pipe(uniqBy(prop('x')), sortBy(prop('x')))

const transform =
  (asPercentage: boolean): TransformLine<Data> =>
  (datum) => {
    const datumData = pipe(
      head, // Only operate on pinned entity (not applicable for search results)
      pathOr<Data[]>([], ['data'])
    )(datum)

    const yearsXYs = pipe(
      pluck('year') as PluckYear,
      sort(subtract),
      getYearRange,
      map((x) => ({ x, y: 0 }))
    )(datumData)

    const chartColors = colors.chartColorsGradient.slice(0)

    return pipe(
      (data: Data[]) => data,
      sortBy(prop('year')),
      sortBy(prop('techCenter')),
      reduce<Data, Accumulator>(
        // Transforms the data into `x` & `y` points with `id` being the tech center
        // Also keeps track of filings per year for the percentage graph
        ({ xys, years }, { techCenter, year, ...rest }) => {
          const value = pathOr<number>(0, keys(rest), rest)

          return {
            xys: {
              ...xys,
              [techCenter]: {
                color: xys?.[techCenter]?.color ?? chartColors.shift(),
                data: getSortedUniqByX([
                  { x: year, y: value },
                  ...(xys?.[techCenter]?.data ?? []),
                  ...yearsXYs,
                ]),
                id: techCenter,
              },
            },
            years: {
              ...years,
              [year]: value + (years?.[year] ?? 0),
            },
          }
        },
        { xys: {}, years: {} }
      ),
      // If we show percentage, then run the supplied function. Skips if not percentage.
      when(always(asPercentage), ({ xys, years }) => ({
        xys: map<typeof xys, typeof xys>(
          ({ data, ...rest }) => ({
            ...rest,
            data: map(({ x, y }) => ({ x, y: y / (years[x] || 1) }), data),
          }),
          xys
        ),
        years,
      })),
      pathOr<Record<string, Datum<XY>>>({}, ['xys']),
      values
    )(datumData)
  }

const ChartByTechCenter: React.FC<ChartByTechCenterProps> = ({
  asPercentage = false,
  chartProps = {},
  title,
  ...props
}) => (
  <PlatformChartContainer
    exportableConfig={{
      filename: title.toLowerCase().replace(/ /g, '_'),
      getData: (data) =>
        transform(asPercentage)(data).map((item) => [item.id, ...item.data.map(({ y }) => y)]),
      getHeader: (data) =>
        pathOr([], [0, 'data'], transform(asPercentage)(data)).map(({ x }: XY) => x),
    }}
    skeleton={LineChartSkeletonLoader}
    title={getTitleForLookups(title, props.lookups)}
    {...props}
  >
    {({ data: raw, ...dimensions }) => {
      const data = transform(asPercentage)(raw)

      return (
        <LineChart
          {...dimensions}
          {...chartProps}
          areaOpacity={0.8}
          data={data}
          enableArea={true}
          showLegend={true}
          legend={{
            anchor: 'right',
            direction: 'column',
            itemWidth: 80,
            translateX: 110,
            translateY: 0,
          }}
          margin={{
            bottom: 45,
            left: 50,
            right: 80,
            top: 10,
          }}
          splitLegend={false}
          tooltipFormat={asPercentage ? '.0%' : ','}
          yScale={{
            max: asPercentage ? 1 : 'auto',
            min: 0,
            stacked: true,
            type: 'linear',
          }}
        />
      )
    }}
  </PlatformChartContainer>
)

export default ChartByTechCenter
