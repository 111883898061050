const alto = '#e0e0e0'
const black = '#000000'
const dark = '#1b1e31'
const dark2 = '#1c1e24'
const dark2Alpha50 = 'rgba(28, 30, 36, .5)'
const dark3 = '#282f3b'
const paleGray = '#f2f3f5'
const paleGray2 = '#f7f9fc'
const paleGray2Alpha50 = 'rgba(247, 249, 252, 0.5)'
const paleGray2Alpha70 = 'rgba(247, 249, 252, 0.7)'
const paleGray2Alpha80 = 'rgba(247, 249, 252, 0.8)'
const charcoalGray = '#30323b'
const charcoalGrayAlpha10 = 'rgba(48, 50, 59, 0.10)'
const charcoalGrayAlpha80 = 'rgba(48, 50, 59, 0.8)'
const charcoalGray2 = '#303741'
const charcoalGray2alpha04 = 'rgba(48, 55, 65, 0.04)'
const charcoalGray2alpha06 = 'rgba(48, 55, 65, 0.06)'
const charcoalGray2alpha10 = 'rgba(48, 55, 65, 0.1)'
const charcoalGray2alpha30 = 'rgba(48, 55, 65, 0.3)'
const charcoalGray2alpha50 = 'rgba(48, 55, 65, 0.5)'
const charcoalGray3 = '#353b4f'
const charcoalGray4 = '#40465a'
const charcoalGray5 = '#454958'
const charcoalGray6 = '#272d43'
const confetti = '#f0e056'
const crete = '#706928'
const googleRed = '#ea4335'
const lightGray = '#d8d8d8'
const linkedInBlue = '#0077B5'
const oathBlack = '#000200'
const silver = '#c0c1c2'
const silver2 = '#c0c3c6'
const silver2Alpha50 = 'rgba(192, 195, 198, 0.5)'
const silver3 = '#7e8289'
const appleGreen = '#7cd638'
const appleGreen2 = '#7ed321'
const azure = '#0f9fff'
const linkBlue = '#109fff'
const darkGreenBlue = '#17573b'
const lightGreenishBlue = '#5ceaa2'
const mineshaft = '#333333'
const paleGrayAlpha20 = 'rgba(242, 243, 245, 0.2)'
const paleGrayAlpha30 = 'rgba(242, 243, 245, 0.3)'
const darkGreenBlueAlpha30 = 'rgba(23, 87, 59, 0.3)'
const cloudyBlue = '#b1b9cc'
const cloudyBlueAlpha10 = 'rgba(177, 185, 204, 0.1)'
const cloudyBlueAlpha12 = 'rgba(177, 185, 204, 0.12)'
const cloudyBlueAlpha20 = 'rgba(177, 185, 204, 0.2)'
const cloudyBlueAlpha50 = 'rgba(177, 185, 204, 0.5)'
const cloudyBlueLight = '#d7dce4'
const greenyBlue = '#3ec198'
const greenyBlue2 = '#3ec298'
const greenyBlueAlpha50 = 'rgba(124, 214, 56, 0.5)'
const greenyGlow = 'rgba(92, 234, 162, 0.6)'
const mediumPurple = '#8862e5'
const monarch = '#800828'
const pastelRed = '#e84855'
const placeholder = 'rgba(40, 47, 59, 0.6)'
const santasGray = '#9ba2b3'
const shadow = 'rgba(48, 50, 59, 0.1)'
const shamrock = '#38d692'
const silverSand = '#c0c3c6'
const torchRed = '#ff0f4f'
const veniceBlue = '#085080'
const white = '#ffffff'
const ceruleanBlue = '#2b59c3'
const schoolbusYellow = '#ffd800'
const malachite = '#1ace56'
const vividCerise = '#db277b'
const tiffanyBlue = '#0cbaba'
const selectiveYellow = '#eeba0b'
const deepSaffron = '#ff9933'

const chartColors = [
  azure,
  mediumPurple,
  pastelRed,
  deepSaffron,
  schoolbusYellow,
  greenyBlue,
  ceruleanBlue,
  vividCerise,
  torchRed,
  appleGreen,
  selectiveYellow,
  veniceBlue,
]

const chartColorsGradient = [
  '#0f9fff',
  '#6782f2',
  '#8862e5',
  '#c7559c',
  '#e84855',
  '#f79642',
  '#ffd800',
  '#b5cd65',
  '#3ec198',
]

const userColors = [
  azure,
  greenyBlue,
  mediumPurple,
  pastelRed,
  torchRed,
  ceruleanBlue,
  malachite,
  selectiveYellow,
  tiffanyBlue,
  deepSaffron,
]

export {
  alto,
  black,
  chartColors,
  chartColorsGradient,
  dark,
  dark2,
  dark2Alpha50,
  dark3,
  paleGray,
  paleGray2,
  paleGray2Alpha50,
  paleGray2Alpha70,
  paleGray2Alpha80,
  charcoalGray,
  charcoalGrayAlpha80,
  charcoalGray2,
  charcoalGray2alpha04,
  charcoalGray2alpha06,
  charcoalGrayAlpha10,
  charcoalGray2alpha10,
  charcoalGray2alpha30,
  charcoalGray2alpha50,
  charcoalGray3,
  charcoalGray4,
  charcoalGray5,
  charcoalGray6,
  appleGreen,
  appleGreen2,
  confetti,
  crete,
  lightGray,
  silver,
  silver2,
  silver2Alpha50,
  silver3,
  azure,
  linkBlue,
  darkGreenBlue,
  lightGreenishBlue,
  paleGrayAlpha20,
  paleGrayAlpha30,
  darkGreenBlueAlpha30,
  cloudyBlue,
  cloudyBlueAlpha10,
  cloudyBlueAlpha12,
  cloudyBlueAlpha20,
  cloudyBlueAlpha50,
  cloudyBlueLight,
  googleRed,
  greenyBlue,
  greenyBlue2,
  greenyBlueAlpha50,
  greenyGlow,
  linkedInBlue,
  mediumPurple,
  mineshaft,
  monarch,
  oathBlack,
  pastelRed,
  placeholder,
  santasGray,
  schoolbusYellow,
  shadow,
  shamrock,
  silverSand,
  torchRed,
  userColors,
  veniceBlue,
  white,
}
