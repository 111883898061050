import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import actions from './actions'
import { getNotifications } from './selectors'
import { Notification, NotificationTypes, NotificationUpdatePayload } from './types'
import makeNotification from './utils/makeNotification'

export function useNotification() {
  const dispatch = useDispatch()
  const notifications = useSelector(getNotifications)

  const addNotification = useCallback(
    (message: string, options?: Partial<Notification>) => {
      const notification = makeNotification({ ...options, message })

      dispatch(actions.push(notification))

      return notification.id
    },
    [dispatch]
  )

  const removeNotification = useCallback(
    (id: string) => {
      dispatch(actions.pop(id))
    },
    [dispatch]
  )

  const addSuccessNotification = useCallback(
    (message: string) => addNotification(message, { type: NotificationTypes.Success }),
    [addNotification]
  )

  const addErrorNotification = useCallback(
    (message: string) => addNotification(message, { type: NotificationTypes.Error }),
    [addNotification]
  )

  const updateNotification = useCallback(
    (payload: NotificationUpdatePayload) => {
      dispatch(actions.update(payload))
    },
    [dispatch]
  )

  return {
    addErrorNotification,
    addNotification,
    addSuccessNotification,
    notifications,
    removeNotification,
    updateNotification,
  }
}
