import { Filter, Key } from '@juristat/common/types'
import { always, apply, equals, ifElse, join, match, pipe, props, toUpper } from 'ramda'
import React from 'react'

import stringifyList from '../../../utils/stringifyList'
import { useNotification } from '../../notification/hooks'
import TagFilterContainer from './TagFilterContainer'
import getTagInputErrorValues from './utils/getTagInputErrorValues'

// All prefixes that currently exist in our database
const prefixes = ['B', 'D', 'H', 'PP', 'RE', 'T', 'X']

// All kind codes that currently exist in our database
const kindCodes = ['A1', 'A2', 'A9', 'B1', 'B2', 'E1', 'H1', 'H2', 'P1', 'P2', 'P3', 'P9', 'S1']

// Matches four groups: 1: US (optional), 2: prefix (optional), 3: patent number, 4: kind code (optional)
const matchPatentNumber = new RegExp(
  `(US)?(${join('|', prefixes)})?(\\d+)(${join('|', kindCodes)})?`,
  'i'
)

const buildPatentNumber = ([, us, prefix, patent = '', kindCode]: string[]) => {
  const trimmedPatent = patent.replace(/^0+/, '')
  const patentLength = trimmedPatent.length

  return patentLength > 0 && patentLength < 9 ? [us, prefix, trimmedPatent, kindCode].join('') : ''
}

const matchWholeRegex: (matches: RegExpMatchArray) => boolean = pipe(
  props(['0', 'input']) as any,
  apply(equals as any) as any
)

const parsePatentNumber: (value: string) => string = pipe(
  match(matchPatentNumber),
  ifElse(matchWholeRegex, buildPatentNumber, always('')),
  toUpper
)

const getErrorValues = getTagInputErrorValues(/[\s]+/)

const PatentNumberTagFilter: React.FC = () => {
  const { addErrorNotification } = useNotification()

  return (
    <TagFilterContainer
      addKeys={[Key.Enter, Key.Space, Key.Tab]}
      filter={Filter.PatentNumber}
      handleParseError={(data: string) => {
        const errorValues = getErrorValues(data)

        addErrorNotification(
          `Failed to parse ${stringifyList(
            errorValues
          )} as patent number(s). Patent numbers should include the optional "US" prefix, optional patent prefix, 1-8 numbers, and an optional kind code.`
        )
      }}
      parseInput={parsePatentNumber}
      pasteSplit={(data: string) => data.split(/[\s]+/).map((d: string) => d.trim())}
      title="Patent Number"
      placeholder="Enter a Patent #..."
    />
  )
}

export { getErrorValues }
export default PatentNumberTagFilter
