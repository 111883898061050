import React from 'react'

export enum StatusType {
  Error,
  Info,
  Warning,
}

type StatusProps = {
  text: string
  status: StatusType
}

const styles = {
  shared: 'text-[#838383] tracking-[2px] text-center text-[19px] p-4 w-full',
  [StatusType.Error]: 'bg-[rgba(213,_114,_120,_0.2)] text-[#7d1b22]',
  [StatusType.Info]: 'bg-[rgba(99,_161,_209,_0.2)] text-[#1b537d]',
  [StatusType.Warning]: 'bg-[rgba(206,_198,_88,_0.2)] text-[#7f781c]',
}

const Status = ({ status, text }: StatusProps): JSX.Element => (
  <div className={`${styles.shared} ${styles[status]}`}>{text}</div>
)

export default Status
