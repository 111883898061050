import * as React from 'react'

import SVG from './SVG'
import InfoStandardSolid from './solid/InfoStandardSolid'
import { SolidProps } from './types'

const InfoStandard = ({ solid, ...props }: SolidProps) => {
  if (solid === true) {
    return <InfoStandardSolid {...props} />
  }

  return (
    <SVG title="info-standard" {...props}>
      <circle cx="17.97" cy="10.45" r="1.4" />
      <path d="M21,25H19V14.1H16a1,1,0,0,0,0,2h1V25H15a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
      <path d="M18,34A16,16,0,1,1,34,18,16,16,0,0,1,18,34ZM18,4A14,14,0,1,0,32,18,14,14,0,0,0,18,4Z" />
    </SVG>
  )
}

export default InfoStandard
