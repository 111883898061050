import { any, contains, equals, flip, isEmpty, isNil, keys, pick, reject, zipObj } from 'ramda'
import { ParametricSelector, createSelector } from 'reselect'

import { getFilterStateActive, getFilterStateOpen, getFilterStateSelected } from '.'
import { AppState } from '../../../redux'
import {
  ActiveReducer,
  Filter,
  StatCountSelection as Selection,
  SelectionAndValue,
  StatCount,
  StatCountFilter,
} from '../types'

const getFilters: ParametricSelector<AppState, { filters: Filter[] }, Filter[]> = (
  _: AppState,
  { filters }
) => filters

const transformToSelectionAndValue = (statCount: StatCount): SelectionAndValue => {
  if (isEmpty(statCount)) {
    return { selection: null, value: null }
  }

  const { atLeast, atMost } = statCount
  const [selection, value] =
    typeof atMost === 'number'
      ? [Selection.AtMost, String(atMost)]
      : [Selection.AtLeast, String(atLeast)]

  return { selection: atLeast === atMost ? Selection.Exactly : selection, value }
}

const getNonEmptyKeys = (state: Partial<ActiveReducer>) => keys(reject(isNil, state))

const emptyObject = {}

const isAnyFilterOpen = createSelector(getFilters, getFilterStateOpen, (filters, state) =>
  any(flip(contains)(filters) as any, state)
)

const getStatCountButtonProps = createSelector(
  getFilters,
  getFilterStateActive,
  getFilterStateSelected,
  (filters, active, selected) => {
    const getCountKeys = pick(filters)

    const activeCounts = getCountKeys(active)
    const selectedCounts = getCountKeys(selected)

    const applyKeys = getNonEmptyKeys(selectedCounts)
    const clearKeys = [...applyKeys, ...getNonEmptyKeys(activeCounts)]
    const applyDisabled = equals(activeCounts, selectedCounts)
    const clearDisabled = isEmpty(clearKeys)

    return {
      applyDisabled,
      applyKeys,
      clearDisabled,
      clearKeys,
    }
  }
)

const getStatCountFilterValues = createSelector(
  getFilters,
  getFilterStateSelected,
  (filters, selected) => {
    const getFilterValue = (filter: Filter) => {
      const selectedFilter = selected[filter as StatCountFilter]

      return transformToSelectionAndValue(selectedFilter ?? emptyObject)
    }

    return zipObj(filters, filters.map(getFilterValue))
  }
)

export { isAnyFilterOpen, getStatCountButtonProps, getStatCountFilterValues }
