import { Charts, ExaminerMetric } from '@juristat/common/types'
import { Bar } from '@nivo/bar'
import { css, cx } from 'emotion'
import React from 'react'

import useResizeObserver from '../../../hooks/useResizeObserver'
import { colors } from '../../../styles'
import { useQuery } from '../../api'
import BarChartSkeletonLoader from '../../charts/components/BarChartSkeletonLoader'
import { ChartProps } from '../../charts/types'
import chartTheme from '../../charts/utils/chartTheme'
import { useDashboardPpair } from '../../dashboards/hooks'
import { useFilters, useIsDashboard, useIsExaminerReport } from '../hooks'
import * as getExaminerDispositionsByRejectionCount from '../queries/getExaminerDispositionsByRejectionCount.graphql'
import { ExaminerAbandonedAllowedResponse } from '../types'
import ExaminerCardInfoText from './ExaminerCardInfoText'
import ExaminerDataContainer from './ExaminerDataContainer'

type ExaminerDispositionsByRejectionCountProps = Partial<ChartProps> & {
  className?: string
  examinerId?: number
}

type ExaminerDispositionsByRejectionCountData = Array<{
  Abandoned: number
  Allowed: number
  rejections: string
}>

const styles = {
  container: css({
    flexGrow: 1,
    height: 0,
    minHeight: 320,
  }),
  charts: css({
    minHeight: 350,
  }),
  dashboard: css({
    minHeight: 250,
  }),
  error: css({
    marginTop: 100,
  }),
  innerContainer: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  }),
  main: css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  }),
}

const ExaminerDispositionsByRejectionCount = ({
  className,
  examinerId,
  ...props
}: ExaminerDispositionsByRejectionCountProps) => {
  const {
    ref,
    dimensions: { height, width },
  } = useResizeObserver<HTMLDivElement>()

  const isDashboard = useIsDashboard()
  const isExaminerReport = useIsExaminerReport()
  const ppair = useDashboardPpair()

  const [state] = useQuery<
    ExaminerDispositionsByRejectionCountData,
    ExaminerAbandonedAllowedResponse
  >('examiner-dispositions-by-rejection-count', getExaminerDispositionsByRejectionCount, {
    ppair,
    transform: ({ applicationSet: { metrics } }) =>
      metrics
        .filter(({ buckets }) => buckets[0].value !== null)
        .map(({ applicationCounts: { allowed, abandoned }, buckets }) => ({
          abandoned,
          allowed,
          rejections: `${buckets[0].value}`,
        }))
        .sort((left, right) => Number(left.rejections) - Number(right.rejections))
        .map(({ abandoned, allowed, rejections }) => ({
          Abandoned: abandoned,
          Allowed: allowed,
          rejections,
        })),
    variables: {
      filters: useFilters(examinerId),
    },
  })

  return (
    <ExaminerDataContainer
      {...props}
      chart={Charts.DispositionsByRejectionCount}
      className={cx(styles.main, className, {
        [styles.charts]: !isExaminerReport,
        [styles.dashboard]: isDashboard,
      })}
      draggable={!isExaminerReport}
      exportableConfig={{
        filename: 'dispositions_by_rejection_count',
        getData: (data: ExaminerDispositionsByRejectionCountData) =>
          data.map((item) => [
            props.lookups?.[0].label ?? '',
            item.rejections,
            item.Allowed,
            item.Abandoned,
          ]),
        getHeader: () => ['Rejections', 'Allowed', 'Abandoned'],
      }}
      informationText="The number of applications allowed and abandoned after the specified number of rejections."
      machine={state}
      metric={ExaminerMetric.DispositionsByRejectionCount}
      title="Dispositions by Rejection Count"
    >
      <div
        className={cx(styles.container, { [styles.dashboard]: isDashboard || !isExaminerReport })}
        ref={ref}
      >
        {(['idle', 'loading'] as const).some(state.matches) ? <BarChartSkeletonLoader /> : null}
        {state.matches('failure') ? (
          <ExaminerCardInfoText>{state.context.error}</ExaminerCardInfoText>
        ) : null}
        {state.matches('success') ? (
          state.context.data.length > 0 ? (
            <div className={styles.innerContainer}>
              <Bar
                animate={false}
                axisLeft={{ format: '~s', tickPadding: 10 }}
                colors={[colors.charcoalGray, colors.appleGreen]}
                data={state.context.data}
                enableGridX={false}
                enableGridY={true}
                enableLabel={false}
                height={height}
                indexBy="rejections"
                keys={['Abandoned', 'Allowed']}
                legends={[
                  {
                    anchor: 'right',
                    dataFrom: 'keys',
                    direction: 'column',
                    itemHeight: 20,
                    itemWidth: 120,
                    itemsSpacing: 10,
                    symbolShape: 'circle',
                    translateX: 140,
                  },
                ]}
                padding={0.5}
                margin={{
                  bottom: 30,
                  left: 60,
                  right: 130,
                  top: 10,
                }}
                theme={chartTheme}
                width={width}
              />
            </div>
          ) : (
            <ExaminerCardInfoText>
              The examiner does not have enough continuations to generate this chart
            </ExaminerCardInfoText>
          )
        ) : null}
      </div>
    </ExaminerDataContainer>
  )
}

export default ExaminerDispositionsByRejectionCount
