import { combineReducers } from 'redux'

import { makeFilterReducer } from '../../../../filter/reducer'
import { FilterReportType } from '../../../../filter/types'
import createUidReducer from '../../../reducer/createUidReducer'

const filters = makeFilterReducer(FilterReportType.IntelligenceCompare)
const uid = createUidReducer(FilterReportType.IntelligenceCompare)

const reducer = combineReducers({ filters, uid })

export default reducer
