import makeAction from '../../../redux/utils/makeAction'
import {
  ApplyDefaultViewAction,
  ApplyDefaultViewErrorAction,
  SearchHistoryDeleteFavoriteAction,
  SearchHistoryDeletedAction,
  SearchHistoryFetchAction,
  SearchHistoryGetAction,
  SearchHistorySaveError,
  SearchHistorySaveFavoriteAction,
  SearchHistorySavedAction,
  SearchHistorySetAction,
  SearchHistoryUpdateFavoriteAction,
} from '../types'

const historyDeleteFavorite = makeAction<SearchHistoryDeleteFavoriteAction>(
  'search/history/DELETE_FAVORITE'
)

const historyDeleted = makeAction<SearchHistoryDeletedAction>('search/history/DELETED')

const historyGet = makeAction<SearchHistoryGetAction>('search/history/GET')

const historySaveError = makeAction<SearchHistorySaveError>('search/history/SAVE_ERROR')

const historySaved = makeAction<SearchHistorySavedAction>('search/history/SAVED')

const historySaveFavorite = makeAction<SearchHistorySaveFavoriteAction>(
  'search/history/SAVE_FAVORITE'
)

const historySet = makeAction<SearchHistorySetAction>('search/history/SET')

const historyUpdateFavorite = makeAction<SearchHistoryUpdateFavoriteAction>(
  'search/history/UPDATE_FAVORITE'
)

const fetch = makeAction<SearchHistoryFetchAction>('search/history/FETCH')

const applyDefaultView = makeAction<ApplyDefaultViewAction>('search/history/APPLY_DEFAULT')

const applyDefaultViewError = makeAction<ApplyDefaultViewErrorAction>(
  'search/history/APPLY_DEFAULT_ERROR'
)

const actions = {
  applyDefaultView,
  applyDefaultViewError,
  deleteFavorite: historyDeleteFavorite,
  deleted: historyDeleted,
  error: historySaveError,
  fetch,
  get: historyGet,
  saveFavorite: historySaveFavorite,
  saved: historySaved,
  set: historySet,
  updateFavorite: historyUpdateFavorite,
}

export default actions
