import React from 'react'

import ConditionalWrapper from '../../../components/ConditionalWrapper'
import { MultiSelect, Select } from '../../../components/Select'

type CommonProps = {
  background: 'white' | 'blue'
  disabled?: boolean
  handleClear?: () => void
  maxHeight?: number
  options: Array<{
    disabled?: boolean
    description?: string
    label: string
    value: string
  }>
  placeholder: string
  searchable?: string
}

type WorkflowAutomationSelectProps = CommonProps & {
  handleSelectionChange: (value: string) => void
  value: string
  values?: never
}

type WorkflowAutomationMultiSelectProps = CommonProps & {
  handleSelectionChange: (value: string[]) => void
  value?: never
  values: string[]
  width?: number
}

const styles = {
  dim: '[&_>_button_>_span]:opacity-60',
  disabled: 'pointer-events-none opacity-60',
  item: '[&_>_span:first-of-type]:font-bold [&_>_span:first-of-type]:overflow-hidden [&_>_span:first-of-type]:text-ellipsis !text-blue-gray !font-roboto',
  select:
    'font-montserrat text-s !font-bold [&_>_button]:text-blue-gray [&_>_button]:font-montserrat [&_>_button]:text-s [&_>_button]:!font-bold [&_>_button_>_span]:font-montserrat [&_>_button_>_span]:text-s [&_>_button_>_span]:!font-bold [&_>_button_>_svg]:fill-current [&_>_button_>_ul]:max-h-[400px] [&_>_button_>_ul]:overflow-auto rounded [&_>_button]:border-transparent h-[31px] max-w-[262px] [&_>_ul]:max-h-[400px] [&_>_ul]:overflow-y-auto',
}

const WorkflowAutomationMultiSelect = ({
  background,
  ...props
}: WorkflowAutomationMultiSelectProps) => {
  return (
    <MultiSelect
      tw={`${styles.select} ${props.values.length === 0 ? styles.dim : ''} ${
        background === 'blue' ? 'bg-[#f5f9ff]' : ''
      }`}
      {...props}
    />
  )
}

const WorkflowAutomationSelect = ({ background, ...props }: WorkflowAutomationSelectProps) => {
  return (
    <Select
      tw={`${styles.select} ${props.value && props.value.length === 0 ? styles.dim : ''} ${
        background === 'blue' ? 'bg-[#f5f9ff] [&_>_button_>_span]:text-blue-gray' : ''
      }`}
      {...props}
    >
      {({ className, getItemProps, index, ...item }) => {
        const { description, disabled = false } =
          props.options.find((option) => option.value === item.value) ?? {}

        return (
          <li
            {...getItemProps({
              className: `${className} ${styles.item} ${disabled ? styles.disabled : ''}`,
              index,
              item,
              key: item.value,
            })}
          >
            <ConditionalWrapper
              condition={Boolean(description)}
              wrapper={(children) => (
                <>
                  {children}
                  <span>{description}</span>
                </>
              )}
            >
              <span>{item.label}</span>
            </ConditionalWrapper>
          </li>
        )
      }}
    </Select>
  )
}

export { WorkflowAutomationMultiSelect, WorkflowAutomationSelect }
