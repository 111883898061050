import { Action } from '../../redux'
import { HttpContent } from '../http'

type MetricsKeyMap = 'art_unit' | 'cpc4' | 'id' | 'tech_center'

enum Classification {
  ArtUnit = 'art-unit',
  Cpc = 'cpc',
  TechCenter = 'tech-center',
  Uspc = 'uspc',
}

type SimilarApplication = {
  appno: string
  artUnit: number
  assignee: {
    id: number
    name: string
  }
  cpcClass: string
  similarity: number
  techCenter: number
  title: string
  uspcClass: string
}

type SignificantCitedArt = {
  appno: string
  assignee: {
    id: number
    name: string
  }
  citationCount: number
  title: string
}

type ClassificationState = {
  citedArt: SignificantCitedArt[]
  classifications: { [C in Classification]: { [key: string]: number } }
  keywords: {
    [C in Classification]: {
      [key: string]: {
        [word: string]: number
      }
    }
  }
  similarApplications: SimilarApplication[]
}

type KeyMetricsState = {
  [key: string]: {
    alice: number
    allowance: number
    averageOfficeActions: number
    title: string
  }
}

type Suggestion = {
  [word: string]: {
    [suggestion: string]: number
  }
}

type DraftingState = {
  classification: HttpContent<ClassificationState>
  keyMetrics: { [C in Classification]: HttpContent<KeyMetricsState> }
  suggestions: {
    [C in Classification]: {
      boosted: string[]
      results: HttpContent<Suggestion>
    }
  }
}

type BoostAction = Action<'drafting/BOOST', string[], Classification>
type ClassifyAction = Action<'drafting/CLASSIFY', string>
type ClearAction = Action<'drafting/CLEAR', undefined>
type GetKeyMetricsAction = Action<'drafting/GET_KEY_METRICS', Classification>
type GetSuggestionsAction = Action<'drafting/GET_SUGGESTIONS', Classification>
type SetClassificationStateAction = Action<
  'drafting/SET_CLASSIFICATION',
  DraftingState['classification']
>
type SetKeyMetricsStateAction = Action<
  'drafting/SET_KEY_METRICS',
  HttpContent<KeyMetricsState>,
  Classification
>
type SetSuggestionsStateAction = Action<
  'drafting/SET_SUGGESTIONS',
  HttpContent<Suggestion>,
  Classification
>

type ReducerActions =
  | BoostAction
  | ClearAction
  | ClassifyAction
  | SetClassificationStateAction
  | SetKeyMetricsStateAction
  | SetSuggestionsStateAction

type SimilarDocResponse = {
  appno: string
  assignment: {
    assignee: {
      entity_id: number
      name: string
    }
  }
  class: {
    art_unit: number
    cpc: string
    cpc_full: string
    tech_center: number
    uspc: string
  }
  similarity_score: number
  title: string
}

type CitedArtResponse = {
  appno: number
  assignment: {
    assignee: {
      entity_id: number
      name: string
    }
  }
  citeCount: number
  country: string
  score: number
  title: string
}

type ClassifyResponse = {
  cited_art: CitedArtResponse
  classifications: {
    art_unit: { [artUnit: string]: number }
    cpc: { [cpc: string]: number }
    tech_center: { [techCenter: string]: number }
    uspc: { [uspc: string]: number }
  }
  similar_docs: SimilarDocResponse[]
  terms: {
    [term: string]: {
      art_unit: { [artUnit: string]: number }
      cpc: { [cpc: string]: number }
      tech_center: { [techCenter: string]: number }
      uspc: { [uspc: string]: number }
    }
  }
}

type RecursiveNullableNumber<T> = {
  [P in keyof T]: T[P] extends Array<infer U>
    ? Array<RecursiveNullableNumber<U>>
    : T[P] extends Record<string, unknown>
    ? RecursiveNullableNumber<T[P]>
    : T[P] | null
}

type Groups = {
  art_unit: { art_unit: number }
  cpc4: { cpc4: number }
  id: { class: number }
  tech_center: { tech_center: number }
}

export type KeyMetricsResponseFields<G extends keyof Groups = any> = {
  allowance_rate: number
  allowance_rates: Array<{
    overall: number
    with_interview: number
    without_interview: number
  }>
  applications: {
    abandoned: number
    allowed: number
    disposed: number
    disposed_with_interview: number
    disposed_without_interview: number
    total: number
  }
  claims: {
    [A in 'counts' | 'words_per_claim']: {
      [B in 'dependent' | 'independent']: {
        [C in 'at_granting' | 'at_publishing']: {
          average: number
        }
      }
    }
  }
  groups: Groups[G]
  office_actions: {
    [D in 'abandoned_after' | 'allowed_after']: {
      0: number
      1: number
      2: number
      3: number
      4: number
      5: number
      6: number
      7: number
      8: number
      9: number
      '10_plus': number
    }
  } & {
    [E in 'to_abandonment' | 'to_allowance' | 'to_disposition']: {
      average: number
    }
  }
  rejections: {
    alice: number
    all: number
    double_patenting: number
    mayo_myriad: number
    obvious: number
    same: number
    type_101: number
    type_102: number
    type_103: number
    type_112a: number
    type_112b: number
    type_171: number
  } & {
    post: {
      alice: number
      bilski: number
      mayo: number
      myriad: number
    }
  }
  time_to: { [F in 'abandonment' | 'allowance' | 'disposition']: { average: number } }
  win_rates: {
    [G in 'appeal' | 'interview' | 'rce']: {
      loss_rate: number
      losses: number
      total: number
      win_rate: number
      wins: number
    }
  }
}

type KeyMetricsResponse = RecursiveNullableNumber<{
  [M in MetricsKeyMap]: Record<string, KeyMetricsResponseFields<M>>
}>

type GetEntitiesTitleResponse = GraphQLResponse<{
  applicationSet: {
    filterOptions: {
      artUnit: Array<{
        apps: number
        name: string
        result: {
          group: {
            description: string
          }
        }
      }>
      cpcClass: Array<{
        apps: number
        name: string
        result: {
          descriptionText: string
        }
      }>
      techCenter: Array<{
        apps: number
        name: string
        result: {
          description: string
        }
      }>
      uspcClass: Array<{
        apps: number
        name: string
        result: {
          descriptionText: string
        }
      }>
    }
  }
}>

type ClassificationResult2 = {
  id: string
  probability: number
}

type ClassificationResult = {
  alice: number
  allowance: number
  averageOfficeActions: number
  id: string
  probability: number
  title: string
}

type ClassificationProbability = HttpContent<ClassificationResult2[]>
type ClassificationResults = HttpContent<ClassificationResult[]>

export {
  BoostAction,
  Classification,
  ClassificationProbability,
  ClassificationResults,
  ClearAction,
  ClassificationState,
  ClassifyAction,
  ClassifyResponse,
  CitedArtResponse,
  DraftingState,
  GetEntitiesTitleResponse,
  GetKeyMetricsAction,
  GetSuggestionsAction,
  KeyMetricsResponse,
  KeyMetricsState,
  MetricsKeyMap,
  ReducerActions,
  SetClassificationStateAction,
  SetKeyMetricsStateAction,
  SetSuggestionsStateAction,
  SignificantCitedArt,
  SimilarApplication,
  SimilarDocResponse,
  Suggestion,
}
