import { Reducer } from 'redux'

import { LocationChangeAction, RouterState } from './types'

const reducer: Reducer<RouterState> = (state = null, action: LocationChangeAction) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return action.payload!
    default:
      return state
  }
}

export default reducer
