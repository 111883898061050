import makeAction from '../../../redux/utils/makeAction'
import { ExportsPushAction, ExportsUpdateAction } from '../types'

const push = makeAction<ExportsPushAction>('exports/PUSH')
const update = makeAction<ExportsUpdateAction>('exports/UPDATE')

export default {
  push,
  update,
}
