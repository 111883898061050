import React, { Suspense } from 'react'

import Footer from './Footer'
import JuristatLoader from './JuristatLoader'

type PageContainerProps = Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'id'> & {
  children: React.ReactNode
}

const PageContainer: React.FC<PageContainerProps> = ({ children, className, ...props }) => (
  <div
    className={`flex w-0 flex-grow flex-col overflow-x-hidden overflow-y-scroll ${className ?? ''}`}
    id="page-container"
    {...props}
  >
    <div className="mb-10 flex-[1_0_auto]">
      <Suspense fallback={<JuristatLoader />}>{children}</Suspense>
    </div>
    <Footer className="flex-[0_0_auto]" />
  </div>
)

export default PageContainer
