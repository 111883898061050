import { css } from 'emotion'
import React from 'react'

import Checkbox from '../../../components/Checkbox'
import { BoundAction } from '../../../redux'
import { textStyles } from '../../../styles'
import {
  ActiveFilterPayload,
  PossibleFilterItem,
  RemoveAction,
  SelectAction,
  TypeaheadFilter,
} from '../types'
import FilterItem from './FilterItem'

type FilterCheckboxProps = {
  action:
    | BoundAction<RemoveAction, ActiveFilterPayload>
    | BoundAction<SelectAction, ActiveFilterPayload>
  active: boolean
  checked: boolean
  filter: TypeaheadFilter
  filterItem: PossibleFilterItem
  key: string
  value: ActiveFilterPayload['value']
}

const styles = {
  appsLabel: css(textStyles.paleGray2Normal14),
  container: css({
    marginBottom: 10,
  }),
  label: (active: boolean) =>
    css(
      textStyles.paleGray2Semibold14,
      {
        height: 20,
        maxWidth: 130,
        overflow: 'hidden',
        paddingRight: 4,
        textOverflow: 'ellipsis',
      },
      active && { fontWeight: 'bold' }
    ),
  link: css({
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
  linkContainer: css({
    alignItems: 'center',
    display: 'flex',
  }),
}

const FilterCheckbox = ({
  action,
  active,
  checked,
  filter,
  filterItem,
  value,
}: FilterCheckboxProps) => {
  const { apps, metadata, name } = filterItem
  const validMetadata = metadata && !Array.isArray(metadata)
  const displayName = validMetadata ? `${name}: ${metadata}` : name

  return (
    <div className={styles.container}>
      <Checkbox
        checked={checked}
        handleClick={() => {
          action({ filter, value })
        }}
        label={displayName}
        renderLabel={(label) => (
          <FilterItem
            appsCss={styles.appsLabel}
            labelCss={styles.label(active)}
            name={label}
            apps={apps}
          />
        )}
        solid={active}
      />
    </div>
  )
}

export default FilterCheckbox
