import { IntelligenceEntityType } from '../types'

const isFirm = (entity: IntelligenceEntityType) => {
  return (
    entity === IntelligenceEntityType.FirmAtDisposition ||
    entity === IntelligenceEntityType.CurrentFirm
  )
}

export default isFirm
