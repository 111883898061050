import { BarTooltipDatum } from '@nivo/bar'
import { useTheme, useValueFormatter } from '@nivo/core'
import { css } from 'emotion'
import React from 'react'

import { colors } from '../../../styles'
import { getEntityLabel } from '../utils'

type BarTooltipProps = BarTooltipDatum & {
  tooltipFormat: string
  xLegend: string
  yLegend: string
}

const styles = {
  hr: css({
    backgroundColor: colors.silver2,
    border: 0,
    height: 1,
  }),
  idContainer: css({
    alignItems: 'center',
    display: 'flex',
  }),
}

const BarTooltip: React.FC<BarTooltipProps> = ({
  color,
  id,
  indexValue,
  tooltipFormat,
  value,
  xLegend,
  yLegend,
}) => {
  const valueFormatter = useValueFormatter(tooltipFormat)
  const theme = useTheme()

  return (
    <div style={theme.tooltip.container}>
      <div>
        {xLegend}: <strong>{indexValue}</strong>
      </div>
      {id && (
        <div className={styles.idContainer}>
          <div
            style={{
              backgroundColor: color,
              height: 14,
              marginRight: 8,
              width: 14,
            }}
          />{' '}
          {getEntityLabel(id, 30)}
        </div>
      )}
      <hr className={styles.hr} />
      <div>
        {yLegend}: <strong>{valueFormatter(value)}</strong>
      </div>
    </div>
  )
}

export default BarTooltip
