import makeAction from '../../../redux/utils/makeAction'
import {
  ApplyAction,
  ApplyAllAction,
  ApplyListAction,
  ApplySomeAction,
  ClearAction,
  ClearAllAction,
  ClearSomeAction,
  ErrorAction,
  FetchAction,
  GetAvailableAction,
  HydrateAction,
  RemoveAction,
  RemoveListAction,
  RemoveObjectAction,
  SelectAction,
  SelectListAction,
  SelectObjectAction,
  SelectSomeAction,
  SelectTypeaheadAction,
  SetAction,
  SetAvailableAction,
  SetSomeAction,
  SwitchAction,
  ToggleAction,
  ToggleSomeAction,
  TriStateClearAction,
  TriStateExcludeAction,
  TriStateIncludeAction,
} from '../types'

const apply = makeAction<ApplyAction>('filter/APPLY')
const applyAll = makeAction<ApplyAllAction>('filter/APPLY_ALL')
const applyList = makeAction<ApplyListAction>('filter/APPLY_LIST')
const applySome = makeAction<ApplySomeAction>('filter/APPLY_SOME')
const clear = makeAction<ClearAction>('filter/CLEAR')
const clearAll = makeAction<ClearAllAction>('filter/CLEAR_ALL')
const clearSome = makeAction<ClearSomeAction>('filter/CLEAR_SOME')
const error = makeAction<ErrorAction>('filter/ERROR')
const fetch = makeAction<FetchAction>('filter/FETCH')
const getAvailable = makeAction<GetAvailableAction>('filter/GET_AVAILABLE')
const hydrate = makeAction<HydrateAction>('filter/HYDRATE')
const remove = makeAction<RemoveAction>('filter/REMOVE')
const removeObject = makeAction<RemoveObjectAction>('filter/REMOVE_OBJECT')
const removeList = makeAction<RemoveListAction>('filter/REMOVE_LIST')
const select = makeAction<SelectAction>('filter/SELECT')
const selectList = makeAction<SelectListAction>('filter/SELECT_LIST')
const selectObject = makeAction<SelectObjectAction>('filter/SELECT_OBJECT')
const selectSome = makeAction<SelectSomeAction>('filter/SELECT_SOME')
const selectTypeahead = makeAction<SelectTypeaheadAction>('filter/SELECT_TYPEAHEAD')
const set = makeAction<SetAction>('filter/SET')
const setAvailable = makeAction<SetAvailableAction>('filter/SET_AVAILABLE')
const setSome = makeAction<SetSomeAction>('filter/SET_SOME')
const switchAction = makeAction<SwitchAction>('filter/SWITCH')
const toggle = makeAction<ToggleAction>('filter/TOGGLE')
const toggleSome = makeAction<ToggleSomeAction>('filter/TOGGLE_SOME')
const tristateClear = makeAction<TriStateClearAction>('filter/TRISTATE_CLEAR')
const tristateExclude = makeAction<TriStateExcludeAction>('filter/TRISTATE_EXCLUDE')
const tristateInclude = makeAction<TriStateIncludeAction>('filter/TRISTATE_INCLUDE')

const actions = {
  apply,
  applyAll,
  applyList,
  applySome,
  clear,
  clearAll,
  clearSome,
  error,
  fetch,
  getAvailable,
  hydrate,
  remove,
  removeList,
  removeObject,
  select,
  selectList,
  selectObject,
  selectSome,
  selectTypeahead,
  set,
  setAvailable,
  setSome,
  switch: switchAction,
  toggle,
  toggleSome,
  tristateClear,
  tristateExclude,
  tristateInclude,
}

export default actions
