import { css } from 'emotion'
import { stringify } from 'qs'
import { zipObj } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'

import { useQueryStringParam } from '../hooks'
import { getQueryString } from '../modules/router'
import { makeGetAccess } from '../modules/session/selectors'
import { Access } from '../modules/session/types'
import { AppState } from '../redux'

type ConnectedLinkProps = {
  activeClassName?: string
  appendQueryString?: boolean | string[]
  canAccess?: Access
  children: React.ReactNode
  className?: string
  match?: Parameters<typeof useRouteMatch>[0]
  to: string
  tw?: boolean
}

const sort = (left: string, right: string) => left.localeCompare(right)

const ConnectedLink = ({
  activeClassName,
  appendQueryString = true,
  className,
  canAccess,
  children,
  match,
  to,
  tw = false,
}: ConnectedLinkProps) => {
  const qs = useSelector(getQueryString)
  const hasAccess = useSelector((state: AppState) =>
    canAccess ? makeGetAccess(canAccess)(state) : true
  )
  const queryKeys = useQueryStringParam(Array.isArray(appendQueryString) ? appendQueryString : [])
  const queryString = Array.isArray(appendQueryString)
    ? stringify(zipObj(appendQueryString, queryKeys), { addQueryPrefix: true, sort })
    : appendQueryString === true
    ? qs
    : ''
  const active = useRouteMatch(match ?? { exact: true, path: to })
  const props = active ? ({ 'aria-current': 'page' } as const) : {}

  if (!hasAccess) {
    return null
  }

  return (
    <Link
      className={
        tw
          ? `${className} ${active ? activeClassName : ''}`
          : css(className, active && activeClassName)
      }
      to={`${to}${queryString}`}
      {...props}
    >
      {children}
    </Link>
  )
}

export default ConnectedLink
