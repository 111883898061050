import { createSelector } from 'reselect'

import { getAccount } from '../../../selectors'

const getGroupState = createSelector(getAccount, (state) => state.group)

const getDetailsContent = createSelector(getGroupState, (state) => state.details)
const getSearchContent = createSelector(getGroupState, (state) => state.search)
const getTypeaheadContent = createSelector(getGroupState, (state) => state.typeahead)

export { getDetailsContent, getSearchContent, getTypeaheadContent }
