import makeAction from '../../../redux/utils/makeAction'
import {
  FetchMyPortfoliosAction,
  FetchTopAssigneesAction,
  FetchTopFirmsAction,
  NewsErrorAction,
  NewsFetchAction,
  NewsGetAction,
  NewsSetAction,
  SetMyPortfoliosAction,
  SetTopAssigneesAction,
  SetTopFirmsAction,
} from '../types'

const fetchMyPortfolios = makeAction<FetchMyPortfoliosAction>('home/FETCH_MY_PORTFOLIOS')
const fetchTopAssignees = makeAction<FetchTopAssigneesAction>('home/FETCH_TOP_ASSIGNEES')
const fetchTopFirms = makeAction<FetchTopFirmsAction>('home/FETCH_TOP_FIRMS')
const setMyPortfolios = makeAction<SetMyPortfoliosAction>('home/SET_MY_PORTFOLIOS')
const setTopAssignees = makeAction<SetTopAssigneesAction>('home/SET_TOP_ASSIGNEES')
const setTopFirms = makeAction<SetTopFirmsAction>('home/SET_TOP_FIRMS')

const news = {
  error: makeAction<NewsErrorAction>('home/news/ERROR'),
  fetch: makeAction<NewsFetchAction>('home/news/FETCH'),
  get: makeAction<NewsGetAction>('home/news/GET'),
  set: makeAction<NewsSetAction>('home/news/SET'),
}

export default {
  fetchMyPortfolios,
  fetchTopAssignees,
  fetchTopFirms,
  news,
  setMyPortfolios,
  setTopAssignees,
  setTopFirms,
}
