import { css } from 'emotion'
import { stringify } from 'qs'
import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../components/Button'
import { buttonStyles, colors, textStyles } from '../../../styles'
import { RenderProps } from './Omnisearch'

type OmnisearchByKeywordProps = Omit<RenderProps, 'input'>

const styles = {
  button: css(buttonStyles.primaryBlue, {
    marginBottom: '1em',
    'a&': {
      paddingTop: 6,
    },
  }),
  container: css({
    padding: '0 0 1em',
    width: '100%',
  }),
  content: css({
    alignItems: 'center',
    borderBottom: `1px solid ${colors.silver2}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  }),
  divider: css({
    backgroundColor: colors.white,
    color: colors.silver2,
    marginBottom: -12,
    padding: '0 10px',
  }),
  message: css(textStyles.darkNormal13, { fontSize: '14px', marginBottom: 16 }),
  options: css({
    '& > div': {
      width: 240,
    },
    display: 'flex',
  }),
  link: css({
    color: colors.azure,
  }),
}

const OmnisearchByExpertKeyword: React.FC<OmnisearchByKeywordProps> = ({ searchTerm: query }) => {
  const search = stringify({ query }, { addQueryPrefix: true })

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.message}>
          Search for CV's and Declarations by keyword. Type your keyword search in the search bar
          and click the button below.{' '}
          <a
            className={styles.link}
            href={
              'https://help.juristat.com/en/articles/6387230-advanced-operator-searching-with-juristat'
            }
            rel="noreferrer"
            target="_blank"
          >
            Click here
          </a>{' '}
          to see some example searches and learn more about available connectors and operators.
        </p>
        {query.trim() === '' ? (
          <Button disabled className={styles.button}>
            Keyword Search CV's and Declarations
          </Button>
        ) : (
          <Link className={styles.button} to={`/search/expert${search}`}>
            Keyword Search CV's and Declarations
          </Link>
        )}
        <span className={styles.divider}>OR</span>
      </div>
    </div>
  )
}

export default OmnisearchByExpertKeyword
