import * as React from 'react'

import { IconProps } from './types'

type SVGProps = {
  children?: React.ReactNode
  double?: boolean
  horizontal?: boolean
  solid?: boolean
  style?: React.CSSProperties
  title: string
  vertical?: boolean
  viewBox?: string
} & IconProps

const SVG = ({ children, className, title, ...props }: SVGProps) => {
  const {
    double,
    height = '1em',
    horizontal,
    preserveAspectRatio = 'xMidYMid meet',
    solid,
    vertical,
    viewBox = '0 0 36 36',
    width = '1em',
    ...events
  } = props

  return (
    <svg
      {...events}
      className={className}
      height={height}
      preserveAspectRatio={preserveAspectRatio}
      role="img"
      version="1.1"
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {title && <title>{title}</title>}
      {children}
    </svg>
  )
}

export default SVG
