import { select } from 'redux-saga/effects'

import { getAccessToken } from '../../auth/selectors'

export default function* getOptionsWithAuth(options: RequestInit = {}) {
  const accessToken = yield select(getAccessToken)
  if (!accessToken) {
    return options
  }
  const { headers: incomingHeaders, ...opts } = options
  const headers = new Headers(incomingHeaders ?? {})
  headers.append('Authorization', `Bearer ${accessToken}`)
  return {
    ...opts,
    credentials: 'include',
    headers,
  }
}
