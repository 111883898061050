import { prop, sortBy } from 'ramda'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import actions from '../actions'
import FilterContext from '../context/filterContext'
import { CheckListFilter, CheckListFilterCount } from '../types'
import CheckList from './CheckList'
import FilterContainer, { FilterContainerProps } from './FilterContainer'

type CheckListFilterContainerProps = Omit<FilterContainerProps, 'render' | 'filter'> & {
  filter: CheckListFilter
}

export const CheckListFilterContainer: React.FC<CheckListFilterContainerProps> = ({
  filter,
  title,
}) => {
  const dispatch = useDispatch()
  const { meta } = useContext(FilterContext)

  return (
    <FilterContainer
      filter={filter}
      render={({ available, selected }) => (
        <CheckList
          handleInput={(on, value) => {
            const action = on ? actions.select : actions.remove

            dispatch(action({ filter, value }, meta))
          }}
          items={sortBy(prop('value'), available ?? ([] as any)) as CheckListFilterCount[]}
          selected={selected as string[]}
        />
      )}
      title={title}
    />
  )
}

export default CheckListFilterContainer
