import download from 'downloadjs'
import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { DownloadFileAction } from '../types'

const getFilenameFromHeaders = (headers: Headers) => {
  if (headers.has('content-disposition')) {
    const header = headers.get('content-disposition')
    const matches = header!.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
    if (Array.isArray(matches)) {
      return matches[1].trim().replace(/[^a-zA-Z0-9.\-_]/g, '')
    }

    return undefined
  }

  return undefined
}

function* downloadFile(action: DownloadFileAction) {
  const { name, url } = action.payload!

  yield put(
    notificationActions.push(
      makeNotification({
        message: 'Your download will start momentarily...',
      })
    )
  )

  try {
    const response = yield call(api, url)

    if (!response.ok) {
      yield put(
        notificationActions.push(
          makeNotification({
            message: 'Download request failed',
            type: NotificationTypes.Error,
          })
        )
      )
      return
    }

    const blob = yield call([response, 'blob'])
    const filename = name || getFilenameFromHeaders(response.headers)

    yield fork(download, blob, filename)
  } catch {
    yield put(
      notificationActions.push(
        makeNotification({
          message: 'Download request failed',
          type: NotificationTypes.Error,
        })
      )
    )
  }
}

function* watchDownloadFile() {
  yield takeLatest(actions.downloadFile().type, downloadFile)
}

export { downloadFile, getFilenameFromHeaders }
export default watchDownloadFile
