import { call } from 'redux-saga/effects'

import { graphqlApi } from '../../api'
import { ActiveReducer } from '../../filter/types'
import * as query from '../queries/getSearchUid.graphql'
import { SortOrderVariables } from '../types'

type Params = {
  filters: Partial<Omit<ActiveReducer, 'appno'> & { appno: number[] }>
  searches: WeakObject
  similarTo: WeakObject
  sortOrders: SortOrderVariables[]
}

function* getSearchUid({ filters, searches, similarTo, sortOrders }: Params) {
  try {
    const response = yield call(graphqlApi, query, {
      filters,
      searches,
      similarTo,
      sortOrders,
    })
    if (response.ok) {
      const result = yield call([response, 'json'])
      const {
        data: {
          applicationSet: { uid },
        },
      } = result
      return uid || null
    }

    return null
  } catch (e) {
    return null
  }
}

export default getSearchUid
