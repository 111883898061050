const background = 1
const behind = -1
const controlBar = 100
const header = 97
const modal = 102
const modalBackground = 101
const navDrawer = 98
const notification = 101
const select = 100
const splash = 200
const tooltip = 102 // Needs to be above controlBar

const zIndex = {
  background,
  behind,
  controlBar,
  header,
  modal,
  modalBackground,
  navDrawer,
  notification,
  select,
  splash,
  tooltip,
}

export default zIndex
