import { IntelligenceEntityType } from '../../intelligence/types'
import usptoMeta from '../../intelligence/utils/usptoMeta'
import { FilterContextState, searchContext } from '../context/filterContext'
import { FilterReportType } from '../types'

const getFilterStateFromUrl = (pathname = ''): FilterContextState => {
  const routes = pathname.split('/')

  // section and ID are in different positions for /uspto
  const [, maybeReport = '', maybeIdOrSection] = routes

  if ([FilterReportType.Search, '', 'home', 'application'].includes(maybeReport)) {
    return searchContext
  }

  if (maybeReport === 'dashboards') {
    return {
      entity: IntelligenceEntityType.SearchSet,
      meta: {
        report: FilterReportType.Dashboard,
      },
    }
  }

  const entity = maybeReport as IntelligenceEntityType
  const report = FilterReportType.IntelligenceCompare

  if (maybeReport === IntelligenceEntityType.Uspto) {
    return {
      entity: IntelligenceEntityType.Uspto,
      meta: {
        ...usptoMeta,
        report,
      },
    }
  }

  return {
    entity,
    meta: {
      entity,
      id: maybeIdOrSection,
      report,
    },
  }
}

export default getFilterStateFromUrl
