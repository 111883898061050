/**
 * Format a string patentNumber into a string patentNumber with commas
 *
 * Works for design patents, plant patents, reissue patents
 *
 * Turns '9234567' into '9,234,567'
 * Turns '10234567' into '10,234,567'
 * Turns 'D123456' into 'D123,456'
 *
 * @param {string} patentNumber The patentNumber to format
 *
 * @return {string}
 */
const formatPatentNumber = (patentNumber: string | number): string =>
  `${patentNumber}`.replace(/^(\d+)(\d{6})$/, '$1,$2').replace(/^(.*)(\d{3})$/, '$1,$2')

export default formatPatentNumber
