import { pathOr } from 'ramda'
import { call, takeLatest } from 'redux-saga/effects'

import actions from '../actions'
import { IntercomTrackEventApi, LogIntercomEventAction } from '../types'

function* logIntercomEvent(action: LogIntercomEventAction) {
  const event = action.payload!
  const metadata = action.meta

  const intercom: IntercomTrackEventApi | null = pathOr(null, ['Intercom'], window)

  if (!intercom) {
    return
  }

  if (metadata) {
    yield call(intercom, 'trackEvent', event, metadata)
  } else {
    yield call(intercom, 'trackEvent', event)
  }
}

function* watchTrackIntercomEvent() {
  yield [takeLatest(actions.intercom.log().type, logIntercomEvent)]
}

export { logIntercomEvent }
export default watchTrackIntercomEvent
