import config from '@juristat/config'
import { useCallback } from 'react'

import { useAccessToken } from '../../auth'

// Internal hook and shouldn't be exposed outside api module
export default function useAppApi(json = true) {
  const accessToken = useAccessToken()

  return useCallback(
    async (
      url: string,
      { body, method }: { body?: string; method: string } = { body: undefined, method: 'get' }
    ) => {
      const accessHeader: Array<[string, string]> = accessToken
        ? [['authorization', `Bearer ${accessToken}`]]
        : []

      const jsonHeader: Array<[string, string]> = json ? [['content-type', 'application/json']] : []

      const headers = new Headers([['accept', 'application/json'], ...accessHeader, ...jsonHeader])

      try {
        const res = await fetch(`${config.appApiUrl}${url}`, {
          body,
          credentials: 'include',
          headers,
          method,
        })

        const type = res.headers.get('content-type')
        const isJson = type ? type.includes('json') : false
        const isPdf = type ? type.includes('pdf') : false
        const data = await (isJson ? res.json() : isPdf ? res.arrayBuffer() : res.text())

        if (!res.ok) {
          throw Error(res.headers.get('x-exposed-error') ?? data.error ?? res.statusText)
        }

        return data
      } catch (ex) {
        throw (ex as Error).message
      }
    },
    [accessToken, json]
  )
}
