import { useContext } from 'react'

import {
  AccessTokenContext,
  Auth0ClientContext,
  AuthorizeContext,
  GroupContext,
  ResetPasswordContext,
  UserContext,
} from './contexts'

export function useAccessToken() {
  const context = useContext(AccessTokenContext)

  if (context === undefined) {
    throw new Error('useAccessToken must be used within an Auth0ContextProvider')
  }

  return context
}

export function useAuth0Client() {
  const context = useContext(Auth0ClientContext)

  if (context === undefined) {
    throw new Error('useAuth0Client must be used within an Auth0ContextProvider')
  }

  return context
}

export function useAuthorize() {
  const context = useContext(AuthorizeContext)

  if (context === undefined) {
    throw new Error('useAuthorize must be used within an Auth0ContextProvider')
  }

  return context
}

export function useGroup() {
  const context = useContext(GroupContext)

  if (context === undefined) {
    throw new Error('useGroup must be used with an Auth0ContextProvider')
  }

  return context
}

export function useResetPassword() {
  const context = useContext(ResetPasswordContext)

  if (context === undefined) {
    throw new Error('useResetPassword must be used within an Auth0ContextProvider')
  }

  return context
}

export function useUser() {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser must be used with an Auth0ContextProvider')
  }

  return context
}
