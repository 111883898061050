import { find, path } from 'ramda'

import {
  Classification,
  GetEntitiesTitleResponse,
  KeyMetricsResponseFields,
  KeyMetricsState,
} from '../types'
import { FilterKeyMap, GroupsKeyMap, filterKeyMap, groupsKeyMap } from './classificationMaps'

type Item = { name: string }

const getTitle = (response: WeakObject, key: FilterKeyMap) => {
  switch (key) {
    case 'artUnit':
      return response.result?.group?.description ?? 'Not an active art unit'
    case 'techCenter':
      return response.result?.description ?? ''
    case 'cpcClass':
    case 'uspcClass':
      return response.result?.descriptionText ?? ''
    default:
      return ''
  }
}

const transformKeyMetrics = (
  metrics: KeyMetricsResponseFields[],
  titles: GetEntitiesTitleResponse,
  groupKey: GroupsKeyMap,
  filterKey: FilterKeyMap
) =>
  metrics.reduce((acc, metric) => {
    const id = String(path(['groups', groupKey], metric))
    const filters = titles.data.applicationSet.filterOptions[filterKey]
    const metricTitle = find((item) => item.name === id, filters as unknown as Item[])
    const title = metricTitle ? getTitle(metricTitle, filterKey) : ''
    const { rejections } = metric
    const alice =
      rejections.post.alice !== null && rejections.post.alice > 0
        ? (rejections.alice ?? 0) / rejections.post.alice
        : 0

    return {
      ...acc,
      [id]: {
        alice,
        allowance: metric.allowance_rate ?? 0,
        averageOfficeActions: metric.office_actions.to_allowance.average ?? 0,
        title,
      },
    }
  }, {})

const transformKeyMetricsResponse = (
  metrics: KeyMetricsResponseFields[],
  titles: GetEntitiesTitleResponse,
  classification: Classification
): KeyMetricsState => {
  const groupsKey = groupsKeyMap[classification]
  const filterKey = filterKeyMap[classification]

  return transformKeyMetrics(metrics, titles, groupsKey, filterKey)
}

export default transformKeyMetricsResponse
