import { Classification, ClassificationState, ClassifyResponse } from '../types'

const transformClassificationsResponse = (
  response: ClassifyResponse['classifications']
): ClassificationState['classifications'] => ({
  [Classification.ArtUnit]: response.art_unit,
  [Classification.Cpc]: response.cpc,
  [Classification.TechCenter]: response.tech_center,
  [Classification.Uspc]: response.uspc,
})

export default transformClassificationsResponse
