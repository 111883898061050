import {
  DataSource,
  GridItem,
  GridItemType,
  OrderDirection,
  SearchView,
  TopXForYTableGridItem,
} from '@juristat/common/types'
import { toPng } from 'html-to-image'
import {
  equals,
  find,
  includes,
  is,
  isEmpty,
  mergeAll,
  paths,
  pipe,
  range,
  reject,
  toUpper,
  without,
} from 'ramda'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import { usePrevious, useQueryStringParam, useSyncToQueryString } from '../../hooks'
import { AppState } from '../../redux'
import { daysToMonths } from '../../utils'
import { useMergedQueries, useQuery, useUserData } from '../api'
import { useDashboardDataSource } from '../dashboards/hooks'
import { useDemoModeValue } from '../demo'
import filterActions from '../filter/actions'
import FilterContext from '../filter/context/filterContext'
import { getPageSize } from '../pagination/selectors'
import { useSearchVariables, useSelectedColumnVariables } from '../search/hooks'
import * as getApplicationSetByColumns from '../search/queries/getApplicationSetByColumns.graphql'
import * as getApplicationSetByFields from '../search/queries/getApplicationSetByFields.graphql'
import * as getSearchUid from '../search/queries/getSearchUid.graphql'
import * as getUidDefinition from '../search/queries/getUidDefinition.graphql'
import { getSearchDataSourceById } from '../search/selectors'
import { TableGraphQLResult, TableResult } from '../search/types'
import { makeMapResponseToResult } from '../search/utils'
import PrintButton from './components/PrintButton'
import { usePrefetchKeyMetrics } from './components/TopXForYTable'
import * as entityDetailsQuery from './queries/getEntityDetails.graphql'
import * as entityDetailsWithUidQuery from './queries/getEntityDetailsWithUid.graphql'
import * as nameQuery from './queries/getEntityName.graphql'
import {
  EntityKeyMetrics,
  EntityTypeAndId,
  GetEntityDetailsResponse,
  GetEntityNameResponse,
  IntelligenceEntityType,
  MaybeUid,
} from './types'
import {
  getDefaultKeyMetricsBy,
  getDemoLabel,
  getEntityLabel,
  getIntrinsicFilters,
  isAssigneeOrFirm,
  isAttorney,
} from './utils'

type SortState = {
  direction: OrderDirection
  field: string | null
}

const transform = ({ applicationSet }: GetEntityDetailsResponse['data']) => {
  const [entityMetrics] = applicationSet?.metrics ?? []

  if (!entityMetrics) {
    return {
      allowanceRate: null,
      avgOas: null,
      disposed: null,
      filed: null,
      monthsToDisposition: null,
      pending: null,
    }
  }

  const {
    allowanceRate,
    officeActions,
    applicationCounts: { disposed, pending, total },
    timing,
  } = entityMetrics

  return {
    allowanceRate,
    avgOas: officeActions.toDisposition.average,
    disposed,
    filed: total,
    monthsToDisposition: daysToMonths(timing.daysToDisposition.average),
    pending,
  }
}

export function useEntityDetails({ entity, id, uid }: EntityTypeAndId & MaybeUid, enabled = true) {
  const dashboardDataSource = useDashboardDataSource()
  const dashboardPpair = dashboardDataSource === DataSource.PrivatePair
  const includeFilters = (dashboardDataSource && dashboardPpair) ?? !dashboardDataSource
  const dataSource = useSelector((state: AppState) =>
    getSearchDataSourceById(state, { searchId: String(id) })
  )
  const ppair =
    dashboardPpair ||
    (entity === IntelligenceEntityType.SearchSet && dataSource === DataSource.PrivatePair)
  const variables = uid
    ? { uid }
    : {
        filters: includeFilters ? getIntrinsicFilters({ entity, id }) : {},
      }

  const [data, { refetch, retry }] = useQuery<
    Omit<EntityKeyMetrics, 'id' | 'name'>,
    GetEntityDetailsResponse['data']
  >('entity-details', uid ? entityDetailsWithUidQuery : entityDetailsQuery, {
    enabled: enabled && id !== 0,
    ppair,
    transform,
    variables,
  })

  return [data, retry, refetch] as const
}

export function usePrefetchEntityDetails(props: EntityTypeAndId) {
  const [, , refetch] = useEntityDetails(props, false)

  return refetch
}

const formatEntityName =
  ({ demoMode, entity, id }: EntityTypeAndId & { demoMode: boolean }) =>
  (value?: string | null) => {
    switch (value) {
      case null:
      case undefined:
        return `${getEntityLabel(entity)} ${id}`
      default:
        switch (entity) {
          case IntelligenceEntityType.ArtUnit:
          case IntelligenceEntityType.Cpc:
          case IntelligenceEntityType.TechCenter:
          case IntelligenceEntityType.Uspc:
            return `${id}: ${value}`
          default:
            return demoMode ? getDemoLabel({ entity, id, name: value }) : value
        }
    }
  }

export function useEntityName({ entity, id }: EntityTypeAndId, enabled = true) {
  const demoMode = useDemoModeValue()
  const numericId = Number(id)
  const entityIsAttorney = isAttorney(entity)
  const dataSource = useSelector((state: AppState) =>
    getSearchDataSourceById(state, { searchId: String(id) })
  )
  const variables = {
    entityId: isNaN(numericId) ? 0 : numericId,
    entityName: String(id),
    isArtUnit: entity === IntelligenceEntityType.ArtUnit,
    isAssigneeOrFirm: isAssigneeOrFirm(entity),
    isAttorney: entityIsAttorney,
    isCpc: entity === IntelligenceEntityType.Cpc,
    isTechCenter: entity === IntelligenceEntityType.TechCenter,
    isUspc: entity === IntelligenceEntityType.Uspc,
  }
  const transform = useCallback(
    pipe(
      paths([
        ['artUnit', 'group', 'description'],
        ['attorney', 'name'],
        ['cpcClass', 'descriptionText'],
        ['entity', 'name'],
        ['techCenter', 'description'],
        ['uspcClass', 'descriptionText'],
      ]) as (item: unknown) => Array<string | null>,
      find(is(String)),
      formatEntityName({ demoMode, entity, id }),
      toUpper
    ),
    [demoMode, entity, id]
  )

  const [data, { refetch, setData }] = useQuery<string, GetEntityNameResponse['data']>(
    ['entity-name', { demoMode }],
    nameQuery,
    {
      enabled: false,
      ppair: entity === IntelligenceEntityType.SearchSet && dataSource === DataSource.PrivatePair,
      transform,
      variables,
    }
  )

  const previousEntity = usePrevious(entity)

  if (entity !== previousEntity) {
    switch (entity) {
      case IntelligenceEntityType.SearchSet:
        setData('SEARCH RESULTS')
        break
      case IntelligenceEntityType.Uspto:
        setData('USPTO')
        break
    }
  }

  if (
    data.matches('idle') &&
    !includes(entity, [IntelligenceEntityType.SearchSet, IntelligenceEntityType.Uspto]) &&
    ['0', 'NaN'].every((value) => value !== String(id)) &&
    enabled
  ) {
    refetch()
  }

  return [data, { refetch }] as const
}

export function usePrefetchEntityName(props: EntityTypeAndId) {
  const [, { refetch }] = useEntityName(props, false)

  return refetch
}

export function useEntityTypeAndId() {
  const { entity = IntelligenceEntityType.Uspto, id = '0' } = useParams<Record<string, string>>()

  return { entity, id } as EntityTypeAndId
}

const emptyVariables = {
  filters: {},
  searches: {},
  similarTo: {},
  sortOrders: [],
}

const defaultSortedBy = {
  direction: OrderDirection.Descending,
  field: null,
}

export function useIntelligenceUid() {
  const props = useEntityTypeAndId()
  const searchVariables = useSearchVariables()

  const dispatch = useDispatch()
  const { meta } = useContext(FilterContext)

  const initialLoad = useRef(true)
  const [sortedBy, setSortedBy] = useState<SortState>(defaultSortedBy)

  // Force is used from Saved Search to ensure we load data correctly
  const force = useQueryStringParam('force')

  const urlUid = useQueryStringParam<string>('uid')
  const [definition, definitionActions] = useQuery<
    { filters?: WeakObject; orderings?: WeakObject[] },
    { uid: { definition: string } }
  >(['intelligence-uid-definition', urlUid], getUidDefinition, {
    enabled: false,
    transform: ({ uid: { definition } }: { uid: { definition: string } }) =>
      definition === '{}' || !definition ? {} : JSON.parse(definition),
  })

  useEffect(() => {
    if (!equals(sortedBy, defaultSortedBy)) {
      initialLoad.current = false
    }
  }, [sortedBy])

  useEffect(() => {
    if (definition.matches({ success: 'stale' })) {
      const [ordering] = definition.context.data.orderings ?? []

      if (ordering) {
        setSortedBy({ direction: ordering.orderingDirection, field: ordering.orderingType })
      } else {
        initialLoad.current = false
      }

      const intrinsicFilters = getIntrinsicFilters(props)
      const { filters: uidFilters } = definition.context.data ?? {}
      const [intrinsicFiltersKey] = Object.keys(intrinsicFilters)
      const filters = reject(isEmpty, {
        ...uidFilters,
        [intrinsicFiltersKey]: without(
          (intrinsicFilters as WeakObject)?.[intrinsicFiltersKey] ?? [],
          uidFilters?.[intrinsicFiltersKey] ?? []
        ),
      })

      if (!equals(filters, searchVariables.filters)) {
        dispatch(filterActions.hydrate(filters, meta))
      }
    }
  }, [definition])

  const filters = mergeAll([
    definition.context.data?.filters ?? {},
    searchVariables.filters,
    getIntrinsicFilters(props),
  ])
  const sortOrders =
    sortedBy.field === null
      ? []
      : [{ orderingDirection: sortedBy.direction, orderingType: sortedBy.field }]

  const [uid, uidActions] = useQuery<string, { applicationSet: { uid: string } }>(
    ['intelligence-applications-uid', force ? urlUid : null],
    getSearchUid,
    {
      enabled: !initialLoad.current && definition.matches({ success: 'stale' }),
      transform: ({ applicationSet: { uid } }) => uid,
      variables: { ...emptyVariables, filters, sortOrders },
    }
  )

  useEffect(() => {
    uidActions.refetch({
      variables: {
        ...emptyVariables,
        filters: mergeAll([searchVariables.filters, getIntrinsicFilters(props)]),
        sortOrders,
      },
    })
  }, [JSON.stringify(searchVariables.filters)])

  // We either fetch definition of existing uid or set the definition to empty
  if (definition.matches('idle')) {
    if (urlUid) {
      definitionActions.refetch({ variables: { uid: urlUid } })
      uidActions.setData(urlUid)
    } else {
      definitionActions.setData({})
    }
  }

  // We intentionally only recalculate when uid machine changes
  // This prevents updating the url with a stale uid
  const syncUid = useMemo(
    () =>
      Boolean(urlUid) ||
      (uid.matches('success') && (!isEmpty(searchVariables.filters) || !isEmpty(sortOrders))),
    [uid.value]
  )

  useSyncToQueryString('uid', syncUid ? uid.context.data ?? urlUid : undefined)
  useSyncToQueryString('force', [])

  return [uid, sortedBy, setSortedBy] as const
}

export function useApplications() {
  const [uid, sortedBy, setSortedBy] = useIntelligenceUid()
  const [p = '1'] = useQueryStringParam<[string]>(['p'])

  const searchVariables = useSearchVariables()

  const page = Number(p ?? 1)

  const toggleSort = useCallback((payload: SortState) => setSortedBy(payload), [setSortedBy])

  const isApplicationView =
    useRouteMatch(['/uspto/applications', '/:entity/:id/applications']) !== null
  const isTableView =
    useRouteMatch(['/uspto/applications/table', '/:entity/:id/applications/table']) !== null

  const columns = useSelectedColumnVariables()

  const pageSize = useSelector(getPageSize)
  const requests = pageSize / 20
  const pageBase = (page - 1) * requests

  const [applications] = useMergedQueries<TableResult, { uid: { page: TableGraphQLResult[] } }>(
    ['intelligence-applications', searchVariables.dataSource, isTableView, uid.context.data],
    isTableView ? getApplicationSetByColumns : getApplicationSetByFields,
    range(1, requests + 1).map((index) => ({
      transform: ({ uid: { page } }) =>
        page.map(
          makeMapResponseToResult(
            searchVariables.dataSource,
            isTableView ? SearchView.Table : SearchView.Card
          ) as any
        ) as any,
      variables: { columns, pageNum: pageBase + index, pageSize: 20, uid: uid.context.data },
    })),
    {
      enabled: isApplicationView && uid.matches('success') && !isEmpty(columns),
      flat: true,
    }
  )

  return { applications, page, sortedBy, toggleSort, uid }
}

export function usePrefetchEntity(entity: EntityTypeAndId['entity'], id: EntityTypeAndId['id']) {
  const prefetchEntityDetails = usePrefetchEntityDetails({ entity, id })
  const prefetchEntityName = usePrefetchEntityName({ entity, id })

  const key = ['grid-items', entity, id, 'intelligence'].join('/')
  const [items, { refetch }] = useUserData<GridItem[]>(key, `/${key}`, { enabled: false })
  const options = useMemo(() => {
    const { by = getDefaultKeyMetricsBy(entity), selected = null } =
      items.context.data?.filter(
        (item): item is TopXForYTableGridItem => item.type === GridItemType.TopXForYTable
      )?.[0] ?? {}

    return { by, selected }
  }, [entity, items.context.data])
  const prefetchKeyMetrics = usePrefetchKeyMetrics({ entity, id, ...options })

  const prefetchEntity = useCallback(() => {
    refetch()
    prefetchEntityDetails()
    prefetchEntityName()
    prefetchKeyMetrics()
  }, [prefetchEntityDetails, prefetchEntityName, prefetchKeyMetrics, refetch])

  const [debouncedPrefetchEntity, cancel] = useDebouncedCallback(prefetchEntity, 300)

  return [debouncedPrefetchEntity, cancel]
}

const empty = [] as unknown as NodeListOf<HTMLElement>

async function waitForElementToBeRemoved(container: HTMLElement, selector: string) {
  return new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      const element = container.querySelector(selector)

      if (!element) {
        clearInterval(interval)

        resolve()
      }
    }, 5000)
  })
}

export function usePrintMode({
  chartSelector,
  dom,
  isIntelligenceView,
}: Omit<React.ComponentProps<typeof PrintButton>, 'pageTitle'>) {
  const isPrintServer = typeof useQueryStringParam('print') === 'string'
  const printingRef = useRef(false)
  const container = dom.current

  const charts = container?.querySelectorAll<HTMLElement>(chartSelector) ?? empty

  useEffect(() => {
    async function convert() {
      const elementsToHide = isIntelligenceView
        ? ['[data-component="PlatformChartContainer"]']
        : [
            '[data-component="InformationPopup"]',
            '[data-component="PaginationBar"]',
            'th[data-component="SimpleTable"] > button > svg',
          ]

      await Promise.all(
        Array.from(charts).map(async (chart) => {
          chart.style.margin = '0'

          elementsToHide.forEach((elementSelector) => {
            const element = chart.querySelector<HTMLElement>(elementSelector)

            if (element) {
              element.style.display = 'none'
            }
          })

          await waitForElementToBeRemoved(chart, '[data-testid$="-loader"]')

          const dataUrl = await toPng(chart)

          if (isIntelligenceView) {
            chart.childNodes.forEach((child) => {
              const childElement = child as HTMLElement

              childElement.style.display = 'none'
            })
          }

          const newChild = document.createElement('img')

          if (isIntelligenceView) {
            newChild.style.height = '100%'
          } else {
            newChild.style.width = '100%'
          }

          newChild.src = dataUrl

          // the examiner page uses flexbox to organize the charts into a grid, and this means that adding new content (images) to a row will push charts onto new lines or increase the height.
          // we don't want the grid's layout to change while we're converting the charts to images, so I'm keeping the images hidden until we've converted all the charts to images
          if (!isIntelligenceView) {
            newChild.style.display = 'none'
          }

          chart.append(newChild)
        })
      )

      if (container) {
        container.setAttribute('data-done', 'true')
        container.style.width = ''

        if (container.firstChild) {
          const firstChild = container.firstChild as HTMLElement

          firstChild.style.display = 'block'
        }
      }
    }

    if (isPrintServer && charts.length > 0 && !printingRef.current) {
      printingRef.current = true

      convert()
    }
  }, [charts, container, isIntelligenceView, isPrintServer])
}
