import { css } from 'emotion'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../components/Button'
import { AppState } from '../../../redux'
import { colors, makeTransition, textStyles } from '../../../styles'
import { useIntercomActions } from '../../analytics/hooks'
import actions from '../actions'
import FilterContext from '../context/filterContext'
import isApplyAllEnabled from '../selectors/isApplyAllEnabled'

const styles = {
  button: css(textStyles.paleGray2Bold12, {
    color: colors.cloudyBlue,
    padding: '10px 20px',
    textTransform: 'uppercase',
    transition: makeTransition('color'),
  }),
  buttonEnabled: css({
    color: colors.azure,
  }),
  container: css({
    backgroundColor: colors.white,
    borderTop: `1px solid ${colors.silver}`,
    padding: '1em 2em',
  }),
}

const ApplyAllButton: React.FC = () => {
  const { filterApplyClick } = useIntercomActions()
  const { meta } = useContext(FilterContext)
  const dispatch = useDispatch()
  const enabled = useSelector((state: AppState) => isApplyAllEnabled(state, meta))

  return (
    <Button
      active={enabled}
      className={css(styles.button, enabled && styles.buttonEnabled)}
      handleClick={() => {
        dispatch(actions.applyAll(undefined, meta))
        filterApplyClick()
      }}
    >
      Apply All
    </Button>
  )
}

export default ApplyAllButton
