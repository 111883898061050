import makeAction from '../../redux/utils/makeAction'
import { ConfirmDialogueAction, CreateDialogueAction, DismissDialogueAction } from './types'

const confirm = makeAction<ConfirmDialogueAction>('dialogue/CONFIRM')
const create = makeAction<CreateDialogueAction>('dialogue/CREATE')
const dismiss = makeAction<DismissDialogueAction>('dialogue/DISMISS')

export default {
  confirm,
  create,
  dismiss,
}
