import { Charts } from '@juristat/common/types'
import { equals } from 'ramda'
import React, { memo } from 'react'

import ExaminerDispositionsByRejectionCount from '../../examiner/components/ExaminerDispositionsByRejectionCount'
import ExaminerOfficeActionResponseWinRates from '../../examiner/components/ExaminerOfficeActionResponseWinRates'
import { DraggableGridItem, HiddenGridItem } from '../../grid-items'
import { useComparisonState } from '../../intelligence/components/ComparisonContext'
import { IntelligenceEntityType } from '../../intelligence/types'
import { ChartProps, ChartType } from '../types'
import AllowanceRate from './AllowanceRate'
import AllowanceRateByTechCenter from './AllowanceRateByTechCenter'
import ApplicationStatus from './ApplicationStatus'
import AverageApplicantResponseTime from './AverageApplicantResponseTime'
import AverageOfficeActions from './AverageOfficeActions'
import ClaimsChange from './ClaimsChange'
import DivisionOfWork from './DivisionOfWork'
import Extensions from './Extensions'
import Filings from './Filings'
import FilingsByTechCenter from './FilingsByTechCenter'
import IssuedPatents from './IssuedPatents'
import IssuedPatentsByTechCenter from './IssuedPatentsByTechCenter'
import MonthsToDisposition from './MonthsToDisposition'
import OfficeActionsByDispositionYear from './OfficeActionsByDispositionYear'
import RejectionBasesOverTime from './RejectionBasesOverTime'
import WordsPerClaim from './WordsPerClaim'

type ChartGridItemProps = ChartProps & {
  chart: Charts
}

const ChartGridItem: React.FC<ChartGridItemProps> = ({ chart, lookups, ...rest }) => {
  const comparisons = useComparisonState()
  const swapCharts = comparisons.length > 0
  const props = {
    ...rest,
    lookups: lookups.concat(
      ...comparisons.map(({ filterKey, name, ...comparison }) => ({
        ...comparison,
        filterKey:
          filterKey?.entity === IntelligenceEntityType.SearchSet
            ? { ...filterKey, id: lookups[0]?.id }
            : filterKey,
        label: filterKey ? `${name} for ${filterKey.name}` : name,
        type: ChartType.Compare,
      }))
    ),
  }

  if (lookups[0].id === 0) {
    return <HiddenGridItem itemKey={null} />
  }

  switch (chart) {
    case Charts.AllowanceRatesByTechCenter:
      return <AllowanceRateByTechCenter {...props} />
    case Charts.AllowanceRatesOverTime:
      return <AllowanceRate {...props} />
    case Charts.ApplicationStatus:
      return <ApplicationStatus {...props} />
    case Charts.DaysToDispositionOverTime:
      return <MonthsToDisposition {...props} />
    case Charts.DaysToFirstResponseOverTime:
      return <AverageApplicantResponseTime {...props} />
    case Charts.DispositionsByRejectionCount:
      return swapCharts ? (
        <HiddenGridItem itemKey={null} />
      ) : (
        <ExaminerDispositionsByRejectionCount {...props} />
      )
    case Charts.DivisionOfWork:
      return <DivisionOfWork {...props} />
    case Charts.ExtensionsOverTime:
      return <Extensions {...props} />
    case Charts.ExtensionsPercentage:
      return <Extensions asPercentage {...props} />
    case Charts.FilingsByTechCenterOverTime:
      return swapCharts ? <HiddenGridItem itemKey={null} /> : <FilingsByTechCenter {...props} />
    case Charts.FilingsByTechCenterPercentage:
      return swapCharts ? (
        <HiddenGridItem itemKey={null} />
      ) : (
        <FilingsByTechCenter asPercentage {...props} />
      )
    case Charts.IssuedPatentsByTechCenterOverTime:
      return swapCharts ? (
        <HiddenGridItem itemKey={null} />
      ) : (
        <IssuedPatentsByTechCenter {...props} />
      )
    case Charts.IssuedPatentsOverTime:
      return <IssuedPatents {...props} />
    case Charts.FilingsOverTime:
      return <Filings {...props} />
    case Charts.OfficeActionResponseWinRates:
      return <ExaminerOfficeActionResponseWinRates {...props} />
    case Charts.OfficeActionsByDispositionYear:
      return swapCharts ? (
        <AverageOfficeActions {...props} />
      ) : (
        <OfficeActionsByDispositionYear {...props} />
      )
    case Charts.RejectionBasesOverTime:
      return swapCharts ? <HiddenGridItem itemKey={null} /> : <RejectionBasesOverTime {...props} />
    case Charts.IndependentClaimsOverTime:
      return (
        <ClaimsChange
          chart={Charts.IndependentClaimsOverTime}
          title="Change in Independent Claims"
          {...props}
        />
      )
    case Charts.DependentClaimsOverTime:
      return (
        <ClaimsChange
          chart={Charts.DependentClaimsOverTime}
          title="Change in Dependent Claims"
          {...props}
        />
      )
    case Charts.WordsPerIndependentClaimOverTime:
      return (
        <WordsPerClaim
          chart={Charts.WordsPerIndependentClaimOverTime}
          title="Change in Words per Independent Claim"
          {...props}
        />
      )
    case Charts.WordsPerDependentClaimOverTime:
      return (
        <WordsPerClaim
          chart={Charts.WordsPerDependentClaimOverTime}
          title="Change in Words per Dependent Claim"
          {...props}
        />
      )
    default:
      return <HiddenGridItem itemKey={null} />
  }
}

const ChartGridItemWrapper: React.FC<ChartGridItemProps> = (props) => (
  <DraggableGridItem itemKey={null}>
    <ChartGridItem {...props} />
  </DraggableGridItem>
)

export default memo(ChartGridItemWrapper, equals)
