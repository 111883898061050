import { find, propEq } from 'ramda'
import { createSelector } from 'reselect'

import { HttpStatus } from '../../../../http/types'
import { getUuid } from '../../../../session/selectors'
import { getAccount, getIdFromPathname } from '../../../selectors'

const getUsers = createSelector(getAccount, (state) => state.users)

const getUsersResults = createSelector(getUsers, (state) => state.results)

const getUserDetails = createSelector(getUsers, (state) => state.details)

const getLoadedUserUuid = createSelector(getUserDetails, (state) =>
  state.type === HttpStatus.Success ? state.data.uuid : null
)

const getUserIdFromPathnameOrSession = createSelector(
  getIdFromPathname,
  getUuid,
  (pathnameUserId?: string, sessionUserId?: string) => pathnameUserId ?? sessionUserId
)

const makeGetUser = (userId: string) =>
  createSelector(getUsersResults, (state) =>
    state.type !== HttpStatus.Success ? null : find(propEq('uuid', userId), state.data) ?? null
  )

export {
  getLoadedUserUuid,
  getUserDetails,
  getUsersResults,
  getUserIdFromPathnameOrSession,
  makeGetUser,
}
