import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Check = (props: IconProps) => (
  <SVG title="check" viewBox="0 0 8 8" {...props}>
    <g transform="translate(-6, -6)" fillRule="nonzero">
      <path d="M11.5195527,6.95977569 C11.7780586,6.51953842 12.3424947,6.36822273 12.7765665,6.62202337 C13.2219051,6.87357449 13.3751289,7.44599548 13.1192141,7.88174279 C13.0249763,8.04597123 12.8600326,8.33324726 12.6477843,8.70261726 C12.2940346,9.31823809 11.939887,9.93386468 11.6087745,10.508488 C10.107783,13.1132653 10.0722382,13.1744451 9.99015896,13.2482834 C9.6190149,13.5982456 9.03461847,13.5811078 8.71006495,13.2349886 L6.75102671,11.4251917 C6.39991592,11.0536459 6.41923752,10.4624625 6.79087849,10.1213137 C7.16193808,9.77143121 7.7459736,9.78854999 8.0712554,10.1344791 L9.12584199,11.1071274 L11.5195527,6.95977569 Z M9.31232031,12.5131574 C9.33282752,12.4936646 9.37506493,12.4931329 9.40481076,12.517024 L9.3388552,12.4560909 C9.31689765,12.4972365 9.30711587,12.5178499 9.31232031,12.5131574 Z M9.41305121,12.5246369 C9.41044155,12.5218681 9.40768445,12.519332 9.40481076,12.517024 L9.41305121,12.5246369 Z" />
    </g>
  </SVG>
)

export default Check
