import { Key } from '@juristat/common/types'
import { isNilOrEmpty } from '@juristat/common/utils'
import {
  contains,
  filter,
  isEmpty,
  keys,
  map,
  mergeAll,
  reject,
  union,
  values,
  without,
} from 'ramda'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { InputHandle } from './Input'
import ScrollableOnHover from './ScrollableOnHover'
import Tag from './Tag'
import TextInput from './TextInput'

type TagInputProps = {
  addKeys: Key[]
  className?: string
  classNameInput?: string
  classNameTag?: string
  classNameTagContainer?: string
  dark?: boolean
  handleParseError: (data: string) => void
  onTagsChange: (newTags: string[], oldTags: string[]) => void
  parseInput?: (data: string) => string
  pasteSplit: (data: string) => string[]
  placeholder?: string
  tags: string[]
}

const makeHandleOnKeyDown =
  ({
    addKeys,
    handleParseError,
    onTagsChange,
    parseInput,
    setText,
    tags,
    text,
  }: Pick<
    TagInputProps,
    'addKeys' | 'handleParseError' | 'onTagsChange' | 'parseInput' | 'tags'
  > & {
    text: string
    setText: (text: string) => void
  }) =>
  (keyCode: number, event: React.KeyboardEvent) => {
    if (!contains(keyCode, addKeys)) {
      return
    }
    event.preventDefault()
    const parsed = parseInput ? parseInput(text) : text
    if (!isNilOrEmpty(parsed)) {
      onTagsChange(union(tags, [parsed]), tags)
    } else {
      handleParseError(text)
    }
    setText('')
  }

const makeHandleOnPaste =
  ({
    handleParseError,
    onTagsChange,
    parseInput,
    pasteSplit,
    setText,
    tags,
  }: Pick<
    TagInputProps,
    'handleParseError' | 'onTagsChange' | 'parseInput' | 'pasteSplit' | 'tags'
  > & { setText: (text: string) => void }) =>
  (pasteText: string) => {
    const candidates = pasteSplit(pasteText)
    const tagsMap = mergeAll(candidates.map((tag: string) => ({ [tag]: tag })))
    const parsedMap = map((value) => (parseInput ? parseInput(value) : value), tagsMap)
    const parseErrorValues = keys(filter(isNilOrEmpty, parsedMap))
    const newTags = values(reject(isNilOrEmpty, parsedMap))

    if (!isEmpty(parseErrorValues)) {
      handleParseError(parseErrorValues.join(', '))
    }

    if (!isEmpty(newTags)) {
      onTagsChange(union(tags, newTags), tags)
    }
    setText('')
  }

const TagInput = ({
  dark,
  className,
  classNameTagContainer,
  classNameInput,
  classNameTag,
  placeholder,
  ...props
}: TagInputProps) => {
  const textInputRef = useRef<InputHandle>(null)

  const [text, setText] = useState('')
  const [debouncedText] = useDebounce(text, 3000)
  const { tags } = props

  const handleOnKeyDown = useCallback(makeHandleOnKeyDown({ ...props, setText, text }), [
    ...values(props),
    setText,
    text,
  ])

  const handleOnPaste = useCallback(makeHandleOnPaste({ ...props, setText }), [
    ...values(props),
    setText,
  ])

  useEffect(() => {
    // Automatically add tag if we parse it successfully
    if (debouncedText && props.parseInput) {
      const parsed = props.parseInput(debouncedText)

      if (!isNilOrEmpty(parsed)) {
        props.onTagsChange(union(tags, [parsed]), tags)
        setText('')
      }
    }
  }, [debouncedText, props.onTagsChange, props.parseInput, tags])

  useEffect(() => {
    textInputRef.current?.focus()
  }, [])

  return (
    <div className={className}>
      <TextInput
        className={classNameInput}
        dark={dark}
        handleOnKeyDown={handleOnKeyDown}
        handleOnPaste={handleOnPaste}
        handleOnTextChange={setText}
        placeholder={placeholder}
        ref={textInputRef}
        text={text}
      />
      <ScrollableOnHover className={classNameTagContainer}>
        {tags.map((tag) => (
          <Tag
            className={classNameTag}
            handleOnRemoveClick={() => props.onTagsChange(without([tag], tags), tags)}
            key={tag}
            label={tag}
          />
        ))}
      </ScrollableOnHover>
    </div>
  )
}

export default TagInput
