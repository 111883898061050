import { isNil, mergeDeepRight, prop, union } from 'ramda'

import buildObjFromKeys from './buildObjFromKeys'

const recurseWithoutObj = (remove: unknown, from: unknown, keys: string[] = []): WeakObject => {
  if (typeof from !== 'object' || typeof remove !== 'object' || from === null || remove === null) {
    if (isNil(from) || from === remove) {
      return {}
    }
    return buildObjFromKeys(keys, from)
  }

  const allKeys = union(Object.keys(from), Object.keys(remove))

  return allKeys
    .map((newKey) =>
      recurseWithoutObj(
        prop(newKey, remove as Record<string, unknown>),
        prop(newKey, from as Record<string, unknown>),
        [...keys, newKey]
      )
    )
    .reduce((merged, next) => mergeDeepRight(merged, next))
}

/**
 * Returns a new object without keys/values in the first argument.
 * Works like Ramda's `without` but for objects
 * @param remove the object with keys/values to remove
 * @param from the base object whose keys are being removed
 * @example
 * withoutObj(
 *  { cat: { blue: 1, red: 2, yellow: 3 }},
 *  { cat: { blue: 1, red: 100, green: 1 }}
 * ) // { cat: { red: 100, green: 1 }}
 */
const withoutObj = (remove: WeakObject = {}, from: WeakObject = {}): WeakObject =>
  recurseWithoutObj(remove, from)

export default withoutObj
