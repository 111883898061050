import { DataSource } from '@juristat/common/types'
import { parse, stringify } from 'qs'
import { mergeAll, omit, tail, values } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useSyncToQueryString } from '../../../../../hooks'
import { colors } from '../../../../../styles'
import { daysToMonths } from '../../../../../utils'
import { useIntercomActions } from '../../../../analytics/hooks'
import { useMergedQueries } from '../../../../api'
import { HttpStatus } from '../../../../http/types'
import { useSearchVariables } from '../../../../search/hooks'
import * as getSearchUid from '../../../../search/queries/getSearchUid.graphql'
import * as getEntityDetailsWithUid from '../../../queries/getEntityDetailsWithUid.graphql'
import { Entity, EntityTypeAndId, IntelligenceEntityType } from '../../../types'
import { getIntrinsicFilters } from '../../../utils'
import { TopEntityKeyMetricsResponse } from '../../breakdown/types'
import { Comparison, UseComparisons } from '../types'
import useSyncComparisonsToFilters from './useSyncComparisonsToFilters'

const cache = new Map<string, Comparison[]>()

const usableColors = tail(colors.chartColors)

const getComparisonName = ({ filterKey, name }: Comparison | Omit<Comparison, 'color'>) =>
  (filterKey ? `${name} for ${filterKey.name}` : name).toUpperCase()

const addColor = (items: Array<Omit<Comparison, 'color'>>): Comparison[] =>
  items.map((item, index) => ({
    ...item,
    color: usableColors[index],
  }))

const getEntityKey = ({ entity, filterKey, id }: Entity) =>
  [entity, id, filterKey?.entity, filterKey?.id].join('_')

const getParamsKeys = (entities: Entity[]) => [...new Set(entities.map(getEntityKey))].sort()

const sort = (left: string, right: string) => left.localeCompare(right)

const entityTypeIndex = [
  IntelligenceEntityType.ArtUnit,
  IntelligenceEntityType.AssigneeAtDisposition,
  IntelligenceEntityType.Cpc,
  IntelligenceEntityType.CurrentAssignee,
  IntelligenceEntityType.CurrentFirm,
  IntelligenceEntityType.Examiner,
  IntelligenceEntityType.FirmAtDisposition,
  IntelligenceEntityType.SearchSet,
  IntelligenceEntityType.TechCenter,
  IntelligenceEntityType.Uspc,
  IntelligenceEntityType.Uspto,
  IntelligenceEntityType.AttorneyAtDisposition,
  IntelligenceEntityType.CurrentAttorney,
]

const emptyVariables = {
  filters: {},
  searches: {},
  similarTo: {},
  sortOrders: [],
}

const urlToEntity = ([index, id, name, ...rest]: string[]): Entity => ({
  entity: entityTypeIndex[Number(index)],
  ...(rest.length > 0 ? { filterKey: urlToEntity(rest) } : undefined),
  id,
  name: decodeURIComponent(name),
})

const useComparisons = (props: EntityTypeAndId) => {
  const location = useLocation()
  const searchVariables = useSearchVariables()
  const cacheKey = useMemo(() => location.pathname, [location.pathname])

  const { addEntityClick } = useIntercomActions()

  const [comparisons, setComparisons] = useState<Comparison[]>([])

  useEffect(() => {
    const getNeededComparisons = () => {
      const parsed = parse(location.search, { ignoreQueryPrefix: true })
      const urlComparisons = Array.isArray(parsed.comparisons)
        ? parsed.comparisons
        : typeof parsed.comparisons === 'string'
        ? (values(parse(parsed.comparisons, { comma: true })) as string[][]).map(urlToEntity)
        : []

      const stateComparisons = Array.isArray(location.state)
        ? location.state
        : (values(parse(location.state, { comma: true })) as string[][]).map(urlToEntity)

      const merged: Entity[] = [...urlComparisons, ...stateComparisons]
      const mergedKeys = [...new Set(getParamsKeys(merged))]

      return mergedKeys.length > 0
        ? cache.get(cacheKey) ??
            addColor(
              mergedKeys
                .map((key) => ({
                  ...merged.find((item) => getEntityKey(item) === key)!,
                  metrics: { type: HttpStatus.Fetching } as const,
                }))
                .sort((left, right) =>
                  getComparisonName(left).localeCompare(getComparisonName(right))
                )
            )
        : []
    }

    setComparisons(getNeededComparisons)
  }, [cacheKey, location.search, location.state])

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return
    }

    cache.set(cacheKey, comparisons)
  }, [cacheKey, comparisons])

  const addComparison = useCallback<UseComparisons['addComparison']>(
    (payload) => {
      const key = getEntityKey(payload)

      addEntityClick()
      setComparisons((state) =>
        addColor([
          ...state.filter((item) => getEntityKey(item) !== key),
          {
            ...payload,
            metrics: payload.metrics ?? { type: HttpStatus.Fetching },
          } as Comparison,
        ]).sort((left, right) => getComparisonName(left).localeCompare(getComparisonName(right)))
      )
    },
    [addEntityClick]
  )

  const removeComparison = useCallback((payload: Entity) => {
    const key = getEntityKey(payload)

    setComparisons((state) => state.filter((item) => getEntityKey(item) !== key))
  }, [])

  const entities: Entity[] = comparisons
    .sort((left, right) => getComparisonName(left).localeCompare(getComparisonName(right)))
    .map(omit(['color', 'metrics']))

  const params = stringify(
    entities.map(({ entity, filterKey, id, name }) =>
      [entityTypeIndex.indexOf(entity), id, encodeURIComponent(name)].concat(
        filterKey
          ? [
              entityTypeIndex.indexOf(filterKey.entity),
              filterKey.id,
              encodeURIComponent(filterKey.name),
            ]
          : []
      )
    ),
    { arrayFormat: 'comma', encode: false, sort }
  )

  useSyncToQueryString('comparisons', params || undefined, { withLocationState: true })

  useSyncComparisonsToFilters(props, comparisons)

  const neededComparisons = comparisons.filter(
    ({ metrics }) => metrics.type === HttpStatus.Fetching
  )

  const [uids, , send] = useMergedQueries<
    [string, Comparison],
    GraphQLResponse<{
      applicationSet: {
        uid: string
      }
    }>['data']
  >(
    'needed-comparison-uids',
    getSearchUid,
    neededComparisons.map((comparison) => {
      const { entity, filterKey, id } = comparison

      const variables =
        entity === IntelligenceEntityType.SearchSet
          ? searchVariables
          : filterKey?.entity === IntelligenceEntityType.SearchSet
          ? searchVariables
          : { ...emptyVariables, filters: searchVariables.filters }

      const filters = mergeAll([
        variables.filters,
        getIntrinsicFilters({ entity, id }),
        filterKey ? getIntrinsicFilters(filterKey) : {},
      ])

      return {
        transform: ({ applicationSet: { uid } }) => [uid, comparison],
        variables: { ...variables, filters },
      }
    }),
    { enabled: neededComparisons.length > 0 }
  )

  const [comparisonData] = useMergedQueries<Comparison, TopEntityKeyMetricsResponse['data']>(
    'needed-comparisons',
    getEntityDetailsWithUid,
    uids.context.data?.map(([uid, comparison]) => {
      return {
        ppair: searchVariables.dataSource === DataSource.PrivatePair,
        transform: ({
          applicationSet: {
            metrics: [entityMetrics],
          },
        }) => {
          if (!entityMetrics) {
            return {
              ...comparison,
              metrics: {
                data: {
                  allowanceRate: null,
                  avgOas: null,
                  disposed: null,
                  filed: null,
                  monthsToDisposition: null,
                  pending: null,
                },
                type: HttpStatus.Success,
              },
            }
          }

          const {
            allowanceRate,
            officeActions,
            applicationCounts: { disposed, pending, total },
            timing,
          } = entityMetrics

          return {
            ...comparison,
            metrics: {
              data: {
                allowanceRate: allowanceRate,
                avgOas: officeActions.toDisposition.average,
                disposed,
                filed: total,
                monthsToDisposition: daysToMonths(timing.daysToDisposition.average),
                pending,
              },
              type: HttpStatus.Success,
            },
          }
        },
        variables: { uid },
      }
    }) ?? [],
    { enabled: uids.matches('success') }
  )

  useEffect(() => {
    if (comparisonData.matches('success')) {
      // Clear uids
      send('RESET')
      comparisonData.context.data?.map(addComparison)
    }
  }, [comparisonData.value])

  useEffect(() => {
    setComparisons((state) =>
      state.map((item) => ({ ...item, metrics: { type: HttpStatus.Fetching } }))
    )
  }, [JSON.stringify(searchVariables)])

  return { addComparison, comparisons, removeComparison }
}

export default useComparisons
