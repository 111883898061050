import * as React from 'react'

import { scrollbar } from '../styles'

type ScrollerProps = {
  className?: string
  children?: React.ReactNode
  dark?: boolean
  contentContainerClassName?: string
  scrollableX?: boolean
}

const isMac = typeof navigator !== 'undefined' && navigator.platform.startsWith('Mac')

const styles = {
  container: (scrollableX = false, dark = false) =>
    `${dark ? scrollbar.dark : scrollbar.light} ${
      scrollableX ? 'overflow-x-auto' : 'overflow-x-hidden'
    } overflow-y-auto transition-[height] duration-300 ease-in-out ${
      !isMac ? 'focus:visible hover:visible' : 'invisible'
    }`,
  content: `[@supports_-ms-ime-align:_auto]:relative [@supports_-ms-ime-align:_auto]:top-0 [@media_screen_and_(-ms-high-contrast:_active),_(-ms-high-contrast: none)]:relative [@media_screen_and_(-ms-high-contrast:_active),_(-ms-high-contrast: none)]:top-0 ${
    isMac ? 'visible' : ''
  }`,
}

const ScrollableOnHover = React.forwardRef<HTMLDivElement, ScrollerProps>(
  ({ children, className, contentContainerClassName, dark, scrollableX }, ref) => (
    <div
      className={`${styles.container(scrollableX, dark)} ${className ?? ''}`}
      data-testid="scrollable-on-hover"
      ref={ref!}
    >
      <div className={`${styles.content} ${contentContainerClassName}`}>{children}</div>
    </div>
  )
)

export { ScrollerProps }
export default ScrollableOnHover
