import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Close = (props: IconProps) => (
  <SVG aria-label="close" title="close" {...props} viewBox="0 0 10 10">
    <path
      d="M8.706 1.293a1.016 1.016 0 0 0-1.428 0l-2.3 2.3-2.239-2.24a1.002 1.002 0 1 0-1.415 1.42l2.24 2.23-2.269 2.27a1.013 1.013 0 0 0 0 1.43c.394.39 1.034.39 1.428 0l2.268-2.27 2.238 2.24a1.002 1.002 0 1 0 1.416-1.42l-2.24-2.23 2.3-2.3c.396-.4.396-1.03 0-1.43z"
      fillRule="evenodd"
    />
  </SVG>
)

export default Close
