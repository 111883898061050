import config from '@juristat/config'
import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import searchHistoryActions from '../actions'
import { SearchHistoryDeleteFavoriteAction } from '../types'

function* deleteSearchHistoryItem({ payload }: SearchHistoryDeleteFavoriteAction) {
  const savedKey = /\/saved/.test(payload!)
    ? payload!
    : payload!.replace(/search_history/i, 'search_history/saved')

  const url = `${config.accountsUrl}/user/data/${savedKey}`
  const options = {
    credentials: 'include',
    method: 'delete',
    url,
  }
  const response = yield call(api, url, options)
  if (response.ok) {
    yield put(
      searchHistoryActions.deleted({
        [savedKey]: {
          userDataKey: savedKey,
        },
      })
    )
  } else {
    yield put(
      searchHistoryActions.error({
        message: `Could not delete history item with key ${payload}`,
        userDataKey: savedKey,
      })
    )
  }
}

function* watchDeleteSearchHistoryItem() {
  yield takeLatest([searchHistoryActions.deleteFavorite().type], deleteSearchHistoryItem)
}

export { deleteSearchHistoryItem }
export default watchDeleteSearchHistoryItem
