import { DataSource, SearchScope, SearchView } from '@juristat/common/types'
import config from '@juristat/config'
import { format } from 'date-fns'
import { contains, isEmpty, isNil, omit, path, pathOr, reject, values } from 'ramda'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import { FilterReportType } from '../../filter/types'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import paginationActions from '../../pagination/actions'
import { getResultsTotal } from '../../pagination/selectors'
import { getPathname } from '../../router/selectors'
import {
  getSearchDataSource,
  getSearchPhrase,
  getSearchScopes,
  getSearchType,
  getSearchUid,
  makeGetSearchIdFromPayloadOrState,
} from '../../search/selectors'
import getActiveFilters from '../../search/selectors/getActiveFilters'
import { SearchType } from '../../search/types'
import actions from '../actions'
import getActiveFilterMetadata from '../selectors/getActiveFilterMetadata'
import {
  SearchHistoryQueryParams,
  SearchHistorySaveFavoriteAction,
  SearchHistorySavedPayload,
  SearchHistoryUpdateFavoriteAction,
  SearchHistoryUpdateFavoritePayload,
} from '../types'
import { stringToViewType } from '../utils/viewTypeConversions'

type Actions = SearchHistorySaveFavoriteAction | SearchHistoryUpdateFavoriteAction

type Body = {
  dataSource: DataSource
  description?: string
  labels?: string[] | string
  metadata: Record<string, unknown>
  name?: string
  numResults: number
  query: SearchHistoryQueryParams
  searchScopes: SearchScope[]
  searchType: SearchType
  timestamp: number
  uid: string
  userDataKey: string
  viewType: SearchView
}

export const getDataKey = (date: Date, actionType: string) =>
  `search_history/${actionType === actions.saveFavorite().type ? 'saved/' : ''}${format(
    date,
    'yyyy/MM/dd/HH/mm/ss/SSS'
  )}`

export const checkEmpty = (val: Record<string, unknown>) => isEmpty(reject(isNil, values(val)))

export function* saveSearchHistoryItem(action: Actions) {
  const payloadSearchId = path(['meta', 'searchId'], action)
  const getSearchId = yield call(makeGetSearchIdFromPayloadOrState, 'filters')
  const searchId = yield select(getSearchId, { searchId: payloadSearchId })
  const payloadHasQuery = path(['payload', 'query'], action)
  // Check if query is empty
  const filters =
    (payloadHasQuery
      ? omit(['q'], action.payload!.query)
      : yield select(getActiveFilters, { report: FilterReportType.Search })) || {}
  const phrase = payloadHasQuery
    ? pathOr('', ['payload', 'query', 'q'], action)
    : yield select(getSearchPhrase, { searchId })
  const query = phrase !== '' ? { ...filters, q: phrase } : filters
  const numResults =
    path(['payload', 'numResults'], action) || (yield select(getResultsTotal, { searchId })) || 0
  if (checkEmpty(query) || !numResults) {
    return
  }

  // Get key and check if exists
  const now = new Date()
  const urlBase = `${config.accountsUrl}/user/data/`
  const key = contains(action.type, [actions.saveFavorite().type, paginationActions.set().type])
    ? getDataKey(now, action.type)
    : (action.payload! as SearchHistoryUpdateFavoritePayload).userDataKey
  try {
    const exists = yield call(api, `${urlBase}${key}`, {
      credentials: 'include',
      url: `${urlBase}${key}`,
    })

    // get the rest of the body
    const incomingProps =
      action.type === actions.updateFavorite().type
        ? omit(['numResults', 'query', 'userDataKey'], action.payload!)
        : {}
    const metadata = yield select(getActiveFilterMetadata, { report: FilterReportType.Search })
    // cors will fail if PATCH is not all uppercase (https://fetch.spec.whatwg.org/#methods)
    const method = exists.ok ? 'PATCH' : 'put'
    const pathname = yield select(getPathname)
    const qs = exists.ok ? '?strategy=shallowMerge' : ''
    const dataSource = (yield select(getSearchDataSource, { searchId })) || DataSource.PublicPair
    const searchScopes = (yield select(getSearchScopes, { searchId })) || [SearchScope.FullText]
    const searchType = (yield select(getSearchType, { searchId })) || SearchType.Keyword
    const uid = yield select(getSearchUid, { searchId })
    const viewType: SearchView = stringToViewType(pathname)

    // Make the call
    const body: Body = {
      ...incomingProps,
      dataSource,
      metadata,
      numResults,
      query,
      searchScopes,
      searchType,
      timestamp: now.getTime(),
      uid,
      userDataKey: key,
      viewType,
    }
    const url = `${config.accountsUrl}/user/data/${key}${qs}`
    const options = {
      body: JSON.stringify(body),
      credentials: 'include',
      method,
      url,
    }

    const response = yield call(api, url, options)
    if (response.ok) {
      const payload: SearchHistorySavedPayload = { [key]: body! }
      const name = incomingProps.name ? `"${incomingProps.name}" ` : ''
      if (
        action.type === actions.saveFavorite().type ||
        action.type === actions.updateFavorite().type
      ) {
        if (!path(['meta', 'hideNotification'], action)) {
          yield put(
            notificationActions.push(
              makeNotification({
                message: `Search ${name}has been ${exists.ok ? 'updated' : 'saved'}.`,
                type: NotificationTypes.Success,
              })
            )
          )
        }
      }
      yield put(actions.saved(payload))
    } else {
      yield put(
        notificationActions.push(
          makeNotification({
            message: 'Search failed to save.',
            type: NotificationTypes.Error,
          })
        )
      )
      yield put(actions.error({ message: 'Could not save history', userDataKey: key }))
    }
  } catch (e) {
    yield put(
      notificationActions.push(
        makeNotification({
          message: 'Search failed to save.',
          type: NotificationTypes.Error,
        })
      )
    )
    yield put(actions.error({ message: 'Could not save history', userDataKey: key }))
  }
}

function* watchSaveSearchHistoryItem() {
  yield takeLatest(
    [actions.saveFavorite().type, actions.updateFavorite().type, paginationActions.set().type],
    saveSearchHistoryItem
  )
}

export default watchSaveSearchHistoryItem
