import { formatAppno } from '@juristat/common/utils'
import { pipe } from 'ramda'
import { useCallback } from 'react'
import { useDebounce } from 'use-debounce'

import { formatPatentNumber } from '../../utils'
import { useQuery } from '../api'
import * as getExpertSet from '../expert/queries/getExpertSearch.graphql'
import * as getPatentSearch from '../patent/queries/getPatentSearch.graphql'
import * as getOmnisearch from './queries/getOmnisearch.graphql'
import * as getOmnisearchIdFilter from './queries/getOmnisearchIdFilter.graphql'
import { OmnisearchResult, OmnisearchType } from './types'

type ArtUnit = {
  group: {
    description: string | null
  } | null
}
type Description = { description: string | null }
type DescriptionText = { descriptionText: string | null }
type Id = { id: number }
type Main = { main: string }
type Name = { name: string }

type Arguments<T> = {
  enabled: (type: OmnisearchType) => boolean
  parseResult: (result: T) => Omit<OmnisearchResult, 'key' | 'type'>
  search: string | null
}

type Params = {
  enabledForType?: OmnisearchType
  isPrivatePair: boolean
  searchTerm: string | null
}

const join = (parts: Array<number | string | null>) => parts.filter(Boolean).join(': ')

function useOmnisearchResult<T>(
  type: OmnisearchType,
  { enabled, parseResult, search }: Arguments<T>
) {
  const [results] = useQuery<OmnisearchResult[], { omniSearch: Array<{ result: T }> }>(
    'omnisearch',
    getOmnisearch,
    {
      enabled: enabled(type),
      transform: ({ omniSearch }) =>
        omniSearch.map(({ result }) =>
          pipe(parseResult, (parsed) => ({ ...parsed, key: String(parsed.id), type }))(result)
        ),
      variables: { search, type },
    }
  )

  return results
}

function useOmnisearchIdFilter<T>(
  type: OmnisearchType,
  { enabled, parseResult, search }: Arguments<T>
) {
  const [results] = useQuery<OmnisearchResult[], { applicationSet: { page: T[] } }>(
    ['omnisearch', type],
    getOmnisearchIdFilter,
    {
      enabled: enabled(type),
      transform: ({ applicationSet: { page } }) =>
        page.map((result) =>
          pipe(parseResult, (parsed) => ({ ...parsed, key: String(parsed.id), type }))(result)
        ),
      variables: { parts: search?.split(' ') ?? [] },
    }
  )

  return results
}

function useOmniExpertSearchResult<T>(
  type: OmnisearchType,
  { enabled, parseResult, search }: Arguments<T>
) {
  const [results] = useQuery<OmnisearchResult[], { ptabExpertSet: { results: T[] } }>(
    'expert-search',
    getExpertSet,
    {
      enabled: enabled(type),
      transform: ({ ptabExpertSet }) =>
        ptabExpertSet?.results.map((result) =>
          pipe(parseResult, (parsed) => ({ ...parsed, key: String(parsed.id), type }))(result)
        ),
      variables: { search, type },
    }
  )
  return results
}

function useOmniPatentSearchResult<T>(
  type: OmnisearchType,
  { enabled, parseResult, search }: Arguments<T>
) {
  const [results] = useQuery<OmnisearchResult[], { applicationSet: { page: T[] } }>(
    'patent-search',
    getPatentSearch,
    {
      enabled: enabled(type),
      transform: ({ applicationSet }) =>
        applicationSet?.page.map((result) =>
          pipe(parseResult, (parsed) => ({ ...parsed, key: String(parsed.id), type }))(result)
        ),
      variables: { search: search?.replace(/,/g, '').split(' ') ?? [] },
    }
  )
  return results
}

export function useOmnisearchResults({ searchTerm, isPrivatePair, enabledForType }: Params) {
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500)
  const getIdAndName = useCallback(
    ({ id, name }: Id & Name) => ({ description: name, id: String(id) }),
    []
  )

  const commonArgs = {
    search: debouncedSearchTerm,
    parseResult: getIdAndName,
    enabled: (type: OmnisearchType) =>
      (!enabledForType || enabledForType === type) &&
      !isPrivatePair &&
      Boolean(debouncedSearchTerm),
  }

  const appnos = useOmnisearchIdFilter<{ appno: number }>(OmnisearchType.Application, {
    ...commonArgs,
    parseResult: ({ appno }) => ({
      description: formatAppno(appno),
      id: String(appno),
    }),
  })
  const artUnits = useOmnisearchResult<Id & ArtUnit>(OmnisearchType.ArtUnit, {
    ...commonArgs,
    parseResult: ({ group, id }) => ({
      description: join([id, group?.description ?? null]),
      id: String(id),
    }),
  })
  const attorneys = useOmnisearchResult<Id & Name>(OmnisearchType.Attorney, {
    ...commonArgs,
    enabled: (type: OmnisearchType) =>
      (!enabledForType || enabledForType === type) && Boolean(commonArgs.search),
  })
  const cpcs = useOmnisearchResult<DescriptionText & Name>(OmnisearchType.Cpc, {
    ...commonArgs,
    parseResult: ({ descriptionText, name }) => ({
      description: join([name, descriptionText]),
      id: name,
    }),
  })
  const examiners = useOmnisearchResult<Id & Name>(OmnisearchType.Examiner, commonArgs)
  const companies = useOmnisearchResult<Id & Name>(OmnisearchType.Company, commonArgs)
  const firms = useOmnisearchResult<Id & Name>(OmnisearchType.Firm, commonArgs)

  const patentExperts = useOmniPatentSearchResult<{ patent: { number: number } | null }>(
    OmnisearchType.PatentExpert,
    {
      ...commonArgs,
      parseResult: ({ patent }) => ({
        description: formatPatentNumber(patent?.number ?? ''),
        id: `pat,${patent?.number}`,
      }),
    }
  )

  const patents = useOmnisearchIdFilter<{ appno: number; patent: { number: number } | null }>(
    OmnisearchType.Patent,
    {
      ...commonArgs,
      parseResult: ({ appno, patent }) => {
        return {
          description: formatPatentNumber(patent?.number ?? ''),
          id: `pat,${appno}`,
        }
      },
    }
  )

  const techCenters = useOmnisearchResult<Description & Id>(OmnisearchType.TechCenter, {
    ...commonArgs,
    parseResult: ({ description, id }) => ({
      description: join([id, description]),
      id: String(id),
    }),
  })
  const uspcs = useOmnisearchResult<DescriptionText & Main>(OmnisearchType.Uspc, {
    ...commonArgs,
    parseResult: ({ descriptionText, main }) => ({
      description: join([main, descriptionText]),
      id: main,
    }),
  })
  const experts = useOmniExpertSearchResult<Id & Name>(OmnisearchType.Expert, commonArgs)

  return [
    {
      [OmnisearchType.Application]: appnos,
      [OmnisearchType.ArtUnit]: artUnits,
      [OmnisearchType.Attorney]: attorneys,
      [OmnisearchType.Company]: companies,
      [OmnisearchType.Cpc]: cpcs,
      [OmnisearchType.Examiner]: examiners,
      [OmnisearchType.Firm]: firms,
      [OmnisearchType.Patent]: patents,
      [OmnisearchType.PatentExpert]: patentExperts,
      [OmnisearchType.TechCenter]: techCenters,
      [OmnisearchType.Uspc]: uspcs,
      [OmnisearchType.Expert]: experts,
    },
    isPrivatePair ? !attorneys.matches('idle') : !artUnits.matches('idle'),
  ] as const
}
