import { SearchView } from '@juristat/common/types'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { SetReportPayload } from '../../../search/types'
import { SearchHistoryData } from '../../types'
import ListItem from './ListItem'

export default function SavedSearchesListItem({
  active = false,
  item,
  performSearch,
}: {
  active: boolean
  canUndo: boolean
  item: SearchHistoryData
  performSearch: (searchVariables: SetReportPayload) => void
}) {
  const history = useHistory()

  return (
    <ListItem
      active={active}
      handleClick={() => {
        if (item.viewType === SearchView.IntelligenceApplications) {
          const { entity, id } = item
          const uid = item.uid ? `&uid=${item.uid}` : ''

          return history.push(`/${entity}/${id}/applications/table?force=true${uid}`)
        }

        const {
          dataSource,
          query: { q, ...filters } = {},
          searchScope,
          searchScopes,
          searchType: type,
          uid,
          userDataKey,
        } = item

        performSearch({
          dataSource,
          filters,
          phrase: q,
          scopes: searchScopes ?? [searchScope!],
          type,
          uid,
          userDataKey,
        })
      }}
      label={item.name!}
      numResults={item.numResults}
    />
  )
}
