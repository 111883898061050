import React, { createContext, useContext } from 'react'

import useComparisons from '../modules/compare/hooks/useComparisons'
import { UseComparisons } from '../modules/compare/types'
import { EntityTypeAndId } from '../types'

type ComparisonProviderProps = EntityTypeAndId & { children: React.ReactNode }

const AddComparisonContext = createContext<UseComparisons['addComparison'] | undefined>(undefined)
const ComparisonStateContext = createContext<UseComparisons['comparisons'] | undefined>(undefined)
const EntityTypeAndIdContext = createContext<EntityTypeAndId | undefined>(undefined)
const RemoveComparisonContext = createContext<UseComparisons['removeComparison'] | undefined>(
  undefined
)

function useAddComparison() {
  const context = useContext(AddComparisonContext)

  if (context === undefined) {
    throw new Error('useAddComparison must be used within a ComparisonProvider')
  }

  return context
}

function useComparisonState() {
  const context = useContext(ComparisonStateContext)

  if (context === undefined) {
    throw new Error('useComparisonState must be used within a ComparisonProvider')
  }

  return context
}

function useEntityTypeAndId() {
  const context = useContext(EntityTypeAndIdContext)

  if (context === undefined) {
    throw new Error('useEntityTypeAndId must be used within a ComparisonProvider')
  }

  return context
}

function useRemoveComparison() {
  const context = useContext(RemoveComparisonContext)

  if (context === undefined) {
    throw new Error('useRemoveComparison must be used within a ComparisonProvider')
  }

  return context
}

function useComparison() {
  return [useComparisonState(), useAddComparison(), useRemoveComparison()] as const
}

const ComparisonProvider: React.FC<ComparisonProviderProps> = ({ children, ...props }) => {
  const { addComparison, comparisons, removeComparison } = useComparisons(props)

  return (
    <EntityTypeAndIdContext.Provider value={props}>
      <ComparisonStateContext.Provider value={comparisons}>
        <AddComparisonContext.Provider value={addComparison}>
          <RemoveComparisonContext.Provider value={removeComparison}>
            {children}
          </RemoveComparisonContext.Provider>
        </AddComparisonContext.Provider>
      </ComparisonStateContext.Provider>
    </EntityTypeAndIdContext.Provider>
  )
}

export {
  ComparisonProvider,
  useAddComparison,
  useComparison,
  useComparisonState,
  useEntityTypeAndId,
  useRemoveComparison,
}
