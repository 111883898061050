import { css } from 'emotion'
import * as React from 'react'

import Button from '../../../../components/Button'
import { colors, textStyles } from '../../../../styles'

type MonthProps = {
  handleClick: () => void
  month: string
  selected: boolean
}

const styles = {
  main: css(textStyles.paleGray2Normal14, {
    backgroundColor: colors.cloudyBlueAlpha12,
    borderRadius: 4,
    color: colors.charcoalGray2,
    fontSize: 12,
    height: 36,
    marginBottom: 4,
    width: 58,
  }),
  selected: css({
    background: colors.white,
    border: `0.5px solid ${colors.greenyBlue}`,
    color: colors.greenyBlue,
  }),
}

const Month = ({ handleClick, month, selected }: MonthProps) => (
  <Button className={css(styles.main, selected && styles.selected)} handleClick={handleClick}>
    {month}
  </Button>
)

export default Month
