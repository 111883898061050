import { combineReducers } from 'redux'

import { DateRangeState, SetDateRangeAction } from '../types'

const numMonths = (state: DateRangeState['numMonths'] = 3, action: SetDateRangeAction) => {
  switch (action.type) {
    case 'dateRange/SET':
      return action.payload
    default:
      return state
  }
}

const reducer = combineReducers({
  numMonths,
})

export default reducer
