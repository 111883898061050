import { css, cx } from 'emotion'
import React from 'react'

import { useMuuriActiveItemKey } from '../../hooks'
import { GridItemProps } from '../../types'
import BaseGridItem from './BaseGridItem'

const styles = {
  behind: css({
    zIndex: -1,
  }),
}

const DraggableGridItem: React.FC<GridItemProps & { children: React.ReactNode }> = (props) => {
  const activeItemKey = useMuuriActiveItemKey()

  return (
    <BaseGridItem
      className={cx({ [styles.behind]: Boolean(activeItemKey) && props.itemKey !== activeItemKey })}
      {...props}
    />
  )
}

export default DraggableGridItem
