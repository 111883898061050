import { CSSObject } from 'create-emotion'
import { css } from 'emotion'

import * as animations from './animations'
import * as breakpoints from './breakpoints'
import * as buttonStyles from './buttons'
import * as colors from './colors'
import * as colorsOld from './colorsOld'
import makeLink from './makeLink'
import makeRgba from './makeRgba'
import makeTransition from './makeTransition'
import * as scrollbar from './scrollbar'
import * as textStyles from './text'
import * as twTextStyles from './texttw'
import zIndex from './zIndex'

const emptyMessage = css({
  color: colorsOld.gray,
  letterSpacing: 2,
  textAlign: 'center',
})

const uppercase = css({ textTransform: 'uppercase' })

const pseudo = (selector: string) => (styles: CSSObject) => ({
  [selector]: styles,
})

const after = pseudo('&::after')
const before = pseudo('&::before')
const hover = pseudo('&:hover')
const firstChild = pseudo('&:first-child')
const focus = pseudo('&:focus')
const lastChild = pseudo('&:last-child')
const not = (selector: string, styles: CSSObject) => pseudo(`&:not(${selector})`)(styles)
const nthChild = (selector: string, styles: CSSObject) => pseudo(`&:nth-child(${selector})`)(styles)
const onlyChild = pseudo('&:only-child')
const visited = pseudo('&:visited')

const media = (mediaSelector: string, attributes: CSSObject): CSSObject => ({
  [`@media ${mediaSelector}`]: attributes,
})

const supports = (supportsSelector: string, attributes: CSSObject): CSSObject => ({
  [`@supports (${supportsSelector})`]: attributes,
})

const edgeOnly = (styles: CSSObject) => supports('-ms-ime-align: auto', styles)

const ie11only = (styles: CSSObject) =>
  media('screen and (-ms-high-contrast: active), (-ms-high-contrast: none)', styles)

export {
  after,
  animations,
  before,
  breakpoints,
  buttonStyles,
  colors,
  colorsOld,
  edgeOnly,
  emptyMessage,
  firstChild,
  focus,
  hover,
  ie11only,
  lastChild,
  makeLink,
  makeRgba,
  makeTransition,
  media,
  not,
  nthChild,
  onlyChild,
  scrollbar,
  textStyles,
  twTextStyles,
  uppercase,
  visited,
  zIndex,
}
