import config from '@juristat/config'
import { omit } from 'ramda'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import { HttpStatus } from '../../http/types'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { getAvailableRoles as getAvailableRolesSelector } from '../selectors'
import { AvailableRole } from '../types'

type AvailableRoleResponse = AvailableRole & { uri: string }

const errorMessage = 'Failed to get available roles'
const url = `${config.accountsUrl}/api/role`

function* getAvailableRoles() {
  try {
    const rolesInState = yield select(getAvailableRolesSelector)
    if (rolesInState.type === HttpStatus.Success) {
      return
    }

    const response = yield call(api, url)
    if (response.ok) {
      const rolesResponse: AvailableRoleResponse[] = yield call([response, 'json'])
      const roles = rolesResponse.map(omit(['uri']))
      yield put(actions.setAvailableRoles(roles))
    } else {
      throw new Error(errorMessage)
    }
  } catch (unknownErr) {
    const e = unknownErr as Error
    const message = e.message || errorMessage
    yield put(
      notificationActions.push(
        makeNotification({
          message,
          type: NotificationTypes.Error,
        })
      )
    )
    yield put(actions.getAvailableRolesError(message))
  }
}

export { errorMessage, getAvailableRoles, url }
export default function* watchGetAvailableRoles() {
  yield takeLatest(actions.getAvailableRoles().type, getAvailableRoles)
}
