import { contains, keys, uniq, without } from 'ramda'

import { HydrateFromUidAction } from '../../search/types'
import { AlmostActiveReducer, Filter, PossibleActions } from '../types'

const open = (
  state: Filter[] = [],
  action: PossibleActions | HydrateFromUidAction,
  selected: AlmostActiveReducer
): Filter[] => {
  switch (action.type) {
    case 'filter/HYDRATE':
      return uniq([...state, ...keys(action.payload ?? {}), ...keys(selected)]) as Filter[]
    case 'filter/TOGGLE': {
      const opened = contains(action.payload, state)

      return opened ? without([action.payload!], state) : [...state, action.payload!]
    }
    case 'filter/TOGGLE_SOME': {
      const { filters, open: opened } = action.payload!

      return opened ? [...state, ...filters] : without(filters, state)
    }
    case 'filter/SET':
      return uniq([...state, action.payload!.filter])
    case 'filter/SET_SOME':
      return uniq([...state, ...keys(action.payload!)]) as Filter[]
    case 'filter/SWITCH':
      return uniq([...state, action.payload!])
    default:
      return state
  }
}

export default open
