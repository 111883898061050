import { useMachine } from '@xstate/react'
import { useEffect, useMemo } from 'react'
import { assign, createMachine } from 'xstate'

type Context<T> = {
  previous: T
  next: T
}

type SetEvent<T> = { type: 'SET'; value: T }
type TypingEvent<T> = { text: T; type: 'TYPING' }

type Events<T> =
  | SetEvent<T>
  | TypingEvent<T>
  | { type: 'CANCEL' }
  | { type: 'EDIT' }
  | { type: 'SAVE' }

const editable = createMachine<Context<any>, Events<any>>({
  id: 'editable',
  initial: 'idle',
  states: {
    idle: {
      on: {
        EDIT: 'editing',
        SET: {
          actions: assign((_, { value }) => ({
            previous: value,
            next: value,
          })),
        },
      },
    },
    editing: {
      on: {
        CANCEL: {
          actions: assign({ next: (context) => context.previous }),
          target: 'idle',
        },
        TYPING: {
          actions: assign({ next: (_, { text }) => text }),
        },
        SAVE: [
          { cond: (context) => context.previous === context.next, target: 'idle' },
          {
            actions: assign({ previous: (context) => context.next }),
            target: 'saving',
          },
        ],
      },
    },
    saving: {
      after: {
        1: 'idle',
      },
    },
  },
})

export function useEditableText<T>(value: T | undefined) {
  const [state, send] = useMachine<Context<T | undefined>, Events<T>>(editable, {
    context: { previous: value, next: value },
  })

  useEffect(() => {
    if (value) {
      send({ type: 'SET', value })
    }
  }, [send, value])

  const actions = useMemo(
    () => ({
      cancel: () => {
        send('CANCEL')
      },
      edit: () => {
        send('EDIT')
      },
      save: () => {
        send('SAVE')
      },
      update: (text: T) => {
        send({ text, type: 'TYPING' })
      },
    }),
    [send]
  )

  return [state, actions] as const
}
