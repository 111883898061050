import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

type UpDownArrowProps = {
  activeFill?: string
  direction?: 'up' | 'down'
}

const UpDownArrow = ({
  activeFill = 'white',
  direction,
  ...props
}: IconProps & UpDownArrowProps) => (
  <SVG title="up-down-arrow" viewBox="0 0 15 12" {...props}>
    <path
      fill={direction === 'down' ? activeFill : undefined}
      d="M6.36963267,8.03232828 L4.17290432,10.260507 L4.17290432,4.54016454 C4.17290432,4.24145355 3.93462779,4 3.63984721,4 C3.34559969,4 3.1067901,4.24145355 3.1067901,4.54016454 L3.1067901,10.260507 L0.910061751,8.03232828 C0.701636421,7.82112394 0.36421127,7.82112394 0.156318998,8.03232828 C-0.0521063325,8.24299245 -0.0521063325,8.58599693 0.156318998,8.7966611 L3.17129001,11.8539924 C3.29922372,11.9836319 3.47406645,12.0214434 3.63984721,11.9890335 C3.80562797,12.0214434 3.9804707,11.9836319 4.10840441,11.8539924 L7.12337543,8.7966611 C7.33180076,8.58599693 7.33180076,8.24299245 7.12337543,8.03232828 C6.91601621,7.82112394 6.578058,7.82112394 6.36963267,8.03232828 L6.36963267,8.03232828 Z"
      id="Path"
    />
    <path
      fill={direction === 'up' ? activeFill : undefined}
      d="M11.2131595,0.149679449 C11.0852416,0.0200559933 10.9104205,-0.0204513366 10.7446602,0.00925403868 C10.5789,-0.0204513366 10.4040788,0.0200559933 10.276161,0.149679449 L7.26156282,3.20447223 C7.05316327,3.41565044 7.05316327,3.7580724 7.26156282,3.96817042 C7.46889639,4.17934864 7.8068128,4.17934864 8.01521236,3.96817042 L10.211669,1.74242766 L10.211669,7.45990227 C10.211669,7.75695602 10.4499161,8 10.7446602,8 C11.0388713,8 11.2776514,7.75695602 11.2776514,7.45990227 L11.2776514,1.74242766 L13.4741081,3.96817042 C13.6825076,4.17934864 14.019891,4.17934864 14.2277576,3.96817042 C14.4361572,3.7580724 14.4361572,3.41565044 14.2277576,3.20447223 L11.2131595,0.149679449 L11.2131595,0.149679449 Z"
      id="Path"
    />
  </SVG>
)

export default UpDownArrow
