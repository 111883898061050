import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import actions from '../actions'
import FilterContext from '../context/filterContext'
import {
  ApplicationTypeState,
  CheckListFilterCount,
  IncludeOrExclude,
  TristateFilter,
} from '../types'
import FilterContainer, { FilterContainerProps } from './FilterContainer'
import IncludeExcludeList from './IncludeExcludeList'

type TristateFilterContainerProps = Omit<FilterContainerProps, 'render' | 'filter'> & {
  filter: TristateFilter
}

const TristateFilterContainer: React.FC<TristateFilterContainerProps> = ({ filter, title }) => {
  const dispatch = useDispatch()
  const { meta } = useContext(FilterContext)

  return (
    <FilterContainer
      filter={filter}
      render={({ available = [], selected }) => (
        <IncludeExcludeList
          handleSelection={(value, includeOrExclude) => {
            switch (includeOrExclude) {
              case IncludeOrExclude.Exclude:
                return dispatch(actions.tristateExclude({ filter, value }, meta))
              case IncludeOrExclude.Include:
                return dispatch(actions.tristateInclude({ filter, value }, meta))
              case null:
              default:
                return dispatch(actions.tristateClear({ filter, value }, meta))
            }
          }}
          items={available as CheckListFilterCount[]}
          selected={selected as ApplicationTypeState}
        />
      )}
      title={title}
    />
  )
}

export default TristateFilterContainer
