import { Column } from '@juristat/common/types'

export type ApplicationColumn = {
  description: string
  enumName: Column
  label: string
  pinned?: boolean
  sortable: boolean
}

export { Column }
