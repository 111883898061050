import { path } from 'ramda'

import { ActiveReducer } from '../../filter/types'
import enforceMinimumDate from './enforceMinimumDate'

const defaultFilingDateFilter = (filters: ActiveReducer) => ({
  ...filters,
  filingDate: {
    ...filters.filingDate,
    start: enforceMinimumDate(path(['filingDate', 'start'], filters)),
  },
})

export default defaultFilingDateFilter
