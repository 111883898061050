import { format, isValid, parse, parseISO } from 'date-fns'
import { is, isEmpty, isNil } from 'ramda'

type Primitives = number | WeakObject | string | boolean
type Value = unknown | null | Primitives | Primitives[]
type Entries<T> = Array<
  {
    [K in keyof T]: [K, T[K]]
  }[keyof T]
>

/**
 * Get a typed version of `Object.entries`.
 */
export function entries<T extends object>(value: T): Entries<T> {
  return Object.entries(value) as any
}

/**
 * Format a numeric appno into a nice appno string
 *
 * Turns 13233266 into '13/233,266'
 *
 * @param {number} appno The appno to format
 *
 * @return {string}
 */
export function formatAppno(appno: number | string): string {
  return `${appno}`.replace(/^(\d{7})$/, '0$1').replace(/^(\d{2})(\d{3})(\d{3})$/, '$1/$2,$3')
}

export function formatClientName(name: string) {
  const parts = name.split(/( |_|-)/)

  if (parts.length === 1 && parts[0].length < 5) {
    return parts[0].toUpperCase()
  }

  return parts
    .map(startCase)
    .filter((word) => /[a-z]+/i.test(word))
    .join(' ')
    .replace(/\s(llp|ip|\(.*\))/i, (word) => word.toUpperCase())
}

export function formatDate(date: Date | string, specifier = 'yyyy-MM-dd'): string {
  return format(date instanceof Date ? date : parseDate(date), specifier)
}

export function isEmail(email = '') {
  /* eslint-disable-next-line no-useless-escape */
  return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    email
  )
}

export function isNilOrEmpty(value?: Value): boolean {
  return isNil(value) || isEmpty(value)
}

export function isUuid(uuid = '') {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid)
}

export function parseDate(date: string) {
  const parsed = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date())

  return isValid(parsed) ? parsed : parseISO(date)
}

export function pick<T extends Record<string, unknown>, K extends keyof T>(
  data: T,
  keys: K[]
): Pick<T, K> {
  return Object.fromEntries(
    Object.entries(data).filter(([key]) => keys.includes(key as K))
  ) as Pick<T, K>
}

/**
 * Takes a string and converts to start case.
 *
 * Turns `KISTNER, NEIL` into `Kistner, Neil`.
 *
 * @param {string} name The string to transform
 *
 * @return {string}
 */
export function startCase(name?: string | null): string {
  return (name ?? '').toLowerCase().replace(/((^| )(.))/g, (letter: string) => letter.toUpperCase())
}

export function upperFirst(str: string) {
  if (!is(String, str) || isEmpty(str)) {
    return str
  }

  return str.charAt(0).toUpperCase() + str.substring(1)
}

const uppercase = ['Ids', 'Npl', 'Ctnf', 'Ctfr']

export function getTaskTypeLabel(taskType: string) {
  return taskType
    .replace(/^([\w])/, (_, letter) => letter.toUpperCase())
    .replace(/([A-Z]|\d+)/g, ' $1')
    .split(' ')
    .filter((segment) => segment !== 'Document')
    .map((segment) => {
      switch (true) {
        case uppercase.includes(segment):
          return segment.toUpperCase()
        case segment === 'Cited':
          return 'for Cited'
        default:
          return segment
      }
    })
    .join(' ')
    .trim()
}

export function formatName(
  { first, last }: { first: string; last: string } = { first: '', last: '' }
) {
  return first ? `${first} ${last}` : undefined
}

export function formatGroupName(name: string | undefined) {
  return name ? startCase(name.replace(/-/g, ' ')) : undefined
}
