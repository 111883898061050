import { mergeAll } from 'ramda'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { useMergedQueries } from '../../../../api'
import filterActions from '../../../../filter/actions'
import FilterContext from '../../../../filter/context/filterContext'
import { useSearchVariables } from '../../../../search/hooks'
import * as getSearchUid from '../../../../search/queries/getSearchUid.graphql'
import { EntityTypeAndId, IntelligenceEntityType } from '../../../types'
import { getIntrinsicFilters } from '../../../utils'
import { Comparison } from '../types'

const emptyVariables = {
  filters: {},
  searches: {},
  similarTo: {},
  sortOrders: [],
}

const useSyncComparisonsToFilters = (props: EntityTypeAndId, comparisons: Comparison[]) => {
  const dispatch = useDispatch()
  const filterMeta = useContext(FilterContext)
  const searchVariables = useSearchVariables()
  const needed = [props, ...comparisons] as Comparison[]
  const routeMatch = useRouteMatch([
    '/search/intelligence',
    '/uspto/applications',
    '/uspto/intelligence',
    '/:entity/:id/applications',
    '/:entity/:id/intelligence',
  ])
  const { dataSource } = searchVariables

  const [uids] = useMergedQueries<
    string,
    GraphQLResponse<{
      applicationSet: {
        uid: string
      }
    }>['data']
  >(
    'comparison-filters-uids',
    getSearchUid,
    needed.map((comparison) => {
      const { entity, filterKey, id } = comparison

      const variables =
        entity === IntelligenceEntityType.SearchSet
          ? searchVariables
          : filterKey?.entity === IntelligenceEntityType.SearchSet
          ? searchVariables
          : { ...emptyVariables, filters: searchVariables.filters }

      const filters = mergeAll([
        variables.filters,
        getIntrinsicFilters({ entity, id }),
        filterKey ? getIntrinsicFilters(filterKey) : {},
      ])

      return {
        transform: ({ applicationSet: { uid } }) => uid,
        variables: { ...variables, filters },
      }
    }),
    { enabled: routeMatch !== null }
  )

  useEffect(() => {
    if (Array.isArray(uids.context.data)) {
      dispatch(
        filterActions.getAvailable(
          {
            dataSource,
            filters: { applicationSet: { include: uids.context.data } },
          },
          filterMeta.meta
        )
      )
    }
  }, [dataSource, dispatch, filterMeta.meta, uids.context.data])
}

export default useSyncComparisonsToFilters
