import { useEffect } from 'react'

const useTitle = (title: string) => {
  useEffect(() => {
    if (document) {
      document.title = [title, 'Juristat'].filter(Boolean).join(' :: ')
    }
  }, [title])
}

export default useTitle
