import React, { useMemo } from 'react'
import { animated, useTransition } from 'react-spring'

import { useHighlight } from '../hooks'

type WorkflowAutomationHighlightableCellProps = {
  children: JSX.Element
  column: string
  id: string
}

const WorkflowAutomationHighlightableCell = ({
  children,
  column,
  id,
}: WorkflowAutomationHighlightableCellProps) => {
  const highlight = useHighlight()
  const isHighlighting = useMemo(() => {
    if (!highlight) {
      return false
    }

    // Add type is supported at the row level
    if (highlight.type === 'add') {
      return false
    }

    if (highlight.type === 'updates') {
      return highlight.data.some(({ id: highlightId, ...data }) => {
        const [key] = Object.keys(data)

        return column === key && highlightId === id
      })
    }

    const [key] = Object.keys(highlight.data)

    return column === key && highlight.id === id
  }, [column, highlight, id])

  const transition = useTransition(isHighlighting, {
    from: { backgroundColor: 'transparent' },
    enter: { backgroundColor: '#fafcc2' },
    leave: { backgroundColor: 'transparent' },
  })

  return (
    <div className="relative flex h-full w-full">
      {transition(({ backgroundColor }, item) => (
        <animated.div
          style={{
            backgroundColor: backgroundColor.to((color) =>
              item || isHighlighting ? color : 'transparent'
            ),
            position: 'absolute',
          }}
          className="flex h-full w-full items-center"
        >
          {children}
        </animated.div>
      ))}
    </div>
  )
}

export default WorkflowAutomationHighlightableCell
