import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const DragHandle = (props: IconProps) => (
  <SVG title="drag-handle" {...props}>
    <circle cx="15" cy="12" r="1.5" />
    <circle cx="15" cy="24" r="1.5" />
    <circle cx="21" cy="12" r="1.5" />
    <circle cx="21" cy="24" r="1.5" />
    <circle cx="21" cy="18" r="1.5" />
    <circle cx="15" cy="18" r="1.5" />
    <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
  </SVG>
)

export default DragHandle
