import { useMachine } from '@xstate/react'
import { equals } from 'ramda'
import React, { Dispatch, useCallback, useEffect } from 'react'
import { ActorRefFrom, State, assign, createMachine, spawn } from 'xstate'

import ActionButton from '../../../components/ActionButton'
import { ModalSize } from '../../../components/Modal'
import RadioGroup from '../../../components/RadioGroup'
import { twTextStyles } from '../../../styles'
import { noop } from '../../../utils'
import { useMutation, useQuery } from '../../api'
import { TimesCircle } from '../../icons'
import { useNotification } from '../../notification'
import { useUsername } from '../../session/hooks'
import {
  WorkflowAutomationModal,
  WorkflowAutomationRecipientsField,
  WorkflowAutomationTextInput,
  recipientMachine,
} from '../../workflow-automation'
import * as getThirdPartyAlerts from '../queries/getThirdPartyAlerts.graphql'
import * as setThirdPartyAlerts from '../queries/setThirdPartyAlerts.graphql'

type ManageRecipientsModalProps = {
  items: number[]
  show: boolean
  toggle: Dispatch<void>
}

type ModalEvent =
  | { data: string[]; type: 'SET_DATA' }
  | { type: 'CLOSE' | 'INVALID' | 'OPEN' | 'VALID' }

type ModalContext = {
  data: string[]
}

type RecipientsContext = {
  all: string[]
  any: string[]
  initial: string[]
  recipient: ActorRefFrom<typeof recipientMachine>
}

type RecipientsEvent =
  | { type: 'TOGGLE' }
  | ({ type: 'SET' } & Partial<Omit<RecipientsContext, 'recipient'>>)
  | { recipients: string[]; type: 'ADD_RECIPIENTS' }
  | { recipient: string; type: 'REMOVE_RECIPIENT' }

type ThirdPartyAlert = {
  appno: number
  thirdPartyEmails: string[]
}

const styles = {
  info: `${twTextStyles.placeholderNormal13} !italic leading-[1.5] whitespace-normal`,
  label: `${twTextStyles.montserratDarkBold16} [&_>_span]:!font-normal !text-blue-gray !text-s`,
  modal: '[&_>_div]:left-[27.5%] [&_>_div]:fixed [&_>_div]:top-[20%]',
  pill: `${twTextStyles.montserratDarkBold16} [&_>_svg]:cursor-pointer [&_>_svg]:fill-current items-center bg-[#d5edff] rounded text-[#0496ff] flex text-xs justify-between p-[10px] w-full`,
  pills: 'flex flex-wrap gap-[5px]',
  section: '[&:not(:first-of-type)]:mt-5 [&_>_div]:mt-[10px]',
}

const emailPattern = "^[A-Za-z0-9._%+'-]+@[A-Za-z0-9._-]+\\.[A-Za-z]{2,}$"

const modalMachine = createMachine<ModalContext, ModalEvent>({
  id: 'modal',
  initial: 'closed',
  states: {
    closed: {
      on: {
        OPEN: 'open',
      },
    },
    open: {
      initial: 'invalid',
      on: {
        CLOSE: {
          actions: assign({
            data: (_) => [],
          }),
          target: 'closed',
        },
      },
      states: {
        invalid: {
          on: { VALID: 'valid' },
        },
        valid: {
          on: { INVALID: 'invalid' },
        },
        validating: {
          always: [
            {
              target: 'valid',
              cond: (context) =>
                context.data.every((recipient) => new RegExp(emailPattern).test(recipient)),
            },
            'invalid',
          ],
        },
      },
    },
  },
  on: {
    SET_DATA: {
      actions: assign((_, { data }) => ({ data })),
      target: 'open.validating',
    },
  },
})

const recipientsMachine = createMachine<RecipientsContext, RecipientsEvent>({
  id: 'recipients',
  initial: 'any',
  states: {
    any: {
      entry: assign({
        recipient: (_) => spawn(recipientMachine),
      }),
      on: { TOGGLE: 'all' },
    },
    all: {
      on: { TOGGLE: 'any' },
    },
  },
  on: {
    ADD_RECIPIENTS: {
      actions: assign(({ all, any }, { recipients }) => ({
        all: [...all, ...recipients],
        any: [...any, ...recipients],
      })),
    },
    REMOVE_RECIPIENT: {
      actions: assign(({ all, any }, { recipient }) => ({
        all: all.filter((item) => item !== recipient),
        any: any.filter((item) => item !== recipient),
      })),
    },
    SET: {
      actions: assign((_, { type, ...data }) => data),
    },
  },
})

function useModal(
  show: boolean,
  recipients: State<
    RecipientsContext,
    RecipientsEvent,
    any,
    {
      value: any
      context: RecipientsContext
    }
  >
) {
  const [state, send] = useMachine(modalMachine, {
    context: {
      data: [],
    },
  })

  useEffect(() => {
    const data = recipients.context[recipients.value as 'all' | 'any']

    if (state.matches('open')) {
      if (!equals(state.context.data, data)) {
        send({ type: 'SET_DATA', data })
      }

      if (equals(recipients.context.initial, state.context.data)) {
        send('INVALID')
      } else {
        send('VALID')
      }

      if (data.length === 0 && recipients.context.initial.length === 0) {
        send('INVALID')
      }
    }
  }, [recipients.context, recipients.value, send, state])

  useEffect(() => {
    if (show) {
      send('OPEN')
    }
  }, [send, show])

  const close = useCallback(() => send('CLOSE'), [send])

  return [state, { close }] as const
}

const ManageRecipientsModal = ({ items, show, toggle }: ManageRecipientsModalProps) => {
  const username = useUsername()

  const { addErrorNotification, addSuccessNotification } = useNotification()

  const [recipients, send] = useMachine(recipientsMachine, {
    context: { any: [], all: [], initial: [] },
  })
  const [modal, { close }] = useModal(show, recipients)

  const [alerts] = useQuery<ThirdPartyAlert[], { alertSubscriptions: ThirdPartyAlert[] }>(
    ['third-party-alerts', items],
    getThirdPartyAlerts,
    {
      enabled: modal.matches('open'),
      transform: ({ alertSubscriptions }) => alertSubscriptions,
      variables: { appnos: items },
    }
  )

  useEffect(() => {
    if (show && alerts.matches({ success: 'stale' })) {
      const emails = items.map(
        (appno) => alerts.context.data.find((item) => item.appno === appno)?.thirdPartyEmails
      )
      const uniques = [...new Set(emails.flat())]

      const all = uniques.filter((email): email is string =>
        emails.every((recipient) => recipient?.includes(email ?? '') ?? false)
      )
      const any = uniques.filter((email): email is string =>
        emails.some((recipient) => recipient?.includes(email ?? '') ?? false)
      )

      send({
        type: 'SET',
        all,
        any,
        initial: uniques.filter((item): item is string => Boolean(item)),
      })
    }
  }, [alerts, items, send, show])

  const unsubscribes = recipients.context.initial.filter(
    (recipient) => !modal.context.data.includes(recipient)
  )

  const [mutate, mutation] = useMutation(
    setThirdPartyAlerts,
    {
      appnos: items,
      includeSubscribes: modal.context.data.length > 0,
      includeUnsubscribes: unsubscribes.length > 0,
      subscribes: modal.context.data,
      unsubscribes,
    },
    { refetchQueries: ['alerts', ['third-party-alerts', items]] }
  )

  const onClose = useCallback(() => {
    close()
    toggle()
  }, [close, toggle])

  useEffect(() => {
    if (mutation.matches('success')) {
      onClose()
      addSuccessNotification('Additional alert recipients have been saved successfully!')
    }

    if (mutation.matches('failure')) {
      addErrorNotification('An error occurred while saving the additional alert recipients.')
    }
  }, [addErrorNotification, addSuccessNotification, mutation, onClose])

  const onSubmit = useCallback(() => {
    mutate()
  }, [mutate])

  return (
    <span className={styles.modal}>
      <WorkflowAutomationModal
        leftPane={
          <>
            <div className={styles.section}>
              <label className={styles.label}>Primary Recipient</label>
              <span>
                <WorkflowAutomationTextInput
                  background="blue"
                  disabled
                  handleOnTextChange={noop}
                  placeholder="Primary Recipient"
                  value={username ?? ''}
                />
              </span>
            </div>
            <WorkflowAutomationRecipientsField actor={recipients.context.recipient} />
            <ActionButton
              action={() => send({ type: 'SET', all: [], any: [] })}
              className="absolute -bottom-[25px]"
              disabled={modal.context.data.length === 0}
              tertiary
              text="Remove all"
            />
          </>
        }
        mutation={mutation}
        onClose={onClose}
        onSubmit={onSubmit}
        rightPane={
          <>
            {items.length > 1 && !equals(recipients.context.all, recipients.context.any) ? (
              <div className={styles.section}>
                <label className={styles.label}>Show recipients for [any/all] selected apps:</label>
                <RadioGroup
                  horizontal
                  options={[
                    { label: 'All', value: 'all' },
                    { label: 'Any', value: 'any' },
                  ]}
                  selectedOption={recipients.value}
                  setSelectedOption={() => send('TOGGLE')}
                />
              </div>
            ) : null}
            <div className={styles.section}>
              <label className={styles.label}>Additional Recipient(s)</label>
              <span>
                {alerts.matches('success') && modal.context.data.length === 0 ? (
                  <div className={styles.info}>No additional recipients added.</div>
                ) : null}
                <div className={styles.pills}>
                  {modal.context.data.map((recipient) => (
                    <div key={recipient} className={styles.pill}>
                      <span>{recipient}</span>
                      <TimesCircle onClick={() => send({ recipient, type: 'REMOVE_RECIPIENT' })} />
                    </div>
                  ))}
                </div>
              </span>
            </div>
          </>
        }
        size={ModalSize.Wide}
        state={modal}
        title={`Manage Recipients for Selected App${items.length === 1 ? '' : 's'}`}
      />
    </span>
  )
}

export default ManageRecipientsModal
