import Modal, { ModalProps, ModalSize } from './Modal'
import ModalBackground from './ModalBackground'
import ModalHeader from './ModalHeader'
import ModalWithBackground from './ModalWithBackground'
import ModalWithTrigger from './ModalWithTrigger'

export {
  ModalWithBackground,
  ModalBackground,
  ModalHeader,
  ModalProps,
  ModalSize,
  ModalWithTrigger,
}
export default Modal
