import { useCallback } from 'react'

import { useAccessToken } from '../../auth'
import useEnsureServiceMeta from './useEnsureServiceMeta'
import useFetchMachine from './useFetchMachine'

type Options<T> = {
  enabled?: boolean
  transform?: (data: Blob) => T
}

export function useObjectUrl<T = string>(
  url: string,
  { enabled: incomingEnabled = true, transform }: Options<T> = {}
) {
  const accessToken = useAccessToken()
  const enabled = Boolean(url) && incomingEnabled

  const api = useCallback(
    async (url: string) => {
      const headers = new Headers([['authorization', `Bearer ${accessToken}`]])
      const res = await fetch(url, {
        credentials: 'include',
        headers,
        method: 'get',
      })

      if (!res.ok) {
        throw res.statusText
      }

      return res.blob()
    },
    [accessToken]
  )

  const key = useEnsureServiceMeta<T, Blob>(url, () => api(url), {
    transform:
      transform ?? (URL.createObjectURL as unknown as NonNullable<Options<T>['transform']>),
    url,
  })

  return useFetchMachine<T, Blob>(key, { enabled })
}
