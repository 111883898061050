import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { makeGetAccesses } from '../../session/selectors'
import { Access } from '../../session/types'

type ProtectedRouteProps = RouteProps & {
  canAccess: Access | Access[]
  redirect: string
}

export default function ProtectedRoute({ canAccess, redirect, ...props }: ProtectedRouteProps) {
  const hasAccess = useSelector(
    Array.isArray(canAccess) ? makeGetAccesses(canAccess) : makeGetAccesses([canAccess])
  ).some(Boolean)

  return hasAccess ? (
    <Route {...props} />
  ) : (
    <Route {...props}>
      <Redirect to={redirect} />
    </Route>
  )
}
