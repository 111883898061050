import { config, useTransition } from 'react-spring'

import { noop } from '../utils'

export function useSlide(condition: boolean, distance: string, onRest = noop) {
  return useTransition(condition, {
    config: config.stiff,
    enter: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: `translateY(${distance})` },
    leave: { opacity: 0, transform: `translateY(${distance})` },
    onRest,
  })
}

export function useSlideDown(condition: boolean, distance = 16, onRest = noop) {
  return useSlide(condition, `-${distance}px`, onRest)
}

export function useSlideUp(condition: boolean, distance = 16, onRest = noop) {
  return useSlide(condition, `${distance}px`, onRest)
}
