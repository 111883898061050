import * as React from 'react'

import { Code } from '../../modules/icons'
import FooterItem from './FooterItem'

const ProductInfo = () => (
  <FooterItem>
    <Code className="-mb-1 h-4 w-4 fill-[#63a1d1]" title={process.env.PRODUCT_INFO} />
  </FooterItem>
)

export default ProductInfo
