import { Datum as LegendDatum, LegendProps } from '@nivo/legends'
import { Layer, Line, LineSvgProps } from '@nivo/line'
import { includes, pathOr, pluck, range, without } from 'ramda'
import React, { useMemo, useState } from 'react'

import { colors } from '../../../styles'
import isIeOrEdge from '../../../utils/isIeOrEdge'
import { Datum, XY } from '../types'
import { chartTheme, getEntityLabel } from '../utils'
import LegendSymbolShape from './LegendSymbolShape'
import LineSliceTooltip from './LineSliceTooltip'
import PublicationDelayLine from './PublicationDelayLine'

type LineChartProps = Pick<
  LineSvgProps,
  | 'areaOpacity'
  | 'axisBottom'
  | 'axisLeft'
  | 'enableArea'
  | 'enablePoints'
  | 'margin'
  | 'sliceTooltip'
  | 'yScale'
> & {
  data: Array<Datum<XY>>
  height: number
  legend?: Partial<
    Omit<LegendProps, 'data'> & { data: Array<Partial<LegendDatum>>; reverse: boolean }
  >
  showLegend?: boolean
  showPublicationDelay?: boolean
  splitLegend?: boolean
  tooltipFormat: string
  width: number
}

const effects = [
  {
    on: 'hover',
    style: {
      itemBackground: colors.charcoalGray2alpha04,
    },
  },
]

const LineChart: React.FC<LineChartProps> = ({
  axisBottom,
  axisLeft,
  data,
  legend,
  showLegend = true,
  showPublicationDelay = true,
  sliceTooltip,
  splitLegend = true,
  tooltipFormat,
  ...props
}) => {
  const [hidden, setHidden] = useState<Array<string | number>>([])
  const shownData = data.filter(({ id }) => !includes(id, hidden))
  const dataSortedByLengths = shownData
    .map((item) => [item, item.data.length] as const)
    .sort(([, left], [, right]) => right - left)
    .map(([item]) => {
      const years = item.data.map(({ x }) => x)
      const max = Math.max(...years)
      const min = Math.min(...years)
      const nulls = range(min, max + 1).map((year) => year)

      return {
        ...item,
        data: nulls.map((year) => item.data.find(({ x }) => x === year) ?? { x: year, y: null }),
      }
    })
  const dataColors = dataSortedByLengths[0]?.color
    ? (pluck('color', dataSortedByLengths) as string[])
    : null
  const legendData = legend?.reverse ? data.slice(0).reverse() : data
  const legends = (
    splitLegend
      ? [
          { legendData: legendData.slice(0, 3) },
          { legendData: legendData.slice(3), translateY: 80 },
        ]
      : [{ legendData }]
  ).map(
    ({ legendData, ...customProps }) =>
      ({
        anchor: 'bottom-left',
        direction: 'row',
        effects,
        itemDirection: 'left-to-right',
        itemHeight: 20,
        itemWidth: 150,
        onClick: ({ id }) => {
          setHidden((state) => (includes(id, state) ? without([id], state) : [...state, id]))
        },
        symbolShape: (props) => (
          <LegendSymbolShape hidden={includes(props.id, hidden)} {...props} />
        ),
        symbolSize: 12,
        translateX: -40,
        translateY: 60,
        ...customProps,
        ...legend,
        data: legendData.map(({ color, id }, index) => ({
          color: color ?? colors.chartColors[index],
          id,
          label: getEntityLabel(id, 17).toUpperCase(),
          ...legend?.data?.[index],
        })),
      }) as LegendProps
  )

  const defaultSliceTooltip = useMemo(
    () => (sliceTooltipProps: any) => (
      <LineSliceTooltip
        {...sliceTooltipProps}
        xLegend={pathOr('', ['legend'], axisBottom)}
        yLegend={pathOr('', ['legend'], axisLeft)}
        tooltipFormat={tooltipFormat}
      />
    ),
    [axisBottom, tooltipFormat]
  )

  const [longestData] = dataSortedByLengths
  const tickValues =
    longestData.data.length < 5 ? longestData.data.map(({ x }) => new Date(x, 0)) : 'every 2 years'

  const maxValue =
    shownData[0]?.data
      .map(({ x }) => x)
      .reduce(
        (values, x) => [
          ...values,
          data
            .map((item) => item.data.filter((xy) => xy.x === x).map(({ y }) => y))
            .reduce((sum, [y]) => y + sum, 0),
        ],
        [] as number[]
      )
      .sort((left, right) => left - right)
      .pop() ?? 0

  const format =
    typeof axisLeft?.format === 'string'
      ? maxValue < 1000
        ? axisLeft.format.replace(/s$/, '')
        : axisLeft.format
      : undefined

  return (
    <Line
      animate={false}
      axisBottom={{
        format: "'%y",
        legendOffset: 30,
        legendPosition: 'middle',
        tickPadding: isIeOrEdge() ? 15 : 5,
        tickSize: 0,
        tickValues,
        ...axisBottom,
      }}
      axisLeft={{
        legendOffset: isIeOrEdge() ? -40 : -45,
        legendPosition: 'middle',
        tickPadding: 5,
        tickSize: 0,
        tickValues: 6,
        ...axisLeft,
        format,
      }}
      colors={dataColors ?? colors.chartColors}
      data={dataSortedByLengths}
      enableGridX={false}
      enablePoints={!props.enableArea}
      enableSlices="x"
      layers={
        [
          'grid',
          'axes',
          'areas',
          'lines',
          'crosshair',
          'slices',
          'points',
          'mesh',
          ...(showLegend ? ['legends'] : []),
          ...(showPublicationDelay ? [PublicationDelayLine] : []),
        ] as Layer[]
      }
      legends={legends}
      lineWidth={2}
      margin={{
        bottom: showLegend ? (data.length > 3 && splitLegend ? 85 : 65) : 45,
        left: 50,
        right: 15,
        top: 10,
      }}
      pointSize={6}
      // Must be `any` because the types are not correct
      sliceTooltip={sliceTooltip ?? defaultSliceTooltip}
      theme={chartTheme}
      xFormat="time:%Y"
      xScale={{ type: 'time', format: '%Y', useUTC: false }}
      yScale={{ type: 'linear', min: 'auto' }}
      {...props}
    />
  )
}

export default LineChart
