import React from 'react'
import { SpringConfig, animated, config, useSpring } from 'react-spring'

type ProgressBarProps = {
  color?: string
  progress: number
  springConfig?: SpringConfig
}

const styles = {
  main: 'bg-pale-gray rounded h-[6px] overflow-hidden relative w-full',
  meter: (color: string) => `rounded-br rounded-tr h-[6px] left-0 absolute top-0 ${color}`,
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  color = 'bg-apple-green',
  progress,
  springConfig = config.wobbly,
}) => {
  const props = useSpring({
    config: springConfig,
    from: { width: '0%' },
    width: `${progress * 100}%`,
  })

  return (
    <div className={styles.main} title={`${(progress * 100).toFixed(0)}%`}>
      <animated.div
        className={styles.meter(color)}
        data-testid="progress-indicator"
        style={props}
      />
    </div>
  )
}

export default ProgressBar
