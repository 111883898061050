import { Action } from '../../redux'

export type Notification = {
  dismissable?: boolean
  id: string
  link?: NotificationLink
  message: string
  progress?: number
  timeout?: number // timeout in milliseconds (will be passed to delay())
  type: NotificationTypes
}

export type NotificationLink = {
  text: string
  to: string
}

export enum NotificationTypes {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export type NotificationState = {
  notifications: Notification[]
}

export type NotificationPopPayload = string
export type NotificationPushPayload = Notification
export type NotificationUpdatePayload = Partial<Notification> & { id: string }

export type NotificationPopAction = Action<'notification/POP', NotificationPopPayload>
export type NotificationPushAction = Action<'notification/PUSH', NotificationPushPayload>
export type NotificationUpdateAction = Action<'notification/UPDATE', NotificationUpdatePayload>
