import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const TaskList = (props: IconProps) => (
  <SVG title="task-list" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M8 6H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 12H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 18H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 6H3.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 12H3.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 18H3.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SVG>
)

export default TaskList
