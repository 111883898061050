import React from 'react'

import { ChartProps, Charts } from '../types'
import ChartByTechCenter from './ChartByTechCenter'

const IssuedPatentsByTechCenter: React.FC<ChartProps> = (props) => (
  <ChartByTechCenter
    chart={Charts.IssuedPatentsByTechCenterOverTime}
    chartProps={{
      axisBottom: {
        legend: 'Issuance Year',
      },
      axisLeft: {
        format: '~s',
        legend: 'Patents',
      },
    }}
    title="Issued Patents by Tech Center Over Time"
    {...props}
  />
)

export default IssuedPatentsByTechCenter
