import config from '@juristat/config'
import { useMachine } from '@xstate/react'
import { DocumentNode } from 'graphql'
import { equals } from 'ramda'
import { useCallback } from 'react'

import { MutationTypeState, QueryKey } from '../types'
import { getQueryKey } from '../utils'
import { mutationMachine } from './machines'
import useGraphqlApi from './useGraphqlApi'
import useServicesMeta from './useServicesMeta'

type Options = Partial<{
  ppair: boolean
  refetchQueries: QueryKey[]
}>

export function useMutation<T>(
  query: DocumentNode,
  variables = {},
  { ppair = false, refetchQueries = [] }: Options = {}
) {
  const publicApi = useGraphqlApi(undefined, { includeErrors: true })
  const ppairApi = useGraphqlApi(`${config.ppairUrl}/v2/graphql/app`, { includeErrors: true })
  const api = ppair ? ppairApi : publicApi
  const services = useServicesMeta()
  const [current, send] = useMachine(mutationMachine, {
    services: {
      mutateData: async (_, { variables: overrides = {} }) => {
        const result: GraphQLResponse<T> = await api({
          query,
          variables: { ...variables, ...overrides },
        })

        if (result.errors) {
          throw result.errors[0].message
        }

        return result.data
      },
      refetchQueries: () => {
        for (const queryKey of refetchQueries) {
          const arrayQueryKey = Array.isArray(queryKey) ? queryKey : [queryKey]

          services.get(getQueryKey(arrayQueryKey))?.service?.send('REFETCH')

          Array.from(services.values())
            .filter((meta) => equals(meta.queryKey, queryKey))
            .forEach((meta) => meta.service?.send('REFETCH'))
        }

        return Promise.resolve()
      },
    },
  })
  const mutate = useCallback(
    (variables?: Record<string, unknown>) => {
      send({ type: 'FETCH', variables })
    },
    [send]
  )
  const reset = useCallback(() => {
    send('RESET')
  }, [send])

  return [mutate, current as MutationTypeState<T>, reset] as const
}
