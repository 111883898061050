type MaybeRenderProps = {
  children?: (() => React.ReactElement<any> | null) | React.ReactElement<any> | null
  render?: () => React.ReactElement<any> | null
  when: boolean
}

const MaybeRender = ({ children, render, when }: MaybeRenderProps) => {
  if (when === true) {
    if (typeof render === 'function') {
      return render()
    }

    if (typeof children === 'function') {
      return children()
    }

    if (children) {
      return children
    }
  }

  return null
}

export default MaybeRender
