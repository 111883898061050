import { css } from 'emotion'
import * as React from 'react'

import { colors } from '../../styles'

type TableFetchingLoaderProps = {
  className?: string
}

const styles = {
  loader: css({
    backgroundColor: colors.charcoalGrayAlpha10,
    borderRadius: 4,
    height: 12,
    width: '100%',
  }),
}

const TableFetchingLoader = ({ className }: TableFetchingLoaderProps) => (
  <div className={css(styles.loader, className)} data-testid="table-skeleton-loader" />
)

export default TableFetchingLoader
