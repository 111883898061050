import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const PopOut = (props: IconProps) => (
  <SVG title="pop-out" {...props}>
    <path d="M27,33H5a2,2,0,0,1-2-2V9A2,2,0,0,1,5,7H15V9H5V31H27V21h2V31A2,2,0,0,1,27,33Z" />
    <path d="M18,3a1,1,0,0,0,0,2H29.59L15.74,18.85a1,1,0,1,0,1.41,1.41L31,6.41V18a1,1,0,0,0,2,0V3Z" />
  </SVG>
)

export default PopOut
