import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Floppy = (props: IconProps) => {
  return (
    <SVG title="floppy" viewBox="0 0 14 14" {...props}>
      <path
        fillRule="evenodd"
        d="M8.313 4.375a.437.437 0 0 0 .437-.438v-1.75a.437.437 0 1 0-.875 0v1.75c0 .242.196.438.438.438zm4.812 7.875a.875.875 0 0 1-.875.875H1.75a.875.875 0 0 1-.875-.875V1.75c0-.483.392-.875.875-.875h.875V5.25c0 .483.392.875.875.875h7a.875.875 0 0 0 .875-.875V.875h.875c.483 0 .875.392.875.875v10.5zM3.5.875h7v3.938a.437.437 0 0 1-.438.437H3.939a.437.437 0 0 1-.438-.438V.876zM12.25 0H1.75A1.75 1.75 0 0 0 0 1.75v10.5C0 13.216.784 14 1.75 14h10.5A1.75 1.75 0 0 0 14 12.25V1.75A1.75 1.75 0 0 0 12.25 0z"
      />
    </SVG>
  )
}

export default Floppy
