import * as React from 'react'

type FetchingBarsProps = {
  className?: string
  dark?: boolean
  quantity: number
}

const styles = {
  skeleton: (dark: boolean) =>
    `${dark ? 'bg-pale-gray' : 'bg-charcoal'} rounded h-3 mb-[10px] opacity-20 w-full`,
}

const FetchingBars: React.FC<FetchingBarsProps> = ({ className, dark = false, quantity }) => (
  <div className={className}>
    {Array(quantity)
      .fill(0)
      .map((_, index) => (
        <div
          className={styles.skeleton(dark)}
          data-testid="fetching-bars"
          key={index}
          role="progressbar"
        />
      ))}
  </div>
)

export default FetchingBars
