import { LocalStorageKey } from '@juristat/common/types'
import { takeLatest } from 'redux-saga/effects'

import actions from '../actions'
import { SaveUsernameToLocalStorageAction } from '../types'

const saveUsernameToLocalStorage = (action: SaveUsernameToLocalStorageAction) => {
  const username = action.payload!
  try {
    window?.localStorage?.setItem?.(LocalStorageKey.Username, username)
  } catch (e) {
    /* No handling required */
  }
}

export { saveUsernameToLocalStorage }
export default function* watchSaveUsernameToLocalStorage() {
  yield takeLatest(actions.saveUsernameToLocalStorage().type, saveUsernameToLocalStorage)
}
