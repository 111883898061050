import { Feature } from '../../../session/types'

type FeatureInfo = {
  description: string
  img: string
  title: string
}

type FeatureMap = { [feature in Feature]: FeatureInfo }

const featureInfo: FeatureMap = {
  [Feature.Charts]: {
    description:
      'Simply drag and drop more than a dozen charts and graphs to visualize your data. Add, remove, and reorder charts to suit your needs, and save a default view so you can come back time and time again.',
    img: 'https://cdn2.hubspot.net/hubfs/3779160/Graphics/platform%20-%20Modal_Charts%20(1).png',
    title: 'Visualize your results',
  },
  [Feature.Compare]: {
    description:
      'Juristat Platform subscribers will soon be able to create custom charts comparing companies, firms, art units, classes, tech centers, and more. Check back soon.',
    img: 'https://cdn2.hubspot.net/hubfs/3779160/coming%20soon%20banner.png',
    title: 'Compare entities',
  },
  [Feature.Table]: {
    description:
      'With a fully customizable table view, focus only the data you need. Choose from more than 125 data points structure your data any way you like, and save your datasets to custom tables and dashboards that stay up to date automatically.',
    img: 'https://cdn2.hubspot.net/hubfs/3779160/Graphics/platform%20%20-%20Edit%20Columns%20Modal.png',
    title: 'Dig into the details',
  },
  [Feature.OfficeActions]: {
    description:
      'With our OA Response pane, you are just a few clicks away from finding annotated art, other applications with the same cited art, client letters, a custom response shell, and more.',
    img: 'https://cdn2.hubspot.net/hubfs/3779160/Graphics/platform%20-%20OA%20response%20Graphic%20Final.png',
    title: 'Quickly respond to office actions',
  },
  [Feature.PatentFamily]: {
    description:
      'Platform automatically connects immediate patent family members so you can quickly view parent and child applications and their status.',
    img: 'https://cdn2.hubspot.net/hubfs/3779160/Graphics/platform%20-%20Modal_Family.png',
    title: 'Get to know the family',
  },
  [Feature.SavedSearches]: {
    description:
      "Saved searches allow you to create curated keyword searches, filtered on any available facet. Easily track all apps with 2 or more RCE's, or all of IBM's cryptocurrency apps.",
    img: 'https://cdn2.hubspot.net/hubfs/3779160/Graphics/Platform%20overview.png',
    title: 'Build custom data sets and reports',
  },
  [Feature.ThreePaneView]: {
    description:
      'Our three-pane view lets you see the big picture – and all the key details. With Platform, it is easy to refresh your knowledge of an application and and all without ever going to PAIR.',
    img: 'https://cdn2.hubspot.net/hubfs/3779160/Graphics/platform%20-%20App%20view%20Graphic%20Final.png',
    title: 'See the big picture',
  },
  [Feature.Other]: {
    description:
      'Premium filters allow you to segment your search results. Stay up to date with saved searches and alerts. Scoped searches allow you to get into the details of rejections like never before.',
    img: 'https://cdn2.hubspot.net/hubfs/3779160/Stock%20images/platform%20filter%20and%20scoped%20search%20modal.png',
    title: 'And so much more...',
  },
}

export { FeatureInfo, FeatureMap }

export default featureInfo
