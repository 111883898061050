import makeAction from '../../../redux/utils/makeAction'
import {
  ErrorAction,
  FetchAction,
  ForkSearchSetAction,
  HydrateFromUidAction,
  InputAction,
  LoadAction,
  MissingPermissionAction,
  OrderSortAction,
  SearchResultsGetAction,
  SetAction,
  SetActiveSearchSetAction,
  SetAdvancedSearchVisibleAction,
  SetDataSourceAction,
  SetReportAction,
  SetScopesAction,
  SetTypeAction,
  SetUidAction,
} from '../types'

/* Actions */
const error = makeAction<ErrorAction>('search/ERROR')
const fetch = makeAction<FetchAction>('search/FETCH')
const forkSearchSet = makeAction<ForkSearchSetAction>('search/set/FORK')
const hydrate = makeAction<HydrateFromUidAction>('search/HYDRATE')
const input = makeAction<InputAction>('search/INPUT')
const load = makeAction<LoadAction>('search/LOAD')
const missingPermission = makeAction<MissingPermissionAction>('search/MISSING_PERMISSION')
const order = makeAction<OrderSortAction>('search/sort/ORDER')
const resultsGet = makeAction<SearchResultsGetAction>('search/results/GET')
const set = makeAction<SetAction>('search/SET')
const setActiveSearchSet = makeAction<SetActiveSearchSetAction>('search/set/SET_ACTIVE')
const setAdvancedSearchVisible = makeAction<SetAdvancedSearchVisibleAction>(
  'search/ADVANCED_SEARCH_VISIBLE'
)
const setDataSource = makeAction<SetDataSourceAction>('search/dataSource/SET')
const setReport = makeAction<SetReportAction>('search/report/SET')
const setScopes = makeAction<SetScopesAction>('search/scopes/SET')
const setType = makeAction<SetTypeAction>('search/type/SET')
const setUid = makeAction<SetUidAction>('search/uid/SET')

const sort = { order }

const actions = {
  error,
  fetch,
  forkSearchSet,
  hydrate,
  input,
  load,
  missingPermission,
  resultsGet,
  set,
  setActiveSearchSet,
  setAdvancedSearchVisible,
  setDataSource,
  setReport,
  setScopes,
  setType,
  setUid,
  sort,
}

export default actions
