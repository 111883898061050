import { isNilOrEmpty } from '@juristat/common/utils'
import {
  T,
  always,
  cond,
  contains,
  curry,
  equals,
  flip,
  ifElse,
  last,
  omit,
  partition,
  pick,
  pipe,
  propOr,
  reject,
  union,
} from 'ramda'

import { HydrateFromUidAction } from '../../search/types'
import {
  ActiveReducer,
  AvailableFilter,
  Filter,
  PossibleActions,
  SearchSetFilterState,
} from '../types'
import removeKey from './utils/removeKey'

const curriedRemoveKey = curry(removeKey)

const assigneeFilters = [
  Filter.AssigneeAtDisposition,
  Filter.AssigneeAtDispositionName,
  Filter.CurrentAssignee,
  Filter.CurrentAssigneeName,
]

const firmFilters = [
  Filter.CurrentFirm,
  Filter.CurrentFirmName,
  Filter.FirmAtDisposition,
  Filter.FirmAtDispositionName,
]

const active = (
  state: ActiveReducer = {},
  action: PossibleActions | HydrateFromUidAction,
  filterState: SearchSetFilterState
) => {
  const { assignee, firm, selected } = filterState
  const removeAssigneeFilters = [
    flip(contains)(assigneeFilters),
    (found: Filter) => omit(last(partition(equals(found), assigneeFilters) as any) as any, state),
  ]
  const removeFirmFilters = [
    flip(contains)(firmFilters),
    (found: Filter) => omit(last(partition(equals(found), firmFilters) as any) as any, state),
  ]

  switch (action.type) {
    case 'filter/APPLY': {
      const filter = action.payload! as AvailableFilter

      if (isNilOrEmpty(selected[filter])) {
        return omit([filter], state)
      }

      const updatedState: ActiveReducer = cond([
        removeAssigneeFilters as any,
        removeFirmFilters as any,
        [equals(Filter.CurrentFirm), () => removeKey(Filter.FirmAtDisposition, state)],
        [equals(Filter.FirmAtDisposition), () => removeKey(Filter.CurrentFirm, state)],
        [T, always(state)],
      ])(action.payload)

      return {
        ...updatedState,
        [filter]: selected[filter],
      }
    }
    case 'filter/APPLY_ALL':
      return pipe(
        ifElse(
          always(equals(assignee, Filter.CurrentAssignee)),
          curriedRemoveKey(Filter.AssigneeAtDisposition),
          curriedRemoveKey(Filter.CurrentAssignee)
        ),
        ifElse(
          always(equals(firm, Filter.CurrentFirm)),
          curriedRemoveKey(Filter.FirmAtDisposition),
          curriedRemoveKey(Filter.CurrentFirm)
        )
      )(selected)
    case 'filter/APPLY_LIST': {
      const { filter, value } = action.payload!

      return {
        ...state,
        [filter]: union(propOr([], filter, state) as any, value as any),
      }
    }
    case 'filter/APPLY_SOME': {
      const filters = action.payload! as AvailableFilter[]
      const emptyKeys = filters.filter((filter) => isNilOrEmpty(selected[filter]))

      return omit(emptyKeys, {
        ...state,
        ...pick(filters, selected),
      })
    }
    case 'filter/CLEAR': {
      const filter = action.payload! as AvailableFilter

      if (isNilOrEmpty(state[filter])) {
        return state
      }

      return removeKey(filter, state)
    }
    case 'filter/CLEAR_ALL':
      return {}
    case 'filter/CLEAR_SOME': {
      return omit(action.payload!, state)
    }
    case 'filter/SET': {
      const { filter, value } = action.payload!

      const updatedState: ActiveReducer = cond([
        removeAssigneeFilters as any,
        removeFirmFilters as any,
        [T, always(state)],
      ])(filter)

      return {
        ...updatedState,
        [filter]: value,
      }
    }
    case 'filter/SET_SOME':
      return {
        ...state,
        ...action.payload!,
      }
    case 'filter/HYDRATE': {
      const filters = action.payload ?? {}

      return reject(isNilOrEmpty, {
        ...filters,
        docCodeFilter: (
          filters?.docCodeFilter as unknown as Array<Record<'docCode', string>> | undefined
        )?.map(({ docCode }) => docCode),
      })
    }
    default:
      return state
  }
}

export default active
