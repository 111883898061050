import * as React from 'react'

import Button from '../../../../components/Button'
import Chevron, { Direction } from '../../../../components/Chevron'

type GalleryNavProps = {
  className?: string
  disabled: boolean
  onClick: React.MouseEventHandler
  direction: Direction
}

const GalleryNav: React.FC<GalleryNavProps> = ({ className, direction, disabled, onClick }) => (
  <Button
    active={!disabled}
    onClick={onClick}
    tw={`bg-pale-gray h-[33px] w-[33px] ${className ?? ''}`}
  >
    <Chevron direction={direction} title={direction === Direction.Right ? 'Next' : 'Previous'} />
  </Button>
)

export default GalleryNav
