import { css } from 'emotion'
import { MuuriComponent } from 'muuri-react'
import { DecoratedItem, ReactGridProps } from 'muuri-react/dist/types/interfaces'
import React, { Key, useState } from 'react'

import { MuuriActiveItemKeyContext, MuuriActiveItemUpdateContext } from '../contexts'

type MuuriGridItemsProps = {
  animationDuration?: 300 | 600
  children: Array<JSX.Element | JSX.Element[]> | JSX.Element
  dragEnabled?: boolean
  filter?: ReactGridProps['filter']
  forcedActiveItem?: Key
  onDragEnd?: (item: DecoratedItem) => void
  sort?: string[]
}

// Bugfix needed from muuri-react
interface ActualDecoratedItem extends DecoratedItem {
  getElement(): HTMLElement | undefined
}

const styles = {
  placeholder: css({
    opacity: 0.3,
    zIndex: -1,
  }),
}

const MuuriGridItems: React.FC<MuuriGridItemsProps> = ({
  animationDuration = 300,
  children,
  dragEnabled = true,
  filter,
  forcedActiveItem,
  onDragEnd,
  sort,
}) => {
  const [activeItem, setActiveItem] = useState<Key | null>(null)

  return (
    <MuuriActiveItemKeyContext.Provider value={forcedActiveItem ?? activeItem}>
      <MuuriActiveItemUpdateContext.Provider value={setActiveItem}>
        <MuuriComponent
          dragEnabled={dragEnabled}
          dragFixed
          dragPlaceholder={{
            enabled: true,
            createElement: (item) => {
              setActiveItem(item.getKey())

              return (item as ActualDecoratedItem).getElement()?.cloneNode(true) as HTMLElement
            },
          }}
          dragStartPredicate={{ handle: '.handle' }}
          filter={filter}
          itemPlaceholderClass={styles.placeholder}
          layout={{ fillGaps: true, rounding: false }}
          onDragEnd={(item) => {
            setActiveItem(null)
            onDragEnd?.(item)
          }}
          showDuration={animationDuration}
          layoutDuration={animationDuration}
          hideDuration={animationDuration}
          sort={sort}
        >
          {children as React.ReactElement[]}
        </MuuriComponent>
      </MuuriActiveItemUpdateContext.Provider>
    </MuuriActiveItemKeyContext.Provider>
  )
}

export default MuuriGridItems
