import * as React from 'react'

import { Triangle } from '../modules/icons'

export enum Direction {
  Down = 'down',
  Up = 'up',
  Left = 'left',
  Right = 'right',
}

type ChevronProps = {
  className?: string
  color?: string
  direction: Direction
  handleClick?: () => void
  title: string
  tw?: boolean
}

const Chevron = ({ className, direction, handleClick, title }: ChevronProps): JSX.Element => (
  <Triangle
    className={`fill-charcoal h-[5px] w-[10px] transition-[transform] duration-300 ease-in-out ${
      className ?? ''
    }`}
    direction={direction}
    onClick={handleClick}
    title={title}
  />
)

export default Chevron
