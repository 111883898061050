import { DataSource, IntelligenceEntityType, Key } from '@juristat/common/types'
import { css, cx } from 'emotion'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import Button from '../../../components/Button'
import { colors, textStyles, zIndex } from '../../../styles'
import { ASquare, DataCluster, StackOfPapers } from '../../icons'
import { usePrefetchEntityDetails } from '../../intelligence/hooks'
import Attribute from '../../search/components/Attribute'
import { getSearchDataSource } from '../../search/selectors'
import { useHasAccess } from '../../session/hooks'
import { Access } from '../../session/types'
import { OmnisearchResult, OmnisearchType } from '../types'
import Omnisearch, { AdditionalSections } from './Omnisearch'
import OmnisearchByExpertKeyword from './OmnisearchByExpertKeyword'
import OmnisearchByKeyword from './OmnisearchByKeyword'

type NavigationOmnisearchProps = {
  className?: string
  focus?: boolean
  forceOpen?: boolean
  initialTab?: Tab
  initialValue?: string
  tw?: boolean
}

export type Tab = 'category' | 'keyword' | 'expert'

const styles = {
  attribute: css({
    '& > span': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& a, & a:hover, & a:visited': {
      ...textStyles.linkBlueBold13Underlined,
      textTransform: 'uppercase',
    },
    '&:not(:last-of-type) > span': {
      marginBottom: 5,
    },
    display: 'initial',
    paddingRight: 0,
  }),
  container: css({
    alignItems: 'center',
    display: 'flex',
    height: 60,
    justifyContent: 'space-between',
    padding: 10,
  }),
  info: css({
    color: colors.silver3,
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    width: 350,
  }),
  infoMainText: css({
    margin: '0 0 10px 0',
  }),
  infoSubText: css({
    fontSize: 13,
  }),
  link: css(textStyles.linkBlueNormal13, {
    textDecoration: 'underlined',
  }),
  logo: css({
    alignItems: 'center',
    display: 'flex',
    marginLeft: 20,
  }),
  main: css({
    borderBottom: `1px solid ${colors.silver2}`,
    position: 'relative',
  }),
  search: css({
    left: '50%',
    position: 'absolute',
    top: 10,
    transform: 'translateX(-50%)',
    zIndex: zIndex.header,
  }),
  searchBy: {
    active: css({
      borderBottom: `3px solid ${colors.appleGreen2}`,
      fontWeight: 'bold',
      opacity: 1,
    }),
    button: css({
      fontSize: 16,
      padding: '5px 25px',
      opacity: 0.7,
    }),
    buttons: css({
      display: 'flex',
      justifyContent: 'space-between',
    }),
    container: css({
      padding: '10px 60px 0',
    }),
    link: css({
      color: colors.azure,
    }),
    text: css({
      fontSize: 14,
      padding: '30px 0 15px',
    }),
  },
  topContainer: css({
    '& > *': {
      '& > svg': {
        fill: colors.dark,
        marginRight: 8,
      },
      '& i': {
        marginLeft: 4,
      },
      '&:disabled': {
        opacity: 0.5,
      },
      '&:hover:not(:disabled)': {
        textDecoration: 'underline',
      },
      ...textStyles.linkBlueBold12,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      textAlign: 'left',
      width: 240,
    },
    '& > :not(:last-child)': {
      marginRight: 20,
    },
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 24px 0 24px',
  }),
}

const additionalSections: AdditionalSections = {
  type: 'category',
  sections: [
    [],
    [
      { icon: ASquare, key: OmnisearchType.Application, label: 'Applications' },
      { icon: StackOfPapers, key: OmnisearchType.Patent, label: 'Patents' },
    ],
  ],
}

const expertAdditionalSections: AdditionalSections = {
  type: 'expert',
  sections: [
    [{ icon: ASquare, key: OmnisearchType.Expert, label: 'Experts' }],
    [{ icon: StackOfPapers, key: OmnisearchType.PatentExpert, label: 'Patents' }],
  ],
}

const getLink = ({ description, id, type }: OmnisearchResult) => {
  switch (type) {
    case OmnisearchType.Application:
    case OmnisearchType.Patent:
      return { to: `application/${id.replace('pat,', '')}`, value: description }
    case OmnisearchType.ArtUnit:
      return { canAccess: Access.ArtUnit, to: `artunit/${id}`, value: description }
    case OmnisearchType.Attorney:
      return { to: `attorney-at-disp/${id}`, value: description }
    case OmnisearchType.Company:
      return {
        canAccess: Access.BusinessIntelligence,
        to: `assignee-at-disp/${id}`,
        value: description,
      }
    case OmnisearchType.Firm:
      return {
        canAccess: Access.BusinessIntelligence,
        to: `firm-at-disp/${id}`,
        value: description,
      }
    case OmnisearchType.Examiner:
      return { canAccess: Access.Examiner, to: `examiner/${id}`, value: description }
    case OmnisearchType.TechCenter:
      return { canAccess: Access.ArtUnit, to: `techcenter/${id}`, value: description }
    case OmnisearchType.Cpc:
      return { canAccess: Access.UspcClass, to: `cpc/${id}`, value: description }
    case OmnisearchType.Uspc:
      return { canAccess: Access.UspcClass, to: `uspc/${id}`, value: description }
    case OmnisearchType.Expert:
      return { to: `expert/${id}`, value: description }
    default:
      return ''
  }
}

const typeToEntityMap = {
  [OmnisearchType.ArtUnit]: IntelligenceEntityType.ArtUnit,
  [OmnisearchType.Attorney]: IntelligenceEntityType.AttorneyAtDisposition,
  [OmnisearchType.Company]: IntelligenceEntityType.AssigneeAtDisposition,
  [OmnisearchType.Cpc]: IntelligenceEntityType.Cpc,
  [OmnisearchType.Examiner]: IntelligenceEntityType.Examiner,
  [OmnisearchType.Firm]: IntelligenceEntityType.FirmAtDisposition,
  [OmnisearchType.TechCenter]: IntelligenceEntityType.TechCenter,
  [OmnisearchType.Uspc]: IntelligenceEntityType.Uspc,
}

const Result = (result: OmnisearchResult) => {
  const link = getLink(result)
  const prefetchEntityDetails = usePrefetchEntityDetails({
    entity: typeToEntityMap[result.type],
    id: result.id,
  })
  const [prefetch] = useDebouncedCallback(prefetchEntityDetails, 200)

  return link ? (
    <Attribute
      key={JSON.stringify(result)}
      className={styles.attribute}
      label=""
      onMouseEnter={() => prefetch()}
      {...link}
    />
  ) : (
    <div key={JSON.stringify(result)}>{result.description}</div>
  )
}

const ExpertResult = ({ description, id, type }: OmnisearchResult) => {
  const link =
    type === OmnisearchType.Expert
      ? { to: `expert/${id}`, value: description }
      : type === OmnisearchType.PatentExpert
      ? { to: `patent/${description.replace(/[^\d]+/g, '')}`, value: description }
      : null
  const key = JSON.stringify({ description, id, type })

  return link ? (
    <Attribute key={key} className={styles.attribute} label="" {...link} />
  ) : (
    <div key={key}>{description}</div>
  )
}

const expertPaths = ['/expert/:id', '/search/expert', '/patent/:id']

const NavigationOmnisearch: React.FC<NavigationOmnisearchProps> = ({
  className,
  focus,
  forceOpen,
  initialTab,
  initialValue,
}) => {
  const history = useHistory()
  const dataSource = useSelector(getSearchDataSource)
  const isPrivatePair = dataSource === DataSource.PrivatePair
  const [by, setBy] = useState<Tab>(initialTab ?? 'category')
  const canAccessExpertSearch = useHasAccess(Access.ExpertSearch)
  const canAccessPlatform = useHasAccess(Access.Platform)
  const isMatched = useRouteMatch(expertPaths)

  useEffect(() => {
    if (!canAccessPlatform && canAccessExpertSearch) {
      setBy('expert')
    }
    if (isMatched) {
      setBy('expert')
    }
  }, [])

  return (
    <Omnisearch
      additionalSections={by === 'expert' ? expertAdditionalSections : additionalSections}
      className={className}
      forceOpen={forceOpen}
      initialValue={initialValue}
      inputProps={{
        autoFocus: focus,
        onKeyDown: (event) => {
          const value = (event.target as HTMLInputElement).value.trim()

          if (event.keyCode === Key.Enter && value) {
            event.preventDefault()

            history.push('/omnisearch', { by, value })
          }
        },
      }}
      renderResult={(result) =>
        by === 'expert' ? <ExpertResult {...result} /> : <Result {...result} />
      }
      renderTop={(props) => (
        <div className={styles.searchBy.container}>
          <div className={styles.searchBy.buttons}>
            <Button
              className={cx(styles.searchBy.button, {
                [styles.searchBy.active]: by === 'category',
              })}
              disabled={!canAccessPlatform}
              onClick={() => setBy('category')}
            >
              Search by Category
            </Button>
            <Button
              className={cx(styles.searchBy.button, {
                [styles.searchBy.active]: by === 'keyword',
              })}
              disabled={!canAccessPlatform}
              onClick={() => setBy('keyword')}
            >
              Search by Keyword
            </Button>
            {isPrivatePair ? null : (
              <Button
                className={cx(styles.searchBy.button, {
                  [styles.searchBy.active]: by === 'expert',
                })}
                disabled={!canAccessExpertSearch}
                onClick={() => setBy('expert')}
              >
                Expert Search
              </Button>
            )}
          </div>
          <div className={styles.searchBy.text}>
            {by === 'category' ? (
              <div>
                <div>
                  Search for applications and reports by examiner, company name, firm, attorney,
                  tech center, art unit, or class.
                </div>
                <div className={styles.topContainer}>
                  <Link to="/uspto">
                    <DataCluster /> View the entire USPTO dataset
                  </Link>
                </div>
              </div>
            ) : by === 'expert' ? (
              <React.Fragment>
                <OmnisearchByExpertKeyword {...props} />
                <div>
                  Search for an IPR expert by name or search for a patent number to see recommended
                  experts.
                </div>
              </React.Fragment>
            ) : (
              <div>
                Search for applications by keywords or phrases.{' '}
                <a
                  className={styles.searchBy.link}
                  href="http://help.juristat.com/en/articles/2319557-application-searching-with-juristat"
                  rel="noreferrer"
                  target="_blank"
                >
                  Click here
                </a>{' '}
                to see some example searches and learn more about available connectors and
                operators.
              </div>
            )}
          </div>
        </div>
      )}
      tw={true}
    >
      {by === 'keyword' ? (props) => <OmnisearchByKeyword {...props} /> : undefined}
    </Omnisearch>
  )
}

export default NavigationOmnisearch
