import { Action } from '../../redux'
import { HttpContent } from '../http/types'

enum SponsorshipsStatus {
  ErrorFetching = 'error-fetching',
  ErrorSaving = 'error-saving',
  Saving = 'saving',
}

type CustomerNumber = {
  lastSeen: string
  number: number
  whitelisted: boolean
}

type Sponsorship = {
  sponsoredEmail: string
  customerNumberList: CustomerNumber[]
}

type ErrorFetching = {
  message: string
  type: SponsorshipsStatus.ErrorFetching
}

type ErrorSaving = {
  message: string
  type: SponsorshipsStatus.ErrorSaving
}

type Saving<T> = {
  data: T
  type: SponsorshipsStatus.Saving
}

type SponsorshipsState<T = Sponsorship[]> = HttpContent<T> | ErrorFetching | ErrorSaving | Saving<T>

type ApplyChangesAction = Action<'sponsorships/APPLY_CHANGES', Sponsorship>
type FetchSponsorshipsAction = Action<'sponsorships/FETCH_SPONSORSHIPS', undefined>
type SetSponsorshipsAction = Action<'sponsorships/SET_SPONSORSHIPS', SponsorshipsState>
type UpdateSponsorshipAction = Action<'sponsorships/UPDATE_SPONSORSHIP', Sponsorship>

type ReducerActions = ApplyChangesAction | SetSponsorshipsAction | UpdateSponsorshipAction

export {
  ApplyChangesAction,
  CustomerNumber,
  SponsorshipsState,
  SponsorshipsStatus,
  FetchSponsorshipsAction,
  ReducerActions,
  SetSponsorshipsAction,
  Sponsorship,
  UpdateSponsorshipAction,
}
