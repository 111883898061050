import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Buildings = (props: IconProps) => (
  <SVG title="buildings" viewBox="0 0 21 16" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 1H1V15H10V1ZM0 0V16H11V0H0Z" />
    <path d="M3 3H5V5H3V3Z" />
    <path d="M6 3H8V5H6V3Z" />
    <path d="M3 7H5V9H3V7Z" />
    <path d="M6 7H8V9H6V7Z" />
    <path d="M3 11H5V13H3V11Z" />
    <path d="M6 11H8V13H6V11Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 5H11V15H20V5ZM10 4V16H21V4H10Z" />
    <path d="M13 7H15V9H13V7Z" />
    <path d="M16 7H18V9H16V7Z" />
    <path d="M13 11H15V13H13V11Z" />
    <path d="M16 11H18V13H16V11Z" />
  </SVG>
)

export default Buildings
