import { isNilOrEmpty } from '@juristat/common/utils'
import {
  contains,
  eqBy,
  find,
  intersection,
  keys,
  map,
  mapObjIndexed,
  mergeDeepWith,
  omit,
  pathOr,
  pick,
  pipe,
  prop,
  reject,
  sortBy,
  unionWith,
  whereEq,
} from 'ramda'

import { HttpStatus, MergeableHttpContent } from '../../http/types'
import {
  ActiveReducer,
  AvailableFilter,
  AvailableReducer,
  Cpc,
  Filter,
  FilterCount,
  PossibleActions,
  PossibleFilterItem,
  Uspc,
} from '../types'

type PossibleAvailableFilter = NonNullable<AvailableReducer[AvailableFilter]>

const eqByNameOrValue = <T extends PossibleFilterItem>(left: T, right: T) => {
  const name = prop('name')
  const value = prop('value')
  const isEqBy = typeof name(left) === 'undefined' ? eqBy(value as any) : eqBy(name as any)

  return isEqBy(left, right)
}

const getActiveFilterShape = (item?: unknown) =>
  typeof item === 'object' ? Object.keys(item ?? {}) : []

const makeFindItemIn = (value: string | number | Record<string, unknown>) => {
  const findById = find(whereEq({ id: Number(value) }))
  // If value isn't an object, skip this find call
  const findByObject =
    typeof value === 'object' && value !== null ? find(whereEq(value)) : () => undefined
  const findByName = find(whereEq({ name: value }))
  const findByValue = find(whereEq({ value }))

  return <T>(items: T[] = []) =>
    findById(items) ?? findByObject(items) ?? findByName(items) ?? findByValue(items)
}

const available = (
  state: MergeableHttpContent<AvailableReducer> = { type: HttpStatus.NotAsked },
  action: PossibleActions,
  activeState: ActiveReducer
): MergeableHttpContent<AvailableReducer> => {
  switch (action.type) {
    case 'filter/ERROR':
      return {
        message: 'Unable to fetch filters.',
        type: HttpStatus.Error,
      }
    case 'filter/FETCH':
      return {
        data:
          state.type === HttpStatus.Fetching || state.type === HttpStatus.Success ? state.data : {},
        type: HttpStatus.Fetching,
      }
    case 'filter/SET_AVAILABLE': {
      const activeKeys = keys(activeState) as AvailableFilter[]

      if (activeKeys.length === 0) {
        return {
          data: action.payload!,
          type: HttpStatus.Success,
        }
      }

      // Guard against trying to map over date range filter, tag filter, that aren't in available
      const pickedActiveState = pick(keys(action.payload!), activeState)

      const getActiveStateUpdated = <T, U extends string | number | FilterCount | Cpc | Uspc>(
        values: U[],
        key: AvailableFilter
      ) =>
        map((value) => {
          const findItemIn = makeFindItemIn(value)
          const stateItem = findItemIn<T>(
            (state.type === HttpStatus.Fetching ? state.data[key] ?? [] : []) as any
          )
          const payloadItem = findItemIn<T>(action.payload![key] as any)

          if (stateItem !== undefined) {
            return {
              ...stateItem,
              // Pull app count from payload
              apps: pathOr(0, ['apps'], payloadItem),
            }
          }

          return payloadItem!
        }, values)

      // Loop through activeKeys
      const keepers = mapObjIndexed(
        pipe(getActiveStateUpdated as any, reject<PossibleAvailableFilter>(isNilOrEmpty)),
        pickedActiveState
      )

      const mergedPayload: AvailableReducer = mergeDeepWith(
        unionWith(eqByNameOrValue as any),
        action.payload ?? {},
        keepers
      )
      // We sort by label to keep checklist filters static, no effect on other filters
      const updatedPayload = map<AvailableReducer, AvailableReducer>(
        sortBy(prop('label')) as any,
        mergedPayload
      )

      const [activeKey] = activeKeys
      const dataForActiveKey: PossibleAvailableFilter =
        state.type === HttpStatus.Fetching ? state.data[activeKey] ?? [] : []
      const activeFilterShape = getActiveFilterShape(activeState[activeKey]?.[0])
      const dataInActive = intersection(
        (dataForActiveKey as Array<Record<string, string | number>>).map((item) => {
          if (activeFilterShape.length > 0) {
            return pick(activeFilterShape, item)
          }

          return 'id' in item ? item.id : 'name' in item ? item.name : item.value
        }),
        activeState[activeKey] as any
      )
      // This is because the name filter is rendered in the same filter bank as the non-name filter
      const specialFilter: boolean = contains(activeKey, [
        Filter.AssigneeAtDispositionName,
        Filter.CurrentAssigneeName,
      ])

      if (activeKeys.length !== 1 || dataInActive.length !== 0 || specialFilter) {
        return {
          data: updatedPayload,
          type: HttpStatus.Success,
        }
      }

      // This keeps existing options when only 1 active filter is applied
      const updatedState = unionWith<PossibleAvailableFilter>(
        eqByNameOrValue as any,
        updatedPayload[activeKey] as any,
        dataForActiveKey as any
      )

      return {
        data: dataForActiveKey ? { ...updatedPayload, [activeKey]: updatedState } : updatedPayload,
        type: HttpStatus.Success,
      }
    }
    case 'filter/SELECT_TYPEAHEAD': {
      const { filter, value } = action.payload!
      const id = prop('id')
      const obj = omit(['apps', 'name', 'label'])
      const lookup = 'id' in value ? id(value) : obj(value)
      const findItemIn = makeFindItemIn(lookup)
      const dataForFilter = state.type === HttpStatus.Success ? state.data[filter] : []

      if (findItemIn(dataForFilter as any) !== undefined) {
        return state
      }

      return {
        data: {
          ...(state.type === HttpStatus.Success ? state.data : {}),
          [filter]: [...(dataForFilter ?? []), value],
        },
        type: HttpStatus.Success,
      }
    }
    default:
      return state
  }
}

export default available
