import { css, cx } from 'emotion'
import React, { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { colors, textStyles } from '../styles'
import Input, { InputHandle, InputProps } from './Input'

type TextInputProps = InputProps &
  Partial<{
    accent: 'green' | 'blue'
    className: string
    dark: boolean
  }>

const twstyles = {
  accents: {
    blue: 'focus:border-azure',
    green: 'focus:border-greeny-blue',
  },
  common:
    'text-pale-gray2 font-opensans text-sm font-stretch-normal not-italic font-normal tracking-normal rounded h-[31px] py-[6px] px-[11px] w-full',
  dark: 'placeholder:text-[rgba(247,_249,_252,_0.7)] opacity-100 focus:border focus:border-greeny-blue bg-[#40465a] border border-dark text-pale-gray2',
  light:
    'placeholder:text-placeholder placeholder:font-opensans placeholder:text-xs placeholder:font-stretch-normal placeholder:not-italic placeholder:font-normal placeholder:tracking-normal placeholder:opacity-100 focus:bg-transparent focus:border focus:border-greeny-blue bg-pale-gray border border-silver2 text-charcoal-gray6',
}

const styles = {
  accents: {
    blue: css({
      '&:focus': {
        borderColor: colors.azure,
      },
    }),
    green: css({
      '&:focus': {
        borderColor: colors.greenyBlue,
      },
    }),
  },
  common: css(textStyles.paleGray2Normal14, {
    borderRadius: 4,
    height: 31,
    padding: '6px 11px',
    width: '100%',
  }),
  dark: css({
    '&::placeholder': {
      color: colors.paleGray2Alpha70,
      opacity: 1,
    },
    '&:focus': {
      border: `1px solid ${colors.greenyBlue}`,
    },
    backgroundColor: colors.charcoalGray4,
    border: `1px solid ${colors.dark}`,
    color: colors.paleGray2,
  }),
  light: css({
    '&::placeholder': {
      ...textStyles.placeholderNormal12,
      opacity: 1,
    },
    '&:focus': {
      backgroundColor: 'transparent',
      border: `1px solid ${colors.greenyBlue}`,
    },
    backgroundColor: colors.paleGray,
    border: `1px solid ${colors.silverSand}`,
    color: colors.charcoalGray6,
  }),
}

const TextInput = forwardRef<InputHandle, TextInputProps>(
  ({ accent = 'green', className, dark = false, tw = '', ...inputProps }, ref) => (
    <Input
      className={
        tw
          ? twMerge(
              `${twstyles.common} ${dark ? twstyles.dark : twstyles.light} ${
                twstyles.accents[accent]
              } ${tw}`
            )
          : cx(
              styles.common,
              {
                [styles.dark]: dark,
                [styles.light]: !dark,
              },
              styles.accents[accent],
              className
            )
      }
      ref={ref}
      {...inputProps}
    />
  )
)

export { TextInputProps }
export default TextInput
