import config from '@juristat/config'
import { call } from 'redux-saga/effects'

import { auth0Logout } from '../utils/auth0'

function* logout() {
  const options = {
    returnTo: `${config.baseUrl}/signin`,
  }
  yield call(auth0Logout, options)
}

export default logout
