import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

export function useNewWindow(href: string, target: string) {
  const windowRef = useRef<Window | null>(null)
  const timerRef = useRef<NodeJS.Timer | null>(null)
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    return () => {
      if (opened && timerRef !== null) {
        clearInterval(Number(timerRef))
      }
    }
  }, [opened, timerRef])

  useEffect(() => {
    if (windowRef.current) {
      windowRef.current.location.href = href
    }
  }, [href, windowRef])

  const onClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation()
      event.preventDefault()

      windowRef.current = window.open(
        href,
        target,
        `height=${window.innerHeight || 900},left=${window.innerWidth - 920},width=900`
      )
      timerRef.current = setInterval(() => {
        if (windowRef.current?.closed) {
          clearInterval(Number(timerRef.current))

          setOpened(false)
        }
      }, 100)

      setOpened(true)
    },
    [href, windowRef, target]
  )

  return {
    href,
    onClick,
    target,
  }
}
