import { identity } from 'ramda'
import { useCallback } from 'react'

import { useAccessToken } from '../../auth'
import { QueryKey } from '../types'
import useEnsureServiceMeta from './useEnsureServiceMeta'
import useFetchMachine from './useFetchMachine'

type Options<T, R> = Partial<{
  enabled: boolean
  transform: (data: R) => T | R
}>

function useApi() {
  const accessToken = useAccessToken()

  return useCallback(
    async (url: string) => {
      const headers = new Headers([['authorization', `Bearer ${accessToken}`]])

      const res = await fetch(url, {
        credentials: 'include',
        headers,
        method: 'get',
      })

      if (!res.ok) {
        if (res.status === 404) {
          throw 'not found'
        }

        throw res.statusText
      }

      return (await res.text()) as any
    },
    [accessToken]
  )
}

export function useCsvExport<T, R = T>(
  queryKey: QueryKey,
  url: string,
  { enabled = true, transform = identity }: Options<T, R> = {}
) {
  const api = useApi()
  const key = useEnsureServiceMeta(queryKey, async () => await api(url), {
    transform,
    url,
  })

  return useFetchMachine<T, R>(key, { enabled })
}
