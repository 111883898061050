import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

export default function ChevronUp(props: IconProps) {
  return (
    <SVG
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      title="chevron-up"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="m18 15-6-6-6 6" />
    </SVG>
  )
}
