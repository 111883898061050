import { assign, createMachine } from 'xstate'

import { Context, MutationEvent, MutationState } from '../../types'

type MutationContext = Omit<Context<unknown, never>, 'raw' | 'time'>

const context: MutationContext = {
  data: undefined,
  error: undefined,
}

export const mutationMachine = createMachine<
  MutationContext,
  MutationEvent<Record<string, unknown>>,
  MutationState<MutationContext>
>({
  id: 'mutation',
  initial: 'idle',
  context,
  states: {
    idle: {
      on: { FETCH: 'loading' },
    },
    loading: {
      entry: assign((_) => context),
      invoke: {
        src: 'mutateData',
        onDone: {
          target: 'success',
          actions: assign((_, event) => ({ data: event.data })),
        },
        onError: {
          target: 'failure',
          actions: assign((_, event) => ({ error: event.data })),
        },
      },
      on: { CANCEL: 'idle' },
    },
    success: {
      invoke: { src: 'refetchQueries' },
      on: { FETCH: 'loading' },
    },
    failure: {
      on: { FETCH: 'loading' },
    },
  },
  on: {
    RESET: '#mutation.idle',
  },
})
