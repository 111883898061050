import config from '@juristat/config'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api'
import { HttpStatus } from '../../../../http/types'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../../../notification'
import actions from '../actions'
import { getDetailsContent } from '../selectors'
import { GroupState, RenameAction } from '../types'

const errorNotification = notificationActions.push(
  makeNotification({
    message: 'Failed to rename group',
    type: NotificationTypes.Error,
  })
)

const successNotification = notificationActions.push(
  makeNotification({
    message: 'Successfully renamed the group',
    type: NotificationTypes.Success,
  })
)

function* renameGroup(action: RenameAction) {
  const details: GroupState['details'] = yield select(getDetailsContent)

  if (details.type !== HttpStatus.Success) {
    return
  }

  const name = action.payload!
  const url = `${config.accountsUrl}/api/group/${details.data.uuid}`
  const options = {
    body: JSON.stringify({ name }),
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    method: 'post',
    url,
  }

  try {
    const response = yield call(api, url, options)

    if (response.ok) {
      yield put(actions.set({ ...details.data, name }))
      yield put(successNotification)
    } else {
      yield put(errorNotification)
    }
  } catch {
    yield put(errorNotification)
  }
}

function* watchRenameGroup() {
  yield takeLatest(actions.rename().type, renameGroup)
}

export { errorNotification, renameGroup, successNotification }
export default watchRenameGroup
