const isObject = (value: unknown): value is Record<string, unknown> =>
  Boolean(value) && typeof value === 'object' && !Array.isArray(value)

const replacer = (_key: string, value: unknown) =>
  isObject(value)
    ? Object.keys(value)
        .sort()
        .reduce((result, key) => ({ ...result, [key]: value[key] }), {})
    : value

const getQueryKey = <QueryKeyArray>(queryKey: QueryKeyArray[]) => JSON.stringify(queryKey, replacer)

export { getQueryKey }
