import { contains, flip, nthArg, pipe, prop, propSatisfies, toUpper } from 'ramda'
import { uniq } from 'ramda'
import { useParams } from 'react-router-dom'

import { useNoopContext } from '../../../hooks/useNoop'
import { useQuery } from '../../api'
import { useDashboardPpair } from '../../dashboards/hooks'
import { mergeFilters } from '../../dashboards/utils'
import { useEntityTypeAndId } from '../../intelligence/components/ComparisonContext'
import { getIntrinsicFilters } from '../../intelligence/utils'
import { useSearchVariables } from '../../search/hooks'
import * as getExaminerAppeals from '../queries/getExaminerAppeals.graphql'
import {
  AppealDocument,
  AppealSetOrderings,
  AppealSetSearches,
  Appeals,
  AppealsGraphResponse,
  AppealsSetPage,
  ExaminerAppealsTableData,
} from '../types'

const fileDocCodes = ['AP.B', 'APDA', 'APDN', 'APDP', 'APDR', 'APEA', 'APRB']

const transformData = ({
  application: { appno, title },
  outcomeType,
  wrappers,
  orderKey,
}: AppealsSetPage): ExaminerAppealsTableData => {
  const fileMap = wrappers
    .filter(
      pipe(
        // Make TS happy
        nthArg(0),
        // Grab the doc_code property
        prop('docCode'),
        // Uppercase it
        toUpper,
        // See if it exists in docCodes that we care about
        (key) => contains(key, fileDocCodes)
      )
    )
    .map(({ docCode, files }) => ({
      docCode,
      files: files.length > 0 ? files : [null],
    }))
  const getFilesByDocCode = (docCode: string[]) =>
    fileMap
      .filter(propSatisfies(flip(contains)(docCode), 'docCode'))
      .reduce<Array<string | null>>((acc, { files }) => [...acc, ...files], [])

  return {
    appeal: getFilesByDocCode(['AP.B']),
    appno,
    outcome: outcomeType,
    ptab: getFilesByDocCode(['APDR', 'APDP', 'APDA', 'APDN']),
    reply: getFilesByDocCode(['APRB']),
    response: getFilesByDocCode(['APEA']),
    title,
    orderKey,
  }
}

type AppealSetFilterType = {
  hasDocument?: AppealDocument
  outcome?: string[]
}

export function useExaminerAppealsGraph(
  examinerId?: number,
  orderKey?: string,
  orderings?: AppealSetOrderings,
  searches?: AppealSetSearches,
  appealFilters?: AppealSetFilterType
) {
  const { dashboard } = useParams<{ dashboard?: string }>()
  const isDashboard = Boolean(dashboard)

  const { filters } = useSearchVariables()
  const ppair = useDashboardPpair()
  const useMaybeEntityTypeAndId = isDashboard ? useEntityTypeAndId : useNoopContext
  const entityTypeAndId = useMaybeEntityTypeAndId()

  return useQuery<Appeals, AppealsGraphResponse>('examiner-appeals', getExaminerAppeals, {
    ppair,
    transform: ({
      applicationSet: {
        appealsSet: {
          eventsGraph: { links },
          page,
          total,
        },
      },
    }) => {
      const data = page.map(transformData)
      const nodes = uniq(links.reduce<string[]>((acc, { from, to }) => [...acc, from, to], [])).map(
        (appealType: string) => ({ name: appealType })
      )

      return { links, nodes, page: data, total }
    },
    variables: {
      filters: mergeFilters(filters, {
        dispositionDate: { start: '2000-01-01' },
        ...(examinerId ? { examiner: [examinerId] } : {}),
        ...(ppair ? getIntrinsicFilters(entityTypeAndId) : {}),
      }),
      orderKey,
      orderings: orderings ?? {
        appealOrderingType: 'APPEAL_DATE_COLUMN',
        orderingDirection: 'DESCENDING',
      },
      searches,
      appealFilters,
    },
  })
}
