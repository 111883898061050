import * as React from 'react'

import { twTextStyles } from '../styles'

type ToggleProps<T> = {
  accent?: 'green' | 'blue'
  className?: string
  count?: number
  handleToggle: (on: boolean, value: T) => void
  label: string
  on?: boolean
  title?: string
  value: T
}

const styles = {
  count: `${twTextStyles.paleGray2Normal14} m-0 p-0`,
  inner: 'items-center inline-flex',
  input: (accent: 'green' | 'blue') =>
    `[&:checked_+_span:before]:translate-x-5 ${
      accent === 'green'
        ? `[&:checked_+_span]:bg-gradient-to-l [&:checked_+_span]:from-apple-green [&:checked_+_span]:to-greeny-blue2`
        : ''
    } ${accent === 'blue' ? '[&:checked_+_span]:bg-azure' : ''}`,
  label: twTextStyles.paleGray2Semibold14,
  main: 'items-center cursor-pointer flex justify-between min-h-[28px]',
  slider:
    'bg-[#282f3b] rounded-[34px] bottom-0 cursor-pointer left-0 absolute right-0 top-0 transition-[0.4s] before:bg-white before:rounded-[7px] before:bottom-[3px] before:content-[""] before:h-[14px] before:left-[3px] before:absolute before:transition-[0.4s] before:w-[14px] before:will-change-transform',
  toggle: (on: boolean) =>
    `[&_input]:hidden bg-[#282f3b] border ${
      on ? `border-transparent` : `border-cloudy-blue-alpha-50`
    } rounded-[11px] inline-block h-[22px] relative w-[42px]`,
}

const Toggle = <T,>({
  accent = 'green',
  className,
  count,
  handleToggle,
  label,
  on = false,
  title = label,
  value,
}: ToggleProps<T>) => (
  <div className={`${styles.main} ${className ?? ''}`} onClick={() => handleToggle(!on, value)}>
    <div>
      <h1 className={styles.label} title={label}>
        {label}
      </h1>
      {typeof count === 'number' && <div className={styles.count}>{count.toLocaleString()}</div>}
    </div>
    <div className={styles.inner} title={title}>
      <label className={styles.toggle(on)}>
        <input
          checked={on}
          className={styles.input(accent)}
          type="checkbox"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
          }}
          readOnly
        />
        <span className={styles.slider} />
      </label>
    </div>
  </div>
)

export default Toggle
