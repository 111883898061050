import config from '@juristat/config'
import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import actions from '../actions'
import { SearchHistoryGetAction } from '../types'

export function* getSearchHistory({ payload = { onlySaved: false } }: SearchHistoryGetAction) {
  const { onlySaved } = payload

  yield put(actions.fetch())

  const dataKey = onlySaved ? 'saved/_' : '_'
  const url = `${config.accountsUrl}/user/data/search_history/${dataKey}`
  const options = {
    credentials: 'include',
    url,
  }
  const response = yield call(api, url, options)
  // TODO: Handle network error
  if (response.ok) {
    const result = yield call([response, 'json'])
    yield put(actions.set(result, payload))
  }
}

function* watchGetSearchHistory() {
  yield takeLatest([actions.get().type], getSearchHistory)
}

export default watchGetSearchHistory
