import { SearchScope } from '@juristat/common/types'
import { css } from 'emotion'
import { all, contains, without } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'
import RadioGroup from '../../../components/RadioGroup'
import { AppState } from '../../../redux'
import { buttonStyles, textStyles } from '../../../styles'
import { getFilterMeta } from '../../filter/selectors'
import searchActions from '../../search/actions'
import { getSearchDataSource, getSearchScopes, getSearchType } from '../../search/selectors'
import getActiveFilters from '../../search/selectors/getActiveFilters'
import { SearchType } from '../../search/types'
import { RenderProps } from './Omnisearch'

type OmnisearchByKeywordProps = Omit<RenderProps, 'input'>

const styles = {
  button: css(buttonStyles.primaryBlue),
  container: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 10,
    paddingBottom: 30,
    width: '100%',
  }),
  footer: css({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    width: '100%',
  }),
  header: css(textStyles.darkBold16, {
    paddingBottom: 20,
  }),
  helpLink: css(textStyles.linkBlueBold12, {
    alignSelf: 'flex-start',
  }),
  noBorder: css({
    border: 'none',
  }),
  options: css({
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      width: 240,
    },
    display: 'flex',
  }),
  searchScopesContainer: css(textStyles.darkNormal16, {
    '& > label:not(:last-of-type)': {
      marginBottom: 10,
    },
    display: 'flex',
    flex: '0 0 120px',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: 150,
  }),
}

const searchScopes = [
  { label: 'Full Text', value: SearchScope.FullText },
  { label: 'All Fields', value: SearchScope.AllFields },
  { label: 'Abstract', value: SearchScope.Abstract },
  { label: 'Appeals', value: SearchScope.Appeals },
  { label: 'Petitions', value: SearchScope.Petitions },
  { label: 'Claims', value: SearchScope.Claims },
  { label: 'Description', value: SearchScope.Description },
  { label: 'Rejections', value: SearchScope.Rejections },
  { label: 'Title', value: SearchScope.Title },
]

const searchTypes = [
  { label: 'Keyword', value: SearchType.Keyword },
  { label: 'Similar To', value: SearchType.SimilarTo },
]

const scopesSupportingSimilarTo = [
  SearchScope.Abstract,
  SearchScope.Claims,
  SearchScope.Description,
]

export default function OmnisearchByKeyword({ searchTerm: phrase }: OmnisearchByKeywordProps) {
  const dispatch = useDispatch()
  const stateScopes = useSelector(getSearchScopes)
  const stateType = useSelector(getSearchType)
  const dataSource = useSelector(getSearchDataSource)

  const [scopes, setScopes] = useState(stateScopes)
  const [type, setType] = useState(stateType)

  const canSearchSimilar: boolean =
    scopes.length > 0
      ? all((scope: SearchScope) => contains(scope, scopesSupportingSimilarTo), scopes)
      : false

  useEffect(() => {
    if (!canSearchSimilar) {
      setType(SearchType.Keyword)
    }
  }, [canSearchSimilar, scopes])

  const filterMeta = useSelector(getFilterMeta)
  const filters = useSelector((state: AppState) => getActiveFilters(state, filterMeta))

  const setReport = useCallback(
    () =>
      dispatch(
        searchActions.setReport({
          dataSource,
          filters,
          phrase,
          scopes,
          type,
        })
      ),
    [dataSource, dispatch, filters, phrase, scopes, type]
  )

  return (
    <div className={styles.container}>
      <div className={styles.options}>
        <div>
          <div className={styles.header}>Search scope</div>
          <div className={styles.searchScopesContainer}>
            {searchScopes.map(({ label, value }) => (
              <Checkbox
                key={value}
                accent="blue"
                checked={contains(value, scopes)}
                handleClick={() =>
                  setScopes((state) =>
                    contains(value, state) ? without([value], state) : [...state, value]
                  )
                }
                label={label}
              />
            ))}
          </div>
        </div>
        <div>
          <div className={styles.header}>Search type</div>
          <span
            title={
              canSearchSimilar
                ? ''
                : 'Select a scope of Abstract, Claims, or Description to perform a similarity search'
            }
          >
            <RadioGroup
              horizontal
              options={searchTypes.map((item) => ({ ...item, disabled: !canSearchSimilar }))}
              selectedOption={type}
              setSelectedOption={setType}
            />
          </span>
        </div>
      </div>
      <div className={styles.footer}>
        <Button className={styles.button} handleClick={setReport}>
          Search
        </Button>
      </div>
    </div>
  )
}
