import { css } from 'emotion'
import * as React from 'react'

import Button from '../../../../components/Button'
import TextInput from '../../../../components/TextInput'
import { colors } from '../../../../styles'
import { Minus, Plus } from '../../../icons'

type NumberInputProps = {
  update: (value: string | null) => void
  value: string | null
}

const styles = {
  button: css({
    '& > svg': {
      fill: colors.dark3,
    },
    backgroundColor: colors.cloudyBlue,
    border: `0.5px solid ${colors.dark}`,
    height: 20,
    width: 30,
  }),
  container: css({
    '& > :first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '& > :last-child': {
      borderRadius: '0 4px 4px 0',
    },
    display: 'flex',
    width: '100%',
  }),
  input: css({
    borderLeft: 'none',
    borderRadius: 0,
    borderRight: 'none',
    flexGrow: 1,
    fontSize: 12,
    height: 20,
    padding: 0,
    textAlign: 'center',
  }),
}

const maybeNull = (value: string) => (value === '' || Number(value) === -1 ? null : value)

const addBy = (value: string | null, by: number) => String(Number(value) + by)

const NumberInput = ({ value, update }: NumberInputProps) => (
  <div className={styles.container}>
    <Button
      active={value !== null}
      className={styles.button}
      handleClick={() => update(maybeNull(addBy(value, -1)))}
    >
      <Minus height={9} width={9} />
    </Button>
    <TextInput
      className={styles.input}
      dark
      handleOnTextChange={(text) => update(maybeNull(text))}
      selectOnFocus
      text={value ? value : ''}
      type="number"
    />
    <Button className={styles.button} handleClick={() => update(addBy(value, 1))}>
      <Plus height={9} width={9} />
    </Button>
  </div>
)

export default NumberInput
