import { DataSource } from '@juristat/common/types'
import { DocumentNode } from 'graphql'
import { call, put } from 'redux-saga/effects'

import { Action } from '../../../redux'
import { graphqlApi, ppairApi } from '../../api'

type ErrorAction<T> = () => Action<string, T>

function* performSearchQuery<T>(
  variables: WeakObject,
  error: ErrorAction<T>,
  query: DocumentNode,
  dataSource: DataSource = DataSource.PublicPair
) {
  const method = dataSource === DataSource.PrivatePair ? ppairApi : graphqlApi
  try {
    return yield call(method, query, variables)
  } catch (ex) {
    yield put(error())
  }
}

export default performSearchQuery
