import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Start = (props: IconProps) => (
  <SVG title="start" viewBox="0 0 12 12" {...props}>
    <path d="M6.00005 0.299805C2.85605 0.299805 0.300049 2.8558 0.300049 5.9998C0.300049 9.14381 2.85605 11.6998 6.00005 11.6998C9.14405 11.6998 11.7 9.14381 11.7 5.9998C11.7 2.8558 9.14405 0.299805 6.00005 0.299805ZM8.43605 6.52781L5.08805 8.5918C4.68005 8.8438 4.14005 8.54381 4.14005 8.0638V3.9358C4.14005 3.4558 4.66805 3.1558 5.08805 3.4078L8.43605 5.4718C8.83205 5.7118 8.83205 6.28781 8.43605 6.52781Z" />
  </SVG>
)

export default Start
