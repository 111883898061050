import { any, path, startsWith } from 'ramda'
import { Reducer, combineReducers } from 'redux'

import { HttpStatus } from '../../http/types'
import breakdown from '../modules/breakdown/reducer'
import compare from '../modules/compare/reducer'
import { IntelligenceReducerState, IntelligenceState, ReducerActions } from '../types'

const details: Reducer<IntelligenceReducerState['details']> = (
  state = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'intelligence/SET_DETAILS':
      return action.payload!
    default:
      return state
  }
}

const name: Reducer<IntelligenceReducerState['name']> = (
  state = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'intelligence/SET_NAME':
      return action.payload!
    default:
      return state
  }
}

const createReducer = combineReducers({
  breakdown,
  compare,
  details,
  name,
})

const reducer: Reducer<IntelligenceState> = (state = {}, action: ReducerActions) => {
  const startsWithKey = (key: string) => startsWith(`${key}/`, action.type)

  if (any(startsWithKey, ['compare', 'filter', 'intelligence'])) {
    if (!action.meta) {
      return state
    }

    const { entity, id } = action.meta

    if (!entity || !id) {
      return state
    }

    return {
      ...state,
      [entity]: {
        ...(state[entity] ?? {}),
        [id]: createReducer(path([entity, id], state), action),
      },
    }
  }

  return state
}

export default reducer
