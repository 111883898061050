import { BarTooltipDatum } from '@nivo/bar'
import { indexBy, keys, mapObjIndexed, merge, mergeWith, pipe, pluck, prop, values } from 'ramda'
import React from 'react'

import { colors } from '../../../styles'
import { Charts, AllowanceRatesByTechCenter as Data, Datum, TransformBar } from '../types'
import { ChartProps } from '../types'
import { getTitleForLookups } from '../utils'
import BarChart from './BarChart'
import BarChartSkeletonLoader from './BarChartSkeletonLoader'
import BarTooltip from './BarTooltip'
import PlatformChartContainer from './PlatformChartContainer'

type Output = WeakObject & {
  techCenter: string
}

const transform: TransformBar<Data, Output> = (datum) =>
  datum
    .map(({ data, ...item }) =>
      data
        .sort((current, next) => current.techCenter - next.techCenter)
        .map(({ allowanceRate, techCenter }, index) => ({
          // Convert to string to avoid Nivo warning about indexValue since we're indexing by techCenter
          techCenter: String(techCenter),
          [item.id]: allowanceRate,
          [`${item.id}Color`]: datum.length === 1 ? colors.chartColorsGradient[index] : item.color,
        }))
        .reduce((acc, value) => ({ ...acc, [value.techCenter]: value }), {} as Output)
    )
    .reduce<Output[]>(([acc], value) => [mergeWith(merge, acc, value)], [])

const transformColors = pipe(
  indexBy<Datum<Data>>(prop('id')),
  mapObjIndexed<Record<'color', string>, string>(prop('color'))
)

const AllowanceRatesByTechCenter: React.FC<ChartProps> = (props) => (
  <PlatformChartContainer
    chart={Charts.AllowanceRatesByTechCenter}
    exportableConfig={{
      filename: 'allowance_rate_by_tech_center',
      getData: (data) => {
        const transformed = transform(data)[0]

        return data.map(({ id }) => [
          id,
          ...values(mapObjIndexed((item: Output) => item[id], transformed)),
        ])
      },
      getHeader: (data) => keys(transform(data)[0]),
    }}
    skeleton={BarChartSkeletonLoader}
    title={getTitleForLookups('Allowance Rate by Tech Center', props.lookups)}
    {...props}
  >
    {({ data: raw, ...dimensions }) => {
      const data = transform(raw)
      const colorsByKey = transformColors(raw)

      return (
        <BarChart
          {...dimensions}
          axisBottom={{
            legend: 'Tech Center',
            tickPadding: 5,
            tickRotation: 75,
          }}
          axisLeft={{
            format: '.0%',
            legend: 'Allowance Rate',
          }}
          colorsByKey={colorsByKey}
          data={values(data[0])}
          groupMode="grouped"
          indexBy="techCenter"
          keys={pluck('id', raw)}
          tooltip={(tooltipProps: BarTooltipDatum) => (
            <BarTooltip
              {...tooltipProps}
              tooltipFormat=".0%"
              xLegend="Tech Center"
              yLegend="Allowance Rate"
            />
          )}
        />
      )
    }}
  </PlatformChartContainer>
)

export { transform, transformColors }
export default AllowanceRatesByTechCenter
