import config from '@juristat/config'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { api } from '../../../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../../../notification'
import { getUuid } from '../../../../session/selectors'
import actions from '../actions'
import { TokenCreateAction } from '../types'

export function* createGroupToken({ payload }: TokenCreateAction) {
  const { group, domains, note } = payload!
  const url = `${config.accountsUrl}/group/${group}/make-token`
  const method = 'post'
  const body = JSON.stringify(payload!)
  const options = {
    body,
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    method,
    url,
  }
  const failedNotificationAction = notificationActions.push(
    makeNotification({
      message: 'Failed to create group link.',
      type: NotificationTypes.Error,
    })
  )

  try {
    const response = yield call(api, url, options)

    if (response.ok) {
      const { token } = yield call([response, 'json'])
      const signupLink = `https:${config.legacyAppUrl}/#/signup?token=${token}`
      const createdBy = yield select(getUuid)

      yield put(
        actions.token.add({
          createdBy,
          domains,
          groupId: group,
          note,
          token,
          validAfter: payload!.after ?? null,
          validBefore: payload!.before ?? null,
        })
      )

      yield put(
        notificationActions.push(
          makeNotification({
            link: {
              text: signupLink,
              to: signupLink,
            },
            message: `New group link created for group ${group}. The new signup link is {{link}}`,
            type: NotificationTypes.Success,
          })
        )
      )
    } else {
      yield put(failedNotificationAction)
    }
  } catch {
    yield put(failedNotificationAction)
  }
}

function* watchCreateGroupToken() {
  yield takeEvery([actions.token.create().type], createGroupToken)
}

export default watchCreateGroupToken
