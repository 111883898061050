import * as React from 'react'

import SVG from '../SVG'
import { IconProps } from '../types'

const CogSolid = (props: IconProps) => (
  <SVG title="cog-solid" viewBox="0 0 12 12" {...props}>
    <path d="M10.6299 6C10.6299 5.78262 10.6139 5.56869 10.5841 5.35938L12 4.21685L10.5707 1.78292L8.8562 2.41731C8.51537 2.15423 8.13586 1.93731 7.72766 1.776L7.42926 0H4.57074L4.27234 1.776C3.86414 1.93754 3.48463 2.15423 3.1438 2.41731L1.42926 1.78292L0 4.21685L1.41589 5.35938C1.38585 5.56869 1.37012 5.78238 1.37012 6C1.37012 6.21738 1.38608 6.43131 1.41589 6.64062L0 7.78315L1.42926 10.2171L3.1438 9.58269C3.48463 9.84577 3.86414 10.0627 4.27234 10.224L4.57074 12H7.42926L7.72766 10.224C8.13586 10.0625 8.51537 9.84577 8.8562 9.58269L10.5707 10.2171L12 7.78315L10.5841 6.64062C10.6142 6.43131 10.6299 6.21738 10.6299 6ZM6 8.22415C4.75052 8.22415 3.73767 7.22838 3.73767 6C3.73767 4.77162 4.75052 3.77585 6 3.77585C7.24948 3.77585 8.26233 4.77162 8.26233 6C8.26233 7.22838 7.24948 8.22415 6 8.22415Z" />
  </SVG>
)

export default CogSolid
