import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const ASquare = (props: IconProps) => (
  <SVG title="a-square" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1H14C15.6569 1 17 2.34315 17 4V14C17 15.6569 15.6569 17 14 17H4C2.34315 17 1 15.6569 1 14V4C1 2.34315 2.34315 1 4 1ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4ZM10.186 12.0107L10.3955 13.001H11.1763V8.25293C11.1763 7.44043 10.9753 6.8501 10.5732 6.48193C10.1755 6.11377 9.56185 5.92969 8.73242 5.92969C8.32194 5.92969 7.91146 5.98258 7.50098 6.08838C7.09473 6.19417 6.72021 6.34017 6.37744 6.52637L6.70117 7.33252C7.43327 6.97705 8.08919 6.79932 8.66895 6.79932C9.18522 6.79932 9.55973 6.9305 9.79248 7.19287C10.0295 7.45101 10.1479 7.85303 10.1479 8.39893V8.83057L8.96729 8.86865C6.72021 8.93636 5.59668 9.67269 5.59668 11.0776C5.59668 11.7336 5.79134 12.2393 6.18066 12.5947C6.57422 12.9502 7.11589 13.1279 7.80566 13.1279C8.32194 13.1279 8.75146 13.0518 9.09424 12.8994C9.44124 12.7428 9.78825 12.4466 10.1353 12.0107H10.186ZM9.55762 11.7188C9.18522 12.0785 8.67106 12.2583 8.01514 12.2583C7.60042 12.2583 7.27669 12.1589 7.04395 11.96C6.81543 11.7611 6.70117 11.4712 6.70117 11.0903C6.70117 10.6037 6.88525 10.2461 7.25342 10.0176C7.62581 9.78483 8.23096 9.65365 9.06885 9.62402L10.1226 9.57959V10.208C10.1226 10.8555 9.93425 11.359 9.55762 11.7188Z"
    />
  </SVG>
)

export default ASquare
