import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const File = (props: IconProps) => (
  <SVG title="file" {...props}>
    <path d="M21.89,4H7.83A1.88,1.88,0,0,0,6,5.91V30.09A1.88,1.88,0,0,0,7.83,32H28.17A1.88,1.88,0,0,0,30,30.09V11.92Zm-.3,2.49,6,5.9h-6ZM8,30V6H20v8h8V30Z" />
  </SVG>
)

export default File
