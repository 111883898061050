import { SearchView } from '@juristat/common/types'

// Extract viewType from URL path
export const stringToViewType = (pathname: string): SearchView => {
  const [, , viewType = 'card'] = (pathname || '').split('/')
  return viewType as SearchView
}

// Convert viewType to URL path fragment
export const viewTypeToString = (viewType?: SearchView): string => {
  switch (viewType) {
    case SearchView.Table:
    case SearchView.Intelligence:
      return `/${viewType}`
    case SearchView.Card:
    default:
      return ''
  }
}
