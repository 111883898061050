import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const People = (props: IconProps) => (
  <SVG title="people" viewBox="0 0 15 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 6C4.88071 6 6 4.88071 6 3.5C6 2.11929 4.88071 1 3.5 1C2.11929 1 1 2.11929 1 3.5C1 4.88071 2.11929 6 3.5 6ZM3.5 7C5.433 7 7 5.433 7 3.5C7 1.567 5.433 0 3.5 0C1.567 0 0 1.567 0 3.5C0 5.433 1.567 7 3.5 7Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 17V9.5C6 8.11929 4.88071 7 3.5 7C2.11929 7 1 8.11929 1 9.5V17H6ZM3.5 6C1.567 6 0 7.567 0 9.5V18H7V9.5C7 7.567 5.433 6 3.5 6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 10C12.8807 10 14 8.88071 14 7.5C14 6.11929 12.8807 5 11.5 5C10.1193 5 9 6.11929 9 7.5C9 8.88071 10.1193 10 11.5 10ZM11.5 11C13.433 11 15 9.433 15 7.5C15 5.567 13.433 4 11.5 4C9.567 4 8 5.567 8 7.5C8 9.433 9.567 11 11.5 11Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 17V13.5C14 12.1193 12.8807 11 11.5 11C10.1193 11 9 12.1193 9 13.5V17H14ZM11.5 10C9.567 10 8 11.567 8 13.5V18H15V13.5C15 11.567 13.433 10 11.5 10Z"
    />
  </SVG>
)

export default People
