import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

export default function ChevronsUp(props: IconProps) {
  return (
    <SVG
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      title="chevrons-up"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="m17 11-5-5-5 5" />
      <path d="m17 18-5-5-5 5" />
    </SVG>
  )
}
