import * as React from 'react'

import SVG from './SVG'
import ClipboardSolid from './solid/ClipboardSolid'
import { SolidProps } from './types'

const Clipboard = ({ solid, ...props }: SolidProps) => {
  if (solid === true) {
    return <ClipboardSolid {...props} />
  }

  return (
    <SVG title="clipboard" {...props}>
      <path d="M29.29,5H27V7h2V32H7V7H9V5H7A1.75,1.75,0,0,0,5,6.69V32.31A1.7,1.7,0,0,0,6.71,34H29.29A1.7,1.7,0,0,0,31,32.31V6.69A1.7,1.7,0,0,0,29.29,5Z" />
      <path d="M26,7.33A2.34,2.34,0,0,0,23.67,5H21.87a4,4,0,0,0-7.75,0H12.33A2.34,2.34,0,0,0,10,7.33V11H26ZM24,9H12V7.33A.33.33,0,0,1,12.33,7H16V6a2,2,0,0,1,4,0V7h3.67a.33.33,0,0,1,.33.33Z" />
      <rect x="11" y="14" width="14" height="2" />
      <rect x="11" y="18" width="14" height="2" />
      <rect x="11" y="22" width="14" height="2" />
      <rect x="11" y="26" width="14" height="2" />
    </SVG>
  )
}

export default Clipboard
