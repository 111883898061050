import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { AppState } from '../../../redux'
import { HttpStatus } from '../../http/types'
import FilterContext from '../context/filterContext'
import { getActiveFilters, getFilterStateAvailable, getSelectedFilters } from '../selectors'
import getIsFilterOpen from '../selectors/getIsFilterOpen'
import { getStatCountFilterValues } from '../selectors/getStatCountFilter'
import { AvailableFilter, Filter } from '../types'

export function useFilterMeta() {
  const { meta } = useContext(FilterContext)

  return meta
}

export function useFilterState(filter: Filter) {
  const meta = useFilterMeta()

  const open = useSelector((state: AppState) => getIsFilterOpen(state, { filter, ...meta }))
  const active = useSelector((state: AppState) => getActiveFilters(state, { filter, ...meta }))
  const content = useSelector((state: AppState) => getFilterStateAvailable(state, meta))
  const selected = useSelector((state: AppState) => getSelectedFilters(state, { filter, ...meta }))
  const statCount = useSelector((state: AppState) =>
    getStatCountFilterValues(state, { filters: [filter], ...meta })
  )

  return useMemo(() => {
    const available =
      content.type === HttpStatus.Success ? content.data[filter as AvailableFilter] ?? [] : []

    return { active, available, content, meta, open, selected, statCount }
  }, [active, content, filter, meta, open, selected, statCount])
}
