import { combineReducers } from 'redux'

import { Actions, DrawerState } from '../types'

const drawer = (state: DrawerState = DrawerState.Closed, action: Actions) => {
  switch (action.type) {
    case 'navigation/SET_DRAWER_STATE':
      return action.payload ?? state
    default:
      return state
  }
}

const reducer = combineReducers({ drawer })

export default reducer
