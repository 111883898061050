import { delay } from 'redux-saga'
import { call, fork, put, race, select, take } from 'redux-saga/effects'

import * as accountSagas from '../modules/account-tools/sagas'
import { sagas as analyticsSagas } from '../modules/analytics'
import { sagas as analyticsPlatformSagas } from '../modules/analytics'
import authActions from '../modules/auth/actions'
import * as authSagas from '../modules/auth/sagas'
import * as downloadSagas from '../modules/download/sagas'
import draftingSagas from '../modules/drafting/sagas'
import * as exportsSagas from '../modules/exports/sagas'
import * as filterSagas from '../modules/filter/sagas'
import * as homeSagas from '../modules/home/sagas'
import * as navigationSagas from '../modules/navigation/sagas'
import * as notificationSagas from '../modules/notification/sagas'
import * as portfolioSagas from '../modules/portfolio/sagas'
import { getPathname } from '../modules/router/selectors'
import * as searchSagas from '../modules/search/sagas'
import sessionActions from '../modules/session/actions'
import * as sponsorshipsSagas from '../modules/sponsorships/sagas'
import * as searchHistorySagas from '../modules/userSearches/sagas'

const allSagas = [
  accountSagas.watchChangeUserGroup,
  accountSagas.watchCreateGroupToken,
  accountSagas.watchCreateUser,
  accountSagas.watchDisableUser,
  accountSagas.watchExpireGroupToken,
  accountSagas.watchFetchUserDetails,
  accountSagas.watchGetAvailableRoles,
  accountSagas.watchGetCurrentRoles,
  accountSagas.watchGetGroupDetails,
  accountSagas.watchRenameGroup,
  accountSagas.watchRemoveRole,
  accountSagas.watchSearchGroups,
  accountSagas.watchSearchUsers,
  accountSagas.watchSetRole,
  accountSagas.watchVerifyUserEmail,
  analyticsSagas.watchAddLogRocket,
  analyticsSagas.watchShowNewIntercomMessage,
  analyticsSagas.watchTrackIntercomEvent,
  analyticsPlatformSagas.watchTrackIntercomEventOnLocationChange,
  authSagas.watchGetAvailableAuthMethods,
  authSagas.watchHandleAuthError,
  authSagas.watchLogin,
  authSagas.watchSaveUsernameToLocalStorage,
  authSagas.watchSendLoginEmail,
  sponsorshipsSagas.watchFetchCustomerWhitelistData,
  sponsorshipsSagas.watchApplyChanges,
  downloadSagas.watchDownloadFile,
  exportsSagas.watchTrackExportJobs,
  filterSagas.watchGetAvailableFilters,
  homeSagas.watchFetchTopAssignees,
  homeSagas.watchFetchTopFirms,
  homeSagas.watchGetNews,
  navigationSagas.watchLogout,
  notificationSagas.watchSetNotificationTimeout,
  portfolioSagas.watchCreatePortfolio,
  searchHistorySagas.watchDeleteSearchHistoryItem,
  searchHistorySagas.watchGetSearchHistory,
  searchHistorySagas.watchSaveSearchHistoryItem,
  searchSagas.watchMaybePerformSearchFromState,
  searchSagas.watchPerformSearchFromPayload,
  searchSagas.watchPerformSearchFromState,
  ...draftingSagas,
]

export const forks = allSagas.map((saga) => fork(saga))

function* sagas() {
  // It is okay to setup saga listeners (watchers) before we check for session
  yield forks

  const { success } = yield race({
    error: take(authActions.hydrateAccessTokenError().type),
    success: take(sessionActions.set().type),
    timeout: call(delay, 15000),
  })

  if (!success) {
    const pathname = yield select(getPathname)

    if (!pathname.startsWith('/confirm-email') && !pathname.startsWith('/signup')) {
      yield put(authActions.error())
    }

    return
  }
}

export default sagas
