import React from 'react'

import { BoundAction } from '../../../../redux'
import { SetReportAction } from '../../../search/types'
import ListItem from './ListItem'

type ExampleSearchesProps = {
  performSearch: BoundAction<SetReportAction>
}

const examples = [
  {
    text: '>=1 Alice Rejection',
    uid: 'eyJ0eXBlIjoiQXBwbGljYXRpb25TZXQiLCJpbmZvIjp7ImFyZ3MiOnsiZmlsdGVycyI6eyJoYXNSZWplY3Rpb25UeXBlIjpbIkFMSUNFIl19LCJzZWFyY2hlcyI6eyJmdWxsVGV4dCI6IiJ9LCJzaW1pbGFyVG8iOnt9LCJvcmRlcmluZ3MiOlt7Im9yZGVyaW5nVHlwZSI6IlJFSkVDVElPTlNfV0lUSF9BTElDRSIsIm9yZGVyaW5nRGlyZWN0aW9uIjoiREVTQ0VORElORyIsIm1pc3NpbmdCZWhhdmlvciI6IkxBU1QifV19fX0=',
  },
  {
    text: '>= 2 RCEs',
    uid: 'eyJ0eXBlIjoiQXBwbGljYXRpb25TZXQiLCJpbmZvIjp7ImFyZ3MiOnsiZmlsdGVycyI6eyJyY2VDb3VudCI6eyJhdExlYXN0IjoyfX0sInNlYXJjaGVzIjp7ImZ1bGxUZXh0IjoiIn0sInNpbWlsYXJUbyI6e30sIm9yZGVyaW5ncyI6W3sib3JkZXJpbmdUeXBlIjoiUFVCTElDQVRJT05fREFURSIsIm9yZGVyaW5nRGlyZWN0aW9uIjoiREVTQ0VORElORyIsIm1pc3NpbmdCZWhhdmlvciI6IkxBU1QifV19fX0=',
  },
  {
    text: 'Pending Apps',
    uid: 'eyJ0eXBlIjoiQXBwbGljYXRpb25TZXQiLCJpbmZvIjp7ImFyZ3MiOnsiZmlsdGVycyI6eyJhcHBsaWNhdGlvblN0YXR1cyI6WyJQRU5ESU5HIl19LCJzZWFyY2hlcyI6eyJmdWxsVGV4dCI6IiJ9LCJzaW1pbGFyVG8iOnt9LCJvcmRlcmluZ3MiOlt7Im9yZGVyaW5nVHlwZSI6IlBBSVJfU1RBVFVTX0RBVEUiLCJvcmRlcmluZ0RpcmVjdGlvbiI6IkRFU0NFTkRJTkciLCJtaXNzaW5nQmVoYXZpb3IiOiJMQVNUIn1dfX19',
  },
  {
    text: '>= 2 Finals + Pending',
    uid: 'eyJ0eXBlIjoiQXBwbGljYXRpb25TZXQiLCJpbmZvIjp7ImFyZ3MiOnsiZmlsdGVycyI6eyJhcHBsaWNhdGlvblN0YXR1cyI6WyJQRU5ESU5HIl0sImZpbmFsUmVqZWN0aW9uc0NvdW50Ijp7ImF0TGVhc3QiOjJ9fSwic2VhcmNoZXMiOnsiZnVsbFRleHQiOiIifSwic2ltaWxhclRvIjp7fSwib3JkZXJpbmdzIjpbeyJvcmRlcmluZ1R5cGUiOiJQVUJMSUNBVElPTl9EQVRFIiwib3JkZXJpbmdEaXJlY3Rpb24iOiJERVNDRU5ESU5HIiwibWlzc2luZ0JlaGF2aW9yIjoiTEFTVCJ9XX19fQ==',
  },
  {
    text: 'Cryptocurrency Apps',
    uid: 'eyJ0eXBlIjoiQXBwbGljYXRpb25TZXQiLCJpbmZvIjp7ImFyZ3MiOnsiZmlsdGVycyI6e30sInNlYXJjaGVzIjp7ImZ1bGxUZXh0IjoiYmxvY2tjaGFpbiBBTkQgKChjcnlwdG9jdXJyZW5jeSBPUiDigJxjcnlwdG8gY3VycmVuY3nigJ0pIE9SIOKAnGRpc3RyaWJ1dGVkIGxlZGdlcuKAnSkifSwic2ltaWxhclRvIjp7fSwib3JkZXJpbmdzIjpbeyJvcmRlcmluZ1R5cGUiOiJQVUJMSUNBVElPTl9EQVRFIiwib3JkZXJpbmdEaXJlY3Rpb24iOiJERVNDRU5ESU5HIiwibWlzc2luZ0JlaGF2aW9yIjoiTEFTVCJ9XX19fQ==',
  },
]

const ExampleSearches: React.FC<ExampleSearchesProps> = ({ performSearch }) => (
  <React.Fragment>
    {examples.map(({ text, uid }) => (
      <ListItem
        handleClick={() => {
          performSearch({ uid })
        }}
        key={uid}
        label={text}
      />
    ))}
  </React.Fragment>
)

export default ExampleSearches
