import { DataSource, SearchScope } from '@juristat/common/types'
import { equals, isEmpty, keys, not } from 'ramda'
import { createSelector } from 'reselect'

import { getFilterStateActive, getFilterStateSelected } from '.'
import {
  getSearchDataSource,
  getSearchPhrase,
  getSearchScopes,
  getSearchType,
} from '../../search/selectors'
import { SearchType } from '../../search/types'

const isClearAllEnabled = createSelector(
  getFilterStateActive,
  getFilterStateSelected,
  getSearchDataSource,
  getSearchPhrase,
  getSearchScopes,
  getSearchType,
  (active, selected, dataSource, phrase, scopes, type) =>
    dataSource !== DataSource.PublicPair ||
    (phrase !== null && phrase !== '') ||
    not(equals(scopes, [SearchScope.FullText])) ||
    type !== SearchType.Keyword ||
    !isEmpty(keys({ ...active, ...selected }))
)

export default isClearAllEnabled
