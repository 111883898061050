import * as React from 'react'

import SVG from '../SVG'
import { IconProps } from '../types'

const ExclamationCircleSolid = (props: IconProps) => (
  <SVG title="exclamation-circle-solid" {...props}>
    <path d="M30.33,25.54,20.59,7.6a3,3,0,0,0-5.27,0L5.57,25.54A3,3,0,0,0,8.21,30H27.69a3,3,0,0,0,2.64-4.43ZM16.46,12.74a1.49,1.49,0,0,1,3,0v6.89a1.49,1.49,0,1,1-3,0ZM18,26.25a1.72,1.72,0,1,1,1.72-1.72A1.72,1.72,0,0,1,18,26.25Z" />
  </SVG>
)

export default ExclamationCircleSolid
