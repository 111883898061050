import { find, propEq } from 'ramda'

import { GraphQLFilterResponse, RejectionBasisEnum } from '../types'

const rejectionBasisToLabelMap: { [key in RejectionBasisEnum]: string } = {
  [RejectionBasisEnum.Alice]: 'Alice',
  [RejectionBasisEnum.Bilski]: 'Bilski',
  [RejectionBasisEnum.DoublePatenting]: 'Double Patenting',
  [RejectionBasisEnum.MayoMyriad]: 'Myriad/Mayo',
  [RejectionBasisEnum.Type101]: '101',
  [RejectionBasisEnum.Type102]: '102',
  [RejectionBasisEnum.Type103]: '103',
  [RejectionBasisEnum.Type171]: '171',
  [RejectionBasisEnum.Type112a]: '112(a)',
  [RejectionBasisEnum.Type112b]: '112(b)',
  [RejectionBasisEnum.Type112f]: '112(f)',
}

const allRejectionBases = Object.keys(rejectionBasisToLabelMap) as RejectionBasisEnum[]

const transformRejectionBases = (rejectionBases: GraphQLFilterResponse['hasRejectionType']) =>
  allRejectionBases.map((rejectionBasis) => {
    const count = find(propEq('name', rejectionBasis as string), rejectionBases)
    return {
      apps: count ? count.apps : 0,
      label: rejectionBasisToLabelMap[rejectionBasis],
      value: rejectionBasis,
    }
  })

export { allRejectionBases, rejectionBasisToLabelMap }
export default transformRejectionBases
