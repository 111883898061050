import { Key } from '@juristat/common/types'
import { useActor } from '@xstate/react'
import React from 'react'
import { animated } from 'react-spring'
import { ActorRef, ActorRefFrom, EventObject } from 'xstate'

import TextInput from '../../../components/TextInput'
import { useSlideDown } from '../../../hooks/useSlide'
import { noop } from '../../../utils'
import { ExclamationCircle } from '../../icons'
import { createMultipleMachine, createValidationMachine } from '../utils'

type CommonProps = {
  background: 'white' | 'blue'
  disabled?: boolean
  placeholder: string
}

type TextInputProps = CommonProps & {
  actor?: never
  handleOnBlur?: () => void
  handleOnKeyDown?: (keyCode: number, event: React.KeyboardEvent<HTMLInputElement>) => void
  handleOnPaste?: (value: string) => void
  handleOnTextChange: (value: string) => void
  invalid?: never
  value: string
}

type ValidationTextInputProps = CommonProps & {
  actor: ActorRefFrom<typeof typeMachine>
  handleOnTextChange?: never
  invalid: string
  value?: never
}

type WorkflowAutomationMultiTextInputProps = {
  actor: ActorRefFrom<ReturnType<typeof createMultipleMachine>>
  background?: 'blue' | 'white'
  placeholder: string
}

type WorkflowAutomationTextInputProps = TextInputProps | ValidationTextInputProps

const typeMachine = createValidationMachine('type', Boolean)

const errorStyles =
  'bg-light-pink border border-brick-red focus:bg-light-pink focus:border focus:border-brick-red'

const styles = {
  input:
    'text-blue-gray font-montserrat text-s font-bold placeholder:text-blue-gray placeholder:font-montserrat placeholder:placeholder:text-s placeholder:font-bold placeholder:opacity-60 bg-white focus:bg-white border-transparent focus:border-transparent transition-[background-color,border-color] duration-300 ease-in-out p-[10px]',
  invalid: '[&_>_svg]:fill-current ml-1 mr-2 items-center text-[#bd4535] flex text-[11px]',
}

const dummyActor: ActorRef<EventObject> = {
  send: () => noop,
  subscribe: () => ({
    unsubscribe: () => noop,
  }),
}

const WorkflowAutomationTextInput = ({
  actor,
  background,
  handleOnTextChange,
  invalid,
  value,
  ...props
}: WorkflowAutomationTextInputProps) => {
  const [state, send] = useActor(
    actor ?? dummyActor,
    actor ? undefined : () => ({ matches: () => false })
  )
  const isInvalid = Boolean(state.history?.matches('invalid')) || state.matches('invalid')
  const transition = useSlideDown(isInvalid)
  return (
    <>
      <TextInput
        handleOnTextChange={actor ? (text) => send({ text, type: 'UPDATE' }) : handleOnTextChange}
        value={actor ? state?.context.text ?? '' : value}
        tw={`${styles.input} ${isInvalid ? errorStyles : ''} ${
          background === 'blue'
            ? '!bg-[#f5f9ff] focus:!bg-[#f5f9ff] !text-blue-gray !font-bold'
            : ''
        }`}
        {...props}
      />
      {transition(
        (props, item, { key }) =>
          item && (
            <animated.div key={key} className={styles.invalid} style={props}>
              <ExclamationCircle /> {invalid}
            </animated.div>
          )
      )}
    </>
  )
}

const WorkflowAutomationMultiTextInput = ({
  actor,
  background = 'blue',
  placeholder,
}: WorkflowAutomationMultiTextInputProps) => {
  const [state, send] = useActor(actor)

  return (
    <WorkflowAutomationTextInput
      background={background}
      handleOnBlur={() => send({ type: 'ADD' })}
      handleOnKeyDown={(_, event) => {
        if (event.keyCode === Key.Enter) {
          send({ type: 'ADD' })

          event.preventDefault()
        }
      }}
      handleOnPaste={(text) => send({ text, type: 'PASTE' })}
      handleOnTextChange={(text) => send({ text, type: 'UPDATE' })}
      placeholder={placeholder}
      value={state.context.text}
    />
  )
}

export { WorkflowAutomationMultiTextInput, WorkflowAutomationTextInput }
