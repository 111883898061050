import { css } from 'emotion'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../../redux'
import actions from '../actions'
import FilterContext from '../context/filterContext'
import { getStatCountButtonProps } from '../selectors/getStatCountFilter'
import { Filter } from '../types'
import ApplyButton from './ApplyButton'
import ClearButton from './ClearButton'

export type MultiFilterActionButtonsProps = {
  filters: Filter[]
}

const styles = {
  container: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
  }),
}

const MultiFilterActionButtons: React.FC<MultiFilterActionButtonsProps> = ({
  filters,
}: MultiFilterActionButtonsProps) => {
  const { meta } = useContext(FilterContext)
  const dispatch = useDispatch()
  const { applyDisabled, clearDisabled } = useSelector((state: AppState) =>
    getStatCountButtonProps(state, { filters, ...meta })
  )

  return (
    <div className={styles.container}>
      <ApplyButton
        active={!applyDisabled}
        handleClick={() => dispatch(actions.applySome(filters, meta))}
      />
      <ClearButton
        active={!clearDisabled}
        handleClick={() => dispatch(actions.clearSome(filters, meta))}
      />
    </div>
  )
}

export default MultiFilterActionButtons
