import makeAction from '../../redux/utils/makeAction'
import {
  BoostAction,
  ClassifyAction,
  ClearAction,
  GetKeyMetricsAction,
  GetSuggestionsAction,
  SetClassificationStateAction,
  SetKeyMetricsStateAction,
  SetSuggestionsStateAction,
} from './types'

const boost = makeAction<BoostAction>('drafting/BOOST')
const classify = makeAction<ClassifyAction>('drafting/CLASSIFY')
const clear = makeAction<ClearAction>('drafting/CLEAR')
const getSuggestions = makeAction<GetSuggestionsAction>('drafting/GET_SUGGESTIONS')
const getKeyMetrics = makeAction<GetKeyMetricsAction>('drafting/GET_KEY_METRICS')
const setClassificationState = makeAction<SetClassificationStateAction>(
  'drafting/SET_CLASSIFICATION'
)
const setKeyMetricsState = makeAction<SetKeyMetricsStateAction>('drafting/SET_KEY_METRICS')
const setSuggestions = makeAction<SetSuggestionsStateAction>('drafting/SET_SUGGESTIONS')

export default {
  boost,
  classify,
  clear,
  getKeyMetrics,
  getSuggestions,
  setClassificationState,
  setKeyMetricsState,
  setSuggestions,
}
