import React from 'react'

import JuristatLoader from './JuristatLoader'

const styles = {
  splash:
    '[>_svg]:h-1/2 [>_svg]:w-1/2 items-center bg-white flex h-screen justify-center left-0 overflow-hidden absolute top-0 w-screen z-[200]',
}

const Splash = () => (
  <div className={styles.splash} title="Splash screen">
    <JuristatLoader />
  </div>
)

export default Splash
