import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const CodeSquare = (props: IconProps) => (
  <SVG title="code-square" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1H14C15.6569 1 17 2.34315 17 4V14C17 15.6569 15.6569 17 14 17H4C2.34315 17 1 15.6569 1 14V4C1 2.34315 2.34315 1 4 1ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4ZM5.58397 5.72265L3.58397 8.72265L3.39907 9L3.58397 9.27735L5.58397 12.2773L6.41603 11.7227L4.60093 9L6.41603 6.27735L5.58397 5.72265ZM12.416 5.72265L14.416 8.72265L14.6009 9L14.416 9.27735L12.416 12.2773L11.584 11.7227L13.3991 9L11.584 6.27735L12.416 5.72265ZM10 2.9993L7.00004 13.9993L7.9648 14.2624L10.9648 3.26242L10 2.9993Z"
    />
  </SVG>
)

export default CodeSquare
