import React, { useEffect } from 'react'

import ActionButton from '../../../components/ActionButton'
import { useQueryStringParam } from '../../../hooks/useQueryStringParam'
import { twTextStyles } from '../../../styles'
import { useDownload } from '../../api'
import { JuristatLogo, WarningStandard } from '../../icons'

const styles = {
  container: 'flex w-full flex-col items-center justify-center',
  mainText: `${twTextStyles.darkBold61} mb-[84px]`,
  subText: `${twTextStyles.charcoalGray2Alpha50Bold24} mb-[109px]`,
}

const validToDownload = (url: string) => /^https:\/\/(.*)juristat\.com(:\d+)?(\/.*)?$/i.test(url)

const DownloadFile = () => {
  const [download] = useDownload()
  const [file, filename] = useQueryStringParam(['file', 'filename'])

  useEffect(() => {
    if (validToDownload(file)) {
      download(file, filename)
    }
  }, [download, file, filename])

  if (!validToDownload(file)) {
    return (
      <div className={styles.container}>
        <div>
          <WarningStandard height={96} />
        </div>
        <div className={styles.mainText}>Download URL is missing or invalid</div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <JuristatLogo className="mb-[45px] w-[657px]" />
      <div className={styles.mainText}>Your download will begin shortly...</div>
      <div className={styles.subText}>or click below to download</div>
      <ActionButton
        action={() => download(file, filename)}
        className="bg-link-blue px-[90px] py-[30px] text-2xl"
        primary
        text="DOWNLOAD"
      />
    </div>
  )
}

export default DownloadFile
