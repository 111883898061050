import cuid from 'cuid'
import Downshift from 'downshift'
import { css } from 'emotion'
import { always, pipe } from 'ramda'
import React, { useCallback, useState } from 'react'
import { animated, config as springConfig, useTransition } from 'react-spring'

import Button from '../../../../components/Button'
import { colors, textStyles, zIndex } from '../../../../styles'
import { useGridItems } from '../../../grid-items/hooks'
import { GridItemType, TopXForYTableGridItem } from '../../../grid-items/types'
import { Cog } from '../../../icons'

type TableSettingsMenuProps = {
  className?: string
  config: TopXForYTableGridItem
}

const styles = {
  button: css(textStyles.darkNormal13, {
    '&:hover': {
      backgroundColor: colors.cloudyBlueAlpha20,
    },
    cursor: 'pointer',
    justifyContent: 'flex-start',
    padding: '6px 14px',
    transition: 'background-color 200ms ease-in-out',
    width: '100%',
  }),
  menu: css({
    backgroundColor: colors.white,
    borderRadius: 4,
    boxShadow: `0 4px 10px 0 ${colors.charcoalGray2alpha30}`,
    left: -160,
    overflow: 'hidden',
    padding: '10px 0',
    position: 'absolute',
    top: 0,
    width: 150,
    zIndex: zIndex.select,
  }),
  menuButton: css({
    '& > svg': {
      fill: colors.charcoalGray2,
    },
    '&:hover': {
      opacity: 0.6,
    },
    height: 32,
    opacity: 0.3,
    transition: 'opacity 200ms ease-in-out',
    width: 32,
  }),
}

const TableSettingsMenu: React.FC<TableSettingsMenuProps> = ({ className, config }) => {
  const [items, , , update] = useGridItems()
  const tables = items.filter((item) => item.type === GridItemType.TopXForYTable)
  const ids = items.map((item) => item.id)
  const duplicateItem = useCallback(() => {
    const index = items.findIndex((item) => item.id === config.id)
    const duplicate = { ...config, id: cuid() }

    update([...items.slice(0, index + 1), duplicate, ...items.slice(index + 1)])
  }, [config.id, ids])
  const removeItem = useCallback(() => {
    update(items.filter((item) => item.id !== config.id))
  }, [config.id, ids])
  const [downshiftOpen, setDownshiftOpen] = useState(false)
  const transition = useTransition(downshiftOpen, {
    config: springConfig.stiff,
    enter: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(-16px)' },
    leave: { opacity: 0, transform: 'translateY(-16px)' },
  })

  return (
    <Downshift
      stateReducer={(_, changes) => {
        if (changes.isOpen !== undefined) {
          setDownshiftOpen(changes.isOpen)
        }

        return changes
      }}
    >
      {({ getItemProps, getMenuProps, toggleMenu }) => (
        <div className={css(className)}>
          <Button className={styles.menuButton} handleClick={toggleMenu} title="Table actions">
            <Cog title="" />
          </Button>
          {transition(
            (props, item, { key }) =>
              item && (
                <animated.div {...getMenuProps({ className: styles.menu })} key={key} style={props}>
                  <Button
                    {...getItemProps({
                      className: styles.button,
                      item: 'dupe',
                      onClick: pipe(always(undefined), toggleMenu, duplicateItem),
                    })}
                  >
                    Duplicate Table
                  </Button>
                  {tables.length > 1 ? (
                    <Button
                      {...getItemProps({
                        className: styles.button,
                        item: 'remove',
                        onClick: pipe(always(undefined), toggleMenu, removeItem),
                      })}
                    >
                      Remove Table
                    </Button>
                  ) : null}
                </animated.div>
              )
          )}
        </div>
      )}
    </Downshift>
  )
}

export default TableSettingsMenu
