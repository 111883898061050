import React from 'react'
import { useDispatch } from 'react-redux'

import actions from '../actions'
import { IntercomActions } from '../types'

type IntercomTriggerProps = {
  children: React.ReactNode
  className?: string
  event?: IntercomActions
  message?: string
}

const IntercomTrigger: React.FC<IntercomTriggerProps> = ({
  children,
  className,
  event,
  message = '',
}) => {
  const dispatch = useDispatch()

  return (
    <span
      className={className}
      onClick={() => {
        if (event) {
          dispatch(actions.intercom.log(event))
        }

        dispatch(actions.intercom.showNewMessage(message))
      }}
    >
      {children}
    </span>
  )
}

export default IntercomTrigger
