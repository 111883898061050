import React from 'react'
import { useDropzone } from 'react-dropzone'

type WorkflowAutomationDropzoneProps = {
  onDrop: (files: File[]) => void | Promise<void>
}

const styles = {
  dropzone:
    'hover:bg-pale-gray2 hover:border-link-blue border-2 border-dashed border-silver2 rounded cursor-pointer py-5 px-0 text-center transition-[background-color,border-color] duration-300 ease-in-out w-full',
}

export default function WorkflowAutomationDropzone({ onDrop }: WorkflowAutomationDropzoneProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.zip',
    onDrop,
  })

  return (
    <div {...getRootProps({ className: styles.dropzone })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the packets here!</p>
      ) : (
        <p>Drag 'n' drop zipped analyst packets here, or click to select them.</p>
      )}
    </div>
  )
}
