import { LocalStorageKey } from '@juristat/common/types'
import { call, put, takeLatest } from 'redux-saga/effects'

import { sagas } from '../../auth'
import sessionActions from '../../session/actions'
import actions from '../actions'

export function* logout() {
  yield call(sagas.logout)
  yield put(sessionActions.clear())

  try {
    window?.localStorage?.removeItem?.(LocalStorageKey.Username)
  } catch {
    /* No handling required */
  }
}

function* watchLogout() {
  yield takeLatest(actions.logout().type, logout)
}

export default watchLogout
