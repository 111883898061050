import { SearchView } from '@juristat/common/types'
import { isNilOrEmpty } from '@juristat/common/utils'
import { keys, reject } from 'ramda'
import { matchPath } from 'react-router-dom'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import getFilterStateFromUrl from '../../filter/utils/getFilterStateFromUrl'
import { LOCATION_CHANGE, LocationChangeAction } from '../../router'
import { getSearchPhrase, getSearchScopes } from '../../search/selectors'
import getActiveFilters from '../../search/selectors/getActiveFilters'
import actions from '../actions'
import { IntercomActions } from '../types'

type Options = Partial<{
  pathname: string
}>

const paths = [
  [IntercomActions.ApplicationView, { exact: true, path: '/application/:appno' }],
  [IntercomActions.SavedSearches, { exact: true, path: '/searches/saved' }],
  [IntercomActions.SearchHistory, { exact: true, path: '/searches/history' }],
  [IntercomActions.SearchView, { exact: false, path: '/search' }],
] as const

function* logIntercomEvent(action: IntercomActions, options?: Options) {
  switch (action) {
    case IntercomActions.SearchView: {
      const [, , view] = options!.pathname!.split('/')
      const { meta } = getFilterStateFromUrl(window.location.pathname)
      const searchFilters = yield select(getActiveFilters, meta)
      const searchPhrase = yield select(getSearchPhrase)
      const scopes = yield select(getSearchScopes)

      if (!isNilOrEmpty(searchFilters) || !isNilOrEmpty(searchPhrase)) {
        yield put(
          actions.intercom.log(IntercomActions.PerformedSearch, {
            filters: searchFilters ? keys(reject(isNilOrEmpty, searchFilters)).join(', ') : null,
            scopes,
            view: view || SearchView.Card,
          })
        )
      } else {
        yield put(actions.intercom.log(action))
      }

      return
    }
    default:
      yield put(actions.intercom.log(action))
      return
  }
}

function* trackLocationChange({ payload }: LocationChangeAction) {
  const { pathname } = payload!
  const match = paths.reduce<IntercomActions | undefined>(
    (acc, [key, options]) => acc ?? (matchPath(pathname, options) ? key : undefined),
    undefined
  )

  if (match !== undefined) {
    yield call(logIntercomEvent, match, { pathname })
  }
}

function* watchTrackIntercomEventOnLocationChange() {
  yield [takeLatest(LOCATION_CHANGE, trackLocationChange)]
}

export { logIntercomEvent, trackLocationChange }
export default watchTrackIntercomEventOnLocationChange
