import { css } from 'emotion'
import React from 'react'
import { SpringValues, animated } from 'react-spring'

import { breakpoints, media } from '../styles'

type LayoutContainerProps = {
  children: React.ReactNode
  className?: string
  style?: SpringValues<{ width: string }>
  tw?: boolean
}

const styles = {
  main: css(
    media(breakpoints.width1820, {
      maxWidth: 1120,
    }),
    {
      margin: '0 auto',
      maxWidth: 1400,
      padding: '0 20px',
      width: '100%',
    }
  ),
}

const twstyles = {
  main: 'max-[1820px]:max-w-[1120px] my-0 mx-auto max-w-[1400px] py-0 px-5 w-full',
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({
  children,
  className,
  style,
  tw = false,
}) => (
  <animated.div
    className={tw ? `${twstyles.main} ${className}` : css(styles.main, className)}
    style={style}
  >
    {children}
  </animated.div>
)

export default LayoutContainer
