type PermissionDetail = {
  permission: string
  params: string[]
  type: 'granted_permission' | 'role'
}

type Authorization = PermissionDetail & {
  details: PermissionDetail & {
    [role: string]: {
      [permission: string]: boolean
    }
  }
  assertion: string
  succeeded: boolean
}

enum Permission {
  Alpha = 'CAN_USE_ALPHA_JURISTAT',
  AnalystMetrics = 'CAN_VIEW_ANALYST_METRICS',
  Api = 'CAN_USE_API_CONSOLE',
  AssignmentSheet = 'CAN_VIEW_OAR_ASSIGNMENT_SHEET',
  Bi = 'CAN_USE_EPIPHANY',
  CanAdministerPpairWhitelist = 'CAN_ADMINISTER_PPAIR_CUST_NO_WHITELIST',
  CanEditTimes = 'CAN_EDIT_TIMES',
  CanOarBuild = 'CAN_OAR_BUILD',
  CanOarReview = 'CAN_OAR_REVIEW',
  CanOarTech = 'CAN_OAR_TECH',
  CanUseTools = 'CAN_USE_TOOLS',
  CanViewConfidentialData = 'CAN_VIEW_CONFIDENTIAL_DATA',
  CanViewOarClientConfig = 'CAN_VIEW_OAR_CLIENT_CONFIGURATION',
  CanViewOarClientUsageReport = 'CAN_VIEW_OAR_CLIENT_USAGE_REPORT',
  CanViewPrivateOars = 'CAN_VIEW_PRIVATE_OARS',
  Charts = 'CAN_ACCESS_CHARTS',
  Drafting = 'CAN_USE_PROJECT_Z',
  Examiner = 'CAN_BUY_EXAMINER_REPORT(0)',
  ExpertSearch = 'CAN_ACCESS_EXPERT_SEARCH',
  ExpertSearchExport = 'CAN_EXPORT_EXPERT_SEARCH_RESULTS',
  ExportAnalytics = 'CAN_EXPORT_ANALYTICS',
  FreeHealthDashboard = 'CAN_USE_FREE_HEALTH_DASHBOARD',
  GroupAdmin = 'CAN_CREATE_GROUP_TOKENS',
  GroupOwner = 'CAN_ADD_GROUP_OWNERS',
  HumanTasks = 'CAN_DO_HUMAN_TASKS',
  IdsAssignmentSheet = 'CAN_VIEW_IDS_ASSIGNMENT_SHEET',
  IdsViewer = 'CAN_VIEW_IDS',
  Idss = 'CAN_UPLOAD_IDS',
  OarBuildQuality = 'CAN_VIEW_OAR_BUILD_QUALITY',
  OarReviewQuality = 'CAN_VIEW_OAR_REVIEW_QUALITY',
  OarSender = 'CAN_SEND_OARS',
  OarViewer = 'CAN_VIEW_OARS',
  OneOTwoReports = 'CAN_VIEW_ONEOTWO_REPORTS',
  Personal = 'CAN_USE_PERSONAL',
  PlatformExaminerAuTcFilters = 'CAN_USE_EXAMINER_AU_TC_FILTERS',
  PlatformFirmAssigneeFilters = 'CAN_USE_FIRM_ASSIGNEE_FILTERS',
  PlatformOaRceCountFilters = 'CAN_USE_OA_RCE_COUNTS_FILTERS',
  PlatformPatentFamilyComponent = 'CAN_VIEW_PLATFORM_PATENT_FAMILY_COMPONENT',
  PlatformPremiumSorts = 'CAN_USE_PLATFORM_PREMIUM_SORTS',
  PlatformRegCustNumFilters = 'CAN_USE_REG_CUST_NUM_FILTERS',
  PlatformSavedSearchAndHistory = 'CAN_USE_SAVED_SEARCH_AND_HISTORY',
  PlatformSearchScopes = 'CAN_USE_PLATFORM_SEARCH_SCOPES',
  PlatformThreePaneView = 'CAN_USE_PLATFORM_THREE_PANE_VIEW',
  PremiumWork = 'CAN_VIEW_PREMIUM_WORK',
  PrivatePair = 'CAN_USE_PPAIR_UI',
  RejectionBasisFilter = 'CAN_USE_REJECTION_BASIS_FILTER',
  RejectionView = 'CAN_VIEW_REJECTION_RESPONSE',
  SelfAnalystMetrics = 'CAN_VIEW_SELF_ANALYST_METRICS',
  StaffSettings = 'CAN_VIEW_STAFF_SETTINGS',
  Table = 'CAN_ACCESS_TABLE',
  TurboPatentRejectionLink = 'CAN_HAVE_TURBOPATENT_REJECTION_LINK',
}

type User = {
  username: string
  uuid: string
}

type Group = {
  name: string
  uuid: string
}

type GroupAdminResponse = User & {
  admin: boolean
  group_id: string
  owner: boolean
}

type GroupMemberResponse = User & {
  api_only: boolean
  created: string
  disabled: boolean
  email_verified: boolean
  group: {
    name: string
    uuid: string
    url: string
  }
  group_id: string
  last_modified: string
  last_visit: string | null
  links: {
    userdata: string
    role_history: string
    roles: string
    group: string
    subscriptions: string
  }
  migrated_to_auth0: number
  password_changed: string | null
  test_account: boolean
  uri: string
  username: string
}

type GroupAdmin = Pick<GroupAdminResponse, 'username' | 'uuid' | 'owner' | 'admin'>
type GroupMember = GroupAdmin &
  Pick<GroupMemberResponse, 'disabled'> &
  Record<'apiOnly' | 'testAccount', boolean>

export {
  Authorization,
  Group,
  GroupAdmin,
  GroupAdminResponse,
  GroupMember,
  GroupMemberResponse,
  Permission,
  User,
}
