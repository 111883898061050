import { parse } from 'qs'
import { omit, pick } from 'ramda'

const v3keys = ['authprovider', 'destination', 'username']

const parseQueryStrings = (query: Location['search'] = '') => {
  const queryObj = parse(query, { ignoreQueryPrefix: true })
  const authParams = pick(v3keys, queryObj)
  const appParams = omit(v3keys, queryObj)
  return {
    app: appParams,
    auth: authParams,
  }
}

export default parseQueryStrings
