import { Bar, BarDatum, BarSvgProps, Layer } from '@nivo/bar'
import { includes, without } from 'ramda'
import React, { useState } from 'react'

import { colors } from '../../../styles'
import isIeOrEdge from '../../../utils/isIeOrEdge'
import { chartTheme, getEntityLabel } from '../utils'
import BarLegend from './BarLegend'
import LegendSymbolShape from './LegendSymbolShape'

type BarChartProps<T> = Pick<
  BarSvgProps,
  | 'axisBottom'
  | 'axisLeft'
  | 'borderColor'
  | 'colors'
  | 'groupMode'
  | 'maxValue'
  | 'onClick'
  | 'tooltipFormat'
  | 'tooltip'
> &
  RequiredProps<Pick<BarSvgProps, 'indexBy' | 'keys'>> & {
    colorsByKey: { [key: string]: string }
    data: T[]
    forceLegend?: boolean
    height: number
    showLegend?: boolean
    width: number
  }

type ColorsProps<T> = {
  id: string
  data: T
}

const getColor = ({ id, data }: ColorsProps<BarDatum>): string =>
  String(data[`${id}Color`]) || colors.appleGreen

const BarChart = <T extends WeakObject>({
  axisBottom,
  axisLeft,
  colorsByKey,
  forceLegend,
  keys,
  showLegend = true,
  ...props
}: BarChartProps<T>) => {
  const [hidden, setHidden] = useState<Array<string | number>>([])
  const shownKeys = keys.filter((id) => !includes(id, hidden))
  const hasLegend = forceLegend ?? (showLegend && keys.length > 1)

  return (
    <Bar
      animate={false}
      axisBottom={{
        legendOffset: 40,
        legendPosition: 'middle',
        tickPadding: isIeOrEdge() ? 15 : 5,
        tickSize: 0,
        ...axisBottom,
      }}
      axisLeft={{
        legendOffset: isIeOrEdge() ? -40 : -45,
        legendPosition: 'middle',
        tickPadding: 5,
        tickSize: 0,
        tickValues: 6,
        ...axisLeft,
      }}
      colors={getColor}
      enableGridX={false}
      enableLabel={false}
      keys={shownKeys}
      layers={['grid', 'axes', 'bars', 'markers', ...(hasLegend ? [BarLegend] : [])] as Layer[]}
      legends={[
        { legendKeys: keys.slice(0, 3) },
        { legendKeys: keys.slice(3), translateY: 90 },
      ].map(({ legendKeys, ...customProps }) => ({
        anchor: 'bottom-left',
        data: legendKeys.map((id) => ({
          color: colorsByKey[id],
          id,
          label: getEntityLabel(id, 17).toUpperCase(),
        })),
        dataFrom: 'keys',
        direction: 'row',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: colors.charcoalGray2alpha04,
            },
          },
        ],
        itemDirection: 'left-to-right',
        itemHeight: 20,
        itemWidth: 150,
        onClick: ({ id }) => {
          setHidden((state) => (includes(id, state) ? without([id], state) : [...state, id]))
        },
        symbolShape: (props) => (
          <LegendSymbolShape hidden={includes(props.id, hidden)} {...props} />
        ),
        symbolSize: 12,
        translateX: -40,
        translateY: 70,
        ...customProps,
      }))}
      margin={{
        bottom: hasLegend ? (keys.length > 3 ? 90 : 70) : 45,
        left: 50,
        right: 15,
        top: 10,
      }}
      padding={0.5}
      theme={chartTheme}
      {...props}
    />
  )
}

export default BarChart
