import { GridItem } from '@juristat/common/types'
import { css } from 'emotion'
import { whereEq } from 'ramda'
import React from 'react'

import Button from '../../../components/Button'
import { buttonStyles } from '../../../styles'
import { useDashboardItems } from '../hooks'

type AddToDashboardButtonProps = {
  item: GridItem
}

const styles = {
  button: css(buttonStyles.primaryBlue, {
    position: 'absolute',
    right: 30,
  }),
}

const AddToDashboardButton: React.FC<AddToDashboardButtonProps> = ({ item }) => {
  const [dashboardItems, updateDashboardItems] = useDashboardItems()

  return (
    <Button
      active={!dashboardItems.some(whereEq(item))}
      className={styles.button}
      onClick={() => updateDashboardItems([...dashboardItems, item], { autoSave: true })}
    >
      Add To Dashboard
    </Button>
  )
}

export default AddToDashboardButton
