import { CurrentRole } from '../../types'

const makeRolePayload = ({ param, role, validAfter, validBefore }: CurrentRole) => ({
  param: typeof param === 'string' ? param.trim() || null : null,
  role,
  valid_after: validAfter ? validAfter : null,
  valid_before: validBefore ? validBefore : null,
})

export default makeRolePayload
