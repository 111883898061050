import { Action } from '../../redux'
import { FilterReportType } from '../filter/types'
import { HttpContent } from '../http/types'
import { BreakdownReducerState } from './modules/breakdown/types'
import { CompareReducerState } from './modules/compare/types'

type IdAndName = {
  id: string | number
  name: string
}

type Entity = IdAndName & {
  entity: IntelligenceEntityType
  filterKey?: Entity
}

enum IntelligenceEntityType {
  ArtUnit = 'artunit',
  AssigneeAtDisposition = 'assignee-at-disp',
  AttorneyAtDisposition = 'attorney-at-disp',
  Cpc = 'cpc',
  CurrentAssignee = 'current-assignee',
  CurrentAttorney = 'current-attorney',
  CurrentFirm = 'current-firm',
  Examiner = 'examiner',
  FirmAtDisposition = 'firm-at-disp',
  SearchSet = 'search-set',
  TechCenter = 'techcenter',
  Uspc = 'uspc',
  Uspto = 'uspto',
}

type KeyMetricsByEntityType = Exclude<IntelligenceEntityType, IntelligenceEntityType.Uspto>

enum KeyMetricsColumn {
  Actions = 'actions',
  AllowanceRate = 'allowanceRate',
  AverageOas = 'avgOas',
  Disposed = 'disposed',
  Filed = 'filed',
  MonthsToDisposition = 'monthsToDisposition',
  Name = 'name',
  Pending = 'pending',
}

type EntityKeyMetrics = {
  allowanceRate: number | null
  avgOas: number | null
  disposed: number | null
  filed: number | null
  id: string | number | null
  name: string
  monthsToDisposition: number | null
  pending: number | null
}

type IntelligenceReducerState = {
  breakdown: BreakdownReducerState
  compare: CompareReducerState
  details: HttpContent<Omit<EntityKeyMetrics, 'name'>>
  name: HttpContent<string>
}

// State keyed by type and id
type IntelligenceState = Partial<{
  [IntelligenceType in IntelligenceEntityType]: {
    [id: string]: IntelligenceReducerState
  }
}>

type EntityTypeAndId = { entity: IntelligenceEntityType; id: number | string }
type EntityReportAndId = EntityTypeAndId & { report: FilterReportType }
type EntityKeyMetricsAndId = EntityTypeAndId & { keyMetricsBy: KeyMetricsByEntityType }
type MaybeUid = { uid?: string }

type FetchEntityDetailsAction = Action<'intelligence/FETCH_DETAILS', MaybeUid, EntityTypeAndId>
type FetchEntityNameAction = Action<'intelligence/FETCH_NAME', undefined, EntityTypeAndId>

type SetEntityDetailsAction = Action<
  'intelligence/SET_DETAILS',
  IntelligenceReducerState['details'],
  EntityTypeAndId
>
type SetEntityNameAction = Action<
  'intelligence/SET_NAME',
  IntelligenceReducerState['name'],
  EntityTypeAndId
>

type SetUidAction = Action<'intelligence/SET_UID', string, EntityReportAndId>
type UpdateUidAction = Action<'intelligence/UPDATE_UID', undefined>

type ReducerActions = SetEntityDetailsAction | SetEntityNameAction | SetUidAction

type GraphQLEntityTypeBy =
  | 'ART_UNIT'
  | 'ASSIGNEE_AT_DISPOSITION_ID'
  | 'ATTORNEY_AT_DISPOSITION_ID'
  | 'CPC_SHORT'
  | 'CURRENT_ASSIGNEE_ID'
  | 'CURRENT_ATTORNEY_ID'
  | 'CURRENT_FIRM_ID'
  | 'FIRM_AT_DISPOSITION_ID'
  | 'FIRST_ASSIGNEE_ID'
  | 'FIRST_ATTORNEY_ID'
  | 'FIRST_FIRM_ID'
  | 'TECH_CENTER'
  | 'USPC_CLASS'

type GetEntityDetailsResponse = GraphQLResponse<{
  applicationSet?: {
    metrics?: Array<{
      allowanceRate: number
      applicationCounts: {
        disposed: number
        pending: number
        total: number
      }
      timing: { daysToDisposition: { average: number } }
      officeActions: { toDisposition: { average: number } }
    }>
    uid: string
  }
}>

type GetEntityNameResponse = GraphQLResponse<{
  entity?: {
    name: string
  }
  attorney?: {
    name: string
  }
  filterMetadata?: {
    artUnit?: Array<{
      description: string
    }>
    cpcClass?: Array<{
      description: string
    }>
    uspcClass?: Array<{
      description: string
    }>
  }
}>

export {
  EntityKeyMetrics,
  EntityKeyMetricsAndId,
  GetEntityDetailsResponse,
  GetEntityNameResponse,
  Entity,
  EntityTypeAndId,
  EntityReportAndId,
  FetchEntityDetailsAction,
  FetchEntityNameAction,
  GraphQLEntityTypeBy,
  IntelligenceEntityType,
  IntelligenceReducerState,
  IntelligenceState,
  KeyMetricsByEntityType,
  KeyMetricsColumn,
  MaybeUid,
  ReducerActions,
  SetEntityDetailsAction,
  SetEntityNameAction,
  SetUidAction,
  UpdateUidAction,
}
