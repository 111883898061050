import { DataSource, SearchScope } from '@juristat/common/types'
import { pathOr } from 'ramda'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import routerActions from '../../router/actions'
import { getQueryString } from '../../router/selectors'
import actions from '../actions'
import { Column } from '../modules/table/types'
import { getSearchView } from '../selectors'
import { transform } from '../selectors/getActiveFilters'
import {
  OrderDirection,
  SearchType,
  SetReportAction,
  SetReportPayload,
  SortPayload,
} from '../types'
import makeSearchUrl from '../utils/makeSearchUrl'
import getSearchUid from './getSearchUid'

const getSortOrderVariables = (sort?: SortPayload | null) => ({
  orderingDirection: pathOr(OrderDirection.Descending, ['direction'], sort ?? {}),
  orderingType: pathOr(Column.PublicationDate, ['field'], sort ?? {}),
})

function* performSearchFromPayload(action: SetReportAction) {
  const {
    dataSource = DataSource.PublicPair,
    filters: payloadFilters = {},
    pageNum = 1,
    phrase = '',
    scopes = [SearchScope.FullText],
    sort,
    type = SearchType.Keyword,
    uid,
    view,
  } = action.payload! as SetReportPayload
  const currentView = yield select(getSearchView)
  const queryString = action.meta?.clearQueryString ? '' : yield select(getQueryString)
  const filters = transform(payloadFilters)

  if (uid) {
    return yield put(
      routerActions.push(
        makeSearchUrl({ dataSource, pageNum, queryString, uid, view: view ?? currentView })
      )
    )
  }

  const scopesAndPhrase = scopes.reduce((acc, scope) => ({ ...acc, [scope]: phrase }), {})

  const searches = type === SearchType.Keyword ? scopesAndPhrase : {}
  const similarTo = type === SearchType.SimilarTo ? scopesAndPhrase : {}
  const sortOrders = sort ? [getSortOrderVariables(sort)] : []

  const applicationSetUid = yield call(getSearchUid, { filters, searches, similarTo, sortOrders })
  if (applicationSetUid) {
    const url = makeSearchUrl({
      dataSource,
      pageNum,
      queryString,
      uid: applicationSetUid,
      view: view ?? currentView,
    })
    yield put(routerActions.push(url))
    return
  }

  yield put(actions.error())
}

function* watchPerformSearchFromPayload() {
  yield takeLatest('search/report/SET', performSearchFromPayload)
}

export { getSortOrderVariables, performSearchFromPayload }
export default watchPerformSearchFromPayload
