import { Action } from '../../redux'

enum CreateStatus {
  Editing = 'editing',
  Saving = 'saving',
  Saved = 'saved',
}

type Entity = {
  description: string
  id: string | null
  type: string
}

type Portfolio = {
  name: string
  entities: Entity[]
}

type Editing = { type: CreateStatus.Editing }
type Saved = { type: CreateStatus.Saved }
type Saving = { type: CreateStatus.Saving }

type CreateState = Editing | Saved | Saving

type PortfolioState = {
  create: CreateState
}

type EditingAction = Action<'portfolio/EDITING', undefined>
type OmnisearchClearAction = Action<'portfolio/omnisearch/CLEAR', undefined>
type OmnisearchErrorAction = Action<'portfolio/omnisearch/ERROR', string>
type OmnisearchFetchAction = Action<'portfolio/omnisearch/FETCH', undefined>
type OmnisearchGetAction = Action<'portfolio/omnisearch/GET', string>
type OmnisearchSetAction = Action<'portfolio/omnisearch/SET', unknown>
type SaveAction = Action<'portfolio/SAVE', Portfolio>
type SavedAction = Action<'portfolio/SAVED', undefined>

type ReducerActions =
  | EditingAction
  | OmnisearchClearAction
  | OmnisearchErrorAction
  | OmnisearchFetchAction
  | OmnisearchSetAction
  | SaveAction
  | SavedAction

export {
  CreateStatus,
  EditingAction,
  OmnisearchClearAction,
  OmnisearchErrorAction,
  OmnisearchFetchAction,
  OmnisearchGetAction,
  OmnisearchSetAction,
  Portfolio,
  PortfolioState,
  ReducerActions,
  SaveAction,
  SavedAction,
}
