import { isNilOrEmpty } from '@juristat/common/utils'
import {
  difference,
  differenceWith,
  isEmpty,
  mergeDeepRight,
  omit,
  pathOr,
  filter as ramdaFilter,
  reject,
  union,
  unionWith,
  without,
} from 'ramda'

import { AlmostActiveFilter, AlmostActiveReducer, PossibleActions } from '../types'
import compareActiveFilterPayloadValues from '../utils/compareActiveFilterPayloadValues'
import withoutObj from '../utils/withoutObj'
import removeKey from './utils/removeKey'

const selected = (state: AlmostActiveReducer = {}, action: PossibleActions) => {
  switch (action.type) {
    case 'filter/CLEAR_ALL':
      return {}
    case 'filter/CLEAR_SOME':
      return omit(action.payload!, state)
    case 'filter/CLEAR':
      return removeKey(action.payload as AlmostActiveFilter, state)
    case 'filter/REMOVE':
    case 'filter/REMOVE_LIST': {
      const { filter, value } = action.payload!

      if (isNilOrEmpty(state[filter as AlmostActiveFilter])) {
        return state
      }

      const updated =
        action.type === 'filter/REMOVE'
          ? {
              ...state,
              [filter]: without([value], state[filter as AlmostActiveFilter] as any),
            }
          : {
              ...state,
              [filter]: differenceWith(
                compareActiveFilterPayloadValues,
                state[filter as AlmostActiveFilter] as any,
                value as any
              ),
            }

      return isEmpty(updated[filter as AlmostActiveFilter])
        ? removeKey(filter as AlmostActiveFilter, state)
        : updated
    }
    case 'filter/REMOVE_OBJECT': {
      const { filter, value } = action.payload!

      const currentlySelected = state[filter]

      if (isNilOrEmpty(currentlySelected)) {
        return state
      }

      const updated = withoutObj(value, currentlySelected)

      return isEmpty(updated)
        ? removeKey(filter, state)
        : {
            ...state,
            [filter]: updated,
          }
    }
    case 'filter/SELECT':
    case 'filter/SELECT_LIST': {
      const { filter, value } = action.payload!
      return {
        ...state,
        [filter]: unionWith(
          compareActiveFilterPayloadValues,
          (state[filter as AlmostActiveFilter] ?? []) as any,
          (action.type === 'filter/SELECT' ? [value] : value) as any
        ),
      }
    }
    case 'filter/SELECT_OBJECT': {
      const { filter, value } = action.payload!

      return {
        ...state,
        [filter]: mergeDeepRight(state[filter] ?? {}, value),
      }
    }
    case 'filter/SELECT_SOME': {
      const { filter, value } = action.payload!

      if (isEmpty(value)) {
        return removeKey(filter, state)
      }

      return {
        ...state,
        [filter]: value,
      }
    }
    case 'filter/SELECT_TYPEAHEAD': {
      const { filter, value } = action.payload!
      const key = 'id' in value ? value.id ?? value.name : omit(['apps', 'metadata', 'name'], value)

      return {
        ...state,
        [filter]: [...(state[filter] ?? []), key],
      }
    }
    case 'filter/TRISTATE_INCLUDE': {
      const { filter, value } = action.payload!

      return {
        ...state,
        [filter]: {
          exclude: difference(pathOr([], [filter, 'exclude'], state), [value]),
          include: union(pathOr([], [filter, 'include'], state), [value]),
        },
      }
    }
    case 'filter/TRISTATE_EXCLUDE': {
      const { filter, value } = action.payload!

      return {
        ...state,
        [filter]: {
          exclude: union(pathOr([], [filter, 'exclude'], state), [value]),
          include: difference(pathOr([], [filter, 'include'], state), [value]),
        },
      }
    }
    case 'filter/TRISTATE_CLEAR': {
      const { filter, value } = action.payload!

      const tristateValue = ramdaFilter((arr: unknown[]) => arr.length > 0, {
        exclude: difference(pathOr([], [filter, 'exclude'], state), [value]),
        include: difference(pathOr([], [filter, 'include'], state), [value]),
      })

      return !isEmpty(tristateValue)
        ? {
            ...state,
            [filter]: tristateValue,
          }
        : omit([filter], state)
    }
    case 'filter/HYDRATE': {
      const filters = action.payload ?? {}

      return reject(isNilOrEmpty, {
        ...filters,
        docCodeFilter: (
          filters?.docCodeFilter as unknown as Array<Record<'docCode', string>> | undefined
        )?.map(({ docCode }) => ({ docCode })),
      })
    }
    default:
      return state
  }
}

export default selected
