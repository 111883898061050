import { parse } from 'qs'
import { applyMiddleware, createStore as createReduxStore } from 'redux'

import middleware, { runSagas } from './middleware'
import reducers from './reducers'
import getInitialState from './utils/getInitialState'

const createStore = () => {
  const initialState = getInitialState(
    parse(window.location.search, { arrayLimit: 1000, ignoreQueryPrefix: true })
  )

  const store = createReduxStore(reducers, initialState, applyMiddleware(...middleware))

  runSagas()

  return store
}

export default createStore
