import React, { useCallback, useState } from 'react'

import { DemoModeToggleContext, DemoModeValueContext } from '../contexts'

const DemoModeContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [demoMode, setDemoMode] = useState(false)
  const toggleDemoMode = useCallback(() => setDemoMode((state) => !state), [])

  return (
    <DemoModeToggleContext.Provider value={toggleDemoMode}>
      <DemoModeValueContext.Provider value={demoMode}>{children}</DemoModeValueContext.Provider>
    </DemoModeToggleContext.Provider>
  )
}

export default DemoModeContextProvider
