import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Configure = (props: IconProps) => (
  <SVG title="configure" viewBox="0 0 14 14" {...props}>
    <path d="M12.6236 5.9265L11.1301 5.74002C11.0601 5.46002 10.9436 5.20354 10.8036 4.97002L11.7371 3.78002C11.8071 3.6865 11.8071 3.5465 11.7136 3.4765L10.5236 2.2865C10.4301 2.19298 10.3136 2.19298 10.2201 2.26298L9.0301 3.1965C8.77362 3.0565 8.51658 2.96298 8.23658 2.87002L8.0501 1.3765C8.02659 1.26002 7.93362 1.1665 7.81658 1.1665H6.13658C6.0201 1.1665 5.92658 1.26002 5.90306 1.3765L5.71658 2.87002C5.43658 2.96354 5.1801 3.0565 4.94658 3.1965L3.75658 2.26298C3.66307 2.19298 3.52306 2.19298 3.45306 2.2865L2.26306 3.4765C2.19306 3.57002 2.16955 3.6865 2.23955 3.78002L3.17307 4.97002C3.03307 5.2265 2.93955 5.48354 2.84659 5.74002L1.35307 5.9265C1.23658 5.95002 1.14307 6.04298 1.14307 6.16002V7.84002C1.14307 7.9565 1.23658 8.05002 1.35307 8.07354L2.84659 8.26002C2.91659 8.54002 3.03307 8.7965 3.17307 9.03002L2.23955 10.22C2.16955 10.3135 2.16955 10.4535 2.26306 10.5235L3.45306 11.7135C3.54658 11.8071 3.66306 11.8071 3.75658 11.7371L4.94658 10.8035C5.20306 10.9435 5.4601 11.0371 5.71658 11.13L5.90306 12.6235C5.92658 12.74 6.01955 12.8335 6.13658 12.8335H7.81658C7.93307 12.8335 8.02658 12.74 8.0501 12.6235L8.23658 11.13C8.51658 11.0365 8.77306 10.9435 9.0301 10.8035L10.2201 11.7371C10.3136 11.8071 10.4536 11.8071 10.5236 11.7135L11.7136 10.5235C11.7836 10.43 11.8071 10.3135 11.7371 10.22L10.8036 9.03002C10.9436 8.77354 11.0371 8.5165 11.1301 8.26002L12.6236 8.07354C12.7401 8.05002 12.8336 7.95705 12.8336 7.84002V6.16002C12.8336 6.04353 12.7401 5.95002 12.6236 5.9265ZM7.0001 9.9865C5.34362 9.9865 4.01362 8.6565 4.01362 7.00002C4.01362 5.34354 5.34362 4.01354 7.0001 4.01354C8.63362 4.01354 9.98658 5.34354 9.98658 7.00002C9.98658 8.63354 8.6336 9.9865 7.0001 9.9865Z" />
  </SVG>
)

export default Configure
