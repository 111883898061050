import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const CSquare = (props: IconProps) => (
  <SVG title="c-square" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1H14C15.6569 1 17 2.34315 17 4V14C17 15.6569 15.6569 17 14 17H4C2.34315 17 1 15.6569 1 14V4C1 2.34315 2.34315 1 4 1ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4ZM6.55518 12.2002C7.10954 12.818 7.8903 13.127 8.89746 13.127C9.60417 13.127 10.1903 13.0063 10.6558 12.7651V11.832C10.0464 12.0817 9.45182 12.2065 8.87207 12.2065C8.19499 12.2065 7.68294 11.978 7.33594 11.521C6.99316 11.064 6.82178 10.408 6.82178 9.55322C6.82178 7.75049 7.52848 6.84912 8.94189 6.84912C9.15348 6.84912 9.39681 6.88086 9.67188 6.94434C9.94694 7.00358 10.2008 7.07975 10.4336 7.17285L10.7573 6.27783C10.5669 6.1805 10.3045 6.09587 9.97021 6.02393C9.63591 5.95199 9.30159 5.91602 8.96729 5.91602C7.9305 5.91602 7.1307 6.2334 6.56787 6.86816C6.00928 7.50293 5.72998 8.40218 5.72998 9.56592C5.72998 10.7 6.00505 11.5781 6.55518 12.2002Z"
    />
  </SVG>
)

export default CSquare
