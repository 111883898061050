import React from 'react'
import { Link } from 'react-router-dom'

import FooterItem from './FooterItem'

export default function FooterLink({ text, to }: { text: string; to: string }) {
  if (to.includes('://')) {
    return (
      <FooterItem>
        <a
          className="text-link-blue visited:text-link-blue hover:text-shakespeare text-sm transition-colors duration-100"
          href={to}
          rel="noreferrer"
          target="_blank"
        >
          {text}
        </a>
      </FooterItem>
    )
  }

  return (
    <FooterItem>
      <Link
        className="text-link-blue visited:text-link-blue hover:text-shakespeare text-sm transition-colors duration-100"
        to={to}
      >
        {text}
      </Link>
    </FooterItem>
  )
}
