import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Airplane = (props: IconProps) => (
  <SVG title="airplane" viewBox="0 0 16 16" {...props}>
    <path d="M2.32788 6.52416L5.90792 8.50307L9.74694 6.03057C9.8103 5.9901 9.89351 5.99885 9.94671 6.05197C9.99991 6.1051 10.0089 6.18838 9.96819 6.25166L7.49743 10.0936L9.47679 13.6729L13.6722 2.32666L2.32788 6.52416Z" />
  </SVG>
)

export default Airplane
