import { call, put, select, takeLatest } from 'redux-saga/effects'

import { BoundAction } from '../../../redux'
import { graphqlApi } from '../../api'
import dateRangeActions from '../../dateRange/actions'
import { getNumMonths } from '../../dateRange/selectors'
import { SetDateRangeAction } from '../../dateRange/types'
import { HttpStatus } from '../../http/types'
import actions from '../actions'
import * as getTopEntitiesQuery from '../queries/getTopEntities.graphql'
import { getTopAssignees, getTopFirms } from '../selectors'
import {
  EntityType,
  FetchTopAssigneesAction,
  FetchTopFirmsAction,
  SetTopAssigneesAction,
  SetTopFirmsAction,
  TopEntityByPatentsIssuedResponse,
} from '../types'
import { getEntityQueryVariables } from '../utils/getEntityQueryVariables'
import getTop5EntitiesFromResponse from '../utils/getTop5EntitiesFromResponse'

type FetchTopEntitiesParams = {
  entityType: EntityType
  selector: typeof getTopAssignees | typeof getTopFirms
  set: BoundAction<SetTopAssigneesAction> | BoundAction<SetTopFirmsAction>
}

const makeFetchTopEntities = ({ entityType, selector, set }: FetchTopEntitiesParams) =>
  function* (action: FetchTopAssigneesAction | FetchTopFirmsAction | SetDateRangeAction) {
    const topEntitiesState = yield select(selector)
    if (topEntitiesState.type === HttpStatus.Success && action.type !== 'dateRange/SET') {
      return
    }
    const numMonths = yield select(getNumMonths)

    yield put(set({ type: HttpStatus.Fetching }))

    try {
      const variables = getEntityQueryVariables({ entityType, numMonths })
      const result = yield call(graphqlApi, getTopEntitiesQuery, variables)
      if (!result.ok) {
        yield put(set({ message: `Error fetching top ${entityType}`, type: HttpStatus.Error }))
        return
      }
      const response: TopEntityByPatentsIssuedResponse = yield call([result, 'json'])
      const topFirms = getTop5EntitiesFromResponse(response)
      yield put(
        set({
          data: topFirms,
          type: HttpStatus.Success,
        })
      )
    } catch (e) {
      yield put(set({ message: `Error fetching top ${entityType}`, type: HttpStatus.Error }))
    }
  }

const fetchTopAssignees = makeFetchTopEntities({
  entityType: EntityType.Assignees,
  selector: getTopAssignees,
  set: actions.setTopAssignees,
})

const fetchTopFirms = makeFetchTopEntities({
  entityType: EntityType.Firms,
  selector: getTopFirms,
  set: actions.setTopFirms,
})

function* watchFetchTopAssignees() {
  yield takeLatest(
    [actions.fetchTopAssignees().type, dateRangeActions.setNumMonths().type],
    fetchTopAssignees
  )
}

function* watchFetchTopFirms() {
  yield takeLatest(
    [actions.fetchTopFirms().type, dateRangeActions.setNumMonths().type],
    fetchTopFirms
  )
}

export {
  FetchTopEntitiesParams,
  fetchTopAssignees,
  fetchTopFirms,
  getEntityQueryVariables,
  watchFetchTopAssignees,
  watchFetchTopFirms,
}
