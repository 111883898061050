import { useService } from '@xstate/react'
import { useCallback, useEffect } from 'react'

import { FetchTypeState } from '../types'
import useServicesMeta from './useServicesMeta'

type Options = {
  enabled: boolean
}

// Internal hook and shouldn't be exposed outside api module
export default function useFetchMachine<T, R>(key: string, { enabled }: Options) {
  const services = useServicesMeta()

  // Safe to use `!` here because we make sure it exists with `useEnsureServiceMeta`
  const { service } = services.get(key)!
  const [current, send] = useService(service)

  useEffect(() => {
    if (current.matches('idle') && enabled) {
      send('FETCH')
    }

    const meta = services.get(key)

    if (meta) {
      if (meta.cacheTimeout) {
        clearTimeout(meta.cacheTimeout)
      }

      meta.subscribers += 1

      return () => {
        meta.subscribers -= 1

        if (meta.subscribers === 0) {
          // Cache service for 5 minutes if we lost all our subscribers
          meta.cacheTimeout = setTimeout(
            () => {
              services.delete(key)
            },
            5 * 60 * 1000
          )
        }
      }
    }
  }, [current, enabled])
  const refetch = useCallback(
    (payload?: Record<string, unknown>) => {
      send({ ...payload, type: 'FETCH' })
    },
    [send]
  )
  const refresh = useCallback(() => {
    send('REFETCH')
  }, [send])
  const reset = useCallback(() => {
    send('RESET')
  }, [send])
  const retry = useCallback(() => {
    send('RETRY')
  }, [send])
  const setData = useCallback(
    (data: T) => {
      send({ type: 'SET_DATA', data })
    },
    [send]
  )

  return [
    current as FetchTypeState<T, R>,
    { refetch, refresh, reset, retry, setData },
    send,
  ] as const
}
