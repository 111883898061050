import cuid from 'cuid'
import * as React from 'react'

type FileInputProps = {
  children: React.ReactNode
  className?: string
  containerClassName?: string
  handleFileChange: (files: FileList) => void
  id?: string
}

const FileInput = ({
  children,
  className,
  containerClassName,
  handleFileChange,
  id = cuid(),
}: FileInputProps) => {
  return (
    <div className={className}>
      <input
        data-testid="file-input"
        className="hidden"
        disabled={false}
        id={id}
        onChange={(event) => {
          event.preventDefault()

          if ((event.target.files ?? []).length < 1) {
            return
          }

          handleFileChange(event.target.files as FileList)
        }}
        type="file"
        value={undefined}
      />
      <label className={containerClassName} htmlFor={id}>
        {children}
      </label>
    </div>
  )
}

export default FileInput
