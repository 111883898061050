import { includes, without } from 'ramda'
import React, { useMemo } from 'react'

import { useToggle } from '../../../hooks'
import { BulkAlertsLoadingContext, SelectedAlertsContext, ToggleModalContext } from '../contexts'
import ManageRecipientsModal from './ManageRecipientsModal'

type BulkAlertsContextProviderProps<T> = {
  children: React.ReactNode
  items: T[]
  loading: boolean
  setItems: React.Dispatch<React.SetStateAction<T[]>>
}

const BulkAlertsContextProvider = <T,>({
  children,
  items,
  loading,
  setItems,
}: BulkAlertsContextProviderProps<T>) => {
  const [show, toggle] = useToggle()
  const alertActions = useMemo(() => {
    const selected = (payload: T) => includes(payload, items)

    return {
      selected,
      update: (payload: T) =>
        setItems((state) => (selected(payload) ? without([payload], state) : [...state, payload])),
    }
  }, [items, setItems])

  return (
    <ToggleModalContext.Provider value={toggle}>
      <BulkAlertsLoadingContext.Provider value={loading}>
        <SelectedAlertsContext.Provider value={alertActions}>
          <ManageRecipientsModal items={items as number[]} show={show} toggle={toggle} />
          {children}
        </SelectedAlertsContext.Provider>
      </BulkAlertsLoadingContext.Provider>
    </ToggleModalContext.Provider>
  )
}

export default BulkAlertsContextProvider
