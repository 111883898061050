import { DataSource } from '@juristat/common/types'

import { Action } from '../../redux'

enum ExportStatusType {
  Complete = 'Complete',
  Error = 'Error',
  Queued = 'Queued',
  Running = 'Running',
  Submitted = 'Submitted', // Exists only on the client
}

type ExportError = {
  lastUpdated: number
  status: ExportStatusType.Error
}

type ExportQueued = {
  downloadUrl: null
  lastUpdated: number
  progress: 0
  status: ExportStatusType.Queued
}

type ExportSubmitted = {
  lastUpdated: number
  status: ExportStatusType.Submitted
}

type ExportRunning = {
  downloadUrl: null
  lastUpdated: number
  progress: number
  status: ExportStatusType.Running
}

type ExportComplete = {
  downloadUrl: string
  lastUpdated: number
  progress: 100
  status: ExportStatusType.Complete
}

type ExportStatus = ExportError | ExportComplete | ExportQueued | ExportSubmitted | ExportRunning

type ExportStatusGraphQLResponse<T> = GraphQLResponse<{
  exportStatus: T
}>

type ExportsState = {
  appnos: {
    [appno: string]: string
  }
  jobs: {
    [jobId: string]: ExportStatus
  }
}

type ExportsMeta = {
  appno?: string
  exportName?: string
  timeout?: number
  skipNotification?: boolean
}

type ExportsPushPayload = {
  dataSource: DataSource
  jobId: string
}

type ExportsUpdatePayload = {
  jobId: string
  status: ExportStatus
}

type ExportsPushAction = Action<'exports/PUSH', ExportsPushPayload, ExportsMeta>
type ExportsUpdateAction = Action<'exports/UPDATE', ExportsUpdatePayload>

type ReducerActions = ExportsPushAction | ExportsUpdateAction

export {
  ExportStatus,
  ExportStatusType,
  ExportStatusGraphQLResponse,
  ExportsMeta,
  ExportsPushAction,
  ExportsState,
  ExportsUpdateAction,
  ReducerActions,
}
