import { Charts, MissingPermission } from '@juristat/common/types'

import { Action } from '../../redux'
import { GridItemType } from '../grid-items'
import { HttpContent } from '../http'
import { IntelligenceEntityType } from '../intelligence/types'

type ExportableConfig<T> = {
  filename: string
  getData: (datum: T) => Array<Array<number | string>>
  getHeader: (datum: T) => Array<number | string>
}

enum ChartType {
  Breakdown = 'breakdown',
  Compare = 'compare',
  Overview = 'overview',
  Search = 'search',
}

type ChartProps = {
  draggable?: boolean
  gridItem?: {
    chart: Charts
    description?: string
    type: GridItemType.Chart
  }
  id: string | null
  lookups: Lookup[]
}

type ChartStateKey = IntelligenceEntityType | ChartType

type Key = {
  entity: IntelligenceEntityType
  id: string | number
  type: ChartType
}

type KeyWithChart = Pick<Lookup, keyof Key | 'filterKey'> & { chart: Charts }

type Lookup = Key & {
  color?: string
  // Optional key to get application filter state from
  filterKey?: Omit<Key, 'type'>
  label: string
}

type ChartHttpContent<T> = HttpContent<T> | MissingPermission

type ChartContent = {
  [Charts.AllowanceRatesOverTime]: AllowanceRatesOverTime[]
  [Charts.DaysToDispositionOverTime]: DaysToDisposition[]
  [Charts.DaysToFirstResponseOverTime]: DaysToFirstResponse[]
  [Charts.Extensions]: Extensions[]
  [Charts.FilingsOverTime]: FilingsOverTime[]
  [Charts.DependentClaimsOverTime]: ClaimsChange[]
  [Charts.IndependentClaimsOverTime]: ClaimsChange[]
  [Charts.IssuedPatentsOverTime]: IssuedPatentsOverTime[]
  [Charts.WordsPerDependentClaimOverTime]: WordsPerClaim[]
  [Charts.WordsPerIndependentClaimOverTime]: WordsPerClaim[]
  [key: string]: any[]
}

type ChartReducerState = Partial<{ [C in keyof ChartContent]: ChartHttpContent<C> }>

type ChartState = Partial<{
  [K in ChartStateKey]: {
    [id: string]: {
      filteredBy: { [I in ChartStateKey]: { [id: string]: ChartReducerState } }
      main: ChartReducerState
    }
  }
}>

type FetchAction = Action<'chart/FETCH', KeyWithChart, Omit<Key, 'type'>>
type SetAction = Action<'chart/SET', ChartReducerState[Charts], Omit<KeyWithChart, 'type'>>

type FetchActions = FetchAction

type ReducerActions = SetAction

export {
  ChartContent,
  ChartHttpContent,
  ChartProps,
  ChartType,
  ChartReducerState,
  ChartState,
  Charts,
  ExportableConfig,
  FetchAction,
  FetchActions,
  Key,
  KeyWithChart,
  Lookup,
  ReducerActions,
  SetAction,
}

/**
 * Types pulled over from `search/modules/charts`
 */

export type Buckets = Array<{
  name: string
  value: string
}>

export enum BucketType {
  DispositionDate = 'DISPOSITION_DATE',
  FilingDate = 'FILING_DATE',
  IssuanceDate = 'ISSUANCE_DATE',
  OfficeActionYear = 'OFFICE_ACTION_YEAR',
  TechCenter = 'TECH_CENTER',
}

type Metrics<T> = {
  metrics: T[]
}

type AllowanceRateWithBuckets = {
  allowanceRate: number
  buckets: Buckets
}

export type AllowanceRatesByTechCenterResponse = AllowanceRateWithBuckets

export type AllowanceRatesOverTimeResponse = AllowanceRateWithBuckets

export type ApplicationStatusResponse = {
  applicationCounts: {
    abandoned: number
    allowed: number
    issued: number
    pending: number
  }
}

export type DependentClaimsChangeResponse = {
  buckets: Buckets
  claims: {
    lost: {
      dependentClaims: {
        average: number | null
      }
    }
  }
}

export type IndependentClaimsChangeResponse = {
  buckets: Buckets
  claims: {
    lost: {
      independentClaims: {
        average: number | null
      }
    }
  }
}

export type WordsPerDependentClaimResponse = {
  buckets: Buckets
  claims: {
    lost: {
      wordsPerDependentClaims: {
        average: number | null
      }
    }
  }
}

export type WordsPerIndependentClaimResponse = {
  buckets: Buckets
  claims: {
    lost: {
      wordsPerIndependentClaims: {
        average: number | null
      }
    }
  }
}

export type DaysToDispositionResponse = {
  buckets: Buckets
  timing: {
    daysToDisposition: {
      average: number | null
    }
  }
}

export type DaysToFirstResponseOverTimeResponse = {
  buckets: Buckets
  timing: {
    daysToFirstResponse: {
      average: number | null
    }
  }
}

enum ExtensionsEnum {
  HaveExtension = 'haveExtension',
  Total = 'total',
}

export type ExtensionCounts = { [key in ExtensionsEnum]: number }

export type ExtensionsResponse = {
  buckets: Buckets
  counts: ExtensionCounts
}

export type OfficeActionsByDispositionYearResponse = {
  buckets: Buckets
  officeActions: {
    toAbandonment: {
      average: number | null
    }
    toAllowance: {
      average: number | null
    }
    toDisposition: {
      average: number | null
    }
  }
}

export type RejectionBasesOverTimeResponse = {
  buckets: Buckets
  counts: RejectionBasisCounts
}

export type FilingsByTechCenterResponse = {
  applicationCounts: {
    total: number
  }
  buckets: Array<{
    name: BucketType
    value: number
  }>
}

export type FilingsOverTimeResponse = {
  applicationCounts: {
    total: number
  }
  buckets: Buckets
}

export type IssuedPatentsResponse = {
  applicationCounts: {
    issued: number
  }
  buckets: Buckets
}

type OfficeActionMetrics<T> = {
  officeActionSet: Metrics<T>
}

export type ChartsGraphQLResponse = {
  [Charts.AllowanceRatesByTechCenter]: Metrics<AllowanceRatesByTechCenterResponse>
  [Charts.AllowanceRatesOverTime]: Metrics<AllowanceRatesOverTimeResponse>
  [Charts.ApplicationStatus]: Metrics<ApplicationStatusResponse>
  [Charts.AverageOfficeActionsOverTime]: Metrics<OfficeActionsByDispositionYearResponse>
  [Charts.DependentClaimsOverTime]: Metrics<DependentClaimsChangeResponse>
  [Charts.DaysToDispositionOverTime]: Metrics<DaysToDispositionResponse>
  [Charts.DaysToFirstResponseOverTime]: OfficeActionMetrics<DaysToFirstResponseOverTimeResponse>
  [Charts.DivisionOfWork]: Metrics<FilingsOverTimeResponse>
  [Charts.Extensions]: OfficeActionMetrics<ExtensionsResponse>
  [Charts.FilingsByTechCenter]: Metrics<FilingsByTechCenterResponse>
  [Charts.FilingsOverTime]: Metrics<FilingsOverTimeResponse>
  [Charts.IssuedPatentsByTechCenterOverTime]: Metrics<IssuedPatentsResponse>
  [Charts.IssuedPatentsOverTime]: Metrics<IssuedPatentsResponse>
  [Charts.OfficeActionsByDispositionYear]: Metrics<OfficeActionsByDispositionYearResponse>
  [Charts.RejectionBasesOverTime]: OfficeActionMetrics<RejectionBasesOverTimeResponse>
  [Charts.IndependentClaimsOverTime]: Metrics<IndependentClaimsChangeResponse>
  [Charts.WordsPerDependentClaimOverTime]: Metrics<WordsPerDependentClaimResponse>
  [Charts.WordsPerIndependentClaimOverTime]: Metrics<WordsPerIndependentClaimResponse>
}

export type XY = {
  x: number
  y: number
}

export type IdValue = {
  id: string
  value: number
}

export type AllowanceRatesByTechCenter = {
  allowanceRate: number
  techCenter: number
}

export type AllowanceRatesOverTime = {
  allowanceRate: number
  year: number
}

export type ApplicationStatus = IdValue

export type AverageOfficeActionsOverTime = {
  officeActions: number
  year: number
}

export enum ChartSize {
  Large,
  Small,
}

export type ClaimsChange = {
  claims: number | null
  year: number
}

export type DaysToDisposition = {
  daysToDisposition: number | null
  year: number
}

export type DaysToFirstResponse = {
  daysToFirstResponse: number | null
  year: number
}

export type Extensions = {
  extensions: number
  total: number
  year: number
}

export type FilingsByTechCenter = {
  filings: number
  techCenter: number
  year: number
}

export type FilingsOverTime = {
  filings: number
  year: number
}

export type IssuedPatentsByTechCenter = {
  issued: number
  techCenter: number
  year: number
}

export type IssuedPatentsOverTime = {
  issued: number
  year: number
}

export type OfficeActionsByDispositionYear = {
  toAbandonment: number
  toAllowance: number
  toDisposition: number
  year: number
}

export enum RejectionBasesEnum {
  Alice = 'haveTypeAlice',
  Bilski = 'haveTypeBilski',
  DoublePatenting = 'haveTypeDoublePatenting',
  MayoMyriad = 'haveTypeMayoMyriad',
  Type101 = 'haveType101',
  Type102 = 'haveType102',
  Type103 = 'haveType103',
  Type112A = 'haveType112a',
  Type112B = 'haveType112b',
  Type171 = 'haveType171',
}

type RejectionBasisCounts = { [key in RejectionBasesEnum]: number }

export type RejectionBasesOverTime = {
  counts: RejectionBasisCounts
  year: number
}

export type WordsPerClaim = {
  words: number | null
  year: number
}

type PlatformChartsInState =
  | Charts.AllowanceRatesByTechCenter
  | Charts.AllowanceRatesOverTime
  | Charts.ApplicationStatus
  | Charts.AverageOfficeActionsOverTime
  | Charts.DaysToDispositionOverTime
  | Charts.DaysToFirstResponseOverTime
  | Charts.DependentClaimsOverTime
  | Charts.DivisionOfWork
  | Charts.Extensions
  | Charts.FilingsByTechCenter
  | Charts.FilingsOverTime
  | Charts.IndependentClaimsOverTime
  | Charts.IssuedPatentsByTechCenterOverTime
  | Charts.IssuedPatentsOverTime
  | Charts.OfficeActionsByDispositionYear
  | Charts.RejectionBasesOverTime
  | Charts.WordsPerDependentClaimOverTime
  | Charts.WordsPerIndependentClaimOverTime

type ChartsInState = PlatformChartsInState

type PlatformChartsData = {
  [Charts.AllowanceRatesByTechCenter]: Array<Datum<AllowanceRatesByTechCenter>>
  [Charts.AllowanceRatesOverTime]: Array<Datum<AllowanceRatesOverTime>>
  [Charts.ApplicationStatus]: Array<Datum<ApplicationStatus>>
  [Charts.AverageOfficeActionsOverTime]: Array<Datum<AverageOfficeActionsOverTime>>
  [Charts.DaysToDispositionOverTime]: Array<Datum<DaysToDisposition>>
  [Charts.DaysToFirstResponseOverTime]: Array<Datum<DaysToFirstResponse>>
  [Charts.DependentClaimsOverTime]: Array<Datum<ClaimsChange>>
  [Charts.DivisionOfWork]: Array<Datum<FilingsOverTime>>
  [Charts.Extensions]: Array<Datum<Extensions>>
  [Charts.FilingsByTechCenter]: Array<Datum<FilingsByTechCenter>>
  [Charts.FilingsOverTime]: Array<Datum<FilingsOverTime>>
  [Charts.IndependentClaimsOverTime]: Array<Datum<ClaimsChange>>
  [Charts.IssuedPatentsByTechCenterOverTime]: Array<Datum<IssuedPatentsByTechCenter>>
  [Charts.IssuedPatentsOverTime]: Array<Datum<IssuedPatentsOverTime>>
  [Charts.OfficeActionsByDispositionYear]: Array<Datum<OfficeActionsByDispositionYear>>
  [Charts.RejectionBasesOverTime]: Array<Datum<RejectionBasesOverTime>>
  [Charts.WordsPerDependentClaimOverTime]: Array<Datum<WordsPerClaim>>
  [Charts.WordsPerIndependentClaimOverTime]: Array<Datum<WordsPerClaim>>
}

type PlatformChartsDataState = {
  [C in keyof PlatformChartsData]: PlatformChartsData[C] extends Array<Datum<infer U>>
    ? ChartHttpContent<U[]>
    : never
}

type PlatformChartsQueryState = {
  [C in keyof PlatformChartsData]: PlatformChartsData[C] extends Array<infer U> ? U : never
}

type ChartsDataState = PlatformChartsDataState

type ChartData = PlatformChartsData

type Datum<T> = {
  color?: string
  data: T[]
  id: string
}

type Transform<I, O> = (data: Array<Datum<I>>) => O[]

type TransformBar<I, O = WeakObject> = Transform<I, O>
type TransformLine<I> = Transform<I, Datum<XY>>

export {
  ChartData,
  ChartsInState,
  ChartsDataState,
  Metrics,
  OfficeActionMetrics,
  Datum,
  PlatformChartsData,
  PlatformChartsDataState,
  PlatformChartsInState,
  PlatformChartsQueryState,
  RejectionBasisCounts,
  Transform,
  TransformBar,
  TransformLine,
}
