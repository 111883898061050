import { css, cx } from 'emotion'
import {
  getResponsiveStyle,
  getStaticStyle,
  useData,
  useDrag,
  useGrid,
  useRefresh,
} from 'muuri-react'
import React, { useContext, useEffect, useState } from 'react'

import { useMuuriActiveItemKey } from '../../hooks'
import { GridItemProps } from '../../types'

type BaseGridItemProps = GridItemProps & {
  children?: React.ReactNode
  className?: string
}

const styles = {
  behind: css({
    zIndex: -1,
  }),
  content: css({
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  }),
}

const defaultStyleOptions = {
  columns: 4 / 8,
  height: 350,
  margin: '10px',
}

const StyleContext = React.createContext<((style: any) => void) | undefined>(undefined)

const BaseGridItem: React.FC<BaseGridItemProps> = ({
  children,
  className,
  data,
  itemKey,
  ...props
}) => {
  const { grid } = useGrid()
  const isDragging = useDrag()
  const activeItemKey = useMuuriActiveItemKey()

  const [styleOptions, setStyleOptions] = useState({
    ...defaultStyleOptions,
    ...props.style,
  })

  const style = isDragging
    ? getStaticStyle({ grid, ...styleOptions })
    : getResponsiveStyle(styleOptions)

  useData(data)
  useRefresh([style])

  useEffect(() => {
    setStyleOptions({ ...defaultStyleOptions, ...props.style })
  }, [props.style])

  const setStyleOptionsContext = useContext(StyleContext)

  if (setStyleOptionsContext) {
    setStyleOptionsContext({ ...defaultStyleOptions, ...props.style })

    return <>{children}</>
  }

  return (
    <StyleContext.Provider value={setStyleOptions}>
      <div
        className={cx(
          css(style),
          { [styles.behind]: Boolean(activeItemKey) && activeItemKey !== itemKey },
          className
        )}
      >
        <div className={styles.content}>{children}</div>
      </div>
    </StyleContext.Provider>
  )
}

export { GridItemProps }
export default BaseGridItem
