import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Download = (props: IconProps) => (
  <SVG title="download" viewBox="0 0 11 11" {...props}>
    <path d="M5.4999 0.936035C5.6166 0.936035 5.72851 0.982392 5.81103 1.06491C5.89354 1.14742 5.9399 1.25934 5.9399 1.37603V6.86943L6.9552 5.89813C7.03951 5.81732 7.15247 5.77331 7.26923 5.77579C7.38599 5.77827 7.49699 5.82702 7.5778 5.91133C7.65861 5.99565 7.70262 6.10861 7.70014 6.22537C7.69767 6.34213 7.64891 6.45312 7.5646 6.53393L5.8046 8.21803C5.72266 8.29669 5.61348 8.34061 5.4999 8.34061C5.38632 8.34061 5.27714 8.29669 5.1952 8.21803L3.4352 6.53393C3.35089 6.45312 3.30213 6.34213 3.29966 6.22537C3.29718 6.10861 3.34119 5.99565 3.422 5.91133C3.50281 5.82702 3.61381 5.77827 3.73057 5.77579C3.84733 5.77331 3.96029 5.81732 4.0446 5.89813L5.0599 6.86943V1.37603C5.0599 1.25934 5.10626 1.14742 5.18877 1.06491C5.27129 0.982392 5.38321 0.936035 5.4999 0.936035Z" />
    <path d="M1.09991 6.82014C0.983217 6.82014 0.871301 6.8665 0.788785 6.94901C0.706269 7.03153 0.659912 7.14344 0.659912 7.26014V8.58014C0.659912 9.04692 0.84534 9.49458 1.1754 9.82465C1.50547 10.1547 1.95313 10.3401 2.41991 10.3401H8.57991C9.04669 10.3401 9.49436 10.1547 9.82442 9.82465C10.1545 9.49458 10.3399 9.04692 10.3399 8.58014V7.26014C10.3399 7.14344 10.2936 7.03153 10.211 6.94901C10.1285 6.8665 10.0166 6.82014 9.89991 6.82014C9.78322 6.82014 9.6713 6.8665 9.58879 6.94901C9.50627 7.03153 9.45991 7.14344 9.45991 7.26014V8.58014C9.45991 8.81353 9.3672 9.03736 9.20217 9.20239C9.03713 9.36742 8.8133 9.46014 8.57991 9.46014H2.41991C2.18652 9.46014 1.96269 9.36742 1.79766 9.20239C1.63263 9.03736 1.53991 8.81353 1.53991 8.58014V7.26014C1.53991 7.14344 1.49356 7.03153 1.41104 6.94901C1.32852 6.8665 1.21661 6.82014 1.09991 6.82014Z" />
  </SVG>
)

export default Download
