import { css } from 'emotion'
import * as React from 'react'

import { colors, textStyles } from '../../../../styles'
import { noop } from '../../../../utils'
import ActiveFilterIcon from '../../../filter/components/ActiveFilterIcon'

type ListItemProps = {
  active?: boolean
  handleClick: () => void
  label: string
  numResults?: number
}

const styles = {
  appsLabel: css(textStyles.paleGray2Semibold14, {
    opacity: 0.7,
  }),
  appsLabelActive: css({
    color: colors.greenyBlue,
    opacity: 1,
  }),
  containerRight: css({
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
    paddingLeft: 4,
  }),
  label: css(textStyles.paleGray2Semibold14, {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  main: css({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
  }),
}

const ListItem = ({ active = false, handleClick, label, numResults }: ListItemProps) => (
  <div className={styles.main} onClick={!active ? handleClick : noop}>
    <h2 className={styles.label} title={label}>
      {label}
    </h2>
    <div className={styles.containerRight}>
      {active && <ActiveFilterIcon />}
      {numResults && (
        <small className={css(styles.appsLabel, active && styles.appsLabelActive)}>
          {numResults.toLocaleString()}
        </small>
      )}
    </div>
  </div>
)

export default ListItem
