import config from '@juristat/config'
import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../../../notification'
import actions from '../actions'
import { TokenExpireAction } from '../types'

export function* expireGroupToken({ payload }: TokenExpireAction) {
  const url = `${config.accountsUrl}/group/${payload}/expire-token`
  const method = 'post'
  const body = JSON.stringify({ token: payload })
  const options = {
    body,
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    method,
    url,
  }
  const notificationAction = notificationActions.push(
    makeNotification({
      message: `Failed to expire token ${payload!}.`,
      type: NotificationTypes.Error,
    })
  )

  try {
    const response = yield call(api, url, options)
    if (response.ok) {
      yield put(actions.token.invalidate(payload!))
    } else {
      yield put(notificationAction)
    }
  } catch {
    yield put(notificationAction)
  }
}

function* watchExpireGroupToken() {
  yield takeLatest([actions.token.expire().type], expireGroupToken)
}

export default watchExpireGroupToken
