import * as React from 'react'

type JuristatLoaderProps = {
  className?: string
}

const styles = {
  container: 'items-center flex h-screen justify-center p-8 text-center w-full',
  svg: 'fill-apple-green flex-auto mb-8 max-h-32 max-w-32',
}

const JuristatLoader = ({ className }: JuristatLoaderProps) => (
  <div className={`${styles.container} ${className ?? ''}`} data-testid="Juristat-loader">
    <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <defs>
        <clipPath id="loaderWaveClip">
          <rect x="170" y="0" width="300" height="500" />
        </clipPath>
      </defs>
      <path
        id="Frank_J"
        d="M 120.91,375.45
            C 120.91,375.45 136.36,484.51 136.36,484.51
            136.36,484.51 180.83,490.00 209.96,490.00
            239.09,490.00 295.76,487.58 336.97,444.85
            378.18,402.12 378.18,339.70 378.18,319.39
            378.18,299.09 378.18,10.00 378.18,10.00
            378.18,10.00 239.09,10.00 239.09,10.00
            239.09,10.00 239.09,311.82 239.09,323.03
            239.09,334.24 235.45,380.91 179.39,380.91
            153.94,380.91 120.91,375.45 120.91,375.45 Z"
      />
      <g clipPath="url(#loaderWaveClip)">
        <path
          id="wave"
          fill="none"
          stroke="white"
          strokeWidth="22"
          strokeLinecap="round"
          d="M 970.00,369.55
              C 849.09,371.36 858.18,66.36 772.73,66.36M 575.45,369.55
              C 696.36,371.36 687.27,66.36 772.73,66.36M 575.45,369.55
              C 454.55,371.36 463.64,66.36 378.18,66.36M 180.91,369.55
              C 301.82,371.36 292.73,66.36 378.18,66.36M 378.00,86.00"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="translate"
            from="0"
            to="-393"
            dur="1.6s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  </div>
)

export default JuristatLoader
