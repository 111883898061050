import { useEffect } from 'react'

type Handler = () => void

export function usePrintHandlers(beforePrintHandler: Handler, afterPrintHandler: Handler) {
  useEffect(() => {
    window.addEventListener('afterprint', afterPrintHandler)
    window.addEventListener('beforeprint', beforePrintHandler)

    return () => {
      window.removeEventListener('afterprint', afterPrintHandler)
      window.removeEventListener('beforeprint', beforePrintHandler)
    }
  }, [afterPrintHandler, beforePrintHandler])
}
