import { activeGroupClaimKey, activeGroupNameKey } from '@juristat/common/constants'
import { parse } from 'qs'
import { createSelector } from 'reselect'

import { AppState } from '../../../redux'
import { HttpStatus } from '../../http'
import { getQueryString } from '../../router'
import { AuthProvider } from '../types'

const getAuth = (state: AppState) => state.auth

const getAccessToken = createSelector(getAuth, (state) => state.accessToken)
const getAccessTokenId = createSelector(getAuth, (state) => state.accessTokenId)

const getAuthMethods = createSelector(getAuth, (state) => state.authMethods)

const getAuthProviderFromLocation = createSelector(getQueryString, (query: string) => {
  const { authprovider: authProvider } = parse(query, { ignoreQueryPrefix: true })
  return authProvider
})

const getAuthMethodsWithSpecified = createSelector(
  getAuthMethods,
  getAuthProviderFromLocation,
  (authMethods, providerFromLocation) => {
    if (!providerFromLocation || authMethods.type !== HttpStatus.Success) {
      return authMethods
    }

    const juristatProvider = authMethods.data.find(
      (authMethod) => authMethod.authProviderKey === AuthProvider.Juristat
    )
    if (juristatProvider && providerFromLocation === AuthProvider.Passwordless) {
      return {
        ...authMethods,
        data: [
          {
            ...juristatProvider,
            authProviderKey: AuthProvider.Passwordless,
            authProviderName: AuthProvider.Passwordless,
          },
        ],
      }
    }

    return {
      ...authMethods,
      data: authMethods.data.filter(
        ({ authProviderKey: key, authProviderName: name }) =>
          key === providerFromLocation ||
          name === providerFromLocation ||
          name === `juristat-${providerFromLocation}`
      ),
    }
  }
)

const getAuthView = createSelector(getAuth, (state) => state.view)

const getExpiration = createSelector(getAuth, (state) => state.expiration)

const getClaims = createSelector(getAuth, (state) => state.claims)

const getActiveGroup = createSelector(
  getClaims,
  (state): Record<'groupId' | 'groupName', string | null> => ({
    groupId: state?.[activeGroupClaimKey] ?? null,
    groupName: state?.[activeGroupNameKey] ?? null,
  })
)

const getLoginEmailState = createSelector(getAuth, (state) => state.loginEmail)

const makeGetClaim = (claimKey: string) =>
  createSelector(getClaims, (state: WeakObject) => state[claimKey])

export {
  getAccessToken,
  getAccessTokenId,
  getActiveGroup,
  getAuthMethods,
  getAuthMethodsWithSpecified,
  getAuthProviderFromLocation,
  getAuthView,
  getClaims,
  getExpiration,
  getLoginEmailState,
  makeGetClaim,
}
