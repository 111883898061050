import { any, isEmpty, not, pipe } from 'ramda'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Accordion from '../../../components/Accordion'
import { AppState } from '../../../redux'
import actions from '../actions'
import FilterContext from '../context/filterContext'
import { getActiveFilters } from '../selectors'
import { isAnyFilterOpen } from '../selectors/getStatCountFilter'
import { Filter } from '../types'
import ActiveFilterIcon from './ActiveFilterIcon'
import MultiFilterActionButtons from './MultiFilterActionButtons'

type MultiFilterContainerProps = {
  buttons?: boolean
  children: React.ReactNode
  maxHeight?: number
  filters: Filter[]
  title: string
}

const MultiFilterContainer: React.FC<MultiFilterContainerProps> = ({
  buttons = true,
  children,
  filters,
  maxHeight = 750,
  title,
}) => {
  const { meta } = useContext(FilterContext)
  const dispatch = useDispatch()
  const open = useSelector((state: AppState) => isAnyFilterOpen(state, { filters, ...meta }))
  const anyActive = useSelector((state: AppState) =>
    any(
      pipe(isEmpty, not),
      filters.map((filter) => getActiveFilters(state, { filter, ...meta }))
    )
  )

  return (
    <Accordion
      className="bg-charcoal-gray3 mb-[2px] rounded"
      contentClassName="my-2 mx-[10px]"
      headerClassName="py-2 px-[10px]"
      label={title}
      handleClick={() => dispatch(actions.toggleSome({ filters, open: !open }, meta))}
      maxHeight={maxHeight}
      onlyRenderWhenOpen
      open={open}
      renderStatus={anyActive ? ActiveFilterIcon : undefined}
      renderContent={() => (
        <span>
          {children}
          {buttons && <MultiFilterActionButtons filters={filters} />}
        </span>
      )}
    />
  )
}

export { MultiFilterContainerProps }
export default MultiFilterContainer
