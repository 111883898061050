import React from 'react'

import { colors } from '../../../styles'
import { Charts, OfficeActionsByDispositionYear as Data, TransformLine, XY } from '../types'
import { ChartProps } from '../types'
import { getTitleForLookups } from '../utils'
import LineChart from './LineChart'
import LineChartSkeletonLoader from './LineChartSkeletonLoader'
import PlatformChartContainer from './PlatformChartContainer'

type DataKey = 'toAbandonment' | 'toAllowance' | 'toDisposition'

const transform: TransformLine<Data> = (datum) => {
  // Only first row is applicable on this chart
  const data = datum[0].data
    .sort((current, next) => current.year - next.year)
    .reduce(
      ({ toAbandonment, toAllowance, toDisposition }, { year, ...counts }) => {
        return {
          toAbandonment: {
            ...toAbandonment,
            data: [...toAbandonment.data, { x: year, y: counts.toAbandonment }],
          },
          toAllowance: {
            ...toAllowance,
            data: [...toAllowance.data, { x: year, y: counts.toAllowance }],
          },
          toDisposition: {
            ...toDisposition,
            data: [...toDisposition.data, { x: year, y: counts.toDisposition }],
          },
        }
      },
      {
        toAbandonment: { color: colors.torchRed, data: [] as XY[], id: 'To Abandonment' },
        toAllowance: { color: colors.confetti, data: [] as XY[], id: 'To Allowance' },
        toDisposition: { color: colors.appleGreen, data: [] as XY[], id: 'To Disposition' },
      }
    )

  return Object.keys(data)
    .reverse()
    .map((key) => data[key as DataKey])
}

const OfficeActionsByDispositionYear: React.FC<ChartProps> = (props) => (
  <PlatformChartContainer
    chart={Charts.OfficeActionsByDispositionYear}
    exportableConfig={{
      filename: 'office_actions_by_disposition_year',
      getData: (data) => transform(data).map((item) => [item.id, ...item.data.map(({ y }) => y)]),
      getHeader: (data) => transform(data)[0].data.map(({ x }) => x),
    }}
    skeleton={LineChartSkeletonLoader}
    title={getTitleForLookups('Average Office Actions to Disposition', props.lookups)}
    {...props}
  >
    {({ data: raw, ...dimensions }) => {
      const data = transform(raw)

      return (
        <LineChart
          {...dimensions}
          axisBottom={{
            legend: 'Disposition Year',
          }}
          axisLeft={{
            legend: 'Average OAs',
          }}
          data={data}
          legend={{
            data: data.map(({ id }) => ({ id, label: id })),
          }}
          tooltipFormat="1.1f"
        />
      )
    }}
  </PlatformChartContainer>
)

export { transform }
export default OfficeActionsByDispositionYear
