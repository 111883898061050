import config from '@juristat/config'
import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../../../notification'
import actions from '../actions'
import userActions from '../actions'
import { ChangeUserGroupAction, ChangeUserGroupPayload } from '../types'

const makeErrorMessage = () => "Failed to update user's group."

const makeOptions = (body: WeakObject) => ({
  body: JSON.stringify(body),
  headers: { 'Content-Type': 'application/json' },
  method: 'POST',
})

const makeSuccessMessage = ({ groupUuid, userUuid }: ChangeUserGroupPayload) =>
  `User ${userUuid} moved to group ${groupUuid}`

const makeUrl = (uuid: string) => `${config.accountsUrl}/api/user/${uuid}/group`

function* changeUserGroup(action: ChangeUserGroupAction) {
  try {
    const { groupUuid, userUuid } = action.payload!
    const body = { uuid: groupUuid }
    const options = makeOptions(body)
    const url = makeUrl(userUuid)
    const response = yield call(api, url, options)
    if (!response.ok) {
      throw new Error(makeErrorMessage())
    }

    yield put(
      notificationActions.push(
        makeNotification({
          message: makeSuccessMessage(action.payload!),
          type: NotificationTypes.Success,
        })
      )
    )
    yield put(userActions.fetchDetails(userUuid))
  } catch (unknownErr) {
    const e = unknownErr as Error
    const message = e.message || makeErrorMessage()
    yield put(
      notificationActions.push(
        makeNotification({
          message,
          type: NotificationTypes.Error,
        })
      )
    )
  }
}

export { changeUserGroup, makeErrorMessage, makeOptions, makeSuccessMessage, makeUrl }
export default function* watchChangeUserGroup() {
  yield takeLatest(actions.changeGroup().type, changeUserGroup)
}
