import { descend, flatten, map, pipe, prop, sort, uniq } from 'ramda'
import { createSelector } from 'reselect'

import { AppState } from '../../redux'
import { HttpStatus } from '../http'
import { Classification, ClassificationProbability, ClassificationResults } from './types'

const notAsked = { type: HttpStatus.NotAsked }

const classificationToKeyMap = {
  [Classification.ArtUnit]: 'art_unit',
  [Classification.Cpc]: 'cpc',
  [Classification.TechCenter]: 'tech_center',
  [Classification.Uspc]: 'uspc',
}

const getDraftingState = (state: AppState) => state.drafting
const getClassification = (_: AppState, classification: Classification) => classification

const getClassificationState = createSelector(getDraftingState, (state) => state.classification)
const getKeyMetricsState = createSelector(
  getDraftingState,
  getClassification,
  (state, classification) => state.keyMetrics[classification]
)
const getSuggestionsState = createSelector(getDraftingState, (state) => state.suggestions)

const getClassificationKeys = createSelector(
  getClassificationState,
  getClassification,
  (state, classification) => {
    if (state.type !== HttpStatus.Success) {
      return []
    }

    return Object.keys(state.data.classifications[classification])
  }
)

const getProbability = createSelector(
  getClassificationState,
  getClassification,
  (state, classification): ClassificationProbability => {
    if (state.type !== HttpStatus.Success) {
      return state
    }

    const classificationData = state.data.classifications[classification]
    const data = sort(
      descend(prop('probability')),
      Object.keys(classificationData).map((id) => ({
        id,
        probability: classificationData[id],
      }))
    )

    return {
      data,
      type: HttpStatus.Success,
    }
  }
)

const getClassificationResults = createSelector(
  getKeyMetricsState,
  getProbability,
  (metrics, probability): ClassificationResults => {
    if (probability.type !== HttpStatus.Success) {
      return probability
    }

    if (metrics.type !== HttpStatus.Success) {
      return metrics
    }

    const data = probability.data.map((item) => ({
      ...metrics.data[item.id],
      ...item,
    }))

    return {
      data,
      type: HttpStatus.Success,
    }
  }
)

const getBoosted = createSelector(
  getSuggestionsState,
  getClassification,
  (state, classification) => {
    return state[classification].boosted
  }
)

const getSuggestions = createSelector(
  getSuggestionsState,
  getClassification,
  (state, classification) => {
    return state[classification].results || notAsked
  }
)

const getWordsPayload = createSelector(
  getClassificationState,
  getBoosted,
  getClassification,
  (state, boosted, classification) => {
    if (state.type !== HttpStatus.Success) {
      return state
    }

    const boostKey = classificationToKeyMap[classification]
    const data = state.data.keywords[classification]

    return {
      away: [],
      towards: boosted.map((value) => ({ [boostKey]: value })),
      words: pipe(
        map((key: string) => Object.keys(data[key])),
        flatten,
        uniq
      )(boosted),
    }
  }
)

export {
  getBoosted,
  getClassificationKeys,
  getClassificationResults,
  getClassificationState,
  getKeyMetricsState,
  getProbability,
  getSuggestions,
  getWordsPayload,
}
