import { keys } from 'ramda'
import { createSelector } from 'reselect'

import { AppState } from '../../../redux'
import { SearchSets, SearchState, SearchViewActiveState, SearchViewState } from '../types'

type Params = {
  searchId: string
}

// Refactored these out to prevent circular imports between search and pagination selectors
export const getSearch = (state: AppState) => state.search
export const getSearchId = <S, P extends Params | undefined = undefined>(
  _: S,
  ...props: OptionalSpread<P>
): string | undefined => {
  const prop = (props || [])[0]
  return typeof prop === 'undefined' ? undefined : prop.searchId
}

export const getSearchViewState = createSelector(getSearch, (state: SearchState) => state.view)
export const getSearchSets = createSelector(getSearch, (state: SearchState) => state.sets)

export const getSearchViewActive = createSelector(
  getSearchViewState,
  (state: SearchViewState) => state.active
)

export const getActiveFilterSearchId = createSelector(
  getSearchViewActive,
  (state: SearchViewActiveState) => state.filters
)

export const getActiveResultsSearchId = createSelector(
  getSearchViewActive,
  (state: SearchViewActiveState) => state.results
)

export const getFilterSearchDetails = createSelector(
  getSearchId,
  getActiveFilterSearchId,
  getSearchSets,
  (searchId: string | undefined, activeSearchId: string, searchSets: SearchSets) =>
    searchSets[searchId ?? activeSearchId].search
)

export const getFilterSearchReport = createSelector(
  getSearchId,
  getActiveFilterSearchId,
  getSearchSets,
  (searchId: string | undefined, activeSearchId: string, searchSets: SearchSets) =>
    searchSets[searchId ?? activeSearchId].report
)

export const getResultsSearchDetails = createSelector(
  getSearchId,
  getActiveResultsSearchId,
  getSearchSets,
  (searchId: string | undefined, activeSearchId: string, searchSets: SearchSets) =>
    searchSets?.[searchId ?? activeSearchId]?.search
)

export const getResultsSearchView = createSelector(
  getSearchId,
  getActiveResultsSearchId,
  getSearchSets,
  (searchId: string | undefined, activeSearchId: string, searchSets: SearchSets) =>
    searchSets[searchId ?? activeSearchId].view
)

export const getSearchSetIds = createSelector(getSearchSets, (sets: SearchSets) => keys(sets))
