import * as React from 'react'

import SVG from './SVG'
import { IconProps, LogoProps } from './types'

const JuristatLogo = ({ color, ...props }: LogoProps & IconProps) => {
  return (
    <SVG title="juristat-logo" viewBox="0 0 656.27899 138.99001" {...props}>
      <defs id="defs6">
        <clipPath id="clipPath16" clipPathUnits="userSpaceOnUse">
          <path id="path18" d="M 0,720 720,720 720,0 0,0 0,720 Z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.25,0,0,-1.25,-121.8605,519.49487)" id="g10">
        <g id="g12">
          <g clipPath="url(#clipPath16)" id="g14">
            <g transform="translate(577.4033,304.9629)" id="g20">
              <path
                id="path22"
                fill={color ? color : '#7bc142'}
                fillRule="nonzero"
                stroke="none"
                d="m 0,0 c -16.069,8.974 -24.202,40.727 -31.52,64.237 -7.653,24.609 -14.282,45.91 -29.078,46.384 l 0,0.008 c -0.035,0.004 -0.829,0.004 -0.859,0.004 l 0,-0.01 C -76.259,110.228 -82.999,88.964 -90.784,64.394 -98.222,40.919 -106.526,9.212 -122.641,0.326 l 1.272,-0.565 c 19.919,7.438 29.069,36.249 37.268,62.11 6.285,19.835 12.247,40.426 21.309,42.615 0,0 0.734,0.205 1.741,0.203 1.013,-0.002 1.744,-0.21 1.744,-0.21 9.049,-2.24 14.912,-22.86 21.091,-42.727 C -30.153,35.849 -21.155,6.99 -1.274,-0.559 L 0,0 Z"
              />
            </g>
            <g transform="translate(107.8594,313.7979)" id="g24">
              <path
                id="path26"
                fill={color ? color : '#807f83'}
                fillRule="nonzero"
                stroke="none"
                d="m 0,0 c -3.704,0 -7.039,0.556 -9.075,0.924 l -1.296,7.964 c 3.148,-0.741 5.558,-1.11 9.447,-1.11 9.816,0 15.742,4.999 15.742,17.593 l 0,69.079 8.704,0 0,-69.82 C 23.522,7.406 13.521,0 0,0"
              />
            </g>
            <g transform="translate(183.7905,313.2402)" id="g28">
              <path
                id="path30"
                fill={color ? color : '#807f83'}
                fillRule="nonzero"
                stroke="none"
                d="m 0,0 c -21.854,0 -34.446,14.815 -34.446,36.301 l 0,58.707 8.335,0 0,-57.411 c 0,-17.596 8.888,-29.816 26.111,-29.816 17.226,0 26.485,11.296 26.485,29.074 l 0,58.153 8.703,0 0,-57.967 C 35.188,14.448 21.856,0 0,0"
              />
            </g>
            <g transform="translate(261.3921,400.4697)" id="g32">
              <path
                id="path34"
                fill={color ? color : '#807f83'}
                fillRule="nonzero"
                stroke="none"
                d="m 0,0 -15.186,0 0,-38.521 15.555,0 c 17.779,0 25.373,8.706 25.373,19.633 C 25.742,-7.035 17.78,0 0,0 m 31.112,-86.672 -29.631,40.558 -16.667,0 0,-39.634 -8.703,0 0,93.526 24.629,0 c 22.037,0 33.889,-10.556 33.889,-26.298 0,-13.889 -9.071,-23.518 -23.516,-26.668 l 29.259,-39.63 -9.26,-1.854 z"
              />
            </g>
            <path
              id="path36"
              fill={color ? color : '#807f83'}
              fillRule="nonzero"
              stroke="none"
              d="m 318.063,408.248 8.703,0 0,-93.526 -8.703,0 0,93.526 z"
            />
            <g transform="translate(371.4902,313.6133)" id="g38">
              <path
                id="path40"
                fill={color ? color : '#807f83'}
                fillRule="nonzero"
                stroke="none"
                d="m 0,0 c -10.741,0 -22.966,3.334 -28.892,6.479 l 1.296,8.708 c 5.927,-3.151 18.152,-7.224 28.152,-7.224 15.001,0 23.337,8.332 23.337,17.965 0,9.26 -5.557,13.889 -22.039,19.631 -19.817,6.851 -27.041,12.593 -27.041,25.557 0,14.815 13.15,24.815 28.892,24.815 10.184,0 19.442,-2.593 24.629,-5.369 l -1.663,-8.705 c -5.002,2.962 -14.263,6.111 -23.338,6.111 -12.037,0 -20,-7.039 -20,-16.111 0,-8.707 4.63,-12.224 20.556,-17.778 C 25.371,46.483 32.225,39.445 32.225,26.668 32.225,11.296 19.262,0 0,0"
              />
            </g>
            <g transform="translate(450.0166,400.4697)" id="g42">
              <path
                id="path44"
                fill={color ? color : '#807f83'}
                fillRule="nonzero"
                stroke="none"
                d="m 0,0 0,-85.748 -8.706,0 0,85.748 -32.227,0 0,7.778 74.078,0 L 29.473,0 0,0 Z"
              />
            </g>
            <g transform="translate(590.4746,400.4697)" id="g46">
              <path
                id="path48"
                fill={color ? color : '#807f83'}
                fillRule="nonzero"
                stroke="none"
                d="m 0,0 0,-85.748 -8.707,0 0,85.748 -29.281,0 -3.417,7.778 72.333,0 L 32.037,0 0,0 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </SVG>
  )
}

export default JuristatLogo
