export enum HttpStatus {
  NotAsked = 'notAsked',
  Fetching = 'fetching',
  Success = 'success',
  Error = 'error',
}

export enum UploadStatus {
  Error = 'error',
  Processing = 'processing',
  Success = 'success',
  Uploading = 'uploading',
}

export type Error = {
  message: string
  type: HttpStatus.Error
}

export type Fetching = {
  type: HttpStatus.Fetching
}

export type MergeableFetching<T> = {
  data: T
  type: HttpStatus.Fetching
}

export type NotAsked = {
  type: HttpStatus.NotAsked
}

export type Success<T> = {
  data: T
  type: HttpStatus.Success
}

export type HttpContent<T> = NotAsked | Fetching | Success<T> | Error

export type MergeableHttpContent<T> = NotAsked | MergeableFetching<T> | Success<T> | Error
