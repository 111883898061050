import * as React from 'react'

import SVG from './SVG'
import LineChartSolid from './solid/LineChartSolid'
import { SolidProps } from './types'

const LineChart = ({ solid, ...props }: SolidProps) => {
  if (solid === true) {
    return <LineChartSolid {...props} />
  }

  return (
    <SVG title="line-chart" {...props}>
      <path d="M32,5H4A2,2,0,0,0,2,7V29a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V7A2,2,0,0,0,32,5ZM4,29V7H32V29Z" />
      <polygon points="22.28 25.5 15.61 15.56 9.65 24.28 5.42 20.53 6.75 19.04 9.28 21.28 15.63 12 22.38 22.06 28.97 13.35 30.57 14.55 22.28 25.5" />
      <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
    </SVG>
  )
}

export default LineChart
