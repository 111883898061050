import ellipsisString from '../../../utils/ellipsisString'

/**
 * This is needed since some entities have the same name, so we build an id of
 * `entity_label` and pass that to the charts and use this function to get the label
 * for tooltips and legends.
 */
const getEntityLabel = (id: string | number, length: number) =>
  ellipsisString(String(id).replace(/^[^_]+_/, ''), length)

export default getEntityLabel
