import { css } from 'emotion'
import React from 'react'
import { config } from 'react-spring'

import Button from '../../../components/Button'
import ProgressBar from '../../../components/ProgressBar'
import { colors, textStyles } from '../../../styles'
import { Close } from '../../icons'
import { Notification as NotificationType } from '../../notification'

type NotificationProps = NotificationType & {
  dismiss: () => void
}

const styles = {
  close: css({
    '& > svg': {
      fill: 'currentColor',
      height: 12,
      width: 12,
    },
    '&:hover': {
      color: colors.dark,
    },
    color: colors.silver2,
    height: 40,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: 'color 200ms ease-out',
    width: 40,
  }),
  error: css({
    borderTopColor: colors.pastelRed,
  }),
  info: css({
    borderTopColor: colors.azure,
  }),
  main: (dismissable = false) =>
    css(textStyles.darkNormal13, {
      '& a': {
        ...textStyles.linkBlueNormal13,
      },
      backgroundColor: colors.white,
      borderRadius: 4,
      borderTopStyle: 'solid',
      borderTopWidth: 3,
      boxShadow: `0 4px 10px 0 ${colors.charcoalGray2alpha30}`,
      padding: '10px 16px',
      paddingRight: dismissable ? 40 : undefined,
      position: 'relative',
      width: '100%',
    }),
  progress: css({
    marginTop: 12,
  }),
  success: css({
    borderTopColor: colors.appleGreen,
  }),
  warning: css({
    borderTopColor: colors.schoolbusYellow,
  }),
}

const Message = ({ text }: { text?: string }) => (
  <>{text?.split('\r\n').map((string) => <div key={string}>{string}</div>)}</>
)

const Notification = ({
  dismissable,
  dismiss,
  link,
  message,
  progress,
  type,
}: NotificationProps) => {
  const [messageLeft, messageRight] = message.split('{{link}}')
  return (
    <div className={css(styles.main(dismissable), styles[type])} data-notificationtype={type}>
      <div>
        <Message text={messageLeft} />
        {link && <a href={link.to}>{link.text}</a>}
        <Message text={messageRight} />
      </div>
      {progress !== undefined && (
        <div className={styles.progress}>
          <ProgressBar springConfig={config.default} progress={progress} />
        </div>
      )}
      {dismissable && (
        <Button className={styles.close} handleClick={dismiss}>
          <Close />
        </Button>
      )}
    </div>
  )
}

export default Notification
