import { css } from 'emotion'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { colors } from '../../../styles'
import { JuristatJ } from '../../icons'
import HasAccess from '../../session/components/HasAccess'
import { Access } from '../../session/types'

type JButtonConnectedProps = {
  hasAccess: boolean
}

const styles = {
  logo: css({
    margin: '20px 0',
  }),
}

const JButtonComponent = ({ hasAccess }: JButtonConnectedProps) => (
  <Link to={hasAccess ? '/' : '/personal'}>
    <JuristatJ className={styles.logo} color={colors.white} width={24} height={24} title="Home" />
  </Link>
)

const JButton = () => <HasAccess canAccess={Access.Table} render={JButtonComponent} />

export default JButton
