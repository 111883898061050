import config from '@juristat/config'
import { DocumentNode } from 'graphql'
import { print } from 'graphql/language/printer'
import { useCallback } from 'react'

import { useAccessToken } from '../../auth'

type ApiArguments<Variables extends WeakObject = Record<string, unknown>> = {
  query: DocumentNode
  variables?: Variables
}

// Internal hook and shouldn't be exposed outside api module
export default function useGraphqlApi(
  url = config.usptoData.graphqlUrl,
  { includeErrors = false } = {}
) {
  const accessToken = useAccessToken()

  return useCallback(
    async ({ query, variables = {} }: ApiArguments) => {
      const body = JSON.stringify({ query: print(query), variables })
      const headers = new Headers([
        ['authorization', `Bearer ${accessToken}`],
        ['content-type', 'application/json'],
      ])

      const res = await fetch(url, { body, credentials: 'include', headers, method: 'post' })

      if (!res.ok) {
        throw res.statusText
      }

      const type = res.headers.get('content-type')
      const isJson = type ? type.includes('json') : false
      const data = await (isJson ? res.json() : res.text())

      if (isJson && !includeErrors) {
        return data.data
      }

      return data
    },
    [accessToken]
  )
}
