import * as React from 'react'

import SVG from './SVG'
import LockSolid from './solid/LockSolid'
import { SolidProps } from './types'

const Lock = ({ solid, ...props }: SolidProps) => {
  if (solid === true) {
    return <LockSolid {...props} />
  }

  return (
    <SVG title="lock" {...props}>
      <path d="M18.09,20.59A2.41,2.41,0,0,0,17,25.14V28h2V25.23a2.41,2.41,0,0,0-.91-4.64Z" />
      <path d="M26,15V10.72a8.2,8.2,0,0,0-8-8.36,8.2,8.2,0,0,0-8,8.36V15H7V32a2,2,0,0,0,2,2H27a2,2,0,0,0,2-2V15ZM12,10.72a6.2,6.2,0,0,1,6-6.36,6.2,6.2,0,0,1,6,6.36V15H12ZM9,32V17H27V32Z" />
    </SVG>
  )
}

export default Lock
