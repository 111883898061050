import { sort } from 'ramda'
import { Reducer, combineReducers } from 'redux'

import { HttpStatus } from '../http'
import { Classification, DraftingState, ReducerActions } from './types'

type ClassificationSlice = { [K in Classification]: unknown }

type KeyMetricsState = DraftingState['keyMetrics'][Classification]
type SuggestionsState = DraftingState['suggestions'][Classification]

const classifications = [
  Classification.ArtUnit,
  Classification.Cpc,
  Classification.TechCenter,
  Classification.Uspc,
]

const classificationSlice =
  <S extends ClassificationSlice>(
    reducerSlice: Reducer<S[Classification]>
  ): Reducer<S | undefined> =>
  (state, action: ReducerActions) => {
    if (!state) {
      return classifications.reduce(
        (acc, classificationn) => ({
          ...acc,
          [classificationn]: reducerSlice(state, action),
        }),
        {} as S
      )
    }

    switch (action.type) {
      case 'drafting/BOOST':
      case 'drafting/SET_KEY_METRICS': {
        const slice = action.meta!

        return {
          ...state,
          [slice]: reducerSlice(state[slice], action),
        }
      }
      case 'drafting/CLASSIFY':
      case 'drafting/CLEAR':
        return classificationSlice(reducerSlice)(undefined, action)
      case 'drafting/SET_SUGGESTIONS': {
        const slice = action.meta!

        return {
          ...state,
          [slice]: reducerSlice(state[slice], action),
        }
      }
      default:
        return state
    }
  }

const suggestionsSlice: Reducer<SuggestionsState> = (
  state = { boosted: [], results: { type: HttpStatus.NotAsked } },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'drafting/BOOST':
      return {
        ...state,
        boosted: sort((left, right) => left.localeCompare(right), action.payload!),
      }
    case 'drafting/SET_SUGGESTIONS':
      return {
        ...state,
        results: action.payload!,
      }
    default:
      return state
  }
}

const classification: Reducer<DraftingState['classification']> = (
  state = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'drafting/CLEAR':
      return { type: HttpStatus.NotAsked }
    case 'drafting/SET_CLASSIFICATION':
      return action.payload!
    default:
      return state
  }
}

const keyMetricsSlice: Reducer<KeyMetricsState> = (
  state = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'drafting/SET_KEY_METRICS':
      return action.payload!
    default:
      return state
  }
}

const keyMetrics = classificationSlice<DraftingState['keyMetrics']>(keyMetricsSlice)
const suggestions = classificationSlice<DraftingState['suggestions']>(suggestionsSlice)

const reducer = combineReducers({
  classification,
  keyMetrics,
  suggestions,
})

export default reducer
