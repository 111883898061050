import * as React from 'react'

import AngleDouble from './AngleDouble'
import SVG from './SVG'
import { IconProps } from './types'

export type AngleProps = { double?: boolean } & IconProps

const Angle = ({ double, ...props }: AngleProps) => {
  if (double === true) {
    return <AngleDouble {...props} />
  }

  return (
    <SVG title="angle" {...props}>
      <path d="M29.52,22.52,18,10.6,6.48,22.52a1.7,1.7,0,0,0,2.45,2.36L18,15.49l9.08,9.39a1.7,1.7,0,0,0,2.45-2.36Z" />
    </SVG>
  )
}

export default Angle
