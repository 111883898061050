import { Reducer, combineReducers } from 'redux'

import { ExportStatusType, ExportsState, ReducerActions } from '../types'

const appnos: Reducer<ExportsState['appnos']> = (state = {}, action: ReducerActions) => {
  switch (action.type) {
    case 'exports/PUSH': {
      const { jobId } = action.payload!
      const { appno = '' } = action.meta! || {}

      if (appno === '') {
        return state
      }

      return {
        ...state,
        [appno]: jobId,
      }
    }
    default:
      return state
  }
}

const jobs: Reducer<ExportsState['jobs']> = (state = {}, action: ReducerActions) => {
  switch (action.type) {
    case 'exports/PUSH': {
      const { jobId } = action.payload!

      return {
        ...state,
        [jobId]: {
          lastUpdated: Date.now(),
          status: ExportStatusType.Submitted,
        },
      }
    }
    case 'exports/UPDATE': {
      const { jobId, status } = action.payload!

      return {
        ...state,
        [jobId]: status,
      }
    }
    default:
      return state
  }
}

const reducer = combineReducers({
  appnos,
  jobs,
})

export default reducer
