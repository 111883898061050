import { DataSource } from '@juristat/common/types'
import { compareDesc, parseISO } from 'date-fns'

import { useQuery } from '../../api'
import { useSearchDataSource } from '../../search/hooks'
import * as getExaminerDetailsQuery from '../queries/getExaminerDetails.graphql'
import { ExaminerDetailsData, ExaminerDetailsResponse } from '../types'
import { useExaminerId } from './useExaminerId'

export function useExaminerDetails(id?: number) {
  const examinerId = id ?? useExaminerId()
  const ppair = useSearchDataSource() === DataSource.PrivatePair

  const [data] = useQuery<ExaminerDetailsData, ExaminerDetailsResponse>(
    ['examiner', examinerId],
    getExaminerDetailsQuery,
    {
      enabled: examinerId > 0,
      ppair,
      transform: ({ examiner: { artUnits, name, phone, serviceTime, lastActivity, title } }) => ({
        artUnits: artUnits
          .filter(({ artUnitId }) => artUnitId !== null)
          .sort((left, right) =>
            compareDesc(parseISO(left.lastActive ?? ''), parseISO(right.lastActive ?? ''))
          )
          .map((artUnit) => ({
            apps: artUnit.apps,
            id: artUnit.artUnitId!,
            lastActive: artUnit.lastActive,
            lastFiled: artUnit.lastFiled,
            spe: artUnit.artUnit.spe,
          })),
        lastActivity,
        name,
        phone,
        serviceTime,
        title,
      }),
      variables: { examinerId },
    }
  )

  return data
}
