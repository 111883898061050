import { css } from 'emotion'
import React, { Fragment, useState } from 'react'
import { animated, config, useTransition } from 'react-spring'

import { useResizeObserver } from '../../../hooks'
import { colors, textStyles, zIndex } from '../../../styles'
import { Triangle } from '../../icons'

type TooltipProps = {
  children: React.ReactNode
  className?: string
  direction?: 'right' | 'up'
  tooltip: React.ReactNode
}

const styles = {
  arrow: css({
    fill: colors.white,
    height: 16,
    position: 'absolute',
    stroke: colors.silver2,
    strokeWidth: 0.7,
    width: 16,
    zIndex: -1,
  }),
  background: css({
    backgroundColor: colors.white,
    border: `1px solid ${colors.silver2}`,
    borderRadius: 4,
    position: 'relative',
    zIndex: -2,
  }),
  container: css({
    position: 'relative',
  }),
  content: css(textStyles.charcoalGrayNormal12, {
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 4,
    display: 'flex',
    height: 28,
    justifyContent: 'center',
    overflow: 'visible',
    whiteSpace: 'nowrap',
  }),
  contentContainer: css({
    backfaceVisibility: 'hidden',
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: zIndex.tooltip,
  }),
}

const getPropsByDirection = (direction: TooltipProps['direction']) => {
  switch (direction) {
    case 'right':
      return {
        animateFrom: 'translate(0px, -50%)',
        animateTo: 'translate(8px, -50%)',
        arrow: {
          bottom: '50%',
          left: -10,
          transform: 'rotate(90deg) translateX(50%)',
        },
        content: {
          left: 'calc(100% + 3px)',
          top: '50%',
        },
      }
    case 'up':
    default:
      return {
        animateFrom: 'translate(-50%, 0px)',
        animateTo: 'translate(-50%, -8px)',
        arrow: {
          bottom: -10,
          left: '50%',
          transform: 'translateX(-50%)',
        },
        content: {
          bottom: 'calc(100% + 3px)',
          left: '50%',
        },
      }
  }
}

const roundToNearestEvenPixel = (size: number) => {
  const rounded = Math.round(size)

  return rounded % 2 === 0 ? rounded : rounded + 1
}

const TooltipContent = ({ children }: { children: React.ReactNode }) => {
  const {
    dimensions: { width },
    ref,
  } = useResizeObserver<HTMLDivElement>()

  return (
    <div className={styles.content} style={{ width: roundToNearestEvenPixel(width) + 30 }}>
      <div ref={ref}>{children}</div>
    </div>
  )
}

const Tooltip = ({ children, className, direction = 'up', tooltip }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { animateFrom, animateTo, arrow, content } = getPropsByDirection(direction)
  const transition = useTransition(isOpen, {
    config: config.stiff,
    enter: { opacity: 1, transform: animateTo },
    from: { opacity: 0, transform: animateFrom },
    leave: { opacity: 0, transform: animateFrom },
  })

  return (
    <Fragment>
      <div
        className={css(styles.container, className)}
        onClick={() => setIsOpen(false)}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {children}
        {transition(
          (props, item, { key }) =>
            item && (
              <animated.div
                className={css(styles.contentContainer, content)}
                key={key}
                style={props}
              >
                <div className={styles.background}>
                  <Triangle className={css(styles.arrow, arrow)} />
                  <TooltipContent>{tooltip}</TooltipContent>
                </div>
              </animated.div>
            )
        )}
      </div>
    </Fragment>
  )
}

export default Tooltip
