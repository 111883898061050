import { DataSource, SearchView } from '@juristat/common/types'
import { startCase } from '@juristat/common/utils'

import { Column } from '../modules/table/types'
import { GraphQLResult, TableGraphQLResult } from '../types'

type ColumnResponse = TableGraphQLResult['columns'] extends Array<infer U> ? U : never

const parse = (column: ColumnResponse): boolean | number | string | null => {
  if (column.column === Column.Title) {
    return startCase(column.valueString)
  }

  try {
    return JSON.parse(column.valueString as string)
  } catch (ex) {
    return column.valueString
  }
}

const getColumnValue = (column: ColumnResponse) =>
  column.queryId !== undefined ? undefined : parse(column)

const createColumnValueMapping = (columns: TableGraphQLResult['columns']) =>
  columns.reduce(
    (acc, column) => ({
      ...acc,
      [column.column]: getColumnValue(column),
    }),
    {} as any // `any` is okay here because we are typing the return object
  )

const makeMapResponseToResult =
  (dataSource: DataSource, view: SearchView) =>
  ({ abstract, drawings, publication, ...rest }: GraphQLResult & TableGraphQLResult) => {
    const columns = createColumnValueMapping(rest.columns ?? [])

    if (view === SearchView.Card) {
      return {
        ...rest,
        abstract: abstract ?? [''],
        columns,
        dataSource,
        drawings,
        publication,
      }
    }

    return {
      columns,
      dataSource,
    }
  }

export { createColumnValueMapping }
export default makeMapResponseToResult
