import { LocalStorageKey } from '@juristat/common/types'
import { useEffect, useState } from 'react'

export function useLocalStorage<T extends string>(
  key: LocalStorageKey,
  value?: T,
  defaultValue?: T
) {
  const [state, setState] = useState<T | null>(() => {
    const fallback = defaultValue ?? null

    try {
      return (window?.localStorage?.getItem?.(key) as T) ?? fallback
    } catch {
      // Eat exception
    }

    return fallback
  })

  useEffect(() => {
    if (state !== null) {
      try {
        window?.localStorage?.setItem?.(key, state)
      } catch {
        // Eat exception
      }
    }
  }, [key, state])

  useEffect(() => {
    if (value !== undefined) {
      try {
        window?.localStorage?.setItem?.(key, value)
      } catch {
        // Eat exception
      }

      setState(value)
    }
  }, [key, value])

  return [state, setState] as const
}
