import { HttpStatus } from '../../../../../http/types'
import { AccountsUserResponse, UserDetailsInfo, UserInfo } from '../../types'

const mapAccountsUserResponse = ({
  api_only: apiOnly,
  created,
  disabled,
  email_verification_token: emailVerificationToken,
  email_verified: emailVerified,
  group,
  test_account: testAccount,
  username,
  uuid,
}: AccountsUserResponse): UserInfo => ({
  apiOnly,
  createdDate: created,
  disabled,
  emailVerificationToken: emailVerificationToken ?? null,
  emailVerified,
  group: group?.uuid
    ? {
        name: group.name ?? 'Unnamed group',
        uuid: group.uuid,
      }
    : undefined,
  testAccount,
  username,
  uuid,
})

const mapAccountsUserDetailsResponse = (response: AccountsUserResponse): UserDetailsInfo => ({
  ...mapAccountsUserResponse(response),
  disabled: {
    data: response.disabled,
    type: HttpStatus.Success,
  },
})

export { mapAccountsUserDetailsResponse }
export default mapAccountsUserResponse
