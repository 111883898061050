import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Hamburger = (props: IconProps) => {
  return (
    <SVG title="hamburger" viewBox="0 0 10 9" {...props}>
      <g transform="translate(-10, -14)">
        <path d="M19.5,18 L10.5,18 C10.224,18 10,18.224 10,18.5 C10,18.7765 10.224,19 10.5,19 L19.5,19 C19.776,19 20,18.7765 20,18.5 C20,18.224 19.776,18 19.5,18 L19.5,18 Z M19.5,22 L10.5,22 C10.224,22 10,22.224 10,22.5 C10,22.7765 10.224,23 10.5,23 L19.5,23 C19.776,23 20,22.7765 20,22.5 C20,22.224 19.776,22 19.5,22 L19.5,22 Z M10.5,15 L19.5,15 C19.776,15 20,14.7765 20,14.5 C20,14.224 19.776,14 19.5,14 L10.5,14 C10.224,14 10,14.224 10,14.5 C10,14.7765 10.224,15 10.5,15 L10.5,15 Z" />
      </g>
    </SVG>
  )
}

export default Hamburger
