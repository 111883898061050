import { Column, DataSource } from '@juristat/common/types'
import { startCase } from '@juristat/common/utils'
import config from '@juristat/config'
import { contains, innerJoin, range, uniq } from 'ramda'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useQueryStringParam } from '../../hooks'
import { AppState } from '../../redux'
import { useIntercomActions } from '../analytics/hooks'
import { useMergedQueries, useMutation, useQuery } from '../api'
import { useExport } from '../exports/hooks'
import filterActions from '../filter/actions'
import FilterContext from '../filter/context/filterContext'
import { ActiveReducer, Transformed } from '../filter/types'
import { IntelligenceEntityType } from '../intelligence/types'
import { useNotification } from '../notification/hooks'
import paginationActions from '../pagination/actions'
import { getPageSize } from '../pagination/selectors'
import { useUsername } from '../session/hooks'
import actions from './actions'
import { SelectedColumnsItemsContext, SelectedColumnsUpdateContext } from './contexts'
import * as getApplicationColumns from './modules/table/queries/getApplicationColumns.graphql'
import { ApplicationColumn } from './modules/table/types'
import * as getApplicationSetByColumns from './queries/getApplicationSetByColumns.graphql'
import * as getApplicationSetByFields from './queries/getApplicationSetByFields.graphql'
import * as getSearchUid from './queries/getSearchUid.graphql'
import * as getUidDefinition from './queries/getUidDefinition.graphql'
import * as getUidTotal from './queries/getUidTotal.graphql'
import * as submitExportQuery from './queries/submitExport.graphql'
import {
  getSearchDataSourceById,
  getSearchOrderingsById,
  getSearchPhraseParsedById,
  getSearchScopesById,
  getSearchTypeById,
} from './selectors'
import getActiveFilters from './selectors/getActiveFilters'
import { getActiveResultsSearchId } from './selectors/getSearch'
import {
  GraphQLResult,
  HydrateFromUidPayload,
  Result,
  SearchPhrase,
  SearchType,
  SetReportAction,
  TableGraphQLResult,
  TableResult,
} from './types'
import { getSortOrders } from './utils'

type ColumnResponse = TableGraphQLResult['columns'] extends Array<infer U> ? U : never

const idColumns = [
  Column.Examiner,
  Column.AssistantExaminer,
  Column.PrimaryExaminer,
  Column.Assignee,
  Column.AssigneeAtDisposition,
  Column.AssigneeAtPublishing,
  Column.Firm,
  Column.FirmAtDisposition,
  Column.FirmAtPublishing,
  Column.FirstAssignee,
  Column.FirstFirm,
  Column.Attorney,
  Column.AttorneyAtDisposition,
  Column.FirstAttorney,
]

const parse = (column: ColumnResponse): boolean | number | string | null => {
  if (column.column === Column.Title) {
    return startCase(column.valueString)
  }

  try {
    return JSON.parse(column.valueString as string)
  } catch (ex) {
    return column.valueString
  }
}

export function useTableColumns() {
  const [current] = useQuery<ApplicationColumn[], { applicationColumns: ApplicationColumn[] }>(
    'application-columns',
    getApplicationColumns,
    { transform: (data) => data.applicationColumns }
  )

  return current.context.data ?? []
}

export function useGetUid(filters: ActiveReducer) {
  const dataSource = useSearchDataSource()
  const [uid] = useQuery<string, { applicationSet: { uid: string } }>('a-uid', getSearchUid, {
    enabled: Object.keys(filters).length > 0,
    ppair: dataSource === DataSource.PrivatePair,
    transform: ({ applicationSet: { uid } }) => uid,
    variables: { filters, searches: {}, similarTo: {}, sortOrders: [] },
  })

  return uid.context.data ?? ''
}

export function useSearchUid() {
  const dispatch = useDispatch()
  const enabled = Boolean(useUsername())

  const { meta } = useContext(FilterContext)
  const urlUid = useQueryStringParam('uid') ?? config.emptySearchUid
  const initialLoad = useRef(true)

  const { dataSource, ...variables } = useSearchVariables()
  const [uid, { setData }] = useQuery<string, { applicationSet: { uid: string } }>(
    'search-uid',
    getSearchUid,
    {
      enabled,
      transform: ({ applicationSet: { uid } }) => uid,
      variables: { ...variables },
    }
  )

  if (initialLoad.current) {
    setData(urlUid)

    initialLoad.current = false
  }

  const [definition] = useQuery<
    HydrateFromUidPayload & { filters: Transformed },
    { uid: { definition: string } }
  >('uid-definition', getUidDefinition, {
    enabled: uid.matches('success'),
    transform: ({ uid: { definition } }: { uid: { definition: string } }) =>
      definition === '{}' || !definition ? {} : JSON.parse(definition),
    variables: { uid: uid.context.data ?? urlUid },
  })

  useEffect(() => {
    if (uid.matches({ success: 'stale' }) && urlUid !== uid.context.data) {
      setData(urlUid)
    }
  }, [uid.value, urlUid])

  useEffect(() => {
    if (uid.matches({ success: 'stale' })) {
      dispatch(filterActions.getAvailable({ dataSource, uid: uid.context.data }, meta))
    }
  }, [uid.value])

  useEffect(() => {
    if (definition.matches({ success: 'stale' })) {
      const { filters, ...rest } = definition.context.data

      dispatch(actions.hydrate(rest))
      dispatch(filterActions.hydrate(filters, meta))

      if (uid.matches({ success: 'stale' })) {
        dispatch(filterActions.getAvailable({ dataSource, uid: uid.context.data }, meta))
      }
    }
  }, [definition.value])

  const { count } = useSearchInfo()

  useEffect(() => {
    if (count.matches({ success: 'stale' }) && urlUid !== config.emptySearchUid) {
      const total = count.context.data

      dispatch(paginationActions.set({ pageCount: 0, total }))
    }
  }, [count.value, urlUid, JSON.stringify(variables)])

  return uid
}

export function useCardSearchResults() {
  const uid = useSearchUid()
  const { dataSource } = useSearchVariables()

  const p = useQueryStringParam('p')
  const pageNum = Number(p ?? 1)

  const pageSize = useSelector(getPageSize)
  const requests = pageSize / 20
  const pageBase = (pageNum - 1) * requests

  const [results] = useMergedQueries<
    Result,
    {
      uid: {
        page: GraphQLResult[] | null
      }
    }
  >(
    'search-results-card',
    getApplicationSetByFields,
    range(1, requests + 1).map((index) => ({
      ppair: dataSource === DataSource.PrivatePair,
      transform: ({ uid: { page } }) =>
        page?.map(({ abstract, ...data }) => ({
          abstract: abstract ?? [''],
          ...data,
        })) ?? ({} as any),
      variables: { pageNum: pageBase + index, pageSize: 20, uid: uid.context.data },
    })),
    { enabled: uid.matches('success'), flat: true }
  )

  return results
}

export function useSelectedColumnVariables() {
  const availableColumns = useTableColumns()

  const [selectedColumns] = useSelectedColumns()
  const additionalColumns = selectedColumns.reduce<Column[]>(
    (acc, column) => (contains(column, idColumns) ? [...acc, `${column}_ID` as Column] : acc),
    []
  )

  const columns = uniq([
    ...innerJoin(
      (selected, available) => available.enumName === selected,
      selectedColumns,
      availableColumns
    ),
    ...additionalColumns,
    Column.ApplicationNumber,
  ]).map((column) => ({ column }))

  return columns
}

export function useTableSearchResults() {
  const uid = useSearchUid()
  const { dataSource } = useSearchVariables()

  const columns = useSelectedColumnVariables()

  const p = useQueryStringParam('p')
  const pageNum = Number(p ?? 1)

  const pageSize = useSelector(getPageSize)
  const requests = pageSize / 20
  const pageBase = (pageNum - 1) * requests

  const [results] = useMergedQueries<
    TableResult,
    {
      uid: {
        page: TableGraphQLResult[] | null
      }
    }
  >(
    'search-results-table',
    getApplicationSetByColumns,
    range(1, requests + 1).map((index) => ({
      ppair: dataSource === DataSource.PrivatePair,
      transform: ({ uid: { page } }) =>
        page?.map((data) => ({
          columns: data.columns.reduce(
            (acc, column) => ({ ...acc, [column.column]: parse(column) }),
            {} as any
          ),
        })) ?? ([] as any),
      variables: { columns, pageNum: pageBase + index, pageSize: 20, uid: uid.context.data },
    })),
    { enabled: uid.matches('success'), flat: true }
  )

  return results
}

export function useSearchResultsExport({
  dataSource,
  uid,
}: {
  dataSource: DataSource
  uid: string
}) {
  const { tableViewExportClick } = useIntercomActions()
  const { addErrorNotification } = useNotification()

  const columnVariables = useSelectedColumnVariables()
  const [selectedColumns] = useSelectedColumns()

  const columns = columnVariables.filter(({ column }) => selectedColumns.includes(column))

  const [trackExport] = useExport()
  const [startExport, mutation] = useMutation<{
    submitExportJob: { applicationSet: { jobId: string } }
  }>(submitExportQuery, { columns, uid }, { ppair: dataSource === DataSource.PrivatePair })

  useEffect(() => {
    if (mutation.matches('success')) {
      const { jobId } = mutation.context.data.submitExportJob.applicationSet

      trackExport(jobId, { dataSource })
    }

    if (mutation.matches('failure')) {
      addErrorNotification('Your export encountered an error')
    }
  }, [addErrorNotification, dataSource, mutation, trackExport])

  const action = useCallback(() => {
    startExport()
    tableViewExportClick()
  }, [startExport, tableViewExportClick])

  return action
}

export function useSelectedTableColumns() {
  const availableColumns = useTableColumns()
  const selectedColumns = useSelectedColumnsItems()
  const columns = selectedColumns
    .map((name) => availableColumns.find((column) => column.enumName === name))
    .filter((column): column is ApplicationColumn => Boolean(column))

  return columns
}

export function useSearchDataSource() {
  const searchId = useSelector(getActiveResultsSearchId)

  return useSelector((state: AppState) => getSearchDataSourceById(state, { searchId }))
}

export function useSearchInfo() {
  const [p = '1', dataSource = DataSource.PublicPair, uid = config.emptySearchUid] =
    useQueryStringParam(['p', 'src', 'uid'])

  const page = Number(p ?? 1)

  const count = useUidCount(uid, {
    enabled: Boolean(uid),
    ppair: dataSource === DataSource.PrivatePair,
  })

  return { count, dataSource, page, uid } as {
    count: typeof count
    dataSource: DataSource
    page: number
    uid: string
  }
}

function useSearchId() {
  return useSelector(getActiveResultsSearchId)
}

export function useSearchPhrase() {
  const searchId = useSearchId()

  return useSelector((state: AppState) => getSearchPhraseParsedById(state, { searchId }))
}

export function useSearchScopes() {
  const searchId = useSearchId()

  return useSelector((state: AppState) => getSearchScopesById(state, { searchId }))
}

export function useSearchType() {
  const searchId = useSearchId()

  return useSelector((state: AppState) => getSearchTypeById(state, { searchId }))
}

export function useSearchVariables() {
  const { entity, meta } = useContext(FilterContext)
  const filters = useSelector((state: AppState) => getActiveFilters(state, meta))

  const searchId = useSelector(getActiveResultsSearchId)
  const dataSource = useSearchDataSource()
  const phrase = useSearchPhrase()
  const scopes = useSearchScopes()
  const searchType = useSearchType()

  const searchOrderings = useSelector((state: AppState) =>
    getSearchOrderingsById(state, { searchId })
  )

  return useMemo(() => {
    const sortOrders = getSortOrders(phrase, searchOrderings)
    const scopesAndPhrase = scopes.reduce(
      (acc, scope) => ({ ...acc, [scope]: phrase }),
      {}
    ) as SearchPhrase
    const searches =
      searchType === SearchType.Keyword && entity === IntelligenceEntityType.SearchSet
        ? scopesAndPhrase
        : ({} as SearchPhrase)
    const similarTo =
      searchType === SearchType.SimilarTo && entity === IntelligenceEntityType.SearchSet
        ? scopesAndPhrase
        : ({} as SearchPhrase)

    return {
      dataSource,
      filters,
      searches,
      similarTo,
      sortOrders,
    }
  }, [dataSource, entity, filters, phrase, scopes, searchOrderings, searchType])
}

export function useSetReport() {
  const dispatch = useDispatch()
  const setReport = useCallback(
    (payload?: SetReportAction['payload'], meta?: SetReportAction['meta']) => {
      dispatch(actions.setReport(payload, meta))
    },
    [dispatch]
  )

  return setReport
}

export function useSelectedColumnsItems() {
  const context = useContext(SelectedColumnsItemsContext)

  if (context === undefined) {
    throw new Error('useSelectedColumnsItems must be used within a SelectedColumnsContextProvider')
  }

  return context
}

export function useSelectedColumnsUpdate() {
  const context = useContext(SelectedColumnsUpdateContext)

  if (context === undefined) {
    throw new Error('useSelectedColumnsUpdate must be used within a SelectedColumnsContextProvider')
  }

  return context
}

export function useSelectedColumns() {
  return [useSelectedColumnsItems(), useSelectedColumnsUpdate()] as const
}

export function useUidCount(
  uid: string,
  options: Partial<Pick<NonNullable<Parameters<typeof useQuery>[2]>, 'enabled' | 'ppair'>>
) {
  const [count] = useQuery<number, { uid: { total: number } }>(['uid-total', uid], getUidTotal, {
    ...options,
    transform: (data) => data.uid.total,
    variables: { uid },
  })

  return count
}
