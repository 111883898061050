import { AppState } from '.'

const getLocalStorage = (_: AppState) => {
  try {
    return typeof window?.localStorage?.getItem === 'function' ? window.localStorage : null
  } catch (e) {
    return null
  }
}

export { getLocalStorage }
