import { IntelligenceEntityType, OmnisearchType } from '@juristat/common/types'
import { mergeDeepWith, union } from 'ramda'

export function getEntityFromType(type: OmnisearchType | null) {
  switch (type) {
    case OmnisearchType.Firm:
      return IntelligenceEntityType.CurrentFirm
    default:
      return IntelligenceEntityType.CurrentAssignee
  }
}

export function mergeFilters<T, U>(existing: T, overrides: U, valueIndex = 0) {
  return mergeDeepWith(
    (...args) => {
      const value = args[valueIndex]

      return Array.isArray(value) ? union(...args) : value
    },
    existing,
    overrides
  )
}
