import { delay } from 'redux-saga'
import { call, put, race, take, takeEvery } from 'redux-saga/effects'

import actions from '../actions'
import { NotificationPopAction, NotificationPushAction } from '../types'

const makeTakeAction = (id: string) => (action: NotificationPopAction) =>
  action.type === actions.pop().type && action.payload! === id

function* setNotificationTimeout(action: NotificationPushAction) {
  const { id, timeout = 10000 } = action.payload!
  if (timeout === 0) {
    return
  }

  const takeAction = yield call(makeTakeAction, id)
  const { shouldPop } = yield race({
    quit: take(takeAction),
    shouldPop: call(delay, timeout),
  })

  if (shouldPop) {
    yield put(actions.pop(id))
  }
}

export { makeTakeAction, setNotificationTimeout }
export default function* watchSetNotificationTimeout() {
  yield takeEvery([actions.push().type, actions.update().type], setNotificationTimeout)
}
