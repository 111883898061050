import { path } from 'ramda'
import React from 'react'
import { useDispatch } from 'react-redux'

import Accordion from '../../../components/Accordion'
import sessionActions from '../../session/actions'
import CheckPermission from '../../session/components/CheckPermission'
import { Access, Feature } from '../../session/types'

type CheckFilterAccessProps = {
  children: React.ReactNode
  canAccess: Access
}

const CheckFilterAccess: React.FC<CheckFilterAccessProps> = ({ canAccess, children }) => {
  const dispatch = useDispatch()

  return (
    <CheckPermission
      canAccess={canAccess}
      renderNoAccess={() => (
        <>
          {React.Children.map(children, (child) => (
            <Accordion
              className="mb-[2px] cursor-help px-[10px] py-2"
              handleClick={() =>
                dispatch(
                  sessionActions.featureModalSet({ focusFeature: Feature.Other, open: true })
                )
              }
              label={path(['props', 'title'], child) ?? ''}
              locked
            />
          ))}
        </>
      )}
      renderWithAccess={() => <>{children}</>}
    />
  )
}

export default CheckFilterAccess
