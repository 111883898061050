import { ClassificationState, ClassifyResponse } from '../types'

const transformSimilarDocsResponse = (
  similarDocsReponse: ClassifyResponse['similar_docs']
): ClassificationState['similarApplications'] => {
  if (!Array.isArray(similarDocsReponse)) {
    return []
  }

  return similarDocsReponse.map(({ class: classes, ...doc }) => ({
    appno: doc.appno,
    artUnit: classes?.art_unit,
    assignee: {
      id: doc.assignment?.assignee?.entity_id,
      name: doc.assignment?.assignee?.name,
    },
    cpcClass: classes?.cpc,
    similarity: doc.similarity_score,
    techCenter: classes?.tech_center,
    title: doc.title,
    uspcClass: classes?.uspc,
  }))
}

export default transformSimilarDocsResponse
