import * as React from 'react'

import { HttpContent, HttpStatus } from '../types'
import TypedContentComponent from './TypedContentComponent'

type HttpContentProps<T> = {
  content: HttpContent<T>
  renderError: (message: string) => JSX.Element | null
  renderFetching: () => JSX.Element | null
  renderNotAsked?: () => JSX.Element | null
  renderSuccess: (data: T) => JSX.Element | null
}

const HttpContentComponent = <T,>({
  content,
  renderError,
  renderFetching,
  renderNotAsked = () => null,
  renderSuccess,
}: HttpContentProps<T>) => (
  <TypedContentComponent
    content={content}
    renderFunctions={{
      [HttpStatus.Error]: ({ message }: { message: string }) => renderError(message),
      [HttpStatus.Fetching]: renderFetching,
      [HttpStatus.NotAsked]: renderNotAsked,
      [HttpStatus.Success]: ({ data }: { data: T }) => renderSuccess(data),
    }}
  />
)

export default HttpContentComponent
