import { KeyMetricsColumn } from './types'

const columns = [
  {
    key: KeyMetricsColumn.Actions,
    render: '',
    sortable: false,
    width: 50,
  },
  {
    key: KeyMetricsColumn.Name,
    render: 'Name',
    sortable: true,
    width: 250,
  },
  {
    key: KeyMetricsColumn.Pending,
    render: 'Pending',
    sortable: true,
  },
  {
    key: KeyMetricsColumn.Filed,
    render: 'Filed',
    sortable: true,
  },
  {
    key: KeyMetricsColumn.Disposed,
    render: 'Disposed',
    sortable: true,
  },
  {
    key: KeyMetricsColumn.AllowanceRate,
    render: 'Allowance Rate',
    sortable: true,
  },
  {
    key: KeyMetricsColumn.MonthsToDisposition,
    render: 'Months to Disp.',
    sortable: true,
  },
  {
    key: KeyMetricsColumn.AverageOas,
    render: 'Avg. OAs',
    sortable: true,
  },
]

export default columns
