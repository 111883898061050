import { css } from 'emotion'
import { propOr } from 'ramda'
import * as React from 'react'

import { colors, textStyles } from '../styles'
import { TableFetchingLoader } from './StaticTable'

type ColumnConfig<T> = {
  key: T
  render: React.ReactNode
  width?: number
}

type SimpleTableProps<T> = {
  className?: string
  columns: Array<string | ColumnConfig<T>>
  cellsByRow?: React.ReactNode[][]
  fetching?: number
  rowRenderer?: React.FC<{
    cells: React.ReactNode[]
    columns: Array<ColumnConfig<T>>
  }>
  tw?: boolean
}

const twstyles = {
  table:
    '[&_a]:text-link-blue [&_a]:font-opensans [&_a]:text-s [&_a]:font-stretch-normal [&_a]:not-italic [&_a]:font-bold [&_a]:tracking-normal [&_a]:underline [&_td]:text-dark [&_td]:font-opensans [&_td]:text-s [&_td]:font-stretch-normal [&_td]:not-italic [&_td]:font-normal [&_td]:tracking-normal [&_td]:h-[47px] [&_td]:first-child]:py-0 [&_td]:first-child]:pr-[10px] [&_td]:first-child]:pl-5 [&_th:first-child]:py-0 [&_th:first-child]:pr-[10px] [&_th:first-child]:pl-5 [&_td]:last-child]:py-0 [&_td]:last-child]:pr-5 [&_td]:last-child]:pl-[10px] [&_th:last-child]:py-0 [&_th:last-child]:pr-5 [&_th:last-child]:pl-[10px] [&_td]:leading-[1] [&_td]:overflow-hidden [&_td]:py-0 [&_td]:px-[10px] [&_td]:text-left [&_td]:overflow-ellipsis [&_td]:whitespace-nowrap [&_td]:leading-[1] [&_td]:overflow-hidden [&_td]:py-0 [&_td]:px-[10px] [&_td]:text-left [&_td]:overflow-ellipsis [&_td]:whitespace-nowrap [&_th]:text-dark [&_th]:font-opensans [&_th]:text-xs [&_th]:font-stretch-normal [&_th]:not-italic [&_th]:font-bold [&_th]:tracking-normal [&_th]:bg-white [&_th]:border-b [&_th]:border-silver2 [&_th]:h-[39px] [&_tr:not(:last-child)_td]:border-b [&_tr:not(:last-child)_td]:border-silver2 border border-silver2 rounded overflow-hidden table-fixed w-full',
}

const styles = {
  table: css({
    '& a': textStyles.linkBlueBold13Underlined,
    '& td': {
      ...textStyles.darkNormal13,
      height: 47,
    },
    '& td, th': {
      '&:first-child': {
        padding: '0 10px 0 20px',
      },
      '&:last-child': {
        padding: '0 20px 0 10px',
      },
      lineHeight: 1,
      overflow: 'hidden',
      padding: '0 10px',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& th': {
      ...textStyles.darkBold12,
      backgroundColor: colors.white,
      borderBottom: `1px solid ${colors.silver2}`,
      height: 39,
    },
    '& tr:not(:last-child)': {
      '& td': {
        borderBottom: `1px solid ${colors.silver2}`,
      },
    },
    border: `1px solid ${colors.silver2}`,
    borderRadius: 4,
    borderSpacing: 0,
    overflow: 'hidden',
    tableLayout: 'fixed',
    width: '100%',
  }),
}

const SimpleTableRow = <T,>({
  cells,
  columns,
}: {
  cells: React.ReactNode[]
  columns: Array<ColumnConfig<T>>
}) => (
  <tr>
    {cells.map((cell, index) => (
      <td
        key={index}
        style={{ width: columns[index].width }}
        title={typeof cell === 'string' ? cell : undefined}
      >
        {cell}
      </td>
    ))}
  </tr>
)

const makeFetchingLoaders = (columns: any[], numFetchingRows?: number) => {
  if (!numFetchingRows) {
    return []
  }
  return Array(numFetchingRows)
    .fill(0)
    .map((_, row) =>
      Array(columns.length)
        .fill(0)
        .map((__, column) => <TableFetchingLoader key={`${row}_${column}`} />)
    )
}

const SimpleTable = <T,>({
  className,
  columns,
  cellsByRow,
  fetching,
  rowRenderer: Row = SimpleTableRow,
  tw = false,
}: SimpleTableProps<T>) => {
  const columnConfigs: Array<ColumnConfig<T>> = columns.map((column, index) => ({
    key: propOr(index, 'key', column),
    render: propOr(column, 'render', column),
    width: propOr(undefined, 'width', column),
  }))
  const rowCells = fetching ? makeFetchingLoaders(columns, fetching) : cellsByRow!

  return (
    <table className={tw ? `${twstyles.table} ${className}` : css(styles.table, className)}>
      <thead>
        <tr>
          {columnConfigs.map(({ key, render, width }) => (
            <th key={String(key)} style={{ width }}>
              {render}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowCells.map((row, index) => (
          <Row cells={row} columns={columnConfigs} key={index} />
        ))}
      </tbody>
    </table>
  )
}

export { ColumnConfig, SimpleTableProps }
export default SimpleTable
