import * as React from 'react'

import FooterLink from './FooterLink'
import ProductInfo from './ProductInfo'

export default function FooterLinks() {
  return (
    <ul className="!-ml-7 flex items-center">
      <FooterLink text="Privacy Policy" to="/privacy" />
      <FooterLink text="Terms of Use" to="/tou" />
      <FooterLink text="Terms of Sale" to="/tos" />
      <FooterLink text="Contact" to="https://juristat.com/contact" />
      <ProductInfo />
    </ul>
  )
}
