import * as React from 'react'

import SVG from './SVG'
import { DirectionalProps, IconProps } from './types'

const getRotateAngle = ({ direction }: DirectionalProps) => {
  switch (direction) {
    case 'right':
      return 270
    case 'down':
      return 0
    case 'left':
      return 90
    case 'up':
      return 180
  }
}

const Triangle = ({ direction = 'down', ...props }: DirectionalProps & IconProps) => {
  return (
    <SVG
      title={`triangle-${direction}`}
      transform={`rotate(${getRotateAngle({ direction })})`}
      viewBox="0 0 10 5"
      {...props}
    >
      <g transform="translate(-253.000000, -23.000000)">
        <path d="M253.165657,23 C252.944781,23.2138968 252.944781,23.5507843 253.165657,23.7593338 L257.502747,27.8554581 C257.638104,27.9837962 257.823866,28.0212281 258,27.9891436 C258.176134,28.0212281 258.361896,27.9837962 258.49782,27.8554581 L262.834343,23.7593338 C263.055219,23.5507843 263.055219,23.2138968 262.834343,23 C258.663476,23 255.26539,23 253.165657,23 Z" />
      </g>
    </SVG>
  )
}

export default Triangle
