import { keyframes } from 'emotion'

const pulse = keyframes({
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
  '50%': {
    opacity: 0,
  },
})

const shake = keyframes({
  '10%, 90%': {
    transform: 'translateX(-1px)',
  },
  '20%, 80%': {
    transform: 'translateX(2px)',
  },
  '30%, 50%, 70%': {
    transform: 'translateX(-4px)',
  },
  '40%, 60%': {
    transform: 'translateX(4px)',
  },
})

export { pulse, shake }
