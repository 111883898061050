import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import { HttpStatus } from '../../http'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { ClassifyAction, ClassifyResponse } from '../types'
import transformCitedArtResponse from '../utils/transformCitedArtResponse'
import transformClassificationsResponse from '../utils/transformClassificationsResponse'
import transformSimilarDocsResponse from '../utils/transformSimilarDocsResponse'
import transformTermsResponse from '../utils/transformTermsResponse'
import { DRAFTING_URL } from './constants'

function* handleError() {
  yield put(
    notificationActions.push(
      makeNotification({
        message: 'Error running classification',
        type: NotificationTypes.Error,
      })
    )
  )

  yield put(
    actions.setClassificationState({
      message: 'Error running classification',
      type: HttpStatus.Error,
    })
  )
}

function* classify(action: ClassifyAction) {
  const text = action.payload

  yield put(actions.setClassificationState({ type: HttpStatus.Fetching }))

  try {
    const response: ApiResponse<ClassifyResponse> = yield call(api, `${DRAFTING_URL}/analyze`, {
      body: JSON.stringify({ claims: text }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })

    if (!response.ok) {
      return yield fork(handleError)
    }

    const result: ClassifyResponse = yield call([response, 'json'])

    const data = {
      citedArt: transformCitedArtResponse(result.cited_art),
      classifications: transformClassificationsResponse(result.classifications),
      keywords: transformTermsResponse(result.terms),
      similarApplications: transformSimilarDocsResponse(result.similar_docs),
    }

    yield put(
      actions.setClassificationState({
        data,
        type: HttpStatus.Success,
      })
    )
  } catch {
    yield fork(handleError)
  }
}

function* watchClassify() {
  yield takeLatest(actions.classify().type, classify)
}

export { classify, handleError }
export default watchClassify
