import { css } from 'emotion'
import * as React from 'react'

import { Access } from '../../session/types'
import AttributeValue from './AttributeValue'

export type AttributeProps = {
  className?: string
  canAccess?: Access
  label: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  to?: string
  value: number | string | null
}

const styles = {
  container: css({ display: 'flex', paddingRight: '.75em' }),
  indent: css({
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    width: '100%',
  }),
  label: css({
    textTransform: 'capitalize',
  }),
}

const Attribute = ({
  canAccess,
  className,
  label,
  onMouseEnter,
  onMouseLeave,
  to,
  value,
}: AttributeProps) => (
  <div
    className={css(styles.container, className)}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <span className={styles.indent}>
      <span className={styles.label}>{label}</span>
      <AttributeValue {...{ canAccess, to, value }} />
    </span>
  </div>
)

export default Attribute
