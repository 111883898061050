import { useMachine } from '@xstate/react'
import React, { useCallback, useEffect } from 'react'
import { ActorRefFrom, actions, createMachine, spawn } from 'xstate'

import { useQueryStringParam } from '../../../hooks/useQueryStringParam'
import { useAppMutation } from '../../api'
import { createValidationMachine } from '../../workflow-automation'
import { ClientMatterNumberContext } from '../contexts'

type MachineContext = {
  clientMatterNumber: ActorRefFrom<typeof clientMatterNumberMachine>
  value: string | null
}

type MachineEvent = { type: 'CAPTURE' | 'RECAPTURE' }

const clientMatterNumberMachine = createValidationMachine('clientMatterNumber', Boolean)

const machine = createMachine<MachineContext, MachineEvent>({
  id: 'client-matter-number-machine',
  initial: 'setup',
  states: {
    captured: {
      on: {
        RECAPTURE: 'uncaptured',
      },
    },
    save: {
      after: {
        10: 'captured',
      },
    },
    setup: {
      always: [
        { cond: (context) => context.value !== '', target: 'captured' },
        { target: 'uncaptured' },
      ],
    },
    uncaptured: {
      entry: actions.assign({
        clientMatterNumber: (_) => spawn(clientMatterNumberMachine),
      }),
      on: {
        CAPTURE: {
          actions: actions.assign({
            value: (context) => context.clientMatterNumber.state.context.text,
          }),
          target: 'save',
        },
      },
    },
  },
})

const ClientMatterNumberProvider = ({ children }: { children: React.ReactNode }) => {
  const fromUrl = useQueryStringParam('cmn')

  const value = useMachine(machine, { context: { value: fromUrl ?? null } })
  const [state] = value
  const [mutate] = useAppMutation('/expert-search', 'post')

  const save = useCallback(() => {
    const clientMatterNumber = state.context.value

    if (clientMatterNumber) {
      mutate({ clientMatterNumber })
    }
  }, [mutate, state.context.value])

  // Handle when a user changes client matter number
  useEffect(() => {
    if (state.matches('save')) {
      save()
    }
  }, [save, state])

  return (
    <ClientMatterNumberContext.Provider value={value}>
      {children}
    </ClientMatterNumberContext.Provider>
  )
}

export default ClientMatterNumberProvider
