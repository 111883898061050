import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Filters = (props: IconProps) => {
  return (
    <SVG title="filters" viewBox="0 0 17 18" {...props}>
      <path
        fillRule="evenodd"
        d="M11.25 10.8a1.8 1.8 0 1 1 0-3.6 1.8 1.8 0 0 1 0 3.6zm4.5-2.55h-1.607A2.991 2.991 0 0 0 11.25 6a2.992 2.992 0 0 0-2.893 2.25H.75a.75.75 0 0 0 0 1.5h7.607A2.991 2.991 0 0 0 11.25 12c1.396 0 2.56-.958 2.893-2.25h1.607a.75.75 0 1 0 0-1.5zM4.5 4.8a1.8 1.8 0 1 1 0-3.6 1.8 1.8 0 0 1 0 3.6zm11.25-2.55H7.393A2.991 2.991 0 0 0 4.5 0C3.104 0 1.94.958 1.607 2.25H.75a.75.75 0 0 0 0 1.5h.857A2.991 2.991 0 0 0 4.5 6c1.396 0 2.56-.958 2.893-2.25h8.357a.75.75 0 1 0 0-1.5zM4.5 16.8a1.8 1.8 0 1 1 0-3.6 1.8 1.8 0 0 1 0 3.6zm11.25-2.55H7.393A2.992 2.992 0 0 0 4.5 12c-1.396 0-2.56.959-2.893 2.25H.75a.75.75 0 1 0 0 1.5h.857A2.991 2.991 0 0 0 4.5 18c1.396 0 2.56-.958 2.893-2.25h8.357a.75.75 0 1 0 0-1.5z"
      />
    </SVG>
  )
}

export default Filters
