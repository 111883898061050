import * as React from 'react'

import Modal, { ModalBackground, ModalProps } from '.'

type ModalWithBackgroundProps = ModalProps & {
  onClose?: () => void
}

const ModalWithBackground = ({ onClose, ...modalProps }: ModalWithBackgroundProps) => (
  <ModalBackground closeModal={modalProps.closeModal} onClose={onClose}>
    <Modal {...modalProps} />
  </ModalBackground>
)

export default ModalWithBackground
