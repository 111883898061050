import { format, subMonths } from 'date-fns'

import { EntityType } from '../types'

export function getEntityQueryVariables({
  entityType,
  numMonths,
}: {
  entityType: EntityType
  numMonths: number
}) {
  const now = Date.now()
  const startDate = format(subMonths(now, numMonths), 'yyyy-MM-dd')
  const endDate = format(now, 'yyyy-MM-dd')

  const entityTypes =
    entityType === EntityType.Assignees
      ? [{ column: 'CURRENT_ASSIGNEE' }]
      : [{ column: 'FIRM_AT_DISPOSITION' }]

  return { entityTypes, startDate, endDate }
}
