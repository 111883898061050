declare global {
  interface Window {
    clipboardData: {
      getData: (type: string) => string
    }
  }
}

const getClipboardData = (event: Pick<ClipboardEvent, 'clipboardData'>) => {
  if (event.clipboardData) {
    return event.clipboardData.getData('text/plain')
  }
  // Internet explorer only
  if (window.clipboardData) {
    return window.clipboardData.getData('Text')
  }
  return ''
}

export default getClipboardData
