import makeAction from '../../../redux/utils/makeAction'
import {
  ApplyChangesAction,
  FetchSponsorshipsAction,
  SetSponsorshipsAction,
  UpdateSponsorshipAction,
} from '../types'

const applyChanges = makeAction<ApplyChangesAction>('sponsorships/APPLY_CHANGES')
const fetchSponsorships = makeAction<FetchSponsorshipsAction>('sponsorships/FETCH_SPONSORSHIPS')
const setSponsorships = makeAction<SetSponsorshipsAction>('sponsorships/SET_SPONSORSHIPS')
const updateSponsorship = makeAction<UpdateSponsorshipAction>('sponsorships/UPDATE_SPONSORSHIP')

export default {
  applyChanges,
  fetchSponsorships,
  setSponsorships,
  updateSponsorship,
}
