import Downshift, { DownshiftProps } from 'downshift'
import React from 'react'
import { animated, config, useTransition } from 'react-spring'

import { InfoCircle } from '../modules/icons'
import Button from './Button'
import Modal, { ModalSize } from './Modal'

type InformationPopupProps = Pick<DownshiftProps<any>, 'onStateChange'> & {
  children: React.ReactNode
  className?: string
  direction?: 'down' | 'up'
  tw?: boolean
}

type ModalPopupProps = {
  children: React.ReactNode
  isOpen: boolean
  toggleMenu: () => void
  direction: 'down' | 'up'
}

const styles = {
  info: '[&_>_svg]:h-[17px] [&_>_svg]:w-[17px] hover:fill-silver3 items-center bg-white flex fill-silver2 h-7 justify-center transition-[fill] duration-300 ease-out w-7',
  modal: 'shadow-[0_4px_20px_0_rgba(48,_55,_65,_0.3)]',
}

const ModalPopup: React.FC<ModalPopupProps> = ({ isOpen, children, direction, toggleMenu }) => {
  const transition = useTransition(isOpen, {
    config: config.stiff,
    enter: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(-16px)' },
    leave: { opacity: 0, transform: 'translateY(-16px)' },
  })

  return transition(
    (props, item, { key }) =>
      item && (
        <animated.div
          className="absolute z-[100]"
          key={key}
          style={{
            ...(direction === 'down' ? { right: 0, top: 38 } : { right: 28, bottom: 0 }),
            ...props,
          }}
        >
          <Modal
            className={styles.modal}
            closeModal={toggleMenu}
            size={ModalSize.Short}
            title="Description"
          >
            {children}
          </Modal>
        </animated.div>
      )
  )
}

const InformationPopup: React.FC<InformationPopupProps> = ({
  children,
  className,
  direction = 'down',
  onStateChange,
}) => {
  return (
    <Downshift onStateChange={onStateChange}>
      {({ isOpen, toggleMenu }) => (
        <div className={`relative ${className ?? ''}`}>
          <Button handleClick={toggleMenu} tw={styles.info}>
            <InfoCircle title="Click for more information" />
          </Button>
          <ModalPopup {...{ direction, isOpen, toggleMenu }}>{children}</ModalPopup>
        </div>
      )}
    </Downshift>
  )
}

export default InformationPopup
