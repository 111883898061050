import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const EyeWithLine = (props: IconProps) => (
  <SVG title="eye-with-line" viewBox="0 0 12 12" {...props}>
    <path d="M6 3.45996C6.82884 3.46274 7.63943 3.70361 8.33527 4.15392C9.03112 4.60423 9.58293 5.245 9.925 5.99996C9.58445 6.75579 9.03291 7.39722 8.33665 7.84719C7.64038 8.29715 6.82901 8.53652 6 8.53652C5.17099 8.53652 4.35961 8.29715 3.66335 7.84719C2.96708 7.39722 2.41555 6.75579 2.075 5.99996C2.41707 5.245 2.96888 4.60423 3.66472 4.15392C4.36057 3.70361 5.17116 3.46274 6 3.45996ZM6 2.45996C4.903 2.46127 3.83333 2.8023 2.93801 3.43619C2.04269 4.07007 1.36567 4.9657 1 5.99996C1.36404 7.03544 2.04059 7.93235 2.93622 8.56685C3.83186 9.20135 4.90239 9.54212 6 9.54212C7.09761 9.54212 8.16814 9.20135 9.06378 8.56685C9.95941 7.93235 10.636 7.03544 11 5.99996C10.6343 4.9657 9.95731 4.07007 9.06199 3.43619C8.16667 2.8023 7.097 2.46127 6 2.45996Z" />
    <path d="M7.10498 5.55998C6.92902 5.55867 6.76063 5.48819 6.6362 5.36376C6.51177 5.23933 6.44129 5.07095 6.43998 4.89498C6.44337 4.79754 6.46848 4.70209 6.51346 4.61559C6.55844 4.52909 6.62216 4.45372 6.69998 4.39498C6.48088 4.29127 6.24234 4.23504 5.99998 4.22998C5.64991 4.22998 5.3077 4.33379 5.01662 4.52828C4.72555 4.72277 4.49868 4.99921 4.36471 5.32263C4.23075 5.64606 4.1957 6.00195 4.26399 6.34529C4.33229 6.68864 4.50086 7.00402 4.7484 7.25156C4.99594 7.4991 5.31133 7.66768 5.65467 7.73598C5.99802 7.80427 6.35391 7.76922 6.67733 7.63525C7.00076 7.50128 7.27719 7.27442 7.47168 6.98334C7.66617 6.69227 7.76998 6.35006 7.76998 5.99998C7.77121 5.75907 7.72181 5.52059 7.62498 5.29998C7.56417 5.38042 7.4856 5.44574 7.39541 5.49084C7.30521 5.53593 7.20582 5.5596 7.10498 5.55998Z" />
    <path d="M9.50009 10.0001C9.43429 10.0005 9.36906 9.98786 9.30814 9.96298C9.24722 9.9381 9.19181 9.90143 9.14509 9.85509L2.14509 2.85509C2.05094 2.76094 1.99805 2.63324 1.99805 2.50009C1.99805 2.36694 2.05094 2.23924 2.14509 2.14509C2.23924 2.05094 2.36694 1.99805 2.50009 1.99805C2.63324 1.99805 2.76094 2.05094 2.85509 2.14509L9.85509 9.14509C9.90196 9.19157 9.93915 9.24687 9.96454 9.3078C9.98992 9.36873 10.003 9.43409 10.003 9.50009C10.003 9.5661 9.98992 9.63145 9.96454 9.69238C9.93915 9.75331 9.90196 9.80861 9.85509 9.85509C9.80837 9.90143 9.75296 9.9381 9.69205 9.96298C9.63113 9.98786 9.5659 10.0005 9.50009 10.0001Z" />
  </SVG>
)

export default EyeWithLine
