import { DataSource, SearchView } from '@juristat/common/types'
import config from '@juristat/config'
import { parse, stringify } from 'qs'
import { isNil, reject } from 'ramda'

type Params = Partial<{
  dataSource: DataSource
  pageNum: number
  queryString: string
  uid: string
  view: SearchView
}>

const sort = (left: string, right: string) => left.localeCompare(right)

const makeSearchUrl = ({
  dataSource = DataSource.PublicPair,
  pageNum = 1,
  queryString = '',
  uid = config.emptySearchUid,
  view,
}: Params) => {
  const queryObj = parse(queryString, { ignoreQueryPrefix: true })
  const urlView = view === SearchView.Card ? '' : view ? `/${view}` : ''
  const p = pageNum > 1 ? pageNum : undefined
  const src = dataSource !== DataSource.PublicPair ? dataSource : undefined
  const trunk = view === SearchView.IntelligenceApplications ? '' : `/search${urlView}`
  return `${trunk}?${stringify(
    reject(isNil, {
      ...queryObj,
      p,
      src,
      uid,
    }),
    { sort }
  )}`
}

export default makeSearchUrl
