import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import getClipboardData from '../utils/getClipboardData'

type InputHandle = {
  focus: () => void
}

type InputProps = Partial<
  {
    autoFocus: boolean
    className: string
    disabled: boolean
    enabled: boolean
    handleOnBlur: () => void
    handleOnFocus: () => void
    handleOnKeyDown: (keyCode: number, event: React.KeyboardEvent<HTMLInputElement>) => void
    handleOnPaste: (pasteText: string) => void
    handleOnTextChange: (query: string) => void
    pattern: string
    placeholder: string
    readOnly: boolean
    selectOnFocus: boolean
    tw?: string
    text: string
  } & Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'aria-label' | 'id' | 'onClick' | 'type' | 'value'
  >
>

const Input = forwardRef<InputHandle, InputProps>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    className,
    enabled = true,
    handleOnBlur,
    handleOnFocus,
    handleOnKeyDown,
    handleOnPaste,
    handleOnTextChange,
    readOnly = false,
    placeholder,
    selectOnFocus = false,
    text,
    type = 'text',
    ...nativeProps
  } = props

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus()
    },
  }))

  return (
    <input
      aria-label={nativeProps.id ? undefined : placeholder}
      data-testid="text-input"
      className={className}
      disabled={!enabled}
      onBlur={handleOnBlur}
      onFocus={() => {
        if (selectOnFocus) {
          inputRef.current?.select()
        }

        handleOnFocus?.()
      }}
      onChange={(event) => {
        handleOnTextChange?.(event.target.value)
      }}
      onKeyDown={(event) => {
        handleOnKeyDown?.(Number(event.keyCode), event)
      }}
      onPaste={(event) => {
        if (!handleOnPaste) {
          return
        }

        event.preventDefault()
        handleOnPaste(getClipboardData(event))
      }}
      readOnly={readOnly}
      ref={inputRef}
      type={type}
      placeholder={placeholder}
      value={text}
      {...nativeProps}
    />
  )
})

export { InputHandle, InputProps }
export default Input
