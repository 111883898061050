import { useContext } from 'react'

import { DemoModeToggleContext, DemoModeValueContext } from './contexts'

export function useDemoModeToggle() {
  const context = useContext(DemoModeToggleContext)

  if (context === undefined) {
    throw new Error('useDemoModeToggle must be used within a DemoModeContextProvider')
  }

  return context
}

export function useDemoModeValue() {
  const context = useContext(DemoModeValueContext)

  if (context === undefined) {
    throw new Error('useDemoModeValue must be used within a DemoModeContextProvider')
  }

  return context
}

export function useDemoMode() {
  return [useDemoModeValue(), useDemoModeToggle()] as const
}
