import config from '@juristat/config'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api'
import { HttpStatus } from '../../../../http/types'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../../../notification'
import actions from '../actions'
import { getLoadedUserUuid } from '../selectors'
import { UsersDisableAction } from '../types'

function* disableUser(action: UsersDisableAction) {
  const disabled = action.payload!

  const uuid = yield select(getLoadedUserUuid)

  if (!uuid) {
    return
  }

  yield put(actions.setDisabled({ type: HttpStatus.Fetching }))

  try {
    const url = `${config.accountsUrl}/api/user/${uuid}`
    const response = yield call(api, url, {
      body: JSON.stringify({ disabled }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })

    if (!response.ok) {
      yield put(actions.setDisabled({ data: !disabled, type: HttpStatus.Success }))
      yield put(
        notificationActions.push(
          makeNotification({
            message: "Failed to change user's enabled state",
            type: NotificationTypes.Error,
          })
        )
      )
      return
    }

    yield put(actions.setDisabled({ data: disabled, type: HttpStatus.Success }))
  } catch {
    yield put(actions.setDisabled({ data: !disabled, type: HttpStatus.Success }))
    yield put(
      notificationActions.push(
        makeNotification({
          message: "Failed to change user's enabled states",
          type: NotificationTypes.Error,
        })
      )
    )
  }
}

function* watchDisableUser() {
  yield takeLatest(actions.disable().type, disableUser)
}

export { disableUser }
export default watchDisableUser
