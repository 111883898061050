import React from 'react'

import { DateRangeFilter } from '../types'
import FilterContainer, { FilterContainerProps } from './FilterContainer'
import MonthPicker from './MonthPicker'
import { StartEndDate } from './MonthPicker/MonthPicker'

type DateRangeFilterContainerProps = Omit<FilterContainerProps, 'render' | 'filter'> & {
  filter: DateRangeFilter
}

const DateRangeFilterContainer = (props: DateRangeFilterContainerProps) => (
  <FilterContainer
    render={({ selected }) => (
      <MonthPicker selected={selected as StartEndDate} filter={props.filter} />
    )}
    skipHttpStatus
    {...props}
  />
)

export default DateRangeFilterContainer
