import * as React from 'react'

import SVG from './SVG'
import { IconProps, LogoProps } from './types'

const JuristatJ = ({ color, ...props }: LogoProps & IconProps) => {
  return (
    <SVG title="juristat-logo" viewBox="0 0 42.366249 118.0625" {...props}>
      <defs id="defs6">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath16">
          <path d="M 0,720 720,720 720,0 0,0 0,720 Z" id="path18" />
        </clipPath>
      </defs>
      <g id="g10" transform="matrix(1.25,0,0,-1.25,-121.8605,510.30988)">
        <g id="g14" clipPath="url(#clipPath16)">
          <g id="g24" transform="translate(107.8594,313.7979)">
            <path
              fill={color ? color : '#807f83'}
              fillRule="nonzero"
              stroke="none"
              d="m 0,0 c -3.704,0 -7.039,0.556 -9.075,0.924 l -1.296,7.964 c 3.148,-0.741 5.558,-1.11 9.447,-1.11 9.816,0 15.742,4.999 15.742,17.593 l 0,69.079 8.704,0 0,-69.82 C 23.522,7.406 13.521,0 0,0"
              id="path26"
            />
          </g>
        </g>
      </g>
    </SVG>
  )
}

export default JuristatJ
