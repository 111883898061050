import { useCallback, useState } from 'react'

import { usePrintHandlers } from './usePrintHandlers'

export function useIsPrinting() {
  const [isPrinting, setIsPrinting] = useState(false)

  const afterPrintHandler = useCallback(() => setIsPrinting(false), [])
  const beforePrintHandler = useCallback(() => setIsPrinting(true), [])

  usePrintHandlers(beforePrintHandler, afterPrintHandler)

  return isPrinting
}
