import { transform } from '../../search/selectors/getActiveFilters'
import { EntityTypeAndId, IntelligenceEntityType } from '../types'

const entityIdToFilter = (entityType: IntelligenceEntityType, input: string | number) => {
  switch (entityType) {
    case IntelligenceEntityType.Cpc: {
      const match = /^\s*(([a-z])(([0-9]{2})(([a-z])? ?(([0-9]+)[/-]?([0-9]+)?)?)?)?)?\s*$/i.exec(
        input as string
      )

      return match
        ? {
            class: match[4],
            mainGroup: match[8],
            section: match[2],
            subClass: match[6],
            subGroup: match[9],
          }
        : input
    }
    case IntelligenceEntityType.Uspc: {
      const match = /^(([a-zA-Z0-9]+)(\/([a-zA-Z0-9.]+))?)?$/.exec(input as string)

      return match
        ? {
            class: match[2],
            subclass: match[4],
          }
        : input
    }
    default:
      return input
  }
}

const getFilterKey = ({ entity }: Pick<EntityTypeAndId, 'entity'>) => {
  switch (entity) {
    case IntelligenceEntityType.ArtUnit:
      return 'artUnit'
    case IntelligenceEntityType.Cpc:
      return 'cpcClass'
    case IntelligenceEntityType.Examiner:
      return 'examiner'
    case IntelligenceEntityType.TechCenter:
      return 'techCenter'
    case IntelligenceEntityType.Uspc:
      return 'uspcClass'
    case IntelligenceEntityType.FirmAtDisposition:
      return 'firmAtDisposition'
    case IntelligenceEntityType.CurrentAttorney:
      return 'currentAttorney'
    case IntelligenceEntityType.CurrentFirm:
      return 'currentFirm'
    case IntelligenceEntityType.AssigneeAtDisposition:
      return 'assigneeAtDisposition'
    case IntelligenceEntityType.AttorneyAtDisposition:
      return 'attorneyAtDisposition'
    case IntelligenceEntityType.CurrentAssignee:
    default:
      return 'currentAssignee'
  }
}

const getIntrinsicFilters = ({ entity, id }: EntityTypeAndId) => {
  if (entity === IntelligenceEntityType.Uspto || entity === IntelligenceEntityType.SearchSet) {
    return {}
  }

  return transform({
    [getFilterKey({ entity })]: [entityIdToFilter(entity, id.toString())],
  })
}

export default getIntrinsicFilters
