// add more environments here as needed
import { beta, development, production, test } from './envs'

type JuristatV4Configurator = () => JuristatV4Config

type JuristatV4Configurators = {
  [name: string]: JuristatV4Configurator
}

/*
  If all you're doing is consuming the configuration, just do a default import
  from this module, e.g.: import config from '@juristat/config'
*/

// this interface is returned by some functions, so it must be defined separately
interface NetDocsConfig {
  groupId: string
  groupName: string
  authUrl: string
  apiUrl: string
  clientId: string
  clientSecret: string
}

interface IManageConfig {
  groupName: 'arent-fox' | 'fenwick'
  authUrl: string
  apiUrl: string
  clientId: string
  clientSecret: string
  clientUser: string
  clientPass: string
  clientCustomerId: string
  clientLibraryName: string
}

// this is the interface that the configs must implement; update as appropriate
interface JuristatV4Config {
  accountsApiId?: string

  accountsApiKey?: string

  accountsUrl?: string

  analyze?: boolean

  appApiAwsRegion: string

  appApiCommonDbDatabase?: string

  appApiCommonDbHost?: string

  appApiCommonDbPort?: number

  appApiCommonDbPassword?: string

  appApiCommonDbUsername?: string

  appApiPort: number

  appApiUrl: string

  appUrl: string

  auth0Audience: string

  auth0ClientId: string

  auth0Domain: string

  auth0Host?: string

  auth0Issuer: string | string[]

  auth0JuristatAccountsConnection: string

  auth0Namespace: string

  auth0ProviderName: string

  authServiceUrl: string

  baseUrl: string

  credentialsApiUrl: string

  dbHost?: string

  emptySearchUid: string

  hexWorkspaceToken: string

  intercomAccessToken?: string

  jiraApiToken: string

  jiraAssigneeId: string

  jiraAuthEmail: string

  jiraEmail: string

  jiraHost: string

  jurilog: {
    errorSnsArn: string
    eventSnsArn: string
    production: boolean
  }

  legacyAppUrl?: string

  ppairUrl?: string

  production: boolean

  appApiS3Bucket: string

  sendGridApiKey: string

  slackBotToken: string

  slackChannels: {
    analystRoom: string
    idsNotificationRoom: string
    oarPriorityReportRoom: string
    oarsRoom: string
  }

  smartshellUrl: string

  supportEmail: string

  netDocs: {
    [clientName: string]: NetDocsConfig
  }

  iManage: {
    [groupId: string]: IManageConfig
  }

  usptoData: {
    consoleUrl: string
    graphqlUrl: string
    legacyUrl: string
    s3PublicApplicationRole: string
    db: {
      host: string
      port: string
      name: string
      username: string
      password: string
    }
  }

  packetKmsKeyId: string
  packetUploaderRole: string

  websocketUrl: string
}

const configurators: JuristatV4Configurators = {
  beta,
  development,
  prod: production,
  production,
  test,
}

// ok stop touching stuff at this point

const configEnv = process.env.CONFIG_ENV
const nodeEnv = process.env.NODE_ENV

const environment = configEnv || nodeEnv

if (!environment) {
  throw new Error('CONFIG_ENV or NODE_ENV must be defined')
}

const configEnvConfig = typeof configEnv === 'string' ? configurators[configEnv] : null
const nodeEnvConfig = typeof nodeEnv === 'string' ? configurators[nodeEnv] : null

const config = configEnvConfig ?? nodeEnvConfig

if (!config) {
  throw new Error(`no configuration found for CONFIG_ENV or NODE_ENV: ${environment}`)
}

export { JuristatV4Config, NetDocsConfig, IManageConfig }
export default config()
