import config from '@juristat/config'
import { stringify } from 'qs'
import { contains, map, prop } from 'ramda'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import { HttpStatus } from '../../http/types'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { makeGetCurrentRoles } from '../selectors'
import { RemoveRoleAction, RemoveRolePayload } from '../types'

const makeErrorMessage = ({ role, type, uuid }: RemoveRolePayload) =>
  `Failed to remove role ${role} for ${type} with uuid ${uuid}`

const makeUrl = ({ role, type, uuid, ...props }: RemoveRolePayload) => {
  const urlBase = `${config.accountsUrl}/api/${type}/${uuid}/roles/${role}`
  const param = typeof props.param === 'string' ? props.param.trim() : null
  const query = stringify({ param }, { addQueryPrefix: true, skipNulls: true })

  return `${urlBase}${query}`
}

function* removeRole(action: RemoveRoleAction) {
  try {
    const { role, type, uuid } = action.payload!

    // Confirm the user/group has this role and throw error if they don't
    const getCurrentRoles = yield call(makeGetCurrentRoles, uuid)
    const currentRoles = yield select(getCurrentRoles)

    if (
      (currentRoles.type !== HttpStatus.Fetching && currentRoles.type !== HttpStatus.Success) ||
      !contains(role, map(prop('role'), currentRoles.data || []))
    ) {
      throw new Error('Cannot remove a role if it is not currently set')
    }

    const url = makeUrl(action.payload!)
    const options = {
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
    }
    const response = yield call(api, url, options)
    if (response.ok) {
      yield put(actions.getCurrentRoles({ type, uuid }))
    } else {
      throw new Error(makeErrorMessage(action.payload!))
    }
  } catch (unknownErr) {
    const e = unknownErr as Error
    const message = e.message || makeErrorMessage(action.payload!)
    yield put(
      notificationActions.push(
        makeNotification({
          message,
          type: NotificationTypes.Error,
        })
      )
    )
    yield put(actions.setOrRemoveRoleError({ message, uuid: action.payload!.uuid }))
  }
}

export { makeErrorMessage, makeUrl, removeRole }
export default function* watchRemoveRole() {
  yield takeLatest(actions.removeRole().type, removeRole)
}
