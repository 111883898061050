import config from '@juristat/config'
import { path } from 'ramda'
import { delay } from 'redux-saga'
import { call, put, race, select, take } from 'redux-saga/effects'

import { actions as authActions, getExpiration } from '../auth'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../notification'
import getOptionsWithAuth from './utils/getOptionsWithAuth'
import makeGraphqlOptionsJson from './utils/makeGraphqlOptionsJson'

type ApiFunction = <Query, Variables>(
  query: Query,
  variables?: Variables
) => IterableIterator<WeakObject>

const { graphqlUrl } = config.usptoData
const ppairGraphqlUrl = `${config.ppairUrl}/v2/graphql/app`

function* createHttpCall(url: string, options?: RequestInit) {
  const optionsWithAuth = yield call(getOptionsWithAuth, options)
  return yield call(fetch, url, optionsWithAuth)
}

function* createGraphqlCall<Variables>(
  query: Record<string, unknown>,
  variables?: Variables,
  url = graphqlUrl
) {
  const options = makeGraphqlOptionsJson(query, variables ?? {})
  const optionsWithAuth = yield call(getOptionsWithAuth, options)
  return yield call(fetch, url, optionsWithAuth)
}

function* createAppApiCall(route: string, options?: RequestInit) {
  const appApiUrl = `${config.appApiUrl}${route}`
  return yield createHttpCall(appApiUrl, options)
}

function* createPpairCall<Variables>(query: Record<string, unknown>, variables?: Variables) {
  return yield call(createGraphqlCall, query, variables, ppairGraphqlUrl)
}

function* checkJwt<Variables>(variables?: Variables) {
  const expiration = yield select(getExpiration)
  if (expiration < Date.now() && !path(['noJwt'], variables ?? {})) {
    // checking access token
    yield put(authActions.refreshAccessToken())
    const { failed, timeout } = yield race({
      failed: take(authActions.hydrateAccessTokenError().type),
      success: take(authActions.setAccessToken().type),
      timeout: call(delay, 5000),
    })

    if (failed || timeout) {
      yield put(
        notificationActions.push(
          makeNotification({
            link: {
              text: 'signing in',
              to: '/signin',
            },
            message:
              'Could not complete the request because your session has expired and could not be refreshed. Try checking your internet connection or {{link}}.',
            timeout: 0,
            type: NotificationTypes.Error,
          })
        )
      )
      return false
    }
  }

  return true
}

const makeApi = <T extends ApiFunction>(apiFunc: T) =>
  function* <Variables>(
    query: RequestInit | Record<string, unknown> | string,
    variables?: Variables
  ) {
    const isAuthenticated = yield call(checkJwt, variables)
    if (!isAuthenticated) {
      return yield { ok: false }
    }
    return yield call(apiFunc, query, variables)
  }

const api = makeApi(createHttpCall as ApiFunction)
const appApi = makeApi(createAppApiCall as ApiFunction)
const graphqlApi = makeApi(createGraphqlCall as ApiFunction)
const ppairApi = makeApi(createPpairCall as ApiFunction)

export {
  api,
  appApi,
  createHttpCall,
  createGraphqlCall,
  graphqlApi,
  graphqlUrl,
  ppairGraphqlUrl,
  ppairApi,
}
