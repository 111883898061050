import { History } from 'history'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Router } from 'react-router-dom'

import { actions } from '../modules/router'

type ConnectedRouterProps = {
  children: React.ReactNode
  history: History<any>
}

const ConnectedRouter = (props: ConnectedRouterProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const listener = props.history.listen((location) => {
      dispatch(actions.sync(location))
    })

    dispatch(actions.sync(props.history.location, { initial: true }))

    return listener
  }, [])

  return <Router {...props} />
}

export default ConnectedRouter
