import { assign, createMachine } from 'xstate'

import { Context, FetchEvent, FetchState } from '../../types'

type FetchContext = Context<any, any>

const context: FetchContext = {
  data: undefined,
  error: undefined,
  raw: undefined,
  time: 0,
}

export const fetchMachine = createMachine<FetchContext, FetchEvent<any>, FetchState<FetchContext>>({
  id: 'fetch',
  initial: 'idle',
  context,
  states: {
    idle: {
      entry: assign((_) => context),
      on: {
        FETCH: 'loading',
      },
    },
    loading: {
      entry: assign((_) => ({
        ...context,
        time: performance.now(),
      })),
      invoke: {
        src: 'fetchData',
        onDone: {
          target: 'success',
          actions: assign((context, event) => ({
            raw: event.data,
            time: (performance.now() - context.time!) / 1000,
          })),
        },
        onError: {
          target: 'failure',
          actions: assign((_, event) => ({ error: event.data })),
        },
      },
      on: { CANCEL: 'idle' },
    },
    success: {
      initial: 'transform',
      states: {
        refetching: {
          entry: assign((_) => ({ time: performance.now() })),
          invoke: {
            src: 'fetchData',
            onDone: {
              target: '#fetch.success',
              actions: assign((context, event) => ({
                raw: event.data,
                time: (performance.now() - context.time!) / 1000,
              })),
            },
            onError: {
              target: '#fetch.failure',
              actions: assign((_, event) => ({ error: event.data })),
            },
          },
        },
        stale: {
          on: {
            FETCH: '#fetch.loading',
            REFETCH: 'refetching',
          },
        },
        transform: {
          entry: 'transform',
          always: [{ cond: 'transformErrored', target: '#fetch.failure' }, { target: 'stale' }],
        },
      },
    },
    failure: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            FETCH: '#fetch.loading',
            RETRY: 'retrying',
            REFETCH: 'retrying',
          },
        },
        retrying: {
          entry: assign((_) => ({ ...context, time: new Date().getTime() })),
          invoke: {
            src: 'fetchData',
            onDone: {
              target: '#fetch.success',
              actions: assign((_, event) => ({ raw: event.data })),
            },
            onError: {
              target: '#fetch.failure',
              actions: assign((_, event) => ({ error: event.data })),
            },
          },
        },
      },
    },
  },
  on: {
    RESET: '#fetch.idle',
    SET_DATA: {
      target: '#fetch.success.stale',
      actions: assign((_, event) => ({ data: event.data })),
    },
  },
})
