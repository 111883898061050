import { css } from 'emotion'
import {
  eqBy,
  flatten,
  head,
  last,
  map,
  pipe,
  pluck,
  prop,
  range,
  sort,
  sortBy,
  subtract,
  unionWith,
} from 'ramda'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Select } from '../../../components/Select'
import { useIsChartsLibrary } from '../../dashboards/hooks'
import { IntelligenceEntityType } from '../../intelligence/types'
import { getDefaultKeyMetricsBy, getEntityLabel as getEntityName } from '../../intelligence/utils'
import { Charts, Datum, FilingsOverTime, TransformLine, XY } from '../types'
import { ChartProps } from '../types'
import getEntityLabel from '../utils/getEntityLabel'
import LineChart from './LineChart'
import LineChartSkeletonLoader from './LineChartSkeletonLoader'
import LineSliceTooltip from './LineSliceTooltip'
import PlatformChartContainer from './PlatformChartContainer'

const styles = {
  heading: css({
    alignItems: 'center',
    display: 'flex',
  }),
  point: css({
    alignItems: 'center',
    display: 'flex',
  }),
  select: css({
    marginLeft: 10,
    width: 180,
  }),
}

const getYearRange = (ys: number[]) => (ys.length > 0 ? range(head(ys)!, last(ys)! + 1) : [])

const yearsXYs = pipe(
  pluck('data'),
  flatten,
  pluck('year') as any,
  sort(subtract),
  getYearRange,
  map((x) => ({ x, y: 0 }))
)

const transformData = (data: FilingsOverTime[], missingXYs: XY[]) =>
  sortBy(
    prop('x'),
    // This combines missing year data with current year data per entity
    // If we don't do this, data is cut off because it can't stack properly
    unionWith(
      eqBy(prop<'x', number>('x')),
      data.map(({ filings, year }) => ({ x: year, y: filings })),
      missingXYs
    )
  )

const transform: TransformLine<FilingsOverTime> = (datum) => {
  const missingXYs: XY[] = yearsXYs(datum)

  const [first, ...others] = datum

  const otherXYs = others
    .map(({ data, ...item }) => ({
      ...item,
      data: transformData(data, missingXYs),
    }))
    .reverse()

  const firstXYs = {
    ...first,
    data: transformData(first.data, missingXYs).map(({ x, y }, i) => ({
      x,
      y: y - otherXYs.reduce((acc, next) => acc + next.data[i].y, 0),
    })),
  }

  return [...otherXYs, firstXYs]
}

const makeLineSliceTooltip = () => (sliceTooltipProps: any) => (
  <LineSliceTooltip
    {...sliceTooltipProps}
    tooltipFormat=","
    xLegend="Filing Year"
    yLegend="Filings"
  >
    {({ slice, valueFormatter }) =>
      slice.points.map(({ id, data: { y }, serieColor, serieId }, index) => {
        const value = index === 0 ? slice.points.reduce((acc, next) => acc + next.data.y, 0) : y

        return (
          <div key={id} className={styles.point}>
            {slice.points.length > 1 && (
              <div
                style={{
                  backgroundColor: serieColor,
                  height: 14,
                  marginRight: 8,
                  width: 14,
                }}
              />
            )}
            <div>
              {getEntityLabel(serieId, 30)}: <strong>{valueFormatter(value)}</strong>
            </div>
          </div>
        )
      })
    }
  </LineSliceTooltip>
)

const allEntities = [
  IntelligenceEntityType.ArtUnit,
  IntelligenceEntityType.AssigneeAtDisposition,
  IntelligenceEntityType.CurrentAssignee,
  IntelligenceEntityType.FirmAtDisposition,
  IntelligenceEntityType.CurrentFirm,
  IntelligenceEntityType.Cpc,
  IntelligenceEntityType.TechCenter,
  IntelligenceEntityType.Uspc,
]

const DivisionOfWork: React.FC<ChartProps> = (props) => {
  const Tooltip = useMemo(makeLineSliceTooltip, [])
  const { entity } = useParams<{ entity: IntelligenceEntityType }>()
  const [by, setBy] = useState(getDefaultKeyMetricsBy(entity))
  const isChartsLibrary = useIsChartsLibrary()

  const processData = (data: Array<Datum<FilingsOverTime>>) => {
    const [lookup] = props.lookups
    const shownIds = [
      lookup,
      ...props.lookups.filter(
        (item) =>
          (item.filterKey?.id === lookup.id ||
            item.filterKey?.entity === IntelligenceEntityType.SearchSet) &&
          item.entity === by
      ),
    ].map((item) => `${item.entity}_${item.label}`)
    const filtered = data.filter((item) => shownIds.indexOf(item.id) > -1)

    return transform(filtered.length > 0 ? filtered : data)
  }

  return (
    <PlatformChartContainer
      chart={Charts.DivisionOfWork}
      exportableConfig={{
        filename: `division_of_work_by_${by}`,
        getData: (data) =>
          processData(data).map((item) => [item.id, ...item.data.map(({ y }) => y)]),
        getHeader: (data) => data[0].data.map(({ year }) => year),
      }}
      skeleton={LineChartSkeletonLoader}
      style={{ columns: 1 }}
      {...props}
      title={
        <span className={styles.heading}>
          Division of Work by
          <Select
            className={styles.select}
            disabled={isChartsLibrary}
            handleSelectionChange={setBy}
            options={allEntities.map((entityType) => ({
              label: getEntityName(entityType),
              value: entityType,
            }))}
            value={by}
          />
        </span>
      }
    >
      {({ data: raw, ...dimensions }) => {
        const data = processData(raw)

        return (
          <LineChart
            {...dimensions}
            areaOpacity={0.4}
            axisBottom={{
              legend: 'Filing Year',
            }}
            axisLeft={{
              format: '~s',
              legend: 'Applications',
            }}
            data={data}
            enableArea={true}
            enablePoints={true}
            legend={{
              data: data
                .slice(0)
                .reverse()
                .map(({ color, id }, index) => ({
                  color,
                  id,
                  label: getEntityLabel(id, 17).toUpperCase(),
                  value: index,
                })),
              effects: undefined,
              onClick: undefined,
            }}
            splitLegend={false}
            tooltipFormat=","
            sliceTooltip={Tooltip}
            yScale={{
              stacked: true,
              type: 'linear',
            }}
          />
        )
      }}
    </PlatformChartContainer>
  )
}

export default DivisionOfWork
