import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const StackOfPapers = (props: IconProps) => (
  <SVG title="stack-of-papers" viewBox="0 0 62.448 62.448" {...props}>
    <path
      d="M62.381,53.827c-0.007-1.23-0.206-2.517-0.288-3.668c-0.401-5.625-0.932-11.243-1.691-16.832
		c-0.04-0.293-0.226-0.467-0.456-0.557c0.183-2.945,0.281-5.784-1.376-8.392c-0.119-0.187-0.278-0.284-0.445-0.332
		c0.063-3.005-0.66-5.656-3.329-6.61c0.124-1.065,0.233-2.133,0.303-3.21c0.043-0.67-0.584-1.039-1.162-1.021
		c-0.046-0.462-0.467-0.92-0.995-0.744c-12.435,4.131-26.11,1.39-34.457-9.243c-0.255-0.324-0.811-0.262-1.075,0
		C12.154,8.44,6.767,12.975,1.07,17.693c-0.328,0.272-0.318,0.621-0.148,0.898c-0.203,0.163-0.319,0.456-0.173,0.724
		c0.716,1.32,1.529,2.6,2.415,3.844c-0.511,0.658-1.012,1.32-1.501,1.996c-0.573,0.793-1.3,1.574-1.619,2.505
		c-0.269,0.787,0.765,1.293,1.326,0.803c0.451,0.488,0.928,0.954,1.398,1.426c-0.055,0.027-0.111,0.052-0.16,0.097
		c-1.256,1.163-1.787,2.129-2.237,3.769c-0.081,0.298-0.003,0.659,0.231,0.872c0.704,0.643,1.428,1.257,2.164,1.854
		c-5.141,3.253-1.863,8.88,1.926,11.664c8.038,5.904,18.355,7.737,27.984,9.204c4.768,0.726,9.559,1.481,14.376,1.782
		c3.658,0.228,9.846,0.931,13.235-0.948c1.599-0.887,2.045-2.374,2.09-3.996C62.475,54.09,62.466,53.936,62.381,53.827z
		 M56.431,34.224c0.273-1.735,1.048-4.544,1.452-7.391c0.81,2.827,0.19,6.398-0.132,9.326c-0.524,0.014-1.049,0.053-1.558,0.068
		C56.261,35.523,56.334,34.841,56.431,34.224z M56.48,44.167c0.372,0.011,0.753,0.039,1.134,0.049
		c-0.03,0.981-0.1,1.934-0.265,2.788c-0.655,0.072-1.311,0.183-1.95,0.271c-0.366,0.05-0.283,0.679,0.088,0.649
		c0.544-0.044,1.103-0.071,1.659-0.121c-0.058,0.198-0.103,0.409-0.174,0.591c-2.218,5.637-17.339,0.81-22.022-0.219
		c-5.135-1.129-36.886-9.915-31.292-17.378c0.001-0.001,0.001-0.003,0.002-0.004C15.131,41.805,31.608,48.274,47.22,49.487
		c2.55,0.198,5.162,0.452,6.683-1.932c1.125-1.763,1.594-4.26,1.876-6.803c0.032,0.004,0.057,0.021,0.096,0.01
		c0.579-0.173,1.147-0.233,1.722-0.26c0.019,0.98,0.045,2.006,0.034,3.018c-0.387,0.009-0.774,0.038-1.152,0.049
		C56.096,43.581,56.095,44.155,56.48,44.167z M55.135,33.043c-0.501-0.068-0.983,0.069-1.48,0.17
		c-0.639,0.13-1.279,0.257-1.918,0.385c-0.024,0.005-0.039,0.016-0.059,0.024c0.201-0.902,0.403-1.804,0.605-2.706
		c0.056,0.011,0.118,0.006,0.184-0.034c1.055-0.645,2.042-0.739,3.186-0.817C55.499,31.066,55.322,32.068,55.135,33.043z
		 M57.623,37.538c-0.046,0.693-0.045,1.492-0.033,2.328c-0.6,0.044-1.196,0.187-1.757,0.392c0.119-1.165,0.206-2.323,0.303-3.423
		c0.005,0,0.006,0.005,0.011,0.005c0.5,0.013,1.017,0.053,1.533,0.069C57.661,37.119,57.637,37.337,57.623,37.538z M53.239,26.518
		c0.898,0.175,1.985,0.221,2.748-0.324c0.203-0.145,0.16-0.539-0.14-0.518c-0.851,0.062-1.616,0.289-2.472,0.186
		c0.448-2.181,0.859-4.37,1.173-6.581c0.878,0.514,1.34,1.689,1.525,3.242l-1.593,0.053c-0.375,0.012-0.376,0.571,0,0.584
		c0.538,0.018,1.077,0.036,1.615,0.053c0.013,0,0.027,0.002,0.041,0.002c0.116,1.778-0.064,3.944-0.382,6.163
		c-1.137-0.158-2.366,0.285-3.326,0.888C52.707,29.019,52.977,27.769,53.239,26.518z M1.922,18.998
		c0.043-0.013,0.086-0.014,0.128-0.035c5.766-2.836,9.868-7.57,14.417-11.933c2.236-2.144,1.867-1.291,4.195,0.914
		c1.744,1.651,3.678,3.074,5.763,4.264c7.931,4.525,18.12,5.631,26.575,1.933c-0.269,1.801-0.505,3.607-0.735,5.414
		c-2.554-0.113-5.351,0.659-7.859,1.028c-0.291,0.043-0.226,0.549,0.07,0.518c2.472-0.261,5.296-0.22,7.708-0.917
		c-0.391,3.084-0.768,6.17-1.245,9.243c-0.353,2.28-0.849,4.611-1.215,6.948c-2.523,0.001-5.042,0.276-7.576,0.238
		c-0.289-0.004-0.279,0.418,0,0.443c2.417,0.217,5.05,0.393,7.47,0.022c-0.12,0.834-0.217,1.669-0.284,2.502
		c-0.192,0.026-0.385,0.047-0.578,0.051c-0.029-0.077-0.079-0.144-0.182-0.161c-1.137-0.191-2.319-0.026-3.469,0.002
		c-1.319,0.033-2.638,0.04-3.957,0.013c-0.464-0.009-0.458,0.692,0,0.717c2.218,0.123,4.929,0.473,7.129-0.086
		c0.31,0.047,0.66,0.081,1.018,0.083c-0.044,0.742-0.066,1.483-0.042,2.221c-9.603-0.356-18.576-1.703-27.232-6.172
		C13.86,32.037,7.754,25.92,1.922,18.998z M2.033,27.632c0.316-0.499,0.578-1.046,0.86-1.544c0.356-0.626,0.694-1.26,1.033-1.894
		c10.125,13.299,29.434,21.725,45.57,19.609c0.69-0.091,0.848-0.909,0.4-1.233c0.134-0.832,0.285-1.66,0.439-2.487
		c0.233-0.065,0.448-0.16,0.628-0.294c0.207-0.155,0.179-0.589-0.151-0.557c-0.112,0.011-0.221,0.034-0.329,0.062
		c0.339-1.756,0.715-3.503,1.101-5.247c0.065,0.092,0.175,0.155,0.314,0.135c0.605-0.091,1.209-0.184,1.814-0.274
		c0.439-0.065,0.894-0.096,1.306-0.255c-0.252,1.277-0.514,2.495-0.752,3.576c-0.812-0.052-1.652,0.046-2.451,0.084
		c-0.391,0.018-0.396,0.611,0,0.612c0.747,0.001,1.537,0.06,2.298-0.004c-0.301,1.365-0.545,2.46-0.645,3.11
		c-0.027,0.173-0.046,0.339-0.069,0.509c-0.754-0.011-1.521,0.043-2.252,0.074c-0.33,0.014-0.331,0.499,0,0.513
		c0.702,0.03,1.437,0.08,2.162,0.074c-0.147,1.158-0.285,2.223-0.741,3.121c-0.01-0.003-0.014-0.014-0.025-0.016
		c-1.637-0.264-3.411-0.055-5.064-0.016c-0.317,0.008-0.317,0.485,0,0.492c1.567,0.037,3.24,0.214,4.805,0.009
		c-0.61,0.873-1.63,1.55-3.453,1.937c-1.364,0.289-3.354-0.291-4.698-0.464c-3.167-0.408-6.295-1.017-9.383-1.83
		C22.329,42.161,11.911,35.596,2.033,27.632z M60.263,53.931c-2.921,0.396-5.837,1.116-8.782,1.385
		c-0.288,0.026-0.299,0.458,0,0.457c2.547-0.012,5.487-0.098,8.145-0.689c-3.702,4.529-18.724,1.354-23.046,0.72
		c-6.02-0.883-12.076-1.772-17.925-3.493c-4.032-1.187-22.81-8.053-15.302-15.093c0.062-0.058,0.094-0.124,0.115-0.191
		c11.773,9.21,27.227,12.943,41.749,15.169c3.832,0.588,11.961,2.231,13.628-2.644c0.498-1.457,0.735-3.156,0.853-4.931
		c0.145,1.822,0.769,4.614,0.823,6.923c-0.721,0.078-1.454,0.225-2.127,0.318c-0.337,0.047-0.258,0.613,0.08,0.594
		c0.641-0.037,1.344-0.038,2.029-0.093c-0.026,0.542-0.081,1.057-0.213,1.492C60.284,53.883,60.271,53.906,60.263,53.931z"
    />
    <path
      d="M49.462,32.83c-2.444,0.145-4.851,0.728-7.316,0.841c-0.288,0.013-0.291,0.443,0,0.45
		c2.369,0.053,5.131,0.077,7.392-0.725C49.827,33.294,49.807,32.809,49.462,32.83z"
    />
    <path
      d="M49.814,29.575c-1.352,0.044-2.746,0.421-4.073,0.67c-0.257,0.048-0.202,0.492,0.063,0.463
		c1.341-0.149,2.786-0.193,4.09-0.546C50.214,30.075,50.156,29.564,49.814,29.575z"
    />
    <path
      d="M50.491,25.933c-1.281,0.111-2.528,0.465-3.814,0.595c-1.287,0.129-2.57,0.132-3.862,0.095
		c-0.3-0.009-0.285,0.425,0,0.457c2.42,0.275,5.445,0.366,7.759-0.532C50.875,26.431,50.872,25.9,50.491,25.933z"
    />
    <path
      d="M51.145,23.217c-1.305-0.072-2.697,0.259-3.993,0.419c-0.355,0.044-0.371,0.599,0,0.569
		c1.325-0.105,2.784-0.062,4.072-0.401C51.56,23.715,51.474,23.235,51.145,23.217z"
    />
    <path
      d="M52.141,17.161c-1.095-0.042-2.246,0.232-3.331,0.375c-0.37,0.049-0.39,0.63,0,0.598
		c1.116-0.092,2.328-0.07,3.415-0.351C52.578,17.691,52.492,17.175,52.141,17.161z"
    />
  </SVG>
)

export default StackOfPapers
