import React from 'react'

import BaseSelect, { SelectProps as BaseSelectProps } from './BaseSelect'

type SelectProps<T> = BaseSelectProps<T> & {
  handleSelectionChange: (selection: T) => void
  value: T
}

const Select = <T extends string | number>({
  children,
  handleSelectionChange,
  title,
  value,
  ...props
}: SelectProps<T>) => {
  const { label = '' } = props.options.find((option) => option.value === value) ?? {}

  return (
    <BaseSelect
      {...props}
      label={label}
      onChange={(selection) => {
        if (selection) {
          handleSelectionChange(selection.value)
        }
      }}
    >
      {({ className, getItemProps, index, ...item }) =>
        children?.({ className, getItemProps, index, ...item }) ?? (
          <li
            {...getItemProps({
              className,
              index,
              item,
              key: item.value,
            })}
          >
            {item.label}
          </li>
        )
      }
    </BaseSelect>
  )
}

export default Select
