import { useLayoutEffect, useRef, useState } from 'react'

const useMedia = (query: string) => {
  const mountedRef = useRef<boolean>()
  const [state, setState] = useState(() => window.matchMedia(query).matches)

  useLayoutEffect(() => {
    mountedRef.current = true

    const listener = window.matchMedia(query)
    const onChange = () => {
      if (!mountedRef.current) {
        return
      }

      setState(!!listener.matches)
    }

    listener.addListener(onChange)

    setState(listener.matches)

    return () => {
      mountedRef.current = false

      listener.removeListener(onChange)
    }
  }, [query])

  return state
}

export default useMedia
