import cuid from 'cuid'
import { mergeDeepRight, propEq, reject } from 'ramda'
import { combineReducers } from 'redux'

import { NOTIFICATION_POP, NOTIFICATION_PUSH, NOTIFICATION_UPDATE } from '../actions'
import {
  Notification,
  NotificationPopAction,
  NotificationPushAction,
  NotificationUpdateAction,
  NotificationUpdatePayload,
} from '../types'

type NotificationsActions =
  | NotificationPopAction
  | NotificationPushAction
  | NotificationUpdateAction

const makeMergeById = (update: NotificationUpdatePayload) => (notis: Notification[]) =>
  notis.map((notification: Notification) => {
    return update.id === notification.id ? mergeDeepRight(notification, update) : notification
  })

export const notifications = (state: Notification[] = [], action: NotificationsActions) => {
  switch (action.type) {
    case NOTIFICATION_POP:
      return reject(propEq('id', action.payload!), state)
    case NOTIFICATION_PUSH:
      return [...state, { id: cuid(), ...action.payload }]
    case NOTIFICATION_UPDATE:
      return makeMergeById(action.payload!)(state)
    default:
      return state
  }
}

const reducer = combineReducers({
  notifications,
})

export default reducer
