import { pathOr } from 'ramda'
import { call, put, takeLatest } from 'redux-saga/effects'

import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { IntercomShowNewMessageApi, ShowNewIntercomMessageAction } from '../types'

function* showNewIntercomMessage(action: ShowNewIntercomMessageAction) {
  const intercom: IntercomShowNewMessageApi | null = pathOr(null, ['Intercom'], window)

  if (!intercom) {
    yield put(
      notificationActions.push(
        makeNotification({
          link: {
            text: 'contact page',
            to: 'https://juristat.com/contact',
          },
          message:
            'Error: Intercom chat not loaded. Please use our {{link}} to speak to our sales team.',
          type: NotificationTypes.Error,
        })
      )
    )
    return
  }

  yield call(intercom, 'showNewMessage', action.payload!)
}

function* watchShowNewIntercomMessage() {
  yield [takeLatest(actions.intercom.showNewMessage().type, showNewIntercomMessage)]
}

export { showNewIntercomMessage }
export default watchShowNewIntercomMessage
