import { Classification, MetricsKeyMap } from '../types'

type GroupsKeyMap = 'art_unit' | 'class' | 'cpc4' | 'tech_center'
type FilterKeyMap = 'artUnit' | 'cpcClass' | 'techCenter' | 'uspcClass'

type ClassificationMap<T> = { [K in Classification]: T }

type MapFn = (value: string) => number | WeakObject

const labelMap = {
  [Classification.ArtUnit]: 'Art Unit',
  [Classification.Cpc]: 'CPC',
  [Classification.TechCenter]: 'Tech Center',
  [Classification.Uspc]: 'USPC',
}

const urlMap = {
  [Classification.ArtUnit]: 'art-units',
  [Classification.Cpc]: 'cpc4',
  [Classification.TechCenter]: 'tech-centers',
  [Classification.Uspc]: 'classes',
}

const filterKeyMap: ClassificationMap<FilterKeyMap> = {
  [Classification.ArtUnit]: 'artUnit',
  [Classification.Cpc]: 'cpcClass',
  [Classification.TechCenter]: 'techCenter',
  [Classification.Uspc]: 'uspcClass',
}

const commonKeys = {
  [Classification.ArtUnit]: 'art_unit',
  [Classification.Cpc]: 'cpc4',
  [Classification.TechCenter]: 'tech_center',
} as const

const metricsKeyMap: ClassificationMap<MetricsKeyMap> = {
  ...commonKeys,
  [Classification.Uspc]: 'id',
}

const groupsKeyMap: ClassificationMap<GroupsKeyMap> = {
  ...commonKeys,
  [Classification.Uspc]: 'class',
}

const filterValuesMap: { [key: string]: MapFn } = {
  [Classification.ArtUnit]: Number,
  [Classification.Cpc]: (input: string) => {
    const regex = /^\s*(([a-z])(([0-9]{2})(([a-z])? ?(([0-9]+)[/-]?([0-9]+)?)?)?)?)?\s*$/i
    const match = regex.exec(input)

    if (!match) {
      return {}
    }

    return {
      class: match[4],
      mainGroup: match[8],
      section: match[2],
      subClass: match[6],
      subGroup: match[9],
    }
  },
  [Classification.TechCenter]: Number,
  [Classification.Uspc]: (input: string) => {
    const match = /^(([a-zA-Z0-9]+)(\/([a-zA-Z0-9.]+))?)?$/.exec(input)

    if (!match) {
      return {}
    }

    return {
      class: match[2],
      subclass: match[4],
    }
  },
}

export {
  GroupsKeyMap,
  FilterKeyMap,
  MetricsKeyMap,
  filterKeyMap,
  filterValuesMap,
  groupsKeyMap,
  labelMap,
  metricsKeyMap,
  urlMap,
}
