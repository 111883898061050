import { createContext } from 'react'
import { ActorRefFrom, Interpreter, State, Typestate } from 'xstate'

import { createValidationMachine } from '../workflow-automation'

type MachineContext = {
  clientMatterNumber: ActorRefFrom<ReturnType<typeof createValidationMachine>>
  value: string | null
}

type MachineEvent = { type: 'CAPTURE' | 'RECAPTURE' }

type Machine = [
  State<MachineContext, MachineEvent, any, Typestate<MachineContext>>,
  Interpreter<MachineContext, any, MachineEvent, Typestate<MachineContext>>['send'],
  Interpreter<MachineContext, any, MachineEvent, Typestate<MachineContext>>,
]

export const ClientMatterNumberContext = createContext<Machine | undefined>(undefined)
