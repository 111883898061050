import config from '@juristat/config'
import { map } from 'ramda'
import { call, put, takeEvery } from 'redux-saga/effects'

import { api } from '../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { GetCurrentRolesAction, RolesPayload } from '../types'
import transformRoleResponse from './utils/transformRoleResponse'

const makeErrorMessage = ({ type, uuid }: RolesPayload) =>
  `Unable to fetch current roles for ${type} with uuid ${uuid}`
const makeUrl = ({ type, uuid }: RolesPayload) => `${config.accountsUrl}/api/${type}/${uuid}/roles`

function* getCurrentRoles(action: GetCurrentRolesAction) {
  try {
    const url = makeUrl(action.payload!)
    const response = yield call(api, url)
    if (response.ok) {
      const result = yield call([response, 'json'])
      const roles = map(transformRoleResponse, result)
      yield put(
        actions.setCurrentRoles({
          ...action.payload!,
          roles,
        })
      )
      return
    } else {
      throw new Error(makeErrorMessage(action.payload!))
    }
  } catch (unknownErr) {
    const e = unknownErr as Error
    const message = e.message || makeErrorMessage(action.payload!)
    yield put(
      notificationActions.push(
        makeNotification({
          message,
          type: NotificationTypes.Error,
        })
      )
    )
    yield put(actions.getCurrentRolesError({ message, uuid: action.payload!.uuid }))
  }
}

export { getCurrentRoles, makeErrorMessage, makeUrl }
export default function* watchGetCurrentRoles() {
  yield takeEvery(actions.getCurrentRoles().type, getCurrentRoles)
}
