import { useMachine } from '@xstate/react'
import { equals } from 'ramda'
import { useCallback } from 'react'

import { MutationTypeState, QueryKey } from '../types'
import { getQueryKey } from '../utils'
import { mutationMachine } from './machines'
import useAppApi from './useAppApi'
import useServicesMeta from './useServicesMeta'

export function useAppMutation<T>(
  url: string,
  method: 'patch' | 'post' | 'put' | 'delete',
  { refetchQueries = [] }: Partial<{ refetchQueries: QueryKey[] }> = {}
) {
  const api = useAppApi()
  const services = useServicesMeta()
  const [current, send] = useMachine(mutationMachine, {
    services: {
      mutateData: (_, { body, ...overrides }) =>
        api(overrides.url ?? url, { body, method: method.toUpperCase() }),
      refetchQueries: () => {
        for (const queryKey of refetchQueries) {
          const arrayQueryKey = Array.isArray(queryKey) ? queryKey : [queryKey]

          services.get(getQueryKey(arrayQueryKey))?.service?.send('REFETCH')

          Array.from(services.values())
            .filter((meta) => equals(meta.queryKey, queryKey))
            .forEach((meta) => meta.service?.send('REFETCH'))
        }

        return Promise.resolve()
      },
    },
  })
  const mutate = useCallback(
    <T>(data?: T, url?: string) => {
      send('FETCH', { body: JSON.stringify(data), url })
    },
    [send]
  )
  const reset = useCallback(() => {
    send('RESET')
  }, [send])

  return [mutate, current as MutationTypeState<T>, reset] as const
}
