import { Column } from '../modules/table/types'
import { OrderDirection } from '../types'

const queryString = {
  [OrderDirection.Ascending]: 'asc',
  [OrderDirection.Descending]: 'desc',
}

const fromQueryString = {
  asc: OrderDirection.Ascending,
  desc: OrderDirection.Descending,
}

const defaultSortOrder = {
  direction: OrderDirection.Descending,
  field: Column.PublicationDate,
}

const directionToQueryString = (direction: OrderDirection) => queryString[direction]

const directionFromQueryString = (direction: 'asc' | 'desc') => fromQueryString[direction]

export { defaultSortOrder, directionFromQueryString, directionToQueryString }
