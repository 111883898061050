import { css } from 'emotion'
import * as React from 'react'

import Month from './Month'
import { MonthAndYear } from './MonthPicker'

type SelectionContainerProps = {
  set: (date: MonthAndYear) => void
  month: number
  year: number
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const styles = {
  wrapper: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }),
}

const SelectionContainer = ({ set, month: current, year }: SelectionContainerProps) => (
  <div className={styles.wrapper}>
    {months.map(
      (month: string, idx: number): JSX.Element => (
        <Month
          key={month}
          selected={idx === current}
          handleClick={() => {
            set({ year, month: idx })
          }}
          month={month}
        />
      )
    )}
  </div>
)

export default SelectionContainer
