import { useEffect, useRef } from 'react'

type Callback = () => void

export function useInterval(callback: Callback, delay: number | null) {
  const savedCallback = useRef<Callback | null>(null)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current?.()
    }

    if (delay !== null) {
      const interval = setInterval(tick, delay)

      return () => clearInterval(interval)
    }
  }, [delay])
}
