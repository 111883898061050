import { DataSource, SearchScope } from '@juristat/common/types'
import { mergeAll } from 'ramda'
import { ParametricSelector, createSelector } from 'reselect'

import { AppState } from '../../redux'
import memoize from '../../utils/memoize'
import getFilterStateFromUrl from '../filter/utils/getFilterStateFromUrl'
import { IntelligenceEntityType } from '../intelligence/types'
import { getIntrinsicFilters } from '../intelligence/utils'
import { getPathname } from '../router/selectors'
import {
  getSearchDataSourceById,
  getSearchPhraseParsedById,
  getSearchScopesById,
  getSearchTypeById,
} from '../search/selectors'
import getActiveFilters from '../search/selectors/getActiveFilters'
import { SearchType } from '../search/types'
import { ChartType, Lookup } from './types'

type LookupsParam = { lookups: Lookup[] }

const getLookups: ParametricSelector<AppState, LookupsParam, Lookup[]> = (_, { lookups }) => lookups

const getChartVariables = createSelector(
  (state) => state,
  getPathname,
  getLookups,
  (state, pathname, lookups) => {
    const { entity: mainEntity, id: mainId } = lookups[0]

    return memoize(
      lookups.map(({ color, label, ...payload }) => {
        const scope =
          payload.entity === IntelligenceEntityType.Uspto
            ? undefined
            : payload.entity !== mainEntity
            ? { entity: mainEntity, id: mainId }
            : undefined

        const { entity, filterKey, id, type } = payload
        const intrinsicFilters = getIntrinsicFilters({ entity, id })
        const searchId = String(
          entity === IntelligenceEntityType.SearchSet
            ? id
            : filterKey?.entity === IntelligenceEntityType.SearchSet
            ? filterKey.id
            : ''
        )
        const phrase = getSearchPhraseParsedById(state, { searchId })
        const scopes = getSearchScopesById(state, { searchId }) || [SearchScope.FullText]
        const searchType = getSearchTypeById(state, { searchId }) || SearchType.Keyword
        const scopesAndPhrase = scopes.reduce((acc, scope) => ({ ...acc, [scope]: phrase }), {})

        const searches = searchType === SearchType.Keyword ? scopesAndPhrase : {}
        const similarTo = searchType === SearchType.SimilarTo ? scopesAndPhrase : {}

        const { meta } = getFilterStateFromUrl(pathname)

        if ([entity, filterKey?.entity].some((item) => item === IntelligenceEntityType.SearchSet)) {
          const dataSource = getSearchDataSourceById(state, { searchId })
          const filters = getActiveFilters(state, { report: meta.report })

          return {
            dataSource,
            filters: mergeAll([filters, intrinsicFilters]),
            searches,
            similarTo,
          }
        }

        const activeFilters = getActiveFilters(
          state,
          filterKey
            ? {
                ...meta,
                entity: filterKey.entity,
                id: filterKey.id,
              }
            : meta
        )
        const scopedFilters =
          type === ChartType.Breakdown && scope
            ? getIntrinsicFilters(scope)
            : type === ChartType.Compare && filterKey
            ? getIntrinsicFilters(filterKey)
            : {}

        return {
          dataSource: DataSource.PublicPair,
          filters: mergeAll([intrinsicFilters, scopedFilters, activeFilters]),
          searches,
          similarTo,
        }
      })
    )
  }
)

export { getChartVariables }
