import React from 'react'

import { Charts, RejectionBasesOverTime as Data, TransformLine, XY } from '../types'
import { ChartProps } from '../types'
import { getTitleForLookups } from '../utils'
import LineChart from './LineChart'
import LineChartSkeletonLoader from './LineChartSkeletonLoader'
import PlatformChartContainer from './PlatformChartContainer'

type DataKey =
  | 'haveType101'
  | 'haveType102'
  | 'haveType103'
  | 'haveType112a'
  | 'haveType112b'
  | 'haveType171'
  | 'haveTypeAlice'
  | 'haveTypeBilski'
  | 'haveTypeDoublePatenting'
  | 'haveTypeMayoMyriad'

const transform: TransformLine<Data> = (datum) => {
  // Only first row is applicable on this chart
  const data = datum[0].data
    .sort((current, next) => current.year - next.year)
    .reduce(
      (
        {
          haveType101,
          haveType102,
          haveType103,
          haveType112a,
          haveType112b,
          haveType171,
          haveTypeAlice,
          haveTypeBilski,
          haveTypeDoublePatenting,
          haveTypeMayoMyriad,
        },
        { counts, year }
      ) => ({
        haveType101: {
          ...haveType101,
          data: [...haveType101.data, { x: year, y: counts.haveType101 }],
        },
        haveType102: {
          ...haveType102,
          data: [...haveType102.data, { x: year, y: counts.haveType102 }],
        },
        haveType103: {
          ...haveType103,
          data: [...haveType103.data, { x: year, y: counts.haveType103 }],
        },
        haveType112a: {
          ...haveType112a,
          data: [...haveType112a.data, { x: year, y: counts.haveType112a }],
        },
        haveType112b: {
          ...haveType112b,
          data: [...haveType112b.data, { x: year, y: counts.haveType112b }],
        },
        haveType171: {
          ...haveType171,
          data: [...haveType171.data, { x: year, y: counts.haveType171 }],
        },
        haveTypeAlice: {
          ...haveTypeAlice,
          data: [...haveTypeAlice.data, { x: year, y: counts.haveTypeAlice }],
        },
        haveTypeBilski: {
          ...haveTypeBilski,
          data: [...haveTypeBilski.data, { x: year, y: counts.haveTypeBilski }],
        },
        haveTypeDoublePatenting: {
          ...haveTypeDoublePatenting,
          data: [...haveTypeDoublePatenting.data, { x: year, y: counts.haveTypeDoublePatenting }],
        },
        haveTypeMayoMyriad: {
          ...haveTypeMayoMyriad,
          data: [...haveTypeMayoMyriad.data, { x: year, y: counts.haveTypeMayoMyriad }],
        },
      }),
      {
        haveType101: { data: [] as XY[], id: '101' },
        haveType102: { data: [] as XY[], id: '102' },
        haveType103: { data: [] as XY[], id: '103' },
        haveType112a: { data: [] as XY[], id: '112a' },
        haveType112b: { data: [] as XY[], id: '112b' },
        haveType171: { data: [] as XY[], id: '171' },
        haveTypeAlice: { data: [] as XY[], id: 'Alice' },
        haveTypeBilski: { data: [] as XY[], id: 'Bilski' },
        haveTypeDoublePatenting: { data: [] as XY[], id: 'Dbl. Pat.' },
        haveTypeMayoMyriad: { data: [] as XY[], id: 'Mayo Myriad' },
      }
    )

  return Object.keys(data).map((key) => data[key as DataKey])
}

const RejectionBasesOverTime: React.FC<ChartProps> = (props) => (
  <PlatformChartContainer
    chart={Charts.RejectionBasesOverTime}
    exportableConfig={{
      filename: 'rejection_bases',
      getData: (data) => transform(data).map((item) => [item.id, ...item.data.map(({ y }) => y)]),
      getHeader: (data) => transform(data)[0].data.map(({ x }) => x),
    }}
    skeleton={LineChartSkeletonLoader}
    title={getTitleForLookups('Rejection Bases', props.lookups)}
    {...props}
  >
    {({ data: raw, ...dimensions }) => {
      const data = transform(raw)

      return (
        <LineChart
          {...dimensions}
          axisBottom={{
            legend: 'Rejection Year',
          }}
          axisLeft={{
            format: '~s',
            legend: 'Bases Count',
          }}
          data={data}
          legend={{
            anchor: 'right',
            data: data.map(({ id }) => ({ id, label: id })),
            direction: 'column',
            itemWidth: 95,
            translateX: 105,
            translateY: 0,
          }}
          margin={{
            bottom: 45,
            left: 50,
            right: 105,
            top: 10,
          }}
          splitLegend={false}
          tooltipFormat=",d"
        />
      )
    }}
  </PlatformChartContainer>
)

export default RejectionBasesOverTime
