import makeAction from '../../../redux/utils/makeAction'
import {
  GetAvailableRolesAction,
  GetAvailableRolesErrorAction,
  GetCurrentRolesAction,
  GetCurrentRolesErrorAction,
  RemoveRoleAction,
  SetAvailableRolesAction,
  SetCurrentRolesAction,
  SetOrRemoveRoleErrorAction,
  SetRoleAction,
} from '../types'

const getAvailableRoles = makeAction<GetAvailableRolesAction>('account/GET_AVAILABLE_ROLES')
const getAvailableRolesError = makeAction<GetAvailableRolesErrorAction>(
  'account/GET_AVAILABLE_ROLES_ERROR'
)
const getCurrentRoles = makeAction<GetCurrentRolesAction>('account/GET_CURRENT_ROLES')
const getCurrentRolesError = makeAction<GetCurrentRolesErrorAction>(
  'account/GET_CURRENT_ROLES_ERROR'
)
const removeRole = makeAction<RemoveRoleAction>('account/REMOVE_ROLE')
const setAvailableRoles = makeAction<SetAvailableRolesAction>('account/SET_AVAILABLE_ROLES')
const setCurrentRoles = makeAction<SetCurrentRolesAction>('account/SET_CURRENT_ROLES')
const setRole = makeAction<SetRoleAction>('account/SET_ROLE')
const setOrRemoveRoleError = makeAction<SetOrRemoveRoleErrorAction>(
  'account/SET_OR_REMOVE_ROLE_ERROR'
)

export default {
  getAvailableRoles,
  getAvailableRolesError,
  getCurrentRoles,
  getCurrentRolesError,
  removeRole,
  setAvailableRoles,
  setCurrentRoles,
  setOrRemoveRoleError,
  setRole,
}
