import { Dispatch, SetStateAction, createContext } from 'react'

import { Cite } from '../../modules/analyst-tasks/types'
import { AssignmentBroadcast } from './types'

type UpdateAction = (data: Record<string, unknown>, id: string, localOnly?: boolean) => void
type UpdateDocument = (data: Cite) => void

export const CheckedContext = createContext<string[] | undefined>(undefined)
export const HighlightContext = createContext<AssignmentBroadcast | null | undefined>(undefined)
export const SetCheckedContext = createContext<Dispatch<SetStateAction<string[]>> | undefined>(
  undefined
)
export const UpdateActionContext = createContext<UpdateAction | undefined>(undefined)
export const UpdateDocumentContext = createContext<UpdateDocument | undefined>(undefined)
