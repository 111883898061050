import { isEmpty, mapObjIndexed, omit, pipe, reject } from 'ramda'

import { Suggestion } from '../types'

const transformSuggestionsResponse: (response: Suggestion) => Suggestion = pipe(
  mapObjIndexed((value: WeakObject, key: string) => omit([key], value)),
  reject(isEmpty)
)

export default transformSuggestionsResponse
