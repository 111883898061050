import { IntercomActions } from '../../../analytics/types'
import { Feature } from '../../../session/types'

type FeatureIntercomEventMap = { [feature in Feature]: IntercomActions }

const featureIntercomEvents: FeatureIntercomEventMap = {
  [Feature.Charts]: IntercomActions.FeatureChartView,
  [Feature.Compare]: IntercomActions.FeatureCompare,
  [Feature.OfficeActions]: IntercomActions.FeatureApplicationOfficeActions,
  [Feature.Other]: IntercomActions.FeatureOther,
  [Feature.PatentFamily]: IntercomActions.FeatureApplicationPatentFamily,
  [Feature.SavedSearches]: IntercomActions.FeatureSavedSearches,
  [Feature.Table]: IntercomActions.FeatureTableView,
  [Feature.ThreePaneView]: IntercomActions.FeatureApplicationThreePaneView,
}

export default featureIntercomEvents
