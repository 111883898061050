import { DataSource } from '@juristat/common/types'
import { css, cx } from 'emotion'
import { flatten, isEmpty, map, pipe, propOr, without } from 'ramda'
import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import ConditionalWrapper from '../../../components/ConditionalWrapper'
import { useNoop } from '../../../hooks/useNoop'
import { AppState } from '../../../redux'
import { colors } from '../../../styles'
import { FetchTypeState, useQuery } from '../../api'
import AddToDashboardButton from '../../dashboards/components/AddToDashboardButton'
import {
  useDashboardDataSource,
  useDashboardItems,
  useIsChartsLibrary,
} from '../../dashboards/hooks'
import {
  DraggableGridItem,
  GridItem,
  GridItemProps,
  GridItemType,
  StaticGridItem,
  useGridItems,
} from '../../grid-items'
import { DragHandle } from '../../icons'
import { transform } from '../../search/selectors/getActiveFilters'
import { getChartVariables } from '../selectors'
import { ChartData, PlatformChartsData, PlatformChartsInState } from '../types'
import { ChartProps } from '../types'
import { ExportableConfig } from '../types'
import { getChartQueryConfig } from '../utils'
import ChartContainer from './ChartContainer'
import ChartSettingsMenu from './ChartSettingsMenu'

type ChildrenProps<T> = {
  data: T
  height: number
  width: number
}

type PlatformChartContainerProps<T extends PlatformChartsInState> = ChartProps &
  Omit<GridItemProps, 'itemKey'> & {
    chart: T
    children: (props: ChildrenProps<PlatformChartsData[T]>) => JSX.Element
    exportableConfig?: ExportableConfig<PlatformChartsData[T]>
    skeleton: (props: { animate?: boolean }) => JSX.Element
    title: React.ReactNode
  }

const styles = {
  container: css({
    display: 'flex',
    flexGrow: 1,
  }),
  draggable: css({
    '&:hover': {
      '& h2 + div > svg': {
        fill: colors.charcoalGray6,
        opacity: 1,
      },
      border: '1px solid transparent',
      boxShadow: '0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15)',
    },
    transition: 'border-color 300ms ease-in-out, box-shadow 300ms ease-in-out',
  }),
  handle: css({
    cursor: 'grab',
    fill: colors.charcoalGray2,
    opacity: 0.3,
    transition: 'fill 300ms ease-in-out, opacity 300ms ease-in-out',
  }),
  header: css({
    display: 'flex',
    height: 32,
    marginLeft: 'auto',
  }),
  help: css({
    padding: 20,
  }),
  infoText: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'center',
    marginTop: -20,
  }),
  title: css({
    '& > div:first-of-type': {
      marginRight: 175,
    },
  }),
  titleContainer: css({
    '& > div:first-of-type': {
      height: 32,
    },
  }),
}

const checkEmpty = pipe(map(propOr([], 'data')), flatten, isEmpty)

const PlatformChartContainer = <T extends PlatformChartsInState>({
  chart,
  draggable = true,
  exportableConfig,
  gridItem,
  id,
  lookups,
  style,
  ...props
}: PlatformChartContainerProps<T>) => {
  const domRef = useRef<HTMLDivElement>(null)
  const chartVariables = useSelector((state: AppState) => getChartVariables(state, { lookups }))
  const { createQuery, transformData, transformFilters, transformOfficeActionSetFilters } =
    getChartQueryConfig(chart)
  const dashboardDataSource = useDashboardDataSource()
  const lookupsHash = lookups.map(({ id, label }) => `${id}-${label}`).join('/')

  const [machine] = useQuery([chart, lookupsHash], createQuery?.(chartVariables.length), {
    ppair: (dashboardDataSource ?? chartVariables.at(0)?.dataSource) === DataSource.PrivatePair,
    enabled: Boolean(createQuery),
    transform(response: Record<string, any>) {
      return lookups.map(({ color, entity, label }, index) => {
        return {
          color,
          data: transformData(response[`index${index}`]),
          id: `${entity}_${label}`,
        }
      })
    },
    variables: chartVariables.reduce((acc, { dataSource, ...variables }, index) => {
      const filters = transform(transformFilters(variables.filters))
      const oaFilters = transformOfficeActionSetFilters?.(variables.filters)

      Object.entries({ ...variables, filters, oaFilters }).forEach(([key, value]) => {
        acc[`${key}${index}`] = value
      })

      return acc
    }, {}),
  })

  const dashboard = useRouteMatch('/dashboards/:dashboard?') !== null
  const useMaybeDashboardItems = dashboard ? useDashboardItems : useNoop
  const maybeDashboardItems = useMaybeDashboardItems()
  const useMaybeGridItems = dashboard || !draggable ? useNoop : useGridItems
  const maybeGridItems = useMaybeGridItems()

  const height = 550
  const isChartsLibrary = useIsChartsLibrary()

  const headerContent = useMemo(() => {
    return isChartsLibrary && gridItem !== undefined ? (
      <AddToDashboardButton item={{ chart: gridItem.chart, type: GridItemType.Chart }} />
    ) : (
      <div className={styles.header}>
        {exportableConfig && (
          <ChartSettingsMenu
            {...exportableConfig}
            className={draggable ? undefined : css({ right: 32 })}
            handleRemove={() => {
              const removeItem = without<GridItem>([
                gridItem ?? { chart, id: id ?? undefined, type: GridItemType.Chart },
              ])

              if (dashboard) {
                const [dashboardItems, updateDashboardItems] = maybeDashboardItems

                return updateDashboardItems(removeItem(dashboardItems), { autoSave: false })
              }

              const [gridItems, , , updateGridItems] = maybeGridItems

              updateGridItems(removeItem(gridItems))
            }}
            dom={domRef}
            machine={machine}
          />
        )}
        {draggable && (
          <DragHandle
            className={cx(styles.handle, 'handle')}
            height={32}
            width={32}
            title="Drag to reorder chart"
          />
        )}
      </div>
    )
  }, [
    chart,
    dashboard,
    draggable,
    exportableConfig,
    gridItem,
    id,
    isChartsLibrary,
    machine,
    maybeDashboardItems,
    maybeGridItems,
  ])

  return (
    <ConditionalWrapper
      condition={draggable}
      wrapper={(children) => (
        <DraggableGridItem data={gridItem} itemKey={id} style={style}>
          {children}
        </DraggableGridItem>
      )}
    >
      <ConditionalWrapper
        condition={isChartsLibrary}
        wrapper={(children) => (
          <StaticGridItem itemKey={id ?? chart} style={{ ...style, columns: 1 / 2, height }}>
            {children}
          </StaticGridItem>
        )}
      >
        <div ref={domRef}>
          <ChartContainer
            bodyContent={
              isChartsLibrary && <div className={styles.help}>{gridItem?.description}</div>
            }
            checkEmpty={checkEmpty}
            className={cx(
              {
                [css({ height }, styles.title)]: isChartsLibrary,
                [styles.draggable]: draggable,
              },
              styles.titleContainer
            )}
            headerContent={headerContent}
            machine={machine as FetchTypeState<ChartData[T], any>}
            {...props}
          />
        </div>
      </ConditionalWrapper>
    </ConditionalWrapper>
  )
}

export default PlatformChartContainer
