import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Spreadsheet = (props: IconProps) => (
  <SVG title="spreadsheet" viewBox="0 0 13 13" {...props}>
    <path d="M11.5699 1.04004H1.42992C1.28692 1.04004 1.16992 1.15704 1.16992 1.30004V3.90004V5.46004V7.02004V8.58004V10.14V11.7C1.16992 11.843 1.28692 11.96 1.42992 11.96H4.80992H8.18992H11.5699C11.7129 11.96 11.8299 11.843 11.8299 11.7V10.14V8.58004V7.02004V5.46004V3.90004V1.30004C11.8299 1.15704 11.7129 1.04004 11.5699 1.04004ZM7.92992 4.16004V5.20004H5.06992V4.16004H7.92992ZM11.3099 4.16004V5.20004H8.44992V4.16004H11.3099ZM11.3099 6.76004H8.44992V5.72004H11.3099V6.76004ZM11.3099 8.32004H8.44992V7.28004H11.3099V8.32004ZM1.68992 7.28004H4.54992V8.32004H1.68992V7.28004ZM1.68992 5.72004H4.54992V6.76004H1.68992V5.72004ZM5.06992 5.72004H7.92992V6.76004H5.06992V5.72004ZM5.06992 7.28004H7.92992V8.32004H5.06992V7.28004ZM4.54992 5.20004H1.68992V4.16004H4.54992V5.20004ZM1.68992 8.84004H4.54992V9.88004H1.68992V8.84004ZM5.06992 8.84004H7.92992V9.88004H5.06992V8.84004ZM8.44992 8.84004H11.3099V9.88004H8.44992V8.84004ZM1.68992 1.56004H11.3099V3.64004H8.18992H4.80992H1.68992V1.56004ZM1.68992 10.4H4.54992V11.44H1.68992V10.4ZM5.06992 10.4H7.92992V11.44H5.06992V10.4ZM11.3099 11.44H8.44992V10.4H11.3099V11.44Z" />
  </SVG>
)

export default Spreadsheet
