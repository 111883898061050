import { Action } from '../../redux'

export type NavigationState = {
  drawer: DrawerState
}

export enum DrawerState {
  Closed = 'closed',
  Filters = 'filters',
  SavedSearches = 'savedSearches',
}

/* Actions */
export type LogoutAction = Action<'navigation/LOGOUT', undefined>
export type SetDrawerStateAction = Action<'navigation/SET_DRAWER_STATE', DrawerState>

export type Actions = LogoutAction | SetDrawerStateAction
