import { Filter, SearchView } from '@juristat/common/types'
import { isEmpty } from 'ramda'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { useAlerts } from '../modules/alerts'
import actions from '../modules/search/actions'

const RedirectToPersonal: React.FC = () => {
  const dispatch = useDispatch()
  const alerts = useAlerts()

  if ((alerts.matches('success') && isEmpty(alerts.context.data)) || alerts.matches('failure')) {
    return <Redirect to="/" />
  }

  if (alerts.matches('success')) {
    dispatch(
      actions.setReport({
        filters: {
          [Filter.ApplicationNumber]: alerts.context.data.map(String),
        },
        view: SearchView.Card,
      })
    )
  }

  return null
}

export default RedirectToPersonal
