import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Drafting = (props: IconProps) => {
  return (
    <SVG title="drafting" viewBox="0 0 17 20" {...props}>
      <path
        fillRule="nonzero"
        d="M16.015 13.516H13.33l-1.266-3a.781.781 0 1 0-1.44.608l1.01 2.392H9.141v-.782a.781.781 0 1 0-1.563 0v.782H5.116l1.937-4.627a3.52 3.52 0 0 0 4.822-3.264c0-1.67-1.17-3.072-2.734-3.428V.781a.781.781 0 1 0-1.563 0v1.416a3.522 3.522 0 0 0-2.734 3.428c0 .9.34 1.723.9 2.346l-2.322 5.545H.782a.781.781 0 1 0 0 1.562h1.986L1.16 18.917a.781.781 0 1 0 1.44.603l1.86-4.442h3.117v.781a.781.781 0 1 0 1.563 0v-.781h3.152l1.876 4.444a.781.781 0 1 0 1.44-.607l-1.62-3.837h2.026a.781.781 0 0 0 0-1.562zM8.36 3.672c1.077 0 1.953.876 1.953 1.953A1.955 1.955 0 0 1 8.36 7.578a1.955 1.955 0 0 1-1.953-1.953c0-1.077.876-1.953 1.953-1.953z"
      />
    </SVG>
  )
}

export default Drafting
