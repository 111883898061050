import makeAction from '../../../../../redux/utils/makeAction'
import {
  ChangeUserGroupAction,
  CreateUserAction,
  UsersDisableAction,
  UsersErrorAction,
  UsersFetchDetailsAction,
  UsersSearchAction,
  UsersSetAction,
  UsersSetDetailsAction,
  UsersSetDisabledAction,
  UsersVerifyEmailAction,
} from '../types'

const changeGroup = makeAction<ChangeUserGroupAction>('accounts/users/CHANGE_GROUP')

const createUser = makeAction<CreateUserAction>('accounts/users/CREATE')
const usersDisable = makeAction<UsersDisableAction>('account/users/DISABLE')
const usersError = makeAction<UsersErrorAction>('accounts/users/ERROR')
const usersFetchDetails = makeAction<UsersFetchDetailsAction>('accounts/users/FETCH_DETAILS')
const usersSearch = makeAction<UsersSearchAction>('accounts/users/SEARCH')
const usersSet = makeAction<UsersSetAction>('accounts/users/SET')
const usersSetDetails = makeAction<UsersSetDetailsAction>('accounts/users/SET_DETAILS')
const usersSetDisabled = makeAction<UsersSetDisabledAction>('accounts/users/SET_DISABLED')
const usersVerifyEmail = makeAction<UsersVerifyEmailAction>('accounts/users/VERIFY_EMAIL')

export default {
  changeGroup,
  create: createUser,
  disable: usersDisable,
  error: usersError,
  fetchDetails: usersFetchDetails,
  search: usersSearch,
  set: usersSet,
  setDetails: usersSetDetails,
  setDisabled: usersSetDisabled,
  verifyEmail: usersVerifyEmail,
}
