import { createContext, useContext } from 'react'

import { noop } from '../utils'

const NoopContext = createContext(null)

export function useNoop<T>(): T {
  return noop() as unknown as T
}

export function useNoopContext<T>(): T {
  useContext(NoopContext)

  return noop() as unknown as T
}
