import { useEffect, useRef } from 'react'

const usePrevious = <T>(value: T, setToCurrent = false) => {
  const ref = useRef<T | undefined>(setToCurrent ? value : undefined)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default usePrevious
