import { Reducer, combineReducers } from 'redux'

import { CreateStatus, PortfolioState, ReducerActions } from '../types'

const create: Reducer<PortfolioState['create']> = (
  state = { type: CreateStatus.Editing },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'portfolio/EDITING':
      return { type: CreateStatus.Editing }
    case 'portfolio/SAVE':
      return { type: CreateStatus.Saving }
    case 'portfolio/SAVED':
      return { type: CreateStatus.Saved }
    default:
      return state
  }
}

const reducer = combineReducers<PortfolioState>({ create })

export default reducer
