import { compose, keys, mergeDeepLeft, not, omit, pickBy, union, without } from 'ramda'
import { combineReducers } from 'redux'

import {
  SearchHistoryData,
  SearchHistoryDeleteFavoriteAction,
  SearchHistoryDeletedAction,
  SearchHistoryFetchAction,
  SearchHistoryHistoryState,
  SearchHistorySaveError,
  SearchHistorySaveFavoriteAction,
  SearchHistorySavedAction,
  SearchHistorySetAction,
  SearchHistoryUpdateFavoriteAction,
  UserSearchesFetchingStateIsFetching,
  UserSearchesFetchingStateItems,
} from '../types'
import { getUnsavedKey } from '../utils/userDataKeys'

type SetReducerActions =
  | SearchHistoryDeletedAction
  | SearchHistorySetAction
  | SearchHistorySavedAction

const isFavorite = (v: SearchHistoryData, k: string) => {
  return v && /saved/gi.test(k) && JSON.stringify(v.query) !== '{}'
}
const notIsFavorite = compose(not, isFavorite)

export const history = (state: SearchHistoryHistoryState = {}, action: SetReducerActions) => {
  switch (action.type) {
    case 'search/history/SET':
      return action.meta?.onlySaved === true
        ? state
        : pickBy(notIsFavorite, action.payload) || state
    case 'search/history/SAVED':
      return mergeDeepLeft(pickBy(notIsFavorite, action.payload!) as Record<string, unknown>, state)
    case 'search/history/DELETED': {
      const savedIdsToReject = keys(action.payload)

      return omit(savedIdsToReject, state)
    }
    default:
      return state
  }
}

const favorites = (
  state: SearchHistoryHistoryState = {},
  action: SetReducerActions | SavingActions
) => {
  switch (action.type) {
    case 'search/history/SET':
      return pickBy(isFavorite, action.payload) || state
    case 'search/history/UPDATE_FAVORITE': {
      const { userDataKey } = action.payload!
      return mergeDeepLeft({ [userDataKey]: action.payload! }, state)
    }
    case 'search/history/SAVED':
      return mergeDeepLeft(pickBy(isFavorite, action.payload!) as Record<string, unknown>, state)
    case 'search/history/DELETED': {
      const savedIdsToReject = keys(action.payload)

      return omit(savedIdsToReject, state)
    }
    default:
      return state
  }
}

type SavingActions =
  | SearchHistoryDeletedAction
  | SearchHistoryDeleteFavoriteAction
  | SearchHistorySaveFavoriteAction
  | SearchHistorySavedAction
  | SearchHistorySaveError
  | SearchHistoryUpdateFavoriteAction

const isFetching = (
  state: UserSearchesFetchingStateIsFetching = false,
  action: SavingActions | SearchHistoryFetchAction | SearchHistorySetAction
): UserSearchesFetchingStateIsFetching => {
  switch (action.type) {
    case 'search/history/FETCH':
      return true
    case 'search/history/DELETED':
    case 'search/history/SAVED':
    case 'search/history/SAVE_ERROR':
    case 'search/history/SET':
      return false
    default:
      return state
  }
}

const fetchingItems = (
  state: UserSearchesFetchingStateItems = [],
  action: SavingActions
): UserSearchesFetchingStateItems => {
  switch (action.type) {
    case 'search/history/DELETE_FAVORITE': {
      const savedDeletingItem = action.payload!
      const deletingItem = getUnsavedKey(action.payload!)

      return union(state, [deletingItem, savedDeletingItem])
    }
    case 'search/history/SAVE_FAVORITE':
    case 'search/history/UPDATE_FAVORITE':
      return union(state, [action.payload!.userDataKey])
    case 'search/history/DELETED':
    case 'search/history/SAVED': {
      const savedDeletedIds = keys(action.payload!) as string[] // Not sure where it is getting number from
      const deletedIds = savedDeletedIds.map(getUnsavedKey)

      return without(savedDeletedIds.concat(deletedIds), state)
    }
    case 'search/history/SAVE_ERROR':
      return without([action.payload!.userDataKey], state)
    default:
      return state
  }
}

const fetching = combineReducers({
  isFetching,
  items: fetchingItems,
})

const view = combineReducers({
  fetching,
})

const reducer = combineReducers({
  favorites,
  history,
  view,
})

export default reducer
