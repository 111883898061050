import { DataSource } from '@juristat/common/types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import actions from './actions'

type Options = Partial<{
  appno: string
  dataSource: DataSource
  exportName: string
  timeout: number
  skipNotification: boolean
}>

export function useExport() {
  const dispatch = useDispatch()

  const trackExport = useCallback(
    (jobId: string, { dataSource = DataSource.PublicPair, ...meta }: Options = {}) => {
      console.log({ jobId, meta })
      dispatch(actions.push({ dataSource, jobId }, meta))
    },
    [dispatch]
  )

  return [trackExport] as const
}
