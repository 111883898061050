import React from 'react'

import SortableTable, { SortableTableProps } from '../../../components/SortableTable'

const styles = {
  table:
    '[&_tbody_tr:nth-child(odd)]:bg-white [&_thead_th_svg]:fill-[#d5d8dc] [&_thead_th]:bg-transparent [&_thead_th]:border-none [&_thead_th]:!text-blue-gray [&_thead_th]:font-montserrat [&_tr_td]:!border-none [&_tr_td]:text-blue-gray [&_tr_td]:font-roboto [&_tr_td]:overflow-[inherit] border-none',
}

const WorkflowAutomationTable = <T,>({ className, ...props }: SortableTableProps<T>) => (
  <SortableTable className={`${styles.table} ${className ?? ''}`} tw={true} {...props} />
)

export default WorkflowAutomationTable
