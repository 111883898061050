import { cps, put, takeLatest } from 'redux-saga/effects'

import actions from '../actions'
import { LoginEmailSendAction } from '../types'
import { auth0PasswordlessStart } from '../utils/auth0'

function* sendLoginEmail(action: LoginEmailSendAction) {
  try {
    const options = {
      connection: 'email',
      email: action.payload!,
      send: 'link',
    }
    yield cps(auth0PasswordlessStart, options)

    yield put(actions.loginEmailSent({ email: action.payload!, sent: Date.now() }))
  } catch (e) {
    yield put(actions.loginEmailError('Unable to send login email'))
  }
}

export { sendLoginEmail }
export default function* watchSendLoginEmail() {
  yield takeLatest(actions.sendLoginEmail().type, sendLoginEmail)
}
