import { T, always, cond, contains, equals, filter, flip, isEmpty, keys, path } from 'ramda'

import { HttpStatus } from '../../http/types'
import {
  CurrentOrDispositionState,
  Filter,
  FilterReportType,
  PossibleActions,
  SearchSetFilterState,
} from '../types'
import active from './active'
import available from './available'
import open from './open'
import selected from './selected'

const initialSetState: SearchSetFilterState = {
  active: {},
  assignee: Filter.AssigneeAtDisposition,
  attorney: Filter.AttorneyAtDisposition,
  available: { type: HttpStatus.NotAsked },
  firm: Filter.FirmAtDisposition,
  open: [],
  selected: {},
}

const currentOrDisposition = (
  state: CurrentOrDispositionState,
  action: PossibleActions,
  options: Filter[]
) => {
  switch (action.type) {
    case 'filter/HYDRATE': {
      const appliedFilter = filter(flip(contains)(options), keys(action.payload as any) as any)

      const updatedState = cond<CurrentOrDispositionState, Filter>([
        [equals<Filter>(Filter.AssigneeAtDispositionName), always(Filter.AssigneeAtDisposition)],
        [equals<Filter>(Filter.CurrentAssigneeName), always(Filter.CurrentAssignee)],
        [equals<Filter>(Filter.CurrentFirmName), always(Filter.CurrentFirm)],
        [equals<Filter>(Filter.FirmAtDispositionName), always(Filter.FirmAtDisposition)],
        [T, always(state)],
      ])(state)

      return isEmpty(appliedFilter) ? updatedState : appliedFilter[0]
    }
    case 'filter/SWITCH':
      return contains(action.payload!, options)
        ? (action.payload as CurrentOrDispositionState)
        : state
    default:
      return state
  }
}

const searchSetReducer = (
  state: SearchSetFilterState = initialSetState,
  action: PossibleActions
) => ({
  active: active(state.active, action, state),
  assignee: currentOrDisposition(state.assignee, action, [
    Filter.AssigneeAtDisposition,
    Filter.AssigneeAtDispositionName,
    Filter.CurrentAssignee,
    Filter.CurrentAssigneeName,
  ]),
  attorney: currentOrDisposition(state.attorney, action, [
    Filter.AttorneyAtDisposition,
    Filter.CurrentAttorney,
  ]),
  available: available(state.available, action, state.active),
  firm: currentOrDisposition(state.firm, action, [
    Filter.CurrentFirm,
    Filter.CurrentFirmName,
    Filter.FirmAtDisposition,
    Filter.FirmAtDispositionName,
  ]),
  open: open(state.open, action, state.selected),
  selected: selected(state.selected, action),
})

const makeFilterReducer =
  (reportType: FilterReportType) =>
  (state: SearchSetFilterState = initialSetState, action: PossibleActions) => {
    if (path(['meta', 'report'], action) !== reportType) {
      return state
    }

    return searchSetReducer(state, action)
  }

export { makeFilterReducer, searchSetReducer }
