import { combineReducers } from 'redux'

import { HttpContent, HttpStatus } from '../../../../http/types'
import { Actions, UserInfo, UsersState } from '../types'

export const results = (
  state: HttpContent<UserInfo[]> = { type: HttpStatus.NotAsked },
  action: Actions
) => {
  switch (action.type) {
    case 'accounts/users/SEARCH':
      return { type: HttpStatus.Fetching }
    case 'accounts/users/SET':
      return { data: action.payload!, type: HttpStatus.Success }
    case 'accounts/users/ERROR':
      return { message: action.payload!, type: HttpStatus.Error }
    default:
      return state
  }
}

export const details = (
  state: UsersState['details'] = { type: HttpStatus.NotAsked },
  action: Actions
) => {
  switch (action.type) {
    case 'accounts/users/SET_DETAILS':
      return action.payload!
    case 'accounts/users/SET_DISABLED':
      return state.type === HttpStatus.Success
        ? {
            data: {
              ...state.data,
              disabled: action.payload!,
            },
            type: HttpStatus.Success,
          }
        : state
    default:
      return state
  }
}

const reducer = combineReducers({
  details,
  results,
})

export default reducer
