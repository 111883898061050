import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const CheckmarkWithCircle = (props: IconProps) => (
  <SVG title="checkmark-with-circle" viewBox="0 0 12 12" {...props}>
    <path d="M2.79175 5.49476L3.75505 4.53161C3.8512 4.43546 4.0078 4.43546 4.10395 4.53161L5.40715 5.83481C5.5033 5.93096 5.6599 5.93096 5.75605 5.83481L9.41605 2.17496C9.5122 2.07896 9.66895 2.07896 9.7648 2.17496L10.7278 3.13826C10.8241 3.23441 10.824 3.39101 10.7278 3.48716L6.89365 7.32131L5.75605 8.45906C5.6599 8.55521 5.5033 8.55521 5.40715 8.45906L4.2694 7.32176L2.79175 5.84351C2.6956 5.74751 2.6956 5.59091 2.79175 5.49476ZM5.8438 1.35596C6.82075 1.35596 7.7272 1.65791 8.47525 2.17316C8.5156 2.20091 8.53915 2.24111 8.5435 2.29001C8.548 2.33876 8.5321 2.38256 8.49745 2.41721L8.03755 2.87696C7.984 2.93066 7.9024 2.93951 7.8385 2.89826C7.26355 2.52776 6.5788 2.31266 5.8438 2.31266C4.82545 2.31266 3.90355 2.72546 3.2365 3.39251C2.5693 4.05971 2.1565 4.98161 2.1565 5.99981C2.1565 7.01801 2.5693 7.93991 3.2365 8.60711C3.90355 9.27431 4.82545 9.68696 5.8438 9.68696C6.862 9.68696 7.7839 9.27431 8.45095 8.60711C9.11815 7.93991 9.53095 7.01801 9.53095 5.99981C9.53095 5.82536 9.51865 5.65376 9.49525 5.48576C9.4882 5.43446 9.50395 5.38751 9.5404 5.35091L10.0845 4.80686C10.1263 4.76516 10.183 4.75031 10.24 4.76606C10.2969 4.78181 10.3378 4.82396 10.3519 4.88141C10.4406 5.23961 10.4875 5.61416 10.4875 5.99981C10.4875 8.56451 8.4085 10.6437 5.8438 10.6437C3.27895 10.6437 1.19995 8.56451 1.19995 5.99981C1.19995 3.43511 3.27895 1.35596 5.8438 1.35596Z" />
  </SVG>
)

export default CheckmarkWithCircle
