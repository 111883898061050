import { combineReducers } from 'redux'

import { Actions, Feature, PermissionsState } from './types'

const focusFeature = (state: Feature = Feature.Other, action: Actions) => {
  switch (action.type) {
    case 'session/featureModal/SET':
      return action.payload!.focusFeature ?? state
    default:
      return state
  }
}

const open = (state = false, action: Actions) => {
  switch (action.type) {
    case 'session/featureModal/SET':
      return action.payload!.open
    default:
      return state
  }
}

const featureModal = combineReducers({
  focusFeature,
  open,
})

const groupId = (state = '', action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return ''
    case 'session/SET':
      return action.payload!.groupId
    default:
      return state
  }
}

const initialPermissions: PermissionsState = {
  accountTools: false,
  alpha: false,
  analystMetrics: false,
  api: false,
  assignmentSheet: false,
  bi: false,
  charts: false,
  confidentialData: false,
  customerPpairSettings: false,
  clientConfig: false,
  clientUsageReport: false,
  drafting: false,
  editOarTimes: false,
  examiner: false,
  expertSearch: false,
  expertSearchExport: false,
  exportAnalytics: false,
  freeHealthDashboard: false,
  groupAdmin: false,
  groupOwner: false,
  humanTasks: false,
  ids: false,
  idsAssignmentSheet: false,
  idsViewer: false,
  oarBuild: false,
  oarBuildQuality: false,
  oarReview: false,
  oarReviewQuality: false,
  oarSender: false,
  oarTech: false,
  oarViewer: false,
  oneOTwoReports: false,
  personal: false,
  platformExaminerAuTcFilters: false,
  platformFirmAssigneeFilters: false,
  platformOaRceCountFilters: false,
  platformPatentFamilyComponent: false,
  platformPremiumSorts: false,
  platformRegCustNumFilters: false,
  platformSavedSearchAndHistory: false,
  platformSearchScopes: false,
  platformThreePaneView: false,
  ppair: false,
  premiumWork: false,
  rejectionBasisFilter: false,
  rejections: false,
  selfAnalystMetrics: false,
  smartshell: false,
  staffSettings: false,
  table: false,
}

const permissions = (state: PermissionsState = initialPermissions, action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return initialPermissions
    case 'session/SET':
      return action.payload!.permissions
    default:
      return state
  }
}

const username = (state = '', action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return ''
    case 'session/SET':
      return action.payload!.username
    default:
      return state
  }
}

const uuid = (state = '', action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return ''
    case 'session/SET':
      return action.payload!.uuid
    default:
      return state
  }
}

const reducer = combineReducers({
  featureModal,
  groupId,
  permissions,
  username,
  uuid,
})

export default reducer
