import { OrderDirection } from '@juristat/common/types'
import { useCallback, useState } from 'react'

export type SortState<T> = {
  by: T | null
  direction: OrderDirection
}

export type ToggleSort<T> = (key: T) => void

const oppositeDirection = (direction: OrderDirection) =>
  direction === OrderDirection.Ascending ? OrderDirection.Descending : OrderDirection.Ascending

const getNewSortDirection = (sameColumn: boolean, currentDirection: OrderDirection) =>
  sameColumn ? oppositeDirection(currentDirection) : OrderDirection.Descending

export function useSortState<T>(
  defaultSortState: SortState<T> = { by: null, direction: OrderDirection.Descending }
) {
  const [sortState, setSortState] = useState(defaultSortState)

  const toggleSort = useCallback((key: T) => {
    setSortState((state) => {
      const newDirection = getNewSortDirection(state.by === key, state.direction)

      return {
        by: key,
        direction: newDirection,
      }
    })
  }, [])

  return {
    sortState,
    toggleSort,
  }
}
