import { Column } from '../modules/table/types'
import { OrderDirection, SortOrderVariables } from '../types'

const getSortOrders = (phrase: string, orders: SortOrderVariables[]) => {
  // Always use set orders
  if (orders.length > 0) {
    return orders
  }

  // Use publication date filter when no search term
  if (phrase.length === 0) {
    return [
      {
        orderingDirection: OrderDirection.Descending,
        orderingType: Column.PublicationDate,
      },
    ]
  }

  return orders
}

export default getSortOrders
