import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Search = (props: IconProps) => (
  <SVG title="search" viewBox="0 0 20 20" {...props}>
    <path d="M8.5731564 14.356588c3.3205945 0 6.0084883-2.6422534 6.0084883-5.9015863 0-3.2605184-2.6890937-5.9072128-6.0084883-5.9072128-3.3192766 0-6.0079256 2.6465777-6.0079256 5.9072128 0 3.2594495 2.6874493 5.9015863 6.0079256 5.9015863zm6.1585781-.9312208l4.3778615 4.3094822c.3875059.3827211.3875059 1.007204.0008243 1.3795105-.3831303.3793369-1.0024933.3793369-1.3857038.0008565l-4.4180337-4.3477033c-1.3588296.991043-3.0039453 1.5368639-4.7335264 1.5368639C4.1715077 16.304377.6 12.7907876.6 8.4550017.6 4.1145069 4.1705778.6 8.5731564.6c4.4025787 0 7.9731565 3.5145069 7.9731565 7.8550017 0 1.8393844-.6505898 3.5768206-1.8145784 4.9703655z" />
  </SVG>
)

export default Search
