import React from 'react'

import { Check, Minus } from '../modules/icons'

type CheckboxState = 'checked' | 'partial' | 'unchecked'

type CheckboxProps = {
  accent?: 'green' | 'blue'
  checked: boolean
  className?: string
  classNameChecked?: string
  disabled?: boolean
  handleClick?: () => void
  label?: string
  renderLabel?: (label: string) => JSX.Element | null
  state?: CheckboxState
  size?: number
  solid?: boolean
}

type StyleProps<Keys extends keyof CheckboxProps> = { [K in Keys]: NonNullable<CheckboxProps[K]> }

const getTailWindClasses = ({
  accent,
  checked,
  disabled,
  solid,
}: StyleProps<'accent' | 'checked' | 'disabled' | 'solid'>) => {
  let checkedIconClasses = solid
    ? 'fill-white stroke-white'
    : accent === 'green'
    ? 'fill-teal-500 stroke-teal-500'
    : 'fill-sky-500 stroke-sky-500'
  const adjacentClasses = solid
    ? 'peer-checked:border-white'
    : accent === 'green'
    ? 'peer-checked:border-teal-500'
    : 'peer-checked:border-sky-500'
  const bgClasses = solid ? (accent === 'green' ? 'bg-teal-500' : 'bg-sky-500') : 'bg-inherit'
  checkedIconClasses += ` ${bgClasses}`
  const disabledClasses = disabled ? 'hover:cursor-not-allowed opacity-60' : 'opacity-100'
  const hoverClasses = disabled
    ? ''
    : accent === 'green'
    ? '[&:hover_>_div]:border-teal-500'
    : '[&:hover_>_div]:border-sky-500'
  return {
    checkbox: `peer opacity-0 w-0 h-0 absolute -z-1 transition-background-color duration-300 ease-out transition-border-color duration-300 ease-out`,
    container: `${disabledClasses} ${hoverClasses} flex font-opensans items-center cursor-pointer text-zinc-700 text-xs`,
    path: `${
      checked ? checkedIconClasses : ''
    } transition-fill duration-300 ease-out transition-stroke duration-300 ease-out`,
    partial: `left-0 top-0`,
    svg: `absolute left-[5px] top-[5px] transition-fill transition-stroke`,
    wrapper: `border border-zinc-400 ${adjacentClasses} ${checked ? checkedIconClasses : ''}
     rounded-sm w-5 h-5 flex items-center justify-center mr-2 relative shrink-0 transition-border-color duration-300 ease-out`,
  }
}

const Checkbox = ({
  accent = 'green',
  checked,
  className,
  classNameChecked,
  disabled = false,
  handleClick,
  label,
  renderLabel = (text) => <div>{text}</div>,
  state: incomingState,
  solid = false,
}: CheckboxProps) => {
  const state = incomingState ?? (checked ? 'checked' : 'unchecked')
  const tw = getTailWindClasses({ accent, checked, disabled, solid })
  return (
    <label
      className={`${tw.container} ${className ? className : ''} ${checked ? classNameChecked : ''}`}
      onClick={(event) => {
        if (disabled) {
          return
        }

        event.preventDefault()

        handleClick?.()
      }}
      title={label}
    >
      <input
        aria-checked={state !== 'unchecked'}
        checked={state !== 'unchecked'}
        className={tw.checkbox}
        readOnly
        type="checkbox"
      />
      <div className={tw.wrapper}>
        {state === 'checked' && <Check className={tw.svg} height={8} title="" width={8} />}
        {state === 'partial' && (
          <Minus className={`${tw.svg} ${tw.partial}`} height={19} title="" width={18} />
        )}
      </div>
      {label && renderLabel(label)}
    </label>
  )
}

export default Checkbox
