import Downshift from 'downshift'
import { contains, isEmpty, symmetricDifference, without } from 'ramda'
import React, { useEffect, useState } from 'react'

import Checkbox from '../Checkbox'
import BaseSelect, { SelectProps } from './BaseSelect'

type MultiSelectProps<T> = SelectProps<T> & {
  handleSelectionChange: (selection: T[]) => void
  values: T[]
}

const MultiSelect = <T extends string | number>({
  children,
  title,
  values,
  ...props
}: MultiSelectProps<T>) => {
  const label = props.options
    .filter((option) => contains(option.value, values))
    .map((option) => option.label)
    .join(', ')

  const [items, setItems] = useState(values)

  useEffect(() => {
    setItems(values)
  }, [values])

  return (
    <BaseSelect
      {...props}
      label={label}
      onSelect={(item) => {
        if (item) {
          setItems((state) =>
            contains(item.value, state) ? without([item.value], state) : [...state, item.value]
          )
        }
      }}
      stateReducer={(state, changes) => {
        if (changes.type === Downshift.stateChangeTypes.clickButton && !state.isOpen) {
          return changes
        }

        switch (changes.type) {
          case Downshift.stateChangeTypes.blurButton:
          case Downshift.stateChangeTypes.clickButton:
          case Downshift.stateChangeTypes.mouseUp: {
            if (isEmpty(items)) {
              setItems(values)
            } else if (!isEmpty(symmetricDifference(items, values))) {
              props.handleSelectionChange(items)
            }

            return changes
          }
          case Downshift.stateChangeTypes.clickItem:
          case Downshift.stateChangeTypes.keyDownEnter:
            return {
              ...changes,
              highlightedIndex: state.highlightedIndex,
              isOpen: true,
            }
          default:
            return changes
        }
      }}
      title={title ?? label}
    >
      {({ className, getItemProps, index, ...item }) =>
        children?.({ className, getItemProps, index, ...item }) ?? (
          <li
            {...getItemProps({
              className,
              index,
              item,
              key: item.value,
            })}
          >
            <Checkbox accent="blue" checked={contains(item.value, items)} label={item.label} />
          </li>
        )
      }
    </BaseSelect>
  )
}

export default MultiSelect
