import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../../../redux'
import actions from '../../actions'
import FilterContext from '../../context/filterContext'
import { getStatCountFilterValues } from '../../selectors/getStatCountFilter'
import { StatCountFilter } from '../../types'
import MultiFilterContainer from '../MultiFilterContainer'
import StatCountFilterComponent from './StatCountFilterComponent'

type StatCountFilterInfo<T> = {
  filter: T
  title?: string
}

export type StatCountFilterContainerProps<T> = {
  filters: Array<StatCountFilterInfo<T>>
  title: string
}

const StatCountFilterContainer = <T extends StatCountFilter>({
  filters,
  title,
}: StatCountFilterContainerProps<T>) => {
  const justFilters = filters.map(({ filter }) => filter)
  const { meta } = useContext(FilterContext)
  const filterValues = useSelector((state: AppState) =>
    getStatCountFilterValues(state, { filters: justFilters, ...meta })
  )
  const dispatch = useDispatch()

  return (
    <MultiFilterContainer filters={justFilters} title={title}>
      {filters.map(({ filter, title: filterTitle }) => (
        <StatCountFilterComponent
          filter={filter}
          key={filter}
          selectionAndValue={filterValues[filter]}
          set={(payload) => dispatch(actions.selectSome(payload, meta))}
          title={filterTitle}
        />
      ))}
    </MultiFilterContainer>
  )
}

export default StatCountFilterContainer
