import { combineReducers } from 'redux'

import { HttpStatus } from '../../http/types'
import { HomeState, ReducerActions } from '../types'

const portfolios = (
  state: HomeState['portfolios'] = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'home/SET_MY_PORTFOLIOS':
      return action.payload
    default:
      return state
  }
}

const news = (state: HomeState['news'] = { type: HttpStatus.NotAsked }, action: ReducerActions) => {
  switch (action.type) {
    case 'home/news/ERROR':
      return { message: action.payload!, type: HttpStatus.Error }
    case 'home/news/FETCH':
      return { type: HttpStatus.Fetching }
    case 'home/news/SET':
      return { data: action.payload!, type: HttpStatus.Success }
    default:
      return state
  }
}

const topAssignees = (
  state: HomeState['topAssignees'] = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'home/SET_TOP_ASSIGNEES':
      return action.payload
    default:
      return state
  }
}

const topFirms = (
  state: HomeState['topFirms'] = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'home/SET_TOP_FIRMS':
      return action.payload
    default:
      return state
  }
}

const reducer = combineReducers({
  news,
  portfolios,
  topAssignees,
  topFirms,
})

export default reducer
