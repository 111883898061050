import config from '@juristat/config'
import { stringify } from 'qs'
import { call, put, takeEvery } from 'redux-saga/effects'

import { api } from '../../../../api'
import actions from '../actions'
import { GroupsSearchResult, SearchGetAction, TypeaheadGetAction } from '../types'

enum QueryType {
  Search = 'search',
  Typeahead = 'typeahead',
}

function* fetchGroups({ query, type }: { query: string; type: QueryType }) {
  yield put(actions[type].fetch())

  const url = `${config.accountsUrl}/api/search/group?${stringify({ q: query })}`
  const options = {
    credentials: 'include',
    url,
  }
  const errorAction = actions[type].error('Failed to search groups.')

  try {
    const response: ApiResponse<GroupsSearchResult[]> = yield call(api, url, options)

    if (response.ok) {
      const result: GroupsSearchResult[] = yield call([response, 'json'])

      yield put(actions[type].set(result))
    } else {
      yield put(errorAction)
    }
  } catch {
    yield put(errorAction)
  }
}

function* searchGroups(action: SearchGetAction) {
  yield call(fetchGroups, { query: action.payload!, type: QueryType.Search })
}

function* typeaheadSearch(action: TypeaheadGetAction) {
  yield call(fetchGroups, { query: action.payload!, type: QueryType.Typeahead })
}

export { QueryType, fetchGroups, searchGroups, typeaheadSearch }
export default function* watchSearchGroups() {
  yield takeEvery([actions.search.get().type], searchGroups)
  yield takeEvery([actions.typeahead.get().type], typeaheadSearch)
}
