import { flatten, max, min, pipe, pluck, reduce } from 'ramda'
import React from 'react'

import { Charts, WordsPerClaim as Data, Datum, TransformLine, XY } from '../types'
import { ChartProps } from '../types'
import LineChart from './LineChart'
import LineChartSkeletonLoader from './LineChartSkeletonLoader'
import PlatformChartContainer from './PlatformChartContainer'

type WordsPerClaimProps = ChartProps & {
  chart: Charts.WordsPerDependentClaimOverTime | Charts.WordsPerIndependentClaimOverTime
  title: string
}

const getY = (reducer: typeof min | typeof max, start: number) =>
  pipe(pluck('data'), flatten, pluck('y') as any, reduce<number, number>(reducer, start))

const getMaxY = (data: Array<Datum<XY>>): number => getY(max, -Infinity)(data)
const getMinY = (data: Array<Datum<XY>>): number => getY(min, Infinity)(data)

const transform: TransformLine<Data> = (datum) =>
  datum.map(({ data, ...item }) => ({
    ...item,
    data: data
      .sort((current, next) => current.year - next.year)
      .map(({ words, year }) => ({
        x: year,
        y: words ?? 0,
      })),
  }))

const WordsPerClaim: React.FC<WordsPerClaimProps> = (props) => (
  <PlatformChartContainer
    exportableConfig={{
      filename: props.title.toLowerCase().replace(/ /g, '_'),
      getData: (data) => {
        const years = data[0].data.map(({ year }) => year)

        return data.map((item) => {
          const datapoints = years.map((year) =>
            item.data.find(({ year: datumYear }) => year === datumYear)
          )

          return [
            item.id,
            ...datapoints.map((datapoint) => (datapoint ? datapoint.words ?? 0 : '')),
          ]
        })
      },
      getHeader: (data) => data[0].data.map(({ year }) => year),
    }}
    skeleton={LineChartSkeletonLoader}
    {...props}
  >
    {({ data: raw, ...dimensions }) => {
      const data = transform(raw)

      return (
        <LineChart
          {...dimensions}
          axisBottom={{
            legend: 'Disposition Year',
          }}
          axisLeft={{
            legend: 'Words per Claim',
          }}
          data={data}
          tooltipFormat="1.2f"
          yScale={{
            max: getMaxY(data),
            min: getMinY(data),
            type: 'linear',
          }}
        />
      )
    }}
  </PlatformChartContainer>
)

export default WordsPerClaim
