import config from '@juristat/config'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api'
import actions from '../actions'
import { GetAction, GroupResponse, Token, TokenResponse } from '../types'

type Responses = [ApiResponse<GroupResponse>, ApiResponse<TokenResponse[]>]

const parseDomains = (domains: string): string[] => {
  try {
    return JSON.parse(domains) || []
  } catch {
    return []
  }
}

const transformTokensResult = ({
  created_by: createdBy,
  domains,
  group_id: groupId,
  valid_after: validAfter,
  valid_before: validBefore,
  ...token
}: TokenResponse): Token => ({
  ...token,
  createdBy,
  domains: parseDomains(domains),
  groupId,
  validAfter,
  validBefore,
})

export function* getGroupDetails(action: GetAction) {
  // TODO: Check for existing data
  yield put(actions.fetch())

  const url = `${config.accountsUrl}/group/${action.payload}`
  const options = {
    credentials: 'include',
    url,
  }
  const errorAction = actions.error('Failed to fetch group details.')

  try {
    const responses: Responses = yield all([
      call(api, url, options),
      call(api, `${url}/tokens`, options),
    ])

    if (responses.every((response) => response.ok)) {
      const [groupResponse, tokensResponse] = responses

      const { group_id: uuid, name }: GroupResponse = yield call([groupResponse, 'json'])
      const tokensResult: TokenResponse[] = yield call([tokensResponse, 'json'])

      const tokens = tokensResult.map(transformTokensResult)

      yield put(actions.set({ name, tokens, uuid }))
    } else {
      yield put(errorAction)
    }
  } catch {
    yield put(errorAction)
  }
}

function* watchGetGroupDetails() {
  yield takeLatest([actions.get().type], getGroupDetails)
}

export default watchGetGroupDetails
