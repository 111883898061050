import * as React from 'react'

import SVG from '../SVG'
import { IconProps } from '../types'

const LockSolid = (props: IconProps) => (
  <SVG title="lock-solid" {...props}>
    <path d="M26,15V10.72a8.2,8.2,0,0,0-8-8.36,8.2,8.2,0,0,0-8,8.36V15H7V32a2,2,0,0,0,2,2H27a2,2,0,0,0,2-2V15ZM19,25.23V28H17V25.14a2.4,2.4,0,1,1,2,.09ZM24,15H12V10.72a6.2,6.2,0,0,1,6-6.36,6.2,6.2,0,0,1,6,6.36Z" />
  </SVG>
)

export default LockSolid
