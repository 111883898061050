import * as React from 'react'

import { noop } from '../../utils'
import ActionButton, { ActionButtonProps } from '../ActionButton'
import ModalHeader from './ModalHeader'

export enum ModalSize {
  Short = 'short',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Long = 'long',
  Thin = 'thin',
  Wide = 'wide',
}

type ModalActionButtonProps = Omit<ActionButtonProps, 'action'> & {
  action?: (closeModal: () => void) => void
}

export type ModalProps = {
  children: React.ReactNode
  className?: string
  classNameHeaderContainer?: string
  classNameBodyContainer?: string
  closeModal?: () => void
  primaryButtonProps?: ModalActionButtonProps
  renderFooterLeft?: () => JSX.Element
  renderFooterRight?: () => JSX.Element
  size?: ModalSize
  secondaryButtonProps?: ModalActionButtonProps
  tertiaryButtonProps?: ModalActionButtonProps
  title: string
  twBodyContainer?: string
}

const getDimensionsForSize = (size: ModalSize) => {
  switch (size) {
    case ModalSize.Short:
      return 'h-full w-[480px]'
    case ModalSize.Small:
      return 'h-[275px] w-[480px]'
    case ModalSize.Large:
      return 'h-[442px] w-[580px]'
    case ModalSize.Long:
      return 'h-full min-h-[342px] w-[580px]'
    case ModalSize.Thin:
      return 'h-full w-[340px]'
    case ModalSize.Wide:
      return 'h-full w-[680px]'
    case ModalSize.Medium:
    default:
      return 'h-[342px] w-[480px]'
  }
}

const styles = {
  leftButtonContainer: 'mt-0 mr-0 mb-5 ml-5',
  modalBody: 'flex flex-col grow p-5 relative',
  modalContent: (size: ModalSize) =>
    `bg-white rounded flex flex-col relative ${getDimensionsForSize(size)}`,
  modalFooter: 'flex items-center flex shrink-0 justify-between',
  rightButtonContainer: 'flex mt-0 mr-5 mb-5 ml-0 [&_>_button:first-of-type]:mr-[10px]',
}

const createModalAction = (closeModal: () => void, props: ModalActionButtonProps) =>
  props.action ? () => props.action!(closeModal) : noop

class Modal extends React.Component<ModalProps> {
  closeOnEscape = (event: KeyboardEvent) => {
    const { closeModal } = this.props
    if (event.key === 'Escape' && typeof closeModal === 'function') {
      closeModal()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeOnEscape, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeOnEscape, false)
  }

  render() {
    const {
      children,
      className,
      classNameHeaderContainer,
      classNameBodyContainer,
      closeModal,
      renderFooterLeft,
      renderFooterRight,
      size = ModalSize.Medium,
      title,
      primaryButtonProps,
      secondaryButtonProps,
      tertiaryButtonProps,
      twBodyContainer,
    } = this.props

    return (
      <div className={`${styles.modalContent(size)} ${className ? className : ''}`}>
        <ModalHeader {...{ closeModal, title, classNameHeaderContainer }} />
        <div
          className={`${styles.modalBody} ${classNameBodyContainer ?? ''} ${twBodyContainer ?? ''}`}
        >
          {children}
        </div>
        <div className={styles.modalFooter}>
          <div className={styles.leftButtonContainer}>
            {tertiaryButtonProps && (
              <ActionButton
                tertiary
                {...tertiaryButtonProps}
                action={createModalAction(closeModal ?? noop, tertiaryButtonProps)}
              />
            )}
            {renderFooterLeft?.()}
          </div>
          <div className={styles.rightButtonContainer}>
            {secondaryButtonProps && (
              <ActionButton
                secondary
                {...secondaryButtonProps}
                action={createModalAction(closeModal ?? noop, secondaryButtonProps)}
              />
            )}
            {primaryButtonProps && (
              <ActionButton
                primary
                {...primaryButtonProps}
                action={createModalAction(closeModal ?? noop, primaryButtonProps)}
              />
            )}
            {renderFooterRight?.()}
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
