import { useContext } from 'react'

import { DialogueActionsContext, DialogueCreateContext, DialogueStateContext } from './contexts'

export function useDialogueActions() {
  const context = useContext(DialogueActionsContext)

  if (context === undefined) {
    throw new Error('useDialogueActions must be used within a DialogueProvider')
  }

  return context
}

export function useDialogueCreate() {
  const context = useContext(DialogueCreateContext)

  if (context === undefined) {
    throw new Error('useDialogueCreate must be used within a DialogueProvider')
  }

  return context
}

export function useDialogueState() {
  const context = useContext(DialogueStateContext)

  if (context === undefined) {
    throw new Error('useDialogueState must be used within a DialogueProvider')
  }

  return context
}

export function useDialogue() {
  return [useDialogueState(), useDialogueCreate(), useDialogueActions()] as const
}
