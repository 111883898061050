import { useLayoutEffect } from 'react'

import useResizeObserver from './useResizeObserver'

const useEllipsis = <T extends Element>(text: string, { ellipsis = '…', lines = 2 } = {}) => {
  const {
    ref,
    dimensions: { height, width },
  } = useResizeObserver<T>()

  useLayoutEffect(() => {
    const element = ref.current

    if (!element) {
      return
    }

    const lineHeight = Number(
      window.getComputedStyle(element).getPropertyValue('line-height').replace('px', '')
    )
    const maxHeight = lineHeight * lines + 1

    let start = 0
    let middle = 0
    let end = text.length

    const moveMarkers = () => {
      if (element.clientHeight <= maxHeight) {
        start = middle + 1
      } else {
        end = middle - 1
      }
    }

    while (start <= end) {
      middle = Math.floor((start + end) / 2)

      element.textContent = text.slice(0, middle)

      if (middle === text.length) {
        return
      }

      moveMarkers()
    }

    const maybeEllipsis = middle === text.length ? '' : ellipsis

    element.textContent = text.slice(0, middle - 5) + maybeEllipsis
  }, [height, text, width])

  return ref
}

export default useEllipsis
