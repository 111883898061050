import { SymbolProps } from '@nivo/legends/dist/types/svg/symbols/types'
import { css } from 'emotion'
import React from 'react'

import { colors } from '../../../styles'
import isIeOrEdge from '../../../utils/isIeOrEdge'

type LegendSymbolShapeProps = SymbolProps & {
  hidden: boolean
}

const styles = {
  hidden: css({
    '&, & + text': {
      fill: `${colors.placeholder} !important`,
    },
  }),
}

const LegendSymbolShape = ({
  borderColor = 'transparent',
  borderWidth = 0,
  hidden,
  fill,
  size,
  x,
  y,
}: LegendSymbolShapeProps) => (
  <circle
    className={hidden ? styles.hidden : undefined}
    r={size / 2}
    cx={x + size / 2}
    cy={y + size / 2}
    fill={fill}
    strokeWidth={borderWidth}
    stroke={borderColor}
    style={{
      pointerEvents: 'none',
    }}
    transform={isIeOrEdge() ? 'translate(0 -4)' : undefined}
  />
)

// export { LegendSymbolShapeProps }
export default LegendSymbolShape
