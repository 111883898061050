import { ActiveFilterPayload } from '../types'

const compareActiveFilterPayloadValues = (
  first: ActiveFilterPayload['value'],
  second: ActiveFilterPayload['value']
) =>
  typeof first === 'string' && second === 'string'
    ? first === second
    : JSON.stringify(first) === JSON.stringify(second)

export default compareActiveFilterPayloadValues
