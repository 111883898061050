import { IntelligenceEntityType } from '../types'

type GetEntityLabelOptions = Partial<{
  simple: boolean
  titleCase: boolean
}>

const getEntityLabel = (entity: IntelligenceEntityType, options: GetEntityLabelOptions = {}) => {
  const { simple = false, titleCase = true } = options

  switch (entity) {
    case IntelligenceEntityType.Uspto:
      return 'USPTO'
    case IntelligenceEntityType.SearchSet:
      return 'search'
    case IntelligenceEntityType.ArtUnit:
      return titleCase ? 'Art Unit' : 'art unit'
    case IntelligenceEntityType.Cpc:
      return 'CPC'
    case IntelligenceEntityType.TechCenter:
      return titleCase ? 'Tech Center' : 'tech center'
    case IntelligenceEntityType.Uspc:
      return 'USPC'
    case IntelligenceEntityType.AssigneeAtDisposition:
      if (simple) {
        return titleCase ? 'Assignee' : 'assignee'
      }
      return titleCase ? 'Assignee at Disposition' : 'assignee at disposition'
    case IntelligenceEntityType.AttorneyAtDisposition:
      if (simple) {
        return titleCase ? 'Attorney' : 'attorney'
      }
      return titleCase ? 'Attorney at Disposition' : 'attorney at disposition'
    case IntelligenceEntityType.FirmAtDisposition:
      if (simple) {
        return titleCase ? 'Firm' : 'firm'
      }
      return titleCase ? 'Firm at Disposition' : 'firm at disposition'
    case IntelligenceEntityType.CurrentAssignee:
      if (simple) {
        return titleCase ? 'Assignee' : 'assignee'
      }
      return titleCase ? 'Current Assignee' : 'current assignee'
    case IntelligenceEntityType.CurrentAttorney:
      if (simple) {
        return titleCase ? 'Attorney' : 'attorney'
      }
      return titleCase ? 'Current Attorney' : 'current attorney'
    case IntelligenceEntityType.CurrentFirm:
      if (simple) {
        return titleCase ? 'Firm' : 'firm'
      }
      return titleCase ? 'Current Firm' : 'current firm'
    default:
      return 'unknown label'
  }
}

export default getEntityLabel
