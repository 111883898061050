import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import { BoundAction } from '../../../redux'
import actions from '../actions'
import FilterContext from '../context/filterContext'
import { RemoveListAction, SelectListAction } from '../types'
import FilterContainer, { FilterContainerProps } from './FilterContainer'
import TagFilterInput, { TagFilterInputProps } from './TagFilterInput'

export type TagFilterContainerProps = Omit<FilterContainerProps, 'render' | 'filter'> &
  TagFilterInputProps

const TagFilterContainer = (props: TagFilterContainerProps) => {
  const { meta } = useContext(FilterContext)
  const dispatch = useDispatch()
  const removeList: BoundAction<RemoveListAction> = (payload) =>
    dispatch(actions.removeList(payload, meta))
  const selectList: BoundAction<SelectListAction> = (payload) =>
    dispatch(actions.selectList(payload, meta))

  return (
    <FilterContainer
      {...props}
      skipHttpStatus
      render={(containerProps) => (
        <TagFilterInput
          {...props}
          {...containerProps}
          {...{ removeList, selectList }}
          selected={containerProps.selected as string[]}
        />
      )}
    />
  )
}

export default TagFilterContainer
