import React from 'react'

import { useTitle } from '../hooks'
import { twTextStyles } from '../styles'
import Header from './Header'
import LayoutContainer from './LayoutContainer'
import PageContainer from './PageContainer'

const styles = {
  header: `${twTextStyles.montserratDarkNormal36} h-[44px] mt-[42px] mb-6 mx-0`,
  subheader: twTextStyles.darkNormal24,
}

const PrivacyPolicy = () => {
  const lastUpdated = 'January 30, 2024'

  useTitle('Privacy Policy')

  return (
    <PageContainer>
      <Header />
      <LayoutContainer>
        <h1 className={styles.header}>Privacy Policy</h1>
        <p>Last Updated: {lastUpdated}</p>
        <p>
          This Privacy Policy describes the privacy practices of Juristat, Inc. (“Juristat,” “we,”
          “us,” “our”) with respect to Juristat’s products, software, services, and website
          (collectively, the “Services”), including, but not limited to, our treatment of personally
          identifiable information. This Privacy Policy also applies to Juristat’s marketing and
          advertising practices. This Privacy Policy does not apply to any third-party websites or
          services not owned, operated, or controlled by Juristat.
        </p>
        <p>
          By accessing or using the Services, in any manner, you acknowledge that you accept the
          practices and policies outlined in this Privacy Policy and to the collection and use of
          your information, including, but not limited to, your personally identifiable information,
          as outlined herein, to the extent permitted by applicable law. If you do not agree to this
          Privacy Policy, do not access or use the Services.
        </p>
        <h2 className={styles.subheader}>Accounts, Registration, and Communications</h2>
        <p>
          When you register to use the Services, create an account, or contact Juristat for support
          or other inquiries, Juristat collects information that identifies you. This may include,
          but is not limited to: contact information, such as name, email address, mailing address,
          and phone number; billing information, such as your billing contact information and your
          billing address; profile information, such as a user name or job title; and preferences,
          such as notification and marketing preferences. Juristat may also save your communications
          to help improve our products and customer service.
        </p>
        <h2 className={styles.subheader}>Website, Cookies, and Activity</h2>
        <p>
          Juristat collects information about how you use and interact with the Services. This
          information includes, but is not limited to, information you voluntarily input and store
          in the Services, internet protocol (IP) addresses, browser type, internet service
          provider, URLs of referring/exit pages, URLs you accessed, operating system, date/time
          stamp, information you search for, the features and functions you used, location data,
          language preferences, identifiers associated with your device, and system configuration
          information.
        </p>
        <p>
          Like most websites and applications, our Services use cookies and similar technologies to
          provide, improve, protect, and promote the Services. Cookies are small data files stored
          on your hard drive or in device memory. Such uses include, but are not limited to,
          remembering your login information for your next visit and analyzing how you are
          interacting with the Services. If you click on a link to a third-party website, such third
          party may also transmit cookies to you. This Privacy Policy does not cover the use of
          cookies by any third parties. You can set your browser to reject or limit cookies, but
          this may limit your ability to use the Services.
        </p>
        <h2 className={styles.subheader}>Emails, Advertisements, Blogs, and Social Networking</h2>
        <p>
          We may contact you, by email or other means, to provide, improve, protect, and promote the
          Services, as set forth more specifically below. Emails we send you may include a web
          beacon that tells us whether you have received or opened the email, clicked a link within
          the email, or otherwise interacted with the email helps us make emails more interesting
          and improve our service. You may opt out of receiving emails or other communications at
          any time by selecting the “unsubscribe” or “preferences” option in the emails or by
          contacting Juristat using one of the methods on our contact page, unless such emails or
          other communications pertain to a legitimate business or legal interest between you and
          Juristat.
        </p>
        <p>
          Juristat collects information about its online ads, such as which ads are clicked on and
          on which web page the ads were displayed. Juristat also has social networking pages, such
          as Twitter and Facebook, as well as a blog. We may collect information when you interact
          with these pages when you, for example, “post,” “like,” “share,” “comment,” or “retweet”
          content.
        </p>
        <p>
          In some cases, another user, such as an account administrator or a user attempting to
          share content with you or invite you to use the Services, may provide your information to
          Juristat, including contact information or other information they choose to provide. We
          collect this information under the direction of our users and often have no direct
          relationship with the individuals whose information we receive. If you provide information
          about someone else, you must have the authority to act for them and to consent to the
          collection and use of their information as described in this Privacy Policy. If you
          believe another user has provided your information without your permission, please contact
          Juristat.
        </p>
        <h2 className={styles.subheader}>Use of Your Information</h2>
        <p>
          Juristat may collect and share your information as set forth in this Privacy Policy to
          provide, improve, protect, and promote the Services, as set forth more specifically below.
          For these same purposes, Juristat may combine information collected from you with
          information from public sources and our trusted partners, such as information about your
          business or organization, public governmental records, and social media information.
          Juristat collects and processes information about you only where it has a legal basis for
          doing so under, and to the extent permitted by, applicable law.
        </p>
        <p>
          We use information about you to provide the Services to you, including, but not limited
          to, processing transactions with you, authenticating your account when you log in,
          providing customer support, and operating and maintaining the Services.
        </p>
        <p>
          We use your information to resolve technical issues you encounter, respond to your
          requests for assistance, analyze crash information, repair and improve the Services, and
          communicate with you regarding such support.
        </p>
        <p>
          We use collective learnings about how users use and interact with the Services, as well as
          feedback provided directly to us, to troubleshoot; to identify trends, usage, activity
          patterns, and areas for integration and improvement of the Services; and to research and
          develop new products and services.
        </p>
        <p>
          We use your contact information to send transactional communications via email and within
          the Services, including confirming your purchases, reminding you of subscription
          expirations, responding to your comments, questions and requests, providing customer
          support, and sending you technical notices, updates, security alerts, and administrative
          messages. We also may provide tailored communications based on your activity and
          interactions with us. For example, certain actions you take in the Services may
          automatically trigger a feature or third-party application suggestion within the Services
          that would make that task easier. We also send you communications as you onboard to a
          particular Service to help you become more proficient in using that Service. These
          communications are part of the Services.
        </p>
        <p>
          We use your contact information and information about how you use the Services to send
          promotional communications that may be of interest to you, including information about new
          features or products, promotional offers, survey requests, newsletters, blog posts,
          articles, and other information we think may be of interest to you. These communications
          are aimed at driving engagement and maximizing what you get out of the Services.
        </p>
        <p>
          We use information about you and your use of the Services to verify and secure your
          accounts. We may also use such information to monitor suspicious or fraudulent activity or
          to identify violations of our policies.
        </p>
        <p>
          Where required by law or where we believe it is necessary to protect our legal rights or
          interests, or the interests of others, we may use information about you in connection with
          legal claims, compliance, regulatory, and audit functions, as well as disclosures in
          connection with the acquisition, merger, or sale of a business.
        </p>
        <p>
          We use information about you where you have given us consent to do so for a specific
          purpose not listed above. For example, we may publish testimonials or featured customer
          stories to promote the Services, with your permission.
        </p>
        <h2 className={styles.subheader}>Sharing Your Information</h2>
        <p>
          Juristat will not sell your information to advertisers or other third parties without your
          consent.
        </p>
        <p>
          Juristat may share your information with Juristat officers, employees, and agents to
          provide, improve, protect, and promote the Services, as set forth in this Privacy Policy.
          In the event Juristat merges with or is purchased by another business or otherwise
          undergoes a change of ownership, all collected information may be transferred to the new
          owner.
        </p>
        <p>
          Juristat may also use certain trusted third parties to help provide, improve, protect, and
          promote the Services. These third parties will access your information only to perform
          tasks on our behalf and in compliance with this Privacy Policy.
        </p>
        <p>
          Juristat may share or publish aggregate information that does not specifically identify
          you, such as statistical information about visitors to our website or how customers use
          the Services.
        </p>
        <p>
          Juristat may share your information with companies, organizations, or individuals outside
          of Juristat when we have your consent to do so.
        </p>
        <p>
          Juristat may share information, such as your name, user name, or information regarding
          your use of the Services, to other users from your business or organization to, for
          example, inform other users that your business or organization already has other
          account(s) with Juristat, negotiate agreements with your business or organization, or
          promote the Services to your business or organization. Account administrators for your
          business or organization may also have the ability to access, monitor, and review your
          account, the information provided in your account, and your use of the Services.
        </p>
        <p>
          Juristat may disclose your information to third parties if we determine that such
          disclosure is reasonably necessary to comply with the law, protect any person from death
          or serious bodily injury, prevent fraud or abuse, or protect Juristat’s rights.
        </p>
        <p>
          Notwithstanding anything in this Privacy Policy, Juristat will never share or disclose
          your unpublished, unfiled, or draft patent application data or claims or any of your
          search queries or results with any third parties or use any such data in any of Juristat’s
          marketing, advertising, blog posts, articles, or any other public medium, without your
          express permission, unless required by law or where we believe it is necessary to protect
          our legal rights or interests, or the interests of others.
        </p>
        <h2 className={styles.subheader}>Data Storage, Retention, and Security</h2>
        <p>
          We use data hosting service providers to host the information we collect, and we use
          reasonable safeguards to secure your data. However, no security system is impenetrable,
          and due to the inherent nature of the internet, we cannot guarantee that data, during
          transmission through the Internet or while stored on our systems or otherwise in our care,
          is absolutely safe from intrusion by others. Juristat may retain your information for as
          long as necessary for the purposes described in this Privacy Policy, to the extent
          permitted by applicable law.
        </p>
        <h2 className={styles.subheader}>Third-Party Payment Processors</h2>
        <p>
          Juristat may use third-party payment processors, such as Stripe or Xero, to process
          payments. When making payment through these third-party processors, you provide all
          payment information, such as credit card details, directly to the third-party, and the use
          and collection of such information is governed by the third-party’s privacy policies and
          terms. Juristat does not store credit card details and instead relies upon these
          third-party payment processors.
        </p>
        <h2 className={styles.subheader}>Links to Third-Party Websites</h2>
        <p>
          For your use and convenience, the Services may provide links to third-party websites, not
          owned, operated, or controlled by Juristat. The collection and use of your information by
          such third-parties is governed by their own privacy policies and terms, and not this
          Privacy Policy. This Privacy Policy applies only to Juristat and its Services, and not to
          any information you provide, directly or indirectly, to any third party.
        </p>
        <h2 className={styles.subheader}>Access and Control of Your Information</h2>
        <p>
          To the extent permitted by applicable law, you may have the right to access, update, or
          correct your information; to request a copy of your information; to object to our use of
          your information; to opt out of certain communications; or to request deletion of your
          information. You many exercise any such rights by contacting Juristat using any of the
          method described on our contact page. Your rights may be limited in certain cases where
          applicable law or your business or organization’s contract with Juristat permits
          Juristat’s retention and use of your information. If you have unresolved concerns, you may
          have the right to complain to a data protection authority in the country where you live,
          where you work, or where you believe your rights were infringed.
        </p>
        <h2 className={styles.subheader}>Modifications</h2>
        <p>
          Juristat may revise this Privacy Policy at any time. Modifications will be posted to this
          page. You should check this page regularly. We will also notify active owners of active
          Juristat accounts or any significant changes to this Privacy Policy via email, through the
          user interface in the Services, or via other reasonable means.
        </p>
        <h2 className={styles.subheader}>Contact Information</h2>
        <p>
          If you have any questions about this Privacy Policy, you may contact Juristat using any of
          the methods described on our contact page.
        </p>
      </LayoutContainer>
    </PageContainer>
  )
}

export default PrivacyPolicy
