import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const DocsParsed = (props: IconProps) => (
  <SVG title="docs-parsed" viewBox="0 0 9 9" fill="none" {...props}>
    <path
      d="M4.00355 9H0.777699V0.272727H3.99929C4.88849 0.272727 5.65412 0.447443 6.29617 0.796875C6.94105 1.14347 7.43821 1.64347 7.78764 2.29688C8.13707 2.94744 8.31179 3.72585 8.31179 4.6321C8.31179 5.54119 8.13707 6.32244 7.78764 6.97585C7.44105 7.62926 6.94531 8.13068 6.30043 8.48011C5.65554 8.8267 4.88991 9 4.00355 9ZM2.88707 7.2017H3.92259C4.41122 7.2017 4.82457 7.11932 5.16264 6.95455C5.50355 6.78693 5.76065 6.51562 5.93395 6.14062C6.11009 5.76278 6.19815 5.25994 6.19815 4.6321C6.19815 4.00426 6.11009 3.50426 5.93395 3.1321C5.75781 2.7571 5.49787 2.48722 5.15412 2.32244C4.81321 2.15483 4.39276 2.07102 3.89276 2.07102H2.88707V7.2017Z"
      fill="#3EC198"
    />
  </SVG>
)

export default DocsParsed
