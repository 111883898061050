import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const ViewColumns = (props: IconProps) => (
  <SVG title="view-columns" viewBox="0 0 14 14" {...props}>
    <path d="M2 0h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm-.5 1a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-2zm4 0a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-2z" />
  </SVG>
)

export default ViewColumns
