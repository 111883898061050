import { BarSvgProps } from '@nivo/bar'
import { BoxLegendSvg, LegendProps } from '@nivo/legends'
import React from 'react'

type BarLegendProps = {
  height: number
  legends: NonNullable<BarSvgProps['legends']> extends Array<infer U>
    ? Array<
        U & {
          data: NonNullable<LegendProps['data']>
        }
      >
    : never
  width: number
}

const BarLegend: React.FC<BarLegendProps> = ({ height, legends, width }) => (
  <>
    {legends.map((legend) => (
      <BoxLegendSvg
        key={JSON.stringify(legend.data.map(({ id }) => id))}
        {...legend}
        containerHeight={height}
        containerWidth={width}
      />
    ))}
  </>
)

export default BarLegend
