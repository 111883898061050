import { Column } from '../modules/table/types'
import { ResultColumns } from '../types'

/*
  Transforms to camel case keys to avoid importing Column enum everywhere and offers
  stricter types.
*/
const transformColumns = (columns: ResultColumns) => ({
  appno: columns[Column.ApplicationNumber] as number, // Will always be present
  artUnit: columns[Column.ArtUnit],
  assignee: columns[Column.Assignee],
  assigneeAtDisposition: columns[Column.AssigneeAtDisposition],
  assigneeAtPublishing: columns[Column.AssigneeAtPublishing],
  assigneeId: columns[Column.AssigneeId],
  assistantExaminer: columns[Column.AssistantExaminer],
  attorneyDocketNumber: columns[Column.AttorneyDocketNumber],
  cpcClass: columns[Column.CpcClass],
  dispositionDate: columns[Column.DispositionDate],
  examiner: columns[Column.Examiner],
  examinerId: columns[Column.ExaminerId],
  filingDate: columns[Column.FilingDate],
  finalRejections: columns[Column.FinalRejections],
  firm: columns[Column.Firm],
  firmAtDisposition: columns[Column.FirmAtDisposition],
  firmAtPublishing: columns[Column.FirmAtPublishing],
  firmId: columns[Column.FirmId],
  firstNamedInventor: columns[Column.FirstNamedInventor],
  nonFinalRejections: columns[Column.NonFinalRejections],
  officeActions: columns[Column.OfficeActions],
  pairStatus: columns[Column.PairStatus],
  pairStatusDate: columns[Column.PairStatusDate],
  patentIssueDate: columns[Column.PatentIssueDate],
  patentNumber: columns[Column.PatentNumber],
  primaryExaminer: columns[Column.PrimaryExaminer],
  publicationDate: columns[Column.PublicationDate],
  publicationNumber: columns[Column.PublicationNumber],
  status: columns[Column.Status],
  title: columns[Column.Title],
  uspcSubclass: columns[Column.UspcSubclass],
  uspcClass: columns[Column.UspcClass],
})

/*
  We use ReturnType here to avoid creating a nearly duplicate object of ResultColumns
  just with camel case keys instead of uppercase snake case.
*/
type TransformedColumns = ReturnType<typeof transformColumns>

export { TransformedColumns }
export default transformColumns
