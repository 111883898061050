import { cloneElement } from 'react'
import { createPortal } from 'react-dom'

type WithPortal = {
  portal: HTMLElement
  wrapper?: never
}

type WithoutPortal = {
  portal?: never
  wrapper: (children: JSX.Element) => JSX.Element
}

type MaybePortal = WithPortal | WithoutPortal

type ConditionalWrapperProps = MaybePortal & {
  children: JSX.Element
  condition: boolean
}

const ConditionalWrapper = ({ children, condition, ...props }: ConditionalWrapperProps) =>
  condition
    ? props.portal
      ? createPortal(children, props.portal)
      : cloneElement(props.wrapper(children))
    : children

export default ConditionalWrapper
