import { css } from 'emotion'
import * as React from 'react'

import { colors, textStyles } from '../../../../styles'
import { Arrow } from '../../../icons'
import { MonthAndYear } from './MonthPicker'

type PickerHeaderProps = {
  set: (date: MonthAndYear) => void
  year: number
}

const styles = {
  arrow: {
    base: css({
      cursor: 'pointer',
      fill: colors.dark,
      height: 15,
    }),
    left: css({ transform: 'rotate(270deg)' }),
    right: css({ transform: 'rotate(90deg)' }),
  },
  container: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 13,
    userSelect: 'none',
  }),
  year: css(textStyles.darkBold12, { color: colors.dark }),
}

const PickerHeader = ({ set, year }: PickerHeaderProps) => (
  <div className={styles.container}>
    <Arrow
      className={css(styles.arrow.base, styles.arrow.left)}
      onClick={() => {
        set({ month: 0, year: year - 1 })
      }}
      title="Previous"
    />
    <span className={styles.year}>{year}</span>
    <Arrow
      className={css(styles.arrow.base, styles.arrow.right)}
      onClick={() => {
        set({ month: 0, year: year + 1 })
      }}
      title="Next"
    />
  </div>
)

export default PickerHeader
