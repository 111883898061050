import { identity } from 'ramda'

import { daysToMonths } from '../../../../../utils'
import { EntityKeyMetrics, GraphQLEntityTypeBy } from '../../../types'
import { TopEntityKeyMetricsResponse } from '../types'

type TransformName = (name: string, id: string) => string

const hasObject = (entityType: GraphQLEntityTypeBy) => {
  switch (entityType) {
    case 'ASSIGNEE_AT_DISPOSITION_ID':
    case 'ATTORNEY_AT_DISPOSITION_ID':
    case 'CURRENT_ASSIGNEE_ID':
    case 'CURRENT_ATTORNEY_ID':
    case 'CURRENT_FIRM_ID':
    case 'FIRM_AT_DISPOSITION_ID':
    case 'FIRST_ASSIGNEE_ID':
    case 'FIRST_ATTORNEY_ID':
    case 'FIRST_FIRM_ID':
    case 'TECH_CENTER':
      return true
    default:
      return false
  }
}

const keyMetricsResponseToResults = (
  response: TopEntityKeyMetricsResponse,
  transformName: TransformName = identity
): EntityKeyMetrics[] => {
  const {
    data: {
      applicationSet: { metrics },
    },
  } = response

  return metrics
    .filter(({ buckets }) => buckets[0].value !== 'null' && buckets[0].value !== null)
    .map(({ allowanceRate, applicationCounts, buckets, officeActions, timing }) => {
      const { name, value, object } = buckets[0]
      const id = hasObject(name) ? object?.id ?? null : value

      return {
        allowanceRate,
        avgOas: officeActions.toDisposition.average,
        disposed: applicationCounts.disposed,
        filed: applicationCounts.total,
        id,
        monthsToDisposition: daysToMonths(timing.daysToDisposition.average),
        name: transformName(hasObject(name) ? object?.name ?? value! : value!, String(id)),
        pending: applicationCounts.pending,
      }
    })
}

export default keyMetricsResponseToResults
