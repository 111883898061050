import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const BookWithPencil = (props: IconProps) => (
  <SVG title="book-with-pencil" viewBox="0 0 11 12" {...props}>
    <path d="M9.21211 8.53617V9.12406H2.84211C2.67422 9.12406 2.50634 9.17984 2.38 9.27828L0.88214 10.4398V1.38199H9.21214V4.05627L10.0943 3.06204V1.25632C10.0943 0.836323 9.74425 0.500547 9.33848 0.500547L0.755776 0.5C0.335776 0.5 0 0.835776 0 1.25578V10.6779C0 10.9721 0.154224 11.2237 0.42 11.3637C0.532109 11.4195 0.644224 11.4479 0.755776 11.4479C0.91 11.4479 1.07789 11.3921 1.21789 11.2937L2.84217 10.0058H9.33789C9.75789 10.0058 10.0937 9.6558 10.0937 9.25002L10.0942 7.6963L9.53421 8.3263C9.44999 8.40998 9.3379 8.49406 9.21211 8.53617Z" />
    <path d="M2.57569 3.50975H7.50355C7.75566 3.50975 7.95143 3.31397 7.95143 3.06186C7.95143 2.82397 7.75566 2.61397 7.50355 2.61397H2.57569C2.32358 2.61397 2.1278 2.82397 2.1278 3.06186C2.14202 3.31397 2.3378 3.50975 2.57569 3.50975Z" />
    <path d="M7.95211 5.18974C7.95211 4.95186 7.75633 4.74186 7.50422 4.74186H2.5758C2.32369 4.74186 2.12791 4.95186 2.12791 5.18974C2.12791 5.44186 2.32369 5.63763 2.5758 5.63763H7.50366C7.75577 5.63818 7.95211 5.44186 7.95211 5.18974Z" />
    <path d="M2.14212 7.31832C2.14212 7.57043 2.33789 7.76621 2.59 7.76621H6.84572L6.99995 6.88409H2.57567C2.33778 6.88409 2.14212 7.07987 2.14212 7.31832Z" />
    <path d="M8.45304 5.95219L11.0786 3.02255L12.3298 4.14364L9.70429 7.07328L8.45304 5.95219Z" />
    <path d="M13.2157 2.78189L12.3478 1.99766C12.2499 1.89977 12.082 1.91345 11.9978 2.01188L11.4799 2.59977L12.7257 3.71977L13.2436 3.13188C13.3278 3.03399 13.3141 2.86611 13.2157 2.78189Z" />
    <path d="M7.82569 8.22831L8.88992 7.92043C8.93203 7.90621 8.97413 7.87832 9.00202 7.85043L9.30991 7.51465L8.06413 6.39465L7.75625 6.73043C7.72836 6.77254 7.70046 6.81465 7.70046 6.85621L7.51835 7.94832C7.48991 8.12989 7.6578 8.26988 7.82569 8.22831Z" />
  </SVG>
)

export default BookWithPencil
