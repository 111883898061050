import { css } from 'emotion'
import * as React from 'react'

import { colors } from '../../../styles'

const style = css({
  backgroundColor: colors.paleGray2,
  border: `solid 1.5px ${colors.charcoalGray5}`,
  borderRadius: 5,
  boxShadow: `0 0 10px 2px ${colors.greenyGlow}`,
  height: 10,
  marginRight: 10,
  width: 10,
})

const ActiveFilterIcon = () => <div className={style} data-testid="active-filter-icon" />

export default ActiveFilterIcon
