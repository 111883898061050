export const width1820 = 'only screen and (max-width: 1820px)'
export const width1440 = 'only screen and (max-width: 1440px)'
export const width1280 = 'only screen and (max-width: 1280px)'
export const width960 = 'only screen and (max-width: 960px)'

// 850px
export const small = 'only screen and (min-width: 53.125em)'

// 1200px = 75em, 1260px = 78.75em, 1280px = 80em
export const large = 'only screen and (min-width: 80em)'

// 1440px = 90em
export const xlarge = 'only screen and (min-width: 90em)'
