import { path, startsWith } from 'ramda'
import { Reducer, combineReducers } from 'redux'

import { makeFilterReducer } from '../../../../filter/reducer'
import { FilterReportType } from '../../../../filter/types'
import { HttpStatus } from '../../../../http/types'
import createUidReducer from '../../../reducer/createUidReducer'
import { BreakdownReducerState, KeyMetricsState, ReducerActions } from '../types'
import getFirstUnusedColor from '../utils/getFirstUnusedColor'

const pinned: Reducer<KeyMetricsState['pinned']> = (state = [], action: ReducerActions) => {
  switch (action.type) {
    case 'intelligence/keyMetrics/PIN_RESULT':
      return [
        ...state,
        {
          color: getFirstUnusedColor(state),
          data: action.payload!,
        },
      ]
    case 'intelligence/keyMetrics/UNPIN_RESULT':
      return state.filter(({ data: { id } }) => id !== action.payload!.id)
    default:
      return state
  }
}

const results: Reducer<KeyMetricsState['results']> = (
  state = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'intelligence/keyMetrics/SET_RESULTS':
      return action.payload!
    case 'intelligence/keyMetrics/PIN_RESULT': {
      if (state.type !== HttpStatus.Success) {
        return state
      }
      return {
        data: state.data.filter(({ id }) => id !== action.payload!.id),
        type: HttpStatus.Success,
      }
    }
    case 'intelligence/keyMetrics/UNPIN_RESULT': {
      if (state.type !== HttpStatus.Success) {
        return state
      }
      return {
        data: [action.payload!, ...state.data],
        type: HttpStatus.Success,
      }
    }
    default:
      return state
  }
}

const createKeyMetricsReducer = combineReducers({
  pinned,
  results,
})

const keyMetrics: Reducer<BreakdownReducerState['keyMetrics']> = (
  state = {},
  action: ReducerActions
) => {
  if (startsWith('intelligence/keyMetrics', action.type)) {
    const { keyMetricsBy: keyMetricsEntity } = action.meta!

    return {
      ...state,
      [keyMetricsEntity]: createKeyMetricsReducer(path([keyMetricsEntity], state), action),
    }
  }

  return state
}

const filters = makeFilterReducer(FilterReportType.IntelligenceBreakdown)
const uid = createUidReducer(FilterReportType.IntelligenceBreakdown)

const reducer = combineReducers({ filters, keyMetrics, uid })

export default reducer
