import { css } from 'emotion'
import * as React from 'react'

import { colors, makeTransition } from '../../../styles'

type NavBarItemProps = {
  active?: boolean
  children: (props: { className: string }) => React.ReactElement<any> | null
  className?: string
}

const styles = {
  active: css({
    '&:hover': {
      background: colors.white,
    },
    background: colors.paleGray,
    fill: colors.appleGreen,
  }),
  main: css({
    '&:hover': {
      background: colors.paleGrayAlpha30,
    },
    alignItems: 'center',
    backgroundColor: colors.paleGrayAlpha20,
    borderRadius: 4,
    display: 'flex',
    fill: colors.white,
    height: 36,
    justifyContent: 'center',
    transition: makeTransition('background', 0.2),
    width: 36,
  }),
}

const NavBarItem: React.FC<NavBarItemProps> = ({ active = false, children, className }) =>
  children({ className: css(styles.main, className, active && styles.active) })

export default NavBarItem
