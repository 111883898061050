import { isNilOrEmpty } from '@juristat/common/utils'
import { CSSObject } from 'create-emotion'
import Downshift from 'downshift'
import { css } from 'emotion'
import React from 'react'
import { useDispatch } from 'react-redux'

import InfoText from '../../../components/InfoText'
import SearchTextInput from '../../../components/SearchTextInput'
import { TableFetchingLoader } from '../../../components/StaticTable'
import { colors, scrollbar, textStyles, zIndex } from '../../../styles'
import actions from '../actions'
import { useFilterTypeahead } from '../hooks'
import { TypeaheadFilter as TypeaheadFilterName } from '../types'
import FilterItem from './FilterItem'

type TypeaheadFilterProps = {
  dark?: boolean
  defaultValue?: string
  enabled?: boolean
  filter: TypeaheadFilterName
  title: string
  width?: CSSObject['width']
}

const styles = {
  active: css({
    backgroundColor: colors.cloudyBlueAlpha20,
  }),
  container: ({
    dark,
    width = 'calc(100% - 20px)',
  }: Pick<TypeaheadFilterProps, 'dark' | 'width'>) =>
    css(
      dark && scrollbar.dark,
      dark ? textStyles.paleGray2Normal14 : textStyles.charcoalGrayNormal14,
      {
        background: dark ? colors.charcoalGray4 : colors.white,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderBottomWidth: 0.5,
        borderColor: colors.greenyBlue,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        borderStyle: 'solid',
        borderTopWidth: 0,
        maxHeight: 210,
        overflowY: 'auto',
        position: 'absolute',
        width,
        zIndex: zIndex.background,
      }
    ),
  fetching: (width: number) =>
    css({
      '&:first-of-type': {
        marginTop: 8,
      },
      '&:last-of-type': {
        marginBottom: 8,
      },
      backgroundColor: colors.charcoalGray6,
      margin: '4px 10px',
      width: `${width}%`,
    }),
  info: css({
    '& > div:first-of-type': {
      '& > svg': {
        height: 'auto',
        width: 'auto',
      },
      display: 'flex',
      margin: '0 10px',
    },
    color: 'inherit',
    flexDirection: 'row',
    fontSize: 14,
    marginBottom: 5,
  }),
  input: (dark: boolean) =>
    css({
      '& > input': {
        background: dark ? colors.charcoalGray4 : colors.white,
      },
    }),
  inputOpen: css({
    '& > input': {
      '&:focus': {
        borderBottom: '0 !important',
      },
      borderBottomLeftRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    },
  }),
  item: css({
    cursor: 'pointer',
    padding: '4px 10px',
  }),
  menuClosed: css({
    border: 'none',
  }),
}

const TypeaheadFilter = ({
  enabled,
  dark = true,
  defaultValue,
  filter,
  title,
  width,
}: TypeaheadFilterProps) => {
  const dispatch = useDispatch()
  const { debouncedSearchTerm, disabled, meta, onClear, searchTerm, setSearchTerm, typeahead } =
    useFilterTypeahead(filter)

  return (
    <Downshift
      defaultHighlightedIndex={0}
      itemToString={(item) => (item ? item.name : '')}
      onChange={(value) => {
        if (value) {
          dispatch(actions.selectTypeahead({ filter, value }, meta))
          setSearchTerm(null)
        }
      }}
    >
      {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen, openMenu }) => (
        <div>
          <SearchTextInput
            {...getInputProps({
              className: css(styles.input(dark), isOpen && debouncedSearchTerm && styles.inputOpen),
              dark,
              disabled: enabled !== undefined ? !enabled : disabled,
              fetching: typeahead.matches('loading'),
              id: undefined,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(event.target.value),
              onClear,
              onFocus: () => {
                if (debouncedSearchTerm) {
                  openMenu()
                }
              },
              placeholder: `Search for ${title}...`,
              ref: undefined,
              value: defaultValue ?? searchTerm ?? '',
            })}
          />
          <ul
            {...getMenuProps({
              className: css(
                styles.container({ dark, width }),
                (!isOpen || !debouncedSearchTerm) && styles.menuClosed
              ),
            })}
          >
            {!isOpen ? null : typeahead.matches('success') ? (
              <>
                {isNilOrEmpty(typeahead.context.data) ? (
                  <InfoText className={styles.info}>No results found</InfoText>
                ) : (
                  typeahead.context.data.map((item, index) => (
                    <li
                      {...getItemProps({
                        className: css(styles.item, index === highlightedIndex && styles.active),
                        index,
                        item,
                        key: item.name,
                      })}
                    >
                      <FilterItem {...item} />
                    </li>
                  ))
                )}
              </>
            ) : typeahead.matches('loading') ? (
              Array(Math.floor(Math.random() * 5) + 5)
                .fill(0)
                .map((_, idx) => (
                  <TableFetchingLoader
                    className={styles.fetching(Math.floor(Math.random() * 50) + 41)}
                    key={String(idx)}
                  />
                ))
            ) : typeahead.matches('failure') ? (
              <InfoText className={styles.info}>An error occurred</InfoText>
            ) : null}
          </ul>
        </div>
      )}
    </Downshift>
  )
}

export default TypeaheadFilter
