import { Action } from '../../redux'
import { HttpContent } from '../http'

enum AuthProvider {
  Google = 'google-oauth2',
  Juristat = 'juristat-accounts',
  LinkedIn = 'linkedin',
  OathProd = 'oath-okta-prod',
  OathQa = 'oath-okta-qa',
  Passwordless = 'passwordless',
  Skgf = 'juristat-skgf',
}

type AuthMethodResponse = {
  active: 0 | 1
  auth_provider_description: string | null
  auth_provider_key: AuthProvider
  auth_provider_name: string
  automatic_group?: string
  available_by_default: 0 | 1
  creates_users: 0 | 1
  email_verification_required: 0 | 1
  id: string
  provides_identity: 0 | 1
}

type AuthMethod = {
  active: boolean
  authProviderDescription: string
  authProviderKey: string
  authProviderName: AuthProvider
  automaticGroup?: string
  emailVerificationRequired: boolean
  id: string
}

type SentEmail = { email: string; sent: number }

type ViewContent = HttpContent<never>

type AuthState = {
  accessToken?: string
  accessTokenId: string
  authMethods: HttpContent<AuthMethod[]>
  claims: WeakObject
  expiration: number
  idToken?: string
  loginEmail: HttpContent<SentEmail>
  view: ViewContent
}

type AuthorizeResponse = {
  accessToken: string
  appState: string
  expiresIn: number
  idToken: string
  idTokenPayload: WeakObject
  refreshToken: null
  scope: string
  state: string
  tokenType: 'Bearer'
}

type TokenMeta = {
  expiresIn: number
}

type GetAuthMethodsPayload = {
  activeOnly?: boolean
  username: string
}

type LoginActionPayload = {
  password: string
  username: string
}

type AuthorizeAction = Action<'auth/AUTHORIZE', AuthProvider | undefined>
type AuthorizeErrorAction = Action<'auth/AUTHORIZE_ERROR', string, { debug: WeakObject | string }>
type AuthorizeErrorClearAction = Action<'auth/AUTHORIZE_ERROR_CLEAR', string>
type GetAuthMethodsAction = Action<'auth/GET_AVAILABLE_METHODS', GetAuthMethodsPayload>
type GetAuthMethodsErrorAction = Action<'auth/AVAILABLE_METHODS_ERROR', undefined>
type LoginAction = Action<'auth/LOGIN', LoginActionPayload>
type LoginEmailErrorAction = Action<'auth/LOGIN_EMAIL_ERROR', string>
type LoginEmailSendAction = Action<'auth/SEND_LOGIN_EMAIL', string>
type LoginEmailSuccessAction = Action<'auth/LOGIN_EMAIL_SENT', SentEmail>
type SetAuthMethodsAction = Action<'auth/SET_AVAILABLE_METHODS', AuthMethod[]>
type HydrateAccessTokenAction = Action<'auth/accessToken/HYDRATE', string>
type HydrateAccessTokenErrorAction = Action<'auth/accessToken/HYDRATE_ERROR', string>
type InvalidateAccessTokenAction = Action<'auth/accessToken/INVALIDATE', undefined>
type RedirectToDestinationAction = Action<'auth/REDIRECT', undefined>
type RefreshAccessTokenAction = Action<'auth/accessToken/REFRESH', undefined>
type SaveUsernameToLocalStorageAction = Action<'auth/SAVE_USERNAME_TO_LOCAL_STORAGE', string>
type SetAccessTokenAction = Action<'auth/accessToken/SET', string, TokenMeta>
type SetClaimsAction = Action<'auth/SET_CLAIMS', WeakObject>

type Actions =
  | AuthorizeAction
  | AuthorizeErrorAction
  | AuthorizeErrorClearAction
  | GetAuthMethodsAction
  | GetAuthMethodsErrorAction
  | HydrateAccessTokenAction
  | HydrateAccessTokenErrorAction
  | InvalidateAccessTokenAction
  | LoginAction
  | LoginEmailErrorAction
  | LoginEmailSendAction
  | LoginEmailSuccessAction
  | RedirectToDestinationAction
  | RefreshAccessTokenAction
  | SaveUsernameToLocalStorageAction
  | SetAccessTokenAction
  | SetAuthMethodsAction
  | SetClaimsAction

export {
  Actions,
  AuthMethod,
  AuthMethodResponse,
  AuthProvider,
  AuthState,
  AuthorizeAction,
  AuthorizeErrorAction,
  AuthorizeErrorClearAction,
  AuthorizeResponse,
  GetAuthMethodsAction,
  GetAuthMethodsErrorAction,
  GetAuthMethodsPayload,
  HydrateAccessTokenAction,
  HydrateAccessTokenErrorAction,
  InvalidateAccessTokenAction,
  LoginAction,
  LoginActionPayload,
  LoginEmailErrorAction,
  LoginEmailSendAction,
  LoginEmailSuccessAction,
  RedirectToDestinationAction,
  RefreshAccessTokenAction,
  SaveUsernameToLocalStorageAction,
  SentEmail,
  SetAccessTokenAction,
  SetAuthMethodsAction,
  SetClaimsAction,
  TokenMeta,
  ViewContent,
}
