import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const TrashCan = (props: IconProps) => (
  <SVG title="trash-can" width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
    <path d="M2.125 4.25H14.875" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.4584 4.25V14.1667C13.4584 14.875 12.7501 15.5833 12.0417 15.5833H4.95841C4.25008 15.5833 3.54175 14.875 3.54175 14.1667V4.25"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66675 4.25001V2.83334C5.66675 2.12501 6.37508 1.41667 7.08341 1.41667H9.91675C10.6251 1.41667 11.3334 2.12501 11.3334 2.83334V4.25001"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.08325 7.79167V12.0417"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.91675 7.79167V12.0417"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)

export default TrashCan
