import { RadioOption } from '@juristat/common/types'
import * as React from 'react'

import Radio from './Radio'

type RadioGroupProps<T> = {
  className?: string
  classNameSelected?: string
  horizontal?: boolean
  options: Array<RadioOption<T>>
  selectedOption: T
  setSelectedOption: (value: T) => void
  tw?: string
}

const styles = {
  group: (horizontal: boolean) =>
    `${
      horizontal ? '[&_label:not(:last-child)]:mr-5' : '[&_label:not(:last-child)]:mb-[6px]'
    } flex ${horizontal ? 'flex-row' : 'flex-col'}`,
}

const RadioGroup = <T,>({
  className,
  classNameSelected,
  horizontal = false,
  options,
  selectedOption,
  setSelectedOption,
  tw,
}: RadioGroupProps<T>) => (
  <div className={`${styles.group(horizontal)} ${className} ${tw}`} role="radiogroup">
    {options.map((option) => (
      <Radio
        key={String(option.value)}
        {...option}
        classNameSelected={classNameSelected}
        select={setSelectedOption}
        selected={selectedOption === option.value}
      />
    ))}
  </div>
)

export default RadioGroup
