import { call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import { HttpStatus } from '../../http'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { getWordsPayload } from '../selectors'
import { ClassifyResponse, GetSuggestionsAction, Suggestion } from '../types'
import transformSuggestionsResponse from '../utils/transformSuggestionsResponse'
import { DRAFTING_URL } from './constants'

function* handleError() {
  yield put(
    notificationActions.push(
      makeNotification({
        message: 'Error running classification',
        type: NotificationTypes.Error,
      })
    )
  )

  yield put(
    actions.setSuggestions({
      message: 'Error running classification',
      type: HttpStatus.Error,
    })
  )
}

function* getSuggestions(action: GetSuggestionsAction) {
  const classification = action.payload!
  const payload = yield select(getWordsPayload, classification)

  yield put(actions.setSuggestions({ type: HttpStatus.Fetching }, classification))

  try {
    const response: ApiResponse<ClassifyResponse> = yield call(
      api,
      `${DRAFTING_URL}/suggest/words`,
      {
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    )

    if (!response.ok) {
      return yield fork(handleError)
    }

    const result: Suggestion = yield call([response, 'json'])
    const data = transformSuggestionsResponse(result)

    yield put(actions.setSuggestions({ data, type: HttpStatus.Success }, classification))
  } catch {
    yield fork(handleError)
  }
}

function* watchGetSuggestions() {
  yield takeLatest(actions.getSuggestions().type, getSuggestions)
}

export { getSuggestions, handleError }
export default watchGetSuggestions
