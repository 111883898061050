import { createSelector } from 'reselect'

import { getResultsSearchDetails } from '../../search/selectors/getSearch'
import { SearchSet } from '../../search/types'
import { PaginationBar, PaginationState, PaginationTotal, PaginationVariables } from '../types'

const emptyObject = {}

const getPagination = createSelector(
  getResultsSearchDetails,
  (state: SearchSet) => state?.pagination || emptyObject
)

const getCurrentPage = createSelector(getPagination, (state: PaginationState): number => state.page)

const getPageSize = createSelector(
  getPagination,
  (state: PaginationState): number => state.pageSize
)

const getPageCount = createSelector(
  getPagination,
  (state: PaginationState): number => state.pageCount
)

const getResultsTotal = createSelector(
  getPagination,
  (state: PaginationState): number => state.total
)

const getPaginationBar = createSelector(
  getCurrentPage,
  getPageCount,
  (current: number, pageCount: number): PaginationBar => {
    return {
      current,
      pageCount,
    }
  }
)

const getPaginationTotal = createSelector(
  getResultsTotal,
  getCurrentPage,
  (totalCount: number, current: number): PaginationTotal => ({
    count: totalCount,
    page: current,
    rounded: totalCount > 1000,
  })
)

const getPaginationVariables = createSelector(
  getCurrentPage,
  getPageSize,
  (pageNum: number, pageSize: number): PaginationVariables => ({
    pageNum,
    pageSize,
  })
)

export {
  getCurrentPage,
  getPageSize,
  getPaginationBar,
  getPaginationTotal,
  getPaginationVariables,
  getResultsTotal,
}
