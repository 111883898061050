import { combineReducers } from 'redux'

import { Actions, PaginationState, PossibleActions } from '../types'

const page = (state = 1, action: PossibleActions): number => {
  switch (action.type) {
    case 'filter/APPLY':
    case 'filter/APPLY_ALL':
    case 'filter/APPLY_LIST':
    case 'filter/APPLY_SOME':
    case 'filter/CLEAR':
    case 'filter/CLEAR_ALL':
    case 'filter/CLEAR_SOME':
    case 'filter/SET':
    case 'pagination/PAGE_SIZE':
    case 'search/INPUT':
    case 'search/sort/ORDER':
      return 1
    case 'pagination/GO':
      return action.payload!
    case 'pagination/SET':
      // Either use what is in payload, or reset to first page
      return action.payload!.page ?? 1
    default:
      return state
  }
}

const pageCount = (state = 0, action: PossibleActions): number => {
  switch (action.type) {
    case 'pagination/SET':
      return action.payload!.pageCount
    case 'search/ERROR':
      return 0
    default:
      return state
  }
}

const pageSize = (state = 20, action: Actions): number => {
  switch (action.type) {
    case 'pagination/PAGE_SIZE':
      return action.payload!
    default:
      return state
  }
}

const total = (state = 0, action: Actions): number => {
  switch (action.type) {
    case 'pagination/SET':
      return action.payload!.total
    default:
      return state
  }
}

const reducer = combineReducers<PaginationState>({
  page,
  pageCount,
  pageSize,
  total,
})

export default reducer
