import { Row, StickyTable } from '@juristat/react-sticky-table'
import { css } from 'emotion'
import React, { useLayoutEffect, useRef } from 'react'

import { colors, scrollbar } from '../../styles'

type BaseTableProps = {
  bodyCellsByRow: JSX.Element[][]
  className?: string
  classNameRow?: string
  classNameRowHeader?: string
  headerCells: JSX.Element[]
  stickyHeader?: boolean
}

const styles = {
  table: css({
    '& .sticky-table-column': css({
      minHeight: '100%',
    }),
    '& .sticky-table-table': css({
      display: 'table',
      minWidth: '100%',
      overflow: 'visible',
      /*
      Hack to make table paint on it's own layer.
      Because of this it won't paint when the scrollbar paints.
      This one was crucial for performance.
      Scrolling is ~10-20x smoother.
      */
      transform: 'translateZ(0)',
      willChange: 'transform',
    }),
    '& .sticky-table-x-wrapper': css({
      minHeight: '100%',
      overflowX: 'auto',
      width: '100%',
    }),
    '& .sticky-table-y-wrapper': css({
      height: '100%',
      marginRight: -17,
      overflowY: 'auto',
      paddingRight: 17,
    }),
    [`
    & .sticky-table-column,
    & .sticky-table-header,
    & .sticky-table-corner,
    & .sticky-table-x-wrapper
    `]: css({
      display: 'inline-block',
      verticalAlign: 'top',
    }),
    [`
    & .sticky-table-column.hidden,
    & .sticky-table-header.hidden,
    & .sticky-table-corner.hidden
    `]: css({
      display: 'none',
    }),
    [`
    & .sticky-table-y-wrapper,
    & .sticky-table-header-wrapper
    `]: css({
      whiteSpace: 'nowrap',
    }),
    [`
    & .sticky-table-x-wrapper,
    & .sticky-table-y-wrapper
    `]: css({
      '&::-webkit-scrollbar': css({
        display: 'none',
      }),
      MsOverflowStyle: 'none',
      WebkitOverflowScrolling: 'touch',
      position: 'relative',
      zIndex: 0,
    }),
    '& .x-scrollbar': css({
      '& div': css({
        height: '100%',
      }),
      bottom: 0,
      height: 17,
      left: 0,
      width: '100%',
    }),
    '& .x-scrollbar, & .y-scrollbar': css(scrollbar.light, {
      backgroundColor: 'transparent',
      overflow: 'auto',
      position: 'absolute',
      transform: 'translateZ(0)',
      willChange: 'transform',
      zIndex: 5,
    }),
    '& .y-scrollbar': css({
      '& div': css({
        width: '100%',
      }),
      height: '100%',
      right: 0,
      top: 0,
      width: 17,
    }),
    backgroundColor: colors.white,
    position: 'relative',
    width: '100%',
  }),
}

const Table: React.FC<BaseTableProps> = ({
  className,
  classNameRow,
  classNameRowHeader = classNameRow,
  headerCells,
  bodyCellsByRow,
  stickyHeader = false,
}) => {
  const ref = useRef<{ resizeCellTable: () => void }>(null)
  useLayoutEffect(() => {
    if (ref.current && stickyHeader) {
      // Call on react-sticky-table to make sure cells are sized properly
      ref.current.resizeCellTable()
    }
  }, [bodyCellsByRow, headerCells, stickyHeader])

  return (
    <StickyTable
      className={css(styles.table, className)}
      ref={ref}
      stickyColumnCount={0}
      stickyHeaderCount={stickyHeader ? 1 : 0}
    >
      <Row className={classNameRowHeader}>{headerCells}</Row>
      {bodyCellsByRow.map((rowCells, rowIndex) => (
        <Row className={classNameRow} key={String(rowIndex)}>
          {rowCells}
        </Row>
      ))}
    </StickyTable>
  )
}
export default Table
