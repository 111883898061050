import { CustomLayerProps } from '@nivo/line'
import { ScaleType } from '@nivo/scales'
import { getYear, subMonths } from 'date-fns'
import React from 'react'

import { colors } from '../../../styles'

interface Scale {
  (value: number | { valueOf(): number }): number

  type: ScaleType
}

type PublicationDelayLineProps = Omit<CustomLayerProps, 'xScale'> & {
  innerHeight: number
  xScale: Scale
}

const PublicationDelayLine: React.FC<PublicationDelayLineProps> = (props) => {
  const { innerHeight, xScale } = props
  const convertScaleValue = (value: number) =>
    xScale.type === 'time' ? new Date(value, 0, 1) : value

  const publicationDelay = subMonths(new Date(), 18)
  const pubYearScale = xScale(convertScaleValue(getYear(publicationDelay)))

  if (isNaN(pubYearScale)) {
    return null
  }

  return (
    <g transform={`translate(${pubYearScale}, 0)`}>
      <line x1={0} x2={0} y1={0} y2={innerHeight} stroke={colors.charcoalGray} fill="none" />
      <text
        transform={'translate(-5, 95) rotate(-90)'}
        style={{ fill: colors.charcoalGray, fontSize: 11 }}
      >
        Publication Delay
      </text>
    </g>
  )
}

export default PublicationDelayLine
