type RoleResponse = {
  param: string | null
  role: string
  timestamp: string
  uri: string
  valid_after: string | null
  valid_before: string | null
}

const transformRoleResponse = ({
  param,
  role,
  valid_after: validAfter,
  valid_before: validBefore,
}: RoleResponse) => ({
  param,
  role,
  validAfter,
  validBefore,
})

export default transformRoleResponse
