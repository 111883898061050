import { parse, stringify } from 'qs'
import { equals, omit } from 'ramda'
import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'

import routerActions from '../modules/router/actions'
import usePrevious from './usePrevious'

type Options = Partial<{
  method: 'push' | 'replace'
  whenPathnameMatches: string
  withLocationState: boolean
}>

const sort = (left: string, right: string) => left.localeCompare(right)

export function useSyncToQueryString<T>(
  key: string,
  value: T,
  { method = 'replace', whenPathnameMatches = '', withLocationState = false }: Options = {}
) {
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  const routeMatch = useRouteMatch({ exact: true, path: whenPathnameMatches })
  const previousValue = usePrevious(value)

  useLayoutEffect(() => {
    const rest = omit([key], parse(search, { ignoreQueryPrefix: true }))
    const queryString = stringify({ [key]: value, ...rest }, { addQueryPrefix: true, sort })
    const url = `${pathname}${queryString}`

    const pathnameCheck = whenPathnameMatches ? routeMatch !== null : true

    if (pathnameCheck && `${pathname}${search}` !== url && !equals(previousValue, value)) {
      dispatch(routerActions[method](url, withLocationState ? value : undefined))
    }
  }, [key, search, pathname, value])
}
