import config from '@juristat/config'
import { useCallback } from 'react'

import { useAccessToken } from '../../auth'

// Internal hook and shouldn't be exposed outside api module
export default function useAccountsApi(json = false) {
  const accessToken = useAccessToken()

  return useCallback(
    async (
      url: string,
      { body, method }: { body?: string; method: string } = { body: undefined, method: 'get' }
    ) => {
      const accessHeader: Array<[string, string]> = accessToken
        ? [['authorization', `Bearer ${accessToken}`]]
        : []

      const jsonHeader: Array<[string, string]> = json ? [['content-type', 'application/json']] : []

      const headers = new Headers([['accept', 'application/json'], ...accessHeader, ...jsonHeader])

      const res = await fetch(`${config.accountsUrl}${url}`, {
        body,
        credentials: 'include',
        headers,
        method,
      })

      if (!res.ok) {
        if (res.status === 404) {
          throw 'not found'
        }

        const contentType = res.headers.get('content-type')
        const error = new Error(res.statusText)

        if (!contentType) {
          throw error
        }

        switch (true) {
          case contentType.includes('/json;'):
            throw await res.json()
          case contentType.includes('text/'):
            throw await res.text()
          default:
            throw error
        }
      }

      const type = res.headers.get('content-type')
      const isJson = type ? type.includes('json') : false
      const data = await (isJson ? res.json() : res.text())

      return data
    },
    [accessToken, json]
  )
}
