import { eqProps } from 'ramda'
import { Reducer } from 'redux'

import { HttpStatus } from '../../http/types'
import { ReducerActions, SponsorshipsState, SponsorshipsStatus } from '../types'

const reducer: Reducer<SponsorshipsState> = (
  state = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'sponsorships/APPLY_CHANGES':
      if (state.type !== HttpStatus.Success) {
        return state
      }

      return { ...state, type: SponsorshipsStatus.Saving }
    case 'sponsorships/SET_SPONSORSHIPS':
      return action.payload!
    case 'sponsorships/UPDATE_SPONSORSHIP':
      if (state.type !== SponsorshipsStatus.Saving) {
        return state
      }

      return {
        data: state.data.map((sponsorship) =>
          eqProps('sponsoredEmail', sponsorship, action.payload!) ? action.payload! : sponsorship
        ),
        type: HttpStatus.Success,
      }
    default:
      return state
  }
}

export default reducer
