import { createSelector } from 'reselect'

import { AppState } from '../../redux'
import { Params, RouterState } from './types'

const location = {
  hash: '',
  pathname: '',
  search: '',
  state: undefined,
}

export const getRouter = (state: AppState): NonNullable<RouterState> => state.router ?? location

export const getAppnoParam = (_: AppState, { appno }: Params): string => appno

export const getPathname = createSelector(getRouter, (state): string => state.pathname)

export const getQueryString = createSelector(getRouter, (state): string => state.search ?? '')
