import { css } from 'emotion'
import * as React from 'react'

import InfoText from '../../../components/InfoText'

const styles = {
  main: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'center',
    marginTop: -20,
  }),
}

const ExaminerCardInfoText: typeof InfoText = ({ children, ...rest }) => (
  <div className={styles.main}>
    <InfoText {...rest}>{children}</InfoText>
  </div>
)

export default ExaminerCardInfoText
