import { Filter, LocalStorageKey } from '@juristat/common/types'
import cuid from 'cuid'
import { isEmpty, keys, mergeDeepLeft, path, pick, without } from 'ramda'

import { AppState } from '..'
import { CpcFilterCount, UspcFilterCount } from '../../modules/filter/types'
import { DrawerState } from '../../modules/navigation/types'
import { sortOrder } from '../../modules/search/utils'

// TODO: See about typing this better
type Mappings = { [P: string]: (a?: any) => any }

const searchId = cuid()

const nestUnder = (prop: string) => (data: Record<string, unknown>) => ({ [prop]: { ...data } })

const getPaginationPageSizeState = () => {
  try {
    const data = window?.localStorage?.getItem?.(LocalStorageKey.PaginationPageSizeState)

    return data && data !== null ? Number(data.replace(/"/g, '')) : undefined
  } catch {
    return undefined
  }
}

const toActive =
  (prop: Filter) =>
  <T>(data: T) =>
    nestUnder('search')(
      nestUnder('sets')(
        nestUnder(searchId)(nestUnder('search')(nestUnder('filters')({ active: { [prop]: data } })))
      )
    )

const toPaginationPage = (page: string) =>
  nestUnder('search')(
    nestUnder('sets')(
      nestUnder(searchId)(nestUnder('search')(nestUnder('pagination')({ page: Number(page) })))
    )
  )

const toPaginationPageSize = () =>
  nestUnder('search')(
    nestUnder('sets')(
      nestUnder(searchId)(
        nestUnder('search')(nestUnder('pagination')({ pageSize: getPaginationPageSizeState() }))
      )
    )
  )

const toSearchDataSource = (dataSource: string) =>
  nestUnder('search')(nestUnder('sets')(nestUnder(searchId)(nestUnder('search')({ dataSource }))))

const toSearchPhrase = (phrase: string) =>
  nestUnder('search')(nestUnder('sets')(nestUnder(searchId)(nestUnder('search')({ phrase }))))

const toSearchScope = (scopes: string[]) =>
  nestUnder('search')(nestUnder('sets')(nestUnder(searchId)(nestUnder('search')({ scopes }))))

const toSearchType = (type: string) =>
  nestUnder('search')(nestUnder('sets')(nestUnder(searchId)(nestUnder('search')({ type }))))

const toSearchUid = (uid: string) =>
  nestUnder('search')(nestUnder('sets')(nestUnder(searchId)(nestUnder('search')({ uid }))))

const toSortDirection = (d: 'asc' | 'desc') =>
  nestUnder('search')(
    nestUnder('sets')(
      nestUnder(searchId)(
        nestUnder('search')(nestUnder('sort')({ direction: sortOrder.directionFromQueryString(d) }))
      )
    )
  )

const toSortField = (s: string) =>
  nestUnder('search')(
    nestUnder('sets')(
      nestUnder(searchId)(nestUnder('search')(nestUnder('sort')({ field: s.toUpperCase() })))
    )
  )

const toPcClass =
  <T extends Record<string, unknown>, K extends string = Extract<keyof T, string>>(
    prop: Filter,
    props: K[]
  ) =>
  (active: T[]) => {
    const nulledValues = props.reduce((acc, key) => ({ ...acc, [key]: null }), {} as T)
    const activeFilters = active.map((obj) => mergeDeepLeft(obj, nulledValues))

    return toActive(prop)(activeFilters)
  }

const mappings: Mappings = {
  amendmentCount: toActive(Filter.AmendmentCount),
  appealCount: toActive(Filter.AppealCount),
  applicationStatus: toActive(Filter.ApplicationStatus),
  applicationType: toActive(Filter.ApplicationType),
  appno: toActive(Filter.ApplicationNumber),
  artUnit: toActive(Filter.ArtUnit),
  attorney: toActive(Filter.RegistrationNumber),
  attorneyDocketNumber: toActive(Filter.AttorneyDocketNumber),
  cpcClass: toPcClass<CpcFilterCount>(Filter.CpcClass, [
    'class',
    'mainGroup',
    'subClass',
    'subGroup',
  ]),
  currentAssignee: toActive(Filter.CurrentAssignee),
  currentFirm: toActive(Filter.CurrentFirm),
  customerNumber: toActive(Filter.CustomerNumber),
  d: toSortDirection,
  dispositionDate: toActive(Filter.DispositionDate),
  examiner: toActive(Filter.Examiner),
  filingDate: toActive(Filter.FilingDate),
  finalRejectionsCount: toActive(Filter.FinalRejectionsCount),
  hasRejectionType: toActive(Filter.RejectionBasis),
  nonFinalRejectionsCount: toActive(Filter.NonFinalRejectionsCount),
  officeActionCount: toActive(Filter.OfficeActionsCount),
  p: toPaginationPage,
  publicationDate: toActive(Filter.PublicationDate),
  publicationNumber: toActive(Filter.PublicationNumber),
  q: toSearchPhrase,
  rceCount: toActive(Filter.RceCount),
  s: toSortField,
  scopes: toSearchScope,
  src: toSearchDataSource,
  techCenter: toActive(Filter.TechCenter),
  type: toSearchType,
  uid: toSearchUid,
  uspcClass: toPcClass<UspcFilterCount>(Filter.UspcClass, ['subclass']),
}

const officeActionFilters = [
  Filter.FinalRejectionsCount,
  Filter.NonFinalRejectionsCount,
  Filter.OfficeActionsCount,
]

const getOpenKeys = (state: any = {}) => {
  const activeKeys = keys(state)
  const officeActionKeys = pick(officeActionFilters, state)

  return isEmpty(officeActionKeys)
    ? activeKeys
    : // We do this because the office action filters are grouped under Filter.OfficeActionsCount for open
      [...without(officeActionFilters, activeKeys), Filter.OfficeActionsCount]
}

const getFilterState = (state?: any) =>
  !state || isEmpty(state.active)
    ? {}
    : {
        ...state,
        open: getOpenKeys(state.active),
      }

const getNavDrawerState = () => {
  try {
    if (window.location.pathname !== '/home') {
      const data = window?.localStorage?.getItem?.(LocalStorageKey.NavDrawerState)

      return data && data !== null ? (data.replace(/"/g, '') as DrawerState) : undefined
    }

    return undefined
  } catch {
    return undefined
  }
}

const getInitialState = (state: WeakObject): AppState => {
  const drawer = getNavDrawerState()
  const pageSize = toPaginationPageSize()

  const updated = Object.keys(state).reduce(
    (acc: RecursivePartial<AppState>, key: string) => {
      const queryKey = key as string

      return mergeDeepLeft(acc, mappings[queryKey] ? mappings[queryKey](state[queryKey]) : {})
    },
    { navigation: { drawer }, ...pageSize }
  )

  return (
    !isEmpty(updated)
      ? mergeDeepLeft(
          {
            search: {
              sets: {
                [searchId]: {
                  filters: getFilterState(
                    path(['search', 'sets', searchId, 'search', 'filters'], updated)
                  ),
                },
              },
              view: {
                active: {
                  filters: searchId,
                  results: searchId,
                },
              },
            },
          },
          updated
        )
      : updated
  ) as AppState
}

export default getInitialState
