import { OrderDirection } from '@juristat/common/types'
import { ascend, descend, head, memoizeWith, pathOr, pipe, sort, toString } from 'ramda'

const makeSortByDirection = <T>(direction: OrderDirection, pathToKeyToSortOn: string[]) =>
  memoizeWith(pipe(head, toString), (list: T[]) => {
    const path = pathOr('', pathToKeyToSortOn)
    const sortDirection = direction === OrderDirection.Descending ? descend : ascend

    return sort(sortDirection(path), list)
  })

export default makeSortByDirection
