import { Action } from '../../redux'

export enum IntercomActions {
  ApplicationView = 'v4_viewed_application_view',
  FeatureApplicationOfficeActions = 'v4_feature_application_office_actions',
  FeatureApplicationPatentFamily = 'v4_feature_application_patent_family',
  FeatureApplicationThreePaneView = 'v4_feature_application_three_pane_view',
  FeatureChartView = 'v4_feature_chart_view',
  FeatureCompare = 'v4_feature_compare',
  FeatureChatWithSales = 'v4_feature_clicked_chat_with_sales',
  FeatureOther = 'v4_feature_other',
  FeaturePremiumFilters = 'v4_feature_premium_filters',
  FeaturePremiumSorts = 'v4_feature_premium_sorts',
  FeatureSavedSearches = 'v4_feature_saved_searches',
  FeatureSearchScopes = 'v4_feature_search_scopes',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  FeatureTableView = 'v4_feature_premium_sorts',
  PerformedSearch = 'v4_performed_search',
  SavedSearches = 'v4_viewed_saved_searches',
  SearchHistory = 'v4_viewed_search_history',
  SearchView = 'v4_viewed_search_view',
  SigninContactUs = 'v4_signin_clicked_contact_us',
}

export type IntercomShowNewMessageApi = (verb: 'showNewMessage', message: string) => void

export type IntercomTrackEventApi = (
  verb: 'trackEvent',
  event: IntercomActions,
  opts?: WeakObject
) => void

type MetricLog = {
  [report: string]: {
    totalHits: number
    uniqueReports: number
  }
}

type MetricSub = {
  serial: number
  start_date: string
  end_date: string
  stripe_id: string | null
  plan_name: string
  uuid: string
  group_id: string
  renew: 0 | 1
}

export type MetricsResult = {
  formattedRoles: string[]
  logs: {
    [report: string]: MetricLog
  }
  personalApps: number
  trial: boolean
  roles: {
    [key: string]: string[][]
  }
  subs: MetricSub[]
}

export type LogIntercomEventAction = Action<
  'analytics/intercom/LOG',
  IntercomActions,
  Record<string, unknown>
>
export type ShowNewIntercomMessageAction = Action<'analytics/intercom/SHOW_NEW_MESSAGE', string>

export type Actions = LogIntercomEventAction
