type Options = {
  conjunction?: string
  limit?: number
}

const stringifyList = (list: string[], options: Options = {} as Options) => {
  const { conjunction = 'and', limit = 5 } = options
  if (list.length === 1) {
    return `${list[0]}`
  }

  const truncated = limit < list.length
  const visibleLength = truncated ? limit : list.length
  const visibleList = list.slice(0, visibleLength)

  const [last] = visibleList.slice(-1)
  const rest = visibleList.slice(0, visibleList.length - 1)
  const start = rest.join(', ')
  const endWithConjunction = `, ${conjunction} ${last}`
  const endWithMore = `, ${last}, and ${list.length - limit} more`
  return `${start}${truncated ? endWithMore : endWithConjunction}`
}

export default stringifyList
