import { css } from 'emotion'
import { isEmpty } from 'ramda'
import React from 'react'

import DataContainer from '../../../components/DataContainer'
import InfoText from '../../../components/InfoText'
import useResizeObserver from '../../../hooks/useResizeObserver'
import { colors } from '../../../styles'
import { FetchTypeState } from '../../api'
import { ChartData, ChartsInState } from '../types'

type ChildrenProps<T> = {
  data: T
  height: number
  width: number
}

type ChartContainerProps<T extends ChartsInState> = {
  bodyContent?: React.ReactNode
  checkEmpty?: <U>(data: U[]) => boolean
  className?: string
  children: (props: ChildrenProps<ChartData[T]>) => JSX.Element
  headerContent?: React.ReactNode
  machine: FetchTypeState<ChartData[T], any>
  skeleton: (props: { animate?: boolean }) => JSX.Element
  title: React.ReactNode
}

const styles = {
  content: css({
    flexGrow: 1,
    height: 0,
  }),
  dataContainer: css({
    background: colors.white,
    height: 350,
  }),
  infoText: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'center',
    marginTop: -20,
  }),
}

const ChartInfoText = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.infoText}>
    <InfoText>{children}</InfoText>
  </div>
)

const ChartContainer = <T extends ChartsInState>({
  bodyContent,
  checkEmpty = isEmpty,
  className,
  children,
  headerContent,
  machine,
  skeleton: Skeleton,
  title,
}: ChartContainerProps<T>) => {
  const { ref: resizeRef, dimensions } = useResizeObserver<HTMLDivElement>()

  return (
    <DataContainer
      className={css(styles.dataContainer, className)}
      headerContent={headerContent}
      title={title}
    >
      {bodyContent}
      <div ref={resizeRef} className={styles.content}>
        {(machine.matches('idle') || machine.matches('loading')) && <Skeleton />}
        {machine.matches('failure') && (
          <ChartInfoText>
            {typeof machine.context.error === 'string'
              ? machine.context.error
              : 'An unknown error has occurred'}
          </ChartInfoText>
        )}
        {machine.matches('success') &&
          (checkEmpty(machine.context.data as unknown[]) ? (
            <ChartInfoText>Insufficient data to generate graph</ChartInfoText>
          ) : (
            children({ data: machine.context.data, ...dimensions })
          ))}
      </div>
    </DataContainer>
  )
}

export default ChartContainer
