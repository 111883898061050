import { contains, isNil, keys, map, reject } from 'ramda'

import { Action } from '../../../redux'
import filterActions from '../../filter/actions'

type Actions = Action<any, any>

const getActiveSearchIdType = (action: Actions) => {
  const filterTypes = [
    ...reject(
      isNil,
      map((filterAction: any) => {
        const actionItem = (filterActions as any)[filterAction]
        return typeof actionItem === 'function' ? actionItem().type : undefined
      }, keys(filterActions))
    ),
    'search/report/SET',
    'search/INPUT',
    'search/set/FORK',
  ]
  return contains(action.type, filterTypes) ? 'filters' : 'results'
}

export default getActiveSearchIdType
