import { descend, map, partition, prop, sortWith } from 'ramda'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import ScrollableOnHover from '../../../components/ScrollableOnHover'
import { BoundAction } from '../../../redux'
import containsNumOrString from '../../../utils/containsNumOrString'
import actions from '../actions'
import FilterContext from '../context/filterContext'
import { useFilterState } from '../hooks'
import {
  ActiveFilterPayload,
  PossibleFilterItem,
  RemoveAction,
  SelectAction,
  TypeaheadFilter,
} from '../types'
import FilterCheckbox from './FilterCheckbox'

type AvailableFilterItemsProps = Pick<ReturnType<typeof useFilterState>, 'active' | 'selected'> & {
  available: PossibleFilterItem[]
  filter: TypeaheadFilter
  getLookup: (item: PossibleFilterItem) => ActiveFilterPayload['value']
}

type AvailableFilterItemsConnectedProps = AvailableFilterItemsProps & {
  remove: BoundAction<RemoveAction>
  select: BoundAction<SelectAction>
}

const styles = {
  main: 'mt-[10px]',
  scrollableOnHover: 'h-[260px] overflow-x-hidden',
  scrollableOnHoverContent: 'pr-1',
}

const AvailableFilterItems = ({ filter, getLookup, ...props }: AvailableFilterItemsProps) => {
  const { meta } = useContext(FilterContext)
  const dispatch = useDispatch()
  const remove: BoundAction<RemoveAction> = useCallback(
    (payload) => dispatch(actions.remove(payload, meta)),
    [dispatch, meta]
  )
  const select: BoundAction<SelectAction> = useCallback(
    (payload) => dispatch(actions.select(payload, meta)),
    [dispatch, meta]
  )

  const [pinned, checkboxes] = useMemo(() => {
    const [activeFilters, nonActiveFilters] = partition(
      (item) => containsNumOrString(getLookup(item), props.active as any),
      props.available
    )
    const [selectedFilters, availableFilters] = partition(
      (item) => containsNumOrString(getLookup(item), props.selected as any),
      nonActiveFilters
    )

    const [pinned, available] = map(sortWith([descend(prop('apps'))]), [
      [...activeFilters, ...selectedFilters],
      availableFilters,
    ]) as [PossibleFilterItem[], PossibleFilterItem[]]

    const transform = (filterItem: PossibleFilterItem) => {
      const item = getLookup(filterItem)
      const action = containsNumOrString(item, props.selected as any) ? remove : select
      const active = containsNumOrString(item, props.active as any)
      const selected = containsNumOrString(item, props.selected as any)
      const metadataKey = typeof item === 'string' ? item : JSON.stringify(item)
      const key = `${metadataKey}-${active}-${selected}`

      return (
        <FilterCheckbox
          {...{
            action,
            active,
            checked: selected,
            filter,
            filterItem,
            key,
            value: item,
          }}
        />
      )
    }

    return [pinned.map(transform), available.map(transform)]
  }, [filter, getLookup, props.active, props.available, props.selected, remove, select])

  return checkboxes.length < 11 ? (
    <div className={styles.main}>
      {pinned}
      {checkboxes}
    </div>
  ) : (
    <>
      <div className={styles.main}>{pinned}</div>
      <ScrollableOnHover
        className={`${styles.scrollableOnHover} ${styles.main}`}
        contentContainerClassName={styles.scrollableOnHoverContent}
        dark
      >
        <div>{checkboxes}</div>
      </ScrollableOnHover>
    </>
  )
}

export { AvailableFilterItemsConnectedProps }
export default AvailableFilterItems
