import { isNilOrEmpty } from '@juristat/common/utils'
import { css } from 'emotion'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { breakpoints, makeLink, media } from '../../../styles'
import { useHasAccess } from '../../session/hooks'
import { Access } from '../../session/types'

type AttributeValueProps = {
  canAccess?: Access
  to?: string
  value: number | string | null
}

const styles = css(media(breakpoints.xlarge, { maxWidth: '100%' }), {
  maxWidth: '14em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const AttributeValueText = ({ value }: AttributeValueProps) => (
  <b className={styles} title={String(value)}>
    {isNilOrEmpty(value) ? 'N/A' : value}
  </b>
)

const AttributeValue = ({ canAccess, to, value }: AttributeValueProps) => {
  const hasAccess = useHasAccess(canAccess)
  const shouldLink = useMemo(
    () =>
      to !== undefined &&
      !/\/(null|undefined)(\/?|$)/.test(to) &&
      value !== null &&
      (canAccess === undefined || hasAccess),
    [canAccess, hasAccess, to, value]
  )

  return shouldLink ? (
    <Link className={css(makeLink(), styles)} to={`/${to}`}>
      <AttributeValueText value={value} />
    </Link>
  ) : (
    <AttributeValueText value={value} />
  )
}

export default AttributeValue
