import makeRgba from './makeRgba'

// Color names courtesy of http://chir.ag/projects/name-that-color/

const alabaster = '#fbfbfb'
const alabaster2 = '#fafafa'
const alto = '#dddddd'
const alto2 = '#dedede'
const alto3 = '#cfcfcf'
const apple = '#2ecc40'
const astral = '#337ab7'
const atlantis = '#7fc12b'
const atlantisAlpha7 = makeRgba([127, 193, 43], 0.7)
const black = '#000000'
const blackAlpha1 = makeRgba([0, 0, 0], 0.1)
const blackAlpha3 = makeRgba([0, 0, 0], 0.3)
const blumine = '#1b537d'
const blumineAlpha2 = makeRgba([99, 161, 209], 0.2)
const carnation = '#f47560'
const charade = '#2a2c35'
const codGrayAlpha7 = makeRgba([10, 10, 10], 0.7)
const concrete = '#f3f3f3'
const danube = '#63a1d1'
const downy = '#61cdbb'
const energyYellow = '#f1e15b'
const gallery = '#efefef'
const gallery2 = '#ededed'
const ghost = '#c8ccd4'
const governorBay = '#2b35c1'
const gray = '#838383'
const limerickAlpha4 = makeRgba([127, 193, 43], 0.4)
const limerickAlpha8 = makeRgba([127, 193, 43], 0.8)
const merlot = '#7d1b22'
const merlotAlpha2 = makeRgba([213, 114, 120], 0.2)
const pacifika = '#7f781c'
const pacifikaAlpha2 = makeRgba([206, 198, 88], 0.2)
const rebeccapurpleAlpha8 = makeRgba([102, 51, 153], 0.8)
const redOrange = '#ff4136'
const schoolBusYellow = '#ffdc00'
const scorpion = '#585858'
const seashell = '#f1f1f1'
const shakespeare = '#40b5d0'
const silver = '#cccccc'
const silver2 = '#bbbbbb'
const silver3 = '#c7c7c7'
const silver4 = '#c4c4c4'
const silver5 = '#b9b9b9'
const silver6 = '#c3c3c3'
const silverChalice = '#aaaaaa'
const silverChaliceAlpha5 = makeRgba([170, 170, 170], 0.5)
const tacha = '#cec658'
const white = '#ffffff'
const whiteAlpha3 = makeRgba([255, 255, 255], 0.3)
const whiteAlpha5 = makeRgba([255, 255, 255], 0.5)
const whiteAlpha8 = makeRgba([255, 255, 255], 0.8)
const wildSand = '#f6f6f6'
const wildSand2 = '#f7f7f7'

// From nivo
const d320 = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
]

export {
  alabaster,
  alabaster2,
  alto,
  alto2,
  alto3,
  apple,
  astral,
  atlantis,
  atlantisAlpha7,
  black,
  blackAlpha1,
  blackAlpha3,
  blumine,
  blumineAlpha2,
  carnation,
  charade,
  codGrayAlpha7,
  concrete,
  d320,
  danube,
  downy,
  energyYellow,
  gallery,
  gallery2,
  ghost,
  governorBay,
  gray,
  limerickAlpha4,
  limerickAlpha8,
  merlot,
  merlotAlpha2,
  pacifika,
  pacifikaAlpha2,
  rebeccapurpleAlpha8,
  redOrange,
  schoolBusYellow,
  scorpion,
  seashell,
  shakespeare,
  silver,
  silver2,
  silver3,
  silver4,
  silver5,
  silver6,
  silverChalice,
  silverChaliceAlpha5,
  tacha,
  white,
  whiteAlpha3,
  whiteAlpha5,
  whiteAlpha8,
  wildSand,
  wildSand2,
}
