import { Filter, Key } from '@juristat/common/types'
import React from 'react'

import parseAppno from '../../../utils/parseAppno'
import stringifyList from '../../../utils/stringifyList'
import { useNotification } from '../../notification/hooks'
import TagFilterContainer from './TagFilterContainer'
import getTagInputErrorValues from './utils/getTagInputErrorValues'

const getErrorValues = getTagInputErrorValues(/[\s]+/)

const ApplicationNumberTagFilterContainer: React.FC = () => {
  const { addErrorNotification } = useNotification()

  return (
    <TagFilterContainer
      addKeys={[Key.Enter, Key.Space, Key.Tab]}
      filter={Filter.ApplicationNumber}
      handleParseError={(data: string) => {
        const errorValues = getErrorValues(data)
        const message =
          errorValues.length > 0
            ? `Failed to parse ${stringifyList(errorValues)} as application number(s)`
            : 'Did not receive any application numbers'

        addErrorNotification(
          `${message}. Application numbers should contain 8 digits and be formatted as 12000001 or 12/000,001`
        )
      }}
      parseInput={parseAppno}
      pasteSplit={(data: string) => data.split(/[\s]+/).map((d: string) => d.trim())}
      title="Application Number"
      placeholder="Enter an Application #..."
    />
  )
}

export { getErrorValues }
export default ApplicationNumberTagFilterContainer
