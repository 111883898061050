import makeAction from '../../../redux/utils/makeAction'
import {
  EditingAction,
  OmnisearchClearAction,
  OmnisearchErrorAction,
  OmnisearchFetchAction,
  OmnisearchGetAction,
  OmnisearchSetAction,
  SaveAction,
  SavedAction,
} from '../types'

const editing = makeAction<EditingAction>('portfolio/EDITING')
const save = makeAction<SaveAction>('portfolio/SAVE')
const saved = makeAction<SavedAction>('portfolio/SAVED')

const clear = makeAction<OmnisearchClearAction>('portfolio/omnisearch/CLEAR')
const error = makeAction<OmnisearchErrorAction>('portfolio/omnisearch/ERROR')
const fetch = makeAction<OmnisearchFetchAction>('portfolio/omnisearch/FETCH')
const get = makeAction<OmnisearchGetAction>('portfolio/omnisearch/GET')
const set = makeAction<OmnisearchSetAction>('portfolio/omnisearch/SET')

const omnisearch = {
  clear,
  error,
  fetch,
  get,
  set,
}

export default { editing, omnisearch, save, saved }
