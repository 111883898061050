import { eqBy, prop, unionWith } from 'ramda'

import { ApplicationTypeEnum, AppsAndName } from '../types'

const applicationTypeLookup: { [key in ApplicationTypeEnum]: string } = {
  [ApplicationTypeEnum.Continuation]: 'Continuation',
  [ApplicationTypeEnum.ContinuationInPart]: 'Continuation in Part',
  [ApplicationTypeEnum.Design]: 'Design',
  [ApplicationTypeEnum.Divisional]: 'Divisional',
  [ApplicationTypeEnum.FirstFiling]: 'First Filing',
  [ApplicationTypeEnum.ForeignPriority]: 'Foreign Priority',
  [ApplicationTypeEnum.NationalStageEntry]: 'National Stage Entry',
  [ApplicationTypeEnum.Plant]: 'Plant',
  [ApplicationTypeEnum.Provisional]: 'Provisional',
  [ApplicationTypeEnum.Reexamination]: 'Re-examination',
  [ApplicationTypeEnum.Reissue]: 'Re-issue',
  [ApplicationTypeEnum.TrackOne]: 'Track One',
  [ApplicationTypeEnum.Utility]: 'Utility',
}

const defaults = Object.keys(applicationTypeLookup).map((value) => ({
  apps: 0,
  label: applicationTypeLookup[value as ApplicationTypeEnum],
  value,
}))

const transformApplicationTypes = (appTypes: AppsAndName[]) =>
  unionWith(
    eqBy(prop<'value', string>('value')),
    appTypes.map((appType) => ({
      apps: appType.apps,
      label: applicationTypeLookup[appType.name.toUpperCase() as ApplicationTypeEnum],
      value: appType.name.toUpperCase(),
    })),
    defaults
  )

export { applicationTypeLookup }
export default transformApplicationTypes
