import { contains } from 'ramda'
import { createSelector } from 'reselect'

import { getFilter, getFilterStateOpen } from '.'

const getIsFilterOpen = createSelector(getFilter, getFilterStateOpen, (filter, state): boolean =>
  contains(filter, state)
)

export default getIsFilterOpen
