import { isNil, pathOr } from 'ramda'

import { TopEntityByPatentsIssuedResponse } from '../types'

// returns an arroy of the first N results that match the predicate
const takeFirstMatching = <T>(predicate: (item: T) => boolean, maxResults: number, list: T[]) => {
  const arr: T[] = []
  for (const item of list) {
    if (predicate(item)) {
      arr.push(item)
    }
    if (arr.length >= maxResults) {
      return arr
    }
  }
  return arr
}

const getTop5EntitiesFromResponse = (response: TopEntityByPatentsIssuedResponse) => {
  const allBuckets = response.data.applicationSet.metrics

  return takeFirstMatching((metric) => !isNil(metric.buckets[0].object), 5, allBuckets).map(
    (metric) => {
      const entity = metric.buckets[0].object
      const entityMetrics = entity.applicationSet.metrics[0]

      return {
        allowanceRate: entityMetrics.allowanceRate || 0,
        averageDaysToDisposition: pathOr(
          0,
          ['timing', 'daysToDisposition', 'average'],
          entityMetrics
        ),
        averageOfficeActionsToDisposition: pathOr(
          0,
          ['officeActions', 'toDisposition', 'average'],
          entityMetrics
        ),
        id: entity.id,
        name: entity.name,
      }
    }
  )
}

export default getTop5EntitiesFromResponse
