import config from '@juristat/config'
import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api'
import { HttpStatus } from '../../../../http/types'
import actions from '../actions'
import { UsersFetchDetailsAction } from '../types'
import { mapAccountsUserDetailsResponse } from './utils/mapAccountUserResponse'

function* fetchUserDetails(action: UsersFetchDetailsAction) {
  const uuid = action.payload!

  yield put(actions.setDetails({ type: HttpStatus.Fetching }))

  try {
    const url = `${config.accountsUrl}/api/user/${uuid}`
    const response = yield call(api, url)

    if (!response.ok) {
      yield put(
        actions.setDetails({ message: 'Error fetching user details', type: HttpStatus.Error })
      )
      return
    }

    const user = yield call([response, 'json'])
    yield put(
      actions.setDetails({ data: mapAccountsUserDetailsResponse(user), type: HttpStatus.Success })
    )
  } catch {
    yield put(
      actions.setDetails({ message: 'Error fetching user details', type: HttpStatus.Error })
    )
  }
}

function* watchFetchUserDetails() {
  yield takeLatest(actions.fetchDetails().type, fetchUserDetails)
}

export { fetchUserDetails }
export default watchFetchUserDetails
