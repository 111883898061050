import { LocalStorageKey } from '@juristat/common/types'
import { css } from 'emotion'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { animated, useSpring } from 'react-spring'

import { useIsPrinting, useLocalStorage } from '../../../hooks'
import { colors, zIndex } from '../../../styles'
import matchSearchPath from '../../../utils/matchSearchPath'
import SearchFilters from '../../filter/components/SearchFilters'
import { getPathname } from '../../router/selectors'
import SavedSearchesList from '../../userSearches/components/SavedSearchesList'
import actions from '../actions'
import { getDrawerState } from '../selectors'
import { DrawerState } from '../types'

const drawerWidth = 263

const styles = {
  main: css({
    alignItems: 'stretch',
    backgroundColor: colors.dark,
    borderRight: `1px solid ${colors.paleGray2}`,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: '100%',
    marginLeft: 0,
    paddingTop: 10,
    position: 'relative',
    transition: 'margin-left 250ms ease-out',
    width: drawerWidth,
    willChange: 'margin-left',
    zIndex: zIndex.navDrawer,
  }),
}

const NavDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const drawerState = useSelector(getDrawerState)
  const pathname = useSelector(getPathname)
  const isSearchPath = matchSearchPath(pathname)
  const isPrinting = useIsPrinting()
  const collapsed = isPrinting || drawerState === DrawerState.Closed
  const [lastDrawerState, setLastDrawerState] = useState(() =>
    pathname === '/home' ? DrawerState.Closed : drawerState
  )

  const marginLeft = useSpring({
    to: { marginLeft: collapsed ? -drawerWidth : 0 },
    onRest: () => {
      setLastDrawerState(drawerState)
    },
    onStart: () => {
      if (!collapsed) {
        setLastDrawerState(drawerState)
      }
    },
  })

  useLocalStorage(LocalStorageKey.NavDrawerState, drawerState)

  useEffect(() => {
    if (!isSearchPath && drawerState === DrawerState.SavedSearches) {
      dispatch(actions.setDrawerState(DrawerState.Closed))
    }
  }, [pathname])

  useEffect(() => {
    if (drawerState !== DrawerState.Closed) {
      setLastDrawerState(drawerState)
    }
  }, [drawerState])

  return (
    <animated.div className={styles.main} style={marginLeft}>
      {lastDrawerState === DrawerState.Filters && <SearchFilters />}
      {lastDrawerState === DrawerState.SavedSearches && <SavedSearchesList />}
    </animated.div>
  )
}

export default NavDrawer
