import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Bell = (props: IconProps) => (
  <SVG title="bell" viewBox="0 0 12 14" {...props}>
    <path
      d="M0 11.846l1.615-3.23V4.307a4.308 4.308 0 0 1 8.616 0v4.307l1.615 3.231H0zM5.923 14a1.61 1.61 0 0 1-1.516-1.077h3.032A1.61 1.61 0 0 1 5.923 14z"
      fillRule="evenodd"
    />
  </SVG>
)

export default Bell
