import { useMachine } from '@xstate/react'
import downloadjs from 'downloadjs'
import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import noop from '../../../utils/noop'
import { getAccessToken } from '../../auth'
import { NotificationTypes, useNotification } from '../../notification'
import { fetchMachine } from './machines'

const getFilenameFromHeaders = (headers: Headers) => {
  if (headers.has('content-disposition')) {
    const header = headers.get('content-disposition')
    const matches = header!.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)

    if (Array.isArray(matches)) {
      return matches[1].trim().replace(/[^a-zA-Z0-9.\-_]/g, '')
    }

    return undefined
  }

  return undefined
}

export function useDownload({
  skipNotification = false,
  displayApiError = false,
  displayApiErrorPrefix = '',
} = {}) {
  const accessToken = useSelector(getAccessToken)
  const { addNotification, updateNotification } = useNotification()
  const headers = new Headers([['authorization', `Bearer ${accessToken}`]])
  const notificationId = useRef<string>()

  const [state, send] = useMachine(fetchMachine, {
    actions: { transform: noop },
    guards: { transformErrored: () => false },
    services: {
      fetchData: async (_, event) => {
        if (event.type !== 'FETCH') {
          throw 'Event is not of type `FETCH`'
        }

        const { filename, mimeType, url } = event as Partial<Record<string, string>> & {
          type: 'FETCH'
        }

        if (!url) {
          throw 'No url provided'
        }

        const res = await fetch(url, {
          credentials: 'include',
          headers,
          method: 'get',
        })

        if (!res.ok) {
          if (displayApiError) {
            const response =
              res.headers.get('x-exposed-error') ??
              (await (res.headers.get('content-type')?.includes('json') ? res.json() : res.text()))

            throw typeof response === 'string' ? response : response.error
          }

          throw res.statusText
        }

        const fallbackUrl = res.headers.get('x-fallback-url')

        try {
          return downloadjs(
            await res.blob(),
            filename ?? getFilenameFromHeaders(res.headers),
            mimeType
          )
        } catch (error) {
          if (fallbackUrl) {
            throw fallbackUrl
          }
        }
      },
    },
  })

  const download = useCallback(
    (url: string, filename?: string, mimeType?: string) => {
      if (!skipNotification) {
        notificationId.current = addNotification('Your download will start momentarily...')
      }

      send(['RESET', { filename, mimeType, type: 'FETCH', url }])
    },
    [addNotification, send, skipNotification]
  )

  useEffect(() => {
    if (skipNotification) {
      return
    }

    if (state.matches('failure')) {
      const error = state.context.error.toString()
      const message = displayApiError
        ? displayApiErrorPrefix
          ? `${displayApiErrorPrefix}: ${error}`
          : error
        : 'Download request failed.'
      const link = error.startsWith('https://')
        ? { text: 'Click here for a direct download.', to: error }
        : undefined

      if (notificationId.current) {
        updateNotification({
          id: notificationId.current,
          link,
          message,
          type: NotificationTypes.Error,
        })
      } else {
        addNotification(message, { link, type: NotificationTypes.Error })
      }
    }
  }, [addNotification, skipNotification, state, updateNotification])

  return [download, state] as const
}
