import { join, match, pipe, propOr, toUpper } from 'ramda'

// All kind codes that currently exist in our database
const kindCodes = ['A1', 'A2', 'A9', 'B1', 'B2', 'E1', 'H1', 'H2', 'P1', 'P2', 'P3', 'P9', 'S1']

// Matches three groups: 1: US (optional), 2: pubno with special characters, 3: kind code (optional)
const matchPubno = new RegExp(`(US)?([^a-z]+)(${join('|', kindCodes)})?`, 'i')

const sanitizeNumber = (value: string) => value.replace(/[^\d]+/g, '')

const numberIsValid = (value: string) => value.length === 11

const buildString = (...values: Array<string | undefined>) =>
  join(
    '',
    values.map((value) => (value ? value : ''))
  )

const pubnoOrReject = (matched: string[]) => {
  const num = sanitizeNumber(propOr('', '2', matched))

  if (numberIsValid(num)) {
    return buildString(matched[1], num, matched[3])
  }
  return ''
}

const parsePubno: (value: string) => string = pipe(match(matchPubno), pubnoOrReject, toUpper)

export default parsePubno
