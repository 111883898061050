import { format, isAfter, isBefore } from 'date-fns'
import Downshift, { DownshiftInterface } from 'downshift'
import React, { useState } from 'react'
import { animated, useSpring, useTransition } from 'react-spring'

import Chevron, { Direction } from '../../../components/Chevron'
import DatePickerCalendar from '../../../components/DatePickerCalendar'
import Menu from '../../../components/Menu'
import TextInput from '../../../components/TextInput'
import { colors } from '../../../styles'
import { getTextWidth } from '../../../utils/getTextWidth'
import { Arrow, Close } from '../../icons'

type DateFilter = {
  column: string | null
  readOnly?: boolean
  value: {
    endDate: Date | null
    startDate: Date | null
  } | null
}

type OarDateRangeFilterProps<T extends DateFilter> = {
  dateFilter: T
  options: Option[]
  setDateFilter: (payload: T) => void
}

type Option = {
  label: string
  value: string
}

const styles = {
  active: '!border-b !border-azure',
  arrow: 'fill-current mt-[2px] rotate-90',
  calendar: 'left-[95px] absolute top-[50px] z-20',
  chevron: (selection: 'start' | 'end') =>
    `[&_>_svg]:fill-white [&_>_svg]:h-[30px] [&_>_svg]:w-[30px] absolute left-[50px] -top-[17px] ${
      selection === 'start' ? 'translate-x-0' : 'translate-x-[110px]'
    } transition-[transform] duration-300 ease-in-out z-[202]`,
  close: 'hover:opacity-70 fill-red-500 transition-[opacity] duration-300 ease-in-out',
  container:
    'items-center bg-white border-none rounded text-charcoal-gray7 flex h-[35px] justify-between py-2 px-[10px] relative',
  datePicker:
    'focus:border focus:border-transparent bg-transparent border-transparent rounded-none text-charcoal-gray7 font-bold cursor-pointer my-0 mx-[5px] pt-0 px-0 pb-[2px] text-center w-20',
  menu: '[&_>_div_>_button]:text-charcoal-gray7 [&_>_div_>_button]:font-bold [&_>_div_>_button]:whitespace-nowrap border-tl border-bl -mt-[10px] mr-0 -mb-[10px] -ml-[10px] p-[10px]',
  separator: 'bg-silver2 h-full w-[1px]',
}

const TypedDownshift: DownshiftInterface<Date | null> = Downshift

const WorkflowAutomationDateRangeFilter = <T extends DateFilter>({
  dateFilter: { column, readOnly = false, value },
  options,
  setDateFilter,
}: OarDateRangeFilterProps<T>) => {
  const showClose = value !== null
  const title = options.find((option) => option.value === column)?.label ?? 'Date Filter'

  const [downshiftOpen, setDownshiftOpen] = useState(false)
  const [selection, setSelection] = useState<'start' | 'end'>('start')

  const show = useTransition(downshiftOpen, {
    enter: { opacity: 1 },
    from: { opacity: 0 },
    initial: null,
    leave: { opacity: 0 },
  })
  const transition = useTransition(showClose && !readOnly, {
    enter: { opacity: 1, width: 15 },
    from: { opacity: 0, width: 0 },
    initial: null,
    leave: { opacity: 0, width: 0 },
  })
  const width = getTextWidth(title)
  const springWidth = useSpring({ width: width + (column ? 250 : 40) + (showClose ? 25 : 0) })

  return (
    <TypedDownshift
      itemToString={(item) => (item ? format(item, 'MM/dd/yyyy') : '')}
      stateReducer={(_, changes) => {
        if (changes.isOpen !== undefined) {
          setDownshiftOpen(changes.isOpen)
        }

        switch (changes.type) {
          case Downshift.stateChangeTypes.blurInput:
            return { ...changes, isOpen: changes.isOpen ?? true }
          default:
            return changes
        }
      }}
    >
      {({ closeMenu, getInputProps, getRootProps, isOpen, openMenu }) => (
        <animated.div {...getRootProps()} className={styles.container} style={springWidth}>
          <div className={styles.menu}>
            <Menu
              align="left"
              disabled={options.length === 1}
              handleClick={(column) => setDateFilter({ column, value } as T)}
              options={options}
              title={title}
            />
          </div>
          {column ? (
            <>
              <div className={styles.separator} />
              {show(
                (style, item, { key }) =>
                  item && (
                    <animated.div className={styles.calendar} style={style}>
                      <div className={styles.chevron(selection)} key={key}>
                        <Chevron color={colors.white} direction={Direction.Up} title="" />
                      </div>
                      <DatePickerCalendar
                        date={
                          selection === 'start' ? value?.startDate ?? null : value?.endDate ?? null
                        }
                        isDayDisabled={(day) => {
                          if (!day) {
                            return true
                          }

                          if (selection === 'start' && value?.endDate) {
                            return isAfter(day, value.endDate)
                          }

                          if (selection === 'end' && value?.startDate) {
                            return isBefore(day, value.startDate)
                          }

                          return false
                        }}
                        isOpen={isOpen}
                        onChange={(date) => {
                          setDateFilter({
                            column,
                            value: {
                              endDate: null,
                              startDate: null,
                              ...value,
                              [`${selection}Date`]: date,
                            },
                          } as T)

                          if (selection === 'start') {
                            setSelection('end')
                          } else {
                            closeMenu()
                          }
                        }}
                      />
                    </animated.div>
                  )
              )}
              <TextInput
                accent="blue"
                disabled={readOnly}
                {...getInputProps({
                  id: 'oar-date-filter-start-date',
                  onClick: () => {
                    openMenu()
                    setSelection('start')
                  },
                  onFocus: () => {
                    openMenu()
                    setSelection('start')
                  },
                  ref: undefined,
                  value: value?.startDate ? format(value.startDate, 'MM/dd/yyyy') : '',
                })}
                placeholder="Start Date"
                readOnly
                tw={`${styles.datePicker} ${isOpen && selection === 'start' ? styles.active : ''}`}
              />
              <Arrow className={styles.arrow} />
              <TextInput
                accent="blue"
                disabled={readOnly}
                {...getInputProps({
                  id: 'oar-date-filter-end-date',
                  onClick: () => {
                    openMenu()
                    setSelection('end')
                  },
                  onFocus: () => {
                    openMenu()
                    setSelection('end')
                  },
                  ref: undefined,
                  value: value?.endDate ? format(value.endDate, 'MM/dd/yyyy') : '',
                })}
                placeholder="End Date"
                readOnly
                tw={`${styles.datePicker} ${isOpen && selection === 'end' ? styles.active : ''}`}
              />
              {transition(
                (style, item, { key }) =>
                  item && (
                    <animated.div key={key} style={style}>
                      <Close
                        className={styles.close}
                        height={12}
                        onClick={() => {
                          setDateFilter({ column: null, value: null } as T)
                          closeMenu()
                        }}
                        title="Clear filter"
                        width={12}
                      />
                    </animated.div>
                  )
              )}
            </>
          ) : null}
        </animated.div>
      )}
    </TypedDownshift>
  )
}

export default WorkflowAutomationDateRangeFilter
