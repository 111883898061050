import config from '@juristat/config'
import { cps, put, takeLatest } from 'redux-saga/effects'

import actions from '../actions'
import { LoginAction } from '../types'
import { auth0Login } from '../utils/auth0'

function* login(action: LoginAction) {
  try {
    const { password, username } = action.payload!
    yield cps(auth0Login, {
      password,
      realm: config.auth0JuristatAccountsConnection,
      username,
    })
  } catch (unknownErr) {
    const err = unknownErr as Error & { description?: string }
    yield put(actions.error(err.description ?? 'Failed to authorize', { debug: err }))
  }
}

export { login }
export default function* watchLogin() {
  yield takeLatest(actions.login().type, login)
}
