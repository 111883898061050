import * as React from 'react'

import SVG from '../SVG'
import { IconProps } from '../types'

const MailSolid = (props: IconProps) => (
  <SVG title="mail-solid" viewBox="0 0 18 12" {...props}>
    <g transform="translate(-30.000000, -548.000000)">
      <g transform="translate(20.000000, 535.000000)">
        <g transform="translate(10.000000, 13.000000)">
          <path d="M1.07451923,0 L9,6.45833539 L16.9254808,0 L1.07451923,0 Z M0,0.868044123 L0,12 L18,12 L18,0.868044123 L9.44711538,7.84026821 C9.18900672,8.050482 8.81099328,8.050482 8.55288462,7.84026821 L0,0.868044123 Z" />
        </g>
      </g>
    </g>
  </SVG>
)

export default MailSolid
