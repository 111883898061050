import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import memoize from '../../../utils/memoize'
import FilterContext from '../context/filterContext'
import getFilterStateFromUrl from '../utils/getFilterStateFromUrl'

const FilterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation()
  const contextValue = useMemo(() => memoize(getFilterStateFromUrl(pathname)), [pathname])

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>
}

export default FilterContextProvider
