import { path } from 'ramda'

import { ActiveReducer } from '../../filter/types'
import enforceMinimumDate from './enforceMinimumDate'

const defaultDispositionDateFilter = (filters: ActiveReducer) => ({
  ...filters,
  dispositionDate: {
    ...filters.dispositionDate,
    start: enforceMinimumDate(path(['dispositionDate', 'start'], filters)),
  },
})

export default defaultDispositionDateFilter
