import { Charts, ExaminerMetric, IntelligenceEntityType } from '@juristat/common/types'
import { BarTooltipDatum } from '@nivo/bar'
import { css, cx } from 'emotion'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import InfoText from '../../../components/InfoText'
import useResizeObserver from '../../../hooks/useResizeObserver'
import { AppState } from '../../../redux'
import { colors } from '../../../styles'
import formatPercent from '../../../utils/formatPercent'
import { useMergedQueries } from '../../api'
import BarChart from '../../charts/components/BarChart'
import BarChartSkeletonLoader from '../../charts/components/BarChartSkeletonLoader'
import BarTooltip from '../../charts/components/BarTooltip'
import { getChartVariables } from '../../charts/selectors'
import { ChartProps, ChartType } from '../../charts/types'
import { useDashboardDataSource } from '../../dashboards/hooks'
import { transform } from '../../search/selectors/getActiveFilters'
import { useIsDashboard, useIsExaminerReport } from '../hooks'
import { useArtUnitId } from '../hooks/useArtUnitId'
import { useExaminerId } from '../hooks/useExaminerId'
import { useExaminerName } from '../hooks/useExaminerName'
import * as getExaminerOfficeActionResponseWinRates from '../queries/getExaminerOfficeActionResponseWinRates.graphql'
import ExaminerDataContainer from './ExaminerDataContainer'

type OfficeActionResponseWinRatesResponse = RecursivePartial<{
  applicationSet: {
    metrics: Array<{
      outcomeRates: {
        [Key in 'interview' | 'rce']: {
          winRate: number | null
        }
      }
    }>
  }
}>

type OfficeActionResponseWinRates = [
  { type: 'Interview' } & { [key: string]: number },
  { type: 'RCE' } & { [key: string]: number },
]

const styles = {
  container: css({
    flexGrow: 1,
    height: 0,
    minHeight: 320,
  }),
  charts: css({
    height: 350,
    minHeight: 'auto',
  }),
  dashboard: css({
    minHeight: 250,
  }),
  error: css({
    marginTop: 100,
  }),
  innerContainer: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  }),
  main: css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  }),
}

export default function ExaminerOfficeActionResponseWinRates(props: Partial<ChartProps>) {
  const isDashboard = useIsDashboard()
  const isExaminerReport = useIsExaminerReport()
  const artUnitId = useArtUnitId()
  const examinerIdParam = useExaminerId()
  const examinerId = examinerIdParam === 0 ? 1 : examinerIdParam
  const examinerName = useExaminerName()

  const {
    ref,
    dimensions: { height, width },
  } = useResizeObserver<HTMLDivElement>()

  const lookups =
    props.lookups ??
    (artUnitId
      ? [
          {
            color: colors.azure,
            entity: IntelligenceEntityType.Examiner,
            id: examinerId,
            label: examinerName ? examinerName : 'Unknown',
            type: ChartType.Compare,
          },
          {
            color: colors.confetti,
            entity: IntelligenceEntityType.ArtUnit,
            id: artUnitId ?? 1,
            label: `Current AU (${artUnitId})`,
            type: ChartType.Compare,
          },
        ]
      : [
          // Dashboard
          {
            color: colors.azure,
            entity: IntelligenceEntityType.Examiner,
            id: examinerId,
            label: examinerName === '' ? 'Unknown' : examinerName,
            type: ChartType.Compare,
          },
        ])

  const chartVariables = useSelector((state: AppState) => getChartVariables(state, { lookups }))

  const dashboardDataSource = useDashboardDataSource()
  const variables = chartVariables.map(({ filters, ...variables }) => ({
    ...variables,
    dataSource: dashboardDataSource ?? variables.dataSource,
    filters: transform({
      dispositionDate: { start: '2000-01-01' },
      ...filters,
    } as Record<string, unknown>),
  }))
  const options = lookups.map(({ color, label }, index: number) => ({
    enabled: isExaminerReport ? Boolean(artUnitId) : examinerName !== '',
    transform: (response: OfficeActionResponseWinRatesResponse) =>
      [
        {
          type: 'Interview',
          [label]: response?.applicationSet?.metrics?.[0]?.outcomeRates?.interview?.winRate ?? 0,
          [`${label}Color`]: color,
        },
        {
          type: 'RCE',
          [label]: response?.applicationSet?.metrics?.[0]?.outcomeRates?.rce?.winRate ?? 0,
          [`${label}Color`]: color,
        },
      ] as OfficeActionResponseWinRates,
    variables: variables[index],
  }))
  const lookupsHash = lookups.map(({ id, label }) => `${id}-${label}`).join('/')

  const [machine] = useMergedQueries<
    OfficeActionResponseWinRates,
    OfficeActionResponseWinRatesResponse
  >(
    `${Charts.OfficeActionResponseWinRates}/${lookupsHash}`,
    getExaminerOfficeActionResponseWinRates,
    options
  )

  const data = useMemo(() => {
    if (!machine.matches('success')) {
      return []
    }

    return machine.context.data.flat().reduce(
      (acc, item) => {
        const index = acc.findIndex((x) => x.type === item.type)

        if (index === -1) {
          acc.push(item)
        } else {
          acc[index] = { ...acc[index], ...item }
        }

        return acc
      },
      [] as Array<OfficeActionResponseWinRates[number]>
    )
  }, [machine])

  return (
    <ExaminerDataContainer
      {...props}
      chart={Charts.OfficeActionResponseWinRates}
      className={cx(styles.main, {
        [styles.charts]: !isExaminerReport,
        [styles.dashboard]: isDashboard,
      })}
      exportableConfig={{
        filename: 'office_action_response_win_rates',
        getData: () =>
          lookups.map((lookup) => [
            lookup.label,
            formatPercent(data.find((item) => item.type === 'Interview')?.[lookup.label] ?? 0),
            formatPercent(data.find((item) => item.type === 'RCE')?.[lookup.label] ?? 0),
          ]),
        getHeader: () => ['Interview', 'RCE'],
      }}
      informationText="The rates at which applicants have received an allowance, following a rejection, as a result of a request for continuing examination or interview."
      metric={ExaminerMetric.OfficeActionResponseWinRates}
      machine={machine}
      draggable={!isExaminerReport}
      title="OA Response Win Rates"
    >
      <div
        className={cx(styles.container, { [styles.dashboard]: isDashboard || !isExaminerReport })}
        ref={ref}
      >
        {isExaminerReport ? (
          artUnitId ? null : (
            <InfoText className={styles.error}>
              The examiner does not have enough applications to generate this chart
            </InfoText>
          )
        ) : null}
        {machine.matches('loading') ? <BarChartSkeletonLoader /> : null}
        {machine.matches('failure') ? <InfoText>{machine.context.error}</InfoText> : null}
        {machine.matches('success') ? (
          <div className={styles.innerContainer}>
            <BarChart
              axisLeft={{
                format: '.0%',
                tickPadding: 10,
              }}
              colorsByKey={lookups.reduce((acc, { color, label }) => {
                acc[label] = color

                return acc
              }, {})}
              data={data}
              forceLegend={!isDashboard}
              groupMode="grouped"
              height={height}
              indexBy="type"
              keys={lookups.map(({ label }) => label)}
              maxValue={1}
              // tooltipFormat=".0%"
              tooltip={(tooltipProps: BarTooltipDatum) => (
                <BarTooltip
                  {...tooltipProps}
                  tooltipFormat=".0%"
                  xLegend="OA Response"
                  yLegend="Win Rate"
                />
              )}
              width={width}
            />
          </div>
        ) : null}
      </div>
    </ExaminerDataContainer>
  )
}
