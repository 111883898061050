import { DataSource, SearchScope } from '@juristat/common/types'
import { mergeAll } from 'ramda'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { AppState } from '../../../../redux'
import { useQuery } from '../../../api'
import { useDemoModeValue } from '../../../demo'
import { getFilterMeta } from '../../../filter/selectors'
import {
  getActiveSearchId,
  getSearchDataSourceById,
  getSearchPhraseParsedById,
  getSearchScopesById,
  getSearchTypeById,
} from '../../../search/selectors'
import getActiveFilters from '../../../search/selectors/getActiveFilters'
import { SearchType } from '../../../search/types'
import * as getBreakdownResults from '../../modules/breakdown/queries/getBreakdownResults.graphql'
import { TopEntityKeyMetricsResponse } from '../../modules/breakdown/types'
import keyMetricsResponseToResults from '../../modules/breakdown/utils/keyMetricsResponseToResults'
import { Entity, EntityKeyMetrics, EntityTypeAndId, IntelligenceEntityType } from '../../types'
import { getDemoLabel, getIntrinsicFilters } from '../../utils'

type UseKeyMetricsProps = EntityTypeAndId & {
  by: IntelligenceEntityType
  selected: Entity | null
}

const isSearchSet = (entity?: IntelligenceEntityType) => entity === IntelligenceEntityType.SearchSet

const getFilterEnum = (keyMetricsBy: IntelligenceEntityType) => {
  switch (keyMetricsBy) {
    case IntelligenceEntityType.ArtUnit:
      return 'ART_UNIT'
    case IntelligenceEntityType.AssigneeAtDisposition:
      return 'ASSIGNEE_AT_DISPOSITION_ID'
    case IntelligenceEntityType.AttorneyAtDisposition:
      return 'ATTORNEY_AT_DISPOSITION_ID'
    case IntelligenceEntityType.CurrentAssignee:
      return 'CURRENT_ASSIGNEE_ID'
    case IntelligenceEntityType.CurrentAttorney:
      return 'CURRENT_ATTORNEY_ID'
    case IntelligenceEntityType.Cpc:
      return 'CPC_SHORT'
    case IntelligenceEntityType.TechCenter:
      return 'TECH_CENTER'
    case IntelligenceEntityType.Uspc:
      return 'USPC_CLASS'
    case IntelligenceEntityType.FirmAtDisposition:
      return 'FIRM_AT_DISPOSITION_ID'
    case IntelligenceEntityType.CurrentFirm:
    default:
      return 'CURRENT_FIRM_ID'
  }
}

export function useKeyMetrics({ by, entity, id, selected }: UseKeyMetricsProps, enabled = true) {
  const filterMeta = useSelector(getFilterMeta)
  const activeFilters = useSelector((state: AppState) => getActiveFilters(state, filterMeta))
  const searchId = useSelector((state: AppState) =>
    getActiveSearchId(state, { activeProp: 'results' })
  )
  const dataSource = useSelector((state: AppState) => getSearchDataSourceById(state, { searchId }))
  const phrase = useSelector((state: AppState) => getSearchPhraseParsedById(state, { searchId }))
  const scopes = useSelector((state: AppState) => getSearchScopesById(state, { searchId })) || [
    SearchScope.FullText,
  ]
  const searchType =
    useSelector((state: AppState) => getSearchTypeById(state, { searchId })) || SearchType.Keyword
  const scopesAndPhrase = scopes.reduce((acc, scope) => ({ ...acc, [scope]: phrase }), {})

  const entityIsSearchSet = isSearchSet(entity) || isSearchSet(selected?.filterKey?.entity)

  const searches =
    searchType === SearchType.Keyword &&
    (entityIsSearchSet || isSearchSet(selected?.filterKey?.entity))
      ? scopesAndPhrase
      : {}
  const similarTo =
    searchType === SearchType.SimilarTo &&
    (entityIsSearchSet || isSearchSet(selected?.filterKey?.entity))
      ? scopesAndPhrase
      : {}
  const variables = {
    entityTypes: [{ column: getFilterEnum(by) }],
    filters: mergeAll([
      getIntrinsicFilters({ entity, id }),
      selected ? getIntrinsicFilters(selected) : {},
      selected?.filterKey ? getIntrinsicFilters(selected.filterKey) : {},
      activeFilters,
    ]),
    limit: 500,
    searches,
    similarTo,
  }
  const ppair = entityIsSearchSet && dataSource === DataSource.PrivatePair
  const demoMode = useDemoModeValue()

  const transform = useCallback(
    (data: TopEntityKeyMetricsResponse['data']) =>
      keyMetricsResponseToResults({ data }, (name, id) => {
        switch (by) {
          case IntelligenceEntityType.ArtUnit:
            return `Art Unit ${name}`
          case IntelligenceEntityType.TechCenter:
            return `Tech Center ${name}`
          case IntelligenceEntityType.AssigneeAtDisposition:
          case IntelligenceEntityType.CurrentAssignee:
          case IntelligenceEntityType.CurrentFirm:
          case IntelligenceEntityType.FirmAtDisposition:
            return demoMode ? getDemoLabel({ entity: by, id, name }) : name
          default:
            return name
        }
      }).slice(0, 200),
    [by, demoMode]
  )
  const [data, { refetch, retry }] = useQuery<
    EntityKeyMetrics[],
    TopEntityKeyMetricsResponse['data']
  >(['top-x-for-y-key-metrics', demoMode], getBreakdownResults, {
    enabled,
    ppair,
    transform,
    variables,
  })

  return [data, retry, refetch] as const
}

export function usePrefetchKeyMetrics(props: UseKeyMetricsProps) {
  const [, , refetch] = useKeyMetrics(props, false)

  return refetch
}
