import { createContext } from 'react'
import { State } from 'xstate'

import { DialogueEvents, DialogueOptions } from './types'

type Actions = { confirm: () => void; dismiss: () => void }
type CreateAction = <T>(payload?: DialogueOptions<T>) => void

export const DialogueActionsContext = createContext<Actions | undefined>(undefined)
export const DialogueCreateContext = createContext<CreateAction | undefined>(undefined)

export const DialogueStateContext = createContext<
  State<DialogueOptions<unknown>, DialogueEvents> | undefined
>(undefined)
