import { css } from 'emotion'
import React from 'react'

import { colors } from '../styles'
import DataContainerTitle from './DataContainerTitle'

type DataContainerProps = {
  children?: React.ReactNode
  className?: string
  headerContent?: React.ReactNode
  title: React.ReactNode
  tw?: boolean
}

const styles = {
  container: css({
    border: `1px solid ${colors.silver2}`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
  }),
}

const DataContainer: React.FC<DataContainerProps> = ({
  children,
  className,
  headerContent,
  title,
  tw = false,
}) => {
  return (
    <div
      className={
        tw
          ? `border-silver2 flex flex-col rounded border px-[30px] py-5 ${className ?? ''}`
          : css(styles.container, className)
      }
    >
      <DataContainerTitle children={headerContent} title={title} />
      {children}
    </div>
  )
}

export { DataContainerProps }
export default DataContainer
