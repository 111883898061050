import cuid from 'cuid'

import { Notification, NotificationTypes } from '../types'

const makeNotification = (params: Partial<Notification> = {}) => ({
  dismissable: true,
  id: cuid(),
  message: '',
  type: NotificationTypes.Info,
  ...params,
})

export default makeNotification
