import { pathOr } from 'ramda'

import { ActiveReducer } from '../../filter/types'

const defaultTechCenters = [1600, 1700, 2100, 2400, 2600, 2800, 2900, 3600, 3700]

const defaultTechCentersFilter = (filters: ActiveReducer) => ({
  ...filters,
  techCenter: pathOr(defaultTechCenters, ['techCenter'], filters),
})

export default defaultTechCentersFilter
