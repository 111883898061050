import * as React from 'react'

import { Access } from '../types'
import HasAccess from './HasAccess'

type CheckPermissionProps = {
  canAccess: Access
  renderNoAccess?: () => JSX.Element | null
  renderWithAccess: () => JSX.Element | null
}

type RenderProps = {
  hasAccess: boolean
}

const makeRender =
  ({ renderNoAccess = () => null, renderWithAccess }: Omit<CheckPermissionProps, 'canAccess'>) =>
  ({ hasAccess }: RenderProps) =>
    hasAccess ? renderWithAccess() : renderNoAccess()

const CheckPermission: React.FC<CheckPermissionProps> = ({ canAccess, ...rest }) => (
  <HasAccess canAccess={canAccess} render={makeRender(rest)} />
)

export default CheckPermission
