import { DataSource } from '@juristat/common/types'
import qs from 'qs'
import { delay } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { graphqlApi, ppairApi } from '../../api'
import { actions as downloadActions } from '../../download'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import * as getExportStatusQuery from '../queries/getExportStatus.graphql'
import {
  ExportStatus,
  ExportStatusGraphQLResponse,
  ExportStatusType,
  ExportsPushAction,
} from '../types'

function* getStatus(jobId: string, dataSource: DataSource, timeout: number) {
  yield call(delay, timeout)

  try {
    const api = dataSource === DataSource.PublicPair ? graphqlApi : ppairApi
    const response = yield call(api, getExportStatusQuery, { jobId })

    if (response.ok) {
      const {
        data: { exportStatus },
      }: ExportStatusGraphQLResponse<ExportStatus> = yield call([response, 'json'])

      return exportStatus
    } else {
      return { status: ExportStatusType.Error }
    }
  } catch {
    return { status: ExportStatusType.Error }
  }
}

function* trackExportJob({ meta, payload }: ExportsPushAction) {
  const { dataSource, jobId } = payload!
  const { exportName = '', timeout = 1000, skipNotification = false } = meta ?? {}

  if (!skipNotification) {
    yield put(
      notificationActions.push(
        makeNotification({
          id: jobId,
          message: 'Exporting...',
          progress: 0,
          timeout: 0,
        })
      )
    )
  }

  while (true) {
    const exportStatus: ExportStatus = yield call(getStatus, jobId, dataSource, timeout)

    yield put(actions.update({ jobId, status: { ...exportStatus, lastUpdated: Date.now() } }))

    if (exportStatus.status === ExportStatusType.Complete) {
      // API gives us link to download page so we need to pull out file/filename from query params
      // if we want to download from here
      const { file, filename = 'export.csv' } = qs.parse(exportStatus.downloadUrl.split('?')[1])

      if (typeof file !== 'string' || typeof filename !== 'string') {
        break
      }

      yield put(notificationActions.pop(jobId))
      yield put(downloadActions.downloadFile({ name: filename, url: file }))

      break
    }

    if (exportStatus.status === ExportStatusType.Error) {
      yield put(notificationActions.pop(jobId))
      yield put(
        notificationActions.push(
          makeNotification({
            message: `Your export ${
              exportName ? `of ${exportName} ` : ''
            }encountered an error.  Please try again.`,
            type: NotificationTypes.Error,
          })
        )
      )

      break
    }

    if (exportStatus.status === ExportStatusType.Running) {
      yield put(
        notificationActions.update({
          id: jobId,
          progress: (exportStatus.progress || 0) / 100,
          timeout: 0,
        })
      )
    }
  }
}

function* watchTrackExportJobs() {
  yield takeEvery(actions.push().type, trackExportJob)
}

export { getStatus, trackExportJob }
export default watchTrackExportJobs
