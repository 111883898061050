import { combineReducers } from 'redux'

import { AppState } from '.'
import account from '../modules/account-tools/reducer'
import auth from '../modules/auth/reducer'
import dashboard from '../modules/dashboards/reducer'
import dateRange from '../modules/dateRange/reducer'
import dialogue from '../modules/dialogue/reducer'
import drafting from '../modules/drafting/reducer'
import exportsReducer from '../modules/exports/reducer'
import home from '../modules/home/reducer'
import intelligence from '../modules/intelligence/reducer'
import navigation from '../modules/navigation/reducer'
import notification from '../modules/notification/reducer'
import portfolio from '../modules/portfolio/reducer'
import router from '../modules/router/reducer'
import search from '../modules/search/reducer'
import session from '../modules/session/reducer'
import sponsorships from '../modules/sponsorships/reducer'
import searchHistory from '../modules/userSearches/reducer'

const reducers = combineReducers<AppState>({
  account,
  auth,
  dashboard,
  dateRange,
  dialogue,
  drafting,
  exports: exportsReducer,
  home,
  intelligence,
  navigation,
  notification,
  portfolio,
  router,
  search,
  searchHistory,
  session,
  sponsorships,
})

export default reducers
