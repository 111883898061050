import * as React from 'react'

import SVG from './SVG'
import CheckCircleSolid from './solid/CheckCircleSolid'
import { SolidProps } from './types'

const CheckCircle = ({ solid, ...props }: SolidProps) => {
  if (solid === true) {
    return <CheckCircleSolid {...props} />
  }

  return (
    <SVG title="check-circle" viewBox="6 6 24 24" {...props}>
      <path d="M18,6A12,12,0,1,0,30,18,12,12,0,0,0,18,6Zm0,22A10,10,0,1,1,28,18,10,10,0,0,1,18,28Z" />
      <path d="M16.34,23.74l-5-5a1,1,0,0,1,1.41-1.41l3.59,3.59,6.78-6.78a1,1,0,0,1,1.41,1.41Z" />
    </SVG>
  )
}

export default CheckCircle
