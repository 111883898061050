import { call, put, takeLatest } from 'redux-saga/effects'

import { ppairApi } from '../../api'
import { HttpStatus } from '../../http/types'
import actions from '../actions'
import * as query from '../queries/getSponsorships.graphql'
import { SponsorshipsStatus } from '../types'

const errorAction = actions.setSponsorships({
  message: 'Error fetching customer whitelist data',
  type: SponsorshipsStatus.ErrorFetching,
})

function* fetchCustomerWhitelistData() {
  yield put(actions.setSponsorships({ type: HttpStatus.Fetching }))

  try {
    const response = yield call(ppairApi, query)

    if (response.ok) {
      const { data } = yield call([response, 'json'])
      yield put(actions.setSponsorships({ data: data.sponsorships, type: HttpStatus.Success }))
    } else {
      yield put(errorAction)
    }
  } catch {
    yield put(errorAction)
  }
}

function* watchFetchCustomerWhitelistData() {
  yield takeLatest(actions.fetchSponsorships().type, fetchCustomerWhitelistData)
}

export { errorAction, fetchCustomerWhitelistData }
export default watchFetchCustomerWhitelistData
