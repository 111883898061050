import { equals } from 'ramda'
import { createSelector } from 'reselect'

import { getFilterStateActive, getFilterStateSelected } from '.'

const isApplyAllEnabled = createSelector(
  getFilterStateActive,
  getFilterStateSelected,
  (active, selected) => !equals(active, selected)
)

export default isApplyAllEnabled
