import { formatDate } from '@juristat/common/utils'

const isDateValid = <T extends Date | string | null | undefined>(
  date: T
): date is NonNullable<T> =>
  date instanceof Date
    ? formatDate(date).split('-').length > 1
    : typeof date === 'string'
    ? date.length > 3 && formatDate(date).split('-').length > 1
    : false

export default isDateValid
