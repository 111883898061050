import { pathOr, all as ramdaAll } from 'ramda'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { api, graphqlApi } from '../../api'
import { HttpStatus } from '../../http'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import * as getEntitiesTitleQuery from '../queries/getEntitiesTitle.graphql'
import { getClassificationKeys } from '../selectors'
import {
  Classification,
  GetEntitiesTitleResponse,
  GetKeyMetricsAction,
  KeyMetricsResponse,
  KeyMetricsResponseFields,
} from '../types'
import {
  filterKeyMap,
  filterValuesMap,
  groupsKeyMap,
  metricsKeyMap,
  urlMap,
} from '../utils/classificationMaps'
import transformKeyMetricsResponse from '../utils/transformKeyMetricsResponse'
import { DRAFTING_URL } from './constants'

function* handleError(classification: Classification) {
  yield put(
    notificationActions.push(
      makeNotification({
        message: 'Error fetching key metrics',
        type: NotificationTypes.Error,
      })
    )
  )

  yield put(
    actions.setKeyMetricsState(
      {
        message: 'Error fetching key metrics',
        type: HttpStatus.Error,
      },
      classification
    )
  )
}

function* getKeyMetrics(action: GetKeyMetricsAction) {
  const classification = action.payload!

  yield put(actions.setKeyMetricsState({ type: HttpStatus.Fetching }, classification))

  try {
    const urlSegment = urlMap[classification]
    const filterKey = filterKeyMap[classification]
    const filterMap = filterValuesMap[classification]
    const groupKey = groupsKeyMap[classification]
    const metricsKey = metricsKeyMap[classification]
    const filterData: string[] = yield select(getClassificationKeys, classification)
    const filters = { [filterKey]: filterData.map(filterMap) }

    const responses: Array<ApiResponse<unknown>> = yield all([
      call(api, `${DRAFTING_URL}/key-metrics/${urlSegment}`),
      call(graphqlApi, getEntitiesTitleQuery, { filters }),
    ])

    if (!ramdaAll((response: ApiResponse<unknown>) => response.ok, responses)) {
      return yield fork(handleError, classification)
    }

    const [metrics, titles]: [KeyMetricsResponse, GetEntitiesTitleResponse] = yield all(
      responses.map((response) => call([response, 'json']))
    )

    const ids = Object.keys(metrics[metricsKey]).reduce<Record<string, unknown>>((acc, item) => {
      const key = pathOr('', [metricsKey, item, 'groups', groupKey], metrics)

      return key
        ? {
            ...acc,
            [key]: metrics[metricsKey][item],
          }
        : acc
    }, {})
    const filtered = filterData.map((key) => ids[key]) as KeyMetricsResponseFields[]

    const data = transformKeyMetricsResponse(filtered, titles, classification)

    yield put(actions.setKeyMetricsState({ data, type: HttpStatus.Success }, classification))
  } catch {
    yield fork(handleError, classification)
  }
}

function* watchGetKeyMetrics() {
  yield takeLatest(actions.getKeyMetrics().type, getKeyMetrics)
}

export { getKeyMetrics, handleError }
export default watchGetKeyMetrics
