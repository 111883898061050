import { css, cx } from 'emotion'
import React from 'react'

import { useEllipsis } from '../hooks'
import { textStyles } from '../styles'

type DataContainerTitleProps = {
  children?: React.ReactNode
  className?: string
  title: React.ReactNode
}

const styles = {
  container: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  }),
  header: css(textStyles.darkBold16),
}

export default function DataContainerTitle({
  children,
  className,
  title,
}: DataContainerTitleProps) {
  const titleIsString = typeof title === 'string'
  const ellipsisRef = useEllipsis<HTMLHeadingElement>(titleIsString ? (title as string) : '')

  return (
    <div className={cx(styles.container, className)}>
      {titleIsString ? (
        <h2 className={styles.header} ref={ellipsisRef} />
      ) : (
        <h2 className={styles.header}>{title}</h2>
      )}
      {children}
    </div>
  )
}
