import { eqBy, prop, unionWith } from 'ramda'

import { ApplicationStatusEnum, AppsAndName } from '../types'

const applicationStatusLookup: { [key: string]: string } = {
  [ApplicationStatusEnum.Abandoned]: 'Abandoned',
  [ApplicationStatusEnum.Allowed]: 'Allowed',
  [ApplicationStatusEnum.Disposed]: 'Disposed',
  [ApplicationStatusEnum.Issued]: 'Issued',
  [ApplicationStatusEnum.Pending]: 'Pending',
}

const defaults = [
  ApplicationStatusEnum.Abandoned,
  ApplicationStatusEnum.Allowed,
  ApplicationStatusEnum.Disposed,
  ApplicationStatusEnum.Issued,
  ApplicationStatusEnum.Pending,
].map((applicationStatus: ApplicationStatusEnum) => ({
  apps: 0,
  label: applicationStatusLookup[applicationStatus],
  value: applicationStatus,
}))

const transformApplicationStatuses = (applicationStatuses: AppsAndName[]) =>
  unionWith(
    eqBy(prop<'value', string>('value')),
    applicationStatuses.map((applicationStatus) => ({
      apps: applicationStatus.apps,
      label: applicationStatusLookup[applicationStatus.name.toUpperCase()],
      value: applicationStatus.name.toUpperCase(),
    })),
    defaults
  )

export { applicationStatusLookup }
export default transformApplicationStatuses
