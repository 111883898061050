import LogRocket from 'logrocket'
import { contains } from 'ramda'
import * as React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as ReactDOM from 'react-dom'
import 'web-animations-js'

import Root from './components/Root'
import './styles/global'
import './styles/tailwind.css'

window.loadIntercom?.(process.env.INTERCOM_ID!)

if (process.env.NODE_ENV === 'production') {
  // Only run logrocket in a non-local enviroment
  LogRocket.init(process.env.LOGROCKET_ID!, {
    network: {
      requestSanitizer: (request: { url: string; body: Record<string, unknown> | string }) => {
        if (contains('co/authenticate', request.url)) {
          request.body = 'Sensitive information not shown'
        }
        return request
      },
    },
    release: process.env.PRODUCT_INFO,
  })
}

ReactDOM.render(<Root />, document.getElementById('root'))
