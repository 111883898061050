import { call, put, takeLatest } from 'redux-saga/effects'

import { appApi } from '../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { SaveAction } from '../types'

function* createPortfolio(action: SaveAction) {
  const notificationAction = notificationActions.push(
    makeNotification({
      message: 'Failed to create portfolio.',
      type: NotificationTypes.Error,
    })
  )
  const { name } = action.payload!
  const payload = { name, uids: [] }
  const options = {
    body: JSON.stringify(payload),
    headers: { 'content-type': 'application/json' },
    method: 'POST',
  }

  try {
    const response = yield call(appApi, '/reports/portfolio', options)

    if (response.ok) {
      yield put(actions.saved())
    } else {
      yield put(notificationAction)
      yield put(actions.editing())
    }
  } catch {
    yield put(notificationAction)
    yield put(actions.editing())
  }
}

function* watchCreatePortfolio() {
  yield takeLatest([actions.save().type], createPortfolio)
}

export { createPortfolio }
export default watchCreatePortfolio
