import { isEmail } from '@juristat/common/utils'
import config from '@juristat/config'
import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { AuthMethod, AuthMethodResponse, AuthProvider, GetAuthMethodsAction } from '../types'

const transformResponseToAuthMethod = (responseItem: AuthMethodResponse): AuthMethod => ({
  active: !!responseItem.active,
  authProviderDescription: responseItem.auth_provider_description ?? '',
  authProviderKey: responseItem.auth_provider_key,
  authProviderName: responseItem.auth_provider_name as AuthProvider,
  emailVerificationRequired: !!responseItem.email_verification_required,
  id: responseItem.id,
})

function* getAvailableAuthMethods(action: GetAuthMethodsAction) {
  const { activeOnly = true, username } = action.payload!
  if (!isEmail(username)) {
    yield put(actions.getAuthMethodsError())
    return
  }

  try {
    const url = `${config.accountsUrl}/auth/auth-providers?username=${encodeURIComponent(
      username
    )}&activeonly=${activeOnly}`

    const response = yield call(api, url, { noJwt: true, url })

    if (response.ok) {
      const methods = yield call([response, 'json']) || []
      const availableAuthMethods = methods.map(transformResponseToAuthMethod)

      yield put(actions.saveUsernameToLocalStorage(username)) // we know they have auth methods, so they're legit
      yield put(actions.setAuthMethods(availableAuthMethods))
    } else {
      yield put(actions.getAuthMethodsError())
    }
  } catch (e) {
    // an exception here means that we couldn't verify the username so set empty auth methods and show error
    // don't put getAuthMethodsError here because that means that the username has been checked and is invalid
    yield put(actions.setAuthMethods())
    yield put(
      notificationActions.push(
        makeNotification({
          message: 'Unable to verify username. Please check your internet connection.',
          timeout: 0,
          type: NotificationTypes.Error,
        })
      )
    )
  }
}

export { getAvailableAuthMethods }
export default function* watchGetAvailableAuthMethods() {
  yield takeLatest(actions.getAuthMethods().type, getAvailableAuthMethods)
}
