import * as React from 'react'

import SVG from '../SVG'
import { IconProps } from '../types'

const InfoStandardSolid = (props: IconProps) => (
  <SVG title="info-standard-solid" {...props}>
    <path d="M18,2.1a16,16,0,1,0,16,16A16,16,0,0,0,18,2.1Zm-.1,5.28a2,2,0,1,1-2,2A2,2,0,0,1,17.9,7.38Zm3.6,21.25h-7a1.4,1.4,0,1,1,0-2.8h2.1v-9.2H15a1.4,1.4,0,1,1,0-2.8h4.4v12h2.1a1.4,1.4,0,1,1,0,2.8Z" />
  </SVG>
)

export default InfoStandardSolid
