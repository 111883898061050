import { css } from 'emotion'
import * as React from 'react'
import ContentLoader from 'react-content-loader'

type LineChartSkeletonLoaderProps = {
  animate?: boolean
}

const styles = {
  container: css({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  }),
  main: css({
    height: '100%',
    width: '100%',
  }),
}

const makeLineRect = (x: number, y: number, height: number, degrees: number) => (
  <rect
    x={x}
    y={y}
    rx="0"
    ry="0"
    width="5"
    height={height}
    transform={`rotate(${degrees}, ${x}, ${y})`}
  />
)

const LineChartSkeletonLoader = ({ animate = true }: LineChartSkeletonLoaderProps) => (
  <div className={styles.container} data-testid="line-chart-skeleton-loader">
    <ContentLoader
      animate={animate}
      className={css(styles.main)}
      height={625}
      width={1600}
      preserveAspectRatio="xMidYMid meet"
    >
      {makeLineRect(250, 400, 250, 43)}
      {makeLineRect(250, 400, 250, -70)}
      {makeLineRect(487, 489, 320, -150)}
      {makeLineRect(645, 210, 300, -100)}
      {makeLineRect(938, 158, 200, -120)}
      <circle cx={250} cy={400} r={15} />
      <circle cx={487} cy={480} r={15} />
      <circle cx={645} cy={215} r={15} />
      <circle cx={938} cy={158} r={15} />
      <rect x="1250" y="225" rx="0" ry="0" width="200" height="25" />
      <rect x="1250" y="270" rx="0" ry="0" width="215" height="25" />
      <rect x="1250" y="315" rx="0" ry="0" width="150" height="25" />
      <rect x="1250" y="360" rx="0" ry="0" width="150" height="25" />
    </ContentLoader>
  </div>
)

export default LineChartSkeletonLoader
