import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const USquare = (props: IconProps) => (
  <SVG title="u-square" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1H14C15.6569 1 17 2.34315 17 4V14C17 15.6569 15.6569 17 14 17H4C2.34315 17 1 15.6569 1 14V4C1 2.34315 2.34315 1 4 1ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4ZM7.10742 10.5562V6.04297H6.04102V10.5942C6.04102 11.4787 6.25049 12.1219 6.66943 12.5239C7.09261 12.9259 7.72738 13.127 8.57373 13.127C9.06462 13.127 9.50049 13.036 9.88135 12.854C10.2664 12.672 10.5669 12.4097 10.7827 12.0669H10.8398L10.9922 13H11.8618V6.04297H10.8081V9.69922C10.8081 10.6006 10.6388 11.2502 10.3003 11.6479C9.96598 12.0457 9.4349 12.2446 8.70703 12.2446C8.1569 12.2446 7.75277 12.105 7.49463 11.8257C7.23649 11.5464 7.10742 11.1232 7.10742 10.5562Z"
    />
  </SVG>
)

export default USquare
