import React from 'react'

import NavigationOmnisearch from '../modules/omnisearch/components/NavigationOmnisearch'

type HeaderProps = Partial<{
  initialValue: string
}>

const styles = {
  container: 'border-b border-silver2 h-[60px] min-h-[60px] relative',
  search: 'left-1/2 !absolute top-[10px] z-[97] -translate-x-[50%]',
}

const Header: React.FC<HeaderProps> = ({ initialValue }) => (
  <header className={styles.container}>
    <NavigationOmnisearch className={styles.search} initialValue={initialValue} tw={true} />
  </header>
)

export default Header
