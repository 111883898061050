import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Minus = (props: IconProps) => (
  <SVG title="minus" {...props}>
    <path d="M26,17H10a1,1,0,0,0,0,2H26a1,1,0,0,0,0-2Z" />
  </SVG>
)

export default Minus
