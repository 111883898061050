import { createBrowserHistory } from 'history'
import * as Logrocket from 'logrocket'
import { Middleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { Action } from '.'
import { AppState } from '.'
import authActions from '../modules/auth/actions'
import { createMiddleware } from '../modules/router'
import sagas from './sagas'

const history = createBrowserHistory()
const routerMiddleware = createMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
// Not sure why we need `as any` now
const runSagas = () => sagaMiddleware.run(sagas as any)

const makeMiddleware = (): Middleware[] => {
  const middleware: Middleware[] = [
    routerMiddleware,
    sagaMiddleware,
    process.env.NODE_ENV !== 'test' &&
      Logrocket.reduxMiddleware({
        actionSanitizer: (action: Action<any, any>) => {
          if (action.type === authActions.setAccessToken().type) {
            return {
              ...action,
              payload: 'Sensitive information not shown',
            }
          }
          return action
        },
        stateSanitizer: (state: AppState) => {
          return {
            ...state,
            auth: {
              ...state.auth,
              accessToken: 'Sensitive information not shown',
            },
          }
        },
      }),
  ].filter(Boolean)

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return [...middleware, require('redux-logger').createLogger({ collapsed: true, diff: true })]
  }

  return middleware
}

export { history, runSagas }
export default makeMiddleware()
