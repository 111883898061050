import { ClassificationState, ClassifyResponse } from '../types'

const transformCitedArtResponse = (
  citedArtResponse: ClassifyResponse['cited_art']
): ClassificationState['citedArt'] => {
  if (!Array.isArray(citedArtResponse)) {
    return []
  }

  return citedArtResponse.map((art) => ({
    appno: art.appno,
    assignee: {
      id: art.assignment?.assignee?.entity_id,
      name: art.assignment?.assignee?.name,
    },
    citationCount: art.citeCount ?? 0,
    title: art.title,
  }))
}

export default transformCitedArtResponse
