import { css } from 'emotion'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../../redux'
import actions from '../actions'
import FilterContext from '../context/filterContext'
import { areActionButtonsDisabled } from '../selectors'
import { Filter } from '../types'
import ApplyButton from './ApplyButton'
import ClearButton from './ClearButton'

type ActionButtonsProps = {
  filter: Filter
}

const styles = {
  container: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
  }),
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ filter }) => {
  const { meta } = useContext(FilterContext)
  const dispatch = useDispatch()
  const disabled = useSelector((state: AppState) =>
    areActionButtonsDisabled(state, { filter, ...meta })
  )

  return (
    <div className={styles.container}>
      <ApplyButton
        active={!disabled.apply}
        handleClick={() => dispatch(actions.apply(filter, meta))}
      />
      <ClearButton
        active={!disabled.clear}
        handleClick={() => dispatch(actions.clear(filter, meta))}
      />
    </div>
  )
}

export default ActionButtons
