import { path } from 'ramda'
import { ParametricSelector, createSelector } from 'reselect'

import { AppState } from '../../../redux'
import { FilterMeta, FilterReportType } from '../../filter/types'
import { HttpStatus } from '../../http/types'
import { IntelligenceReducerState } from '../types'

const defaultStatus = { type: HttpStatus.Fetching }

const getEntityType: ParametricSelector<AppState, Partial<FilterMeta>, FilterMeta['entity']> = (
  _,
  { entity }
) => entity

const getId: ParametricSelector<AppState, Partial<FilterMeta>, FilterMeta['id']> = (_, { id }) => id

const getIntelligenceReport: ParametricSelector<
  AppState,
  Partial<FilterMeta>,
  FilterMeta['report'] | undefined
> = (_, { report }) => report

const getIntelligenceState = (state: AppState) => state.intelligence

const getIntelligenceKeyedData = createSelector(
  getIntelligenceState,
  getEntityType,
  getId,
  (state, entityType, id) => path([entityType!, id!], state) as IntelligenceReducerState
)

const getIntelligenceEntityDetails = createSelector(
  getIntelligenceKeyedData,
  (state) => (state ? state.details : defaultStatus) as IntelligenceReducerState['details']
)

const getIntelligenceEntityName = createSelector(
  getIntelligenceKeyedData,
  (state) => (state ? state.name : defaultStatus) as IntelligenceReducerState['name']
)

const getIntelligenceFilters = createSelector(
  getIntelligenceKeyedData,
  getIntelligenceReport,
  (state, report) => {
    if (!state) {
      return undefined
    }

    return report === FilterReportType.IntelligenceCompare
      ? state.compare.filters
      : state.breakdown.filters
  }
)

const getIntelligenceEntityDetailsWithName = createSelector(
  getIntelligenceEntityDetails,
  getIntelligenceEntityName,
  (details, name) => {
    if (
      !details ||
      !name ||
      details.type !== HttpStatus.Success ||
      name.type !== HttpStatus.Success
    ) {
      return null
    }
    return {
      ...details.data,
      name: name.data,
    }
  }
)

export {
  getEntityType,
  getId,
  getIntelligenceEntityDetails,
  getIntelligenceEntityDetailsWithName,
  getIntelligenceEntityName,
  getIntelligenceFilters,
  getIntelligenceKeyedData,
}
