import * as React from 'react'

import SVG from './SVG'
import CogSolid from './solid/CogSolid'
import { SolidProps } from './types'

const Cog = ({ solid, ...props }: SolidProps) => {
  if (solid) {
    return <CogSolid {...props} />
  }
  return (
    <SVG title="cog" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        d="M17.686 15.245a.658.658 0 0 1-.904.244l-1.837-1.07a6.589 6.589 0 0 1-3.623 2.114v1.468a.663.663 0 0 1-.66.666H9.338a.663.663 0 0 1-.661-.666v-1.468a6.589 6.589 0 0 1-3.624-2.114l-1.836 1.07a.658.658 0 0 1-.904-.244l-.661-1.155a.669.669 0 0 1 .242-.91l1.844-1.075A6.68 6.68 0 0 1 3.389 10c0-.737.13-1.441.35-2.104L1.895 6.822a.67.67 0 0 1-.242-.911l.661-1.155a.659.659 0 0 1 .904-.244l1.836 1.07a6.589 6.589 0 0 1 3.624-2.115V2c0-.369.295-.667.66-.667h1.323c.366 0 .661.298.661.667v1.467a6.589 6.589 0 0 1 3.623 2.115l1.837-1.07a.659.659 0 0 1 .904.244l.66 1.155a.67.67 0 0 1-.241.91L16.26 7.897c.22.663.35 1.367.35 2.104a6.68 6.68 0 0 1-.35 2.105l1.844 1.075a.669.669 0 0 1 .242.91l-.661 1.155zm.104-3.789c.087-.473.144-.956.144-1.456 0-.498-.057-.982-.144-1.456l1.549-.901a1.339 1.339 0 0 0 .483-1.821L18.5 3.512a1.316 1.316 0 0 0-1.806-.488l-1.565.91a7.925 7.925 0 0 0-2.484-1.47v-1.13C12.645.596 12.053 0 11.322 0H8.678c-.73 0-1.323.597-1.323 1.333v1.132a7.925 7.925 0 0 0-2.485 1.47l-1.564-.91a1.316 1.316 0 0 0-1.806.487L.178 5.822A1.34 1.34 0 0 0 .66 7.643l1.549.901A8.057 8.057 0 0 0 2.066 10c0 .5.057.983.144 1.456l-1.55.902a1.34 1.34 0 0 0-.482 1.821L1.5 16.49a1.316 1.316 0 0 0 1.806.488l1.564-.91a7.913 7.913 0 0 0 2.485 1.469v1.131c0 .736.592 1.334 1.323 1.334h2.644c.73 0 1.323-.598 1.323-1.334v-1.131a7.913 7.913 0 0 0 2.484-1.47l1.565.91c.632.37 1.442.15 1.806-.487l1.322-2.31a1.339 1.339 0 0 0-.483-1.821l-1.549-.902zM8.017 10c0-1.104.888-2 1.983-2 1.095 0 1.983.896 1.983 2 0 1.105-.888 2-1.983 2a1.992 1.992 0 0 1-1.983-2zm-1.323 0c0 1.842 1.48 3.334 3.306 3.334s3.306-1.492 3.306-3.334c0-1.841-1.48-3.333-3.306-3.333S6.694 8.159 6.694 10z"
      />
    </SVG>
  )
}

export default Cog
