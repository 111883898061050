import { css } from 'emotion'
import * as React from 'react'
import ContentLoader from 'react-content-loader'

type BarChartSkeletonLoaderProps = {
  animate?: boolean
}

const styles = {
  container: css({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  }),
  main: css({
    height: '100%',
    width: '100%',
  }),
}

const BarChartSkeletonLoader = ({ animate = true }: BarChartSkeletonLoaderProps) => (
  <div className={styles.container} data-testid="bar-chart-skeleton-loader">
    <ContentLoader animate={animate} className={css(styles.main)} height={625} width={1600}>
      <rect x="50" y="200" rx="0" ry="0" width="175" height="375" />
      <rect x="250" y="300" rx="0" ry="0" width="175" height="275" />
      <rect x="450" y="250" rx="0" ry="0" width="175" height="325" />
      <rect x="650" y="150" rx="0" ry="0" width="175" height="425" />
      <rect x="850" y="260" rx="0" ry="0" width="175" height="315" />
      <rect x="1100" y="225" rx="0" ry="0" width="200" height="25" />
      <rect x="1100" y="270" rx="0" ry="0" width="215" height="25" />
      <rect x="1100" y="315" rx="0" ry="0" width="150" height="25" />
      <rect x="1100" y="360" rx="0" ry="0" width="150" height="25" />
    </ContentLoader>
  </div>
)

export default BarChartSkeletonLoader
