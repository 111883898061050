import { CSSObject } from 'create-emotion'

import { azure } from './colors'
import { azureBold13, whiteBold13 } from './text'

const blueOutlineWithIcon: CSSObject = {
  ...azureBold13,
  '& > svg': {
    fill: 'currentColor',
    marginLeft: 12,
  },
  '&:disabled': {
    opacity: 0.6,
  },
  alignItems: 'center',
  border: `1px solid ${azure}`,
  borderRadius: 4,
  color: azure,
  display: 'flex',
  height: 36,
  justifyContent: 'space-between',
  padding: '0 20px',
  textTransform: 'uppercase',
}

const primaryBlue: CSSObject = {
  ...whiteBold13,
  '& > svg': {
    fill: 'currentColor',
    marginLeft: 12,
  },
  '&:disabled': {
    opacity: 0.6,
  },
  backgroundColor: azure,
  borderRadius: 4,
  height: 32,
  padding: '0 20px',
  textTransform: 'uppercase',
}

const tailWindButton: string =
  '[&::-moz-focus-inner]:border-0 disabled:cursor-not-allowed items-center bg-none cursor-pointer flex justify-center outline-none padding-0 select-none text-s font-opensans font-bold'

const tailWindBlueOutlineWithIcon: string = `${tailWindButton} [&_>_svg]:fill-current [&_>_svg]:ml-3 [&:disabled]:opacity-60 border border-sky-500 text-sky-500 flex justify-between rounded h-8 py-0 px-5 uppercase`
const tailWindPrimaryBlue: string = `${tailWindButton} [&_>_svg]:fill-current [&:disabled]:opacity-60 bg-azure text-white rounded h-8 py-0 px-5 uppercase`
const tailWindGray = `${tailWindButton} items-center bg-pale-gray border-none flex justify-center text-center font-opensans text-s rounded min-w-[95px] py-[7px] px-5 !text-[#303741]`
const tailWindGreen = `${tailWindButton} items-center bg-apple-green border-none flex justify-center text-center font-opensans text-s font-bold rounded min-w-[95px] py-[7px] px-5 text-pale-gray2`

export {
  blueOutlineWithIcon,
  primaryBlue,
  tailWindButton,
  tailWindBlueOutlineWithIcon,
  tailWindGray,
  tailWindGreen,
  tailWindPrimaryBlue,
}
