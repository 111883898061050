import * as fuzzysort from 'fuzzysort'
import { pluck } from 'ramda'
import { useMemo } from 'react'

type UseFuzzySortProps<T> = {
  startsWith?: boolean
  haystack: T[]
  keys: string[]
  needle: string
}

export function useFuzzySort<T>({
  startsWith = false,
  haystack,
  keys,
  needle,
}: UseFuzzySortProps<T>): T[] {
  const sortResult = fuzzysort.go(needle, haystack, { keys })
  let result = useMemo(
    () => (needle === '' ? haystack : pluck('obj', sortResult)),
    [JSON.stringify(haystack), JSON.stringify(keys), needle]
  )
  if (startsWith && needle && keys.length === 1) {
    result = result.filter((item) => item[keys[0]].toLowerCase()?.startsWith(needle.toLowerCase()))
  }
  return result
}
