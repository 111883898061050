import { Reducer } from 'redux'

import { FilterReportType } from '../../filter/types'
import { ReducerActions } from '../types'

const createUidReducer =
  (report: FilterReportType): Reducer<string> =>
  (state = '', action: ReducerActions) => {
    switch (action.type) {
      case 'intelligence/SET_UID':
        return action.meta!.report === report ? action.payload! : state
      default:
        return state
    }
  }

export default createUidReducer
