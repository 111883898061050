import { Filter, Key } from '@juristat/common/types'
import * as React from 'react'

import TagFilterContainer from './TagFilterContainer'

const AttorneyDocketNumberTagFilterContainer = () => (
  <TagFilterContainer
    addKeys={[Key.Enter, Key.Tab]}
    filter={Filter.AttorneyDocketNumber}
    pasteSplit={(data: string) => [data]} // don't split
    title="Attorney Docket Number"
    placeholder="Enter an Attorney Docket #..."
  />
)

export default AttorneyDocketNumberTagFilterContainer
