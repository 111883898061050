import { createSelector } from 'reselect'

import { AppState } from '../../../redux'

const getHomeState = (state: AppState) => state.home

const getNewsState = createSelector(getHomeState, (state) => state.news)
const getTopAssignees = createSelector(getHomeState, (state) => state.topAssignees)
const getTopFirms = createSelector(getHomeState, (state) => state.topFirms)
const getMyPortfolios = createSelector(getHomeState, (state) => state.portfolios)

export { getMyPortfolios, getNewsState, getTopAssignees, getTopFirms }
