import { Key, createContext } from 'react'

import { FetchTypeState } from '../api'
import { GridItem, IntelligenceReport } from './types'

type UpdateAction<T> = (payload: T) => void

export const GridItemsItemsContext = createContext<GridItem[] | undefined>(undefined)
export const GridItemsKeyContext = createContext<string | undefined>(undefined)
export const GridItemsStateContext = createContext<
  FetchTypeState<IntelligenceReport | null, IntelligenceReport> | undefined
>(undefined)
export const GridItemsUpdateContext = createContext<UpdateAction<GridItem[]> | undefined>(undefined)

export const MuuriActiveItemKeyContext = createContext<Key | null | undefined>(undefined)
export const MuuriActiveItemUpdateContext = createContext<UpdateAction<Key | null> | undefined>(
  undefined
)
