import { mergeDeepLeft, without } from 'ramda'

import { Classification, ClassificationState, ClassifyResponse } from '../types'

const classificationToKeyMap = {
  [Classification.ArtUnit]: 'art_unit',
  [Classification.Cpc]: 'cpc',
  [Classification.TechCenter]: 'tech_center',
  [Classification.Uspc]: 'uspc',
} as const

const transformTerms = (classification: Classification, response: ClassifyResponse['terms']) =>
  Object.keys(response)
    .map((term) => {
      const classificationKey = classificationToKeyMap[classification]
      const data = response[term][classificationKey]
      const dataKeys: string[] = without(['????', 'toJSON'], Object.keys(data))

      return dataKeys.map((key) => ({ key, term, value: data[key] }))
    })
    .reduce((acc, item) => {
      const data = item.reduce(
        (inner, { key, term, value }) => ({
          ...inner,
          [key]: { [term]: value },
        }),
        {}
      )

      return mergeDeepLeft(acc, data)
    }, {})

const transformTermsResponse = (
  response: ClassifyResponse['terms']
): ClassificationState['keywords'] => {
  return {
    [Classification.ArtUnit]: transformTerms(Classification.ArtUnit, response),
    [Classification.Cpc]: transformTerms(Classification.Cpc, response),
    [Classification.TechCenter]: transformTerms(Classification.TechCenter, response),
    [Classification.Uspc]: transformTerms(Classification.Uspc, response),
  }
}

export default transformTermsResponse
