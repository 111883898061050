import { filter, identity } from 'ramda'

const getTagInputErrorValues =
  (splitOn: RegExp) =>
  (data: string): string[] =>
    filter(
      identity as any,
      data.split(splitOn).map((value: string) => value && `"${value}"`)
    )

export default getTagInputErrorValues
