import { RadioOption } from '@juristat/common/types'
import * as React from 'react'

import { Check } from '../modules/icons'
import { twTextStyles } from '../styles'

type RadioProps<T> = RadioOption<T> & {
  classNameSelected?: string
  select: (value: T) => void
  selected: boolean
}

const styles = {
  disabled: 'cursor-not-allowed opacity-60',
  nativeRadio:
    'before:border before:border-silver2 before:rounded-[10px] before:content-[""] before:block before:h-5 before:w-5 border-0 bg-clip-[rect(0_0_0_0) h-[1px] -m-[1px] overflow-hidden p-0 absolute w-0',
  radioAndLabel: `${twTextStyles.charcoalGrayNormal12} items-center cursor-pointer flex`,
  styledRadioContainer:
    '[&_>_svg]:fill-link-blue [&_>_svg]:h-[10px] [&_>_svg]:invisible [&_>_svg]:w-[10px] items-center bg-pale-gray border border-silver2 rounded-[10px] cursor-pointer flex shrink-0 h-[22px] justify-center mr-[9px] w-[22px]',
  styledRadioContainerChecked: '[&_>_svg]:!visible bg-white border-link-blue',
}

const Radio = <T,>({
  classNameSelected,
  disabled = false,
  label,
  select,
  selected,
  value,
}: RadioProps<T>) => (
  <label
    className={`${styles.radioAndLabel} ${selected ? classNameSelected : ''} ${
      disabled ? styles.disabled : ''
    }`}
    onClick={(evt) => {
      evt.preventDefault()

      if (disabled) {
        return
      }

      select(value)
    }}
  >
    <input className={styles.nativeRadio} readOnly type="radio" value={String(value)} />
    <div
      className={`${styles.styledRadioContainer} ${
        selected ? styles.styledRadioContainerChecked : ''
      } ${disabled ? styles.disabled : ''}`}
    >
      <Check />
    </div>
    <span>{label}</span>
  </label>
)

export default Radio
