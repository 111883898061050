import { equals, isEmpty, pathOr, propOr } from 'ramda'
import { ParametricSelector, createSelector } from 'reselect'

import { AppState } from '../../../redux'
import memoize from '../../../utils/memoize'
import { getDashboardFilters } from '../../dashboards/selectors'
import { HttpStatus } from '../../http/types'
import { getIntelligenceFilters } from '../../intelligence/selectors'
import { getPathname } from '../../router/selectors'
import { getFilterSearchDetails } from '../../search/selectors/getSearch'
import { SearchSet } from '../../search/types'
import {
  AlmostActiveReducer,
  AvailableReducer,
  Filter,
  FilterMeta,
  FilterReportType,
  SearchSetFilterState,
} from '../types'
import getFilterStateFromUrl from '../utils/getFilterStateFromUrl'

type FilterSelectorProps = {
  filter: Filter
}

const emptyArray: unknown[] = []
const emptyObject = {}

const getFilter: ParametricSelector<
  AppState,
  FilterSelectorProps,
  FilterSelectorProps['filter']
> = (_, { filter }) => filter
const getReportType: ParametricSelector<AppState, FilterMeta, FilterMeta['report']> = (
  _,
  { report }
) => report

const getSearchSetFilterState = createSelector(
  getFilterSearchDetails,
  (state: SearchSet) => state.filters
)

const getReportFilterState = createSelector(
  getReportType,
  getSearchSetFilterState,
  getIntelligenceFilters,
  getDashboardFilters,
  (report, searchFilters, intelligenceFilters, dashboardFilters) => {
    switch (report) {
      case FilterReportType.Dashboard:
        return dashboardFilters
      case FilterReportType.Search:
        return searchFilters
      default:
        return intelligenceFilters
    }
  }
)

const getFilterContext = createSelector(getPathname, (pathname) => getFilterStateFromUrl(pathname))

const getFilterMeta = createSelector(getFilterContext, (state) => state.meta)

const getFilterStateActive = createSelector(
  getReportFilterState,
  (state) => state?.active ?? (emptyObject as SearchSetFilterState['active'])
)

const getFilterStateAvailable = createSelector(
  getReportFilterState,
  (state) => state?.available ?? (emptyObject as SearchSetFilterState['available'])
)

const getFilterStateAvailableIfHasData = createSelector(
  getFilterStateAvailable,
  (state): AvailableReducer =>
    state && (state.type === HttpStatus.Fetching || state.type === HttpStatus.Success)
      ? state.data
      : emptyObject
)

const getFilterStateOpen = createSelector(
  getReportFilterState,
  (state) => state?.open ?? (emptyArray as Filter[])
)

const getFilterStateSelected = createSelector(
  getReportFilterState,
  (state) => (state?.selected ?? emptyObject) as AlmostActiveReducer
)

const getActiveFilters = createSelector(
  getFilterStateActive,
  getFilter,
  (state, filter) => propOr(emptyArray, filter, state) as (typeof state)[Filter]
)

const getSelectedFilters = createSelector(
  getFilterStateSelected,
  getFilter,
  (state, filter) => propOr(emptyArray, filter, state) as (typeof state)[Filter]
)

const areActionButtonsDisabled = createSelector(
  getActiveFilters,
  getSelectedFilters,
  (active, selected) => {
    // This puts the date range filter in an array so we can spread it.
    const noneApplied = isEmpty(active)
    const noneSelected = isEmpty(selected)
    return {
      apply: equals(selected, active),
      clear: noneApplied && noneSelected,
    }
  }
)

const getAvailableFilters = createSelector(getFilterStateAvailable, getFilter, (state, filter) =>
  pathOr(emptyArray, ['data', filter], state)
)

const getCurrentOrDisposition = createSelector(getReportFilterState, (state) =>
  memoize({
    assignee: state?.assignee ?? Filter.CurrentAssignee,
    attorney: state?.attorney ?? Filter.CurrentAttorney,
    firm: state?.firm ?? Filter.CurrentFirm,
  })
)

export {
  areActionButtonsDisabled,
  getActiveFilters,
  getAvailableFilters,
  getCurrentOrDisposition,
  getFilter,
  getFilterMeta,
  getSearchSetFilterState,
  getReportFilterState,
  getFilterStateActive,
  getFilterStateAvailable,
  getFilterStateAvailableIfHasData,
  getFilterStateOpen,
  getFilterStateSelected,
  getSelectedFilters,
}
