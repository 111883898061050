import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Spinner = (props: IconProps) => (
  <SVG title="spinner" {...props} viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2.5">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </SVG>
)

export default Spinner
