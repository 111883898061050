import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Pause = (props: IconProps) => (
  <SVG title="pause" viewBox="0 0 24 24" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M6 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
    <path d="M14 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
  </SVG>
)

export default Pause
