import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const BarChart = (props: IconProps) => {
  return (
    <SVG title="bar-chart" viewBox="0 0 12 14" {...props}>
      <path
        fillRule="evenodd"
        d="M10.5 12.688a.437.437 0 1 1-.875 0V8.311a.437.437 0 1 1 .875 0v4.376zM10.5 7h-.875a.875.875 0 0 0-.875.875v5.25c0 .483.392.875.875.875h.875a.875.875 0 0 0 .875-.875v-5.25A.875.875 0 0 0 10.5 7zm-8.75 5.688a.437.437 0 1 1-.875 0V5.25a.437.437 0 1 1 .875 0v7.438zm0-8.75H.875A.875.875 0 0 0 0 4.811v8.313c0 .483.392.875.875.875h.875a.875.875 0 0 0 .875-.875V4.812a.875.875 0 0 0-.875-.875zm4.375 8.75a.437.437 0 1 1-.875 0V1.312a.437.437 0 1 1 .875 0v11.376zM6.125 0H5.25a.875.875 0 0 0-.875.875v12.25c0 .483.392.875.875.875h.875A.875.875 0 0 0 7 13.125V.875A.875.875 0 0 0 6.125 0z"
      />
    </SVG>
  )
}

export default BarChart
