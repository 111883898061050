import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Upload = (props: IconProps) => (
  <SVG title="upload" viewBox="0 0 11 11" {...props}>
    <path d="M1.09991 6.81982C0.983217 6.81982 0.871301 6.86618 0.788785 6.9487C0.706269 7.03121 0.659912 7.14313 0.659912 7.25982V8.57982C0.659912 9.04661 0.84534 9.49427 1.1754 9.82433C1.50547 10.1544 1.95313 10.3398 2.41991 10.3398H8.57991C9.04669 10.3398 9.49436 10.1544 9.82442 9.82433C10.1545 9.49427 10.3399 9.04661 10.3399 8.57982V7.25982C10.3399 7.14313 10.2936 7.03121 10.211 6.9487C10.1285 6.86618 10.0166 6.81982 9.89991 6.81982C9.78322 6.81982 9.6713 6.86618 9.58879 6.9487C9.50627 7.03121 9.45991 7.14313 9.45991 7.25982V8.57982C9.45991 8.81321 9.3672 9.03705 9.20217 9.20208C9.03713 9.36711 8.8133 9.45982 8.57991 9.45982H2.41991C2.18652 9.45982 1.96269 9.36711 1.79766 9.20208C1.63263 9.03705 1.53991 8.81321 1.53991 8.57982V7.25982C1.53991 7.14313 1.49356 7.03121 1.41104 6.9487C1.32852 6.86618 1.21661 6.81982 1.09991 6.81982Z" />
    <path d="M5.4999 8.06424C5.6166 8.06424 5.72851 8.01789 5.81103 7.93537C5.89355 7.85285 5.9399 7.74094 5.9399 7.62424V2.13084L6.9552 3.10214C7.03951 3.18296 7.15248 3.22696 7.26924 3.22449C7.386 3.22201 7.49699 3.17326 7.5778 3.08894C7.65861 3.00463 7.70262 2.89167 7.70015 2.77491C7.69767 2.65815 7.64891 2.54716 7.5646 2.46634L5.8046 0.782244C5.72266 0.70359 5.61348 0.659668 5.4999 0.659668C5.38632 0.659668 5.27714 0.70359 5.1952 0.782244L3.4352 2.46634C3.35089 2.54716 3.30214 2.65815 3.29966 2.77491C3.29718 2.89167 3.34119 3.00463 3.422 3.08894C3.50282 3.17326 3.61381 3.22201 3.73057 3.22449C3.84733 3.22696 3.96029 3.18296 4.0446 3.10214L5.0599 2.13084V7.62424C5.0599 7.74094 5.10626 7.85285 5.18878 7.93537C5.27129 8.01789 5.38321 8.06424 5.4999 8.06424Z" />
  </SVG>
)

export default Upload
