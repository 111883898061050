import { DataSource } from '@juristat/common/types'
import config from '@juristat/config'
import { DocumentNode } from 'graphql'
import { flatten } from 'ramda'
import { assign } from 'xstate'

import noop from '../../../utils/noop'
import { QueryKey } from '../types'
import useEnsureServiceMeta from './useEnsureServiceMeta'
import useFetchMachine from './useFetchMachine'
import useGraphqlApi from './useGraphqlApi'

type DataSourceVariable = {
  dataSource?: DataSource
}

type MoreOptions = Partial<{
  enabled: boolean
  flat: boolean
  onProgress: (variables: Record<string, any>, index: number) => void
}>

type Options<Variables extends DataSourceVariable, Data, Result> = {
  ppair?: boolean
  transform: (response: Result) => Data
  variables: Variables
}

export function useMergedQueries<T, R = T>(
  queryKey: QueryKey,
  query: DocumentNode,
  options: Array<Options<Record<string, any>, T, R>>,
  { enabled = true, flat = false, onProgress }: MoreOptions = {}
) {
  const publicApi = useGraphqlApi()
  const ppairApi = useGraphqlApi(`${config.ppairUrl}/v2/graphql/app`)
  const key = useEnsureServiceMeta<T[], R[]>(
    queryKey,
    async () => {
      try {
        return await Promise.all(
          options.map(async ({ ppair = false, variables }, index) => {
            const api =
              ppair || variables.dataSource === DataSource.PrivatePair ? ppairApi : publicApi

            const response = await api({ query, variables })

            onProgress?.(variables, index)

            return response
          })
        )
      } catch {
        throw 'An error occurred'
      }
    },
    { options, transform: noop as unknown as (data: R[]) => T[] },
    {
      actions: {
        transform: assign((ctx) => {
          try {
            const data = options.map(({ transform }, index) =>
              transform(ctx.raw?.[index] ?? ({} as R))
            )

            return {
              ...ctx,
              data: flat ? (flatten(data) as unknown as T[]) : data,
            }
          } catch (ex) {
            return { ...ctx, error: (ex as Error).message }
          }
        }),
      },
    }
  )

  return useFetchMachine<T[], R[]>(key, { enabled })
}
