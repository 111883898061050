import { DataSource } from '@juristat/common/types'
import { isNilOrEmpty } from '@juristat/common/utils'
import { equals, merge } from 'ramda'
import { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'

import { AppState } from '../../../redux'
import { useQuery } from '../../api'
import { getIntrinsicFilters } from '../../intelligence/utils'
import { useSearchVariables } from '../../search/hooks'
import * as getSearchUid from '../../search/queries/getSearchUid.graphql'
import FilterContext from '../context/filterContext'
import * as getSearchedFilter from '../queries/getSearchedFilter.graphql'
import { getAvailableFilters, getFilterStateActive } from '../selectors'
import { TypeaheadFilter, TypeaheadSuggestion } from '../types'
import transformFilters from '../utils/transformFilters'

const fields = {
  withAppno: false,
  withArtUnit: false,
  withAssigneeAtDisposition: false,
  withAssigneeAtDispositionName: false,
  withAttorneyAtDisposition: false,
  withCpcClass: false,
  withCurrentAssignee: false,
  withCurrentAssigneeName: false,
  withCurrentAttorney: false,
  withCurrentFirm: false,
  withCurrentFirmName: false,
  withExaminer: false,
  withDocCodeFilter: false,
  withFirmAtDisposition: false,
  withFirmAtDispositionName: false,
  withUspcClass: false,
}

const withify = (value: string) =>
  value.replace(
    /^(.)(.+)$/,
    (_, first: string, rest: string) => `with${first.toUpperCase()}${rest}`
  )

export function useFilterTypeahead(filter: TypeaheadFilter) {
  const { meta } = useContext(FilterContext)
  const { entity, id } = meta
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500)
  const onClear = useMemo(
    () => (isNilOrEmpty(searchTerm) ? undefined : () => setSearchTerm(null)),
    [searchTerm, filter, meta]
  )

  const variables = useSearchVariables()
  const filters =
    entity !== undefined && id !== undefined
      ? merge(getIntrinsicFilters({ entity, id }), variables.filters)
      : variables.filters

  const [uid] = useQuery<string, { applicationSet: { uid: string } }>('filter-uid', getSearchUid, {
    enabled: Boolean(debouncedSearchTerm),
    transform: ({ applicationSet: { uid } }) => uid,
    variables: { ...variables, filters },
  })

  const [typeahead] = useQuery<
    Array<TypeaheadSuggestion[TypeaheadFilter]>,
    { uid: { filters: { [K in TypeaheadFilter]?: Array<TypeaheadSuggestion[TypeaheadFilter]> } } }
  >('filter-typeahead', getSearchedFilter, {
    enabled: uid.matches('success') && Boolean(debouncedSearchTerm),
    ppair: variables.dataSource === DataSource.PrivatePair,
    transform: ({ uid: { filters } }) => {
      const results = filters?.[filter]

      if (results) {
        const { [filter]: value } = transformFilters({ [filter]: results })

        return value
      }

      throw 'An unexpected error occurred.'
    },
    variables: {
      search: debouncedSearchTerm,
      uid: uid.context.data,
      ...fields,
      [withify(filter)]: true,
    },
  })

  const active = useSelector((state: AppState) => getFilterStateActive(state, meta))
  const available = useSelector((state: AppState) =>
    getAvailableFilters(state, { ...meta, filter })
  )
  const isOnlyActiveFilter: boolean = equals(Object.keys(active), [filter])

  const disabled = isOnlyActiveFilter ? false : available.length < 10

  return { debouncedSearchTerm, disabled, onClear, meta, searchTerm, setSearchTerm, typeahead }
}
