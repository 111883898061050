import { identity } from 'ramda'

import { QueryKey } from '../types'
import useAppApi from './useAppApi'
import useEnsureServiceMeta from './useEnsureServiceMeta'
import useFetchMachine from './useFetchMachine'

type Options<T, R> = Partial<{
  enabled: boolean
  transform: (data: R) => T | R
}>

export function useApp<T, R = T>(
  queryKey: QueryKey,
  url: string,
  { enabled = true, transform = identity }: Options<T, R> = {}
) {
  const api = useAppApi()
  const key = useEnsureServiceMeta(queryKey, async () => await api(url), { transform, url })

  return useFetchMachine<T, R>(key, { enabled })
}
