import { Filter, IntelligenceEntityType } from '@juristat/common/types'
import { css } from 'emotion'
import { omit } from 'ramda'
import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ScrollableOnHover from '../../../components/ScrollableOnHover'
import { AppState } from '../../../redux'
import stringifyList from '../../../utils/stringifyList'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import { Access } from '../../session/types'
import FilterContext from '../context/filterContext'
import { getCurrentOrDisposition } from '../selectors'
import ApplicationNumberTagFilterContainer from './ApplicationNumberTagFilterContainer'
import ApplyAllButton from './ApplyAllButton'
import AttorneyDocketNumberTagFilterContainer from './AttorneyDocketNumberTagFilterContainer'
import BooleanFilter from './BooleanFilter'
import CheckFilterAccess from './CheckFilterAccess'
import CheckListFilterContainer from './CheckListFilterContainer'
import ClearAllButton from './ClearAllButton'
import CurrentAtDispositionFilterContainer from './CurrentAtDispositionFilterContainer'
import DateRangeFilterContainer from './DateRangeFilterContainer'
import PatentNumberTagFilter from './PatentNumberTagFilter'
import PublicationNumberTagFilterContainer from './PublicationNumberTagFilter'
import RangeFilterContainer from './RangeFilterContainer'
import StatCountFilter from './StatCountFilter'
import TagFilterContainer from './TagFilterContainer'
import TristateFilterContainer from './TristateFilterContainer'
import TypeaheadFilterContainer from './TypeaheadFilterContainer'
import getTagInputErrorValues from './utils/getTagInputErrorValues'

const styles = {
  content: css({
    padding: '0 10px',
  }),
  main: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 0,
  }),
  saveClearAllContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexBasis: 40,
    flexShrink: 0,
    justifyContent: 'space-between',
  }),
  scrollableOnHover: 'grow h-0',
}

const assigneeFilters = [
  Filter.AssigneeAtDisposition,
  Filter.AssigneeAtDispositionName,
  Filter.CurrentAssignee,
  Filter.CurrentAssigneeName,
]
const attorneyFilters = [Filter.AttorneyAtDisposition, Filter.CurrentAttorney]
const firmFilters = [Filter.CurrentFirm, Filter.FirmAtDisposition]

const getLookup = (item: WeakObject) => omit(['apps', 'metadata', 'name'], item)
const getDocCodeLookup = (item: WeakObject) => ({ docCode: item.docCode })
const getErrorValues = getTagInputErrorValues(/, /)

const SearchFilters = () => {
  const { meta } = useContext(FilterContext)
  const { assignee, attorney, firm } = useSelector((state: AppState) =>
    getCurrentOrDisposition(state, meta)
  )
  const dispatch = useDispatch()
  const createHandleParseError = useCallback(
    (type: string, length: string) => (data: string) => {
      const errorValues = stringifyList(getErrorValues(data))

      dispatch(
        notificationActions.push(
          makeNotification({
            message: `Failed to parse ${errorValues} as ${type} number(s). Values should be ${length} numbers.`,
            type: NotificationTypes.Error,
          })
        )
      )
    },
    [dispatch]
  )

  return (
    <div className={styles.main}>
      <ScrollableOnHover className={styles.scrollableOnHover} dark>
        <div className={styles.content}>
          <ApplicationNumberTagFilterContainer />
          <CheckFilterAccess canAccess={Access.Platform}>
            <PatentNumberTagFilter />
          </CheckFilterAccess>
          <AttorneyDocketNumberTagFilterContainer />
          <PublicationNumberTagFilterContainer />
          <BooleanFilter
            excludeText="Published"
            filter={Filter.FiledUnpublished}
            includeText="Granted Unpublished"
            title="Publication Status"
          />
          <TristateFilterContainer filter={Filter.ApplicationStatus} title="Status" />
          <DateRangeFilterContainer filter={Filter.FilingDate} title="Filing Date" />
          <DateRangeFilterContainer filter={Filter.DispositionDate} title="Disposition Date" />
          <DateRangeFilterContainer filter={Filter.PublicationDate} title="Publication Date" />
          <DateRangeFilterContainer filter={Filter.IssuanceDate} title="Issuance Date" />
          <TypeaheadFilterContainer
            filter={Filter.DocCode}
            getLookup={getDocCodeLookup}
            title="Document Code"
          />
          <CheckFilterAccess canAccess={Access.PlatformExaminerAuTcFilters}>
            {meta.entity === IntelligenceEntityType.Examiner ? null : (
              <TypeaheadFilterContainer filter={Filter.Examiner} title="Examiner" />
            )}
            <RangeFilterContainer
              filter={Filter.ExaminerAllowanceRate}
              title="Examiner Allowance Rate"
            />
          </CheckFilterAccess>
          <CheckFilterAccess canAccess={Access.Platform}>
            <TypeaheadFilterContainer
              enabled
              filter={Filter.UspcClass}
              getLookup={getLookup}
              title="USPC"
            />
            <TypeaheadFilterContainer
              enabled
              filter={Filter.CpcClass}
              getLookup={getLookup}
              title="CPC"
            />
            <TristateFilterContainer filter={Filter.ApplicationType} title="Application Type" />
          </CheckFilterAccess>
          <CheckFilterAccess canAccess={Access.Platform}>
            <CheckListFilterContainer filter={Filter.EntityStatus} title="Entity Status" />
          </CheckFilterAccess>
          <CheckFilterAccess canAccess={Access.PlatformFirmAssigneeFilters}>
            <CurrentAtDispositionFilterContainer
              filter={assignee}
              filters={assigneeFilters}
              title="Assignee"
              rawNames="assignee"
            />
            <CurrentAtDispositionFilterContainer
              filter={firm}
              filters={firmFilters}
              title="Firm"
              rawNames="firm"
            />
          </CheckFilterAccess>
          <CurrentAtDispositionFilterContainer
            filter={attorney}
            filters={attorneyFilters}
            title="Attorney"
          />
          <CheckFilterAccess canAccess={Access.PlatformExaminerAuTcFilters}>
            <TypeaheadFilterContainer filter={Filter.ArtUnit} title="Art Unit" />
            <CheckListFilterContainer filter={Filter.TechCenter} title="Tech Center" />
          </CheckFilterAccess>
          <CheckFilterAccess canAccess={Access.RejectionBasisFilter}>
            <CheckListFilterContainer filter={Filter.RejectionBasis} title="Rejection Basis" />
          </CheckFilterAccess>
          <CheckFilterAccess canAccess={Access.PlatformOaRceCountFilters}>
            <StatCountFilter
              filters={[
                { filter: Filter.OfficeActionsCount, title: 'All Office Actions' },
                { filter: Filter.NonFinalRejectionsCount, title: 'Non-Final Office Actions' },
                { filter: Filter.FinalRejectionsCount, title: 'Final Office Actions' },
              ]}
              title="Office Action Counts"
            />
            <StatCountFilter filters={[{ filter: Filter.AppealCount }]} title="Appeal Count" />
            <StatCountFilter filters={[{ filter: Filter.RceCount }]} title="RCE Count" />
            <StatCountFilter
              filters={[{ filter: Filter.AmendmentCount }]}
              title="Amendment Count"
            />
            <StatCountFilter
              filters={[{ filter: Filter.InterviewCount }]}
              title="Interview Count"
            />
          </CheckFilterAccess>
          <CheckFilterAccess canAccess={Access.PlatformRegCustNumFilters}>
            <TagFilterContainer
              filter={Filter.CustomerNumber}
              handleParseError={createHandleParseError('customer', '3 - 6')}
              parseInput={(value) => (/^\d{3,6}$/.test(value) ? value : '')}
              placeholder="Enter a Customer #..."
              title="Customer Number"
            />
            <TagFilterContainer
              filter={Filter.RegistrationNumber}
              handleParseError={createHandleParseError('registration', '4 - 6')}
              parseInput={(value) => (/^\d{4,6}$/.test(value) ? value : '')}
              placeholder="Enter a Registration #..."
              title="Registration Number"
            />
          </CheckFilterAccess>
          <StatCountFilter
            filters={[{ filter: Filter.UnnecessaryAppeals }]}
            title="Unnecessary Appeals"
          />
          <BooleanFilter filter={Filter.MissedInterview} title="Missed Interview" />
          <BooleanFilter filter={Filter.MissedContinuation} title="Missed Continuation" />
          <StatCountFilter
            filters={[{ filter: Filter.UnnecessaryOfficeActions }]}
            title="Unnecessary OAs"
          />
        </div>
      </ScrollableOnHover>
      <div className={styles.saveClearAllContainer}>
        <ClearAllButton />
        <ApplyAllButton />
      </div>
    </div>
  )
}

export default SearchFilters
