import { format, subDays } from 'date-fns'
import { Reducer, combineReducers } from 'redux'

import { HttpStatus } from '../../../../http/types'
import { GroupState, ReducerActions } from '../types'

const details: Reducer<GroupState['details']> = (
  state = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'account/group/ERROR':
      return { message: action.payload!, type: HttpStatus.Error }
    case 'account/group/FETCH':
      return { type: HttpStatus.Fetching }
    case 'account/group/SET':
      return { data: action.payload!, type: HttpStatus.Success }
    case 'account/group/token/ADD': {
      if (state.type !== HttpStatus.Success) {
        return state
      }

      return { ...state, data: { ...state.data, tokens: [...state.data.tokens, action.payload!] } }
    }
    case 'account/group/token/INVALIDATE': {
      if (state.type !== HttpStatus.Success) {
        return state
      }

      return {
        ...state,
        data: {
          ...state.data,
          tokens: state.data.tokens.map((token) =>
            token.token === action.payload!
              ? {
                  ...token,
                  validBefore: format(subDays(new Date(), 1), "yyyy-MM-dd'T'00:00:00.000'Z'"),
                }
              : token
          ),
        },
      }
    }
    default:
      return state
  }
}

const search: Reducer<GroupState['search']> = (
  state = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'account/group/search/ERROR':
      return { message: action.payload!, type: HttpStatus.Error }
    case 'account/group/search/FETCH':
      return { type: HttpStatus.Fetching }
    case 'account/group/search/SET':
      return { data: action.payload!, type: HttpStatus.Success }
    default:
      return state
  }
}

const typeahead: Reducer<GroupState['typeahead']> = (
  state = { type: HttpStatus.NotAsked },
  action: ReducerActions
) => {
  switch (action.type) {
    case 'account/group/typeahead/ERROR':
      return { message: action.payload!, type: HttpStatus.Error }
    case 'account/group/typeahead/FETCH':
      return { type: HttpStatus.Fetching }
    case 'account/group/typeahead/SET':
      return { data: action.payload!, type: HttpStatus.Success }
    default:
      return state
  }
}

const reducer = combineReducers({ details, search, typeahead })

export default reducer
