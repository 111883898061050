import { find, pathOr } from 'ramda'

import { GraphQLFilterResponse } from '../types'

type TechCenters = GraphQLFilterResponse['techCenter']
type TechCenter = TechCenters extends Array<infer U> ? U : never

const techCenterFilters = [1600, 1700, 2100, 2400, 2600, 2800, 2900, 3600, 3700]

const transformTechCenters = (techCenters?: TechCenters | null) => {
  const findTechCenter = (techCenter: number): TechCenter | undefined =>
    find((item: TechCenter) => item.name === String(techCenter), techCenters ?? [])

  return techCenterFilters.map((techCenter) => {
    const match = findTechCenter(techCenter)
    const description = pathOr(null, ['result', 'description'], match)
    const label = description ? `${techCenter}: ${description}` : String(techCenter)

    return {
      apps: match ? match.apps || 0 : 0,
      label,
      value: techCenter,
    }
  })
}

export default transformTechCenters
