import { Filter, SearchView } from '@juristat/common/types'
import { isNilOrEmpty } from '@juristat/common/utils'
import React from 'react'

import { Spinner } from '../../icons'
import { SetReportPayload } from '../../search/types'
import ListItem from '../../userSearches/components/SavedSearchesList/ListItem'
import { useAlerts } from '../hooks'

type MyAlertsProps = {
  performSearch: (searchVariables: SetReportPayload) => void
}

const MyAlerts: React.FC<MyAlertsProps> = ({ performSearch }) => {
  const alerts = useAlerts()

  if (alerts.matches('success') && isNilOrEmpty(alerts.context.data)) {
    return null
  }

  return !alerts.matches('success') ? (
    <Spinner className="stroke-white" />
  ) : (
    <ListItem
      handleClick={() => {
        performSearch({
          filters: {
            [Filter.ApplicationNumber]: alerts.context.data.map(String),
          },
          view: SearchView.MyAlerts,
        })
      }}
      label="My Alerts"
      numResults={alerts.context.data.length}
    />
  )
}

export default MyAlerts
