import config from '@juristat/config'
import { DocumentNode } from 'graphql'
import { identity } from 'ramda'

import { QueryKey } from '../types'
import useEnsureServiceMeta from './useEnsureServiceMeta'
import useFetchMachine from './useFetchMachine'
import useGraphqlApi from './useGraphqlApi'

type Options<T, R> = Partial<{
  enabled: boolean
  ppair: boolean
  transform: (data: R) => T | R
  variables: Record<string, unknown>
}>

export function useQuery<T, R = T>(
  queryKey: QueryKey,
  query: DocumentNode,
  { enabled = true, ppair = false, transform = identity, variables = {} }: Options<T, R> = {}
) {
  const publicApi = useGraphqlApi()
  const ppairApi = useGraphqlApi(`${config.ppairUrl}/v2/graphql/app`)
  const api = ppair ? ppairApi : publicApi
  const key = useEnsureServiceMeta(
    queryKey,
    async (_, { variables: overrides = {} }) =>
      await api({ query, variables: { ...variables, ...overrides } }),
    { transform, ppair, variables }
  )

  return useFetchMachine<T, R>(key, { enabled })
}
