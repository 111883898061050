import { formatDate } from '@juristat/common/utils'
import { endOfMonth, startOfMonth } from 'date-fns'
import { css } from 'emotion'
import { isNil, reject } from 'ramda'
import React, { useContext, useState } from 'react'
import OnClickout from 'react-onclickout'
import { useDispatch } from 'react-redux'

import { BoundAction } from '../../../../redux'
import isDateValid from '../../../../utils/isDateValid'
import actions from '../../actions'
import FilterContext from '../../context/filterContext'
import {
  DateRangeFilter,
  DateRangeSelection,
  SelectSomeAction,
  SetFilterPayload,
} from '../../types'
import DateInput from './DateInput'
import PickerDropdown from './PickerDropdown'

export type MonthAndYear = {
  month: number
  year: number
}

export type StartEndDate = {
  start?: string
  end?: string
}

type MonthPickerProps = {
  selected: StartEndDate
  filter: DateRangeFilter
}

const styles = {
  inputContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }),
  pickerContainer: css({
    display: 'flex',
    justifyContent: 'center',
  }),
}

export const makeMonthAndYear = (date = ''): MonthAndYear => {
  // TODO: Better validation
  if (!isDateValid(date)) {
    return {
      month: -1,
      year: new Date().getUTCFullYear(),
    }
  }

  const d = new Date(date.replace(/-0$/, ''))

  return {
    month: d.getUTCMonth(),
    year: d.getUTCFullYear(),
  }
}

const makeSetDate =
  (
    selection: DateRangeSelection,
    start: string | undefined,
    end: string | undefined,
    filter: DateRangeFilter,
    select: BoundAction<SelectSomeAction>
  ) =>
  ({ month, year }: MonthAndYear) => {
    const getDate = selection === DateRangeSelection.Start ? startOfMonth : endOfMonth
    const date = getDate(new Date(year, month))
    const formatted = month === -1 ? undefined : formatDate(date)
    const prop = selection.toLowerCase()
    const next: StartEndDate = reject(isNil, { start, end, [prop]: formatted })
    const value = !next.end && !next.start ? undefined : next

    select({ filter, value } as SetFilterPayload)
  }

const MonthPicker = ({ filter, selected }: MonthPickerProps) => {
  const { meta } = useContext(FilterContext)
  const [datePickerOpen, setDatePickerOpen] = useState<DateRangeSelection | null>(null)
  const dispatch = useDispatch()
  const select: BoundAction<SelectSomeAction> = (payload) =>
    dispatch(actions.selectSome(payload, meta))

  const start = selected.start
  const end = selected.end
  return (
    <div>
      <div className={styles.inputContainer}>
        <DateInput
          open={datePickerOpen === DateRangeSelection.Start}
          selection={DateRangeSelection.Start}
          setDatePickerOpen={setDatePickerOpen}
          value={start}
        />
        <DateInput
          open={datePickerOpen === DateRangeSelection.End}
          selection={DateRangeSelection.End}
          setDatePickerOpen={setDatePickerOpen}
          value={end}
        />
      </div>
      {datePickerOpen && (
        <div className={styles.pickerContainer}>
          <OnClickout
            onClickOut={(e: Event) => {
              // Don't close picker if we are switching between Start/End date
              // TODO: Find better way to target just DateInput
              if ((e.target as HTMLElement).getAttribute('placeholder') === 'mm/yyyy') {
                return
              }

              setDatePickerOpen(null)
            }}
          >
            <PickerDropdown
              {...{
                current: makeMonthAndYear(
                  datePickerOpen === DateRangeSelection.Start ? start : end
                ),
                datePickerOpen,
                selection: datePickerOpen,
                set: makeSetDate(datePickerOpen, start, end, filter, select),
              }}
            />
          </OnClickout>
        </div>
      )}
    </div>
  )
}

export default MonthPicker
