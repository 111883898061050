import { EntityTypeAndId } from '../types'
import getEntityLabel from './getEntityLabel'

type DemoLabelProps = EntityTypeAndId & { name: string }

const getFirstAndLast = ([first, ...rest]: string) => [first, rest[rest.length - 1]].join('')

const getDemoLabel = ({ entity, id, name }: DemoLabelProps) => {
  const entityLabel = getEntityLabel(entity, { simple: true })
  const nameLabel = getFirstAndLast(name.trim())
  const idLabel = getFirstAndLast(String(id).trim())

  return `${entityLabel} ${nameLabel}${idLabel}`
}

export default getDemoLabel
