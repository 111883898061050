import cuid from 'cuid'
import { pathOr } from 'ramda'
import { combineReducers } from 'redux'

import { HttpContent, HttpStatus } from '../../http'
import { Actions, AuthMethod, SentEmail, ViewContent } from '../types'

const accessToken = (state: string | null = null, action: Actions) => {
  switch (action.type) {
    case 'auth/accessToken/INVALIDATE':
      return null
    case 'auth/accessToken/SET':
      return action.payload!
    default:
      return state
  }
}

// Hack to refresh token in Hook when triggered from group crossed
const accessTokenId = (state = cuid(), action: Actions) => {
  switch (action.type) {
    case 'auth/accessToken/REFRESH':
      return cuid()
    default:
      return state
  }
}

const authMethods = (
  state: HttpContent<AuthMethod[]> = { type: HttpStatus.NotAsked },
  action: Actions
) => {
  switch (action.type) {
    case 'auth/AVAILABLE_METHODS_ERROR':
      return { message: 'Failed to fetch available authentication methods', type: HttpStatus.Error }
    case 'auth/GET_AVAILABLE_METHODS':
      return { type: HttpStatus.Fetching }
    case 'auth/SET_AVAILABLE_METHODS':
      return { data: action.payload! || [], type: HttpStatus.Success }
    default:
      return state
  }
}

const claims = (state: WeakObject = {}, action: Actions) => {
  switch (action.type) {
    case 'auth/SET_CLAIMS':
      return action.payload!
    default:
      return state
  }
}

const expiration = (state = 0, action: Actions) => {
  switch (action.type) {
    case 'auth/accessToken/INVALIDATE':
      return 0
    case 'auth/accessToken/SET':
      return Date.now() + pathOr(0, ['meta', 'expiresIn'], action) * 1000
    default:
      return state
  }
}

const loginEmail = (
  state: HttpContent<SentEmail> = { type: HttpStatus.NotAsked },
  action: Actions
) => {
  switch (action.type) {
    case 'auth/SEND_LOGIN_EMAIL':
      return { type: HttpStatus.Fetching }
    case 'auth/LOGIN_EMAIL_SENT':
      return { data: action.payload!, type: HttpStatus.Success }
    case 'auth/LOGIN_EMAIL_ERROR':
      return { message: action.payload!, type: HttpStatus.Error }
    default:
      return state
  }
}

const view = (state: ViewContent = { type: HttpStatus.Fetching }, action: Actions) => {
  switch (action.type) {
    case 'auth/AUTHORIZE_ERROR':
      return { message: action.payload!, type: HttpStatus.Error }
    case 'auth/AUTHORIZE_ERROR_CLEAR':
      return { type: HttpStatus.NotAsked }
    case 'auth/AUTHORIZE':
    case 'auth/LOGIN':
    case 'auth/accessToken/HYDRATE':
      return { type: HttpStatus.Fetching }
    case 'auth/accessToken/HYDRATE_ERROR':
      return { type: HttpStatus.NotAsked }
    case 'auth/accessToken/SET':
      return { type: HttpStatus.Success }
    default:
      return state
  }
}

const reducer = combineReducers({
  accessToken,
  accessTokenId,
  authMethods,
  claims,
  expiration,
  loginEmail,
  view,
})

export default reducer
