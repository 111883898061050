import { CSSObject } from 'create-emotion'
import { css } from 'emotion'

import { colors } from '.'
import { rebeccapurpleAlpha8, shakespeare } from './colorsOld'
import makeTransition from './makeTransition'

/**
 * Makes the link styles.
 *
 * @param {number} fontSize Specify font-size in ems.
 *
 * @return {string}
 */
const makeLink = (styles: CSSObject = {}) =>
  css(
    {
      '&:hover': { color: shakespeare },
      '&:visited': { color: rebeccapurpleAlpha8 },
      color: colors.linkBlue,
      transition: makeTransition('color', 0.1),
    },
    styles
  )

export default makeLink
