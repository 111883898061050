import { useEffect, useRef } from 'react'

const createRootElement = (id: string) => {
  const container = document.createElement('div')

  container.setAttribute('id', id)

  return container
}

const addRootElement = (element: HTMLDivElement) => {
  if (document.body.lastElementChild) {
    document.body.insertBefore(element, document.body.lastElementChild.nextElementSibling)
  }
}

export function usePortal(id: string) {
  const elementRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const element = elementRef.current

    const existing = document.querySelector<HTMLDivElement>(`#${id}`)
    const parent = existing ?? createRootElement(id)

    if (!existing) {
      addRootElement(parent)
    }

    if (!element) {
      return
    }

    parent.appendChild(element)

    return () => {
      element.remove()

      if (!parent.childElementCount) {
        parent.remove()
      }
    }
  }, [id])

  const getRootElement = () => {
    if (!elementRef.current) {
      elementRef.current = document.createElement('div')
    }

    return elementRef.current
  }

  return getRootElement()
}
