import { formatClientName } from '@juristat/common/utils'
import { useMachine } from '@xstate/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { createMachine } from 'xstate'

import { useResizeObserver } from '../../hooks'
import { useApp } from '../api'
import {
  CheckedContext,
  HighlightContext,
  SetCheckedContext,
  UpdateActionContext,
  UpdateDocumentContext,
} from './contexts'

type Portal = {
  id: string
  x: number
  y: number
}

export function useAppContext() {
  const [context, setContext] = useState<'juritron' | 'web'>('web')

  useEffect(() => {
    async function checkContext() {
      if (await window.electron?.getUrl()) {
        setContext('juritron')
      }
    }

    checkContext()
  }, [])

  return context
}

export function useChecked() {
  const context = useContext(CheckedContext)

  if (context === undefined) {
    throw new Error('useChecked must be used within an CheckedProvider')
  }

  return context
}

export function useClients(type: 'ids' | 'oar') {
  const [clients] = useApp<
    Array<Record<'label' | 'value', string>>,
    Array<Record<'groupId' | 'groupName', string>>
  >(`${type}-clients`, `/${type}/clients`, {
    transform: (data) =>
      data
        .filter(
          ({ groupId }, index, all) => all.findIndex((item) => item.groupId === groupId) === index
        )
        .map(({ groupId, groupName }) => ({
          label: formatClientName(groupName),
          value: `${groupId},${groupName}`,
        })),
  })

  return useMemo(() => clients.context.data ?? [], [clients.context.data])
}

export function useFilterOptions<T, R extends string>(
  data: T[],
  prop: keyof T,
  isArrayValue = false
) {
  return useMemo(() => {
    const values = data.map((item) => item[prop])

    return Array.from(new Set(isArrayValue ? ([] as unknown[]).concat(...values) : values))
      .filter(Boolean)
      .sort()
      .map((value) => {
        if (typeof value === 'string') {
          return { label: value, value: value as R }
        }

        // Unreachable, but will allow typescript to narrow unknown to string correctly.
        throw new Error()
      })
  }, [data, isArrayValue, prop])
}

export function useGroupCustnos(group: string, all?: 'all') {
  const [groupId] = group.split(',')
  const [custnos] = useApp<Array<Record<'label' | 'value', string>>, string[]>(
    ['custnos', groupId, all],
    `/cust-nos/${groupId}${all ? '/all' : ''}`,
    {
      enabled: Boolean(groupId),
      transform: (data) => data.map((value) => ({ label: value, value })),
    }
  )

  return useMemo(() => custnos.context.data ?? [], [custnos.context.data])
}

export function useHighlight() {
  const context = useContext(HighlightContext)

  return context ?? null
}

const getPosition = (
  { x, y }: DOMRect = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    toJSON() {
      return
    },
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  },
  yOffset: number,
  xOffset: number
) => ({ x: x + xOffset, y: y + yOffset })

export function usePortalAndDirection(
  column: string,
  maxHeight: number,
  rows: number,
  yOffset = 0,
  xOffset = 0
) {
  const { ref } = useResizeObserver<HTMLDivElement>()
  const [direction, setDirection] = useState<'down' | 'up'>('down')
  const [portal, setPortal] = useState<Portal>()

  useEffect(() => {
    const measure = () => {
      const row = ref.current?.closest('.tr') as HTMLTableRowElement
      const tbody = row?.closest('.tbody')
      const rect = ref.current?.getBoundingClientRect()

      if (rect) {
        setPortal({ id: column, ...getPosition(rect, yOffset, xOffset) })
      }

      const index = Array.from(tbody?.children ?? []).findIndex((item) => item === row)

      setDirection(index > rows * 0.4 ? 'up' : 'down')
    }

    const element = ref.current

    element?.addEventListener('mouseover', measure)

    return () => {
      element?.removeEventListener('mouseover', measure)
    }
  }, [column, maxHeight, ref, rows, xOffset, yOffset])

  return [ref, portal, direction] as const
}

export function useSetChecked() {
  const context = useContext(SetCheckedContext)

  if (context === undefined) {
    throw new Error('useSetChecked must be used within an SetCheckedProvider')
  }

  return context
}

export function useUpdateAction() {
  const context = useContext(UpdateActionContext)

  if (context === undefined) {
    throw new Error('useUpdateAction must be used within an UpdateActionProvider')
  }

  return context
}

export function useUpdateDocument() {
  const context = useContext(UpdateDocumentContext)

  if (context === undefined) {
    throw new Error('useUpdateDocument must be used within an UpdateDocumentProvider')
  }

  return context
}

export function useWarningMachine<T>() {
  return useMachine(
    useMemo(
      () =>
        createMachine<T>({
          id: 'warning',
          type: 'parallel',
          states: {
            open: {
              initial: 'invalid',
              states: {
                invalid: {
                  on: {
                    VALID: 'valid',
                  },
                },
                valid: {
                  on: {
                    INVALID: 'invalid',
                  },
                },
              },
            },
            warning: {
              initial: 'inactive',
              states: {
                active: {
                  on: {
                    REMOVE_WARNING: 'inactive',
                  },
                },
                inactive: {
                  on: {
                    WARNING: 'active',
                  },
                },
              },
            },
          },
        }),
      []
    )
  )
}
