import * as CSV from 'comma-separated-values'
import download from 'downloadjs'
import Downshift from 'downshift'
import { css } from 'emotion'
import { toPng } from 'html-to-image'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { animated, config, useTransition } from 'react-spring'

import Button from '../../../components/Button'
import { colors, textStyles, zIndex } from '../../../styles'
import isIe from '../../../utils/isIe'
import { FetchTypeState } from '../../api'
import { Cog } from '../../icons'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import { useHasAccess } from '../../session/hooks'
import { Access } from '../../session/types'
import { ExportableConfig } from '../types'
import { getEntityLabel } from '../utils'

type ChartSettingsMenuProps = ExportableConfig<any> & {
  className?: string
  dom: React.RefObject<HTMLElement | null>
  handleRemove: () => void
  machine: FetchTypeState<any, any>
}

const styles = {
  button: css(textStyles.darkNormal13, {
    '&:hover': {
      backgroundColor: colors.cloudyBlueAlpha20,
    },
    cursor: 'pointer',
    justifyContent: 'flex-start',
    padding: '6px 14px',
    transition: 'background-color 200ms ease-in-out',
    width: '100%',
  }),
  menu: (fixed: boolean) =>
    css({
      backgroundColor: colors.white,
      borderRadius: 4,
      boxShadow: `0 4px 10px 0 ${colors.charcoalGray2alpha30}`,
      overflow: 'hidden',
      padding: '10px 0',
      position: 'absolute',
      right: fixed ? 63 : 95,
      top: 10,
      width: 150,
      zIndex: zIndex.select,
    }),
  menuButton: css({
    '& > svg': {
      fill: colors.charcoalGray2,
    },
    '&:hover': {
      opacity: 0.6,
    },
    height: 32,
    opacity: 0.3,
    transition: 'opacity 200ms ease-in-out',
    width: 32,
  }),
}

const fallback = (domNode: HTMLElement) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')!
  const image = new Image()
  const ratio = window.devicePixelRatio || 1
  const rect = domNode.getBoundingClientRect()
  const width = rect.width * ratio
  const height = rect.height * ratio
  const imageUrl = URL.createObjectURL(
    new Blob([new XMLSerializer().serializeToString(domNode)], { type: 'image/svg+xml' })
  )

  return new Promise<Blob>((resolve, reject) => {
    image.onerror = reject
    image.onload = () => {
      context.fillStyle = 'white'
      context.fillRect(0, 0, canvas.width, canvas.height)
      context.drawImage(image, 0, 0, width, height)
      canvas.toBlob((blob) => {
        URL.revokeObjectURL(imageUrl)

        if (blob) {
          resolve(blob)
        } else {
          reject('Blob was null')
        }
      })
    }

    canvas.width = width
    canvas.height = height
    image.src = imageUrl
  })
}

const ChartSettingsMenu: React.FC<ChartSettingsMenuProps> = ({
  className,
  dom,
  getData,
  getHeader,
  filename,
  handleRemove,
  machine,
}) => {
  const canExportAnalytics = useHasAccess(Access.ExportAnalytics)
  const dispatch = useDispatch()
  const handleSaveAsCsv = useCallback(() => {
    const data = machine.matches('success') ? getData(machine.context.data) : [[]]
    const header = machine.matches('success') ? ['Name', ...getHeader(machine.context.data)] : []

    download(
      CSV.encode(
        data.map(([id, ...rest]) => [getEntityLabel(id, Infinity), ...rest]),
        { header }
      ),
      `${filename}.csv`,
      'text/csv'
    )
  }, [filename, getData, getHeader, machine])
  const handleSaveAsImage = useCallback(async () => {
    if (dom.current) {
      const container = dom.current.querySelector('h2 + div') as HTMLDivElement | null
      const chart = dom.current.querySelector<HTMLElement>('[data-component="ChartContainer"] svg')

      if (container) {
        container.style.display = 'none'
      }

      if (chart) {
        // Disable tooltip from being in the screenshot
        chart.style.pointerEvents = 'none'
      }

      dom.current.querySelectorAll('text').forEach((tag) => {
        tag.style.fontFamily = '"Open Sans", Arial, Sans Serif'
      })

      try {
        const dataUrl = await (isIe() ? fallback(chart!) : toPng(dom.current))

        download(dataUrl, `${filename}.png`)
      } catch {
        dispatch(
          notificationActions.push(
            makeNotification({ message: 'Error generating image', type: NotificationTypes.Error })
          )
        )
      } finally {
        if (container) {
          container.style.display = ''
        }

        if (chart) {
          chart.style.pointerEvents = ''
        }
      }
    }
  }, [dispatch, dom, filename])
  const [downshiftOpen, setDownshiftOpen] = useState(false)
  const transition = useTransition(downshiftOpen, {
    config: config.stiff,
    enter: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(-16px)' },
    leave: { opacity: 0, transform: 'translateY(-16px)' },
  })

  return (
    <Downshift
      stateReducer={(_, changes) => {
        if (changes.isOpen !== undefined) {
          setDownshiftOpen(changes.isOpen)
        }

        return changes
      }}
    >
      {({ getItemProps, getMenuProps, toggleMenu }) => (
        <div className={className}>
          <Button className={styles.menuButton} handleClick={toggleMenu} title="Chart actions">
            <Cog title="" />
          </Button>
          {transition(
            (props, item, { key }) =>
              item && (
                <animated.div
                  {...getMenuProps({ className: styles.menu(Boolean(className)) })}
                  key={key}
                  style={props}
                >
                  <Button
                    {...getItemProps({
                      className: styles.button,
                      item: 'remove',
                      onClick: () => {
                        handleRemove()
                        toggleMenu()
                      },
                    })}
                  >
                    Remove Chart
                  </Button>
                  {canExportAnalytics ? (
                    <Button
                      {...getItemProps({
                        className: styles.button,
                        item: 'csv',
                        onClick: () => {
                          handleSaveAsCsv()
                          toggleMenu()
                        },
                      })}
                    >
                      Save As CSV
                    </Button>
                  ) : null}
                  <Button
                    {...getItemProps({
                      className: styles.button,
                      item: 'image',
                      onClick: () => {
                        handleSaveAsImage()
                        toggleMenu()
                      },
                    })}
                  >
                    Save As Image
                  </Button>
                </animated.div>
              )
          )}
        </div>
      )}
    </Downshift>
  )
}

export default ChartSettingsMenu
