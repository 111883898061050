import { css } from 'emotion'
import * as React from 'react'

import Button from '../../../components/Button'
import { colors, textStyles } from '../../../styles'

type ClearButtonProps = {
  active: boolean
  handleClick: () => void
}

const styles = {
  active: css({
    color: colors.pastelRed,
  }),
  inActive: css({
    color: colors.cloudyBlue,
  }),
  main: css(textStyles.paleGray2Bold10, {
    textTransform: 'uppercase',
  }),
}

const ClearButton = ({ active, handleClick }: ClearButtonProps) => (
  <Button
    active={active}
    className={css(styles.main, active ? styles.active : styles.inActive)}
    handleClick={handleClick}
  >
    Clear
  </Button>
)

export default ClearButton
