import { Feature } from '@juristat/common/types'

import { Action } from '../../redux'

type Permissions =
  | 'accountTools'
  | 'alpha'
  | 'analystMetrics'
  | 'api'
  | 'assignmentSheet'
  | 'bi'
  | 'charts'
  | 'clientConfig'
  | 'clientUsageReport'
  | 'confidentialData'
  | 'customerPpairSettings'
  | 'drafting'
  | 'editOarTimes'
  | 'examiner'
  | 'expertSearch'
  | 'expertSearchExport'
  | 'exportAnalytics'
  | 'freeHealthDashboard'
  | 'groupAdmin'
  | 'groupOwner'
  | 'humanTasks'
  | 'ids'
  | 'idsAssignmentSheet'
  | 'idsViewer'
  | 'oarBuild'
  | 'oarBuildQuality'
  | 'oarReview'
  | 'oarReviewQuality'
  | 'oarSender'
  | 'oarTech'
  | 'oarViewer'
  | 'oneOTwoReports'
  | 'personal'
  | 'platformExaminerAuTcFilters'
  | 'platformFirmAssigneeFilters'
  | 'platformOaRceCountFilters'
  | 'platformPatentFamilyComponent'
  | 'platformPremiumSorts'
  | 'platformRegCustNumFilters'
  | 'platformSavedSearchAndHistory'
  | 'platformSearchScopes'
  | 'platformThreePaneView'
  | 'ppair'
  | 'premiumWork'
  | 'rejectionBasisFilter'
  | 'rejections'
  | 'selfAnalystMetrics'
  | 'staffSettings'
  | 'smartshell'
  | 'table'

export type PermissionsState = Record<Permissions, boolean>

export type SessionPayload = {
  firstName: string
  groupId: string
  hmac: string
  lastName: string
  permissions: PermissionsState
  username: string
  uuid: string
}

export enum Access {
  AccountTools = 'account-tools',
  Alpha = 'alpha',
  AnalystMetrics = 'analyst-metrics',
  Api = 'api',
  ArtUnit = 'art-unit',
  AssignmentSheet = 'assignment-sheet',
  BusinessIntelligence = 'business-intelligence',
  Charts = 'charts',
  ClientConfig = 'client-config',
  ClientUsageReport = 'client-usage-report',
  Competitor = 'competitor',
  ConfidentialData = 'confidential-data',
  CorporateIntelligence = 'corporate-intelligence',
  CounselSelector = 'counsel-selector',
  CustomerPpairNumberSettings = 'customer-ppair-number-settings',
  Drafting = 'drafting',
  Examiner = 'examiner',
  ExpertSearch = 'expert-search',
  ExpertSearchExport = 'expert-search-export',
  ExportAnalytics = 'export-analytics',
  FreeHealthDashboard = 'free-health-dashboard',
  GroupAdmin = 'group-admin',
  GroupOwner = 'group-owner',
  HasSession = 'home',
  HumanTasks = 'human-tasks',
  Ids = 'ids',
  IdsAssignmentSheet = 'ids-assignment-sheet',
  IdsViewer = 'ids-viewer',
  Marketing = 'marketing',
  OarEditTimes = 'edit-oar-times',
  OarBuild = 'oar-build',
  OarBuildQuality = 'oar-build-quality',
  OarReview = 'oar-review',
  OarReviewQuality = 'oar-review-quality',
  OarSender = 'oar-sender',
  OarTech = 'oar-tech',
  OarViewer = 'oar-viewer',
  OneOTwoReports = 'one-o-two-reports',
  Personal = 'personal',
  Platform = 'platform',
  PlatformExaminerAuTcFilters = 'platform-examiner-au-tc-filters',
  PlatformFirmAssigneeFilters = 'platform-firm-assignee-filters',
  PlatformOaRceCountFilters = 'platform-oa-rce-count-filters',
  PlatformPatentFamilyComponent = 'platform-patent-family-component',
  PlatformPremiumSorts = 'platform-premium-sorts',
  PlatformRegCustNumFilters = 'platform-reg-cust-num-filters',
  PlatformSavedSearchAndHistory = 'platform-saved-search-and-history',
  PlatformSearchScopes = 'platform-search-scopes',
  PlatformThreePaneView = 'platform-three-pane-view',
  PremiumWork = 'premium-work',
  PrivatePair = 'ppair',
  RejectionBasisFilter = 'rejection-basis-filter',
  Rejections = 'rejections',
  SelfAnalystMetrics = 'self-analyst-metrics',
  Smartshell = 'smartshell',
  StaffSettings = 'staff-settings',
  Table = 'table',
  UspcClass = 'uspc',
  WorkflowAutomation = 'workflow-automation',
}

export type FeatureModalState = {
  focusFeature: Feature
  open: boolean
}

export type SessionState = {
  featureModal: FeatureModalState
  groupId: string
  permissions: PermissionsState
  username: string | null
  uuid: string
}

/* Actions */
export type ClearAction = Action<'session/CLEAR', null>
export type FeatureModalSetAction = Action<'session/featureModal/SET', Partial<FeatureModalState>>
export type SetAction = Action<'session/SET', SessionPayload>

export type Actions = ClearAction | FeatureModalSetAction | SetAction

export { Feature }
