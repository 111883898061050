// Maybe see this NarrowAction from here https://stackoverflow.com/a/50499316
// TODO Fix the types on renderFunctions
type TypedContentComponentProps<T extends string, C extends { type: T }> = {
  content: C
  renderFunctions: { [key in C['type']]: (props?: any) => JSX.Element | null }
}

const TypedContentComponent = <T extends string, C extends { type: T }>({
  content,
  renderFunctions,
}: TypedContentComponentProps<T, C>) => {
  const { type, ...rest } = content as { type: T }
  if (typeof renderFunctions[type] === 'function') {
    return renderFunctions[type](rest)
  }

  return null
}

export default TypedContentComponent
