import * as React from 'react'

import ScrollableOnHover from '../../../components/ScrollableOnHover'
import Toggle from '../../../components/Toggle'
import containsNumOrString from '../../../utils/containsNumOrString'
import { CheckListFilterCount } from '../types'

type CheckListProps = {
  handleInput: (on: boolean, value: CheckListFilterCount['value']) => void
  items: CheckListFilterCount[]
  selected: string[]
}

const styles = {
  checkListContainer: '[&_>_*]:mb-[10px]',
  label:
    '[&_h1]:overflow-x-hidden [&_h1]:overflow-ellipsis [&_h1]:whitespace-nowrap [&_h1]:w-[170px]',
  scrollContainer: 'max-h-[300px]',
}

const CheckList = ({ items = [], handleInput, selected }: CheckListProps) => (
  <ScrollableOnHover
    className={styles.scrollContainer}
    contentContainerClassName={styles.checkListContainer}
    dark
  >
    {items.map(({ apps, label, value }) => {
      const isSelected = containsNumOrString(value, selected)

      return (
        <Toggle
          className={styles.label}
          count={apps}
          label={label}
          key={value}
          on={isSelected}
          handleToggle={handleInput}
          value={value}
        />
      )
    })}
  </ScrollableOnHover>
)

export default CheckList
