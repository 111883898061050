import * as React from 'react'

import Copyright from '../Copyright'
import FooterLinks from './FooterLinks'

type FooterProps = {
  className?: string
}

const Footer = ({ className }: FooterProps) => (
  <footer
    className={`flex flex-col items-center border-t border-[#0000001a] p-4 ${className ?? ''}`}
  >
    <Copyright />
    <FooterLinks />
  </footer>
)

export default Footer
