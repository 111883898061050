import { css } from 'emotion'
import { contains } from 'ramda'
import React, { useMemo } from 'react'

import ButtonGroup from '../../../components/ButtonGroup'
import ScrollableOnHover from '../../../components/ScrollableOnHover'
import { textStyles } from '../../../styles'
import { CheckListFilterCount, IncludeOrExclude, TriState } from '../types'

type IncludeExcludeListProps<T extends string> = {
  handleSelection: (key: string, value: IncludeOrExclude | null) => void
  items: CheckListFilterCount[]
  selected: TriState<T>
}

const styles = {
  button: 'w-1/2',
  container: '[&_>_*]:mb-[10px]',
  count: css(textStyles.paleGray2Normal13),
  label: css(textStyles.paleGray2Semibold10, {
    textTransform: 'uppercase',
  }),
  labelContainer: css({
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
  }),
  main: css({
    paddingRight: 4,
  }),
  scrollContainer: 'max-h-[300px]',
}

const IncludeExcludeButtonGroup = <T extends string>({
  handleSelection,
  filterKey,
  value,
}: {
  handleSelection: IncludeExcludeListProps<T>['handleSelection']
  filterKey: string
  value: IncludeOrExclude | null
}) => (
  <ButtonGroup
    buttons={[
      {
        label: 'Include',
        value: IncludeOrExclude.Include,
      },
      {
        label: 'Exclude',
        value: IncludeOrExclude.Exclude,
      },
    ]}
    classNameButton={styles.button}
    update={(newValue) => {
      handleSelection(filterKey, newValue)
    }}
    value={value}
  />
)

const IncludeExcludeItem = <T extends string>({
  count,
  filterKey,
  handleSelection,
  label,
  value,
}: {
  count: number
  filterKey: string
  handleSelection: IncludeExcludeListProps<T>['handleSelection']
  label: string
  value: IncludeOrExclude | null
}) => (
  <div className={styles.main}>
    <div className={styles.labelContainer}>
      <label className={styles.label}>{label}</label>
      <div className={styles.count}>{count.toLocaleString()}</div>
    </div>
    <IncludeExcludeButtonGroup {...{ handleSelection, filterKey, value }} />
  </div>
)

const makeGetItemValue =
  <T extends string>(selected: IncludeExcludeListProps<T>['selected']) =>
  (key: T) => {
    if (selected.include && contains(key, selected.include)) {
      return IncludeOrExclude.Include
    }
    if (selected.exclude && contains(key, selected.exclude)) {
      return IncludeOrExclude.Exclude
    }

    return null
  }

const IncludeExcludeList = <T extends string>({
  handleSelection,
  items,
  selected,
}: IncludeExcludeListProps<T>) => {
  const getItemValue = useMemo(() => makeGetItemValue(selected), [selected])

  return (
    <ScrollableOnHover
      className={styles.scrollContainer}
      contentContainerClassName={styles.container}
      dark
    >
      {items.map(({ apps, label, value: key }) => (
        <IncludeExcludeItem
          count={apps}
          filterKey={key}
          handleSelection={handleSelection}
          key={key}
          label={label}
          value={getItemValue(key as T)}
        />
      ))}
    </ScrollableOnHover>
  )
}

export default IncludeExcludeList
