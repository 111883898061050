import { identity, isEmpty, not, reject } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import { matchPath } from 'react-router-dom'

import { getPathname } from '../modules/router/selectors'

type VisibleAtPathnameProps<T> = {
  exact?: boolean
  invert?: boolean
  pathname: string | string[]
  render: (props: Omit<VisibleAtPathnameProps<T>, 'render'>) => React.ReactElement<T> | null
}

const VisibleAtPathname = <T,>({
  exact = false,
  invert = false,
  pathname,
  render,
}: VisibleAtPathnameProps<T>) => {
  const currentPathname = useSelector(getPathname)
  const pathnamesToCheck = Array.isArray(pathname) ? pathname : [pathname]
  const modifier = invert ? identity : not
  const isMatch = (pathnameToCheck: string) =>
    !!matchPath(currentPathname, { path: pathnameToCheck, exact })
  const shouldRender = modifier(isEmpty(reject(not, pathnamesToCheck.map(isMatch))))

  if (shouldRender) {
    return render({ exact, invert, pathname })
  }

  return null
}

export default VisibleAtPathname
