import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Delete = (props: IconProps) => (
  <SVG title="delete" viewBox="0 0 11 11" {...props}>
    <path d="M5.49993 0.550049C4.52091 0.550049 3.56388 0.840361 2.74986 1.38427C1.93583 1.92819 1.30138 2.70127 0.926725 3.60577C0.552072 4.51026 0.454045 5.50554 0.645042 6.46575C0.836039 7.42595 1.30748 8.30796 1.99975 9.00023C2.69202 9.6925 3.57403 10.1639 4.53423 10.3549C5.49444 10.5459 6.48972 10.4479 7.39421 10.0733C8.2987 9.6986 9.07179 9.06414 9.6157 8.25012C10.1596 7.4361 10.4499 6.47907 10.4499 5.50005C10.4484 4.18768 9.92645 2.9295 8.99846 2.00151C8.07048 1.07353 6.81229 0.551536 5.49993 0.550049V0.550049ZM5.49993 10.01C4.60793 10.01 3.73597 9.74554 2.99431 9.24998C2.25264 8.75441 1.67458 8.05005 1.33323 7.22595C0.991881 6.40186 0.902568 5.49505 1.07659 4.62019C1.25061 3.74534 1.68014 2.94173 2.31088 2.311C2.94161 1.68026 3.74522 1.25073 4.62007 1.07671C5.49492 0.902688 6.40173 0.992001 7.22583 1.33335C8.04992 1.6747 8.75429 2.25276 9.24986 2.99443C9.74542 3.73609 10.0099 4.60805 10.0099 5.50005C10.0086 6.69576 9.53298 7.84211 8.68748 8.6876C7.84199 9.5331 6.69564 10.0087 5.49993 10.01V10.01Z" />
    <path d="M7.76444 2.92456L5.49998 5.18902L3.23552 2.92456L2.92444 3.23564L5.1889 5.5001L2.92444 7.76456L3.23552 8.07564L5.49998 5.81118L7.76444 8.07564L8.07552 7.76456L5.81106 5.5001L8.07552 3.23564L7.76444 2.92456Z" />
  </SVG>
)

export default Delete
