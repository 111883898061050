import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Add = (props: IconProps) => (
  <SVG title="add" viewBox="0 0 14 14" {...props}>
    <path d="M7.00016 1.45837C5.90413 1.45837 4.8327 1.78339 3.92138 2.39231C3.01006 3.00124 2.29977 3.86673 1.88033 4.87934C1.4609 5.89194 1.35115 7.00619 1.56498 8.08117C1.77881 9.15614 2.3066 10.1436 3.08161 10.9186C3.85663 11.6936 4.84406 12.2214 5.91904 12.4352C6.99402 12.6491 8.10826 12.5393 9.12087 12.1199C10.1335 11.7004 10.999 10.9901 11.6079 10.0788C12.2168 9.1675 12.5418 8.09608 12.5418 7.00004C12.5418 5.5303 11.958 4.12075 10.9187 3.08149C9.87945 2.04223 8.4699 1.45837 7.00016 1.45837V1.45837ZM7.00016 11.9584C6.0195 11.9584 5.06086 11.6676 4.24546 11.1227C3.43007 10.5779 2.79455 9.80353 2.41926 8.89751C2.04398 7.9915 1.94579 6.99454 2.1371 6.03272C2.32842 5.0709 2.80066 4.1874 3.49409 3.49397C4.18753 2.80053 5.07102 2.3283 6.03284 2.13698C6.99466 1.94566 7.99162 2.04385 8.89764 2.41914C9.80365 2.79442 10.578 3.42994 11.1229 4.24534C11.6677 5.06073 11.9585 6.01937 11.9585 7.00004C11.9585 8.31507 11.4361 9.57624 10.5062 10.5061C9.57637 11.436 8.3152 11.9584 7.00016 11.9584V11.9584Z" />
    <path d="M7.29183 4.08337H6.7085V6.70837H4.0835V7.29171H6.7085V9.91671H7.29183V7.29171H9.91683V6.70837H7.29183V4.08337Z" />
  </SVG>
)

export default Add
