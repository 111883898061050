import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Refresh = (props: IconProps) => (
  <SVG title="refresh" viewBox="0 0 14 14" {...props}>
    <g>
      <g transform="translate(-1164.000000, -182.000000)">
        <g transform="translate(990.000000, 182.000000)">
          <path d="M184.38625,0.875 L187.5625,0.875 C187.804,0.875 188,0.678125 188,0.4375 C188,0.196875 187.804,0 187.5625,0 L183.1875,0 C183.134562,0 182.75,0 182.75,0.4375 L182.75,4.8125 C182.75,5.053125 182.946,5.25 183.1875,5.25 C183.429,5.25 183.625,5.053125 183.625,4.8125 L183.625,1.47 C185.6935,2.454375 187.125,4.55875 187.125,7 C187.125,10.381875 184.38275,13.125 181,13.125 C177.61725,13.125 174.875,10.381875 174.875,7 C174.875,3.915625 177.157875,1.369375 180.125,0.945 L180.125,0.06125 C176.672687,0.494375 174,3.43 174,7 C174,10.8675 177.13425,14 181,14 C184.86575,14 188,10.8675 188,7 C188,4.361875 186.5405,2.069375 184.38625,0.875" />
        </g>
      </g>
    </g>
  </SVG>
)

export default Refresh
