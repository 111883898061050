import { GridItem, IntelligenceEntityType } from '@juristat/common/types'
import { ResponsiveStyleOptions } from 'muuri-react'
import { Key } from 'react'

export type Draggable = { draggable?: boolean }

export type GridItemProps = {
  data?: Record<string, unknown>
  itemKey: Key | null
  style?: ResponsiveStyleOptions
}

export type IntelligenceReport = {
  entityType: IntelligenceEntityType
  id?: string
  items: GridItem[]
}

export { GridItem, GridItemType, TopXForYTableGridItem } from '@juristat/common/types'
