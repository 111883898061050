import { useVisibility } from 'muuri-react'
import React from 'react'

import { GridItemProps } from '../../types'
import BaseGridItem from './BaseGridItem'

const HiddenGridItem: React.FC<GridItemProps & { children?: React.ReactNode }> = (props) => {
  const setVisibility = useVisibility()

  setVisibility(false, { instant: true })

  return <BaseGridItem {...props} />
}

export default HiddenGridItem
