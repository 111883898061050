import { Dispatch, createContext } from 'react'

export type AlertActions<T> = {
  selected: (payload: T) => boolean
  update: (payload: T) => void
}

export const BulkAlertsLoadingContext = createContext<boolean | undefined>(undefined)
export const SelectedAlertsContext = createContext<AlertActions<any> | undefined>(undefined)
export const ToggleModalContext = createContext<Dispatch<void> | undefined>(undefined)
