import React from 'react'

export default function Copyright() {
  return (
    <div className="text-xs font-bold">
      Copyright © 2014–
      {new Date().getFullYear()} Juristat, Inc. All rights reserved.
    </div>
  )
}
