import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const BI = (props: IconProps) => (
  <SVG title="bi" viewBox="0 0 20 20" {...props}>
    <path
      d="M2,1 C1.44771525,1 1,1.44771525 1,2 L1,18 C1,18.5522847 1.44771525,19 2,19 L18,19 C18.5522847,19 19,18.5522847 19,18 L19,2 C19,1.44771525 18.5522847,1 18,1 L2,1 Z M2,0 L18,0 C19.1045695,-2.02906125e-16 20,0.8954305 20,2 L20,18 C20,19.1045695 19.1045695,20 18,20 L2,20 C0.8954305,20 1.3527075e-16,19.1045695 0,18 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z"
      fillRule="nonzero"
    />
    <path d="M6,7 L8.22167969,7 C9.23405454,7 9.96891047,7.14404153 10.4262695,7.43212891 C10.8836286,7.72021628 11.1123047,8.17838228 11.1123047,8.80664062 C11.1123047,9.23307505 11.012208,9.58300645 10.8120117,9.85644531 C10.6118154,10.1298842 10.3457048,10.2942706 10.0136719,10.3496094 L10.0136719,10.3984375 C10.4661481,10.4993495 10.7924795,10.6881497 10.9926758,10.9648438 C11.1928721,11.2415378 11.2929688,11.6093727 11.2929688,12.0683594 C11.2929688,12.7194043 11.0577823,13.2272117 10.5874023,13.5917969 C10.1170224,13.956382 9.47819414,14.1386719 8.67089844,14.1386719 L6,14.1386719 L6,7 Z M7.51367188,9.82714844 L8.39257812,9.82714844 C8.80273643,9.82714844 9.09977122,9.76367251 9.28369141,9.63671875 C9.4676116,9.50976499 9.55957031,9.29980615 9.55957031,9.00683594 C9.55957031,8.73339707 9.45947366,8.53727273 9.25927734,8.41845703 C9.05908103,8.29964133 8.74251519,8.24023438 8.30957031,8.24023438 L7.51367188,8.24023438 L7.51367188,9.82714844 Z M7.51367188,11.0283203 L7.51367188,12.8886719 L8.5,12.8886719 C8.91666875,12.8886719 9.22428286,12.8089201 9.42285156,12.6494141 C9.62142026,12.4899081 9.72070312,12.2457699 9.72070312,11.9169922 C9.72070312,11.3245413 9.29753027,11.0283203 8.45117188,11.0283203 L7.51367188,11.0283203 Z M12.71875,14.1386719 L12.71875,7 L14.2324219,7 L14.2324219,14.1386719 L12.71875,14.1386719 Z" />
  </SVG>
)

export default BI
