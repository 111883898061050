import { isNilOrEmpty } from '@juristat/common/utils'
import { head, prop, reject, values } from 'ramda'

import { Definition } from '../types'

const getPhraseFromDefinition = (definition: Definition): string =>
  head(
    reject(isNilOrEmpty, [
      head(values(prop('searches', definition) ?? {})),
      head(values(prop('similarTo', definition) ?? {})),
    ])
  ) ?? ''

export default getPhraseFromDefinition
