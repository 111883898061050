import { Action } from '../../redux'

enum DialogueType {
  Destructive = 'destructive',
  Normal = 'normal',
  Warning = 'warning',
}

type DialogueEvents =
  | { type: 'OPEN'; data: DialogueOptions<unknown> }
  | { type: 'CONFIRM' }
  | { type: 'DISMISS' }

type DialogueOptions<T> = Partial<{
  actionText: string
  key: T
  text: string
  type: DialogueType
}>

type DialogueState<T> = DialogueOptions<T> | null

type CreateDialogueAction = Action<'dialogue/CREATE', DialogueOptions<unknown>>
type ConfirmDialogueAction = Action<'dialogue/CONFIRM', undefined>
type DismissDialogueAction = Action<'dialogue/DISMISS', undefined>

type ReducerActions = CreateDialogueAction | ConfirmDialogueAction | DismissDialogueAction

export {
  ConfirmDialogueAction,
  CreateDialogueAction,
  DialogueEvents,
  DialogueOptions,
  DialogueState,
  DialogueType,
  DismissDialogueAction,
  ReducerActions,
}
