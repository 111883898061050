import * as React from 'react'

import SVG from '../SVG'
import { IconProps } from '../types'

const LineChartSolid = (props: IconProps) => (
  <SVG title="line-chart-solid" {...props}>
    <path d="M32,5H4A2,2,0,0,0,2,7V29a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V7A2,2,0,0,0,32,5ZM22.56,25.94l-7.1-10.58L9.12,24.64l-4.5-4L6,19.05l2.7,2.39,6.76-9.88,7.19,10.71,7-9.27,1.7,1.28Z" />
    <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
  </SVG>
)

export default LineChartSolid
