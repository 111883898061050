import * as React from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../components/Button'
import MaybeRender from '../../../components/MaybeRender'
import { useHasAccess } from '../../session/hooks'
import { Access } from '../../session/types'
import NavBarItem from './NavBarItem'
import Tooltip from './Tooltip'

type NavBarIconProps = {
  active?: boolean
  canAccess: Access | Access[]
  className?: string
  handleClick?: () => void
  href?: string
  icon: (props?: any) => JSX.Element
  tooltip: string
}

export function NavBarIconWithAccess({
  active,
  className,
  handleClick,
  href,
  icon,
  tooltip,
}: Omit<NavBarIconProps, 'canAccess'>) {
  const child = icon({
    'data-testid': `tooltip-${tooltip}`,
    height: 20,
    title: '',
    width: 20,
  })

  return (
    <Tooltip tooltip={tooltip} direction="right">
      <NavBarItem active={active} className={className}>
        {(props) => (
          <>
            {href ? (
              <Link {...props} to={href}>
                {child}
              </Link>
            ) : (
              <Button aria-label={tooltip} {...props} handleClick={handleClick}>
                {child}
              </Button>
            )}
          </>
        )}
      </NavBarItem>
    </Tooltip>
  )
}

export default function NavBarIcon(props: NavBarIconProps) {
  const { canAccess, ...iconElementProps } = props
  const hasAccess = useHasAccess(canAccess)

  return (
    <MaybeRender render={() => <NavBarIconWithAccess {...iconElementProps} />} when={hasAccess} />
  )
}
