import { css } from 'emotion'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../components/Button'
import { AppState } from '../../../redux'
import { colors, makeTransition, textStyles } from '../../../styles'
import actions from '../actions'
import FilterContext from '../context/filterContext'
import isClearAllEnabled from '../selectors/isClearAllEnabled'

const styles = {
  button: css(textStyles.paleGray2Bold12, {
    color: colors.cloudyBlue,
    padding: '10px 20px',
    textTransform: 'uppercase',
    transition: makeTransition('color'),
  }),
  buttonEnabled: css({
    color: colors.pastelRed,
  }),
}
const ClearAllButton: React.FC = () => {
  const { meta } = useContext(FilterContext)
  const dispatch = useDispatch()
  const enabled = useSelector((state: AppState) => isClearAllEnabled(state, meta))

  return (
    <Button
      active={enabled}
      className={css(styles.button, enabled && styles.buttonEnabled)}
      handleClick={() => dispatch(actions.clearAll(undefined, meta))}
    >
      Clear All
    </Button>
  )
}

export default ClearAllButton
