import { NewsItem } from '@juristat/common/news'

import { Action } from '../../redux'
import { HttpContent } from '../http/types'

type PortfolioSummaryData = {
  id: string
  numApps: number
  numEntities: number
  title: string
}

type EntityData = {
  allowanceRate: number
  averageDaysToDisposition: number
  averageOfficeActionsToDisposition: number
  id: number
  name: string
}

enum EntityType {
  Assignees = 'assignees',
  Firms = 'firms',
}

type HomeState = {
  news: HttpContent<NewsItem[]>
  portfolios: HttpContent<PortfolioSummaryData[]>
  topAssignees: HttpContent<EntityData[]>
  topFirms: HttpContent<EntityData[]>
}

type FetchMyPortfoliosAction = Action<'home/FETCH_MY_PORTFOLIOS', undefined>
type FetchTopAssigneesAction = Action<'home/FETCH_TOP_ASSIGNEES', undefined>
type FetchTopFirmsAction = Action<'home/FETCH_TOP_FIRMS', undefined>

type SetMyPortfoliosAction = Action<'home/SET_MY_PORTFOLIOS', HomeState['portfolios']>
type SetTopAssigneesAction = Action<'home/SET_TOP_ASSIGNEES', HomeState['topAssignees']>
type SetTopFirmsAction = Action<'home/SET_TOP_FIRMS', HomeState['topFirms']>

type NewsErrorAction = Action<'home/news/ERROR', string>
type NewsFetchAction = Action<'home/news/FETCH', undefined>
type NewsGetAction = Action<'home/news/GET', undefined>
type NewsSetAction = Action<'home/news/SET', NewsItem[]>

type ReducerActions =
  | NewsErrorAction
  | NewsFetchAction
  | NewsSetAction
  | SetMyPortfoliosAction
  | SetTopAssigneesAction
  | SetTopFirmsAction

type TopEntityByPatentsIssuedResponse = GraphQLResponse<{
  applicationSet: {
    metrics: Array<{
      buckets: Array<{
        object: {
          id: number
          name: string
          applicationSet: {
            metrics: Array<{
              allowanceRate: number
              timing: { daysToDisposition: { average: number } }
              officeActions: { toDisposition: { average: number } }
            }>
          }
        }
      }>
    }>
  }
}>

type GetPortfoliosResponse = {
  reports: Array<{
    id: string
    name: string
    searches: Array<{ dataApiQuery: { dataApiUid: string } }>
  }>
}

export {
  EntityData,
  EntityType,
  FetchMyPortfoliosAction,
  FetchTopAssigneesAction,
  FetchTopFirmsAction,
  GetPortfoliosResponse,
  HomeState,
  NewsErrorAction,
  NewsFetchAction,
  NewsGetAction,
  NewsSetAction,
  PortfolioSummaryData,
  ReducerActions,
  SetMyPortfoliosAction,
  SetTopAssigneesAction,
  SetTopFirmsAction,
  TopEntityByPatentsIssuedResponse,
}
