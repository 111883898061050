import { isNil, map, path, pathOr, reject } from 'ramda'

import { Definition, SortOrderVariables } from '../types'

const getSortOrderFromDefinition = (definition: Definition = {}) =>
  pathOr(
    null,
    ['0'],
    map(
      (ordering: SortOrderVariables) =>
        reject(isNil, {
          direction: path(['orderingDirection'], ordering),
          field: path(['orderingType'], ordering),
        }),
      pathOr([], ['orderings'], definition)
    )
  )

export default getSortOrderFromDefinition
