import { call, put, takeLatest } from 'redux-saga/effects'

import { ppairApi } from '../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import * as query from '../queries/setCustNoWhitelist.graphql'
import { ApplyChangesAction, SponsorshipsStatus } from '../types'

function* applyChangesToSponsorship(action: ApplyChangesAction) {
  const { customerNumberList, sponsoredEmail } = action.payload!
  const errorAction = actions.setSponsorships({
    message: `Error occured while setting the status of customer numbers on sponsored email: ${sponsoredEmail}`,
    type: SponsorshipsStatus.ErrorSaving,
  })

  try {
    const response = yield call(ppairApi, query, {
      numbers: customerNumberList.map(({ whitelisted, lastSeen, ...rest }) => ({
        ...rest,
        whitelist: whitelisted,
      })),
      sponsoredEmail,
    })

    if (response.ok) {
      yield put(actions.updateSponsorship(action.payload))
      yield put(
        notificationActions.push(
          makeNotification({
            message: 'Customer number whitelist saved successfully',
            type: NotificationTypes.Success,
          })
        )
      )
    } else {
      yield put(errorAction)
    }
  } catch (error) {
    yield put(errorAction)
  }
}

function* watchApplyChanges() {
  yield takeLatest(actions.applyChanges().type, applyChangesToSponsorship)
}

export { applyChangesToSponsorship }
export default watchApplyChanges
