import { createSelector } from 'reselect'

import { AppState } from '../../../redux'
import { DrawerState, NavigationState } from '../types'

const getNavigation = (state: AppState): NavigationState => state.navigation

export const getDrawerState = createSelector(
  getNavigation,
  (state: NavigationState): DrawerState => state.drawer
)
