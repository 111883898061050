import { createContext } from 'react'

type IntercomAction =
  | 'addEntityClick'
  | 'createDashboardClick'
  | 'editChartsClick'
  | 'editColumnsClick'
  | 'filterApplyClick'
  | 'ppairView'
  | 'saveSearchClick'
  | 'tableViewExportClick'

type IntercomActions = { setAlertConfigurations: (params: [number, number]) => void } & {
  [K in IntercomAction]: () => void
}

export const IntercomActionsContext = createContext<IntercomActions | undefined>(undefined)
