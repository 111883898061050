import { CSSObject } from 'create-emotion'
import React from 'react'

import PrintStyles from '../../../components/PrintStyles'

type Styles = {
  [key: string]: CSSObject
}

const styles: Styles = {
  sharedStyles: {
    [`
    & [data-component$="SettingsMenu"],
    & [data-component="ModalWithTrigger"],
    & [data-component="PillsContainer"] button,
    & [data-component="ScrollableOnHover"] > div > [data-component="PrintStyles"],
  `]: {
      display: 'none !important',
    },
    '& [data-component="ResultsContainerComponent"]': {
      display: 'block',
      height: 'auto',
      overflow: 'visible',
    },
    [`
      & [data-component="ChartDropTargetComponent"],
      & [data-component="DragHandle"]
    `]: {
      display: 'none',
    },
    '& .css-1qvxa5g': {
      maxWidth: 'none',
    },
  },
}

const GridItemsPrintStyles = (props: { children: React.ReactNode }) => (
  <PrintStyles {...styles} {...props} />
)

export default GridItemsPrintStyles
