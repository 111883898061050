import { OmnisearchType } from '@juristat/common/types'

type OmnisearchResult = {
  description: string
  key: string
  id: string
  type: OmnisearchType
}

export { OmnisearchResult, OmnisearchType }
