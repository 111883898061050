import React from 'react'
import { DragDropContextProvider } from 'react-dnd'
import Html5Backend from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'

import Auth0ContextProvider from '../modules/auth/components/Auth0ContextProvider'
import DialogueContextProvider from '../modules/dialogue/components/DialogueContextProvider'
import createStore from '../redux/createStore'
import { history } from '../redux/middleware'
import App from './App'
import ConnectedRouter from './ConnectedRouter'

const store = createStore()

const Root = (): JSX.Element => (
  // @ts-expect-error Types for `Provider` don't include `children` because of newer `react` types
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Auth0ContextProvider>
        <DialogueContextProvider>
          {/* @ts-expect-error Types for `DragDropContextProvider` don't include `children` because of newer `react` types */}
          <DragDropContextProvider backend={Html5Backend}>
            <App />
          </DragDropContextProvider>
        </DialogueContextProvider>
      </Auth0ContextProvider>
    </ConnectedRouter>
  </Provider>
)

export default Root
