import makeAction from '../../../../../redux/utils/makeAction'
import {
  ErrorAction,
  FetchAction,
  GetAction,
  RenameAction,
  SearchErrorAction,
  SearchFetchAction,
  SearchGetAction,
  SearchSetAction,
  SetAction,
  TokenAddAction,
  TokenCreateAction,
  TokenExpireAction,
  TokenInvalidateAction,
  TypeaheadErrorAction,
  TypeaheadFetchAction,
  TypeaheadGetAction,
  TypeaheadSetAction,
} from '../types'

const error = makeAction<ErrorAction>('account/group/ERROR')
const fetch = makeAction<FetchAction>('account/group/FETCH')
const get = makeAction<GetAction>('account/group/GET')
const rename = makeAction<RenameAction>('account/group/RENAME')
const set = makeAction<SetAction>('account/group/SET')

const search = {
  error: makeAction<SearchErrorAction>('account/group/search/ERROR'),
  fetch: makeAction<SearchFetchAction>('account/group/search/FETCH'),
  get: makeAction<SearchGetAction>('account/group/search/GET'),
  set: makeAction<SearchSetAction>('account/group/search/SET'),
}

const token = {
  add: makeAction<TokenAddAction>('account/group/token/ADD'),
  create: makeAction<TokenCreateAction>('account/group/token/CREATE'),
  expire: makeAction<TokenExpireAction>('account/group/token/EXPIRE'),
  invalidate: makeAction<TokenInvalidateAction>('account/group/token/INVALIDATE'),
}

const typeahead = {
  error: makeAction<TypeaheadErrorAction>('account/group/typeahead/ERROR'),
  fetch: makeAction<TypeaheadFetchAction>('account/group/typeahead/FETCH'),
  get: makeAction<TypeaheadGetAction>('account/group/typeahead/GET'),
  set: makeAction<TypeaheadSetAction>('account/group/typeahead/SET'),
}

export default {
  error,
  fetch,
  get,
  rename,
  search,
  set,
  token,
  typeahead,
}
