import * as React from 'react'

import SVG from '../SVG'
import { IconProps } from '../types'

const CheckCircleSolid = (props: IconProps) => (
  <SVG title="check-circle-solid" {...props}>
    <path d="M30,18A12,12,0,1,1,18,6,12,12,0,0,1,30,18Zm-4.77-2.16a1.4,1.4,0,0,0-2-2l-6.77,6.77L13,17.16a1.4,1.4,0,0,0-2,2l5.45,5.45Z" />
  </SVG>
)

export default CheckCircleSolid
