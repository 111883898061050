import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Analytics = (props: IconProps) => (
  <SVG title="analytics" viewBox="0 0 13 13" {...props}>
    <path d="M10.9931 0.812499L9.7744 0.743436C9.7207 0.740146 9.66689 0.747553 9.61608 0.765228C9.56527 0.782902 9.51848 0.810492 9.47841 0.846396C9.43835 0.882299 9.40582 0.925802 9.38271 0.974379C9.3596 1.02296 9.34636 1.07564 9.34377 1.12937C9.33822 1.23649 9.37526 1.34144 9.4468 1.42136C9.51834 1.50127 9.61858 1.54964 9.72565 1.55594H9.94908L7.5644 3.65625H4.87502C4.77819 3.65624 4.68455 3.69082 4.61096 3.75375L1.76721 6.19125C1.68532 6.26128 1.63461 6.36098 1.62623 6.4684C1.61785 6.57582 1.65249 6.68218 1.72252 6.76406C1.79255 6.84595 1.89225 6.89666 1.99967 6.90504C2.1071 6.91342 2.21345 6.87878 2.29533 6.80875L5.02533 4.46875H7.71877C7.81752 4.46861 7.91283 4.4325 7.9869 4.36719L10.5097 2.15719V2.40906C10.5018 2.51556 10.5362 2.62089 10.6054 2.70224C10.6746 2.78359 10.773 2.83443 10.8794 2.84375H10.9038C11.0074 2.84394 11.1072 2.8045 11.1827 2.7335C11.2583 2.66251 11.3038 2.56534 11.31 2.46187L11.3791 1.24312C11.3824 1.18943 11.375 1.13562 11.3573 1.08481C11.3396 1.034 11.312 0.987203 11.2761 0.947142C11.2402 0.907081 11.1967 0.87455 11.1481 0.851437C11.0996 0.828323 11.0469 0.815088 10.9931 0.812499Z" />
    <path d="M11.7812 11.375H10.9688V4.875C10.9688 4.76726 10.9259 4.66392 10.8498 4.58774C10.7736 4.51155 10.6702 4.46875 10.5625 4.46875H8.9375C8.82976 4.46875 8.72642 4.51155 8.65024 4.58774C8.57405 4.66392 8.53125 4.76726 8.53125 4.875V11.375H7.71875V6.90625C7.71875 6.79851 7.67595 6.69517 7.59976 6.61899C7.52358 6.5428 7.42024 6.5 7.3125 6.5H5.6875C5.57976 6.5 5.47642 6.5428 5.40024 6.61899C5.32405 6.69517 5.28125 6.79851 5.28125 6.90625V11.375H4.46875V8.53125C4.46875 8.42351 4.42595 8.32017 4.34976 8.24399C4.27358 8.1678 4.17024 8.125 4.0625 8.125H2.4375C2.32976 8.125 2.22642 8.1678 2.15024 8.24399C2.07405 8.32017 2.03125 8.42351 2.03125 8.53125V11.375H1.21875C1.11101 11.375 1.00767 11.4178 0.931488 11.494C0.855301 11.5702 0.8125 11.6735 0.8125 11.7812C0.8125 11.889 0.855301 11.9923 0.931488 12.0685C1.00767 12.1447 1.11101 12.1875 1.21875 12.1875H11.7812C11.889 12.1875 11.9923 12.1447 12.0685 12.0685C12.1447 11.9923 12.1875 11.889 12.1875 11.7812C12.1875 11.6735 12.1447 11.5702 12.0685 11.494C11.9923 11.4178 11.889 11.375 11.7812 11.375Z" />
  </SVG>
)

export default Analytics
