import { equals } from 'ramda'
import { Interpreter } from 'xstate'

import { Context, FetchEvent, FetchState, QueryKey } from '../types'
import { getQueryKey } from '../utils'

type ServiceMeta = {
  cacheTimeout: NodeJS.Timer | null
  queryKey: QueryKey
  service: Interpreter<Context<any, any>, any, FetchEvent<any>, FetchState<Context<any, any>>>
  subscribers: number
}

const services = new Map<string, ServiceMeta>()

// TODO: Figure out something better
if (process.env.NODE_ENV === 'test') {
  afterEach(() => services.clear())
}

export function getQueryData(queryKey: QueryKey) {
  return Array.from(services.keys())
    .map((key) => services.get(key)!)
    .filter((meta) => equals(meta.queryKey, queryKey))
}

export function refetchQueries(queryKey: QueryKey) {
  const arrayQueryKey = Array.isArray(queryKey) ? queryKey : [queryKey]

  services.get(getQueryKey(arrayQueryKey))?.service?.send('REFETCH')

  Array.from(services.values())
    .filter((meta) => equals(meta.queryKey, queryKey))
    .forEach((meta) => meta.service?.send('REFETCH'))
}

export default function useServicesMeta() {
  return services
}
