import { parse } from 'qs'
import { props } from 'ramda'
import { useLocation } from 'react-router-dom'

export function useQueryStringParam<T extends string | string[]>(
  key: T
): T extends string ? string | undefined : string[] {
  const { search } = useLocation()
  const parsed = parse(search, { ignoreQueryPrefix: true }) as Record<string, any>

  return typeof key === 'string' ? parsed[key] : props(key, parsed)
}
