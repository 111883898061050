import { css } from 'emotion'
import { pipe } from 'ramda'
import * as React from 'react'

import Button from '../../../components/Button'
import { colors, textStyles } from '../../../styles'
import { useIntercomActions } from '../../analytics/hooks'

type ApplyButtonProps = {
  active: boolean
  handleClick: () => void
}

const styles = {
  active: css({
    backgroundColor: colors.greenyBlue,
    border: 0,
  }),
  inActive: css({
    background: 'none',
    border: `0.5px solid ${colors.paleGray2}`,
    borderRadius: 1,
  }),
  main: css(textStyles.paleGray2Bold10, {
    height: 30,
    outline: 0,
    textTransform: 'uppercase',
    width: 66,
  }),
}

const ApplyButton = ({ active, handleClick }: ApplyButtonProps) => {
  const { filterApplyClick } = useIntercomActions()

  return (
    <Button
      active={active}
      className={css(styles.main, active ? styles.active : styles.inActive)}
      handleClick={pipe(handleClick, filterApplyClick)}
    >
      Apply
    </Button>
  )
}

export default ApplyButton
