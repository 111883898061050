import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Export = (props: IconProps) => (
  <SVG title="export" viewBox="0 0 12 14" {...props}>
    <path d="M7.6,0 L1.701,0 C0.762,0 0,0.761 0,1.7 L0,11.9 C0,12.839 0.762,13.6 1.701,13.6 L1.924,13.6 L1.981,13.6 L2.014,13.6 L2.014,13.595 C2.25,13.582 2.439,13.39 2.439,13.15 C2.439,12.91 2.25,12.719 2.014,12.705 L2.014,12.7 L1.979,12.7 L1.924,12.7 L1.701,12.7 C1.259,12.7 0.9,12.342 0.9,11.9 L0.9,1.7 C0.9,1.258 1.259,0.9 1.701,0.9 L7.23,0.9 L10.1,3.75 L10.1,11.9 C10.1,12.342 9.743,12.7 9.3,12.7 L9.3,12.703 C9.294,12.703 9.289,12.7 9.284,12.7 C9.036,12.7 8.833,12.901 8.833,13.15 C8.833,13.398 9.036,13.6 9.284,13.6 C9.289,13.6 9.294,13.597 9.3,13.597 L9.3,13.6 C10.24,13.6 11,12.839 11,11.9 L11,3.375 L7.6,0 Z" />
    <path
      d="M7.3,1.0863961 L7.3,2.6 C7.3,3.29035594 7.85964406,3.85 8.55,3.85 L10.0636039,3.85 L7.3,1.0863961 Z M10.55,4.75 L10.15,4.75 L8.55,4.75 C7.36258779,4.75 6.4,3.78741221 6.4,2.6 L6.4,0.6 C6.4,0.410616762 6.518572,0.241501055 6.696614,0.176948306 L7.13695824,0.217293 C7.30118015,0.157751008 7.48502289,-0.00137321031 7.60854229,0.122146187 L10.8679722,3.38157612 C10.9523221,3.465926 10.9997293,3.58031612 10.9997741,3.69960486 L11,4.29983069 C11.0000935,4.54842493 10.7985943,4.75 10.55,4.75 Z"
      fillRule="nonzero"
    />
    <path d="M5.97226667,11.8213333 L5.97226667,6.1676 C5.97226667,5.90953333 5.7632,5.7 5.5056,5.7 C5.248,5.7 5.03893333,5.90953333 5.03893333,6.1676 L5.03893333,11.8390667 L3.49986667,9.97333333 C3.31693333,9.789 3.02013333,9.789 2.8372,9.97333333 C2.65426667,10.1567333 2.65426667,10.4549333 2.8372,10.6388 L5.14346667,13.4346 C5.241,13.5326 5.37026667,13.5746 5.49813333,13.5676 C5.626,13.5746 5.75573333,13.5326 5.85326667,13.4346 L8.15953333,10.6388 C8.34246667,10.4549333 8.34246667,10.1567333 8.15953333,9.97333333 C7.9766,9.789 7.6798,9.789 7.49686667,9.97333333 L5.97226667,11.8213333 Z" />
  </SVG>
)

export default Export
