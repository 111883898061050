import { path } from 'ramda'

import { ActiveReducer } from '../../filter/types'
import enforceMinimumDate from './enforceMinimumDate'

const defaultOfficeActionDateFilter = (filters: ActiveReducer) => ({
  officeActionDate: {
    end: path(['dispositionDate', 'end'], filters),
    start: enforceMinimumDate(path(['filingDate', 'start'], filters)),
  },
})

export default defaultOfficeActionDateFilter
