import { SearchScope } from '@juristat/common/types'
import { isEmpty, keys, not, prop, reject, values } from 'ramda'

import { Definition, SearchType } from '../types'

const getScopesAndTypeFromDefinition = (
  definition: Definition
): { scopes: SearchScope[]; type: SearchType } => {
  const getFirstTruthyFromKey = (key: keyof Definition) =>
    keys(prop(key, definition)) as SearchScope[]

  const type = not(isEmpty(reject(isEmpty, values(definition.similarTo ?? {}))))
    ? SearchType.SimilarTo
    : SearchType.Keyword
  const scopes =
    type === SearchType.Keyword
      ? getFirstTruthyFromKey('searches')
      : getFirstTruthyFromKey('similarTo')

  return {
    scopes,
    type,
  }
}

export default getScopesAndTypeFromDefinition
