import { NewsItem } from '@juristat/common/news'
import { call, put, takeLatest } from 'redux-saga/effects'

import { appApi } from '../../api'
import actions from '../actions'

function* getNews() {
  yield put(actions.news.fetch())

  try {
    const response = yield call(appApi, '/news')

    if (response.ok) {
      const result: NewsItem[] = yield call([response, 'json'])

      yield put(actions.news.set(result))
    } else {
      yield put(actions.news.error('Error fetching news articles'))
    }
  } catch {
    yield put(actions.news.error('Error fetching news articles'))
  }
}

function* watchGetNews() {
  yield takeLatest(actions.news.get().type, getNews)
}

export { getNews }
export default watchGetNews
