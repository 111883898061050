import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { twTextStyles } from '../styles'
import Button from './Button'

type ButtonGroupProps<T> = {
  classNameButton?: string
  classNameButtonActive?: string
  classNameContainer?: string
  buttons: Array<{
    value: T
    label: string
  }>
  update: (selection: T | null) => void
  value: T | null
}

const twstyles = {
  button: `${twTextStyles.paleGray2Normal14} bg-transparent border border-dark inline-block px-[10px] py-[6px] !text-sm`,
  buttonActive: 'bg-greeny-blue border border-greeny-blue',
  container:
    '[&_>_:first-child]:rounded-tl [&_>_:first-child]:rounded-tr-none [&_>_:first-child]:rounded-br-none [&_>_:first-child]:rounded-bl [&_>_:last-child]:rounded-tl-none [&_>_:last-child]:rounded-tr [&_>_:last-child]:rounded-br [&_>_:last-child]:rounded-bl-none [&_button_+_button]:-ml-[1px]',
}

const ButtonGroup = <T,>({
  classNameButton,
  classNameButtonActive,
  classNameContainer,
  update,
  value,
  buttons,
}: ButtonGroupProps<T>) => {
  return (
    <div className={`${twstyles.container} ${classNameContainer}`}>
      {buttons.map(({ label, value: buttonValue }) => (
        <Button
          aria-pressed={value === buttonValue}
          handleClick={() => update(value === buttonValue ? null : buttonValue)}
          key={String(buttonValue)}
          tw={twMerge(
            twstyles.button,
            classNameButton,
            value === buttonValue ? `${twstyles.buttonActive} ${classNameButtonActive}` : ''
          )}
        >
          {label}
        </Button>
      ))}
    </div>
  )
}

export default ButtonGroup
