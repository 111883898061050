import { useMachine } from '@xstate/react'
import { equals } from 'ramda'
import { useCallback } from 'react'

import { QueryKey } from '../types'
import { getQueryKey } from '../utils'
import { mutationMachine } from './machines'
import useAccountsApi from './useAccountsApi'
import useServicesMeta from './useServicesMeta'

export function useAccountsMutation(
  url: string,
  method: 'post' | 'put' | 'delete',
  { refetchQueries = [] }: Partial<{ refetchQueries: QueryKey[] }> = {}
) {
  const api = useAccountsApi(true)
  const services = useServicesMeta()
  const [current, send] = useMachine(mutationMachine, {
    services: {
      mutateData: (_, { body }) => api(url, { body, method }),
      refetchQueries: () => {
        for (const queryKey of refetchQueries) {
          const arrayQueryKey = Array.isArray(queryKey) ? queryKey : [queryKey]

          services.get(getQueryKey(arrayQueryKey))?.service?.send('REFETCH')

          Array.from(services.values())
            .filter((meta) => equals(meta.queryKey, queryKey))
            .forEach((meta) => meta.service?.send('REFETCH'))
        }

        return Promise.resolve()
      },
    },
  })
  const mutate = useCallback((variables: unknown) => {
    send('FETCH', { body: JSON.stringify(variables) })
  }, [])

  return [mutate, current] as const
}
