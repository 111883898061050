import React from 'react'

import { EntityTypeAndId, IntelligenceEntityType } from '../../intelligence/types'
import { FilterMeta, FilterReportType } from '../types'

type FilterContextState = Partial<EntityTypeAndId> & {
  meta: FilterMeta
}

const searchContext = {
  entity: IntelligenceEntityType.SearchSet,
  meta: {
    report: FilterReportType.Search,
  },
}

const FilterContext = React.createContext<FilterContextState>(searchContext)

export { FilterContextState, searchContext }
export default FilterContext
