import config from '@juristat/config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useAccessToken } from '../auth'
import { getSession, makeGetAccesses } from '../session/selectors'
import { Access } from '../session/types'
import { getGroupId, getUsername, getUuid } from './selectors'

export function useHasAccess(access?: Access | Access[], defaultAccess = false) {
  const accesses = useSelector(access ? makeGetAccesses([access].flat()) : () => [defaultAccess])

  return accesses.some(Boolean)
}

export function useIsPpairUser() {
  const [ppair, setPpair] = useState(false)

  const accessToken = useAccessToken()
  const access = useHasAccess(Access.PrivatePair)

  useEffect(() => {
    async function check() {
      if (!accessToken) {
        return
      }

      try {
        const response = await fetch(`${config.ppairUrl}/health/simple`, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        })

        setPpair(response.ok)
      } catch {
        // Nothing to do here.
      }
    }

    check()
  }, [accessToken])

  return access && ppair
}

export function useSession() {
  return useSelector(getSession)
}

export function useGroupId() {
  return useSelector(getGroupId)
}

export function useUserUuid() {
  return useSelector(getUuid)
}

export function useUsername() {
  return useSelector(getUsername)
}

export { Access }
