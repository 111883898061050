import makeAction from '../../../redux/utils/makeAction'
import { LogoutAction, SetDrawerStateAction } from '../types'

const logout = makeAction<LogoutAction>('navigation/LOGOUT')
const setDrawerState = makeAction<SetDrawerStateAction>('navigation/SET_DRAWER_STATE')

export default {
  logout,
  setDrawerState,
}
