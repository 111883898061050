import { css } from 'emotion'
import React, { useRef } from 'react'
import { animated, config, useTransition } from 'react-spring'

import { zIndex } from '../../../styles'
import noop from '../../../utils/noop'
import { useNotification } from '../hooks'
import Notification from './Notification'

const styles = {
  main: css({
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 500,
    padding: '16px 24px',
    position: 'fixed',
    right: 0,
    top: 60,
    zIndex: zIndex.notification,
  }),
}

const NotificationContainer: React.FC = () => {
  const { notifications, removeNotification } = useNotification()

  const refMap = useRef<{ [key: string]: HTMLDivElement }>({})
  const transition = useTransition(notifications, {
    config: config.stiff,
    keys: (notification) => notification.id,
    enter: (item) => async (next) => {
      await next({
        height: refMap.current[item.id].clientHeight,
        marginBottom: 12,
        opacity: 1,
        transform: 'translate3d(0px, 0px, 0)',
      })
    },
    from: { opacity: 0, transform: 'translate3d(0px, 50px, 0)', marginBottom: 12 },
    leave: () => async (next) => {
      await next({
        height: 0,
        marginBottom: 0,
        opacity: 0,
        transform: 'translate3d(100px, 0px, 0)',
      })
    },
    unique: true,
  })

  const makeDismiss = (id: string) => () => removeNotification(id)

  return (
    <div className={styles.main}>
      {transition((props, item, { key }) => (
        <animated.div key={key} style={props}>
          <div
            ref={(ref) => {
              if (ref) {
                refMap.current[key] = ref
              }
            }}
          >
            <Notification
              {...{ ...item, dismiss: item.dismissable ? makeDismiss(item.id) : noop }}
            />
          </div>
        </animated.div>
      ))}
    </div>
  )
}

export default NotificationContainer
