import { IntelligenceEntityType } from '../types'

const isAssignee = (entity: IntelligenceEntityType) => {
  return (
    entity === IntelligenceEntityType.AssigneeAtDisposition ||
    entity === IntelligenceEntityType.CurrentAssignee
  )
}

export default isAssignee
