import React from 'react'

import { Charts, IssuedPatentsOverTime, TransformLine } from '../types'
import { ChartProps } from '../types'
import LineChart from './LineChart'
import LineChartSkeletonLoader from './LineChartSkeletonLoader'
import PlatformChartContainer from './PlatformChartContainer'

const transform: TransformLine<IssuedPatentsOverTime> = (datum) =>
  datum.map(({ data, ...item }) => ({
    ...item,
    data: data
      .sort((current, next) => current.year - next.year)
      .map(({ issued, year }) => ({
        x: year,
        y: issued,
      })),
  }))

const IssuedPatents: React.FC<ChartProps> = (props) => (
  <PlatformChartContainer
    chart={Charts.IssuedPatentsOverTime}
    exportableConfig={{
      filename: 'issued_patents',
      getData: (data) => {
        const years = data[0].data.map(({ year }) => year)

        return data.map((item) => {
          const datapoints = years.map((year) =>
            item.data.find(({ year: datumYear }) => year === datumYear)
          )

          return [item.id, ...datapoints.map((datapoint) => (datapoint ? datapoint.issued : ''))]
        })
      },
      getHeader: (data) => data[0].data.map(({ year }) => year),
    }}
    skeleton={LineChartSkeletonLoader}
    title="Issued Patents"
    {...props}
  >
    {({ data: raw, ...dimensions }) => {
      const data = transform(raw)

      return (
        <LineChart
          {...dimensions}
          axisBottom={{
            legend: 'Issuance Year',
          }}
          axisLeft={{
            format: '~s',
            legend: 'Patents',
          }}
          data={data}
          tooltipFormat=","
        />
      )
    }}
  </PlatformChartContainer>
)

export default IssuedPatents
