import React, { useState } from 'react'

type HandleClick = () => void

type ModalWithTriggerProps = {
  modal: (closeModal: HandleClick) => JSX.Element
  trigger: (openModal: HandleClick) => JSX.Element
}

const ModalWithTrigger = ({ modal, trigger }: ModalWithTriggerProps) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div>
      {trigger(() => setShowModal(true))}
      {showModal && modal(() => setShowModal(false))}
    </div>
  )
}

export default ModalWithTrigger
