import { call, put, select, takeLatest } from 'redux-saga/effects'

import filterActions from '../../filter/actions'
import { getFilterMeta } from '../../filter/selectors'
import { Actions as FilterActions, FilterReportType } from '../../filter/types'
import paginationActions from '../../pagination/actions'
import { getPaginationVariables } from '../../pagination/selectors'
import routerActions from '../../router/actions'
import { getQueryString } from '../../router/selectors'
import searchActions from '../actions'
import { getSearchDataSource, getSearchView } from '../selectors'
import { getActiveResultsSearchId } from '../selectors/getSearch'
import makeSearchUrl from '../utils/makeSearchUrl'
import getSearchUid from './getSearchUid'
import getSearchVariables from './getSearchVariables'

function* performSearchFromState() {
  const searchId = yield select(getActiveResultsSearchId)
  const dataSource = yield select(getSearchDataSource)
  const filterMeta = yield select(getFilterMeta)
  const { filters, searches, similarTo, sortOrders } = yield call(
    getSearchVariables,
    searchId,
    {},
    filterMeta
  )
  const uid = yield call(getSearchUid, { filters, searches, similarTo, sortOrders })

  if (uid) {
    const { pageNum } = yield select(getPaginationVariables)
    const view = yield select(getSearchView)
    const queryString = yield select(getQueryString)
    const url = makeSearchUrl({ dataSource, pageNum, queryString, uid, view })
    return yield put(routerActions.push(url))
  }

  yield put(searchActions.error())
}

function* maybePerformSearchFromState(action: FilterActions) {
  if (action.meta && action.meta.report === FilterReportType.Search) {
    yield call(performSearchFromState)
  }
}

function* watchMaybePerformSearchFromState() {
  yield takeLatest(
    [
      filterActions.apply().type,
      filterActions.applyAll().type,
      filterActions.applyList().type,
      filterActions.applySome().type,
      filterActions.clear().type,
      filterActions.clearAll().type,
      filterActions.clearSome().type,
      filterActions.set().type,
      filterActions.setSome().type,
    ],
    maybePerformSearchFromState
  )
}

function* watchPerformSearchFromState() {
  yield takeLatest(
    [
      paginationActions.go().type,
      paginationActions.pageSize().type,
      searchActions.input().type,
      searchActions.sort.order().type,
      searchActions.setScopes().type,
      searchActions.setType().type,
      searchActions.setDataSource().type,
    ],
    performSearchFromState
  )
}

export {
  maybePerformSearchFromState,
  performSearchFromState,
  watchMaybePerformSearchFromState,
  watchPerformSearchFromState,
}
