import makeAction from '../../redux/utils/makeAction'
import { ClearAction, FeatureModalSetAction, SetAction } from './types'

const clear = makeAction<ClearAction>('session/CLEAR')
const featureModalSet = makeAction<FeatureModalSetAction>('session/featureModal/SET')
const set = makeAction<SetAction>('session/SET')

export default {
  clear,
  featureModalSet,
  set,
}
