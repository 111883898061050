import { css } from 'emotion'
import * as React from 'react'

import Chevron, { Direction } from '../../../../components/Chevron'
import { colors, makeTransition } from '../../../../styles'
import { DateRangeSelection } from '../../types'
import PickerHeader from './PickerHeader'
import SelectionContainer from './SelectionContainer'

type MonthAndYear = {
  month: number
  year: number
}

type PickerDropdownProps = {
  current: MonthAndYear
  selection: DateRangeSelection
  set: (date: MonthAndYear) => void
}

const styles = {
  arrow: (selection: DateRangeSelection) =>
    css({
      '& > svg': {
        fill: colors.white,
      },
      position: 'absolute',
      top: -17,
      transform: `translateX(${selection === DateRangeSelection.Start ? 0 : 130}px)`,
      transition: makeTransition('transform'),
    }),
  container: css({
    backgroundColor: colors.white,
    borderRadius: 4,
    boxShadow: `0 4px 20px 0 ${colors.charcoalGray2alpha30}`,
    marginTop: 8,
    padding: '20px 22px',
    position: 'relative',
    userSelect: 'none',
    width: '100%',
  }),
}

const PickerDropdown = ({ current, selection, set }: PickerDropdownProps) => (
  <div className={styles.container}>
    <div className={styles.arrow(selection)}>
      <Chevron color={colors.white} direction={Direction.Up} title="" />
    </div>
    <PickerHeader set={set} year={current.year} />
    <SelectionContainer set={set} month={current.month} year={current.year} />
  </div>
)

export default PickerDropdown
