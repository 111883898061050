import { IntelligenceEntityType } from '../types'

const getDefaultKeyMetricsBy = (entity: IntelligenceEntityType) => {
  switch (entity) {
    case IntelligenceEntityType.AssigneeAtDisposition:
      return IntelligenceEntityType.FirmAtDisposition
    case IntelligenceEntityType.FirmAtDisposition:
      return IntelligenceEntityType.AssigneeAtDisposition
    case IntelligenceEntityType.CurrentAssignee:
      return IntelligenceEntityType.CurrentFirm
    default:
      return IntelligenceEntityType.AssigneeAtDisposition
  }
}

export default getDefaultKeyMetricsBy
