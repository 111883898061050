import { pathOr } from 'ramda'
import { Reducer, combineReducers } from 'redux'

import { HttpStatus } from '../../http/types'
import group from '../modules/group/reducer'
import users from '../modules/users/reducer'
import { Actions, RolesState } from '../types'

const available: Reducer<RolesState['available']> = (
  state = { type: HttpStatus.NotAsked },
  action: Actions
) => {
  switch (action.type) {
    case 'account/GET_AVAILABLE_ROLES':
      return { type: HttpStatus.Fetching }
    case 'account/GET_AVAILABLE_ROLES_ERROR':
      return { message: action.payload!, type: HttpStatus.Error }
    case 'account/SET_AVAILABLE_ROLES':
      return {
        data: action.payload!,
        type: HttpStatus.Success,
      }
    default:
      return state
  }
}

const current: Reducer<RolesState['current']> = (state = {}, action: Actions) => {
  switch (action.type) {
    case 'account/GET_CURRENT_ROLES':
    case 'account/REMOVE_ROLE':
    case 'account/SET_ROLE': {
      const { uuid } = action.payload!
      return {
        ...state,
        [uuid]: { data: pathOr([], [uuid, 'data'], state), type: HttpStatus.Fetching },
      }
    }
    case 'account/GET_CURRENT_ROLES_ERROR': {
      const { message, uuid } = action.payload!
      return { ...state, [uuid]: { message, type: HttpStatus.Error } }
    }
    case 'account/SET_CURRENT_ROLES': {
      const { roles: data, uuid } = action.payload!
      return { ...state, [uuid]: { data, type: HttpStatus.Success } }
    }
    case 'account/SET_OR_REMOVE_ROLE_ERROR': {
      // Set things back to success with previous state; a notification will announce the error
      const { uuid } = action.payload!
      return {
        ...state,
        [uuid]: { data: pathOr([], [uuid, 'data'], state), type: HttpStatus.Success },
      }
    }
    default:
      return state
  }
}

const roles = combineReducers({
  available,
  current,
})

const reducer = combineReducers({
  group,
  roles,
  users,
})

export default reducer
