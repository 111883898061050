import React from 'react'

import { Charts, Extensions as Data, TransformLine } from '../types'
import { ChartProps } from '../types'
import LineChart from './LineChart'
import LineChartSkeletonLoader from './LineChartSkeletonLoader'
import PlatformChartContainer from './PlatformChartContainer'

type ExtensionsProps = ChartProps & {
  asPercentage?: boolean
  title?: string
}

const transform =
  (asPercentage: boolean): TransformLine<Data> =>
  (datum) =>
    datum.map(({ data, ...item }) => ({
      ...item,
      data: data
        .sort((current, next) => current.year - next.year)
        .map(({ extensions, total, year }) => ({
          x: year,
          y: extensions / (asPercentage ? total : 1),
        })),
    }))

const Extensions: React.FC<ExtensionsProps> = ({ asPercentage = false, ...props }) => (
  <PlatformChartContainer
    chart={Charts.Extensions}
    exportableConfig={{
      filename: `extensions_${asPercentage ? 'percentage' : 'over_time'}`,
      getData: (data) => {
        const years = data[0].data.map(({ year }) => year)

        return data.map((item) => {
          const datapoints = years.map((year) =>
            item.data.find(({ year: datumYear }) => year === datumYear)
          )

          return [
            item.id,
            ...datapoints.map((datapoint) =>
              datapoint ? datapoint.extensions / (asPercentage ? datapoint.total : 1) : ''
            ),
          ]
        })
      },
      getHeader: (data) => data[0].data.map(({ year }) => year),
    }}
    skeleton={LineChartSkeletonLoader}
    title={`Extensions ${asPercentage ? 'Percentage' : 'Over Time'}`}
    {...props}
  >
    {({ data: raw, ...dimensions }) => {
      const data = transform(asPercentage)(raw)

      return (
        <LineChart
          {...dimensions}
          axisBottom={{
            legend: 'Rejection Year',
          }}
          axisLeft={{
            format: asPercentage ? '.0%' : '~s',
            legend: 'Extensions',
          }}
          data={data}
          tooltipFormat={asPercentage ? '.0%' : ','}
        />
      )
    }}
  </PlatformChartContainer>
)

export default Extensions
