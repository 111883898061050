import { assoc, head, tail } from 'ramda'

/**
 * Builds a singly-branched object from `keys` with the last key mapping to `value`
 * @example
 * buildObjFromKeys(['one', 'two', 'three'], true) // { one: { two: { three: true } } }
 */
const buildObjFromKeys = (keys: string[], value: any, startObj: WeakObject = {}): WeakObject => {
  const key = head(keys)

  if (keys.length === 0 || !key) {
    return startObj
  }

  if (keys.length === 1) {
    return assoc(key, value, startObj)
  }

  startObj[key] = {}

  return {
    [key]: buildObjFromKeys(tail(keys), value, startObj[key]),
  }
}

export default buildObjFromKeys
