
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"KeyMetrics"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicationSetMetrics"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"applicationCounts"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disposed"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"pending"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"total"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"allowanceRate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"timing"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"daysToDisposition"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"average"},"arguments":[],"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"officeActions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"toDisposition"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"average"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":247}};
    doc.loc.source = {"body":"fragment KeyMetrics on ApplicationSetMetrics {\n  applicationCounts {\n    disposed\n    pending\n    total\n  }\n  allowanceRate\n  timing {\n    daysToDisposition {\n      average\n    }\n  }\n  officeActions {\n    toDisposition {\n      average\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
