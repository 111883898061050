import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const NplClustered = (props: IconProps) => (
  <SVG title="npl-clustered" viewBox="0 0 9 9" fill="none" {...props}>
    <path
      d="M8.21112 0.272727V9H6.42134L2.94833 3.96307H2.89293V9H0.783558V0.272727H2.5989L6.03356 5.30114H6.106V0.272727H8.21112Z"
      fill="#109FFF"
    />
  </SVG>
)

export default NplClustered
