import { isNilOrEmpty } from '@juristat/common/utils'
import { reject } from 'ramda'
import { useEffect } from 'react'
import { EventObject, MachineOptions, State, assign, interpret } from 'xstate'

import { Context, FetchTypeState, QueryKey } from '../types'
import { getQueryKey } from '../utils'
import { fetchMachine } from './machines'
import useServicesMeta from './useServicesMeta'

type Options<T, R> = {
  transform: (data: R) => T
} & Partial<{
  options: Array<Record<string, any>>
  ppair: boolean
  url: string
  variables: Record<string, unknown>
}>

type ServiceOptions<TContext> = Partial<MachineOptions<TContext, EventObject>>

// Internal hook and shouldn't be exposed outside api module
export default function useEnsureServiceMeta<T, R>(
  queryKey: QueryKey,
  fetchData: (context: Context<T, R>, payload: Record<string, any>) => Promise<R>,
  { transform, ...options }: Options<T, R>,
  serviceOptions?: ServiceOptions<Context<T, R>>
) {
  const services = useServicesMeta()
  const fragments = reject(isNilOrEmpty, options as Omit<Options<T, R>, 'transform'>)
  const arrayQueryKey = Array.isArray(queryKey) ? queryKey : [queryKey]
  const key = getQueryKey([
    ...arrayQueryKey,
    { ...fragments, transform: transform.toString().replace(/( |\n)/g, '') },
  ])

  if (!services.has(key)) {
    const keyFragments = [
      `[${getQueryKey(arrayQueryKey).slice(1, -1)},`,
      ...Object.keys(fragments).map((key) => getQueryKey([{ [key]: fragments[key] }]).slice(2, -2)),
    ]
    const existingKey =
      Array.from(services.keys()).find((serviceKey) =>
        keyFragments.every((fragment) => serviceKey.indexOf(fragment) > -1)
      ) ?? ''
    const { service } = services.get(existingKey) ?? {}

    services.set(key, {
      cacheTimeout: null,
      queryKey,
      service: interpret(
        fetchMachine.withConfig({
          actions: {
            transform: assign<Context<T, R>>((ctx) => {
              try {
                return { ...ctx, data: transform(ctx.raw!) }
              } catch (ex) {
                return { ...ctx, error: (ex as Error).message }
              }
            }),
          },
          guards: {
            transformErrored: (ctx: Context<T, R>) => Boolean(ctx.error),
          },
          ...serviceOptions,
          services: {
            ...serviceOptions?.services,
            fetchData,
          },
        } as any)
      ).start(
        service
          ? (State.from(service.state as any, {
              ...service.state.context,
              data: service.state.context.raw ? transform(service.state.context.raw) : undefined,
            }) as FetchTypeState<T, R>)
          : undefined
      ),
      subscribers: 0,
    })
  }

  // Update service when the access token is regenerated
  useEffect(() => {
    const { service } = services.get(key) ?? {}

    if (service) {
      service.machine.options.services.fetchData = fetchData
    }
  }, [fetchData])

  return key
}
