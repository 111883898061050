import { equals } from 'ramda'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'

import { TableProps } from '../components/Table'

export function useStateReducer<T extends Record<string, unknown>>(
  name: string,
  setExportCsvProps: Dispatch<SetStateAction<TableProps<T>['exportCsvProps'] | undefined>>,
  csvFormats?: Record<string, (value: string) => string>,
  canExportResults = true
) {
  const stateReducer = useCallback<NonNullable<TableProps<T>['stateReducer']>>(
    (_state, _action, _previousState, instance) => {
      const state = _state as typeof _state & { columnOrder: string[] }
      const columnOrder =
        state.columnOrder.length > 0
          ? state.columnOrder
          : instance?.columns.map(
              (column) => column.id ?? (column as typeof column & { accessor: string }).accessor
            ) ?? []

      setExportCsvProps((currentCsvProps) => {
        const header = columnOrder.map(
          (id) => instance?.allColumns.find((column) => column.id === id)?.Header
        ) as string[]
        const exportCsvProps = {
          options: {
            header,
            name,
            values: instance?.rows.map((row) =>
              columnOrder.map((id) => {
                const value = row.values[id]

                return csvFormats?.[id]?.(value) ?? value
              })
            ),
          },
          type: 'local',
        } as unknown as NonNullable<typeof currentCsvProps>

        return equals(currentCsvProps, exportCsvProps) ? currentCsvProps : exportCsvProps
      })

      return state
    },
    [csvFormats, name, setExportCsvProps]
  )

  useEffect(() => {
    setExportCsvProps((currentCsvProps) =>
      currentCsvProps ? { ...currentCsvProps, name } : currentCsvProps
    )
  }, [name, setExportCsvProps])

  return canExportResults ? stateReducer : undefined
}
