import { useMachine } from '@xstate/react'
import React, { useMemo } from 'react'
import { assign, createMachine } from 'xstate'

import { DialogueActionsContext, DialogueCreateContext, DialogueStateContext } from '../contexts'
import { DialogueEvents, DialogueOptions } from '../types'

const context: DialogueOptions<unknown> = {
  actionText: undefined,
  key: undefined,
  text: undefined,
  type: undefined,
}

const dialogueMachine = createMachine<DialogueOptions<unknown>, DialogueEvents>({
  id: 'dialogue',
  initial: 'closed',
  context,
  states: {
    closed: {
      on: {
        OPEN: {
          actions: assign((_, event) => event.data),
          target: 'open',
        },
      },
    },
    confirmed: {
      after: { 1: 'closed' },
    },
    dismissed: {
      after: { 1: 'closed' },
    },
    open: {
      on: {
        CONFIRM: 'confirmed',
        DISMISS: 'dismissed',
      },
    },
  },
})

const DialogueContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [dialogue, send] = useMachine(dialogueMachine)
  const { create, ...actions } = useMemo(() => {
    const create = (data: DialogueOptions<unknown> = {}) => {
      send({ data, type: 'OPEN' })
    }

    const confirm = () => {
      send('CONFIRM')
    }
    const dismiss = () => {
      send('DISMISS')
    }

    return { confirm, create, dismiss }
  }, [send])

  return (
    <DialogueStateContext.Provider value={dialogue}>
      <DialogueActionsContext.Provider value={actions}>
        <DialogueCreateContext.Provider value={create}>{children}</DialogueCreateContext.Provider>
      </DialogueActionsContext.Provider>
    </DialogueStateContext.Provider>
  )
}

export default DialogueContextProvider
