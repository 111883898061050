import makeAction from '../../redux/utils/makeAction'
import { LogIntercomEventAction, ShowNewIntercomMessageAction } from './types'

const intercom = {
  log: makeAction<LogIntercomEventAction>('analytics/intercom/LOG'),
  showNewMessage: makeAction<ShowNewIntercomMessageAction>('analytics/intercom/SHOW_NEW_MESSAGE'),
}

const actions = {
  intercom,
}

export default actions
