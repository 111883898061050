import { defaultTo, evolve, identity, map, omit, pick, pipe } from 'ramda'

import { Filter, FilterResult, GraphQLFilterResponse } from '../types'
import transformApplicationStatuses from '../utils/transformApplicationStatuses'
import transformEntityStatuses from '../utils/transformEntityStatuses'
import transformTechCenters from '../utils/transformTechCenters'
import transformApplicationTypes from './transformApplicationType'
import transformRejectionBases from './transformRejectionBases'

type ItemOfArray<T> = T extends Array<infer U> ? U : never
type WithoutResult = <T extends FilterResult<any>>(filter: T) => Omit<T, 'result'>

type ArtUnit = ItemOfArray<GraphQLFilterResponse[Filter.ArtUnit]>
type CpcClass = ItemOfArray<GraphQLFilterResponse[Filter.CpcClass]>
type CurrentAssignee = ItemOfArray<GraphQLFilterResponse[Filter.CurrentAssignee]>
type CurrentFirm = ItemOfArray<GraphQLFilterResponse[Filter.CurrentFirm]>
type DocCode = ItemOfArray<GraphQLFilterResponse[Filter.DocCode]>
type UspcClass = ItemOfArray<GraphQLFilterResponse[Filter.UspcClass]>

const withoutResult: WithoutResult = omit(['result'])

const transformFilters = evolve({
  advancedAppType: transformApplicationTypes,
  advancedApplicationStatus: transformApplicationStatuses,
  artUnit: map((artUnit: ArtUnit) => ({
    ...withoutResult(artUnit),
    metadata: artUnit.result?.group?.description ?? null,
  })),
  assigneeAtDisposition: map((assigneeAtDisposition: CurrentAssignee) => ({
    ...withoutResult(assigneeAtDisposition),
    metadata: assigneeAtDisposition.result.types,
  })),
  attorneyAtDisposition: pipe(defaultTo([]), identity),
  cpcClass: map((cpcClass: CpcClass) => ({
    ...withoutResult(cpcClass),
    ...pick(['class', 'mainGroup', 'section', 'subClass', 'subGroup'], cpcClass.result),
    metadata: cpcClass.result.descriptionText,
  })),
  currentAssignee: map((currentAssignee: CurrentAssignee) => ({
    ...withoutResult(currentAssignee),
    metadata: currentAssignee.result.types,
  })),
  currentAttorney: pipe(defaultTo([]), identity),
  currentFirm: map((currentFirm: CurrentFirm) => ({
    ...withoutResult(currentFirm),
    metadata: currentFirm.result.types,
  })),
  docCodeFilter: map((docCode: DocCode) => ({
    ...withoutResult(docCode),
    docCode: docCode.result.docCode,
    metadata: docCode.result.description,
  })),
  entityStatus: transformEntityStatuses,
  examiner: identity,
  firmAtDisposition: map((firmAtDisposition: CurrentFirm) => ({
    ...withoutResult(firmAtDisposition),
    metadata: firmAtDisposition.result.types,
  })),
  hasRejectionType: transformRejectionBases,
  techCenter: transformTechCenters,
  uspcClass: map((uspcClass: UspcClass) => ({
    ...withoutResult(uspcClass),
    ...pick(['class', 'subclass'], uspcClass.result),
    metadata: uspcClass.result.descriptionText,
  })),
})

export default transformFilters
