import React from 'react'
import { useSelector } from 'react-redux'

import { makeGetAccess } from '../selectors'
import { Access } from '../types'

type HasAccessRenderProps = WeakObject & {
  hasAccess: boolean
}

type HasAccessProps = {
  canAccess: Access
  render: (props: HasAccessRenderProps) => React.ReactElement<HasAccessRenderProps> | null
}

const HasAccess = ({ canAccess, render, ...rest }: HasAccessProps) => {
  const hasAccess = useSelector(makeGetAccess(canAccess))

  return render({ hasAccess, ...rest })
}

export default HasAccess
