import { CSSObject } from 'create-emotion'

import * as colors from './colors'

const azureNormal10: CSSObject = {
  color: colors.azure,
  fontFamily: 'Open Sans',
  fontSize: 10,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const azureNormal13: CSSObject = {
  color: colors.azure,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const azureBold13: CSSObject = {
  color: colors.azure,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const charcoalGrayBold10: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 10,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const charcoalGrayBold12: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const charcoalGrayBold16: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 16,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const charcoalGrayNormal10: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 10,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const charcoalGrayNormal12: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const charcoalGrayNormal13: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const charcoalGrayNormal14: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 14,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const charcoalGray2Normal10: CSSObject = {
  color: colors.charcoalGray2,
  fontFamily: 'Open Sans',
  fontSize: 10,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 1,
}

const charcoalGray2Semibold12: CSSObject = {
  color: colors.charcoalGray2,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
}

const charcoalGray2Bold12Caps: CSSObject = {
  color: colors.charcoalGray2,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 1,
  textTransform: 'uppercase',
}

const charcoalGrayNormal16: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 16,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const charcoalGrayBold24: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 24,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const charcoalGray2Alpha50Bold12: CSSObject = {
  color: colors.charcoalGray2alpha50,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const charcoalGray2Alpha50Bold24: CSSObject = {
  color: colors.charcoalGray2alpha50,
  fontFamily: 'Open Sans',
  fontSize: 24,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const charcoalGray2Bold13: CSSObject = {
  color: colors.charcoalGray2,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const charcoalGray6Bold12: CSSObject = {
  color: colors.charcoalGray6,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 1,
}

const charcoalGray6Bold36: CSSObject = {
  color: colors.charcoalGray6,
  fontFamily: 'Open Sans',
  fontSize: 36,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 1,
}

const darkBold12: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const darkBold13: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const darkBold16: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 16,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const darkBold24: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 24,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const darkBold61: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 61,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const darkNormal12: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const darkNormal13: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const darkNormal14: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 14,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const darkNormal16: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 16,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const darkNormal24: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 24,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const dark2Semibold12: CSSObject = {
  color: colors.dark,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
}

const greenyBlueBold12: CSSObject = {
  color: colors.greenyBlue,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const paleGrayNormal12: CSSObject = {
  color: colors.paleGray,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const paleGrayBold12: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 1,
  lineHeight: 'normal',
  maxWidth: 300,
}

const paleGray2Bold10: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 10,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 1,
}

const paleGray2Bold12: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 1,
}

const paleGray2Bold13: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const paleGray2Normal12: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const paleGray2Normal12Caps: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'uppercase',
}

const paleGray2Normal13: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const paleGray2Normal14: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 14,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const paleGray2Normal15: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 15,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const paleGray2Bold16Caps: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 16,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
  textTransform: 'uppercase',
}

const paleGray2Semibold10: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 10,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 1,
}

const charcoalGraySemibold12: CSSObject = {
  color: colors.charcoalGray,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
}

const greenyBlueBold13: CSSObject = {
  color: colors.greenyBlue,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const linkBlueBold12: CSSObject = {
  color: colors.linkBlue,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const linkBlueBold13: CSSObject = {
  color: colors.linkBlue,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const linkBlueBold16: CSSObject = {
  color: colors.linkBlue,
  fontFamily: 'Open Sans',
  fontSize: 16,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const linkBlueBold61: CSSObject = {
  color: colors.linkBlue,
  fontFamily: 'Open Sans',
  fontSize: 61,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const linkBlueNormal12: CSSObject = {
  color: colors.linkBlue,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const linkBlueNormal13: CSSObject = {
  color: colors.linkBlue,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const linkBlueBold13Underlined: CSSObject = {
  color: colors.linkBlue,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
  textDecoration: 'underline',
}

const paleGray2Semibold14: CSSObject = {
  color: colors.paleGray2,
  fontFamily: 'Open Sans',
  fontSize: 14,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
}

const placeholderNormal12: CSSObject = {
  color: colors.placeholder,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const placeholderNormal13: CSSObject = {
  color: colors.placeholder,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const placeholderNormal16: CSSObject = {
  color: colors.placeholder,
  fontFamily: 'Open Sans',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const santasGray2Semibold12: CSSObject = {
  color: colors.santasGray,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
}

const silver3Normal13: CSSObject = {
  color: colors.silver3,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const silver3Normal16: CSSObject = {
  color: colors.silver3,
  fontFamily: 'Open Sans',
  fontSize: 16,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const torchRedNormal13: CSSObject = {
  color: colors.torchRed,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
}

const whiteBold10: CSSObject = {
  color: colors.white,
  fontFamily: 'Open Sans',
  fontSize: 10,
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const whiteBold12: CSSObject = {
  color: colors.white,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const whiteBold13: CSSObject = {
  color: colors.white,
  fontFamily: 'Open Sans',
  fontSize: 13,
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const whiteBold24: CSSObject = {
  color: colors.white,
  fontFamily: 'Open Sans',
  fontSize: 24,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
}

const montserratDarkBold16: CSSObject = {
  color: colors.dark,
  fontFamily: 'Montserrat',
  fontSize: 16,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
  lineHeight: 1,
}

const montserratDarkBold24: CSSObject = {
  color: colors.dark,
  fontFamily: 'Montserrat',
  fontSize: 24,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
  lineHeight: 1,
}

const montserratAzureBold48: CSSObject = {
  color: colors.azure,
  fontFamily: 'Montserrat',
  fontSize: 48,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: 'normal',
  lineHeight: 1,
}

const montserratDarkNormal13: CSSObject = {
  color: colors.dark,
  fontFamily: 'Montserrat',
  fontSize: 13,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
  lineHeight: 1,
}

const montserratDarkNormal16: CSSObject = {
  color: colors.dark,
  fontFamily: 'Montserrat',
  fontSize: 16,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
  lineHeight: 1,
}

const montserratDarkNormal36: CSSObject = {
  color: colors.dark,
  fontFamily: 'Montserrat',
  fontSize: 36,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
  lineHeight: 1,
}

const montserratDarkNormal48: CSSObject = {
  color: colors.dark,
  fontFamily: 'Montserrat',
  fontSize: 48,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
  lineHeight: 1,
}

const montserratDarkNormal72: CSSObject = {
  color: colors.dark,
  fontFamily: 'Montserrat',
  fontSize: 72,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: 'normal',
  lineHeight: 1,
}

const montserratMineshaftNormal48: CSSObject = {
  color: colors.mineshaft,
  fontFamily: 'Montserrat',
  fontSize: 48,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
  lineHeight: 1,
}

export {
  azureBold13,
  azureNormal10,
  azureNormal13,
  charcoalGrayBold10,
  charcoalGrayBold12,
  charcoalGrayBold16,
  charcoalGrayNormal10,
  charcoalGrayNormal12,
  charcoalGrayNormal13,
  charcoalGrayNormal14,
  charcoalGrayNormal16,
  charcoalGrayBold24,
  charcoalGraySemibold12,
  charcoalGray2Alpha50Bold12,
  charcoalGray2Alpha50Bold24,
  charcoalGray2Bold13,
  charcoalGray2Bold12Caps,
  charcoalGray2Normal10,
  charcoalGray2Semibold12,
  charcoalGray6Bold12,
  charcoalGray6Bold36,
  darkBold12,
  darkBold13,
  darkBold16,
  darkBold24,
  darkBold61,
  darkNormal12,
  darkNormal13,
  darkNormal14,
  darkNormal16,
  darkNormal24,
  dark2Semibold12,
  greenyBlueBold12,
  greenyBlueBold13,
  linkBlueBold12,
  linkBlueBold13,
  linkBlueBold16,
  linkBlueBold13Underlined,
  linkBlueBold61,
  linkBlueNormal12,
  linkBlueNormal13,
  montserratAzureBold48,
  montserratDarkBold16,
  montserratDarkBold24,
  montserratDarkNormal13,
  montserratDarkNormal16,
  montserratDarkNormal36,
  montserratDarkNormal48,
  montserratDarkNormal72,
  montserratMineshaftNormal48,
  paleGrayBold12,
  paleGrayNormal12,
  paleGray2Bold10,
  paleGray2Bold12,
  paleGray2Bold13,
  paleGray2Normal12,
  paleGray2Normal12Caps,
  paleGray2Normal13,
  paleGray2Normal14,
  paleGray2Normal15,
  paleGray2Semibold10,
  paleGray2Semibold14,
  paleGray2Bold16Caps,
  placeholderNormal12,
  placeholderNormal13,
  placeholderNormal16,
  santasGray2Semibold12,
  silver3Normal13,
  silver3Normal16,
  torchRedNormal13,
  whiteBold10,
  whiteBold12,
  whiteBold13,
  whiteBold24,
}
