import { print } from 'graphql/language/printer'

const makeGraphqlOptionsBase = ({ body, type }: RequestInit & { type: string }): RequestInit => ({
  body,
  headers: {
    'content-type': `application/${type}`,
  },
  method: 'post',
})

const makeGraphqlOptionsJson = <Variables>(
  query: Record<string, unknown>,
  variables: Variables
): RequestInit =>
  makeGraphqlOptionsBase({
    body: JSON.stringify({ query: print(query), variables }),
    type: 'json',
  })

export default makeGraphqlOptionsJson
