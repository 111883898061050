import * as React from 'react'

import SVG from '../SVG'
import { IconProps } from '../types'

const WarningStandardSolid = (props: IconProps) => (
  <SVG title="warning-standard-solid" {...props}>
    <path d="M34.6,29.21,20.71,3.65a3.22,3.22,0,0,0-5.66,0L1.17,29.21A3.22,3.22,0,0,0,4,34H31.77a3.22,3.22,0,0,0,2.83-4.75ZM16.6,10a1.4,1.4,0,0,1,2.8,0v12a1.4,1.4,0,0,1-2.8,0ZM18,29.85a1.8,1.8,0,1,1,1.8-1.8A1.8,1.8,0,0,1,18,29.85Z" />
  </SVG>
)

export default WarningStandardSolid
