import { filter, propEq } from 'ramda'
import { createSelector } from 'reselect'

import { AppState } from '../../../redux'
import { Notification, NotificationTypes } from '../../notification'
import { NotificationState } from '../types'

const getNotificationState = (state: AppState) => state.notification

export const getNotifications = createSelector(
  getNotificationState,
  (state: NotificationState) => state.notifications
)

const filterByType = (type: NotificationTypes, notifications: Notification[]) =>
  filter(propEq('type', type), notifications)

export const makeGetNotificationsByType = (type: NotificationTypes) =>
  createSelector(getNotifications, (state: Notification[]) => filterByType(type, state))

export const getErrorNotifications = makeGetNotificationsByType(NotificationTypes.Error)
export const getInfoNotifications = makeGetNotificationsByType(NotificationTypes.Info)
export const getSuccessNotifications = makeGetNotificationsByType(NotificationTypes.Success)
export const getWarningNotifications = makeGetNotificationsByType(NotificationTypes.Warning)
