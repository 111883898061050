import { css } from 'emotion'
import * as React from 'react'

import { colors } from '../../../styles'

type FilterItemRenderProps = {
  className: string
  text: string
}

export type FilterItemProps = {
  apps: number
  appsCss?: string
  children?: (props: FilterItemRenderProps) => React.ReactNode
  labelCss?: string
  metadata?: string | string[] | null
  name: string
}

const styles = {
  button: css({
    padding: '.1em .5em',
  }),
  container: css({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  }),
  displayName: css({
    color: colors.paleGray2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  displayNameContainer: css({
    alignItems: 'center',
    display: 'flex',
    textTransform: 'capitalize',
    width: '75%',
  }),
}

const FilterItem = ({ apps, appsCss, children, labelCss, metadata, name }: FilterItemProps) => {
  // Guard against null & Assignee/Firm metadata
  const validMetadata = metadata && !Array.isArray(metadata)
  const displayName = validMetadata ? `${name}: ${metadata}` : name

  return (
    <div className={styles.container}>
      <div className={css(styles.displayNameContainer, labelCss)}>
        {typeof children === 'function' ? (
          children({ className: styles.displayName, text: displayName })
        ) : (
          <div className={styles.displayName} title={displayName}>
            {displayName}
          </div>
        )}
      </div>
      <div className={appsCss ? appsCss : 'text-pale-gray2'}>{apps.toLocaleString()}</div>
    </div>
  )
}

export default FilterItem
