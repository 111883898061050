import { IntercomAttribute } from '@juristat/common/types'
import config from '@juristat/config'
import { useMachine } from '@xstate/react'
import { isEmpty, map, mapObjIndexed, mergeLeft } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { AssignAction, Assigner, Machine, assign as xstateAssign } from 'xstate'

import { useAccessToken } from '../../auth'
import { IntercomActionsContext } from '../contexts'

type Attributes = { [K in IntercomAttribute]: number }
type Context = {
  changes: Partial<Attributes>
  current: Attributes
  token: string
}

type AuthedEvent = { token: string; type: 'AUTHED' }
type DoneEvent = { data: Attributes; type: 'done.invoke.fetchData' }
type IncrementEvent = { attribute: IntercomAttribute; type: 'INCREMENT' }
type SetEvent = { attribute: IntercomAttribute; type: 'SET'; value: number }
type SentEvent = { type: 'SENT' }
type ViewedEvent = { attribute: IntercomAttribute; type: 'VIEWED' }

type IntercomEvent = AuthedEvent | DoneEvent | IncrementEvent | SetEvent | SentEvent | ViewedEvent

const incrementAttributes = [
  IntercomAttribute.AddEntityClicks,
  IntercomAttribute.AlertsConfigurationRules,
  IntercomAttribute.AlertsConfigurationActiveRules,
  IntercomAttribute.AlertsConfigurationInactiveRules,
  IntercomAttribute.AlertsConfigurationViews,
  IntercomAttribute.ApplicationViews,
  IntercomAttribute.CreateDashboardClicks,
  IntercomAttribute.DashboardViews,
  IntercomAttribute.EditChartsClicks,
  IntercomAttribute.EditColumnsClicks,
  IntercomAttribute.ExaminerViews,
  IntercomAttribute.FilterAppliedClicks,
  IntercomAttribute.IntelligenceViews,
  IntercomAttribute.PpairApplicationViews,
  IntercomAttribute.PpairViews,
  IntercomAttribute.TableViewExportClicks,
  IntercomAttribute.TableViews,
  IntercomAttribute.SaveSearchClicks,
]

const intercomMachine = Machine<Context, IntercomEvent>({
  id: 'intercom',
  initial: 'authorizing',
  context: {
    current: Object.values(IntercomAttribute).reduce(
      (acc, attribute) => ({
        ...acc,
        [attribute]: 0,
      }),
      {} as Attributes
    ),
    changes: {},
    token: '',
  },
  states: {
    authorizing: {
      on: {
        AUTHED: {
          actions: 'authed',
          target: 'loading',
        },
      },
    },
    loading: {
      invoke: {
        src: 'fetchData',
        onDone: {
          actions: 'done',
          target: 'success',
        },
        onError: 'failure',
      },
    },
    failure: {
      after: {
        5000: 'loading',
      },
    },
    success: {
      always: [{ actions: 'merge', target: 'pending', cond: 'hasChanges' }, 'idle'],
    },
    idle: {
      always: { target: 'pending', cond: 'hasChanges' },
    },
    pending: {
      after: {
        5000: {
          target: 'updating',
        },
      },
    },
    updating: {
      invoke: {
        src: 'update',
      },
      on: {
        SENT: {
          actions: 'clear',
          target: 'idle',
        },
      },
    },
  },
  on: {
    INCREMENT: {
      actions: 'increment',
    },
    SET: {
      actions: 'set',
    },
    VIEWED: {
      actions: 'viewed',
    },
  },
})

const assign = <TEvent extends IntercomEvent>(params: Assigner<Context, TEvent>) =>
  xstateAssign(params) as unknown as AssignAction<Context, IntercomEvent>

const IntercomActionsProvider = ({ children }: { children: React.ReactNode }) => {
  const accessToken = useAccessToken()
  const location = useLocation()

  const [, send] = useMachine<Context, IntercomEvent>(intercomMachine, {
    actions: {
      authed: assign<AuthedEvent>((_, event) => ({ token: event.token })),
      clear: assign(() => ({ changes: {} })),
      done: assign<DoneEvent>((_, event) => ({ current: event.data })),
      increment: assign<IncrementEvent>(({ changes, current }, { attribute }) =>
        map(mergeLeft({ [attribute]: current[attribute] + 1 }), { changes, current })
      ),
      merge: assign(({ changes, current }) => ({
        changes: mapObjIndexed<number, number, IntercomAttribute>(
          (value, attribute) =>
            incrementAttributes.includes(attribute) ? current[attribute] + value : value,
          changes as NonNullable<Attributes>
        ),
      })),
      set: assign<SetEvent>(({ changes, current }, { attribute, value }) =>
        map(mergeLeft({ [attribute]: value }), { changes, current })
      ),
      viewed: assign<ViewedEvent>(({ changes }, { attribute }) => ({
        changes: mergeLeft({ [attribute]: Math.floor(Date.now() / 1000) }, changes),
      })),
    },
    guards: {
      hasChanges: (context) => !isEmpty(context.changes),
    },
    services: {
      fetchData: async (context) => {
        const response = await fetch(`${config.appApiUrl}/intercom/user`, {
          headers: new Headers([
            ['authorization', `Bearer ${context.token}`],
            ['content-type', 'application/json'],
          ]),
        })

        return response.json()
      },
      update: (context) => (callback) => {
        window?.Intercom?.('update', context.changes)

        callback('SENT')
      },
    },
  })

  useEffect(() => {
    if (accessToken) {
      send({ token: accessToken, type: 'AUTHED' })
    }
  }, [accessToken, send])

  const actions = useMemo(
    () => ({
      addEntityClick() {
        send({ attribute: IntercomAttribute.AddEntityClicks, type: 'INCREMENT' })
      },
      createDashboardClick() {
        send({ attribute: IntercomAttribute.CreateDashboardClicks, type: 'INCREMENT' })
      },
      editChartsClick() {
        send({ attribute: IntercomAttribute.EditChartsClicks, type: 'INCREMENT' })
      },
      editColumnsClick() {
        send({ attribute: IntercomAttribute.EditColumnsClicks, type: 'INCREMENT' })
      },
      filterApplyClick() {
        send({ attribute: IntercomAttribute.FilterAppliedClicks, type: 'INCREMENT' })
      },
      ppairView() {
        send([
          { attribute: IntercomAttribute.PpairViews, type: 'INCREMENT' },
          { attribute: IntercomAttribute.LastPpairView, type: 'VIEWED' },
        ])
      },
      saveSearchClick() {
        send({ attribute: IntercomAttribute.SaveSearchClicks, type: 'INCREMENT' })
      },
      setAlertConfigurations([active, inactive]: [number, number]) {
        send([
          {
            attribute: IntercomAttribute.AlertsConfigurationRules,
            type: 'SET',
            value: active + inactive,
          },
          {
            attribute: IntercomAttribute.AlertsConfigurationActiveRules,
            type: 'SET',
            value: active,
          },
          {
            attribute: IntercomAttribute.AlertsConfigurationInactiveRules,
            type: 'SET',
            value: inactive,
          },
        ])
      },
      tableViewExportClick() {
        send({ attribute: IntercomAttribute.TableViewExportClicks, type: 'INCREMENT' })
      },
    }),
    [send]
  )

  useEffect(() => {
    switch (true) {
      case location.pathname.includes('/alerts-configuration'):
        send([
          { attribute: IntercomAttribute.AlertsConfigurationViews, type: 'INCREMENT' },
          { attribute: IntercomAttribute.LastAlertsConfigurationView, type: 'VIEWED' },
        ])

        break
      case location.pathname.includes('/application/'):
        send([
          { attribute: IntercomAttribute.ApplicationViews, type: 'INCREMENT' },
          { attribute: IntercomAttribute.LastApplicationView, type: 'VIEWED' },
        ])

        break
      case location.pathname.includes('/dashboards'):
        send([
          { attribute: IntercomAttribute.DashboardViews, type: 'INCREMENT' },
          { attribute: IntercomAttribute.LastDashboardView, type: 'VIEWED' },
        ])

        break
      case location.pathname.includes('/examiner'):
        send([
          { attribute: IntercomAttribute.ExaminerViews, type: 'INCREMENT' },
          { attribute: IntercomAttribute.LastExaminerView, type: 'VIEWED' },
        ])

        break
      case location.pathname.includes('/intelligence'):
        send([
          { attribute: IntercomAttribute.IntelligenceViews, type: 'INCREMENT' },
          { attribute: IntercomAttribute.LastIntelligenceView, type: 'VIEWED' },
        ])

        break
      case location.pathname.includes('/table'):
        send([
          { attribute: IntercomAttribute.TableViews, type: 'INCREMENT' },
          { attribute: IntercomAttribute.LastTableView, type: 'VIEWED' },
        ])

        break
    }
  }, [location.pathname, send])

  return (
    <IntercomActionsContext.Provider value={actions}>{children}</IntercomActionsContext.Provider>
  )
}

export default IntercomActionsProvider
