import { Charts } from '@juristat/common/types'
import cuid from 'cuid'
import React, { useCallback, useMemo } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { useApp, useAppMutation } from '../../api'
import { useEntityTypeAndId } from '../../intelligence/components/ComparisonContext'
import { getDefaultKeyMetricsBy } from '../../intelligence/utils'
import {
  GridItemsItemsContext,
  GridItemsKeyContext,
  GridItemsStateContext,
  GridItemsUpdateContext,
} from '../contexts'
import { GridItem, GridItemType, IntelligenceReport } from '../types'

type GridItemsContextProviderProps = {
  children: React.ReactNode
}

const allDefaultCharts = [
  Charts.ApplicationStatus,
  Charts.FilingsOverTime,
  Charts.AllowanceRatesOverTime,
  Charts.AllowanceRatesByTechCenter,
  Charts.DaysToDispositionOverTime,
  Charts.OfficeActionsByDispositionYear,
  Charts.ExtensionsOverTime,
  Charts.DaysToFirstResponseOverTime,
  Charts.RejectionBasesOverTime,
  Charts.FilingsByTechCenterOverTime,
  Charts.IndependentClaimsOverTime,
  Charts.DependentClaimsOverTime,
  Charts.WordsPerIndependentClaimOverTime,
  Charts.WordsPerDependentClaimOverTime,
]
const intelligenceDefaultCharts = [Charts.DivisionOfWork, ...allDefaultCharts]

const toChartGridItems = (charts: Charts[]): GridItem[] =>
  charts.map((chart) => ({
    chart,
    id: cuid(),
    type: GridItemType.Chart,
  }))

function useGridItems() {
  const { pathname } = useLocation()
  const isIntelligence =
    useRouteMatch({
      exact: true,
      path: '/:entity(artunit|assignee-at-disp|attorney-at-disp|current-assignee|current-attorney|current-firm|firm-at-disp|cpc|techcenter|uspc)/:id/intelligence',
    }) !== null
  const isUspto = useRouteMatch({ exact: true, path: '/:entity(uspto)/intelligence' }) !== null

  const { entity } = useEntityTypeAndId()
  const by = useMemo(() => getDefaultKeyMetricsBy(entity), [entity])

  const key = `grid-items${pathname}`

  const [state] = useApp<IntelligenceReport | null, IntelligenceReport>(
    ['intelligence-report', entity],
    `/intelligence-report/${entity}`,
    {
      transform(data) {
        if ('id' in data) {
          return {
            ...data,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            items: data.items.map(({ selected, ...item }) => ({
              ...item,
              selected: typeof selected === 'string' ? JSON.parse(selected) : selected,
            })),
          }
        }

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const defaultTopXForYTable: GridItem = {
          by,
          id: cuid(),
          page: 1,
          selected: null,
          type: GridItemType.TopXForYTable,
        }
        const defaultGridItems = [
          defaultTopXForYTable,
          ...toChartGridItems(
            isIntelligence || isUspto ? intelligenceDefaultCharts : allDefaultCharts
          ),
        ].filter(Boolean)

        return { entityType: entity, items: defaultGridItems }
      },
    }
  )

  const { id } = state.context.data ?? {}

  const [save] = useAppMutation(`/intelligence-report/${entity}`, id ? 'patch' : 'post', {
    refetchQueries: [['intelligence-report', entity]],
  })

  const update = useCallback(
    (items: GridItem[]) => {
      save(
        {
          items: items.map((item, order) => ({
            ...item,
            id: item.id?.includes('-') ? item.id : undefined,
            order,
          })),
        },
        id ? `/intelligence-report/${id}` : undefined
      )
    },
    [id, save]
  )

  const gridItems = state.matches('success') ? state.context.data.items : []

  return [gridItems, key, state, update] as const
}

export default function GridItemsContextProvider({ children }: GridItemsContextProviderProps) {
  const [items, key, state, update] = useGridItems()

  return (
    <GridItemsItemsContext.Provider value={items}>
      <GridItemsKeyContext.Provider value={key}>
        <GridItemsStateContext.Provider value={state}>
          <GridItemsUpdateContext.Provider value={update}>
            {children}
          </GridItemsUpdateContext.Provider>
        </GridItemsStateContext.Provider>
      </GridItemsKeyContext.Provider>
    </GridItemsItemsContext.Provider>
  )
}
