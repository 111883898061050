import { clamp } from 'ramda'

const getHighestPageNum = (numPagesToShow: number, num: number, pages: number) =>
  Math.min(num + numPagesToShow, pages + 1)

const getLowestPageNum = (numPagesToShow: number, num: number) =>
  Math.max(num - (numPagesToShow + 1), 1)

const getPageNumRange = (from: number, to: number) =>
  new Array(to + 1 - from).fill('').map((_: null, idx: number) => idx + from)

const getAvailablePageRange = (currentPage: number, totalPages: number, numPagesToShow = 10) => {
  const clampToPageCount: (num: number) => number = clamp(1, totalPages || 1)
  const low = clampToPageCount(getLowestPageNum(numPagesToShow, currentPage))
  const high = clampToPageCount(getHighestPageNum(numPagesToShow, currentPage, totalPages))
  const range = totalPages > 1 ? getPageNumRange(low, high) : []
  const nums = range.filter(
    (num: number) =>
      num - numPagesToShow / 2 < currentPage && num + (numPagesToShow / 2 + 1) > currentPage
  )

  // We have sufficent numbers
  if (nums.length === numPagesToShow) {
    return nums
  }

  // Build a filter function on existing page number range
  const filterBy = (from: number, to: number) =>
    range.filter((num: number) => num >= from && num < to)
  const first = nums[0]
  const numsMissing = numPagesToShow - nums.length

  if (first === 1) {
    const last = nums[nums.length - 1] + 1

    return [...nums, ...filterBy(last, last + numsMissing)]
  }

  return [...filterBy(first - numsMissing, first), ...nums]
}

export default getAvailablePageRange
