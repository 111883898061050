import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const InfoCircle = (props: IconProps) => (
  <SVG title="info-circle" viewBox="0 0 18 18" {...props}>
    <path d="M8.99999 0.666626C4.39999 0.666626 0.666656 4.39996 0.666656 8.99996C0.666656 13.6 4.39999 17.3333 8.99999 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99996C17.3333 4.39996 13.6 0.666626 8.99999 0.666626ZM9.83332 13.1666H8.16666V8.16663H9.83332V13.1666ZM9.83332 6.49996H8.16666V4.83329H9.83332V6.49996Z" />
  </SVG>
)

export default InfoCircle
