import { css } from 'emotion'
import { isEmpty, reject } from 'ramda'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Menu from '../../../components/Menu'
import { useDemoMode } from '../../demo'
import { Cog } from '../../icons'
import { makeGetAccesses } from '../../session/selectors'
import { Access } from '../../session/types'
import actions from '../actions'
import NavBarItem from './NavBarItem'
import Tooltip from './Tooltip'

const styles = {
  container: css({
    marginBottom: 10,
    position: 'relative',
  }),
}

const isValid = (item: unknown): item is Record<'label' | 'value', string> => Boolean(item)

const SettingsMenu = () => {
  const dispatch = useDispatch()
  const logout = useCallback(() => dispatch(actions.logout()), [dispatch])
  const history = useHistory()
  const accesses = useSelector(
    makeGetAccesses([
      Access.Api,
      Access.AccountTools,
      Access.CustomerPpairNumberSettings,
      Access.StaffSettings,
    ])
  )
  const [demoMode, toggleDemoMode] = useDemoMode()

  const options = useMemo(() => {
    const [isApiUser, isJuristatStaff, isCustNoWhitelister, isUsManager] = accesses
    const showStaffSettings = isJuristatStaff || isUsManager

    return reject(isEmpty, {
      Juristat: [
        { label: 'Alerts Configuration', value: '/alerts-configuration' },
        isApiUser && { label: 'API Console', value: '/api' },
        { label: 'Saved Searches', value: '/searches/saved' },
        { label: 'Search History', value: '/searches/history' },
      ].filter(isValid),
      Staff: [
        showStaffSettings && { label: 'Account Tools', value: '/account-tools' },
        showStaffSettings && {
          label: `${demoMode ? 'Disable' : 'Enable'} Demo Mode`,
          value: 'demo',
        },
        showStaffSettings && { label: 'Scraper Management', value: '/scraper-management' },
        isCustNoWhitelister && { label: 'Private PAIR Sponsorships', value: '/sponsorships' },
      ].filter(isValid),
      Other: [
        { label: 'Account Management', value: '/account-management' },
        { label: 'Help', value: 'help' },
        { label: 'Log Out', value: 'logout' },
      ],
    })
  }, [accesses, demoMode])

  return (
    <div className={styles.container}>
      <Menu
        align="left"
        direction="up"
        handleClick={(value) => {
          switch (value) {
            case 'demo':
              return toggleDemoMode()
            case 'help':
              return window.open('https://help.juristat.com')
            case 'logout':
              return logout()
          }

          history.push(value)
        }}
        nested
        options={options}
        title={
          <NavBarItem active={false}>
            {(props) => (
              <Tooltip {...props} direction="right" tooltip="Settings">
                <Cog aria-label="Settings Menu" height={20} title="" width={20} />
              </Tooltip>
            )}
          </NavBarItem>
        }
        width="w-[200px]"
      />
    </div>
  )
}

export default SettingsMenu
