import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Building = (props: IconProps) => (
  <SVG title="building" viewBox="0 0 14 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.15175L13 4.58032V19H1V4.58032L7 1.15175ZM14 4L7 0L0 4V19V20H1H13H14V19V4ZM5 7H3V9H5V7ZM8 7V9H6V7H8ZM5 11H3V13H5V11ZM8 11V13H6V11H8ZM5 15H3V17H5V15ZM8 15V17H6V15H8ZM11 7H9V9H11V7ZM9 11H11V13H9V11ZM11 15H9V17H11V15Z"
    />
  </SVG>
)

export default Building
