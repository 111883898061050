import { isNilOrEmpty } from '@juristat/common/utils'
import { find, isEmpty, pathOr, reduce, reject, whereEq } from 'ramda'
import { createSelector } from 'reselect'

import { getFilterStateActive, getFilterStateAvailableIfHasData } from '../../filter/selectors'
import {
  CpcFilterCount,
  FilterCount,
  SearchSetFilterState,
  UspcFilterCount,
} from '../../filter/types'
import { SearchHistoryMetadataEntity } from '../types'

type Available = Array<FilterCount | CpcFilterCount | UspcFilterCount>
type Reducer<T> = (
  acc: SearchHistoryMetadataEntity,
  key: T extends string ? string : WeakObject
) => SearchHistoryMetadataEntity

const emptyArray: never[] = []
const emptyObject = {}

const reduceById =
  <T extends Available>(available: T | undefined, prop: string): Reducer<string> =>
  (acc, activeId) => {
    const id = Number(activeId)
    const item = find(whereEq({ id }), available ?? emptyArray)
    const description = pathOr(null, [prop], item)

    return item ? { ...acc, [id]: { id, description } } : acc
  }

const reduceByObject =
  <T extends Available>(
    available: T | undefined,
    idProp: string,
    descriptionProp: string
  ): Reducer<WeakObject> =>
  (acc, obj) => {
    const item = find(whereEq(obj), available ?? emptyArray)
    const id = pathOr('', [idProp], item)
    const description = pathOr('', [descriptionProp], item)

    return item ? { ...acc, [id]: { id, description } } : acc
  }

const maybeReduce = <T extends any[]>(
  reducer: Reducer<T extends Array<infer U> ? U : never>,
  source?: T
): SearchHistoryMetadataEntity | null => (source ? reduce(reducer, {}, source) : null)

const getMetadataFromAvailable = (active: SearchSetFilterState['active'], available: any) =>
  reject(isNilOrEmpty, {
    artUnit: maybeReduce(reduceById(available.artUnit, 'metadata'), active.artUnit),
    assigneeAtDisposition: maybeReduce(
      reduceById(available.assigneeAtDisposition, 'name'),
      active.assigneeAtDisposition
    ),
    assigneeAtDispositionName: maybeReduce(
      reduceById(available.assigneeAtDispositionName, 'name'),
      active.assigneeAtDispositionName
    ),
    attorneyAtDisposition: maybeReduce(
      reduceById(available.attorneyAtDisposition, 'name'),
      active.attorneyAtDisposition
    ),
    cpcClass: maybeReduce(reduceByObject(available.cpcClass, 'name', 'metadata'), active.cpcClass),
    currentAssignee: maybeReduce(
      reduceById(available.currentAssignee, 'name'),
      active.currentAssignee
    ),
    currentAssigneeName: maybeReduce(
      reduceById(available.currentAssigneeName, 'name'),
      active.currentAssigneeName
    ),
    currentAttorney: maybeReduce(
      reduceById(available.currentAttorney, 'name'),
      active.currentAttorney
    ),
    currentFirm: maybeReduce(reduceById(available.currentFirm, 'name'), active.currentFirm),
    currentFirmName: maybeReduce(
      reduceById(available.currentFirmName, 'name'),
      active.currentFirmName
    ),
    docCodeFilter: maybeReduce(
      reduceByObject(available.docCodeFilter, 'name', 'metadata'),
      active.docCodeFilter?.map((name) => ({ name }))
    ),
    examiner: maybeReduce(reduceById(available.examiner, 'name'), active.examiner),
    firmAtDisposition: maybeReduce(
      reduceById(available.firmAtDisposition, 'name'),
      active.firmAtDisposition
    ),
    firmAtDispositionName: maybeReduce(
      reduceById(available.firmAtDispositionName, 'name'),
      active.firmAtDispositionName
    ),
    uspcClass: maybeReduce(
      reduceByObject(available.uspcClass, 'name', 'metadata'),
      active.uspcClass
    ),
  })

const getActiveFilterMetadata = createSelector(
  getFilterStateActive,
  getFilterStateAvailableIfHasData,
  (active, available) =>
    isEmpty(available) ? emptyObject : getMetadataFromAvailable(active, available)
)

export default getActiveFilterMetadata
