import { call, put, takeLatest } from 'redux-saga/effects'

import { Response } from '../../../redux'
import performSearchQuery from '../../search/sagas/performSearchQuery'
import actions from '../actions'
import * as query from '../queries/getAvailableFilters.graphql'
import {
  GetAvailableAction,
  GraphQLAvailableFilterResponse,
  SetAvailableFilterPayload,
} from '../types'
import transformFilters from '../utils/transformFilters'

type GraphQLResponse<T> = {
  data: T
}

type GraphQLUidOrAppSetResponse<T> = Partial<{
  applicationSet: T
  uid: T
}>

function* getAvailableFilters(action: GetAvailableAction) {
  yield put(actions.fetch(undefined, action.meta))

  try {
    const { dataSource, filters, uid } = action.payload!
    const result: Response<GraphQLAvailableFilterResponse> = yield call(
      performSearchQuery,
      { uid, filters, withUid: Boolean(uid) },
      actions.error,
      query,
      dataSource
    )

    if (result.ok) {
      const { data }: GraphQLResponse<GraphQLUidOrAppSetResponse<GraphQLAvailableFilterResponse>> =
        yield call([result, 'json'])

      const allFilters = uid ? data.uid!.filters : data.applicationSet!.filters

      const transformedFilters = transformFilters(
        allFilters
      ) as unknown as SetAvailableFilterPayload

      yield put(actions.setAvailable(transformedFilters, action.meta))
    } else {
      yield put(actions.error(undefined, action.meta))
    }
  } catch (ex) {
    yield put(actions.error(undefined, action.meta))
  }
}

function* watchGetAvailableFilters() {
  yield takeLatest(actions.getAvailable().type, getAvailableFilters)
}

export { getAvailableFilters }
export default watchGetAvailableFilters
