import { useContext } from 'react'

import {
  GridItemsItemsContext,
  GridItemsKeyContext,
  GridItemsStateContext,
  GridItemsUpdateContext,
  MuuriActiveItemKeyContext,
  MuuriActiveItemUpdateContext,
} from './contexts'

export function useGridItemsItems() {
  const context = useContext(GridItemsItemsContext)

  if (context === undefined) {
    throw new Error('useGridItemsItems must be used within a GridItemsContextProvider')
  }

  return context
}

export function useGridItemsKey() {
  const context = useContext(GridItemsKeyContext)

  if (context === undefined) {
    throw new Error('useGridItemsKey must be used within a GridItemsContextProvider')
  }

  return context
}

export function useGridItemsState() {
  const context = useContext(GridItemsStateContext)

  if (context === undefined) {
    throw new Error('useGridItemsState must be used within a GridItemsContextProvider')
  }

  return context
}

export function useGridItemsUpdate() {
  const context = useContext(GridItemsUpdateContext)

  if (context === undefined) {
    throw new Error('useGridItemsUpdate must be used within a GridItemsContextProvider')
  }

  return context
}

export function useGridItems() {
  return [
    useGridItemsItems(),
    useGridItemsKey(),
    useGridItemsState(),
    useGridItemsUpdate(),
  ] as const
}

export function useMuuriActiveItemKey() {
  const context = useContext(MuuriActiveItemKeyContext)

  if (context === undefined) {
    throw new Error('useMuuriActiveItemKey must be used within a MuuriGridItems')
  }

  return context
}

export function useMuuriActiveItemUpdate() {
  const context = useContext(MuuriActiveItemUpdateContext)

  if (context === undefined) {
    throw new Error('useMuuriActiveItemUpdate must be used within a MuuriGridItems')
  }

  return context
}

export function useMuuriActiveItem() {
  return [useMuuriActiveItemKey(), useMuuriActiveItemUpdate()] as const
}
