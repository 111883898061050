import * as React from 'react'

type FooterItemProps = {
  children: React.ReactNode
}

const FooterItem = ({ children }: FooterItemProps) => (
  <li className="ml-[1.8em] text-[#838383]">{children}</li>
)

export default FooterItem
