import makeAction from '../../../redux/utils/makeAction'
import {
  AuthorizeAction,
  AuthorizeErrorAction,
  AuthorizeErrorClearAction,
  GetAuthMethodsAction,
  GetAuthMethodsErrorAction,
  HydrateAccessTokenAction,
  HydrateAccessTokenErrorAction,
  InvalidateAccessTokenAction,
  LoginAction,
  LoginEmailErrorAction,
  LoginEmailSendAction,
  LoginEmailSuccessAction,
  RedirectToDestinationAction,
  RefreshAccessTokenAction,
  SaveUsernameToLocalStorageAction,
  SetAccessTokenAction,
  SetAuthMethodsAction,
  SetClaimsAction,
} from '../types'

const authorize = makeAction<AuthorizeAction>('auth/AUTHORIZE')

const error = makeAction<AuthorizeErrorAction>('auth/AUTHORIZE_ERROR')

const errorClear = makeAction<AuthorizeErrorClearAction>('auth/AUTHORIZE_ERROR_CLEAR')

const getAuthMethods = makeAction<GetAuthMethodsAction>('auth/GET_AVAILABLE_METHODS')

const getAuthMethodsError = makeAction<GetAuthMethodsErrorAction>('auth/AVAILABLE_METHODS_ERROR')

const hydrateAccessToken = makeAction<HydrateAccessTokenAction>('auth/accessToken/HYDRATE')

const hydrateAccessTokenError = makeAction<HydrateAccessTokenErrorAction>(
  'auth/accessToken/HYDRATE_ERROR'
)

const invalidateAccessToken = makeAction<InvalidateAccessTokenAction>('auth/accessToken/INVALIDATE')

const login = makeAction<LoginAction>('auth/LOGIN')

const loginEmailError = makeAction<LoginEmailErrorAction>('auth/LOGIN_EMAIL_ERROR')

const loginEmailSent = makeAction<LoginEmailSuccessAction>('auth/LOGIN_EMAIL_SENT')

const redirect = makeAction<RedirectToDestinationAction>('auth/REDIRECT')

const refreshAccessToken = makeAction<RefreshAccessTokenAction>('auth/accessToken/REFRESH')

const saveUsernameToLocalStorage = makeAction<SaveUsernameToLocalStorageAction>(
  'auth/SAVE_USERNAME_TO_LOCAL_STORAGE'
)

const sendLoginEmail = makeAction<LoginEmailSendAction>('auth/SEND_LOGIN_EMAIL')

const setAccessToken = makeAction<SetAccessTokenAction>('auth/accessToken/SET')

const setAuthMethods = makeAction<SetAuthMethodsAction>('auth/SET_AVAILABLE_METHODS')

const setClaims = makeAction<SetClaimsAction>('auth/SET_CLAIMS')

export default {
  authorize,
  error,
  errorClear,
  getAuthMethods,
  getAuthMethodsError,
  hydrateAccessToken,
  hydrateAccessTokenError,
  invalidateAccessToken,
  login,
  loginEmailError,
  loginEmailSent,
  redirect,
  refreshAccessToken,
  saveUsernameToLocalStorage,
  sendLoginEmail,
  setAccessToken,
  setAuthMethods,
  setClaims,
}
