import config from '@juristat/config'
import { call, put, takeEvery } from 'redux-saga/effects'

import { api } from '../../../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../../../notification'
import actions from '../actions'
import { UsersVerifyEmailAction } from '../types'

export function* verifyUserEmail({ payload }: UsersVerifyEmailAction) {
  const { emailVerificationToken, uuid } = payload!
  const url = `${config.accountsUrl}/api/user/${uuid}/verify-email`
  const options = {
    body: JSON.stringify({ token: emailVerificationToken }),
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    method: 'post',
    url,
  }
  const response = yield call(api, url, options)
  if (!response.ok) {
    const message = yield call([response, 'json'])
    yield put(
      notificationActions.push(
        makeNotification({
          message: `Failed to validate email for user with uuid ${uuid}: ${message.error}`,
          type: NotificationTypes.Error,
        })
      )
    )
  }
}

function* watchVerifyUserEmail() {
  yield takeEvery([actions.verifyEmail().type], verifyUserEmail)
}

export default watchVerifyUserEmail
