import { startCase } from '@juristat/common/utils'
import { BarTooltipDatum } from '@nivo/bar'
import {
  contains,
  indexBy,
  keys,
  mapObjIndexed,
  merge,
  mergeWith,
  pipe,
  pluck,
  prop,
  values,
} from 'ramda'
import React from 'react'

import { colors } from '../../../styles'
import { ApplicationStatusEnum } from '../../filter/types'
import { Charts, ApplicationStatus as Data, Datum, TransformBar } from '../types'
import { ChartProps } from '../types'
import { getTitleForLookups } from '../utils'
import BarChart from './BarChart'
import BarChartSkeletonLoader from './BarChartSkeletonLoader'
import BarTooltip from './BarTooltip'
import PlatformChartContainer from './PlatformChartContainer'

type Output = WeakObject & {
  applicationStatus: string
}

const statuses = [
  ApplicationStatusEnum.Abandoned,
  ApplicationStatusEnum.Allowed,
  ApplicationStatusEnum.Pending,
]

const getStatusColor = (status: string) => {
  switch (status) {
    case ApplicationStatusEnum.Abandoned:
      return colors.pastelRed
    case ApplicationStatusEnum.Allowed:
      return colors.appleGreen
    case ApplicationStatusEnum.Pending:
      return colors.schoolbusYellow
    default:
      return colors.azure
  }
}

const transform: TransformBar<Data, Output> = (datum) =>
  datum
    .map(({ data, ...item }) =>
      data
        .filter(({ id }) => contains(id, statuses))
        .map(({ id, value }) => ({
          applicationStatus: startCase(id),
          [item.id]: value,
          [`${item.id}Color`]: datum.length === 1 ? getStatusColor(id) : item.color,
        }))
        .reduce((acc, value) => ({ ...acc, [value.applicationStatus]: value }), {} as Output)
    )
    .reduce<Output[]>(([acc], value) => [mergeWith(merge, acc, value)], [])

const transformColors = pipe(
  indexBy<Datum<Data>>(prop('id')),
  mapObjIndexed<Record<'color', string>, string>(prop('color'))
)

const ApplicationStatus: React.FC<ChartProps> = (props) => (
  <PlatformChartContainer
    chart={Charts.ApplicationStatus}
    exportableConfig={{
      filename: 'application_status',
      getData: (data) => {
        const transformed = transform(data)[0]

        return data.map(({ id }) => [
          id,
          ...values(mapObjIndexed((item: Output) => item[id], transformed)),
        ])
      },
      getHeader: (data) => keys(transform(data)[0]),
    }}
    skeleton={BarChartSkeletonLoader}
    title={getTitleForLookups('Application Status', props.lookups)}
    {...props}
  >
    {({ data: raw, ...dimensions }) => {
      const data = transform(raw)
      const colorsByKey = transformColors(raw)

      return (
        <BarChart
          {...dimensions}
          axisBottom={{
            legend: 'Application Status',
            legendOffset: 30,
          }}
          axisLeft={{
            format: '~s',
            legend: 'Applications',
          }}
          colorsByKey={colorsByKey}
          data={values(data[0])}
          groupMode="grouped"
          indexBy="applicationStatus"
          keys={pluck('id', raw)}
          tooltip={(tooltipProps: BarTooltipDatum) => (
            <BarTooltip
              {...tooltipProps}
              tooltipFormat=","
              xLegend="Application Status"
              yLegend="Applications"
            />
          )}
        />
      )
    }}
  </PlatformChartContainer>
)

export { transform, transformColors }
export default ApplicationStatus
