import { DialogueState, ReducerActions } from '../types'

export const reducer = <T>(state: DialogueState<T> = null, action: ReducerActions) => {
  switch (action.type) {
    case 'dialogue/CREATE':
      return action.payload ?? {}
    case 'dialogue/CONFIRM':
    case 'dialogue/DISMISS':
      return null
    default:
      return state
  }
}

export default reducer
