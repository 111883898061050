import { flatten } from 'ramda'
import { useContext, useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { useMergedQueries, useMutation, useQuery } from '../api'
import { useNotification } from '../notification/hooks'
import { NotificationTypes } from '../notification/types'
import {
  AlertActions,
  BulkAlertsLoadingContext,
  SelectedAlertsContext,
  ToggleModalContext,
} from './contexts'
import * as alertSubscriptions from './queries/alertSubscriptions.graphql'
import * as getAppnosForAlerts from './queries/getAppnosForAlerts.graphql'
import * as subscribeQuery from './queries/subscribeToAlert.graphql'
import * as unsubscribeQuery from './queries/unsubscribeFromAlert.graphql'

type Response = {
  alertSubscriptions: [{ appno: number }]
}

type AlertActionVariables = {
  pageNum: number
  uid: string
}

export function useAlerts() {
  const [alerts] = useQuery<number[], Response>('alerts', alertSubscriptions, {
    transform: ({ alertSubscriptions }) =>
      [...new Set(alertSubscriptions.map((data) => data?.appno))].filter(Boolean),
  })

  return alerts
}

const timeout = 65000 // Network request takes 1 minute to timeout

export function useBulkAlerts(variables: AlertActionVariables[], message = '') {
  const notificationId = useRef<string>()
  const currentPage = useRef(0)

  const { addNotification, updateNotification } = useNotification()
  const [onProgress] = useDebouncedCallback(() => {
    if (notificationId.current) {
      currentPage.current += 1

      updateNotification({
        id: notificationId.current,
        progress: currentPage.current / variables.length,
        timeout,
      })
    }
  }, 300)

  const [subscribe, subscribeState] = useMutation(subscribeQuery, undefined, {
    refetchQueries: ['alerts'],
  })
  const [unsubscribe, unsubscribeState] = useMutation(unsubscribeQuery, undefined, {
    refetchQueries: ['alerts'],
  })
  const [bulk, { refetch }] = useMergedQueries<
    number[],
    { uid: { page: Array<{ appno: number }> } }
  >(
    'alert-appnos',
    getAppnosForAlerts,
    variables.map(({ pageNum, uid }) => ({
      transform: ({ uid: { page } }) => page.map(({ appno }) => appno),
      variables: { pageNum, uid },
    })),
    { enabled: false, onProgress }
  )

  useEffect(() => {
    if (bulk.matches('loading') && !notificationId.current) {
      notificationId.current = addNotification('Adding applications to alerts...', {
        dismissable: false,
        timeout,
        progress: currentPage.current / variables.length,
      })
    }

    if (bulk.matches('failure') && notificationId.current) {
      updateNotification({
        id: notificationId.current,
        dismissable: true,
        progress: undefined,
        message: 'Failed to add applications to alerts',
        timeout: 2000,
        type: NotificationTypes.Error,
      })

      notificationId.current = undefined
    }

    if (bulk.matches('success') && notificationId.current) {
      updateNotification({
        id: notificationId.current,
        dismissable: true,
        progress: 1,
        message: 'Successfully added applications to alerts',
        timeout: 2000,
        type: NotificationTypes.Success,
      })

      notificationId.current = undefined

      subscribe({ appnos: flatten(bulk.context.data) })
    }
  }, [bulk.value, notificationId.current, variables.length])

  return {
    bulk: { message, subscribe: refetch },
    loading: [bulk, subscribeState, unsubscribeState].some(
      (state: { matches: (state: 'loading') => boolean }) => state.matches('loading')
    ),
    subscribe,
    unsubscribe,
  }
}

export function useBulkAlertsLoading() {
  const context = useContext(BulkAlertsLoadingContext)

  if (context === undefined) {
    throw new Error('useBulkAlertsLoading must be used within a BulkAlertsLoadingContext')
  }

  return context
}

export function useSelectedAlerts<T>() {
  const context = useContext(SelectedAlertsContext)

  if (context === undefined) {
    throw new Error('useSelectedAlerts must be used within a BulkAlertsLoadingContext')
  }

  return context as AlertActions<T>
}

export function useToggleModal() {
  const context = useContext(ToggleModalContext)

  if (context === undefined) {
    throw new Error('useToggleModal must be used within a BulkAlertsLoadingContext')
  }

  return context
}
