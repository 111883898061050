import { OrderDirection } from '@juristat/common/types'
import React, { useMemo } from 'react'

import { UpDownArrow } from '../modules/icons'
import { colors } from '../styles'
import Button from './Button'
import SimpleTable, { ColumnConfig, SimpleTableProps } from './SimpleTable'

type SortableColumnConfig<T> = ColumnConfig<T> & {
  sortable: boolean
}

type SortableTableProps<T> = Omit<SimpleTableProps<T>, 'columns'> & {
  columns: Array<SortableColumnConfig<T>>
  handleHeaderClick: (key: T) => void
  sortState?: {
    by: T | null
    direction: OrderDirection
  }
  tw?: boolean
}

const styles = {
  headerContainer: 'items-center flex',
  sortIcon: 'fill-dark h-[13px] ml-[10px] w-[13px]',
}

const orderDirectionToIcon = (sortDir?: OrderDirection) => {
  switch (sortDir) {
    case OrderDirection.Descending:
      return 'down'
    case OrderDirection.Ascending:
      return 'up'
    default:
      return undefined
  }
}

const HeaderContainer = ({
  children,
  handleClick,
  sortable,
}: {
  children: React.ReactNode
  handleClick: () => void
  sortable: boolean
}) =>
  sortable ? (
    <Button handleClick={handleClick} tw={styles.headerContainer}>
      {children}
    </Button>
  ) : (
    <div>{children}</div>
  )

const SortableTable = <T,>({
  columns,
  handleHeaderClick,
  sortState,
  ...simpleTableProps
}: SortableTableProps<T>) => {
  const columnConfigs = useMemo(
    () =>
      columns.map(({ render, sortable, ...rest }: SortableColumnConfig<T>) => ({
        render: (
          <HeaderContainer handleClick={() => handleHeaderClick(rest.key)} sortable={sortable}>
            {render}
            {sortable && (
              <UpDownArrow
                activeFill={colors.azure}
                className={styles.sortIcon}
                direction={
                  sortState && sortState.by === rest.key
                    ? orderDirectionToIcon(sortState.direction)
                    : undefined
                }
              />
            )}
          </HeaderContainer>
        ),
        ...rest,
      })),
    [sortState, handleHeaderClick]
  )

  return <SimpleTable columns={columnConfigs} {...simpleTableProps} tw={true} />
}

export { SortableColumnConfig, SortableTableProps }
export default SortableTable
