import { path } from 'ramda'

import { ActiveReducer } from '../../filter/types'
import enforceMinimumDate from './enforceMinimumDate'

const defaultIssuanceDateFilter = (filters: ActiveReducer) => ({
  ...filters,
  issuanceDate: {
    start: enforceMinimumDate(path(['issuanceDate', 'start'], filters)),
  },
})

export default defaultIssuanceDateFilter
