import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import Checkbox from '../../../components/Checkbox'
import Menu from '../../../components/Menu'
import { More } from '../../icons'
import { useBulkAlerts, useToggleModal } from '../hooks'

type BulkAlertsMenuProps<T> = Omit<ReturnType<typeof useBulkAlerts>, 'loading'> & {
  className?: string
  items: T[]
  results: T[]
  setItems: React.Dispatch<React.SetStateAction<T[]>>
  type: 'appnos' | 'saved-searches'
}

const styles = {
  checkbox: '[&_>_div_>_button_>_svg]:ml-0 items-center flex h-8',
  more: '[&:hover_>_svg]:fill-azure hover:border hover:border-azure hover:opacity-70 [&_>_svg]:fill-silver2 [&_>_svg]:transition-[fill] [&_>_svg]:duration-300 [&_>_svg]:ease-in-out border border-silver2 rounded-[50%] flex ml-[15px] p-[5px] transition-[border-color,opacity] duration-300 ease-in-out',
  open: '[&_>_svg]:fill-azure border-azure',
  placeholder: 'h-10',
}

const BulkAlertsMenu = <T,>({
  bulk,
  className,
  items,
  results,
  setItems,
  subscribe,
  type,
  unsubscribe,
}: BulkAlertsMenuProps<T>) => {
  const [debouncedBulkSubscribe] = useDebouncedCallback(() => bulk.subscribe(), 250)
  const bulkSubscribe = useRef(false)
  const toggle = useToggleModal()
  const history = useHistory()

  useEffect(() => {
    if (bulkSubscribe.current) {
      debouncedBulkSubscribe()

      bulkSubscribe.current = false
    }
  }, [debouncedBulkSubscribe, results])

  if (results.length === 0) {
    return <div className={styles.placeholder} />
  }

  const hidden = type === 'saved-searches'
  const state =
    items.length === results.length ? 'checked' : items.length > 0 ? 'partial' : 'unchecked'

  return (
    <div className={`${styles.checkbox} ${className}`}>
      <Checkbox
        accent="blue"
        checked={false}
        handleClick={() => setItems(results.length === items.length ? [] : results)}
        label={`${state === 'checked' ? 'Des' : 'S'}elect all`}
        renderLabel={() => null}
        state={state}
      />
      <Menu
        align="left"
        handleClick={(value) => {
          switch (value) {
            case 'all':
              return setItems(results)
            case 'none':
              return setItems([])
          }
        }}
        options={[
          { disabled: state === 'checked', label: 'Select All', value: 'all' },
          { disabled: state === 'unchecked', label: 'Select None', value: 'none' },
        ]}
        title=""
        width="w-[150px]"
      />
      <Menu
        align="left"
        handleClick={(value) => {
          switch (value) {
            case 'selected':
              return type === 'saved-searches' ? bulk.subscribe() : subscribe({ appnos: items })
            case 'all-on-page':
              setItems(results)

              if (type === 'saved-searches') {
                bulkSubscribe.current = true

                return
              }

              return subscribe({ appnos: results })
            case 'all-results':
              return bulk.subscribe()
            case 'disable':
              return unsubscribe({ appnos: items })
            case 'manage-recipients':
              return toggle()
            case 'alerts-configuration':
              history.push('/alerts-configuration')
              return
          }
        }}
        nested
        options={[
          {
            disabled: items.length === 0,
            label: `Add selected ${type === 'saved-searches' ? 'searches' : 'apps'} to alerts`,
            value: 'selected',
          },
          { label: 'Select all on page and add to alerts', value: 'all-on-page' },
          {
            hidden,
            label: `Add ${bulk.message} apps to alerts`,
            value: 'all-results',
          },
          {
            hidden,
            disabled: items.length === 0,
            label: 'Manage alert recipients for selected apps',
            value: 'manage-recipients',
          },
          {
            hidden,
            disabled: items.length === 0,
            label: 'Disable alerts for selected apps',
            value: 'disable',
          },
          {
            label: 'Go to alerts configuration page',
            value: 'alerts-configuration',
          },
        ]}
        title={(open) => (
          <div className={`${styles.more} ${open ? styles.open : ''}`} title="Alert actions">
            <More title="" transform="rotate(90)" />
          </div>
        )}
        width="w-[300px]"
      />
    </div>
  )
}

export default BulkAlertsMenu
