import { contains } from 'ramda'
import React from 'react'

import { useMutation } from '../../api'
import { Bell, Spinner } from '../../icons'
import { useAlerts, useBulkAlertsLoading } from '../hooks'
import * as subscribeQuery from '../queries/subscribeToAlert.graphql'
import * as unsubscribeQuery from '../queries/unsubscribeFromAlert.graphql'

type AlertIconProps = {
  appno: number
}

const styles = {
  disabled: 'fill-cloudy-blue',
  enabled: 'fill-apple-green',
  main: 'cursor-pointer h-3 w-3',
  spinner: 'cursor-default stroke-cloudy-blue',
}

const AlertIcon: React.FC<AlertIconProps> = ({ appno }) => {
  const alerts = useAlerts()
  const bulkAlertsLoading = useBulkAlertsLoading()
  const enabled = contains(appno, alerts.matches('success') ? alerts.context.data : [])
  const [mutate, state] = useMutation(
    enabled ? unsubscribeQuery : subscribeQuery,
    { appnos: [appno] },
    { refetchQueries: ['alerts'] }
  )
  const loading =
    bulkAlertsLoading ||
    state.matches('loading') ||
    alerts.matches('loading') ||
    alerts.matches({ success: 'refetching' })

  return loading ? (
    <Spinner
      className={`${styles.main} ${styles.spinner}`}
      title={`${alerts.matches('loading') ? 'Fetching' : 'Toggling'} alerts`}
    />
  ) : (
    <Bell
      className={`${styles.main} ${enabled ? styles.enabled : styles.disabled}`}
      onClick={() => mutate()}
      title={`${enabled ? 'Disable' : 'Enable'} alert`}
    />
  )
}

export default AlertIcon
