import config from '@juristat/config'
import { contains, map, prop } from 'ramda'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../api'
import { HttpStatus } from '../../http/types'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../actions'
import { makeGetCurrentRoles } from '../selectors'
import { SetRoleAction, SetRolePayload } from '../types'
import makeRolePayload from './utils/makeRolePayload'

const makeErrorMessage = ({ role: { role }, type, uuid }: SetRolePayload) =>
  `Failed to set role ${role} for ${type} with uuid ${uuid}`

const makeUrl = ({ type, uuid }: Pick<SetRolePayload, 'type' | 'uuid'>) =>
  `${config.accountsUrl}/api/${type}/${uuid}/roles`

function* setRole(action: SetRoleAction) {
  try {
    const { role, type, uuid } = action.payload!

    // Check if the role already is set for this user and return if it is
    const getCurrentRoles = yield call(makeGetCurrentRoles, uuid)
    const currentRoles = yield select(getCurrentRoles)
    if (
      currentRoles.type === HttpStatus.Success &&
      contains(role.role, map(prop('role'), currentRoles.data))
    ) {
      return
    }

    const url = makeUrl({ type, uuid })
    const options = {
      body: JSON.stringify(makeRolePayload(role)),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    }
    const response = yield call(api, url, options)
    if (response.ok) {
      yield put(actions.getCurrentRoles({ type, uuid }))
    } else {
      throw new Error(makeErrorMessage(action.payload!))
    }
  } catch (unknownErr) {
    const e = unknownErr as Error
    const message = e.message || makeErrorMessage(action.payload!)
    yield put(
      notificationActions.push(
        makeNotification({
          message,
          type: NotificationTypes.Error,
        })
      )
    )
    yield put(actions.setOrRemoveRoleError({ message, uuid: action.payload!.uuid }))
  }
}

export { makeErrorMessage, makeUrl, setRole }
export default function* watchSetRole() {
  yield takeLatest(actions.setRole().type, setRole)
}
