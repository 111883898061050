import { contains, tail } from 'ramda'

import { colors } from '../../../../../styles'

type State = Array<{
  color: string
  [key: string]: any
}>

const usableColors = tail(colors.chartColors)

const getFirstUnusedColor = (state: State) => {
  const usedColors = state.map(({ color }) => color)

  for (const color of usableColors) {
    if (!contains(color, usedColors)) {
      return color
    }
  }

  return colors.azure
}

export default getFirstUnusedColor
