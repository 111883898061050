import { DataSource, Filter, Key } from '@juristat/common/types'
import Downshift from 'downshift'
import { css } from 'emotion'
import React, { useMemo, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import Button from '../../../components/Button'
import InfoText from '../../../components/InfoText'
import Input from '../../../components/Input'
import { TableFetchingLoader } from '../../../components/StaticTable'
import { colors, textStyles } from '../../../styles'
import noop from '../../../utils/noop'
import RawNamesModal from '../../filter/components/RawNamesModal'
import {
  ASquare,
  Building,
  Buildings,
  CSquare,
  Close,
  CodeSquare,
  IconProps,
  People,
  Search,
  USquare,
} from '../../icons'
import { useSearchVariables } from '../../search/hooks'
import { useOmnisearchResults } from '../hooks'
import { OmnisearchResult, OmnisearchType } from '../types'
import Section from './Section'

type RenderProps = {
  input: OmnisearchProps['input']
  searchTerm: string
}

type SectionItem = {
  key: OmnisearchType
  icon: (props: IconProps) => JSX.Element
  label: string
}

type AdditionalSections = {
  type: string
  sections: SectionItem[][]
}

type OmnisearchProps = {
  additionalSections?: AdditionalSections
  children?: (props: RenderProps) => React.ReactNode
  className?: string
  forceOpen?: boolean
  initialValue?: string
  input?: (payload?: string) => void
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  onClose?: () => void
  onOuterClick?: () => void
  renderFooter?: (props: RenderProps) => React.ReactNode
  renderResult: (props: OmnisearchResult) => JSX.Element
  renderTop?: (props: RenderProps) => React.ReactNode
  showRawNamesPrompt?: boolean
  tw?: boolean
}

// The types downshift exports are not accurate
interface GetInputPropsOptions
  extends Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    | 'aria-autocomplete'
    | 'aria-activedescendant'
    | 'aria-controls'
    | 'aria-labelledby'
    | 'autoComplete'
    | 'value'
    | 'id'
    | 'onChange'
    | 'onKeyDown'
    | 'onBlur'
  > {
  disabled?: boolean
}

const styles = {
  close: css({
    '& > svg': {
      fill: colors.santasGray,
      width: 10,
    },
    height: 30,
    position: 'absolute',
    right: 12,
    top: 12,
    width: 30,
  }),
  column: css({
    '& + &': {
      paddingLeft: 10,
    },
    '&:not(:empty)': {
      flex: '0 0 315px',
      paddingBottom: 30,
    },
    overflow: 'hidden',
  }),
  fetching: (width: number) =>
    css({
      marginBottom: 10,
      width: `${width}%`,
    }),
  info: css({
    color: colors.pastelRed,
    paddingTop: 20,
  }),
  input: css({
    '&:focus': {
      '& + svg': {
        fill: colors.greenyBlue,
      },
      backgroundColor: colors.white,
      border: `1px solid ${colors.greenyBlue}`,
    },
    ...textStyles.darkBold12,
    backgroundColor: colors.paleGray,
    border: '1px solid transparent',
    borderRadius: 4,
    height: 36,
    outline: 'none',
    padding: '10px 10px 10px 38px',
    transition: 'background-color 300ms ease-out, border 300ms ease-out',
    width: 320,
  }),
  error: css({
    color: `${colors.torchRed} !important`,
  }),
  inputContainer: css({
    '& > svg': {
      fill: colors.placeholder,
      height: 13,
      left: 14,
      position: 'absolute',
      top: 11,
      transiton: 'fill 300ms ease-out',
      width: 13,
    },
    position: 'relative',
    zIndex: 1,
  }),
  main: css({
    position: 'relative',
  }),
  modal: (isOpen: boolean, forceOpen = false) =>
    css({
      backgroundColor: colors.white,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      boxShadow: forceOpen ? undefined : '0px 8px 24px rgba(27, 30, 49, 0.2)',
      left: '50%',
      opacity: isOpen ? 1 : 0,
      paddingTop: 60,
      position: 'absolute',
      top: -10,
      transform: 'translateX(-50%)',
      transition: 'height 300ms ease-out, opacity 300ms ease-out',
      visibility: isOpen ? 'visible' : 'hidden',
      width: 700,
    }),
  resultsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: -30,
    padding: '20px 30px 32px 30px',
  }),
  rawNamesText: css({
    fontSize: 12,
    marginLeft: 28,
  }),
}

const rawNamesModalStyles = {
  modal: css({
    left: 66,
  }),
  trigger: css({
    '& svg': {
      display: 'none',
    },
    border: 0,
    color: '#109fff',
    fontSize: 12,
    fontWeight: 'bold',
    margin: '0 0 0 28px',
    padding: 0,
    whiteSpace: 'nowrap',
    width: 'auto',
  }),
}

const defaultSections: AdditionalSections = {
  type: 'category',
  sections: [
    [
      { icon: Buildings, key: OmnisearchType.Company, label: 'Companies' },
      { icon: Building, key: OmnisearchType.Firm, label: 'Firms' },
      { icon: People, key: OmnisearchType.Examiner, label: 'Examiners' },
    ],
    [
      { icon: CodeSquare, key: OmnisearchType.TechCenter, label: 'Tech Centers' },
      { icon: ASquare, key: OmnisearchType.ArtUnit, label: 'Art Units' },
      { icon: CSquare, key: OmnisearchType.Cpc, label: 'CPC Classes' },
      { icon: USquare, key: OmnisearchType.Uspc, label: 'USPC Classes' },
    ],
  ],
}

const attorneySections: SectionItem[][] = [
  [{ icon: People, key: OmnisearchType.Attorney, label: 'Attorneys' }],
]

const RawNamesPrompt = () => (
  <>
    <div className={css(styles.rawNamesText)}>Don't see your company?</div>
    <RawNamesModal
      classNames={rawNamesModalStyles}
      filter={Filter.AssigneeAtDisposition}
      type="assignee"
      triggerButtonLabel="Search Raw Assignee Names"
    />
  </>
)

const Omnisearch: React.FC<OmnisearchProps> = ({
  additionalSections,
  children,
  className,
  forceOpen,
  initialValue = null,
  input = noop,
  inputProps,
  onClose,
  onOuterClick,
  renderFooter = () => null,
  renderResult,
  renderTop = () => null,
  showRawNamesPrompt = true,
  tw = false,
}) => {
  const { dataSource, searches, similarTo } = useSearchVariables()
  const isSearchPath = useRouteMatch('/search') !== null
  const [searchTerm, setSearchTerm] = useState<string | null>(() => {
    const [value] = Object.values({ ...searches, ...similarTo })

    return isSearchPath ? value || null : forceOpen && initialValue ? initialValue : null
  })
  const isPrivatePair = dataSource === DataSource.PrivatePair

  const { key } = useLocation()

  const sections = useMemo(() => {
    if (isPrivatePair) {
      return attorneySections
    }

    const [additionalLeft, additionalRight] = additionalSections?.sections ?? [[], []]

    const [left, right] =
      additionalSections && additionalSections.type === 'expert'
        ? [[], []]
        : [[...defaultSections.sections[0], ...attorneySections[0]], defaultSections.sections[1]]

    return [
      [...left, ...additionalLeft],
      [...right, ...additionalRight],
    ]
  }, [additionalSections, isPrivatePair])

  const [machines] = useOmnisearchResults({
    searchTerm,
    isPrivatePair,
  })

  return (
    <Downshift
      key={key}
      isOpen={forceOpen}
      onOuterClick={() => {
        onOuterClick?.()
      }}
      stateReducer={({ isOpen }, changes) => {
        switch (changes.type) {
          case Downshift.stateChangeTypes.blurInput:
            return { ...changes, isOpen: isOpen ?? true }
          default:
            return changes
        }
      }}
    >
      {({ closeMenu, getInputProps, isOpen, openMenu }) => (
        <div className={tw ? `relative ${className}` : css(styles.main, className)}>
          <div className={styles.inputContainer}>
            <Input
              {...(getInputProps({
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchTerm(event.target.value),
                onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.keyCode === Key.Enter) {
                    input(searchTerm ?? undefined)
                  }
                },
                value: searchTerm ?? initialValue ?? '',
              }) as GetInputPropsOptions)}
              {...inputProps}
              className={styles.input}
              handleOnBlur={openMenu}
              handleOnFocus={() => {
                if (searchTerm === null && initialValue) {
                  setSearchTerm(initialValue)
                }

                openMenu()
              }}
              onClick={() => openMenu()}
              placeholder="Search Juristat"
            />
            <Search />
          </div>
          <div className={styles.modal(isOpen, forceOpen)}>
            {forceOpen ? null : (
              <Button
                className={styles.close}
                handleClick={() => {
                  closeMenu()
                  onClose?.()
                }}
              >
                <Close />
              </Button>
            )}
            {renderTop({ input, searchTerm: searchTerm ?? '' })}
            <div className={styles.resultsContainer}>
              {children?.({ input, searchTerm: searchTerm ?? '' }) ??
                sections.map((section, index) => (
                  <div key={String(index)} className={styles.column}>
                    {section.map(({ key, ...props }) => {
                      const machine = machines[key]

                      return (
                        <React.Fragment key={key}>
                          {machine.matches('loading') ? (
                            <Section data={[]} {...props}>
                              {Array(Math.floor(Math.random() * 3) + 3)
                                .fill(0)
                                .map((_, idx) => (
                                  <TableFetchingLoader
                                    className={styles.fetching(Math.floor(Math.random() * 40) + 31)}
                                    key={String(idx)}
                                  />
                                ))}
                            </Section>
                          ) : machine.matches('failure') ? (
                            <Section data={[]} {...props}>
                              <InfoText className={styles.info}>{machine.context.error}</InfoText>
                            </Section>
                          ) : (
                            <Section data={machine.context.data ?? []} {...props}>
                              {machine.context.data?.map(renderResult)}
                              {showRawNamesPrompt &&
                              props.label === 'Companies' &&
                              searchTerm !== null &&
                              Array.isArray(machine.context.data) ? (
                                <RawNamesPrompt />
                              ) : null}
                            </Section>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                ))}
            </div>
            {renderFooter({ input, searchTerm: searchTerm ?? '' })}
          </div>
        </div>
      )}
    </Downshift>
  )
}

export { RenderProps, SectionItem, AdditionalSections }
export default Omnisearch
