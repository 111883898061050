import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Icon102 = (props: IconProps) => (
  <SVG title="102" viewBox="0 0 14 14" {...props}>
    <text x={-1} y={11} fill="white" style={{ fontSize: 9, fontWeight: 'bold' }}>
      102
    </text>
  </SVG>
)

export default Icon102
