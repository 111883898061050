import { stringify } from 'qs'
import { isEmpty } from 'ramda'
import { put, select, takeLatest } from 'redux-saga/effects'

import parseQueryStrings from '../../../redux/utils/parseQueryStrings'
import { getPathname, getQueryString, actions as routerActions } from '../../router'
import actions from '../actions'

function* handleAuthError() {
  const currentPath = yield select(getPathname)
  const queryString = yield select(getQueryString)
  const {
    app,
    auth: { destination: destinationParam, ...auth },
  } = parseQueryStrings(queryString)
  const destination = currentPath === '/signin' ? destinationParam ?? '/' : currentPath
  const appParams = stringify(app)
  const authParams = stringify(auth)

  yield put(
    routerActions.push(
      `/signin?destination=${encodeURIComponent(
        destination + (!isEmpty(app) ? `?${appParams}` : '')
      )}${!isEmpty(auth) ? `&${authParams}` : ''}`
    )
  )
}

export { handleAuthError }
export default function* watchHandleAuthError() {
  yield takeLatest(actions.error().type, handleAuthError)
}
