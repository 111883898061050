import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const DoubleCheck = (props: IconProps) => (
  <SVG title="double-check" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M18 6L7 17L2 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 10L14.5 17.5L13 16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SVG>
)

export default DoubleCheck
