import { eqBy, prop, unionWith } from 'ramda'

import { AppsAndName, EntityStatus } from '../types'

const entityStatusLookup: { [key: string]: string } = {
  [EntityStatus.Micro]: 'Micro',
  [EntityStatus.Small]: 'Small',
  [EntityStatus.Undiscounted]: 'Undiscounted',
}

const defaults = [EntityStatus.Micro, EntityStatus.Small, EntityStatus.Undiscounted].map(
  (entityStatus: EntityStatus) => ({
    apps: 0,
    label: entityStatusLookup[entityStatus],
    value: entityStatus,
  })
)

const trtansformEntityStatuses = (entityStatuses: AppsAndName[]) =>
  unionWith(
    eqBy(prop<'value', string>('value')),
    entityStatuses.map((entityStatus) => ({
      apps: entityStatus.apps,
      label: entityStatusLookup[entityStatus.name.toUpperCase()],
      value: entityStatus.name.toUpperCase(),
    })),
    defaults
  )

export { entityStatusLookup }
export default trtansformEntityStatuses
