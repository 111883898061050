import { format, parseISO } from 'date-fns'
import { css } from 'emotion'
import * as React from 'react'

import TextInput from '../../../../components/TextInput'
import { colors, textStyles } from '../../../../styles'
import noop from '../../../../utils/noop'
import { DateRangeSelection } from '../../types'

type DateInputProps = {
  open: boolean
  selection: DateRangeSelection
  setDatePickerOpen: (open: DateRangeSelection | null) => void
  value: string | undefined
}

const styles = {
  input: css({
    cursor: 'pointer',
    width: 100,
  }),
  inputOpen: css({
    border: `0.5px solid ${colors.greenyBlue}`,
  }),
  label: css(textStyles.paleGray2Normal12),
}

const DateInput = ({ open, selection, setDatePickerOpen, value }: DateInputProps) => (
  <div>
    <h3 className={styles.label}>{`${selection} Date`}</h3>
    <TextInput
      className={css(styles.input, open && styles.inputOpen)}
      dark
      handleOnFocus={() => setDatePickerOpen(selection)}
      handleOnTextChange={noop}
      placeholder="mm/yyyy"
      readOnly
      text={value ? format(parseISO(value), 'MM/yyyy') : ''}
    />
  </div>
)

export default DateInput
