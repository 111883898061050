import { Filter } from '@juristat/common/types'
import { isNilOrEmpty } from '@juristat/common/utils'
import { T, cond, evolve, identity, is, map, pick, pipe, reject } from 'ramda'

import createDeepEqualSelector from '../../../redux/utils/createDeepEqualSelector'
import memoize from '../../../utils/memoize'
import { getFilterStateActive } from '../../filter/selectors'
import { ActiveNonLocalFilter, ActiveReducer, Transformed } from '../../filter/types'

type Transform = (filters?: ActiveReducer) => Transformed

const filterOptions = [
  Filter.AmendmentCount,
  Filter.AppealCount,
  Filter.ApplicationNumber,
  Filter.ApplicationStatus,
  Filter.ApplicationType,
  Filter.ArtUnit,
  Filter.AssigneeAtDisposition,
  Filter.AssigneeAtDispositionName,
  Filter.AttorneyAtDisposition,
  Filter.AttorneyDocketNumber,
  Filter.CpcClass,
  Filter.CsvImport,
  Filter.CurrentAssignee,
  Filter.CurrentAssigneeName,
  Filter.CurrentAttorney,
  Filter.CurrentFirm,
  Filter.CurrentFirmName,
  Filter.CustomerNumber,
  Filter.DispositionDate,
  Filter.DocCode,
  Filter.EntityStatus,
  Filter.Examiner,
  Filter.ExaminerAllowanceRate,
  Filter.ExaminerInterviewWinRate,
  Filter.FiledUnpublished,
  Filter.FilingDate,
  Filter.FirmAtDisposition,
  Filter.FirmAtDispositionName,
  Filter.FinalRejectionsCount,
  Filter.InterviewCount,
  Filter.IssuanceDate,
  Filter.MissedContinuation,
  Filter.MissedInterview,
  Filter.NonFinalRejectionsCount,
  Filter.OfficeActionsCount,
  Filter.PatentNumber,
  Filter.PublicationDate,
  Filter.PublicationNumber,
  Filter.RejectionBasis,
  Filter.RceCount,
  Filter.RegistrationNumber,
  Filter.TechCenter,
  Filter.UnnecessaryAppeals,
  Filter.UnnecessaryOfficeActions,
  Filter.UspcClass,
]

const transformations: { [K in ActiveNonLocalFilter]: any } = {
  [Filter.AmendmentCount]: identity,
  [Filter.AppealCount]: identity,
  [Filter.ApplicationNumber]: map(Number),
  [Filter.ApplicationStatus]: identity,
  [Filter.ApplicationType]: identity,
  [Filter.ArtUnit]: identity,
  [Filter.AssigneeAtDisposition]: map(Number),
  [Filter.AssigneeAtDispositionName]: map(Number),
  [Filter.AttorneyAtDisposition]: map(Number),
  [Filter.AttorneyDocketNumber]: identity,
  [Filter.CpcClass]: identity,
  [Filter.CsvImport]: identity,
  [Filter.CurrentAssignee]: map(Number),
  [Filter.CurrentAssigneeName]: map(Number),
  [Filter.CurrentAttorney]: map(Number),
  [Filter.CurrentFirm]: map(Number),
  [Filter.CurrentFirmName]: map(Number),
  [Filter.CustomerNumber]: map(Number),
  [Filter.DispositionDate]: identity,
  [Filter.DocCode]: map((docCode: Record<'docCode', string> | string) => ({
    docCode: typeof docCode === 'string' ? docCode : docCode.docCode,
    hasDocCode: true,
  })),
  [Filter.EntityStatus]: identity,
  [Filter.Examiner]: map(Number),
  [Filter.ExaminerAllowanceRate]: identity,
  [Filter.ExaminerInterviewWinRate]: identity,
  [Filter.FiledUnpublished]: identity,
  [Filter.FilingDate]: identity,
  [Filter.FirmAtDisposition]: map(Number),
  [Filter.FirmAtDispositionName]: map(Number),
  [Filter.InterviewCount]: identity,
  [Filter.IssuanceDate]: identity,
  [Filter.FinalRejectionsCount]: identity,
  [Filter.MissedContinuation]: identity,
  [Filter.MissedInterview]: identity,
  [Filter.NonFinalRejectionsCount]: identity,
  [Filter.OfficeActionsCount]: identity,
  [Filter.PatentNumber]: map(String),
  [Filter.PublicationDate]: identity,
  [Filter.PublicationNumber]: map(String),
  [Filter.RejectionBasis]: identity,
  [Filter.RceCount]: identity,
  [Filter.RegistrationNumber]: map(Number),
  [Filter.TechCenter]: map(Number),
  [Filter.UnnecessaryAppeals]: identity,
  [Filter.UnnecessaryOfficeActions]: identity,
  [Filter.UspcClass]: identity,
}

const transform = evolve(transformations) as Transform

const getActiveFilters = createDeepEqualSelector(
  getFilterStateActive,
  pipe(
    pick(filterOptions) as any,
    map(
      cond([
        [is(Boolean), identity],
        [T, reject(isNilOrEmpty)],
      ])
    ),
    reject(isNilOrEmpty) as any,
    transform,
    memoize
  ) as <T>(item: T) => T
)

export { filterOptions, transform }
export default getActiveFilters
