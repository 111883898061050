import { Key } from '@juristat/common/types'
import { useActor } from '@xstate/react'
import React from 'react'
import { animated } from 'react-spring'
import { ActorRefFrom, actions, assign, createMachine, sendParent } from 'xstate'

import TextArea from '../../../components/TextArea'
import { useSlideDown } from '../../../hooks/useSlide'
import getClipboardData from '../../../utils/getClipboardData'
import { ExclamationCircle } from '../../icons'

type ValidationContext = { text: string }
type UpdateEvent = ValidationContext & { type: 'UPDATE' }
type ResetEvent = { type: 'RESET' }

type RecipientEvent =
  | { type: 'ADD' }
  | (ValidationContext & { type: 'PASTE' })
  | ResetEvent
  | UpdateEvent

type WorkflowAutomationRecipientsFieldProps = {
  actor: ActorRefFrom<typeof recipientMachine>
}

const styles = {
  error:
    '!bg-light-pink !border !border-light-pink focus:!bg-light-pink focus:!border focus:!border-light-pink',
  invalid:
    '[&_>_svg]:fill-current [&_>_svg]:ml-1 [&_>_svg]:mr-2 items-center text-[#bd4535] flex text-[10px]',
  textarea:
    '!text-blue-gray font-montserrat text-xs font-stretch-normal not-italic !font-bold leading-none placeholder:!text-blue-gray placeholder:font-montserrat placeholder:text-xs placeholder:font-stretch-normal placeholder:not-italic placeholder:!font-bold placeholder:leading-none placeholder:opacity-60 !bg-[#f5f9ff] border-transparent focus:bg-[#f5f9ff] focus:border-transparent transition-[background-color,border-color] duration-300 ease-in-out h-[70px]',
}

const emailPattern = "^[A-Za-z0-9._%+'-]+@[A-Za-z0-9._-]+\\.[A-Za-z]{2,}$"

const getEmails = (text: string) =>
  text
    .split(/(,| )/)
    .map((text) => text.replace(/(,| )/g, ''))
    .filter(Boolean)

export const recipientMachine = createMachine<ValidationContext, RecipientEvent>({
  id: 'recipient',
  initial: 'empty',
  context: {
    text: '',
  },
  on: {
    PASTE: [
      { target: 'empty', cond: (_, { text }) => text === '' },
      {
        actions: sendParent((_, { text }) => ({
          recipients: getEmails(text).map((email) => email.trim()),
          type: 'ADD_RECIPIENTS',
        })),
        target: 'empty',
        cond: (_, { text }) =>
          getEmails(text).every((email) => new RegExp(emailPattern).test(email.trim())),
      },
      { actions: assign((_, { text }) => ({ text })), target: 'invalid' },
    ],
    RESET: 'empty',
    UPDATE: {
      actions: assign({ text: (_, { text }) => text }),
      target: 'validating',
    },
  },
  states: {
    empty: {
      entry: assign({ text: '' }),
    },
    invalid: {},
    valid: {
      on: {
        ADD: {
          actions: [
            sendParent((context) => ({
              recipients: getEmails(context.text).map((email) => email.trim()),
              type: 'ADD_RECIPIENTS',
            })),
            actions.send('RESET'),
          ],
        },
      },
    },
    validating: {
      after: {
        0: [
          { target: 'empty', cond: (context) => context.text === '' },
          {
            target: 'valid',
            cond: (context) =>
              getEmails(context.text).every((email) => new RegExp(emailPattern).test(email.trim())),
          },
        ],
        300: 'invalid',
      },
    },
  },
})

export function WorkflowAutomationRecipientsField({
  actor,
}: WorkflowAutomationRecipientsFieldProps) {
  const [machine, send] = useActor(actor)

  const isInvalid = Boolean(machine.history?.matches('invalid')) || machine.matches('invalid')
  const transition = useSlideDown(isInvalid)

  return (
    <>
      <TextArea
        className={`${styles.textarea} ${isInvalid ? styles.error : ''}`}
        handleTextChange={(text) => send({ text, type: 'UPDATE' })}
        onKeyDown={(event) => {
          if (event.keyCode === Key.Enter) {
            send({ type: 'ADD' })

            event.preventDefault()
          }
        }}
        onPaste={(event) => {
          event.preventDefault()

          send({ text: getClipboardData(event), type: 'PASTE' })
        }}
        placeholder="Add email addresses separated by commas or spaces. Press Enter to add."
        text={machine.context.text}
      />
      {transition(
        (props, item, { key }) =>
          item && (
            <animated.div key={key} className={styles.invalid} style={props}>
              <ExclamationCircle /> Enter a valid email address.
            </animated.div>
          )
      )}
    </>
  )
}
