import { CSSObject } from 'create-emotion'
import { css } from 'emotion'
import React, { useEffect } from 'react'

import { colors, media } from '../styles'
import Copyright from './Copyright'

type PrintStylesProps = Partial<{
  chromeStyles: CSSObject
  edgeStyles: CSSObject
  firefoxStyles: CSSObject
  ieStyles: CSSObject
  sharedStyles: CSSObject
}> & {
  children: React.ReactNode
}

const firefox = '@supports (-moz-appearance: none)'
const edge = '@supports (-ms-ime-align: auto)'
const ie = '(-ms-high-contrast: active), (-ms-high-contrast: none)'
const chrome = '@supports (-webkit-appearance: none) and (not (-ms-ime-align: auto))'

const commonStyles: CSSObject = {
  '& [data-component="PrintOnly"]': { display: 'block' },
  // convert flex and scroll elements to block ones that show all content
  [`
    & [data-component="App"],
    & [data-component="App"] > div,
    & [data-component="App"] > div > div,
    & [data-component="LayoutContainer"],
    & [data-component="PageContainer"],
    & [data-component="ScrollableOnHover"],
    & [data-component="ScrollableOnHover"] > div,
    & [data-component="SearchContainer"]
  `]: {
    display: 'block',
    height: 'auto',
    overflow: 'visible',
    padding: 0,
    visibility: 'visible',
    width: '100%',
  },
  [`
    & [data-component="Footer"],
    & [data-component="Header"],
    & #nav-container,
    & [data-component="NotificationContainerComponent"],
    & [data-component="SearchTop"]
  `]: {
    display: 'none',
  },
  WebkitPrintColorAdjust: 'exact',
}

const footer = css(
  media('print', {
    backgroundColor: colors.white,
    bottom: 0,
    display: 'block',
    height: '15pt',
    left: 0,
    position: 'fixed',
    right: 0,
    textAlign: 'center',
  }),
  {
    display: 'none',
  }
)

const makeStyles = ({
  chromeStyles = {},
  edgeStyles = {},
  firefoxStyles = {},
  ieStyles = {},
  sharedStyles = {},
}: Omit<PrintStylesProps, 'children'>) =>
  css(
    media('print', {
      ...commonStyles,
      ...sharedStyles,
      [chrome]: chromeStyles,
      [edge]: edgeStyles,
      [firefox]: firefoxStyles,
      [ie]: ieStyles,
    })
  )

const PrintStyles: React.FC<PrintStylesProps> = ({ children, ...props }) => {
  useEffect(() => {
    if (document) {
      const className = makeStyles(props)

      document.body.className += ` ${className}`

      return () => {
        document.body.className = document.body.className.replace(className, '')
      }
    }
  }, [])

  return (
    <React.Fragment>
      {children}
      <div className={footer}>
        <Copyright />
      </div>
    </React.Fragment>
  )
}

export default PrintStyles
