import { css } from 'emotion'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import ButtonGroup from '../../../components/ButtonGroup'
import FetchingBars from '../../../components/FetchingBars'
import Status, { StatusType } from '../../../components/Status'
import HttpContentComponent from '../../http/components/HttpContentComponent'
import actions from '../actions'
import { useFilterState } from '../hooks'
import { CurrentOrDispositionState, Filter, PossibleFilterItem, TypeaheadFilter } from '../types'
import ActionButtons from './ActionButtons'
import AvailableFilterItems from './AvailableFilterItems'
import MultiFilterContainer from './MultiFilterContainer'
import RawNamesModal from './RawNamesModal'
import TypeaheadFilterComponent from './TypeaheadFilter'
import { buttonStyles, defaultGetLookup, getTimingOption } from './TypeaheadFilterContainer'

type CurrentAtDispositionFilterContainerProps = {
  filter: Filter
  filters: Filter[]
  rawNames?: 'assignee' | 'firm'
  title: string
}

const styles = {
  loader: css({
    marginBottom: 20,
  }),
}

const CurrentAtDispositionFilterContainer: React.FC<CurrentAtDispositionFilterContainerProps> = ({
  filter,
  filters,
  rawNames,
  title,
}) => {
  const { current, disposition } = getTimingOption(filter)
  const { active, available, content, selected, meta } = useFilterState(filter)

  const dispatch = useDispatch()
  const updateSwitch = useCallback(
    (payload: Filter | null) => {
      dispatch(actions.switch(payload as CurrentOrDispositionState, meta))
    },
    [meta]
  )

  return (
    <MultiFilterContainer
      buttons={false}
      filters={filters}
      maxHeight={rawNames ? 1000 : 850}
      title={title}
    >
      <TypeaheadFilterComponent filter={filter as TypeaheadFilter} title={title} />
      {rawNames ? <RawNamesModal filter={filter} type={rawNames} /> : null}
      <ButtonGroup
        {...buttonStyles}
        buttons={[
          { label: 'At Disp.', value: disposition as Filter },
          { label: 'Current', value: current as Filter },
        ]}
        update={updateSwitch}
        value={filter}
      />
      <HttpContentComponent
        content={content}
        renderError={(message) => <Status text={message} status={StatusType.Error} />}
        renderFetching={() => <FetchingBars className={styles.loader} dark quantity={4} />}
        renderSuccess={() => (
          <AvailableFilterItems
            active={active}
            available={available as PossibleFilterItem[]}
            filter={filter as TypeaheadFilter}
            getLookup={defaultGetLookup}
            selected={selected}
          />
        )}
      />
      <ActionButtons filter={filter} />
    </MultiFilterContainer>
  )
}

export default CurrentAtDispositionFilterContainer
