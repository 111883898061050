import { createSelector } from 'reselect'

import { AppState } from '../../../redux'
import { getPathname } from '../../router/selectors'
import { AccountState, RolesState } from '../types'

const getAccount = (state: AppState) => state.account

const getIdFromPathname = createSelector(getPathname, (pathname = '') => {
  const [id] = pathname.split('/').reverse()
  const isUuid = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/.test(id)

  return isUuid ? id : ''
})

const getRoleState = createSelector(getAccount, (state: AccountState) => state.roles)
const getAvailableRoles = createSelector(getRoleState, (state: RolesState) => state.available)
const getCurrentRoles = createSelector(getRoleState, (state: RolesState) => state.current)
const makeGetCurrentRoles = (uuid: string) =>
  createSelector(getCurrentRoles, (state: RolesState['current']) => state[uuid])

export { getAccount, getAvailableRoles, getIdFromPathname, getRoleState, makeGetCurrentRoles }
