import Quill, { DeltaStatic, QuillOptionsStatic, Sources } from 'quill'
import { useEffect, useRef } from 'react'

type Handlers = Partial<{
  onBlur: () => void
  onChange: (props: { delta?: DeltaStatic; html: string; source: Sources }) => void
}>

const useQuill = (
  ref: React.RefObject<HTMLDivElement>,
  defaultText: string,
  options?: QuillOptionsStatic,
  handlers?: Handlers
) => {
  const editor = useRef<Quill | null>(null)

  const getContents = () => {
    return editor.current ? editor.current.getContents() : undefined
  }

  const setContents = (delta: DeltaStatic) => {
    if (editor.current) {
      editor.current.setContents(delta)
    }
  }

  useEffect(() => {
    if (ref.current && !editor.current) {
      editor.current = new Quill(ref.current, options)

      setContents(editor.current.clipboard.convert(defaultText))
    }
  }, [ref.current])

  useEffect(() => {
    if (!editor.current) {
      return
    }

    const onEditorChange = (
      event: 'text-change' | 'selection-change',
      _delta: DeltaStatic,
      _oldDelta: DeltaStatic,
      source: Sources
    ) => {
      if (!handlers || !editor.current) {
        return
      }

      const { onBlur, onChange } = handlers

      if (event === 'selection-change' && _delta === null && onBlur) {
        onBlur()
      }

      if (event === 'text-change' && onChange) {
        onChange({ delta: getContents(), html: editor.current.root.innerHTML, source })
      }
    }

    editor.current.on('editor-change', onEditorChange)

    return () => {
      if (editor.current) {
        editor.current.off('editor-change', onEditorChange)
      }
    }
  }, [editor.current])

  return { editor, getContents, setContents }
}

export default useQuill
