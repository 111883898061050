import React from 'react'

import { ChartProps, Charts } from '../types'
import ChartByTechCenter from './ChartByTechCenter'

type FilingsByTechCenterProps = ChartProps & {
  asPercentage?: boolean
}

const FilingsByTechCenter: React.FC<FilingsByTechCenterProps> = ({
  asPercentage = false,
  ...props
}) => (
  <ChartByTechCenter
    asPercentage={asPercentage}
    chart={Charts.FilingsByTechCenter}
    chartProps={{
      axisBottom: {
        legend: 'Filing Year',
      },
      axisLeft: {
        format: asPercentage ? '.0%' : '~s',
        legend: 'Filings',
      },
    }}
    title={`Filings by Tech Center ${asPercentage ? 'Percentage' : 'Over Time'}`}
    {...props}
  />
)

export default FilingsByTechCenter
