import { css } from 'emotion'
import React from 'react'
import { SingleOrArray } from 'xstate'

type VoidFn = () => void

type ButtonProps = Partial<{
  active: boolean
  className: string
  disabled: boolean
  handleClick: VoidFn
  onClick: React.MouseEventHandler<HTMLButtonElement> | VoidFn
  onMouseDown: React.MouseEventHandler<HTMLButtonElement> | VoidFn
  tw: string
  title: string
}> & {
  children: SingleOrArray<React.ReactNode>
}

const styles = {
  main: css({
    '&::-moz-focus-inner': {
      border: 0,
    },
    '&:disabled': {
      cursor: 'not-allowed',
    },
    alignItems: 'center',
    background: 'none',
    borderWidth: 0,
    color: 'inherit',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    justifyContent: 'center',
    outline: 0,
    padding: 0,
    userSelect: 'none',
  }),
}

const Button = ({
  active = true,
  children,
  className,
  handleClick,
  onClick,
  onMouseDown,
  tw = '',
  title,
  ...props
}: ButtonProps) => (
  <button
    className={tw ? tw : css(styles.main, className)}
    disabled={!active}
    onClick={handleClick ? handleClick : onClick}
    onMouseDown={onMouseDown}
    title={title}
    {...props}
  >
    {children}
  </button>
)

export default Button
