import { Action } from '..'

const makeAction =
  <
    TAction extends Action<any, any>,
    Type = TAction['type'],
    Payload = TAction['payload'],
    Meta = TAction['meta'],
  >(
    type: Type
  ) =>
  (payload?: Payload, meta?: Meta): Action<Type, Payload, Meta> => ({
    meta,
    payload,
    type,
  })

export default makeAction
