import React, { useMemo } from 'react'

import { twTextStyles } from '../styles'
import getAvailablePageRange from '../utils/getAvailablePageRange'
import Button from './Button'

type PaginationBarProps = {
  className?: string
  current: number
  go: (page: number) => void
  pageCount: number
  theme?: 'green' | 'blue'
}

const styles = {
  container:
    '[&_>_:not(:last-child)]:mr-1 bg-white border border-cloudy-blue-alpha-50 rounded inline-flex p-[2px]',
  page: `${twTextStyles.charcoalGray2Bold13} rounded h-8 w-8`,
  pageActive: (theme: PaginationBarProps['theme']) =>
    `${theme === 'blue' ? 'bg-azure' : 'bg-apple-green'} text-pale-gray2`,
  prevNext: `${twTextStyles.charcoalGray2Bold13} disabled:text-charcoal-gray2-alpha50 bg-pale-gray rounded py-[7px] px-[11px]`,
}

const PaginationBar = ({
  className,
  current,
  go,
  pageCount,
  theme = 'green',
}: PaginationBarProps) => {
  const pageList = useMemo(
    () => getAvailablePageRange(current, pageCount, 10),
    [current, pageCount]
  )
  const hasNext = current < pageCount
  const hasPrevious = current > 1

  if (pageCount <= 1) {
    return null
  }

  return (
    <div className={`${styles.container} ${className ?? ''}`}>
      <Button active={hasPrevious} handleClick={() => go(current - 1)} tw={styles.prevNext}>
        Previous
      </Button>
      {pageList.map((num) => (
        <Button
          aria-pressed={num === current}
          data-pressed={num === current}
          handleClick={() => go(num)}
          key={String(num)}
          tw={`${styles.page} ${num === current ? styles.pageActive(theme) : ''}`}
        >
          {num}
        </Button>
      ))}
      <Button active={hasNext} handleClick={() => go(current + 1)} tw={styles.prevNext}>
        Next
      </Button>
    </div>
  )
}

export default PaginationBar
