import { History } from 'history'
import { Dispatch } from 'redux'

import { AppState } from '../../redux'
import { CallHistoryMethodAction } from './types'

const createMiddleware =
  (history: History) => () => (next: Dispatch<AppState>) => (action?: CallHistoryMethodAction) => {
    if (!action) {
      return
    }

    if (action.type !== '@@router/CALL_HISTORY_METHOD') {
      return next(action)
    }

    const { method, args } = action.payload!

    history[method](...args)
  }

export default createMiddleware
