import { ValueFormat, useValueFormatter } from '@nivo/core'
import { LineProps } from '@nivo/line'
import { getYear } from 'date-fns'
import { css } from 'emotion'
import { pathOr } from 'ramda'
import React from 'react'

import { colors } from '../../../styles'
import { getEntityLabel } from '../utils'

// TODO: PR to nivo
interface LineSliceData {
  axis: 'x' | 'y'
  slice: {
    height: number
    id: number
    points: Array<{
      borderColor: string
      color: string
      data: {
        x: number
        xFormatted: number
        y: number
        yFormatted: number
      }
      id: string
      index: number
      serieColor: string
      serieId: string
      x: number
      y: number
    }>
    width: number
    x0: number
    x: number
    y0: number
    y: number
  }
}

type LineSliceTooltipChildren<T extends number> = Pick<LineSliceData, 'slice'> & {
  valueFormatter: Exclude<ValueFormat<number>, string>
} & Pick<LineSliceTooltipProps<T>, 'xLegend' | 'yLegend'>

type LineSliceTooltipProps<T extends number> = LineSliceData &
  Pick<LineProps, 'tooltipFormat'> & {
    xLegend: string
    yLegend: string
  } & {
    children?: (props: LineSliceTooltipChildren<T>) => React.ReactNode
  }

const styles = {
  container: css({
    background: colors.white,
    borderRadius: 2,
    boxShadow: `0 1px 2px ${colors.dark2Alpha50}`,
    maxWidth: 400,
    padding: '5px 9px',
  }),
  hr: css({
    backgroundColor: colors.silver2,
    border: 0,
    height: 1,
  }),
  point: css({
    alignItems: 'center',
    display: 'flex',
  }),
}

const defaultChildren = <T extends number>({
  slice,
  valueFormatter,
  yLegend,
}: LineSliceTooltipChildren<T>) => (
  <React.Fragment>
    {slice.points.map(({ id, data: { y }, serieColor, serieId }) => (
      <div key={id} className={styles.point}>
        {serieId && (
          <div
            style={{
              backgroundColor: serieColor,
              height: 14,
              marginRight: 8,
              width: 14,
            }}
          />
        )}
        <div>
          {getEntityLabel(serieId || yLegend, 30)}: <strong>{valueFormatter(y)}</strong>
        </div>
      </div>
    ))}
  </React.Fragment>
)

const LineSliceTooltip = <T extends number>({
  children,
  xLegend,
  yLegend,
  slice,
  tooltipFormat,
}: LineSliceTooltipProps<T>) => {
  const valueFormatter = useValueFormatter(tooltipFormat)
  const year = pathOr<number | Date | null>(null, ['points', 0, 'data', 'x'], slice)

  return (
    <div className={styles.container}>
      {xLegend}: <strong>{year instanceof Date ? getYear(year) : year}</strong>
      <hr className={styles.hr} />
      {children
        ? children({ slice, valueFormatter, xLegend, yLegend })
        : defaultChildren({ slice, valueFormatter, xLegend, yLegend })}
    </div>
  )
}

export default LineSliceTooltip
