import React from 'react'
import { useSelector } from 'react-redux'

import JuristatLoader from '../../../components/JuristatLoader'
import LayoutContainer from '../../../components/LayoutContainer'
import ChartGridItem from '../../charts/components/ChartGridItem'
import { Lookup } from '../../charts/types'
import TopXForYTable from '../../intelligence/components/TopXForYTable'
import { makeGetAccess } from '../../session/selectors'
import { Access } from '../../session/types'
import { useGridItems } from '../hooks'
import { GridItemType } from '../types'
import { HiddenGridItem } from './GridItem'
import GridItemsPrintStyles from './GridItemsPrintStyles'
import MuuriGridItems from './MuuriGridItems'

type GridItemsProps = {
  lookups: Lookup[]
}

const GridItems: React.FC<GridItemsProps> = ({ lookups }) => {
  const [items, , machine, update] = useGridItems()
  const canAccessPlatform = useSelector(makeGetAccess(Access.Platform))

  if (machine.matches('loading')) {
    return <JuristatLoader className="mt-5 h-[inherit]" />
  }

  return (
    <GridItemsPrintStyles>
      <LayoutContainer className="px-[10px] py-0" tw={true}>
        <MuuriGridItems
          onDragEnd={(item) =>
            update(
              item
                .getGrid()
                .getItems()
                .map((item) => items.find(({ id }) => id === item.getKey())!)
            )
          }
          sort={items.map((item) => String(item.id))}
        >
          {items.map((item) => {
            switch (item.type) {
              case GridItemType.Chart:
                return (
                  <ChartGridItem key={item.id} {...item} id={item.id ?? null} lookups={lookups} />
                )
              case GridItemType.TopXForYTable:
                return canAccessPlatform ? (
                  <TopXForYTable key={item.id} itemKey={item.id} config={item} />
                ) : (
                  <HiddenGridItem key={item.id} itemKey={null} />
                )
              default:
                return <HiddenGridItem itemKey={null} />
            }
          })}
        </MuuriGridItems>
      </LayoutContainer>
    </GridItemsPrintStyles>
  )
}

export default GridItems
