import { contains } from 'ramda'

import { IntelligenceEntityType } from '../types'

const isAttorney = (entity: IntelligenceEntityType): boolean =>
  contains(entity, [
    IntelligenceEntityType.AttorneyAtDisposition,
    IntelligenceEntityType.CurrentAttorney,
  ])

export default isAttorney
